import { Contact, Company, Customer, CompanySearchInput } from '../../../../shared/api/types/GraphQL';
import * as UsersActions from './users.actions';
import { UserTypesPlural } from '../../../../shared/api/types/enums';

type StateProperties = {
  users: {
    contacts: {
      data: Contact[];
      loading: boolean;
      loaded: false;
      totalItems: number;
      currentPage: number;
      currentPageSize: number;
      selectedUsersList: Contact[];
      companiesFilters: CompanySearchInput;
    };
    companies: {
      data: Company[];
      loading: boolean;
      loaded: false;
      totalItems: number;
      currentPage: number;
      currentPageSize: number;
      selectedUsersList: Company[];
      companiesFilters: CompanySearchInput;
    };
    customers: {
      data: Customer[];
      loading: boolean;
      loaded: false;
      totalItems: number;
      currentPage: number;
      currentPageSize: number;
      selectedUsersList: Customer[];
      companiesFilters: CompanySearchInput;
    };
  };
  tab: UserTypesPlural;
};

export interface State {
  userListing: StateProperties;
  userPicking: StateProperties;
}

const initialStateProperties: StateProperties = {
  users: {
    contacts: {
      data: [],
      loading: false,
      loaded: false,
      totalItems: 0,
      currentPage: 1,
      currentPageSize: 5,
      selectedUsersList: [],
      companiesFilters: {},
    },
    companies: {
      data: [],
      loading: false,
      loaded: false,
      totalItems: 0,
      currentPage: 1,
      currentPageSize: 5,
      selectedUsersList: [],
      companiesFilters: {},
    },
    customers: {
      data: [],
      loading: false,
      loaded: false,
      totalItems: 0,
      currentPage: 1,
      currentPageSize: 5,
      selectedUsersList: [],
      companiesFilters: {},
    },
  },
  tab: UserTypesPlural.contacts,
};

const initialState: State = {
  userListing: initialStateProperties,
  userPicking: initialStateProperties,
};

export function usersReducer(state: State = initialState, action: UsersActions.UsersActions) {
  switch (action.type) {
    case UsersActions.RESET_STATE_ENTRY_STATE:
      return {
        ...state,
        [action.payload.toString()]: initialStateProperties, // Fix typo here
      };
    case UsersActions.GET_CURRENT_PAGE:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          users: {
            ...state[action.payload.stateEntry].users,
            [state[action.payload.stateEntry].tab]: {
              ...state[action.payload.stateEntry].users[state[action.payload.stateEntry].tab],
              currentPage: action.payload.page,
            },
          },
        },
      };
    case UsersActions.GET_CURRENT_PAGE_SIZE:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          users: {
            ...state[action.payload.stateEntry].users,
            [state[action.payload.stateEntry].tab]: {
              ...state[action.payload.stateEntry].users[state[action.payload.stateEntry].tab],
              currentPageSize: action.payload.pageSize,
            },
          },
        },
      };
    case UsersActions.SET_LOADING_TRUE:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          users: {
            ...state[action.payload.stateEntry].users,
            [state[action.payload.stateEntry].tab]: {
              ...state[action.payload.stateEntry].users[state[action.payload.stateEntry].tab],
              loading: true,
            },
          },
        },
      };
    case UsersActions.REFETCH_USERS:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          users: {
            ...state[action.payload.stateEntry].users,
            [state[action.payload.stateEntry].tab]: {
              ...state[action.payload.stateEntry].users[state[action.payload.stateEntry].tab],
              loading: true,
            },
          },
        },
      };

    case UsersActions.REFETCH_COMPANY_SEARCH:
      return {
        ...state,
        [action.stateEntry]: {
          ...state[action.stateEntry],
          users: {
            ...state[action.stateEntry].users,
            [state[action.stateEntry].tab]: {
              ...state[action.stateEntry].users[state[action.stateEntry].tab],
              loading: true,
            },
          },
        },
      };

    case UsersActions.LOAD_USERS:
      const loading = !state[action.payload.stateEntry].users[action.tab].loaded;
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          users: {
            ...state[action.payload.stateEntry].users,
            [action.tab]: {
              ...state[action.payload.stateEntry].users[action.tab],
              loading: loading,
              loaded: false,
            },
          },
        },
      };
    case UsersActions.LOAD_USERS_SUCCESS:
      const { data: loadData, stateEntry: loadStateEntry, tab: loadTab, itemsFound: loadItemsFound } = action.payload;
      return {
        ...state,
        [loadStateEntry]: {
          ...state[loadStateEntry],
          users: {
            ...state[loadStateEntry].users,
            [loadTab]: {
              ...state[loadStateEntry].users[loadTab],
              data: loadData,
              totalItems: loadItemsFound,
              loading: false,
              loaded: true,
            },
          },
        },
      };

    case UsersActions.LOAD_USERS_FAILURE:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          error: action.payload.error,
          loading: false,
        },
      };
    case UsersActions.TAB_CHANGE:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          tab: action.payload.tab,
        },
      };
    case UsersActions.ADD_USER_LIST_TO_SELECTED_LISTS:
      const idParameterName =
        state[action.payload.stateEntry].tab === UserTypesPlural.contacts
          ? 'contactId'
          : state[action.payload.stateEntry].tab === UserTypesPlural.customers
            ? 'customerId'
            : 'companyId';
      if (
        state[action.payload.stateEntry].users[state[action.payload.stateEntry].tab].selectedUsersList
          .map((x: any) => x[idParameterName])
          .indexOf(action.payload.selectedUsersList[idParameterName]) === -1
      ) {
        const userList = [
          ...state[action.payload.stateEntry].users[state[action.payload.stateEntry].tab].selectedUsersList,
          action.payload.selectedUsersList,
        ];
        return {
          ...state,
          [action.payload.stateEntry]: {
            ...state[action.payload.stateEntry],
            users: {
              ...state[action.payload.stateEntry].users,
              [state[action.payload.stateEntry].tab]: {
                ...state[action.payload.stateEntry].users[state[action.payload.stateEntry].tab],
                selectedUsersList: userList,
              },
            },
          },
        };
      }
      return state;

    case UsersActions.DELETE_USER_LIST_TO_SELECTED_LIST:
      const selectedUserSubList =
        state[action.payload.stateEntry].users[state[action.payload.stateEntry].tab].selectedUsersList;
      let userIdProperty = state[action.payload.stateEntry].tab.slice(0, -1) + 'Id';
      if (userIdProperty === 'companieId') {
        userIdProperty = 'companyId';
      }
      const indexToDelete = selectedUserSubList.findIndex((user: any) => user[userIdProperty] === action.payload.id);
      if (indexToDelete !== -1) {
        const updatedUserSubList = [...selectedUserSubList];
        updatedUserSubList.splice(indexToDelete, 1);
        return {
          ...state,
          [action.payload.stateEntry]: {
            ...state[action.payload.stateEntry],
            users: {
              ...state[action.payload.stateEntry].users,
              [state[action.payload.stateEntry].tab]: {
                ...state[action.payload.stateEntry].users[state[action.payload.stateEntry].tab],
                selectedUsersList: updatedUserSubList,
              },
            },
          },
        };
      }
      return state;
    case UsersActions.CLEAR_SELECTED_USERS_LIST:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          users: {
            ...state[action.payload.stateEntry].users,
            [state[action.payload.stateEntry].tab]: {
              ...state[action.payload.stateEntry].users[state[action.payload.stateEntry].tab],
              selectedUsersList: [],
            },
          },
        },
      };

    case UsersActions.ADD_GENERAL_FILTER:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          users: {
            ...state[action.payload.stateEntry].users,
            [state[action.payload.stateEntry].tab]: {
              ...state[action.payload.stateEntry].users[state[action.payload.stateEntry].tab],
              companiesFilters: {
                ...state[action.payload.stateEntry].users[state[action.payload.stateEntry].tab].companiesFilters,
                ...action.payload.filters,
              },
            },
          },
        },
      };
    case UsersActions.REMOVE_GENERAL_FILTER:
      const { columnName, index, tab, stateEntry } = action.payload;

      // Clone the state's companyFilter object to prevent mutation
      const updatedFilters = { ...state[stateEntry].users[tab].companiesFilters };

      // Check if valueSetFilters[columnName] is an array
      if (Array.isArray(updatedFilters[columnName])) {
        // If it's an array, clone the array, remove the item at the specified index, and update the valueSetFilters object
        updatedFilters[columnName] = [...updatedFilters[columnName]];
        updatedFilters[columnName].splice(index, 1);
        if (updatedFilters[columnName].length === 0) {
          delete updatedFilters[columnName];
        }
      } else {
        // If it's not an array, simply delete the key corresponding to columnName
        delete updatedFilters[columnName];
      }
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          users: {
            ...state[action.payload.stateEntry].users,
            [state[action.payload.stateEntry].tab]: {
              ...state[action.payload.stateEntry].users[state[action.payload.stateEntry].tab],
              companiesFilters: updatedFilters,
            },
          },
        },
      };
    case UsersActions.ADD_CONTACTS_TO_SELECTED_LIST:
      return {
        ...state,
        userPicking: {
          ...state.userPicking,
          users: {
            ...state.userPicking.users,
            contacts: {
              ...state.userPicking.users.contacts,
              selectedUsersList: action.payload.contacts,
            },
          },
        },
      };

    default:
      return state;
  }
}
