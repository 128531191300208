<div class="sidenav-wrapper">
  <div class="nav-content">
    <ng-container *ngFor="let navCategory of navObject | keyvalue">
      <div *ngIf="selectedTab === navCategory.key" [@fadeInAnimation]>
        <div class="nav-header">{{ navCategory.key }}</div>
        <ul class="main-nav">
          <ng-container *ngFor="let item of navCategory.value; let i = index">
            <li *ngIf="(item.role && authService.checkUserRole(item.role)) || !item.role">
              <a
                (click)="trackMenuClick(item.value)"
                [routerLink]="item.path"
                [queryParams]="item.path === 'product-catalogue' ? (catalogQueryParams | async) : {}"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: i === 0 ? true : false }"
                >{{ item.value }}</a
              >
            </li>
          </ng-container>
        </ul>
      </div>
    </ng-container>
  </div>
</div>
