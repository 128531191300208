import { gql } from 'apollo-angular';

export const AdminUserFragments = {
  adminUser: gql`
    fragment adminUser on AdminUser {
      id
      email
      gender
      firstName
      middleName
      lastName
      language
      phone
      job
      createdAt
      lastModifiedAt
    }
  `,
};
