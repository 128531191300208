<div class="select-user-action">
  <mat-form-field appearance="outline" color="primary" class="no-prefix">
    <mat-select
      placeholder="Select company"
      floatPlaceholder="never"
      panelClass="dropdown-search"
      [(ngModel)]="selectedCompany"
      (selectionChange)="onContactsLoad(1, true)"
      name="companies"
    >
      <mat-select-trigger>
        {{ selectedCompany?.name }}
      </mat-select-trigger>
      <mat-form-field appearance="outline">
        <mat-icon matPrefix>search</mat-icon>
        <input
          #companySearchInput
          matInput
          placeholder="Search"
          floatPlaceholder="never"
          (keydown)="$event.stopPropagation()"
          class="company-search"
        />
      </mat-form-field>
      <div class="option-wrapper" (scroll)="loadMore($event, 'companies')">
        <mat-option
          *ngFor="
            let company of isCompaniesFiltered && companiesFilteredDropdownObject.length
              ? companiesFilteredDropdownObject
              : !isCompaniesFiltered && companiesDropdownObject.length
                ? companiesDropdownObject
                : []
          "
          [value]="company"
        >
          {{ company?.name }}
        </mat-option>
      </div>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline" color="primary" class="no-prefix">
    <mat-select
      placeholder="Select contact"
      floatPlaceholder="never"
      panelClass="dropdown-search"
      [(ngModel)]="selectedContact"
      (selectionChange)="onContactSelect(selectedCompany, selectedContact)"
      name="contacts"
    >
      <mat-select-trigger> {{ selectedContact?.firstName }} {{ selectedContact?.lastName }} </mat-select-trigger>
      <!-- <mat-form-field appearance="outline">
          <mat-icon matPrefix>search</mat-icon>
          <input
            matInput
            placeholder="Search"
            floatPlaceholder="never"
            class="contact-search"
            (keydown)="$event.stopPropagation()"
            #contactSearchInput
          />
        </mat-form-field> -->
      <div class="option-wrapper" (scroll)="loadMore($event, 'contacts')">
        <!-- <mat-option value="anonymous">Anonymous user</mat-option> -->
        <mat-option *ngFor="let contact of allContacts.length ? allContacts : []" [value]="contact">
          {{ contact.firstName }} {{ contact.lastName }}
        </mat-option>
      </div>
    </mat-select>
  </mat-form-field>
</div>
