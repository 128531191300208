import { OrderStatusSet, OrderStatusSetsSearchInput } from 'src/app/shared/api/types/GraphQL';
import * as OrderStatusSetActions from './order-status-set.actions';
import { globals } from 'src/app/shared/globals';

export interface State {
  loading: boolean;
  loaded: boolean;
  orderStatusSetList: OrderStatusSet[];
  currentPage: number;
  currentPageSize: number;
  orderStatusSetCount: number;
  error: Error;
  generalFilterValue: string;
  activeTab: number;
  selectedOrderStatusSetList: OrderStatusSet[];
  orderStatusSetFilters: OrderStatusSetsSearchInput;
}

const initialState: State = {
  loading: false,
  loaded: false,
  orderStatusSetList: [],
  currentPage: 1,
  currentPageSize: globals.globalOffset,
  orderStatusSetCount: 0,
  error: undefined,
  generalFilterValue: '',
  activeTab: 0,
  selectedOrderStatusSetList: [],
  orderStatusSetFilters: {
    name: '',
  },
};

export function orderStatusSetReducer(
  state: State = initialState,
  action: OrderStatusSetActions.OrderStatusSetActions,
) {
  switch (action.type) {
    case OrderStatusSetActions.GET_GENERAL_FILTER:
      return {
        ...state,
        generalFilterValue: action.payload,
      };
    case OrderStatusSetActions.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case OrderStatusSetActions.GET_CURRENT_PAGE_SIZE:
      return {
        ...state,
        currentPageSize: action.payload,
      };
    case OrderStatusSetActions.GET_ORDER_STATUS_SET_COUNT:
      return {
        ...state,
        orderStatusSetCount: action.payload,
      };
    case OrderStatusSetActions.LOAD_ORDER_STATUS_SET:
      let loading;
      state.loaded ? (loading = false) : (loading = true);
      return {
        ...state,
        loading: loading,
      };
    case OrderStatusSetActions.REFETCH_ORDER_STATUS_SET:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case OrderStatusSetActions.LOAD_ORDER_STATUS_SET_SUCCESS:
      return {
        ...state,
        orderStatusSetList: [...action.payload],
        loading: false,
        loaded: true,
      };
    case OrderStatusSetActions.LOAD_ORDER_STATUS_SET_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case OrderStatusSetActions.ADD_ORDER_STATUS_SET:
      return {
        ...state,
        orderStatusSetList: [...state.orderStatusSetList, action.payload],
      };
    case OrderStatusSetActions.ADD_ORDER_STATUS_SET_TO_LIST:
      if (state.selectedOrderStatusSetList.map((x) => x.id).indexOf(action.payload.id) === -1) {
        return {
          ...state,
          selectedOrderStatusSetList: [...state.selectedOrderStatusSetList, action.payload],
        };
      } else {
        return {
          ...state,
        };
      }
    case OrderStatusSetActions.DELETE_ORDER_STATUS_SET_FROM_LIST:
      if (state.selectedOrderStatusSetList.map((x) => x.id).indexOf(action.payload) !== -1) {
        const index = state.selectedOrderStatusSetList.map((x) => x.id).indexOf(action.payload);
        return {
          ...state,
          selectedOrderStatusSetList: state.selectedOrderStatusSetList.filter((list, i) => {
            return i !== index;
          }),
        };
      }
      return state;
    case OrderStatusSetActions.CLEAR_ORDER_STATUS_SET_LIST:
      return {
        ...state,
        selectedOrderStatusSetList: [],
      };
    case OrderStatusSetActions.UPDATE_ORDER_STATUS_SET:
      const updatedOrderStatusSetIndex = state.orderStatusSetList.findIndex(
        (orderStatusSet) => orderStatusSet.id === action.payload.id,
      );
      if (updatedOrderStatusSetIndex > -1) {
        const updatedOrderStatusSetList = [...state.orderStatusSetList];
        updatedOrderStatusSetList[updatedOrderStatusSetIndex] = action.payload.updatedOrderStatusSet;
        return {
          ...state,
          orderStatusSetList: [...updatedOrderStatusSetList],
        };
      }
      return state; // If the updatedOrderStatusSetList is not found, return the current state unchanged.
    case OrderStatusSetActions.CREATE_ORDER_STATUS_SET:
      return {
        ...state,
        orderStatusSetList: [...state.orderStatusSetList, action.payload],
      };
    case OrderStatusSetActions.ADD_GENERAL_FILTER:
      return {
        ...state,
        orderStatusSetFilters: { ...state.orderStatusSetFilters, ...action.payload },
      };
    case OrderStatusSetActions.REMOVE_GENERAL_FILTER:
      const { columnName, index } = action.payload;

      // Clone the state's orderStatusSetFilters object to prevent mutation
      const updatedFilters = { ...state.orderStatusSetFilters };

      // Check if orderStatusSetFilters[columnName] is an array
      if (Array.isArray(updatedFilters[columnName])) {
        // If it's an array, clone the array, remove the item at the specified index, and update the orderStatusSetFilters object
        updatedFilters[columnName] = [...updatedFilters[columnName]];
        updatedFilters[columnName].splice(index, 1);
      } else {
        // If it's not an array, simply delete the key corresponding to columnName
        delete updatedFilters[columnName];
      }

      return {
        ...state,
        orderStatusSetFilters: updatedFilters,
      };
    default:
      return state;
  }
}
