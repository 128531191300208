import { Action } from '@ngrx/store';
import { ValuesetItem, ValuesetSearchInput } from 'src/app/shared/api/types/GraphQL';

export const GET_CURRENT_PAGE = '[ValueSetItem] Get Current Page';
export const GET_CURRENT_PAGE_SIZE = '[ValueSetItem] Get Current Page Size';
export const GET_VALUE_SET_ITEM_COUNT = '[ValueSetItem] Get ValueSetItem Count';
export const LOAD_VALUE_SET_ITEM = '[ValueSetItem] Load ValueSetItem';
export const REFETCH_VALUE_SET_ITEM = '[ValueSetItem] Refetch ValueSetItem';
export const LOAD_VALUE_SET_ITEM_SUCCESS = '[ValueSetItem] Load ValueSetItem Success';
export const LOAD_VALUE_SET_ITEM_FAILURE = '[ValueSetItem] Load ValueSetItem Failure';

export const ADD_VALUE_SET_ITEM = '[ValueSetItem] Add ValueSetItem';
export const CREATE_VALUE_SET_ITEM = '[ValueSetItem] Create ValueSetItem';

export const UPDATE_VALUE_SET_ITEM = '[ValueSetItem] Update ValueSetItem';
export const DELETE_VALUE_SET_ITEM = '[ValueSetItem] Delete ValueSetItem';

export const GET_GENERAL_FILTER = '[ValueSetItem] Get General Filter';
export const ADD_GENERAL_FILTER = '[OrderStatus] Add General Filter';
export const REMOVE_GENERAL_FILTER = '[OrderStatus] Remove General Filter';

export const ADD_VALUE_SET_ITEM_TO_LIST = '[ValueSetItem] Add Selected ValueSetItem to List';
export const DELETE_VALUE_SET_ITEM_FROM_LIST = '[ValueSetItem] Delete ValueSetItem from List';
export const CLEAR_VALUE_SET_ITEM_LIST = '[ValueSetItem] Clear ValueSetItem List';

export class GetCurrentPage implements Action {
  readonly type = GET_CURRENT_PAGE;

  constructor(public payload: number) {}
}

export class GetCurrentPageSize implements Action {
  readonly type = GET_CURRENT_PAGE_SIZE;

  constructor(public payload: number) {}
}

export class GetValueSetItemCount implements Action {
  readonly type = GET_VALUE_SET_ITEM_COUNT;

  constructor(public payload: number) {}
}

export class LoadValueSetItem implements Action {
  readonly type = LOAD_VALUE_SET_ITEM;

  constructor(public payload: {}) {}
}

export class RefetchValueSetItem implements Action {
  readonly type = REFETCH_VALUE_SET_ITEM;

  constructor(public payload: {}) {}
}

export class LoadValueSetItemSuccess implements Action {
  readonly type = LOAD_VALUE_SET_ITEM_SUCCESS;

  constructor(public payload: ValuesetItem[]) {}
}

export class LoadValueSetItemFailure implements Action {
  readonly type = LOAD_VALUE_SET_ITEM_FAILURE;

  constructor(public payload: Error) {}
}

export class AddValueSetItem implements Action {
  readonly type = ADD_VALUE_SET_ITEM;

  constructor(public payload: ValuesetItem) {}
}
export class CreateValueSetItem implements Action {
  readonly type = CREATE_VALUE_SET_ITEM;

  constructor(public payload: ValuesetItem) {}
}
export class UpdateValueSetItem implements Action {
  readonly type = UPDATE_VALUE_SET_ITEM;

  constructor(public payload: { id: number; updatedValueSetItem: ValuesetItem }) {}
}
export class DeleteValueSetItem implements Action {
  readonly type = DELETE_VALUE_SET_ITEM;

  constructor(public payload: string) {}
}

export class AddValueSetItemToList implements Action {
  readonly type = ADD_VALUE_SET_ITEM_TO_LIST;

  constructor(public payload: ValuesetItem) {}
}

export class DeleteValueSetItemFromList implements Action {
  readonly type = DELETE_VALUE_SET_ITEM_FROM_LIST;

  constructor(public payload: number) {}
}

export class ClearValueSetItemList implements Action {
  readonly type = CLEAR_VALUE_SET_ITEM_LIST;
}

export class GetGeneralFilter implements Action {
  readonly type = GET_GENERAL_FILTER;

  constructor(public payload: string) {}
}
export class AddGeneralFilter implements Action {
  readonly type = ADD_GENERAL_FILTER;

  constructor(public payload: ValuesetSearchInput) {}
}

export class RemoveGeneralFilter implements Action {
  readonly type = REMOVE_GENERAL_FILTER;

  constructor(public payload: { columnName: string; index?: number }) {}
}

export type ValueSetItemActions =
  | GetGeneralFilter
  | AddGeneralFilter
  | RemoveGeneralFilter
  | GetCurrentPage
  | GetCurrentPageSize
  | GetValueSetItemCount
  | LoadValueSetItem
  | RefetchValueSetItem
  | LoadValueSetItemSuccess
  | LoadValueSetItemFailure
  | AddValueSetItem
  | UpdateValueSetItem
  | DeleteValueSetItem
  | AddValueSetItemToList
  | DeleteValueSetItemFromList
  | ClearValueSetItemList
  | CreateValueSetItem;
