import { Action } from '@ngrx/store';
import { ManageDisplayedTableColumnsInput } from '../../api/types/types';
import { FavoriteStateEntry, UserStateEntry, UserTypesPlural } from '../../api/types/enums';
import { AttributeDescriptionClass } from '../../api/types/GraphQL';

export const PAYMETHODS_TABLE_COLUMNS = '[PayMethods] PayMethods Table Columns';
export const CARRIERS_TABLE_COLUMNS = '[Carriers] Carriers Table Columns';
export const SURCHARGES_TABLE_COLUMNS = '[Surcharges] Surcharges Table Columns';
export const TAXES_TABLE_COLUMNS = '[Taxes] Tax Table Columns';
export const BACKOFFICE_USERS_TABLE_COLUMNS = '[BackofficeUsers] BackofficeUsers Table Columns';
export const VALUE_SET_TABLE_COLUMNS = '[ValueSet] ValueSet Table Columns';
export const VALUE_SET_ITEM_TABLE_COLUMNS = '[ValueSetItem] ValueSetItem Table Columns';
export const ORDERLISTS_TABLE_COLUMNS = '[Orderlists] Table Columns';
export const FAVORITES_TABLE_COLUMNS = '[Favorites] Favorites Table Columns';
export const ATTRIBUTE_DESCRIPTIONS_TABLE_COLUMNS = '[AttributeDescriptions] Attribute Descriptions Table Columns';
export const ORDER_STATUS_TABLE_COLUMNS = '[OrderStatus] OrderStatus Table Columns';
export const ORDER_STATUS_SET_TABLE_COLUMNS = '[OrderStatusSet] OrderStatusSet Table Columns';
export const USERS_TABLE_COLUMNS = '[Users] Users Table Columns';
export const PRICE_SHEET_TABLE_COLUMNS = '[PriceSheet] PriceSheet Table Columns';

export class PayMethodsTableColumns implements Action {
  readonly type = PAYMETHODS_TABLE_COLUMNS;

  constructor(public payload: ManageDisplayedTableColumnsInput) {}
}

export class CarriersTableColumns implements Action {
  readonly type = CARRIERS_TABLE_COLUMNS;

  constructor(public payload: ManageDisplayedTableColumnsInput) {}
}

export class SurchargesTableColumns implements Action {
  readonly type = SURCHARGES_TABLE_COLUMNS;

  constructor(public payload: ManageDisplayedTableColumnsInput) {}
}

export class TaxesTableColumns implements Action {
  readonly type = TAXES_TABLE_COLUMNS;
  constructor(public payload: ManageDisplayedTableColumnsInput) {}
}

export class BackofficeUsersTableColumns implements Action {
  readonly type = BACKOFFICE_USERS_TABLE_COLUMNS;

  constructor(public payload: ManageDisplayedTableColumnsInput) {}
}

export class ValueSetTableColumns implements Action {
  readonly type = VALUE_SET_TABLE_COLUMNS;

  constructor(public payload: { columnsObj: ManageDisplayedTableColumnsInput; stateEntry: FavoriteStateEntry }) {}
}

export class ValueSetItemTableColumns implements Action {
  readonly type = VALUE_SET_ITEM_TABLE_COLUMNS;

  constructor(public payload: ManageDisplayedTableColumnsInput) {}
}

export class OrderlistsTableColumns implements Action {
  readonly type = ORDERLISTS_TABLE_COLUMNS;
  constructor(public payload: ManageDisplayedTableColumnsInput) {}
}

export class FavoritesTableColumns implements Action {
  readonly type = FAVORITES_TABLE_COLUMNS;

  constructor(public payload: { columnsObj: ManageDisplayedTableColumnsInput; stateEntry: FavoriteStateEntry }) {}
}
export class OrderStatusTableColumns implements Action {
  readonly type = ORDER_STATUS_TABLE_COLUMNS;

  constructor(public payload: ManageDisplayedTableColumnsInput) {}
}
export class OrderStatusSetTableColumns implements Action {
  readonly type = ORDER_STATUS_SET_TABLE_COLUMNS;

  constructor(public payload: ManageDisplayedTableColumnsInput) {}
}

export class UsersTableColumns implements Action {
  readonly type = USERS_TABLE_COLUMNS;
  constructor(
    public payload: { columnsObj: ManageDisplayedTableColumnsInput; stateEntry: UserStateEntry; tab: UserTypesPlural },
  ) {}
}

export class AttributeDescriptionsTableColumns implements Action {
  readonly type = ATTRIBUTE_DESCRIPTIONS_TABLE_COLUMNS;
  constructor(public payload: { columnObj: ManageDisplayedTableColumnsInput; attrClass: AttributeDescriptionClass }) {}
}

export class PriceSheetTableColumns implements Action {
  readonly type = PRICE_SHEET_TABLE_COLUMNS;

  constructor(public payload: ManageDisplayedTableColumnsInput) {}
}

export type TableColumnsActions =
  | PayMethodsTableColumns
  | CarriersTableColumns
  | SurchargesTableColumns
  | TaxesTableColumns
  | BackofficeUsersTableColumns
  | ValueSetTableColumns
  | ValueSetItemTableColumns
  | OrderlistsTableColumns
  | FavoritesTableColumns
  | AttributeDescriptionsTableColumns
  | OrderStatusTableColumns
  | OrderStatusSetTableColumns
  | UsersTableColumns
  | PriceSheetTableColumns;
