import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';

import { ProductListResponse, ProductResponse } from '../shared/api/types/types';
import { CatalogQuery } from '../shared/api/queries/prouct-queries';
import { ProductSearchInput } from '../shared/api/types/GraphQL';
import { CatalogCrossupsellsQuery } from '../shared/api/queries/product-details-queries';

@Injectable({ providedIn: 'root' })
export class CatalogService {
  imgLoading: boolean = true;

  constructor(private apollo: Apollo) {}

  getCatalogProducts(variables: {
    input: ProductSearchInput;
    companyId: number;
    contactId: number;
  }): QueryRef<ProductListResponse> {
    return this.apollo.watchQuery<ProductListResponse>({
      query: CatalogQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
  }

  getPdpCrossUpsells(variables: {}): QueryRef<ProductResponse> {
    return this.apollo.watchQuery<ProductResponse>({
      query: CatalogCrossupsellsQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
  }
}
