import { gql } from 'apollo-angular';

export const PayMethodsFragments = {
  payMethod: gql`
    fragment payMethod on PayMethod {
      id
      createdAt
      lastModifiedAt
      names {
        language
        value
      }
      externalCode
      logo
    }
  `,
};
