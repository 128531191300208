import { Action } from '@ngrx/store';
import { Tax, TaxSearchInput } from 'src/app/shared/api/types/GraphQL';

export const GET_CURRENT_PAGE = '[Taxes] Get Current Page';
export const GET_CURRENT_PAGE_SIZE = '[Taxes] Get Current Page Size';
export const GET_TAXES_COUNT = '[Taxes] Get Taxes Count';
export const LOAD_TAXES = '[Taxes] Load Taxes';
export const REFETCH_TAXES = '[Taxes] Refetch Taxes';
export const LOAD_TAXES_SUCCESS = '[Taxes] Load Taxes Success';
export const LOAD_TAXES_FAILURE = '[Taxes] Load Taxes Failure';

export const GET_GENERAL_FILTER = '[Taxes] Get General Filter';
export const ADD_GENERAL_FILTER = '[Taxes] Add General Filter';
export const REMOVE_GENERAL_FILTER = '[Taxes] Remove General Filter';

export const ADD_TAX_TO_SELECTED_LIST = '[Taxes] Add Tax To Selected Tax List';
export const DELETE_TAX_FROM_SELECTED_LIST = '[Tax] Delete Tax From Selected Tax List';
export const CLEAR_SELECTED_LIST = '[Taxes] Clear Selected Tax List';

export const TAXES_PAGE_INITIALIZED = '[Taxes] Page Initialized';
export const TAXES_PAGE_DESTROYED = '[Taxes] Page Destroyed';

export class GetCurrentPage implements Action {
  readonly type = GET_CURRENT_PAGE;

  constructor(public payload: number) {}
}

export class GetCurrentPageSize implements Action {
  readonly type = GET_CURRENT_PAGE_SIZE;

  constructor(public payload: number) {}
}

export class GetTaxesCount implements Action {
  readonly type = GET_TAXES_COUNT;

  constructor(public payload: number) {}
}

export class LoadTaxes implements Action {
  readonly type = LOAD_TAXES;

  constructor(public payload: { input: TaxSearchInput }) {}
}

export class RefetchTaxes implements Action {
  readonly type = REFETCH_TAXES;

  constructor(public payload: {}) {}
}

export class LoadTaxesSuccess implements Action {
  readonly type = LOAD_TAXES_SUCCESS;

  constructor(
    public payload: {
      items: Tax[];
      itemsFound: number;
    },
  ) {}
}

export class LoadTaxesFailure implements Action {
  readonly type = LOAD_TAXES_FAILURE;

  constructor(public payload: Error) {}
}

export class AddTaxToSelectedList implements Action {
  readonly type = ADD_TAX_TO_SELECTED_LIST;

  constructor(public payload: Tax) {}
}

export class DeleteTaxFromSelectedList implements Action {
  readonly type = DELETE_TAX_FROM_SELECTED_LIST;

  constructor(public payload: string) {}
}

export class ClearSelectedList implements Action {
  readonly type = CLEAR_SELECTED_LIST;
}

export class GetGeneralFilter implements Action {
  readonly type = GET_GENERAL_FILTER;

  constructor(public payload: string) {}
}

export class AddGeneralFilter implements Action {
  readonly type = ADD_GENERAL_FILTER;

  constructor(public payload: TaxSearchInput) {}
}
export class RemoveGeneralFilter implements Action {
  readonly type = REMOVE_GENERAL_FILTER;

  constructor(public payload: { columnName: string; index?: number }) {}
}
export class TaxesPageInitialized implements Action {
  readonly type = TAXES_PAGE_INITIALIZED;
}

export class TaxesPageDestroyed implements Action {
  readonly type = TAXES_PAGE_DESTROYED;
}

export type TaxesActions =
  | GetGeneralFilter
  | AddGeneralFilter
  | RemoveGeneralFilter
  | GetCurrentPage
  | GetCurrentPageSize
  | GetTaxesCount
  | LoadTaxes
  | RefetchTaxes
  | LoadTaxesSuccess
  | LoadTaxesFailure
  | AddTaxToSelectedList
  | DeleteTaxFromSelectedList
  | ClearSelectedList
  | TaxesPageInitialized
  | TaxesPageDestroyed;
