import { gql } from 'apollo-angular';
import { CategoryFragments } from '../fragments/category-fragments';

export const CategoryCreate = gql`
  mutation CategoryCreate($input: CreateCategoryInput!, $appLanguage: String) {
    categoryCreate(input: $input) {
      ...category
      categories {
        ...category
      }
    }
  }
  ${CategoryFragments.category}
`;

export const CategoryUpdate = gql`
  mutation CategoryUpdate($categoryId: Float!, $input: UpdateCategoryInput!, $appLanguage: String) {
    categoryUpdate(categoryId: $categoryId, input: $input) {
      ...category
      categories {
        ...category
      }
    }
  }
  ${CategoryFragments.category}
`;

export const CategoryDetailsUpdate = gql`
  mutation CategoryDetailsUpdate($categoryId: Float!, $input: UpdateCategoryInput!, $appLanguage: String) {
    categoryUpdate(categoryId: $categoryId, input: $input) {
      ...categoryDetails
    }
  }
  ${CategoryFragments.categoryDetails}
`;

export const CategoryDelete = gql`
  mutation CategoryDelete($categoryId: Float!) {
    categoryDelete(categoryId: $categoryId)
  }
`;
