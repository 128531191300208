import { AdminUser, AdminUsersSearchInput } from 'src/app/shared/api/types/GraphQL';
import * as BackofficeUsersActions from './backoffice-users.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  backofficeUsers: AdminUser[];
  currentPage: number;
  currentPageSize: number;
  backofficeUsersCount: number;
  error: Error;
  generalFilterValue: string;
  selectedBackofficeUsersList: AdminUser[];
  multiselectFilterValues: AdminUsersSearchInput;
}

const initialState: State = {
  loading: false,
  loaded: false,
  backofficeUsers: [],
  currentPage: 1,
  currentPageSize: 5,
  backofficeUsersCount: 0,
  error: undefined,
  generalFilterValue: '',
  selectedBackofficeUsersList: [],
  multiselectFilterValues: {
    sortInputs: [],
  },
};

export function backofficeUsersReducer(
  state: State = initialState,
  action: BackofficeUsersActions.BackofficeUsersActions,
) {
  switch (action.type) {
    case BackofficeUsersActions.GET_GENERAL_FILTER:
      return {
        ...state,
        generalFilterValue: action.payload,
      };
    case BackofficeUsersActions.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case BackofficeUsersActions.GET_CURRENT_PAGE_SIZE:
      return {
        ...state,
        currentPageSize: action.payload,
      };
    case BackofficeUsersActions.GET_BACKOFFICE_USERS_COUNT:
      return {
        ...state,
        backofficeUsersCount: action.payload,
      };
    case BackofficeUsersActions.LOAD_BACKOFFICE_USERS:
      let loading;
      state.loaded ? (loading = false) : (loading = true);
      return {
        ...state,
        loading: loading,
      };
    case BackofficeUsersActions.REFETCH_BACKOFFICE_USERS:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case BackofficeUsersActions.LOAD_BACKOFFICE_USERS_SUCCESS:
      return {
        ...state,
        backofficeUsers: [...action.payload],
        loading: false,
        loaded: true,
      };
    case BackofficeUsersActions.LOAD_BACKOFFICE_USERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case BackofficeUsersActions.ADD_BACKOFFICE_USER:
      return {
        ...state,
        backofficeUsers: [...state.backofficeUsers, action.payload],
      };
    case BackofficeUsersActions.ADD_BACKOFFICE_USER_TO_LIST:
      if (state.selectedBackofficeUsersList.map((x) => x.id).indexOf(action.payload.id) === -1) {
        return {
          ...state,
          selectedBackofficeUsersList: [...state.selectedBackofficeUsersList, action.payload],
        };
      } else {
        return {
          ...state,
        };
      }
    case BackofficeUsersActions.DELETE_BACKOFFICE_USER_FROM_LIST:
      if (state.selectedBackofficeUsersList.map((x) => x.id).indexOf(action.payload) !== -1) {
        const index = state.selectedBackofficeUsersList.map((x) => x.id).indexOf(action.payload);
        return {
          ...state,
          selectedBackofficeUsersList: state.selectedBackofficeUsersList.filter((product, i) => {
            return i !== index;
          }),
        };
      } else {
        return {
          ...state,
        };
      }
    case BackofficeUsersActions.CLEAR_BACKOFFICE_USERS_LIST:
      return {
        ...state,
        selectedBackofficeUsersList: [],
      };
    case BackofficeUsersActions.ADD_SORT_FILTERS:
      const { sortInputs, ...otherPayload } = action.payload;
      return {
        ...state,
        multiselectFilterValues: {
          ...state.multiselectFilterValues,
          ...otherPayload,
          sortInputs: sortInputs,
        },
      };

    default:
      return state;
  }
}
