import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { CarrierCreateResponse, CarrierListResponse, CarrierUpdateResponse } from '../../shared/api/types/types';
import { CarrierCreateInput, CarrierUpdateInput, CarriersSearchInput } from '../../shared/api/types/GraphQL';
import { CarrierDropdownList, CarriersList } from '../../shared/api/queries/carrier-query';
import { CarrierCreate, CarrierDelete, CarrierUpdate } from '../../shared/api/mutations/carrier-mutations';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CarriersService {
  private queryVariablesSubject: BehaviorSubject<CarriersSearchInput> = new BehaviorSubject<CarriersSearchInput>({});
  queryVariables$ = this.queryVariablesSubject.asObservable();

  constructor(private apollo: Apollo) {}

  setQueryVariables(variables: CarriersSearchInput): void {
    this.queryVariablesSubject.next(variables);
  }

  getQueryVariables(): CarriersSearchInput {
    return this.queryVariablesSubject.value;
  }

  getCarriersList(input: CarriersSearchInput): QueryRef<CarrierListResponse> {
    return this.apollo.watchQuery<CarrierListResponse>({
      query: CarriersList,
      variables: { input },
    });
  }
  getCarrierDropdownList(input: CarriersSearchInput): QueryRef<CarrierListResponse> {
    return this.apollo.watchQuery<CarrierListResponse>({
      query: CarrierDropdownList,
      variables: { input },
    });
  }

  carrierDelete(id: number) {
    return this.apollo.mutate<Boolean>({
      mutation: CarrierDelete,
      variables: { id },
    });
  }

  carrierUpdate(variables: { id: number; input: CarrierUpdateInput }) {
    return this.apollo.mutate<CarrierUpdateResponse>({
      mutation: CarrierUpdate,
      variables,
    });
  }

  carrierCreate(variables: { input: CarrierCreateInput }) {
    return this.apollo.mutate<CarrierCreateResponse>({
      mutation: CarrierCreate,
      variables,
    });
  }

  checkLogoValidity(imageUrl: string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const img = new Image();
      img.src = imageUrl;

      img.onload = () => {
        observer.next(true); // Image loaded successfully.
        observer.complete();
      };

      img.onerror = () => {
        observer.next(false); // Image failed to load.
        observer.complete();
      };
    });
  }
}
