import { Action } from '@ngrx/store';
import { Warehouse } from 'src/app/shared/api/types/GraphQL';

export const GET_CURRENT_PAGE = '[Warehouses] Get Current Page';
export const GET_CURRENT_PAGE_SIZE = '[Warehouses] Get Current Page Size';
export const GET_WAREHOUSES_COUNT = '[Warehouses] Get Warehouses Count';
export const LOAD_WAREHOUSES = '[Warehouses] Load Warehouses';
export const REFETCH_WAREHOUSES = '[Warehouses] Refetch Warehouses';
export const LOAD_WAREHOUSES_SUCCESS = '[Warehouses] Load Warehouses Success';
export const LOAD_WAREHOUSES_FAILURE = '[Warehouses] Load Warehouses Failure';

export const ADD_WAREHOUSE = '[Warehouses] Add Warehouse';
export const UPDATE_WAREHOUSE = '[Warehouses] Update Warehouse';
export const DELETE_WAREHOUSE = '[Warehouses] Delete Warehouse';

export const GET_GENERAL_FILTER = '[Warehouses] Get General Filter';
export const GET_ACTIVE_TAB = '[Warehouses] Get Active Tab';

export const ADD_WAREHOUSE_TO_LIST = '[Warehouses] Add Selected Warehouse to List';
export const DELETE_WAREHOUSE_FROM_LIST = '[Warehouses] Delete Warehouse from List';
export const CLEAR_WAREHOUSES_LIST = '[Warehouses] Clear Warehouses List';

export const WAREHOUSES_TABLE_COLUMNS = '[Warehouses] Warehouses Table Columns';

export const WAREHOUSES_PAGE_INITIALIZED = '[Warehouses] Page Initialized';
export const WAREHOUSES_PAGE_DESTROYED = '[Warehouses] Page Destroyed';

export class GetCurrentPage implements Action {
  readonly type = GET_CURRENT_PAGE;

  constructor(public payload: number) {}
}

export class GetCurrentPageSize implements Action {
  readonly type = GET_CURRENT_PAGE_SIZE;

  constructor(public payload: number) {}
}

export class GetWarehousesCount implements Action {
  readonly type = GET_WAREHOUSES_COUNT;

  constructor(public payload: number) {}
}

export class LoadWarehouses implements Action {
  readonly type = LOAD_WAREHOUSES;

  constructor(public payload: {}) {}
}

export class RefetchWarehouses implements Action {
  readonly type = REFETCH_WAREHOUSES;

  constructor(public payload: {}) {}
}

export class LoadWarehousesSuccess implements Action {
  readonly type = LOAD_WAREHOUSES_SUCCESS;

  constructor(public payload: Warehouse[]) {}
}

export class LoadWarehousesFailure implements Action {
  readonly type = LOAD_WAREHOUSES_FAILURE;

  constructor(public payload: Error) {}
}

export class AddWarehouse implements Action {
  readonly type = ADD_WAREHOUSE;

  constructor(public payload: Warehouse) {}
}

// export class UpdateWarehouse implements Action {
//     readonly type = UPDATE_WAREHOUSE;

//     constructor(public payload: {id: number, newWarehouse: Order}) {}
// }
export class DeleteWarehouse implements Action {
  readonly type = DELETE_WAREHOUSE;

  constructor(public payload: string) {}
}

export class AddWarehouseToList implements Action {
  readonly type = ADD_WAREHOUSE_TO_LIST;

  constructor(public payload: Warehouse) {}
}

export class DeleteWarehouseFromList implements Action {
  readonly type = DELETE_WAREHOUSE_FROM_LIST;

  constructor(public payload: string) {}
}

export class ClearWarehousesList implements Action {
  readonly type = CLEAR_WAREHOUSES_LIST;
}

export class GetGeneralFilter implements Action {
  readonly type = GET_GENERAL_FILTER;

  constructor(public payload: string) {}
}

export class WarehousesTableColumns implements Action {
  readonly type = WAREHOUSES_TABLE_COLUMNS;

  constructor(
    public payload: {
      displayedColumns: string[];
      hiddenColumns: string[];
      availableColumns: string[];
      disabledColumns: string[];
      disabledSuffixLength: number;
    },
  ) {}
}

export class WarehousesPageInitialized implements Action {
  readonly type = WAREHOUSES_PAGE_INITIALIZED;
}

export class WarehousesPageDestroyed implements Action {
  readonly type = WAREHOUSES_PAGE_DESTROYED;
}

export type WarehousesActions =
  | GetGeneralFilter
  | GetCurrentPage
  | GetCurrentPageSize
  | GetWarehousesCount
  | LoadWarehouses
  | RefetchWarehouses
  | LoadWarehousesSuccess
  | LoadWarehousesFailure
  | AddWarehouse
  //| UpdateWarehouse
  | DeleteWarehouse
  | AddWarehouseToList
  | DeleteWarehouseFromList
  | ClearWarehousesList
  | WarehousesTableColumns
  | WarehousesPageInitialized
  | WarehousesPageDestroyed;
