import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { SnackBarService } from 'src/app/shared/components/snack-bar/snack-bar.service';
import {
  AttributeEnumValue,
  Company,
  CompanySearchSortField,
  CompanySortableField,
  ValuesetItem,
} from '../../../../shared/api/types/GraphQL';
import { ListingTypes, UserStateEntry, UserTypesPlural } from '../../../../shared/api/types/enums';
import * as fromApp from '../../../../store/app.reducer';
import * as UsersActions from '../../store/users/users.actions';
import {
  ListingComponent,
  TableColumn,
  TableColumnType,
} from 'src/app/shared/components/listing-component/listing.component';
import { UserManagementService } from '../user-management.service';
import { cloneDeep } from '@apollo/client/utilities';
import { ContactsInCompanyComponent } from '../../user-details/tabs/contacts-in-company/contacts-in-company.component';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
})
export class CompaniesComponent implements OnInit, OnDestroy {
  @ViewChild(ListingComponent) listingComponent: ListingComponent;

  @Input() stateEntry: UserStateEntry = UserStateEntry.userListing;
  //for now it is used when the component is called from orderList dialog
  @Input() isInPicker: boolean = false;
  @Input() singleValueSelect: boolean = false;
  @Input() userTags: ValuesetItem[];
  @Input() canAddTags: boolean = true;

  @Output() actionTriggered = new EventEmitter<void>();
  additionalSelectedItemsActions = [
    {
      label: 'Add users',
      action: () => this.selectedItems(),
    },
  ];

  companyList: Company[];
  companyListCount: Observable<number>;
  initiallySelectedCompanies: Company[];

  page: number = 1;
  itemsPerPage: number = 5;
  loading: Observable<boolean>;

  selectedCompanyList: any;
  showSelectedCompanyList: boolean = false;

  listingType = ListingTypes.users;
  tab: UserTypesPlural = UserTypesPlural.companies;

  shouldShowDeleteBtn: boolean = true;

  enableHandleRow: boolean = false;
  shouldShowTooltip: boolean = false;
  tooltipMessage: string = 'Click to view and select contacts';
  sendCompaniesBatch: boolean = false;
  displayedColumns: TableColumn[] = [
    {
      name: 'name',
      label: 'Name',
      sortable: true,
      sortField: CompanySortableField.Name,
      additionalSortField: CompanySearchSortField.CompanyName,
      content: (element: Company) => element.name,
    },
    {
      name: 'location',
      label: 'Location',
      content: (element: Company) => element?.addresses[0]?.city || '',
    },
    {
      name: 'tags',
      label: 'Groups',
      sortable: false,
      type: TableColumnType.array,
      content: (element: Company) =>
        element.attributes.items ? (element.attributes.items[0]?.value as AttributeEnumValue)?.enumValues || [] : [],
    },
    {
      name: 'contacts',
      label: 'Contacts',
      sortable: false,
      content: (element: Company | any) => {
        if (element.contacts && element.contacts.itemsFound) {
          return element.contacts.itemsFound;
        } else if (element.contacts && element.contacts.length) {
          return element.contacts.length;
        }
      },
    },
    {
      name: 'source',
      label: 'Source',
      sortable: false,
      content: (element: Company) => 'Source data',
    },
    { name: 'actions', label: '', content: () => '' },
  ];
  actionItems?: {
    label: string;
    icon: string;
    type: string;
    action: () => void;
  }[] = [];

  shouldShowActionColumn: boolean = false;
  allowSelectionForContacts: boolean = false;

  isDialog: boolean = false;
  showCheckBox: boolean = true;

  private destroySubject: Subject<void> = new Subject();
  private filterCount = 0;

  constructor(
    private store: Store<fromApp.AppState>,
    public snackBarService: SnackBarService,
    public userManagementService: UserManagementService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      requestedFromDialog: boolean;
      enableHandleRow?: boolean;
      allowPickerFromDialog?: boolean;
      singleValueSelect?: boolean;
      shouldShowActionColumn?: boolean;
      allowSelectionForContacts?: boolean;
    },
    public dialogRef: MatDialogRef<CompaniesComponent>,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.isDialog = !!this.dialogRef.id;

    if (this.dialogData) {
      const {
        requestedFromDialog,
        allowPickerFromDialog,
        enableHandleRow,
        shouldShowActionColumn,
        allowSelectionForContacts,
      } = this.dialogData;

      if (requestedFromDialog) {
        this.stateEntry = UserStateEntry.userPicking;
        this.store.dispatch(
          new UsersActions.TabChange({ tab: UserTypesPlural.companies, stateEntry: this.stateEntry }),
        );

        if (allowPickerFromDialog) {
          this.isInPicker = true;
          this.sendCompaniesBatch = true;
          this.canAddTags = false;
        }
      }

      if (this.isDialog) {
        if (enableHandleRow) {
          this.shouldShowTooltip = true;
          this.enableHandleRow = true;
        }
      }

      this.shouldShowActionColumn = !!shouldShowActionColumn;
      this.allowSelectionForContacts = !!allowSelectionForContacts;
    }

    if (this.shouldShowActionColumn) {
      this.enableHandleRow = false;
      this.shouldShowTooltip = false;
      this.actionItems.push({
        label: 'View contacts',
        icon: '',
        type: 'viewButton',
        action: (element?: any) => {
          if (element) {
            this.openContactsInCompany(element, true);
          }
        },
      });
    }

    this.store
      .select((store) => store.users[this.stateEntry].users.companies)
      .pipe(takeUntil(this.destroySubject))
      .subscribe(({ currentPage, currentPageSize, selectedUsersList }) => {
        this.page = currentPage;
        this.itemsPerPage = currentPageSize;
        this.selectedCompanyList = selectedUsersList;
      });
    this.store
      .select((store) => store.users[this.stateEntry].users.companies.companiesFilters)
      .pipe(takeUntil(this.destroySubject))
      .subscribe((filters) => {
        const newFilterCount = Object.keys(filters).length;

        if (newFilterCount > 0 && this.filterCount === 0) {
          this.listingComponent.clearSortState();
        } else if (newFilterCount === 0 && this.filterCount > 0) {
          this.listingComponent.clearSortState();
        }
        this.filterCount = newFilterCount;
      });

    let variables = {
      page: this.page,
      offset: this.itemsPerPage,
    };
    this.store.dispatch(
      new UsersActions.LoadUsers({ variables: variables, stateEntry: this.stateEntry }, UserTypesPlural.companies),
    );
    this.loading = this.store.select((store) => store.users[this.stateEntry].users.companies.loading);
    this.companyListCount = this.store.select((store) => store.users[this.stateEntry].users.companies.totalItems);

    this.store
      .select((state) => state.users[this.stateEntry].users.companies.data as Company[] | any)
      .pipe(takeUntil(this.destroySubject))
      .subscribe((items) => {
        const companiesMapCompanyID = items.map((company) => ({
          ...company,
          companyId: company.companyId || company.id,
        }));
        this.companyList = cloneDeep(companiesMapCompanyID);
      });
  }

  openUserDetails(element: any) {
    if (!this.isDialog) this.userManagementService.openUserDetails(element);
    if (this.isDialog) this.dialogRef.close(element);
  }

  openContactsInCompany(element: any, singleValueSelect: boolean = false) {
    const dialogRef = this.dialog.open(ContactsInCompanyComponent, {
      width: '90vw',
      height: '90vh',
      maxWidth: '90vw',
      maxHeight: '90vh',
      panelClass: ['dialog', 'wCloseBtn', 'fullHeight'],
      data: {
        companyId: element.companyId,
        companyName: element.name,
        contactsInCompanyDialog: true,
        singleValueSelect: singleValueSelect,
        allowSelectionForContacts: this.allowSelectionForContacts,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (this.isDialog && result.shouldClose) this.dialogRef.close({ company: element, contact: result });
    });
  }

  performUserDeletion(element: any) {
    return this.userManagementService.performUserDeletion(element);
  }

  selectedItems() {
    if (!this.sendCompaniesBatch) this.actionTriggered.emit();
    else {
      this.dialogRef.close(this.selectedCompanyList);
      this.store.dispatch(new UsersActions.ClearSelectedUsersList({ stateEntry: this.stateEntry }));
    }
  }

  ngOnDestroy() {
    this.destroySubject.next();
    if (!!this.dialogData) {
      if (this.dialogData.requestedFromDialog) {
        this.stateEntry = UserStateEntry.userListing;
        this.store.dispatch(
          new UsersActions.TabChange({ tab: UserTypesPlural.companies, stateEntry: this.stateEntry }),
        );
      }
    }
  }
}
