import { gql } from 'apollo-angular';
import { TenderFragments } from '../fragments/tender.fragments';

export const TenderStartMutation = gql`
  mutation TenderStart($input: TenderStartInput!) {
    tenderStart(input: $input) {
      ...TenderData
      user {
        ...TenderUser
      }
      company {
        ...TenderCompany
      }
      deliveryAddress {
        ...TenderAddress
      }
      invoiceAddress {
        ...TenderAddress
      }
      items {
        childItems {
          ...TenderChildItems
        }
        ...TenderMainItem
      }
      bonusItems {
        ...TenderMainItem
      }
      carriers {
        ...TenderCarrier
      }
      payMethods {
        ...TenderPaymethod
      }
      paymentData {
        ...TenderPayment
      }
      postageData {
        ...TenderPostage
      }
      total {
        ...TenderTotal
      }
    }
  }
  ${TenderFragments.address}
  ${TenderFragments.total}
  ${TenderFragments.paymentData}
  ${TenderFragments.postageData}
  ${TenderFragments.items}
  ${TenderFragments.childItems}
  ${TenderFragments.carriers}
  ${TenderFragments.paymethods}
  ${TenderFragments.data}
  ${TenderFragments.user}
  ${TenderFragments.company}
`;

export const TenderUpdateAddress = gql`
  mutation TenderUpdateAddress($id: String!, $input: TenderUpdateAddressInput!) {
    tenderUpdateAddress(id: $id, input: $input) {
      user {
        ...TenderUser
      }
      company {
        ...TenderCompany
      }
      deliveryAddress {
        ...TenderAddress
      }
      invoiceAddress {
        ...TenderAddress
      }
      items {
        childItems {
          ...TenderChildItems
        }
        ...TenderMainItem
      }
      bonusItems {
        ...TenderMainItem
      }
      carriers {
        ...TenderCarrier
      }
      paymentData {
        ...TenderPayment
      }
      postageData {
        ...TenderPostage
      }
      total {
        ...TenderTotal
      }
      status
    }
  }
  ${TenderFragments.address}
  ${TenderFragments.paymentData}
  ${TenderFragments.postageData}
  ${TenderFragments.items}
  ${TenderFragments.childItems}
  ${TenderFragments.carriers}
  ${TenderFragments.total}
  ${TenderFragments.user}
  ${TenderFragments.company}
`;

export const TenderAddItems = gql`
  mutation TenderAddItems($id: String!, $input: TenderAddItemsInput!) {
    tenderAddItems(id: $id, input: $input) {
      tender {
        items {
          childItems {
            ...TenderChildItems
          }
          ...TenderMainItem
        }
        bonusItems {
          ...TenderMainItem
        }
        carriers {
          ...TenderCarrier
        }
        paymentData {
          ...TenderPayment
        }
        postageData {
          ...TenderPostage
        }
        total {
          ...TenderTotal
        }
      }
      response {
        data
        messages
      }
    }
  }
  ${TenderFragments.total}
  ${TenderFragments.paymentData}
  ${TenderFragments.postageData}
  ${TenderFragments.items}
  ${TenderFragments.childItems}
  ${TenderFragments.carriers}
`;

export const TenderDeleteItem = gql`
  mutation TenderDeleteItem($id: String!, $itemId: String!) {
    tenderDeleteItem(id: $id, itemId: $itemId) {
      items {
        childItems {
          ...TenderChildItems
        }
        ...TenderMainItem
      }
      bonusItems {
        ...TenderMainItem
      }
      carriers {
        ...TenderCarrier
      }
      paymentData {
        ...TenderPayment
      }
      postageData {
        ...TenderPostage
      }
      total {
        ...TenderTotal
      }
    }
  }
  ${TenderFragments.total}
  ${TenderFragments.paymentData}
  ${TenderFragments.postageData}
  ${TenderFragments.items}
  ${TenderFragments.childItems}
  ${TenderFragments.carriers}
`;

export const TenderUpdateItem = gql`
  mutation TenderUpdateItem($id: String!, $itemId: String!, $input: TenderUpdateItemInput!) {
    tenderUpdateItem(id: $id, itemId: $itemId, input: $input) {
      incentivesApplied
      items {
        childItems {
          ...TenderChildItems
        }
        ...TenderMainItem
      }
      bonusItems {
        ...TenderMainItem
      }
      carriers {
        ...TenderCarrier
      }
      paymentData {
        ...TenderPayment
      }
      postageData {
        ...TenderPostage
      }
      total {
        ...TenderTotal
      }
    }
  }
  ${TenderFragments.total}
  ${TenderFragments.paymentData}
  ${TenderFragments.postageData}
  ${TenderFragments.items}
  ${TenderFragments.childItems}
  ${TenderFragments.carriers}
`;

export const TenderUpdateDiscount = gql`
  mutation TenderUpdateDiscount($id: String!, $input: TenderDiscountInput!) {
    tenderUpdateDiscount(id: $id, input: $input) {
      items {
        childItems {
          ...TenderChildItems
        }
        ...TenderMainItem
      }
      bonusItems {
        ...TenderMainItem
      }
      carriers {
        ...TenderCarrier
      }
      paymentData {
        ...TenderPayment
      }
      postageData {
        ...TenderPostage
      }
      total {
        ...TenderTotal
      }
    }
  }
  ${TenderFragments.total}
  ${TenderFragments.paymentData}
  ${TenderFragments.postageData}
  ${TenderFragments.items}
  ${TenderFragments.childItems}
  ${TenderFragments.carriers}
`;

export const TenderUpdatePayment = gql`
  mutation TenderUpdatePayment($id: String!, $input: TenderPaymentInput!) {
    tenderUpdatePayment(id: $id, input: $input) {
      paymentData {
        ...TenderPayment
      }
      total {
        ...TenderTotal
      }
    }
  }
  ${TenderFragments.total}
  ${TenderFragments.paymentData}
`;

export const TenderUpdate = gql`
  mutation TenderUpdate($id: String!, $input: TenderUpdateInput!) {
    tenderUpdate(id: $id, input: $input) {
      reference
      remarks
      status
      isEditable
      validUntil
      source
      deliveryAddress {
        ...TenderAddress
      }
      invoiceAddress {
        ...TenderAddress
      }
      items {
        childItems {
          ...TenderChildItems
        }
        ...TenderMainItem
      }
      bonusItems {
        ...TenderMainItem
      }
      carriers {
        ...TenderCarrier
      }
      paymentData {
        ...TenderPayment
      }
      postageData {
        ...TenderPostage
      }
      status
      total {
        ...TenderTotal
      }
    }
  }
  ${TenderFragments.address}
  ${TenderFragments.total}
  ${TenderFragments.paymentData}
  ${TenderFragments.postageData}
  ${TenderFragments.items}
  ${TenderFragments.childItems}
  ${TenderFragments.carriers}
`;

export const TenderUpdatePostage = gql`
  mutation TenderUpdatePostage($id: String!, $input: TenderPostageInput!) {
    tenderUpdatePostage(id: $id, input: $input) {
      postageData {
        ...TenderPostage
      }
      paymentData {
        ...TenderPayment
      }
      total {
        ...TenderTotal
      }
      carriers {
        ...TenderCarrier
      }
    }
  }
  ${TenderFragments.total}
  ${TenderFragments.paymentData}
  ${TenderFragments.postageData}
  ${TenderFragments.carriers}
`;

export const TenderProcess = gql`
  mutation TenderProcess($id: String!) {
    tenderProcess(id: $id) {
      orderId
    }
  }
`;

export const TenderApplyIncentives = gql`
  mutation TenderApplyIncentives($id: String!) {
    tenderApplyIncentives(id: $id) {
      items {
        childItems {
          ...TenderChildItems
        }
        ...TenderMainItem
      }
      bonusItems {
        ...TenderMainItem
      }
      incentivesApplied
      carriers {
        ...TenderCarrier
      }
      paymentData {
        ...TenderPayment
      }
      postageData {
        ...TenderPostage
      }
      total {
        ...TenderTotal
      }
    }
  }
  ${TenderFragments.total}
  ${TenderFragments.paymentData}
  ${TenderFragments.postageData}
  ${TenderFragments.items}
  ${TenderFragments.childItems}
  ${TenderFragments.carriers}
`;

export const TenderDelete = gql`
  mutation TenderDelete($id: String!) {
    tenderDelete(id: $id)
  }
`;
