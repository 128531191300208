import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import {
  Category,
  CategorySearchInput,
  CreateCategoryInput,
  UpdateCategoryInput,
} from 'src/app/shared/api/types/GraphQL';
import {
  CategoryCreateResponse,
  CategoryDeleteResponse,
  CategoryListResponse,
  CategoryUpdateResponse,
  SingleCategoryResponse,
} from 'src/app/shared/api/types/types';
import { CategoryNode } from 'src/app/shared/api/types/interfaces';
import {
  CategoryAttributesQuery,
  CategoryDetails,
  OneLevelCategories,
  SingleCategory,
} from 'src/app/shared/api/queries/category-queries';
import {
  CategoryCreate,
  CategoryDelete,
  CategoryDetailsUpdate,
  CategoryUpdate,
} from 'src/app/shared/api/mutations/category-mutations';

@Injectable({ providedIn: 'root' })
export class CategoriesService {
  constructor(private apollo: Apollo) {}

  getRootCategory(variables): QueryRef<SingleCategoryResponse> {
    return this.apollo.watchQuery<SingleCategoryResponse>({
      query: SingleCategory,
      variables,
      fetchPolicy: 'no-cache',
    });
  }

  getCategoryDetails(variables): QueryRef<SingleCategoryResponse> {
    return this.apollo.watchQuery<SingleCategoryResponse>({
      query: CategoryDetails,
      variables,
    });
  }

  getCategoryAttributes(input): QueryRef<SingleCategoryResponse> {
    return this.apollo.watchQuery<SingleCategoryResponse>({
      query: CategoryAttributesQuery,
      variables: { categoryId: input.categoryId, input: input.variables },
      fetchPolicy: 'no-cache',
    });
  }

  getOneLevelCategories(variables: {
    filter?: CategorySearchInput;
    userId?: number;
    appLanguage: string;
  }): QueryRef<CategoryListResponse> {
    return this.apollo.watchQuery<CategoryListResponse>({
      query: OneLevelCategories,
      variables,
    });
  }

  categoryCreate(variables: CreateCategoryInput) {
    return this.apollo.mutate<CategoryCreateResponse>({
      mutation: CategoryCreate,
      variables: { input: variables },
    });
  }

  categoryUpdate(variables: { categoryId: number; input: UpdateCategoryInput; appLanguage: string }) {
    return this.apollo.mutate<CategoryUpdateResponse>({
      mutation: CategoryUpdate,
      variables,
    });
  }

  categoryDetailsUpdate(variables: { categoryId: number; input: UpdateCategoryInput; appLanguage: string }) {
    return this.apollo.mutate<CategoryUpdateResponse>({
      mutation: CategoryDetailsUpdate,
      variables,
    });
  }

  categoryDelete(categoryId: number) {
    return this.apollo.mutate<CategoryDeleteResponse>({
      mutation: CategoryDelete,
      variables: { categoryId },
    });
  }

  transformRoot(category: Category): CategoryNode {
    return (category = {
      ...category,
      categories: category.categories ? this.transformCategoies(category.categories) : [],
      isExpanded: true,
      isLoaded: true,
      hasChildren: !!(category.categories && category.categories.length),
      level: 0,
      loading: false,
    } as CategoryNode);
  }
  transformCategoies(categories: Category[], level: number = 1): CategoryNode[] {
    return categories.map((category) => ({
      ...category,
      isExpanded: false,
      isLoaded: false,
      hasChildren: !!(category.categories && category.categories.length),
      level: level + 1,
      loading: false,
    })) as CategoryNode[];
  }

  transformNodeToCategory(categoryNode: CategoryNode): Category {
    const { isExpanded, isLoaded, hasChildren, level, loading, ...category } = categoryNode;

    return category;
  }
}
