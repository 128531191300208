import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Apollo, QueryRef } from 'apollo-angular';
import {
  AdminUserAssignTenant,
  AdminUserCreate,
  AdminUserDelete,
  AdminUserUpdate,
} from 'src/app/shared/api/mutations/admin-user-mutations';
import {
  AdminUserRoleCreate,
  AdminUserRoleDelete,
  AdminUserRoleUpdate,
} from 'src/app/shared/api/mutations/roles-and-permissions-muations';
import { AdminUser, AdminUserList } from 'src/app/shared/api/queries/admin-user-queries';
import { UserRoles } from 'src/app/shared/api/queries/roles-and-permissions-query';
import { RoleDefinitions } from 'src/app/shared/api/queries/roles-and-permissions-query';
import {
  AdminUsersSearchInput,
  CreateTenantDto,
  CreateUserDto,
  RoleCreateInput,
  RoleDefinitionSearchInput,
  RoleSearchInput,
  RoleUpdateInput,
  UpdateUserDto,
} from 'src/app/shared/api/types/GraphQL';
import {
  AdminUserCreateResponse,
  AdminUserDeleteResponse,
  AdminUserResponse,
  AdminUserTenantCreateResponse,
  AdminUserUpdateResponse,
  AdminUsersResponse,
  RoleCreateResponse,
  RoleDefinitionListResponse,
  RoleDeleteResponse,
  RoleUpdateResponse,
  UserRolesResponse,
} from 'src/app/shared/api/types/types';
import * as fromApp from 'src/app/store/app.reducer';

@Injectable({ providedIn: 'root' })
export class BackofficeUsersService {
  adminTenantID: string;
  overrideHeaders: HttpHeaders;

  constructor(
    private apollo: Apollo,
    private store: Store<fromApp.AppState>,
  ) {
    this.store
      .select((store) => store.auth.adminTenantId)
      .subscribe((tenant) => {
        this.adminTenantID = tenant;
        this.overrideHeaders = new HttpHeaders().set('tenant', this.adminTenantID);
      });
  }

  getAdminUsers(input: AdminUsersSearchInput): QueryRef<AdminUsersResponse> {
    return this.apollo.watchQuery<AdminUsersResponse>({
      query: AdminUserList,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
  }

  getAdminUser(email: string): QueryRef<AdminUserResponse> {
    return this.apollo.watchQuery<AdminUserResponse>({
      query: AdminUser,
      variables: { email },
      fetchPolicy: 'no-cache',
    });
  }

  adminUserUpdate(variables: { email: string; input: UpdateUserDto }) {
    return this.apollo.mutate<AdminUserUpdateResponse>({
      mutation: AdminUserUpdate,
      variables,
      fetchPolicy: 'no-cache',
    });
  }

  adminUserCreate(variables: { input: CreateUserDto }) {
    return this.apollo.mutate<AdminUserCreateResponse>({
      mutation: AdminUserCreate,
      variables,
    });
  }

  adminUserDelete(variables: { email: string }) {
    return this.apollo.mutate<AdminUserDeleteResponse>({
      mutation: AdminUserDelete,
      variables,
    });
  }

  adminUserAssignTenant(input: CreateTenantDto) {
    return this.apollo.mutate<AdminUserTenantCreateResponse>({
      mutation: AdminUserAssignTenant,
      variables: { input },
    });
  }

  getRoleDefinitions(variables: RoleDefinitionSearchInput): QueryRef<RoleDefinitionListResponse> {
    return this.apollo.watchQuery<RoleDefinitionListResponse>({
      query: RoleDefinitions,
      variables,
      context: {
        headers: {
          tenant: this.adminTenantID,
        },
      },
    });
  }

  getUserRoles(input: RoleSearchInput): QueryRef<UserRolesResponse> {
    return this.apollo.watchQuery<UserRolesResponse>({
      query: UserRoles,
      variables: { input },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          tenant: this.adminTenantID,
        },
      },
    });
  }

  adminUserRoleUpdate(variables: { id: string; input: RoleUpdateInput }) {
    return this.apollo.mutate<RoleUpdateResponse>({
      mutation: AdminUserRoleUpdate,
      variables,
      context: {
        headers: {
          tenant: this.adminTenantID,
        },
      },
    });
  }

  adminUserRoleCreate(variables: { input: RoleCreateInput }) {
    return this.apollo.mutate<RoleCreateResponse>({
      mutation: AdminUserRoleCreate,
      variables,
      context: {
        headers: {
          tenant: this.adminTenantID,
        },
      },
    });
  }

  adminUserRoleDelete(variables: { id: string }) {
    return this.apollo.mutate<RoleDeleteResponse>({
      mutation: AdminUserRoleDelete,
      variables,
      context: {
        headers: {
          tenant: this.adminTenantID,
        },
      },
    });
  }
}
