import { Injectable } from '@angular/core';
import { QueryRef } from 'apollo-angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, catchError, map, mergeMap, of, tap } from 'rxjs';
import * as TaxesActions from './taxes.actions';
import { TaxesService } from '../taxes.service';

@Injectable()
export class TaxesEffects {
  query: QueryRef<any>;
  querySub: Observable<Action>;

  constructor(
    private actions: Actions,
    private taxesService: TaxesService,
  ) {}

  public readonly loadTaxes: Observable<Action> = createEffect(() => {
    return this.actions.pipe(
      ofType(TaxesActions.LOAD_TAXES),
      mergeMap((action: TaxesActions.LoadTaxes) => {
        this.query = this.taxesService.getTaxes(action.payload);
        this.querySub = this.query.valueChanges.pipe(
          map((data) => {
            return new TaxesActions.LoadTaxesSuccess({
              items: data.data.taxes.items,
              itemsFound: data.data.taxes.itemsFound,
            });
          }),
          catchError((error) => of(new TaxesActions.LoadTaxesFailure(error))),
        );
        return this.querySub;
      }),
    );
  });

  public readonly refetchUsers = createEffect(
    () =>
      this.actions.pipe(
        ofType(TaxesActions.REFETCH_TAXES),
        tap((action: TaxesActions.RefetchTaxes) => {
          this.query.resetLastResults();
          this.query.refetch(action.payload);
        }),
      ),
    { dispatch: false },
  );
}
