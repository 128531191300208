import { gql } from 'apollo-angular';

export const CategoryFragments = {
  category: gql`
    fragment category on Category {
      categoryId
      categoryPath {
        categoryId
        name(language: $appLanguage) {
          language
          value
        }
      }
      name(language: $appLanguage) {
        language
        value
      }
      parent {
        categoryId
        name(language: $appLanguage) {
          language
          value
        }
      }
    }
  `,

  categoryDetails: gql`
    fragment categoryDetails on Category {
      categoryId
      defaultLanguage
      name {
        language
        value
      }
      parent {
        categoryId
        name {
          language
          value
        }
      }
      description {
        language
        value
      }
      shortDescription {
        language
        value
      }
      slug(language: $appLanguage) {
        value
      }
      categoryPath {
        categoryId
        name(language: $appLanguage) {
          value
        }
      }
    }
  `,
};
