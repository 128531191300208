import { gql } from 'apollo-angular';
import { RuleBuilderFragments } from '../fragments/rule-builder.fragments';

export const BusinessRuleFieldDefinitionsQuery = gql`
  query businessRuleFieldDefinitions($name: String!) {
    businessRuleFieldDefinitions(name: $name) {
      name
      definitions {
        name {
          language
          value
        }
        id
        path
        type
        fields {
          name {
            language
            value
          }
          path
        }
        expressions {
          name {
            language
            value
          }
          path
          type
          fields {
            name {
              language
              value
            }
            path
          }
          expressions {
            name {
              language
              value
            }
            path
            type
            fields {
              name {
                language
                value
              }
              path
            }
            expressions {
              name {
                language
                value
              }
              path
              type
              fields {
                name {
                  language
                  value
                }
                path
              }
            }
          }
        }
      }
    }
  }
`;

export const GetBussinessRule = gql`
  query getBussinesRule($id: ID!) {
    businessRule(id: $id) {
      name {
        language
        value
      }
      ...jdm
    }
  }
  ${RuleBuilderFragments.jdm}
  ${RuleBuilderFragments.expression}
  ${RuleBuilderFragments.array}
  ${RuleBuilderFragments.number}
  ${RuleBuilderFragments.string}
  ${RuleBuilderFragments.date}
  ${RuleBuilderFragments.complex1}
  ${RuleBuilderFragments.complex2}
  ${RuleBuilderFragments.complex3}
  ${RuleBuilderFragments.complex4}
  ${RuleBuilderFragments.complex5}
  ${RuleBuilderFragments.subexpression1}
  ${RuleBuilderFragments.subexpression2}
  ${RuleBuilderFragments.subexpression3}
  ${RuleBuilderFragments.subexpression4}
`;

export const BusinessRulesListQuery = gql`
  query getBussinesRules($filter: BusinessRuleSearchInput) {
    businessRules(filter: $filter) {
      itemsFound
      items {
        id
        name {
          language
          value
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const GetJson = gql`
  query getJson($id: ID!) {
    businessRuleToJDMJson(id: $id)
  }
`;
export const GetTable = gql`
  query getTable($ruleId: String!, $tableId: String!) {
    businessRuleDecisionTable(input: { ruleId: $ruleId, tableId: $tableId }) {
      ...decisionTable
    }
  }
`;
