import { Action } from '@ngrx/store';
import { OrderStatus, OrderStatusesSearchInput } from 'src/app/shared/api/types/GraphQL';

export const GET_CURRENT_PAGE = '[OrderStatus] Get Current Page';
export const GET_CURRENT_PAGE_SIZE = '[OrderStatus] Get Current Page Size';
export const GET_ORDER_STATUS_COUNT = '[OrderStatus] Get OrderStatus Count';
export const LOAD_ORDER_STATUS = '[OrderStatus] Load OrderStatus';
export const REFETCH_ORDER_STATUS = '[OrderStatus] Refetch OrderStatus';
export const LOAD_ORDER_STATUS_SUCCESS = '[OrderStatus] Load OrderStatus Success';
export const LOAD_ORDER_STATUS_FAILURE = '[OrderStatus] Load OrderStatus Failure';

export const ADD_ORDER_STATUS = '[OrderStatus] Add OrderStatus';
export const CREATE_ORDER_STATUS = '[OrderStatus] Create OrderStatus';
export const UPDATE_ORDER_STATUS = '[OrderStatus] Update OrderStatus';
export const DELETE_ORDER_STATUS = '[OrderStatus] Delete OrderStatus';

export const GET_GENERAL_FILTER = '[OrderStatus] Get General Filter';
export const ADD_GENERAL_FILTER = '[OrderStatus] Add General Filter';
export const REMOVE_GENERAL_FILTER = '[OrderStatus] Remove General Filter';

export const ADD_ORDER_STATUS_TO_LIST = '[OrderStatus] Add Selected OrderStatus to List';
export const DELETE_ORDER_STATUS_FROM_LIST = '[OrderStatus] Delete OrderStatus from List';
export const CLEAR_ORDER_STATUS_LIST = '[OrderStatus] Clear OrderStatus List';

export class GetCurrentPage implements Action {
  readonly type = GET_CURRENT_PAGE;

  constructor(public payload: number) {}
}

export class GetCurrentPageSize implements Action {
  readonly type = GET_CURRENT_PAGE_SIZE;

  constructor(public payload: number) {}
}

export class GetOrderStatusCount implements Action {
  readonly type = GET_ORDER_STATUS_COUNT;

  constructor(public payload: number) {}
}

export class LoadOrderStatus implements Action {
  readonly type = LOAD_ORDER_STATUS;

  constructor(public payload: {}) {}
}

export class RefetchOrderStatus implements Action {
  readonly type = REFETCH_ORDER_STATUS;

  constructor(public payload: {}) {}
}

export class LoadOrderStatusSuccess implements Action {
  readonly type = LOAD_ORDER_STATUS_SUCCESS;

  constructor(public payload: OrderStatus[]) {}
}

export class LoadOrderStatusFailure implements Action {
  readonly type = LOAD_ORDER_STATUS_FAILURE;

  constructor(public payload: Error) {}
}

export class AddOrderStatus implements Action {
  readonly type = ADD_ORDER_STATUS;

  constructor(public payload: OrderStatus) {}
}
export class CreateOrderStatus implements Action {
  readonly type = CREATE_ORDER_STATUS;

  constructor(public payload: OrderStatus) {}
}
export class UpdateOrderStatus implements Action {
  readonly type = UPDATE_ORDER_STATUS;

  constructor(public payload: { id: number; updatedOrderStatus: OrderStatus }) {}
}
export class DeleteOrderStatus implements Action {
  readonly type = DELETE_ORDER_STATUS;

  constructor(public payload: OrderStatus) {}
}

export class AddOrderStatusToList implements Action {
  readonly type = ADD_ORDER_STATUS_TO_LIST;

  constructor(public payload: OrderStatus) {}
}

export class DeleteOrderStatusFromList implements Action {
  readonly type = DELETE_ORDER_STATUS_FROM_LIST;

  constructor(public payload: number) {}
}

export class ClearOrderStatusList implements Action {
  readonly type = CLEAR_ORDER_STATUS_LIST;
}

export class GetGeneralFilter implements Action {
  readonly type = GET_GENERAL_FILTER;

  constructor(public payload: string) {}
}
export class AddGeneralFilter implements Action {
  readonly type = ADD_GENERAL_FILTER;

  constructor(public payload: OrderStatusesSearchInput | any) {}
}

export class RemoveGeneralFilter implements Action {
  readonly type = REMOVE_GENERAL_FILTER;

  constructor(public payload: { columnName: string; index?: number }) {}
}

export type OrderStatusActions =
  | GetGeneralFilter
  | AddGeneralFilter
  | RemoveGeneralFilter
  | GetCurrentPage
  | GetCurrentPageSize
  | GetOrderStatusCount
  | LoadOrderStatus
  | RefetchOrderStatus
  | LoadOrderStatusSuccess
  | LoadOrderStatusFailure
  | AddOrderStatus
  | UpdateOrderStatus
  | DeleteOrderStatus
  | AddOrderStatusToList
  | DeleteOrderStatusFromList
  | ClearOrderStatusList
  | CreateOrderStatus;
