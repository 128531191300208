import { Order, OrderSearchArguments } from 'src/app/shared/api/types/GraphQL';
import { TableColumns } from 'src/app/shared/api/types/interfaces';
import * as RequestsActions from './request.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  requests: Order[];
  rejected: Order[];
  currentPage: number;
  currentPageRejected: number;
  currentPageSize: number;
  currentPageSizeRejected: number;
  requestsCount: number;
  rejectedCount: number;
  error: Error;
  columnFilterValues: {
    name: string;
    sku: string;
  };
  multiselectFilterValues: {
    manufacturer: string[];
    supplier: string[];
  };
  numberOfRequestFiltersSelected: number;
  numberOfRejectFiltersSelected: number;
  generalFilterValue: string;
  selectedRequestsList: Order[];
  productTableColumns: TableColumns;
  ordersTableColumns: TableColumns;
  requestFilters: OrderSearchArguments;
  rejectedFilters: OrderSearchArguments;
}

const initialState: State = {
  loading: false,
  loaded: false,
  requests: [],
  rejected: [],
  currentPage: 1,
  currentPageRejected: 1,
  currentPageSize: 5,
  currentPageSizeRejected: 5,
  requestsCount: 0,
  rejectedCount: 0,
  error: undefined,
  columnFilterValues: {
    name: '',
    sku: '',
  },
  multiselectFilterValues: {
    manufacturer: [],
    supplier: [],
  },
  numberOfRequestFiltersSelected: 0,
  numberOfRejectFiltersSelected: 0,
  generalFilterValue: '',
  selectedRequestsList: [],
  productTableColumns: {
    displayedColumns: [
      'image',
      'sku',
      'name',
      'supplier',
      'listPrice',
      'customerPrice',
      'discount',
      'costPrice',
      'salesDiscount',
      'salesPrice',
      'saleMargin',
      'totalDiscount',
      'quantity',
      'total',
      'defaultMargin',
      'attributedDiscount',
      'attributedMargin',
    ],
    hiddenColumns: [],
    disabledSuffixLength: 0,
    availableColumns: [],
    disabledColumns: ['sku', 'name'],
  },
  ordersTableColumns: {
    displayedColumns: [
      'id',
      'company',
      'status',
      'debtorId',
      'source',
      'total',
      'itemsCount',
      'dateCreated',
      'statusDate',
    ],
    hiddenColumns: [],
    disabledSuffixLength: 0,
    availableColumns: [],
    disabledColumns: ['id', 'company'],
  },
  requestFilters: {},
  rejectedFilters: {},
};

export function requestsReducer(state: State = initialState, action: RequestsActions.RequestsActions) {
  switch (action.type) {
    case RequestsActions.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case RequestsActions.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case RequestsActions.GET_CURRENT_PAGE_SIZE:
      return {
        ...state,
        currentPageSize: action.payload,
      };
    case RequestsActions.GET_CURRENT_PAGE_SIZE_REJECTED:
      return {
        ...state,
        currentPageSizeRejected: action.payload,
      };
    case RequestsActions.GET_REQUESTS_COUNT:
      return {
        ...state,
        requestsCount: action.payload,
      };
    case RequestsActions.GET_REQUESTS_COUNT_REJECTED:
      return {
        ...state,
        rejectedCount: action.payload,
      };
    case RequestsActions.LOAD_REQUESTS:
      return {
        ...state,
        loading: true,
      };
    case RequestsActions.LOAD_REJECTED:
      return {
        ...state,
        loading: true,
      };
    case RequestsActions.REFETCH_REQUESTS:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case RequestsActions.REFETCH_REJECTED:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case RequestsActions.LOAD_REQUESTS_SUCCESS:
      return {
        ...state,
        requests: [...action.payload],
        loading: false,
        loaded: true,
      };
    case RequestsActions.LOAD_REJECTED_SUCCESS:
      return {
        ...state,
        rejected: [...action.payload],
        loading: false,
        loaded: true,
      };
    case RequestsActions.LOAD_REQUESTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case RequestsActions.LOAD_REJECTED_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case RequestsActions.ADD_REQUEST:
      let requestsList = [...state.requests];
      let requestsCount = state.requestsCount;

      const requestsIndex = requestsList.findIndex((obj) => obj.id < action.payload.id);
      if (requestsIndex !== -1) {
        requestsList.splice(requestsIndex, 0, action.payload);
        if (requestsList.length > state.currentPageSizeRejected) requestsList.pop();
      } else if (requestsIndex === -1 && requestsList.length < state.currentPageSizeRejected)
        requestsList.push(action.payload);

      requestsCount += 1;

      return {
        ...state,
        requests: requestsList,
        requestsCount,
      };
    case RequestsActions.ADD_REJECTED:
      let rejectedList = [...state.rejected];
      let rejectedCount = state.rejectedCount;

      const rejectedIndex = rejectedList.findIndex((obj) => obj.id < action.payload.id);
      if (rejectedIndex !== -1) {
        rejectedList.splice(rejectedIndex, 0, action.payload);
        if (rejectedList.length > state.currentPageSizeRejected) rejectedList.pop();
      } else if (rejectedIndex === -1 && rejectedList.length < state.currentPageSizeRejected)
        rejectedList.push(action.payload);

      rejectedCount += 1;

      return {
        ...state,
        rejected: rejectedList,
        rejectedCount,
      };
    case RequestsActions.UPDATE_REQUEST:
      const index = state.requests.map((x) => x.id).indexOf(action.payload.id);
      const updatedRequest = {
        ...state.requests[index],
        ...action.payload.updatedRequest,
      };
      const updatedRequests = [...state.requests];
      updatedRequests[index] = updatedRequest;
      return {
        ...state,
        requests: updatedRequests,
      };
    case RequestsActions.DELETE_REQUEST:
      if (state.requests.map((x) => x.id).indexOf(action.payload) !== -1) {
        let requestsCount = state.requestsCount;
        const index = state.requests.map((x) => x.id).indexOf(action.payload);

        requestsCount -= 1;
        return {
          ...state,
          requests: state.requests.filter((request, i) => {
            return i !== index;
          }),
          requestsCount,
        };
      } else {
        return {
          ...state,
        };
      }
    case RequestsActions.ADD_REQUEST_TO_LIST:
      if (state.selectedRequestsList.map((x) => x.id).indexOf(action.payload.id) === -1) {
        return {
          ...state,
          selectedRequestsList: [...state.selectedRequestsList, action.payload],
        };
      } else {
        return {
          ...state,
        };
      }
    case RequestsActions.DELETE_REQUEST_FROM_LIST:
      if (state.selectedRequestsList.map((x) => x.id).indexOf(action.payload) !== -1) {
        const index = state.selectedRequestsList.map((x) => x.id).indexOf(action.payload);
        return {
          ...state,
          selectedRequestsList: state.selectedRequestsList.filter((product, i) => {
            return i !== index;
          }),
        };
      } else {
        return {
          ...state,
        };
      }
    case RequestsActions.CLEAR_REQUESTS_LIST:
      return {
        ...state,
        selectedRequestsList: [],
      };
    case RequestsActions.GET_COLUMN_FILTERS:
      let numOfFilters = state.numberOfRequestFiltersSelected;
      if (action.payload.filterValue === '' && state.columnFilterValues[action.payload.columnName] !== '') {
        numOfFilters -= 1;
      } else if (action.payload.filterValue !== '' && state.columnFilterValues[action.payload.columnName] === '') {
        numOfFilters += 1;
      }
      return {
        ...state,
        columnFilterValues: {
          ...state.columnFilterValues,
          [action.payload.columnName]: action.payload.filterValue,
        },
        numberOfFiltersSelected: numOfFilters,
      };
    case RequestsActions.GET_MULTISELECT_REQUEST_FILTERS:
      let payloadCreatedAt =
        action.payload.createdAt?.greaterThan !== undefined && action.payload.createdAt?.lessThan !== undefined;
      let payloadUpdatedAt =
        action.payload.lastModifiedAt?.greaterThan !== undefined &&
        action.payload.lastModifiedAt.lessThan !== undefined;
      let payloadPrice =
        action.payload.price?.greaterThan !== undefined || action.payload.price?.lessThan !== undefined;
      let payloadLength =
        (action.payload.type ? 1 : 0) +
        (payloadCreatedAt ? 1 : 0) +
        (payloadUpdatedAt ? 1 : 0) +
        (action.payload.userId !== undefined ? action.payload.userId.length : 0) +
        (action.payload.status?.length !== 1 ? 1 : 0) +
        (payloadPrice ? 1 : 0);
      return {
        ...state,
        requestFilters: {
          ...state.requestFilters,
          userId: action.payload.userId,
          status: action.payload.status,
          type: action.payload.type,
          createdAt: action.payload.createdAt,
          lastModifiedAt: action.payload.lastModifiedAt,
          price: action.payload.price,
          sortInputs: action.payload.sortInputs,
        },
        numberOfRequestFiltersSelected: payloadLength,
      };

    case RequestsActions.GET_MULTISELECT_REJECT_FILTERS:
      let payloadRejectCreatedAt =
        action.payload.createdAt?.greaterThan !== undefined && action.payload.createdAt?.lessThan !== undefined;
      let payloadRejectUpdatedAt =
        action.payload.lastModifiedAt?.greaterThan !== undefined &&
        action.payload.lastModifiedAt.lessThan !== undefined;
      let payloadRejectPrice =
        action.payload.price?.greaterThan !== undefined || action.payload.price?.lessThan !== undefined;
      let payloadRejectLength =
        (action.payload.type ? 1 : 0) +
        (payloadRejectCreatedAt ? 1 : 0) +
        (payloadRejectUpdatedAt ? 1 : 0) +
        (action.payload.userId !== undefined ? action.payload.userId.length : 0) +
        (action.payload.status.length !== 1 ? 1 : 0) +
        (payloadRejectPrice ? 1 : 0);
      return {
        ...state,
        rejectedFilters: {
          ...state.rejectedFilters,
          userId: action.payload.userId,
          status: action.payload.status,
          type: action.payload.type,
          createdAt: action.payload.createdAt,
          lastModifiedAt: action.payload.lastModifiedAt,
          price: action.payload.price,
          sortInputs: action.payload.sortInputs,
        },
        numberOfRejectFiltersSelected: payloadRejectLength,
      };
    case RequestsActions.GET_GENERAL_FILTER:
      return {
        ...state,
        generalFilterValue: action.payload,
      };
    case RequestsActions.ADD_GENERAL_FILTER_REQUEST:
      return {
        ...state,
        requestFilters: { ...state.requestFilters, ...action.payload },
      };
    case RequestsActions.ADD_GENERAL_FILTER_REJECT:
      return {
        ...state,
        rejectedFilters: { ...state.rejectedFilters, ...action.payload },
      };
    case RequestsActions.CLEAR_ALL_FILTERS:
      return {
        ...state,
        columnFilterValues: {
          name: '',
          sku: '',
        },
        multiselectFilterValues: {
          manufacturer: [],
          supplier: [],
        },
        generalFilterValue: '',
        numberOfFiltersSelected: 0,
      };
    case RequestsActions.PRODUCT_TABLE_COLUMNS:
      return {
        ...state,

        productTableColumns: action.payload,
      };
    case RequestsActions.ORDERS_TABLE_COLUMNS:
      return {
        ...state,
        ordersTableColumns: action.payload,
      };
    default:
      return state;
  }
}
