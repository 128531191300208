import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'content',
})
export class ContentPipe implements PipeTransform {
  transform(column: any, element: any): any {
    if (!element || !column || !column.content) {
      return null;
    }
    return column.content(element);
  }
}
