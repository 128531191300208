import { Warehouse } from 'src/app/shared/api/types/GraphQL';
import * as WarehousesActions from './warehouses.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  warehouses: Warehouse[];
  currentPage: number;
  currentPageSize: number;
  warehousesCount: number;
  error: Error;
  generalFilterValue: string;
  activeTab: number;
  selectedWarehousesList: Warehouse[];
  warehousesTableColumns: {
    displayedColumns: string[];
    hiddenColumns: string[];
    disabledSuffixLength: number;
    availableColumns: string[];
    disabledColumns: string[];
  };
}

const initialState: State = {
  loading: false,
  loaded: false,
  warehouses: [],
  currentPage: 1,
  currentPageSize: 5,
  warehousesCount: 0,
  error: undefined,
  generalFilterValue: '',
  activeTab: 0,
  selectedWarehousesList: [],
  warehousesTableColumns: {
    displayedColumns: ['select', 'id', 'name', 'isActive', 'isStore', 'isPickupLocation', 'dateCreated', 'dateUpdated'],
    hiddenColumns: ['select'],
    disabledSuffixLength: 0,
    availableColumns: ['select'],
    disabledColumns: ['name'],
  },
};

export function warehousesReducer(state: State = initialState, action: WarehousesActions.WarehousesActions) {
  switch (action.type) {
    case WarehousesActions.GET_GENERAL_FILTER:
      return {
        ...state,
        generalFilterValue: action.payload,
      };
    case WarehousesActions.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case WarehousesActions.GET_CURRENT_PAGE_SIZE:
      return {
        ...state,
        currentPageSize: action.payload,
      };
    case WarehousesActions.GET_WAREHOUSES_COUNT:
      return {
        ...state,
        warehousesCount: action.payload,
      };
    case WarehousesActions.LOAD_WAREHOUSES:
      let loading;
      state.loaded ? (loading = false) : (loading = true);
      return {
        ...state,
        loading: loading,
      };
    case WarehousesActions.REFETCH_WAREHOUSES:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case WarehousesActions.LOAD_WAREHOUSES_SUCCESS:
      return {
        ...state,
        warehouses: [...action.payload],
        loading: false,
        loaded: true,
      };
    case WarehousesActions.LOAD_WAREHOUSES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case WarehousesActions.ADD_WAREHOUSE:
      return {
        ...state,
        warehouses: [...state.warehouses, action.payload],
      };
    case WarehousesActions.ADD_WAREHOUSE_TO_LIST:
      if (state.selectedWarehousesList.map((x) => x.id).indexOf(action.payload.id) === -1) {
        return {
          ...state,
          selectedWarehousesList: [...state.selectedWarehousesList, action.payload],
        };
      } else {
        return {
          ...state,
        };
      }
    case WarehousesActions.DELETE_WAREHOUSE_FROM_LIST:
      if (state.selectedWarehousesList.map((x) => x.id).indexOf(action.payload) !== -1) {
        const index = state.selectedWarehousesList.map((x) => x.id).indexOf(action.payload);
        return {
          ...state,
          selectedWarehousesList: state.selectedWarehousesList.filter((item, i) => {
            return i !== index;
          }),
        };
      } else {
        return {
          ...state,
        };
      }
    case WarehousesActions.CLEAR_WAREHOUSES_LIST:
      return {
        ...state,
        selectedWarehousesList: [],
      };
    case WarehousesActions.WAREHOUSES_TABLE_COLUMNS:
      return {
        ...state,
        warehousesTableColumns: action.payload,
      };

    default:
      return state;
  }
}
