import { Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { concatMap, filter, forkJoin, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import { Action, select } from '@ngrx/store';
import { Store } from '@ngrx/store';
import { Sort, MatSort } from '@angular/material/sort';

import { DialogButtonsText, ListingTypes, TableColumns, UserTypesPlural } from '../../api/types/enums';

import {
  FavoriteList,
  Tax,
  Valueset,
  ValuesetItem,
  Orderlist,
  Surcharge,
  PayMethod,
  Carrier,
  AdminUser,
  AttributeDescription,
  Company,
  Contact,
  Customer,
  OrderStatus,
  OrderStatusSet,
  Pricesheet,
} from '../../api/types/GraphQL';

import { SnackBarService } from '../snack-bar/snack-bar.service';
import { CheckBoxService } from '../../services/checkbox-service';

import * as FavoritesActions from '../../../crm/favorites/store/favorites.actions';
import * as TaxesActions from '../../../pricing/taxes/store/taxes.actions';
import * as OrderlistsActions from '../../../crm/orderlists/store/orderlists.actions';
import * as SurchargesActions from '../../../pricing/surcharges/store/surcharges.actions';
import * as ValueSetActions from 'src/app/admin/value-set/store/value-set/value-set.actions';
import * as ValueSetItemActions from 'src/app/admin/value-set/store/value-set-item/value-set-item.actions';
import * as OrderStatusActions from '../../../order-management/order-status/store/order-status/order-status.actions';
import * as OrderStatusSetActions from '../../../order-management/order-status/store/order-status-set/order-status-set.actions';

import * as PayMethodsActions from 'src/app/order-management/paymethods/store/paymethods.actions';
import * as CarriersActions from 'src/app/order-management/carriers/store/carriers.actions';

import * as BackofficeUsersActions from 'src/app/admin/backoffice-users/store/backoffice-users.actions';
import * as AttributeDescriptionsActions from 'src/app/admin/attributes/store/attributes.actions';
import * as TableColumnsActions from '../../store/table-columns-store/table-columns.actions';
import * as UsersActions from '../../../crm/users/store/users/users.actions';
import * as PriceSheetActions from '../../../pricing/pricing/pricesheets/store/price-sheet.actions';

import * as fromApp from '../../../store/app.reducer';

import { TableEditorDialogService } from '../../services/table-editor-dialog.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GqlErrorService } from '../../services/gql-error.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatTableDataSource } from '@angular/material/table';
import { ValueSetService } from 'src/app/admin/value-set/value-set.service';
import { fadeInAnimation } from '../../animations';
import { AttributesService } from 'src/app/admin/attributes/attributes.service';
import { GoToPageComponent } from '../go-to-page/go-to-page.component';

type SharedListingType =
  | Tax
  | FavoriteList
  | Valueset
  | ValuesetItem
  | Orderlist
  | OrderStatus
  | OrderStatusSet
  | Surcharge
  | PayMethod
  | Carrier
  | AdminUser
  | AttributeDescription
  | Company
  | Contact
  | Customer
  | Pricesheet;

export interface TableColumn {
  name: string;
  label: string;
  sortable?: boolean;
  sortField?: string;
  additionalSortField?: string;
  type?: TableColumnType;
  content: (element: SharedListingType) => any;
}

export enum TableColumnType {
  date = 'date',
  html = 'html',
  icon = 'icon',
  array = 'array',
}

interface Variables {
  input: {
    storeFilters?: any;
    additionalQueryVariables?: any;
    sortInputs?: any;
    page: number;
    offset: number;
  };
}

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss'],
  animations: [fadeInAnimation],
})
export class ListingComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() itemsPerPage: number;
  @Input() page: number;
  @Input() dataSource: SharedListingType[];
  @Input() displayedColumns: TableColumn[];
  @Input() listingType: ListingTypes;
  @Input() stateEntry: any;
  @Input() selectedTab?: any;
  @Input() tab: string;
  @Input() itemsCount: Observable<number>;
  @Input() showPageSizeOptions: boolean = true;
  @Input() isDragEnabled: boolean = true;
  @Input() selectedItems: SharedListingType[];
  @Input() showSelectedItems: boolean;
  @Input() loading: Observable<boolean>;
  @Input() deleteItem: (item: SharedListingType) => void;
  @Input() updateItemPriority: (key, priority) => void;
  @Input() shouldShowDeleteBtn?: boolean = true;
  @Input() canAddTags: boolean = false;
  @Input() isDialog?: boolean = false;
  @Input() singleValueSelect: boolean = false;
  @Input() additionalSelectedItemsActions?: {
    label: string;
    action: () => void;
  }[] = []; // other than deleteItem

  @Input() actionItems?: {
    label: string;
    icon: string;
    type: string;
    action: () => void;
  }[] = [];
  actionsColumnPresent: boolean = false;

  @Input() additionalQueryVariables?: any; // other than page and offset
  @Input() navigateToDetails: (row: any) => void;
  @Input() isInPicker: boolean = false;
  @Input() isViewer: boolean;
  @Input() showCheckbox: boolean = true;
  @Input() handleRowAction: (row: any) => void;
  @Input() handleRow: boolean = false;

  // Updates checkboxService with new presetSelectedList value
  @Input() set presetSelectedList(value: SharedListingType[]) {
    this.checkboxService.presetSelectedList = value;
  }
  @Input() totalPages: Observable<number>;

  @Input() tooltipMessage: string = '';
  @Input() shouldShowTooltip: boolean = false;

  isGoToPageChange: boolean;
  error: string = '';
  dataSourceLoading: MatTableDataSource<{}> = new MatTableDataSource(Array.from({ length: 5 }, () => ({})));

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('goToPageComponent') goToPageComponent: GoToPageComponent;

  tableEditorColumns: Observable<any>;

  tableColumnsObj: {
    displayedColumns: string[];
    hiddenColumns: string[];
    availableColumns: string[];
    disabledColumns: string[];
    disabledSuffixLength: number;
  };
  sortInputs: any;
  storeFilters: any;
  dragDisabled: boolean = true;
  generateListingTypeLabel: string;
  destroySubject: Subject<void> = new Subject();

  TableColumnType = TableColumnType;
  ListingTypes = ListingTypes;

  constructor(
    public checkboxService: CheckBoxService,
    private store: Store<fromApp.AppState>,
    private snackBarService: SnackBarService,
    private tableEditorService: TableEditorDialogService,
    private errorService: GqlErrorService,
    private dialog: MatDialog,
    public sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<any>,
    public valuesetSerice: ValueSetService,
    public attributesService: AttributesService,
  ) {}

  ngOnInit() {
    if (this.stateEntry && this.tab) {
      this.tableEditorColumns = this.store.select(
        (store) => store.tableColumns[this.stateEntry][`${this.listingType}TableColumns`][this.tab],
      );
    } else if (this.stateEntry) {
      this.tableEditorColumns = this.store.select(
        (store) => store.tableColumns[this.stateEntry][`${this.listingType}TableColumns`],
      );
    } else if (this.selectedTab) {
      this.tableEditorColumns = this.store.select(
        (store) => store.tableColumns[`${this.listingType}TableColumns`][this.selectedTab],
      );
    } else {
      this.tableEditorColumns = this.store.select((state) => state.tableColumns[`${this.listingType}TableColumns`]);
    }

    this.tableEditorColumns.pipe(takeUntil(this.destroySubject)).subscribe((tableColumns) => {
      const hasSelectColumn = tableColumns.displayedColumns.includes('select');
      if (!hasSelectColumn) {
        tableColumns.displayedColumns.unshift('select');
      }
      this.tableColumnsObj = tableColumns;
    });
    if (this.displayedColumns.find((column) => column.name === 'actions')) {
      //this.actionsColumnPresent = true;
    }
    this.generateListingTypeLabel = this.getCustomTextForListingType(this.listingType);
    this.calculateTotalPages();
  }

  ngAfterViewInit() {
    // Subscribe to the store to get filters
    this.selectFilters(this.listingType, 'Filters')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((object: any) => {
        // Check if object exists
        if (object) {
          // If object exists, filter out empty values and assign to storeFilters
          this.storeFilters = this.filterOutEmptyValues(object);
        } else {
          // If object does not exist, assign undefined to storeFilters
          this.storeFilters = undefined;
        }
      });
    this.paginator.page.subscribe({
      next: (page: PageEvent) => this.handlePageChange(page),
      error: (error) => this.handleError(error),
    });

    setTimeout(() => {
      this.paginator.pageIndex = this.page - 1;
    });
  }

  private handlePageChange(page: PageEvent) {
    const { getCurrentPageSize, getCurrentPage, refetch, refetchSearchCompany } = this.actionsMapper[this.listingType];
    if (this.itemsPerPage !== page.pageSize) {
      this.itemsPerPage = page.pageSize;
      this.store.dispatch(getCurrentPageSize());
      this.calculateTotalPages();
      this.goToPageComponent.resetPage();
    }
    if (!this.isGoToPageChange) {
      this.goToPageComponent.resetPage();
    }

    this.page = page.pageIndex + 1;
    this.store.dispatch(getCurrentPage());
    let variables = {
      input: {
        ...this.additionalQueryVariables,
        ...this.sortInputs,
        ...this.storeFilters,
        page: this.page,
        offset: this.itemsPerPage,
      },
    };
    const dispatchRefetch = (action, input) => {
      this.store.dispatch(action(input ? input : variables));
    };

    if (refetchSearchCompany) {
      const hasStoreFilters = Object.keys(this.storeFilters).length > 0;
      const actionToDispatch = hasStoreFilters ? refetchSearchCompany : refetch;
      const inputToDispatch = hasStoreFilters ? variables.input : variables;
      dispatchRefetch(actionToDispatch, inputToDispatch);
    } else {
      this.store.dispatch(refetch(variables));
    }
    this.isGoToPageChange = false;
  }

  private handleError(error: any) {
    this.error = error?.graphQLErrors?.[0]?.message;
    this.snackBarService.openSnackBar(this.error, '', 'error');
  }

  onGoToPage(pageToGo: number) {
    this.isGoToPageChange = true;
    this.page = pageToGo;
    const pageEvent: PageEvent = {
      pageIndex: pageToGo - 1,
      pageSize: this.itemsPerPage,
      length: this.paginator.length,
    };
    this.paginator.pageIndex = pageEvent.pageIndex;
    this.paginator._changePageSize(this.itemsPerPage);
  }

  calculateTotalPages() {
    this.itemsCount.subscribe((total) => {
      this.totalPages = of(Math.ceil(total / this.itemsPerPage));
    });
  }

  // Define a function to dynamically select the property from the store
  selectFilters(listingType: ListingTypes, filterSuffix: string): Observable<any> {
    if (this.stateEntry && this.tab) {
      return this.store.pipe(
        select((state) => state[listingType][this.stateEntry][listingType][this.tab]), // Select the part of the state corresponding to listingType
        switchMap((result) => {
          const filterKey = Object.keys(result).find((key) => key.toLowerCase().endsWith('filters'));
          if (!filterKey) {
            throw new Error(`Filter key ending with 'filters' not found for ${listingType}`);
          }
          return this.store.pipe(
            select((state) => state[listingType][this.stateEntry][listingType][this.tab]), // Select the part of the state corresponding to listingType
            filter((listingTypeState) => !!listingTypeState), // Filter out undefined values
            select((listingTypeState) => listingTypeState[filterKey]),
            filter((filter) => !!filter), // Filter out undefined property
          );
        }),
      );
    } else {
      return this.store.pipe(
        select((state) => state[listingType]), // Select the part of the state corresponding to listingType
        filter((listingTypeState) => !!listingTypeState), // Filter out undefined values
        select((listingTypeState) => listingTypeState[listingType + filterSuffix]), // Select the desired property
        filter((filter) => !!filter), // Filter out undefined property
      );
    }
  }

  // Function to filter out empty values from an object
  filterOutEmptyValues(object) {
    return Object.fromEntries(
      Object.entries(object).filter(([key, value]) => {
        return this.isNotEmpty(value);
      }),
    );
  }

  isNotEmpty(value) {
    if (value == null) {
      return false;
    }
    if (typeof value === 'string' || Array.isArray(value)) {
      return value.length > 0;
    }
    if (typeof value === 'object') {
      return Object.keys(value).length > 0;
    }
    return true;
  }

  onRowClick(row: any): void {
    if (!this.actionsColumnPresent && !this.isDialog && !this.isInPicker) {
      this.navigateToDetails(row);
    } else if ((this.isDialog && this.listingType === ListingTypes.valueSet) || this.singleValueSelect) {
      this.closeDialogWithElement(row);
    }

    // Add your new logic here
    if (this.handleRow && !this.actionsColumnPresent) {
      this.handleRowAction(row);
    }
  }

  openTableEditor() {
    this.tableEditorService.open(this.tableColumnsObj);

    this.tableEditorService.confirmed().subscribe((data) => {
      if (data !== false) {
        const tableColumnsPayload = {
          displayedColumns: data.displayedColumns,
          availableColumns: data.availableColumns,
          hiddenColumns: data.hiddenColumns,
          disabledColumns: data.disabledColumns,
          disabledSuffixLength: data.disabledSuffixLength,
        };
        const { tableColumnsEdit } = this.actionsMapper[this.listingType];
        this.store.dispatch(tableColumnsEdit(tableColumnsPayload));
      }
    });
  }
  getCustomTextForListingType(listingType: ListingTypes): string {
    switch (listingType) {
      case ListingTypes.attributeDescriptions:
        return 'Attributes';
      case ListingTypes.adminUsers:
        return 'Backoffice users';
      case ListingTypes.valueSet:
        return 'Valuesets';
      case ListingTypes.valueSetItem:
        return 'Valueset Items';
      case ListingTypes.orderStatus:
        return 'Order Statuses';
      case ListingTypes.orderStatusSet:
        return 'Order Status Sets';
      case ListingTypes.users:
        return this.capitalizeFirstLetter(this.tab);
      default:
        return this.capitalizeFirstLetter(listingType.toLowerCase());
    }
  }

  deleteMultipleItems(callback: (completed: boolean) => void) {
    const dialogRef = this.openDeleteConfirmationDialog();
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult === DialogButtonsText.CONFIRM) {
        this.performDeletions(callback);
      } else {
        callback(false); // Indicate deletion canceled via callback
      }
    });
  }

  private openDeleteConfirmationDialog(): MatDialogRef<ConfirmDialogComponent> {
    let tabName;
    switch (this.listingType) {
      case ListingTypes.taxes:
        tabName = this.listingType.replace(/es\b/, '(es)');
        break;
      case ListingTypes.users:
        tabName = this.tab.replace(/s\b/, '(s)');
        break;
      case ListingTypes.orderStatus:
        tabName = this.generateListingTypeLabel.replace(/es\b/, '(es)');
        tabName = tabName.toLowerCase();
        break;
      case ListingTypes.orderStatusSet:
        tabName = this.generateListingTypeLabel.replace(/ts\b/, 't(s)');
        tabName = tabName.toLowerCase();
        break;
      case ListingTypes.valueSet:
        tabName = this.generateListingTypeLabel.replace(/s\b/, '(s)');
        break;
      case ListingTypes.valueSetItem:
        tabName = this.generateListingTypeLabel.replace(/s\b/, '(s)');
        tabName = tabName.toLowerCase();
        break;
      case ListingTypes.adminUsers:
        tabName = this.generateListingTypeLabel.replace(/s\b/, '(s)');
        tabName = tabName.toLowerCase();
        break;
      default:
        tabName = this.listingType.replace(/s\b/, '(s)');
        break;
    }

    const dialogData = new ConfirmDialogModel(
      'Are you sure?',
      `Are you sure you want to delete selected ${tabName} ? `,
      'Delete',
    );

    return this.dialog.open(ConfirmDialogComponent, {
      width: '324px',
      position: { top: '136px' },
      data: dialogData,
      panelClass: ['confirm-dialog', 'warning', 'dialog'],
      disableClose: true,
    });
  }

  private performDeletions(callback: (completed: boolean) => void): void {
    let successfulDeletions = 0;
    let failedDeletions = 0;

    const deletionObservables: any = this.selectedItems.map((item: SharedListingType) => {
      return this.deleteItem(item);
    });

    forkJoin(deletionObservables)
      .pipe(
        concatMap((deletionResults: any) => {
          deletionResults.forEach((result: any) => {
            const deleteKey = Object.keys(result).find((key) => key.toLowerCase().endsWith('delete'));
            if (deleteKey && !!result[deleteKey] === true) {
              successfulDeletions++;
            } else {
              failedDeletions++;
            }
          });

          this.clearSelectionAndHideItems();
          // Note: Retaining the loader timeout is necessary because the backend's delete process for company contacts and customers takes a significant amount of time.
          // When we refetch data immediately, we may receive the same response without the deleted item.
          let loaderTimeout = 0;
          if (this.listingType === ListingTypes.users) {
            loaderTimeout = 3000; // 3 second
            this.loading = of(true); // Start loading
          }
          return new Observable((observer) => {
            setTimeout(() => {
              const refetchAction = this.actionsMapper[this.listingType]?.refetch;

              if (refetchAction) {
                const refetchActionInstance = refetchAction({});
                this.store.dispatch(refetchActionInstance);
                if (this.listingType === ListingTypes.users) {
                  this.loading = of(false); // Stop loading
                }
                observer.next(null);
                observer.complete();
              }
            }, loaderTimeout);
          });
        }),
      )
      .subscribe({
        next: () => {
          this.displaySnackbarMessage(this.listingType, successfulDeletions, failedDeletions);
          callback(true);
        },
        error: (deletionError) => {
          this.errorService.getGqlError(deletionError);
          callback(false);
        },
      });
  }

  deleteMultipleItemsCallback() {
    this.deleteMultipleItems((completed: boolean) => {
      if (completed) {
        // Handle deletion completion
      } else {
        // Handle deletion cancellation or failure
      }
    });
  }

  sortColumn(sortState: Sort) {
    const activeColumn = this.displayedColumns.find((column) => column.name == sortState.active);
    if (activeColumn) {
      const sortField = activeColumn.sortField;
      const additionalSortField = activeColumn.additionalSortField;

      const { getCurrentPage, refetch, applyFilter, removeFilter, refetchSearchCompany } =
        this.actionsMapper[this.listingType];
      let hasStoreFilters;
      if (refetchSearchCompany && this.storeFilters) {
        hasStoreFilters = Object.keys(this.storeFilters).length > 0;
      }
      if (this.listingType === ListingTypes.users) {
        if (hasStoreFilters) {
          this.sortInputs = {
            sortInputs: {
              field: additionalSortField,
              order: sortState.direction.toUpperCase(),
            },
          };
        } else {
          this.sortInputs = {
            sort: {
              field: sortField,
              order: sortState.direction.toUpperCase(),
            },
          };
        }
      } else if (this.listingType === ListingTypes.attributeDescriptions) {
        this.sortInputs = {
          sortInputs: {
            field: sortField,
            // Delete this if the sortInputs property for attributeDescriptions is changed to order
            sortInputs: sortState.direction.toUpperCase(),
          },
        };
      } else {
        this.sortInputs = {
          sortInputs: {
            field: sortField,
            order: sortState.direction.toUpperCase(),
          },
        };
      }
      let variables: Variables;
      if (sortState.direction) {
        variables = {
          input: {
            ...this.storeFilters,
            ...this.additionalQueryVariables,
            ...this.sortInputs,
            page: this.page,
            offset: this.itemsPerPage,
          },
        };
      } else {
        if (removeFilter) this.store.dispatch(removeFilter({ columnName: 'sortInputs' }));
        this.sortInputs = {};
        variables = {
          input: {
            ...this.storeFilters,
            ...this.additionalQueryVariables,
            ...this.sortInputs,
            page: this.page,
            offset: this.itemsPerPage,
          },
        };
      }
      if (applyFilter) {
        this.store.dispatch(applyFilter(this.sortInputs));
      }
      // Determine which refetch action to dispatch
      const dispatchRefetch = (action, input) => {
        this.store.dispatch(action(input ? input : variables));
      };
      if (this.page > 1) {
        this.paginator.firstPage();
      } else {
        if (refetchSearchCompany) {
          const actionToDispatch = hasStoreFilters ? refetchSearchCompany : refetch;
          const inputToDispatch = hasStoreFilters ? variables.input : variables;
          dispatchRefetch(actionToDispatch, inputToDispatch);
        } else {
          this.store.dispatch(refetch(variables));
        }
        this.store.dispatch(getCurrentPage());
      }
    }
  }
  clearSortState() {
    if (this.sort) this.sort.sort({ id: '', start: 'asc', disableClear: false });
  }

  clearSelectionAndHideItems() {
    this.checkboxService.clearSelectionList(this.listingType, this.stateEntry, this.selectedTab);
    this.showSelectedItems = false;
  }

  displaySnackbarMessage(listingType: string, successfulDeletions: number, failedDeletions: number) {
    if (failedDeletions > 0) {
      const totalDeletions = successfulDeletions + failedDeletions;
      this.snackBarService.openSnackBar(
        `${failedDeletions} out of ${totalDeletions} ${listingType} were not successfully deleted`,
        '',
        'error',
      );
    } else {
      this.snackBarService.openSnackBar(
        `${successfulDeletions} ${this.generateListingTypeLabel.toLowerCase()} successfully deleted`,
        '',
      );
    }
  }

  capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  goOnFirstPage() {
    this.paginator.firstPage();
  }

  resetPageIndex() {
    this.paginator.pageIndex = 0;
  }

  clearSelection() {
    this.checkboxService.clearSelectionList(this.listingType, this.stateEntry, this.selectedTab);
  }

  closeDialogWithElement(item) {
    this.dialogRef.close(item);
  }

  closeDialogWithMultipleItems() {
    this.dialogRef.close(this.selectedItems);
    this.checkboxService.clearSelectionList(this.listingType, this.stateEntry, this.selectedTab);
  }

  drop(event: CdkDragDrop<any>) {
    this.dragDisabled = true;
    const { updateItem } = this.actionsMapper[this.listingType];

    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

    if (event.currentIndex !== event.previousIndex) {
      for (let [key, item] of event.container.data.entries()) {
        const updateItemPriorityObservables: any = this.updateItemPriority(item.id, key + 1);
        forkJoin(updateItemPriorityObservables).subscribe({
          next: (results: any) => {
            if (results[0] === true) {
              this.store.dispatch(updateItem(item.id, { ...item, priority: key + 1 }));
            }
          },
        });
      }
    }
  }

  getIconClass(column: any, condition: boolean): string {
    return condition ? column.iconSettings.trueIcon.classIcon : column.iconSettings.falseIcon.classIcon;
  }

  getIcon(column: any, condition: boolean): string {
    return condition ? column.iconSettings.trueIcon.icon : column.iconSettings.falseIcon.icon;
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  performAction(action: { label: string; action: () => void }) {
    action.action();
  }
  performActionItem(
    action: { label: string; action: (element: SharedListingType) => void },
    element: SharedListingType,
  ) {
    action.action(element);
  }

  actionsMapper: Record<
    string,
    {
      getCurrentPageSize: () => Action;
      getCurrentPage: () => Action;
      refetch: (variables: any) => Action;
      refetchSearchCompany?: (variables: any) => Action;
      tableColumnsEdit?: (tableColumnsPayload: TableColumns) => Action;
      applyFilter?: (variables: any) => Action;
      removeFilter?: (variables: any) => Action;
      updateItem?: (id, data) => Action;
    }
  > = {
    [ListingTypes.favorites]: {
      getCurrentPageSize: () =>
        new FavoritesActions.GetCurrentPageSize({
          pageSize: this.itemsPerPage,
          stateEntry: this.stateEntry,
        }),
      getCurrentPage: () => new FavoritesActions.GetCurrentPage({ page: this.page, stateEntry: this.stateEntry }),
      refetch: (variables) =>
        new FavoritesActions.RefetchFavoriteLists({ variables: variables, stateEntry: this.stateEntry }),
      tableColumnsEdit: (tableColumnsPayload) =>
        new TableColumnsActions.FavoritesTableColumns({
          columnsObj: tableColumnsPayload,
          stateEntry: this.stateEntry,
        }),
    },
    [ListingTypes.taxes]: {
      getCurrentPageSize: () => new TaxesActions.GetCurrentPageSize(this.itemsPerPage),
      getCurrentPage: () => new TaxesActions.GetCurrentPage(this.page),
      refetch: (variables) => new TaxesActions.RefetchTaxes(variables),
      tableColumnsEdit: (tableColumnsPayload) => new TableColumnsActions.TaxesTableColumns(tableColumnsPayload),
    },
    [ListingTypes.orderlists]: {
      getCurrentPageSize: () => new OrderlistsActions.GetCurrentPageSize(this.itemsPerPage),
      getCurrentPage: () => new OrderlistsActions.GetCurrentPage(this.page),
      refetch: (variables) => new OrderlistsActions.RefetchOrderLists(variables),
      tableColumnsEdit: (tableColumnsPayload) => new TableColumnsActions.OrderlistsTableColumns(tableColumnsPayload),
    },
    [ListingTypes.valueSet]: {
      getCurrentPageSize: () => new ValueSetActions.GetCurrentPageSize(this.itemsPerPage),
      getCurrentPage: () => new ValueSetActions.GetCurrentPage(this.page),
      refetch: (variables) => new ValueSetActions.RefetchValueSet(variables),
      tableColumnsEdit: (tableColumnsPayload) =>
        new TableColumnsActions.ValueSetTableColumns({
          columnsObj: tableColumnsPayload,
          stateEntry: this.stateEntry,
        }),
      applyFilter: (variables) => new ValueSetActions.AddGeneralFilter(variables),
    },
    [ListingTypes.valueSetItem]: {
      getCurrentPageSize: () => new ValueSetItemActions.GetCurrentPageSize(this.itemsPerPage),
      getCurrentPage: () => new ValueSetItemActions.GetCurrentPage(this.page),
      refetch: (variables) => new ValueSetItemActions.RefetchValueSetItem(variables),
      tableColumnsEdit: (tableColumnsPayload) => new TableColumnsActions.ValueSetItemTableColumns(tableColumnsPayload),
    },
    [ListingTypes.orderStatus]: {
      getCurrentPageSize: () => new OrderStatusActions.GetCurrentPageSize(this.itemsPerPage),
      getCurrentPage: () => new OrderStatusActions.GetCurrentPage(this.page),
      refetch: (variables) => new OrderStatusActions.RefetchOrderStatus(variables),
      tableColumnsEdit: (tableColumnsPayload) => new TableColumnsActions.OrderStatusTableColumns(tableColumnsPayload),
      applyFilter: (variables) => new OrderStatusActions.AddGeneralFilter(variables),
      removeFilter: (variables) => new OrderStatusActions.RemoveGeneralFilter(variables),
      updateItem: (id, data) => new OrderStatusActions.UpdateOrderStatus({ id, updatedOrderStatus: { ...data } }),
    },
    [ListingTypes.orderStatusSet]: {
      getCurrentPageSize: () => new OrderStatusSetActions.GetCurrentPageSize(this.itemsPerPage),
      getCurrentPage: () => new OrderStatusSetActions.GetCurrentPage(this.page),
      refetch: (variables) => new OrderStatusSetActions.RefetchOrderStatusSet(variables),
      applyFilter: (variables) => new OrderStatusSetActions.AddGeneralFilter(variables),
      tableColumnsEdit: (tableColumnsPayload) =>
        new TableColumnsActions.OrderStatusSetTableColumns(tableColumnsPayload),
    },
    [ListingTypes.surcharges]: {
      getCurrentPageSize: () => new SurchargesActions.GetCurrentPageSize(this.itemsPerPage),
      getCurrentPage: () => new SurchargesActions.GetCurrentPage(this.page),
      refetch: (variables) => new SurchargesActions.RefetchSurcharges(variables),
      tableColumnsEdit: (tableColumnsPayload) => new TableColumnsActions.SurchargesTableColumns(tableColumnsPayload),
    },
    [ListingTypes.paymethods]: {
      getCurrentPageSize: () => new PayMethodsActions.GetCurrentPageSize(this.itemsPerPage),
      getCurrentPage: () => new PayMethodsActions.GetCurrentPage(this.page),
      refetch: (variables) => new PayMethodsActions.RefetchPayMethods(variables),
      tableColumnsEdit: (tableColumnsPayload) => new TableColumnsActions.PayMethodsTableColumns(tableColumnsPayload),
      applyFilter: (variables) => new PayMethodsActions.AddSortFilters(variables),
    },
    [ListingTypes.carriers]: {
      getCurrentPageSize: () => new CarriersActions.GetCurrentPageSize(this.itemsPerPage),
      getCurrentPage: () => new CarriersActions.GetCurrentPage(this.page),
      refetch: (variables) => new CarriersActions.RefetchCarriers(variables),
      tableColumnsEdit: (tableColumnsPayload) => new TableColumnsActions.CarriersTableColumns(tableColumnsPayload),
      applyFilter: (variables) => new CarriersActions.AddSortFilters(variables),
    },
    [ListingTypes.adminUsers]: {
      getCurrentPageSize: () => new BackofficeUsersActions.GetCurrentPageSize(this.itemsPerPage),
      getCurrentPage: () => new BackofficeUsersActions.GetCurrentPage(this.page),
      refetch: (variables) => new BackofficeUsersActions.RefetchBackofficeUsers(variables),
      tableColumnsEdit: (tableColumnsPayload) =>
        new TableColumnsActions.BackofficeUsersTableColumns(tableColumnsPayload),
      applyFilter: (variables) => new BackofficeUsersActions.AddSortFilters(variables),
    },
    [ListingTypes.attributeDescriptions]: {
      getCurrentPageSize: () =>
        new AttributeDescriptionsActions.GetCurrentPageSize({ offset: this.itemsPerPage, attrClass: this.selectedTab }),
      getCurrentPage: () =>
        new AttributeDescriptionsActions.GetCurrentPage({ page: this.page, attrClass: this.selectedTab }),
      refetch: (variables) =>
        new AttributeDescriptionsActions.RefetchAttributeDescriptions({ vars: variables, attrClass: this.selectedTab }),
      tableColumnsEdit: (tableColumnsPayload) =>
        new TableColumnsActions.AttributeDescriptionsTableColumns({
          columnObj: tableColumnsPayload,
          attrClass: this.selectedTab,
        }),
      applyFilter: (variables) =>
        new AttributeDescriptionsActions.AddSortFilters({ sort: variables, attrClass: this.selectedTab }),
    },
    [ListingTypes.users]: {
      getCurrentPageSize: () =>
        new UsersActions.GetCurrentPageSize({
          pageSize: this.itemsPerPage,
          stateEntry: this.stateEntry,
        }),
      getCurrentPage: () => new UsersActions.GetCurrentPage({ page: this.page, stateEntry: this.stateEntry }),
      refetch: (variables) => new UsersActions.RefetchUsers({ variables: variables, stateEntry: this.stateEntry }),
      refetchSearchCompany: (variables) =>
        new UsersActions.RefetchCompanySearch(variables, this.tab as UserTypesPlural, this.stateEntry),
      tableColumnsEdit: (tableColumnsPayload) =>
        new TableColumnsActions.UsersTableColumns({
          columnsObj: tableColumnsPayload,
          stateEntry: this.stateEntry,
          tab: this.tab as UserTypesPlural,
        }),
    },
    [ListingTypes.priceSheet]: {
      getCurrentPageSize: () => new PriceSheetActions.GetCurrentPageSize(this.itemsPerPage),
      getCurrentPage: () => new PriceSheetActions.GetCurrentPage(this.page),
      refetch: (variables) => new PriceSheetActions.RefetchPriceSheet(variables),
      tableColumnsEdit: (tableColumnsPayload) => new TableColumnsActions.PriceSheetTableColumns(tableColumnsPayload),
      applyFilter: (variables) => new PriceSheetActions.AddGeneralFilter(variables),
      removeFilter: (variables) => new PriceSheetActions.RemoveGeneralFilter(variables),
      updateItem: (id, data) => new PriceSheetActions.UpdatePriceSheet({ id, updatedPriceSheet: { ...data } }),
    },
  };
}
