import { gql } from 'apollo-angular';
import { AttributeFragments } from '../fragments/attribute-fragments';

export const AttributeDescriptionUpdate = gql`
  mutation AttributeDescriptionUpdate($id: String!, $input: AttributeDescriptionUpdateInput!) {
    attributeDescriptionUpdate(id: $id, input: $input) {
      ...attributeDescription
      defaultValue {
        ...attrValue
      }
    }
  }
  ${AttributeFragments.attributeDescription}
  ${AttributeFragments.attributeValue}
`;

export const AttributeDescriptionCreate = gql`
  mutation AttributeDescriptionCreate($input: AttributeDescriptionCreateInput!) {
    attributeDescriptionCreate(input: $input) {
      ...attributeDescription
      defaultValue {
        ...attrValue
      }
    }
  }
  ${AttributeFragments.attributeDescription}
  ${AttributeFragments.attributeValue}
`;

export const AttributeDescriptionDelete = gql`
  mutation AttributeDescriptionDelete($id: String!) {
    attributeDescriptionDelete(id: $id)
  }
`;

export const AttributeCreate = gql`
  mutation attributeCreate($input: AttributeCreateInput!) {
    attributeCreate(input: $input) {
      id
      attributeDescription {
        name
      }
      value {
        ...attrValue
      }
      createdAt
      lastModifiedAt
      createdBy
      lastModifiedBy
    }
  }
  ${AttributeFragments.attributeValue}
`;

export const AttributeUpdate = gql`
  mutation attributeUpdate($id: String!, $input: AttributeUpdateInput!) {
    attributeUpdate(id: $id, input: $input) {
      id
      attributeDescription {
        name
      }
      value {
        ...attrValue
      }
      createdAt
      lastModifiedAt
      createdBy
      lastModifiedBy
    }
  }
  ${AttributeFragments.attributeValue}
`;

export const AttributeDelete = gql`
  mutation attributeDelete($id: String!) {
    attributeDelete(id: $id)
  }
`;
