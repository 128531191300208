import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';

import { HttpLink } from 'apollo-angular/http';
import { HttpClientModule } from '@angular/common/http';
import { setContext } from '@apollo/client/link/context';
import { environment } from '../environments/environment';
import { AuthService } from './auth/auth.service';
import { SnackBarService } from './shared/components/snack-bar/snack-bar.service';

const uri = environment.propeller.api.url; // <-- add the URL of the GraphQL server here
export function createApollo(
  httpLink: HttpLink,
  authService: AuthService,
  snackBarService: SnackBarService,
): ApolloClientOptions<any> {
  const auth = setContext(async (_, { headers }) => {
    const token = await authService.getToken();
    const tenant = await authService.getActiveTenant();
    return {
      headers: {
        apikey: environment.propeller.api.key,
        tenant: tenant,
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  });
  const error = onError(({ graphQLErrors, networkError }) => {
    // if (graphQLErrors)
    //  { graphQLErrors.map(({ extensions }) =>
    //     console.log(`[GraphQL error]: Message: ${extensions.messages[0]}`)
    //   )}

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  // httpLink.create({ uri }),

  const link = ApolloLink.from([auth, error, createUploadLink({ uri })]);
  const cache = new InMemoryCache({
    typePolicies: {
      BusinessRuleDecisionTable: {
        keyFields: false,
      },
      Query: {
        fields: {
          products: {
            // Don't cache separate results based on
            // any of this field's arguments.
            keyArgs: false,
            // Concatenate the incoming list items with
            // the existing list items.
            // merge(existing, incoming) {
            //   return [...existing, ...incoming];
            // },
            merge: true,
          },
          product: {
            // Don't cache separate results based on
            // any of this field's arguments.
            keyArgs: false,
            // Concatenate the incoming list items with
            // the existing list items.
            // merge(existing, incoming) {
            //   return [...existing, ...incoming];
            // },
            merge: true,
          },
          orders: {
            keyArgs: false,
            merge: true,
          },
          companies: {
            keyArgs: false,
            merge: true,
          },
          media: {
            keyArgs: false,
            merge: true,
          },
          BusinessRuleDecisionTableContent: {
            keyArgs: false,
            merge: true,
          },
          contact: {
            keyArgs: false,
            merge: true,
          },
          company: {
            keyArgs: false,
            merge: true,
          },
          customer: {
            keyArgs: false,
            merge: true,
          },
        },
      },
    },
  });

  return {
    link,
    cache,
  };
}

@NgModule({
  imports: [ApolloModule],
  exports: [HttpClientModule],
  providers: [
    // {
    //   provide: APOLLO_FLAGS,
    //   useValue: {
    //     useMutationLoading: true,
    //   }
    // },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AuthService],
    },
  ],
})
export class GraphQLModule {}
