import { gql } from 'apollo-angular';
import { priceSheetFragments } from '../fragments/price-sheet-fragments';

export const priceSheetCreate = gql`
  mutation pricesheetCreate($input: PricesheetCreateInput!) {
    pricesheetCreate(input: $input) {
      ...priceSheet
    }
  }
  ${priceSheetFragments.priceSheet}
`;
export const priceSheetUpdate = gql`
  mutation pricesheetUpdate($id: String!, $input: PricesheetUpdateInput!) {
    pricesheetUpdate(id: $id, input: $input) {
      ...priceSheet
    }
  }
  ${priceSheetFragments.priceSheet}
`;

export const priceSheetDelete = gql`
  mutation pricesheetDelete($id: String!) {
    pricesheetDelete(id: $id)
  }
`;
export const priceSheetAssign = gql`
  mutation pricesheetAssign($id: String!, $input: PricesheetAssignInput!) {
    pricesheetAssign(id: $id, input: $input) {
      id
      code
      names {
        language
        value
      }
      descriptions {
        language
        value
      }
      priority
      readonly
      createdAt
      lastModifiedAt
      companies {
        companyId
        name
        __typename
      }
      customers {
        customerId
        firstName
        lastName
        email
        __typename
      }
      contacts {
        contactId
        firstName
        lastName
        email
        __typename
      }
      usergroups
    }
  }
`;
export const priceSheetUnassign = gql`
  mutation pricesheetUnassign($id: String!, $input: PricesheetUnassignInput!) {
    pricesheetUnassign(id: $id, input: $input) {
      id
      code
      names {
        language
        value
      }
      descriptions {
        language
        value
      }
      priority
      readonly
      createdAt
      lastModifiedAt
      companies {
        companyId
        name
        __typename
      }
      customers {
        customerId
        firstName
        lastName
        email
        __typename
      }
      contacts {
        contactId
        firstName
        lastName
        email
        __typename
      }
      usergroups
    }
  }
`;
