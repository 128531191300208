import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { TableEditorDialogComponent } from '../components/table-editor-dialog/table-editor-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class TableEditorDialogService {
  constructor(private dialog: MatDialog) {}
  dialogRef: MatDialogRef<TableEditorDialogComponent>;

  public open(options) {
    this.dialogRef = this.dialog.open(TableEditorDialogComponent, {
      data: {
        displayedColumns: options.displayedColumns,
        hiddenColumns: options.hiddenColumns,
        availableColumns: options.availableColumns,
        disabledColumns: options.disabledColumns,
        disabledSuffixLength: options.disabledSuffixLength,
      },
      disableClose: true,
    });
  }
  public confirmed(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(
      take(1),
      map((res) => {
        return res;
      }),
    );
  }
}
