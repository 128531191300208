import { Injectable } from '@angular/core';
import { Actions, createEffect, EffectNotification, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, map, mergeMap, Observable, of, Subscription, takeUntil } from 'rxjs';

import * as CatalogActions from './catalog.actions';
import * as fromApp from '../../store/app.reducer';
import { CatalogService } from '../catalog.service';
import { NavigationStart, Router } from '@angular/router';
import { QueryRef } from 'apollo-angular';
import { ProductListResponse } from 'src/app/shared/api/types/types';
import { Product } from 'src/app/shared/api/types/GraphQL';

@Injectable()
export class CatalogEffects {
  productsQuery: QueryRef<ProductListResponse>;
  productsQuerySub: Observable<any>;
  browserRefresh: boolean;
  refreshSub: Subscription;
  queryRefetch: boolean = false;
  location: string = '';

  constructor(
    private actions: Actions,
    private catalogService: CatalogService,
    private store: Store<fromApp.AppState>,
    private router: Router,
  ) {
    this.refreshSub = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.browserRefresh = !router.navigated;
      }
    });
  }

  public readonly loadProducts: Observable<any> = createEffect(() => {
    return this.actions.pipe(
      ofType(CatalogActions.LOAD_CATALOG),
      mergeMap((action: CatalogActions.LoadCatalog) => {
        this.productsQuery = this.catalogService.getCatalogProducts({
          input: action.payload.input,
          companyId: action.payload.companyId,
          contactId: action.payload.contactId,
        });
        this.productsQuerySub = this.productsQuery.valueChanges.pipe(
          map((data) => {
            this.catalogService.imgLoading = true;
            if (!this.queryRefetch) {
              this.store.dispatch(new CatalogActions.GetCurrentPage(1));
              this.store.dispatch(new CatalogActions.ClearAllFilters());
              this.store.dispatch(new CatalogActions.LoadCatalogFailure(undefined));
            }
            this.queryRefetch = true;
            this.store.dispatch(new CatalogActions.GetCatalogCount(data.data.products.itemsFound));

            this.store.dispatch(new CatalogActions.GetAttributeFilters(data.data.products.filters));

            return new CatalogActions.LoadCatalogSuccess(data.data.products.items as Product[]);
          }),
          catchError((error) => of(new CatalogActions.LoadCatalogFailure(error))),
        );
        return this.productsQuerySub;
      }),
    );
  });

  // public readonly refetchProducts = createEffect(() =>
  //     this.actions.pipe(
  //         ofType(CatalogActions.REFETCH_CATALOG),
  //         tap((action: CatalogActions.RefetchCatalog) => {
  //             this.productsQuery.resetLastResults();
  //             this.productsQuery.refetch(action.payload)
  //         })
  //     ), { dispatch: false }
  // );

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>): Observable<EffectNotification> {
    return this.actions.pipe(
      ofType(CatalogActions.CATALOG_PAGE_INITIALIZED),
      exhaustMap(() =>
        resolvedEffects$.pipe(takeUntil(this.actions.pipe(ofType(CatalogActions.CATALOG_PAGE_DESTROYED)))),
      ),
    );
  }
}
