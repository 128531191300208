import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { debounceTime, map, Subject, switchMap, take, takeUntil } from 'rxjs';
import { ValueSetService } from 'src/app/admin/value-set/value-set.service';
import { GqlErrorService } from '../../services/gql-error.service';

@Component({
  selector: 'app-dropdown-with-search-input',
  templateUrl: './dropdown-with-search-input.component.html',
  styleUrls: ['./dropdown-with-search-input.component.scss'],
})
export class DropdownWithSearchInputComponent implements OnInit, OnDestroy {
  @Input() selectedData: string = '';
  @Input() headerName: string;
  @Input() dropdownPlaceholder: string;
  @Input() isFilter?: boolean = false;
  @Input() isViewer: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() searchPlaceholder: string = 'Search';
  @Output() languageChange = new EventEmitter<string>();
  customData: any[];
  valuesetId: number;
  @Input() valueSetName: string;

  private searchSubject = new Subject<string>();
  private destroySubject: Subject<void> = new Subject<void>();

  constructor(
    private valuesetService: ValueSetService,
    private errorService: GqlErrorService,
  ) {}

  ngOnInit(): void {
    this.valuesetService
      .getValuesetByName({ names: [this.valueSetName] })
      .valueChanges.pipe(
        take(1),
        map((data) => data.data.valuesets.items[0]),
        switchMap((valueset) => {
          this.valuesetId = valueset.id;
          return this.valuesetService.getValueSetItemList({ valuesetIds: [this.valuesetId] }).valueChanges;
        }),
        map((data) => data.data.valuesetItems.items),
      )
      .subscribe({
        next: (valuesets) => {
          this.customData = valuesets;
        },
      });

    this.searchSubject.pipe(debounceTime(500), takeUntil(this.destroySubject)).subscribe((searchTerm) => {
      this.fetchLanguages(searchTerm);
    });
  }

  onDataChange(event): void {
    this.languageChange.emit(event.value);
  }

  onDataSearch(event) {
    const searchTerm = event.target.value;
    this.searchSubject.next(searchTerm);
  }

  private fetchLanguages(searchTerm?: string) {
    const query = { valuesetIds: [this.valuesetId] };
    if (searchTerm) query['values'] = [searchTerm];
    this.valuesetService
      .getValueSetItemList(query)
      .valueChanges.pipe(
        takeUntil(this.destroySubject),
        map((data) => data.data.valuesetItems.items),
      )
      .subscribe({
        next: (data) => {
          this.customData = data.length ? data : [{ key: 'noResults', value: 'No results found' }];
        },
        error: (error) => this.errorService.getGqlError(error),
      });
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
