import { Action } from '@ngrx/store';
import { StateEntry } from 'src/app/shared/api/types/enums';
import { CategoryNode } from 'src/app/shared/api/types/interfaces';

export const RESET_STATE_ENTRY_STATE = '[Categories] Reset the state of one state entry';

export const SET_LOADER = '[Categories] Set Loader';

export const LOAD_CATEGORIES = '[Categories] Load Categories';
export const LOAD_CATEGORIES_SUCCESS = '[Categories] Load Categories Success';
export const LOAD_INNER_CATEGORIES = '[Categories] Load Inner Categories';
export const LOAD_CATEGORIES_FAILURE = '[Categories] Load Categories Failure';

export const ADD_CATEGORY = '[Categories] Add Category';
export const UPDATE_CATEGORY = '[Categories] Update Category';
export const DELETE_CATEGORY = '[Categories] Delete Category';

export const SET_STATE_ENTRY = '[Categories] Set state entry';

export class ResetStateEntryState implements Action {
  readonly type = RESET_STATE_ENTRY_STATE;

  constructor(public payload: StateEntry) {}
}
export class SetLoader implements Action {
  readonly type = SET_LOADER;

  constructor(public payload: { isLoading: boolean; stateEntry: StateEntry }) {}
}
export class LoadCategories implements Action {
  readonly type = LOAD_CATEGORIES;

  constructor(public payload: { arguments: {}; stateEntry: StateEntry }) {}
}

export class LoadCategoriesSuccess implements Action {
  readonly type = LOAD_CATEGORIES_SUCCESS;

  constructor(public payload: { category: CategoryNode; stateEntry: StateEntry }) {}
}

export class LoadInnerCategories implements Action {
  readonly type = LOAD_INNER_CATEGORIES;

  constructor(public payload: { arguments: {}; stateEntry: StateEntry }) {}
}

export class LoadCategoriesFailure implements Action {
  readonly type = LOAD_CATEGORIES_FAILURE;

  constructor(public payload: { error: Error; stateEntry: StateEntry }) {}
}

export class AddCategory implements Action {
  readonly type = ADD_CATEGORY;

  constructor(public payload: { category: CategoryNode; stateEntry: StateEntry }) {}
}

export class UpdateCategory implements Action {
  readonly type = UPDATE_CATEGORY;

  constructor(public payload: { categoryId: number; categoryName: string; stateEntry: StateEntry }) {}
}
export class DeleteCategory implements Action {
  readonly type = DELETE_CATEGORY;

  constructor(public payload: { categoryId: number; stateEntry: StateEntry }) {}
}

export class SetStateEntry implements Action {
  readonly type = SET_STATE_ENTRY;

  constructor(public payload: StateEntry) {}
}

export type CategoriesActions =
  | ResetStateEntryState
  | SetLoader
  | LoadCategories
  | LoadCategoriesSuccess
  | LoadInnerCategories
  | LoadCategoriesFailure
  | AddCategory
  | UpdateCategory
  | DeleteCategory
  | SetStateEntry;
