import {
  AttributeDescription,
  AttributeDescriptionClass,
  AttributeDescriptionSearchInput,
} from 'src/app/shared/api/types/GraphQL';
import * as AttributeDescriptionsActions from './attributes.actions';
import { globals } from 'src/app/shared/globals';

export interface StateProperties {
  loading: boolean;
  loaded: boolean;
  attributeDescriptions: AttributeDescription[];
  currentPage: number;
  currentPageSize: number;
  attributeDescriptionsCount: number;
  error: Error;
  generalFilterValue: string;
  selectedAttributeDescriptionsList: AttributeDescription[];
  queryVariables: AttributeDescriptionSearchInput;
  multiselectFilterValues: AttributeDescriptionSearchInput;
  numberOfFiltersSelected: number;
}

export interface State {
  [key: string]: StateProperties | AttributeDescriptionClass;
}

const initialStateProperties: StateProperties = {
  loading: false,
  loaded: false,
  attributeDescriptions: [],
  currentPage: 1,
  currentPageSize: globals.globalOffset,
  attributeDescriptionsCount: 0,
  error: undefined,
  generalFilterValue: '',
  selectedAttributeDescriptionsList: [],
  queryVariables: {
    page: 1,
    offset: globals.globalOffset,
  },
  multiselectFilterValues: {
    names: [],
    types: [],
    groups: [],
    sortInputs: [],
    isSearchable: undefined,
    isHidden: undefined,
    isPublic: undefined,
    isSystem: undefined,
  },
  numberOfFiltersSelected: 0,
};

const initialState: State = { class: AttributeDescriptionClass.Category };
Object.values(AttributeDescriptionClass).forEach((key) => {
  initialState[key] = initialStateProperties;
});

export function attributeDescriptionsReducer(
  state: State = initialState,
  action: AttributeDescriptionsActions.AttributeDescriptionsActions,
) {
  switch (action.type) {
    case AttributeDescriptionsActions.GET_GENERAL_FILTER:
      return {
        ...state,
        [action.payload.attrClass]: {
          ...(state[action.payload.attrClass] as StateProperties),
          generalFilterValue: action.payload,
        },
      };
    case AttributeDescriptionsActions.GET_CURRENT_PAGE:
      return {
        ...state,
        [action.payload.attrClass]: {
          ...(state[action.payload.attrClass] as StateProperties),
          currentPage: action.payload.page,
        },
      };
    case AttributeDescriptionsActions.GET_CURRENT_PAGE_SIZE:
      return {
        ...state,
        [action.payload.attrClass]: {
          ...(state[action.payload.attrClass] as StateProperties),
          currentPageSize: action.payload.offset,
        },
      };
    case AttributeDescriptionsActions.GET_ATTRIBUTE_DESCRIPTIONS_COUNT:
      return {
        ...state,
        [action.payload.attrClass]: {
          ...(state[action.payload.attrClass] as StateProperties),
          attributeDescriptionsCount: action.payload.count,
        },
      };
    case AttributeDescriptionsActions.LOAD_ATTRIBUTE_DESCRIPTIONS:
      let loading;
      (state[action.payload.attrClass] as StateProperties).loaded ? (loading = false) : (loading = true);
      return {
        ...state,
        [action.payload.attrClass]: {
          ...(state[action.payload.attrClass] as StateProperties),
          loading: loading,
        },
      };
    case AttributeDescriptionsActions.REFETCH_ATTRIBUTE_DESCRIPTIONS:
      return {
        ...state,
        [action.payload.attrClass]: {
          ...(state[action.payload.attrClass] as StateProperties),
          loaded: false,
          loading: true,
        },
      };
    case AttributeDescriptionsActions.LOAD_ATTRIBUTE_DESCRIPTIONS_SUCCESS:
      return {
        ...state,
        [action.payload.attrClass]: {
          ...(state[action.payload.attrClass] as StateProperties),
          attributeDescriptions: [...action.payload.items],
          loading: false,
          loaded: true,
        },
      };
    case AttributeDescriptionsActions.LOAD_ATTRIBUTE_DESCRIPTIONS_FAILURE:
      return {
        ...state,
        [action.payload.attrClass]: {
          ...(state[action.payload.attrClass] as StateProperties),
          error: action.payload.error,
          loading: false,
        },
      };
    case AttributeDescriptionsActions.ADD_ATTRIBUTE_DESCRIPTION_TO_LIST:
      if (
        (state[action.payload.attrClass] as StateProperties).selectedAttributeDescriptionsList
          .map((x) => x.id)
          .indexOf(action.payload.item.id) === -1
      ) {
        return {
          ...state,
          [action.payload.attrClass]: {
            ...(state[action.payload.attrClass] as StateProperties),
            selectedAttributeDescriptionsList: [
              ...(state[action.payload.attrClass] as StateProperties).selectedAttributeDescriptionsList,
              action.payload.item,
            ],
          },
        };
      } else {
        return {
          ...state,
        };
      }
    case AttributeDescriptionsActions.DELETE_ATTRIBUTE_DESCRIPTION_FROM_LIST:
      if (
        (state[action.payload.attrClass] as StateProperties).selectedAttributeDescriptionsList
          .map((x) => x.id)
          .indexOf(action.payload.id) !== -1
      ) {
        const index = (state[action.payload.attrClass] as StateProperties).selectedAttributeDescriptionsList
          .map((x) => x.id)
          .indexOf(action.payload.id);
        return {
          ...state,
          [action.payload.attrClass]: {
            ...(state[action.payload.attrClass] as StateProperties),
            selectedAttributeDescriptionsList: (
              state[action.payload.attrClass] as StateProperties
            ).selectedAttributeDescriptionsList.filter((product, i) => {
              return i !== index;
            }),
          },
        };
      } else {
        return {
          ...state,
        };
      }
    case AttributeDescriptionsActions.CLEAR_ATTRIBUTE_DESCRIPTIONS_LIST:
      return {
        ...state,
        [action.payload]: {
          ...(state[action.payload] as StateProperties),
          selectedAttributeDescriptionsList: [],
        },
      };
    case AttributeDescriptionsActions.ADD_SORT_FILTERS:
      const { sortInputs, ...otherPayload } = action.payload.sort;
      return {
        ...state,
        [action.payload.attrClass]: {
          ...(state[action.payload.attrClass] as StateProperties),
          multiselectFilterValues: {
            ...(state[action.payload.attrClass] as StateProperties).multiselectFilterValues,
            ...otherPayload,
            sortInputs,
          },
        },
      };
    case AttributeDescriptionsActions.GET_MULTISELECT_FILTERS:
      const variables = action.payload.vars;
      let payloadLength =
        (variables.names !== undefined ? variables.names.length : 0) +
        (variables.types !== undefined ? variables.types.length : 0) +
        (variables.groups !== undefined ? variables.groups.length : 0) +
        (variables.isHidden !== undefined ? 1 : 0) +
        (variables.isPublic !== undefined ? 1 : 0) +
        (variables.isSearchable !== undefined ? 1 : 0) +
        (variables.isSystem !== undefined ? 1 : 0);

      return {
        ...state,
        [action.payload.attrClass]: {
          ...(state[action.payload.attrClass] as StateProperties),
          numberOfFiltersSelected: payloadLength,
        },
      };
    case AttributeDescriptionsActions.GET_QUERY_VARIABLES:
      const newValues = action.payload.vars;

      return {
        ...state,
        [action.payload.attrClass]: {
          ...(state[action.payload.attrClass] as StateProperties),
          queryVariables: {
            ...(state[action.payload.attrClass] as StateProperties).queryVariables,
            ...newValues,
          },
        },
      };
    case AttributeDescriptionsActions.GET_CLASS:
      return {
        ...state,
        class: action.payload,
      };
    default:
      return state;
  }
}
