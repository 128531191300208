import { Injectable } from '@angular/core';
import { Actions, createEffect, EffectNotification, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, map, mergeMap, Observable, of, Subscription, takeUntil, tap } from 'rxjs';
import { OrdersService } from '../../orders.service';
import * as RequestsActions from './request.actions';
import * as fromApp from 'src/app/store/app.reducer';
import { NavigationStart, Router } from '@angular/router';
import { QueryRef } from 'apollo-angular';
import { OrderListResponse } from 'src/app/shared/api/types/types';

@Injectable()
export class RequestsEffects {
  requestsQuery: QueryRef<OrderListResponse>;
  rejectedQuery: QueryRef<OrderListResponse>;
  requestsQuerySub: Observable<any>;
  rejectedQuerySub: Observable<any>;
  browserRefresh: boolean;
  refreshSub: Subscription;
  queryRefetch: boolean = false;

  constructor(
    private actions: Actions,
    private ordersService: OrdersService,
    private store: Store<fromApp.AppState>,
    private router: Router,
  ) {
    this.refreshSub = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.browserRefresh = !router.navigated;
      }
    });
  }

  public readonly loadRequests: Observable<any> = createEffect(() => {
    return this.actions.pipe(
      ofType(RequestsActions.LOAD_REQUESTS),
      mergeMap((action: RequestsActions.LoadRequests) => {
        this.requestsQuery = this.ordersService.getAllQuotes(action.payload);

        this.requestsQuerySub = this.requestsQuery.valueChanges.pipe(
          map((data) => {
            if (!this.queryRefetch) {
              this.store.dispatch(new RequestsActions.GetCurrentPage(1));
              this.store.dispatch(new RequestsActions.GetCurrentPageSize(5));
              this.store.dispatch(new RequestsActions.ClearAllFilters());
              this.store.dispatch(new RequestsActions.LoadRequestsFailure(undefined));
              this.store.dispatch(new RequestsActions.ClearRequestsList());
            }
            this.queryRefetch = true;
            this.store.dispatch(new RequestsActions.GetRequestsCount(data.data.orders.itemsFound));
            return new RequestsActions.LoadRequestsSuccess(data.data.orders.items);
          }),
          catchError((error) => of(new RequestsActions.LoadRequestsFailure(error))),
        );

        return this.requestsQuerySub;
      }),
    );
  });

  public readonly loadRejected: Observable<any> = createEffect(() => {
    return this.actions.pipe(
      ofType(RequestsActions.LOAD_REJECTED),
      mergeMap((action: RequestsActions.LoadRejected) => {
        this.rejectedQuery = this.ordersService.getAllQuotes(action.payload);

        this.rejectedQuerySub = this.rejectedQuery.valueChanges.pipe(
          map((data) => {
            if (!this.queryRefetch) {
              this.store.dispatch(new RequestsActions.GetCurrentPageRejected(1));
              this.store.dispatch(new RequestsActions.GetCurrentPageSizeRejected(5));
              this.store.dispatch(new RequestsActions.ClearAllFilters());
              this.store.dispatch(new RequestsActions.LoadRejectedFailure(undefined));
              this.store.dispatch(new RequestsActions.ClearRequestsList());
            }
            this.queryRefetch = true;
            this.store.dispatch(new RequestsActions.GetRequestsCountRejected(data.data.orders.itemsFound));
            return new RequestsActions.LoadRejectedSuccess(data.data.orders.items);
          }),
          catchError((error) => of(new RequestsActions.LoadRejectedFailure(error))),
        );
        return this.rejectedQuerySub;
      }),
    );
  });

  public readonly refetchRequests = createEffect(
    () =>
      this.actions.pipe(
        ofType(RequestsActions.REFETCH_REQUESTS),
        tap((action: RequestsActions.RefetchRequests) => {
          this.requestsQuery.resetLastResults();
          this.requestsQuery.refetch(action.payload);
        }),
      ),
    { dispatch: false },
  );

  public readonly refetchRejected = createEffect(
    () =>
      this.actions.pipe(
        ofType(RequestsActions.REFETCH_REJECTED),
        tap((action: RequestsActions.RefetchRejected) => {
          this.rejectedQuery.resetLastResults();
          this.rejectedQuery.refetch(action.payload);
        }),
      ),
    { dispatch: false },
  );

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>): Observable<EffectNotification> {
    return this.actions.pipe(
      ofType(RequestsActions.REQUESTS_PAGE_INITIALIZED),
      exhaustMap(() =>
        resolvedEffects$.pipe(takeUntil(this.actions.pipe(ofType(RequestsActions.REQUESTS_PAGE_DESTROYED)))),
      ),
    );
  }
}
