import { gql } from 'apollo-angular';
import { globals } from 'src/app/shared/globals';

export const UpdateProductMutation = gql`
  mutation UpdateProduct($productId: Int!, $input: UpdateProductInput!, $globalLanguage: String!) {
    productUpdate(productId: $productId, input: $input) {
      categoryId
      names(language: $globalLanguage) {
        language
        value
      }
      descriptions(language: $globalLanguage) {
        language
        value
      }
      shortDescriptions(language: $globalLanguage) {
        language
        value
      }
      packageDescriptions {
        language
        value
      }
      economicOrderQuantity
      purchaseMinimumQuantity
      purchaseUnit
      releaseDate
      sku
      status
      supplier
      supplierCode
      manufacturerCode
      #eanCode
      unit
      minimumQuantity
      manufacturer
      package
      packageUnit
      packageUnitQuantity
      shortName
      productId
      barCode
      physical
      priceGroup
      statusExtra
      taxonomy
      turnoverGroup
      orderable
      orderableFrom
      orderableTo
    }
  }
`;

export const ProductDeleteMutation = gql`
  mutation ProductDelete($productId: Int!) {
    productDelete(productId: $productId)
  }
`;

export const ProductCreateMutation = gql`
  mutation ProductCreate($input:CreateProductInput!) {
    productCreate(input: $input) {
        productId
        names(language: "${globals.appLanguage}") {
            value
          }
        class
    }
  }
`;

export const ProductUpdateMutation = gql`
  mutation ProductUpdate($productId: Int!, $input: UpdateProductInput!) {
    productUpdate(productId: $productId, input: $input) {
      productId
    }
  }
`;
