import { gql } from 'apollo-angular';
import { orderStatusFragments } from '../fragments/order-status-fragments';

export const orderStatusCreate = gql`
  mutation orderStatusCreate($input: CreateOrderStatusInput!) {
    orderStatusCreate(input: $input) {
      ...orderStatus
    }
  }
  ${orderStatusFragments.orderStatus}
`;

export const orderStatusUpdate = gql`
  mutation orderStatusUpdate($id: Int!, $input: UpdateOrderStatusInput!) {
    orderStatusUpdate(id: $id, input: $input) {
      ...orderStatus
    }
  }
  ${orderStatusFragments.orderStatus}
`;

export const orderStatusDelete = gql`
  mutation orderStatusDelete($id: Int!) {
    orderStatusDelete(id: $id)
  }
`;

export const orderStatusTransitionCreate = gql`
  mutation orderStatusTransitionCreate($input: CreateOrderStatusTransitionInput!) {
    orderStatusTransitionCreate(input: $input) {
      ...orderStatusTransition
    }
  }
  ${orderStatusFragments.orderStatusTransition}
`;

export const orderStatusTransitionUpdate = gql`
  mutation orderStatusTransitionUpdate($id: Int!, $input: UpdateOrderStatusTransitionInput!) {
    orderStatusTransitionUpdate(id: $id, input: $input) {
      ...orderStatusTransition
    }
  }
  ${orderStatusFragments.orderStatusTransition}
`;

export const orderStatusTransitionDelete = gql`
  mutation orderStatusTransitionDelete($id: Int!) {
    orderStatusTransitionDelete(id: $id)
  }
`;
