import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { filter, Observable, switchMap } from 'rxjs';
import * as fromApp from '../store/app.reducer';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store<fromApp.AppState>,
    private router: Router,
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return new Observable<boolean | UrlTree>((obs) => {
      this.store
        .select((store) => store.auth.isInitializing)
        .pipe(
          filter((isInit) => !isInit),
          switchMap(() => {
            return this.store.select((store) => store.auth.user);
          }),
        )
        .subscribe((user) => {
          if (user) {
            obs.next(true);
          } else {
            obs.next(this.router.createUrlTree(['/sign-in']));
          }
        });
    });
  }
}
