import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ShopQuery, ShopsQuery } from './shared/api/queries/shops-query';
import { ShopResponse, ShopsResponse } from './shared/api/types/types';

@Injectable({ providedIn: 'root' })
export class AppService {
  imgLoading: boolean = true;

  constructor(private apollo: Apollo) {}

  getShops() {
    return this.apollo.watchQuery<ShopsResponse>({
      query: ShopsQuery,
    });
  }

  getShop(shopId: number) {
    return this.apollo.watchQuery<ShopResponse>({
      query: ShopQuery,
      variables: { shopId },
    });
  }
}
