import { Action } from '@ngrx/store';
import { Contact, Company, Customer, CompanySearchInput, CompanySearch } from '../../../../shared/api/types/GraphQL';
import { UserStateEntry, UserTypesPlural } from '../../../../shared/api/types/enums';

export const TAB_CHANGE = '[Users] Tab change';
export const SET_LOADING_TRUE = '[Users] Set Loading True';

export const GET_CURRENT_PAGE = '[Users] Get Current Page';
export const GET_CURRENT_PAGE_SIZE = '[Users] Get Current Page Size';
export const GET_USERS_LISTS_COUNT = '[Users] Get Users Count';

export const LOAD_USERS = '[Users] Load Users';
export const REFETCH_USERS = '[Users] Refetch users';
export const REFETCH_COMPANY_SEARCH = '[Users] Refetch Companies Search';

export const LOAD_USERS_SUCCESS = '[Users] Load Users success';
export const LOAD_USERS_FAILURE = '[Users] Load Users failure';

export const GET_GENERAL_FILTER = '[Users] Get General Filter';
export const ADD_GENERAL_FILTER = '[Users] Add General Filter';
export const REMOVE_GENERAL_FILTER = '[Users] Remove General Filter';

export const ADD_USER_LIST_TO_SELECTED_LISTS = '[Users] Add User List To Selected List';
export const DELETE_USER_LIST_TO_SELECTED_LIST = '[Users] Delete User List From Selected List';
export const CLEAR_SELECTED_USERS_LIST = '[Users] Clear selected users list';

export const RESET_STATE_ENTRY_STATE = '[Users] Reset the state of one state entry';

export const ADD_CONTACTS_TO_SELECTED_LIST = '[Users] Add Contacts to Selected List';

export type User = Contact | Customer | Company;

export type UserList = Contact[] | Company[] | Customer[];

export class GetCurrentPage implements Action {
  readonly type = GET_CURRENT_PAGE;
  constructor(public payload: { page: number; stateEntry?: UserStateEntry }) {}
}
export class GetCurrentPageSize implements Action {
  readonly type = GET_CURRENT_PAGE_SIZE;
  constructor(public payload: { pageSize: number; stateEntry?: UserStateEntry }) {}
}
export class GetUsersListsCount implements Action {
  readonly type = GET_USERS_LISTS_COUNT;
  constructor(public payload: { count: number; stateEntry?: UserStateEntry }) {}
}

export class SetLoadingTrue implements Action {
  readonly type = SET_LOADING_TRUE;
  constructor(public payload: { stateEntry?: UserStateEntry }) {}
}

export class RefetchUsers implements Action {
  readonly type = REFETCH_USERS;
  constructor(public payload: { variables?: {}; stateEntry: UserStateEntry }) {}
}

export class LoadUsers implements Action {
  readonly type = LOAD_USERS;
  constructor(
    public payload: { variables: any; stateEntry?: UserStateEntry },
    public tab: UserTypesPlural,
  ) {}
}

export class LoadUsersSuccess implements Action {
  readonly type = LOAD_USERS_SUCCESS;

  constructor(
    public payload: {
      data: Contact[] | Customer[] | Company[] | CompanySearch[];
      itemsFound: number;
      tab: UserTypesPlural;
      stateEntry?: UserStateEntry;
    },
  ) {}
}

export class LoadUsersFailure implements Action {
  readonly type = LOAD_USERS_FAILURE;

  constructor(public payload: { error: Error; stateEntry: UserStateEntry }) {}
}

export class AddContactsToSelectedList implements Action {
  readonly type = ADD_CONTACTS_TO_SELECTED_LIST;

  constructor(public payload: { contacts: Contact[] }) {}
}

export class AddUserToUserList implements Action {
  readonly type = ADD_USER_LIST_TO_SELECTED_LISTS;
  constructor(public payload: { selectedUsersList: User; stateEntry: UserStateEntry }) {}
}

export class DeleteUserFromUserList implements Action {
  readonly type = DELETE_USER_LIST_TO_SELECTED_LIST;
  constructor(public payload: { id: number; stateEntry: UserStateEntry }) {}
}

export class ClearSelectedUsersList implements Action {
  readonly type = CLEAR_SELECTED_USERS_LIST;
  constructor(public payload: { stateEntry: UserStateEntry }) {}
}

export class TabChange implements Action {
  readonly type = TAB_CHANGE;
  constructor(public payload: { tab: UserTypesPlural; stateEntry: UserStateEntry }) {}
}

export class ResetStateEntryState implements Action {
  readonly type = RESET_STATE_ENTRY_STATE;

  constructor(public payload: UserStateEntry) {}
}

export class AddGeneralFilter implements Action {
  readonly type = ADD_GENERAL_FILTER;

  constructor(
    public payload: { filters: CompanySearchInput | any; tab: UserTypesPlural; stateEntry: UserStateEntry },
  ) {}
}

export class RemoveGeneralFilter implements Action {
  readonly type = REMOVE_GENERAL_FILTER;

  constructor(
    public payload: { columnName: string; index?: number; tab: UserTypesPlural; stateEntry: UserStateEntry },
  ) {}
}

export class RefetchCompanySearch implements Action {
  readonly type = REFETCH_COMPANY_SEARCH;

  constructor(
    public payload: {},
    public tab: UserTypesPlural,
    public stateEntry: UserStateEntry,
  ) {}
}

export type UsersActions =
  | GetCurrentPageSize
  | GetCurrentPage
  | SetLoadingTrue
  | RefetchUsers
  | LoadUsersSuccess
  | LoadUsers
  | LoadUsersFailure
  | AddUserToUserList
  | DeleteUserFromUserList
  | TabChange
  | ClearSelectedUsersList
  | ResetStateEntryState
  | AddGeneralFilter
  | RemoveGeneralFilter
  | RefetchCompanySearch
  | AddContactsToSelectedList;
