import { Injectable } from '@angular/core';
import { QueryRef } from 'apollo-angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, catchError, filter, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import * as FavoritesActions from './favorites.actions';
import { FavoritesService } from '../favorites.service';
import * as fromApp from '../../../store/app.reducer';
import { FavoriteStateEntry } from 'src/app/shared/api/types/enums';

@Injectable()
export class FavoritesEffects {
  query: QueryRef<any>;
  querySub: Observable<Action>;

  constructor(
    private actions: Actions,
    private favoritesService: FavoritesService,
    private store: Store<fromApp.AppState>,
  ) {}

  public readonly loadUsers: Observable<Action> = createEffect(() => {
    return this.actions.pipe(
      ofType(FavoritesActions.LOAD_FAVORITE_LISTS),
      switchMap((action: FavoritesActions.LoadFavoriteLists) =>
        this.store
          .select((state) => state.favorites.stateEntry)
          .pipe(
            filter((stateEntry) => !!stateEntry),
            withLatestFrom(this.store.select((state) => state.favorites[action.payload.stateEntry].loaded)),
            filter(([stateEntry, loaded]) => !loaded),
            switchMap(([stateEntry, loaded]: [FavoriteStateEntry, boolean]) => {
              this.query = this.favoritesService.getFavoriteLists(action.payload.variables);
              this.querySub = this.query.valueChanges.pipe(
                map((data) => {
                  this.store.dispatch(
                    new FavoritesActions.GetFavoriteListsCount({
                      count: data.data.favoriteLists.itemsFound,
                      stateEntry: stateEntry,
                    }),
                  );
                  return new FavoritesActions.LoadFavoriteListsSuccess({
                    items: data.data.favoriteLists.items,
                    stateEntry: action.payload.stateEntry,
                  });
                }),
                catchError((error) => of(new FavoritesActions.LoadFavoriteListsFailure(error))),
              );
              return this.querySub;
            }),
          ),
      ),
    );
  });

  public readonly refetchUsers = createEffect(
    () =>
      this.actions.pipe(
        ofType(FavoritesActions.REFETCH_FAVORITE_LISTS),
        tap((action: FavoritesActions.RefetchFavoriteLists) => {
          this.query.resetLastResults();
          this.query.refetch(action.payload.variables);
        }),
      ),
    { dispatch: false },
  );
}
