import { gql } from 'apollo-angular';

export const PriceFragments = {
  price: gql`
    fragment price on Price {
      id
      per
      list
      cost
      suggested
      store
      bulkPriceDiscountType
      defaultTaxCode
      display
    }
  `,
};

export const BulkPriceFragments = {
  bulkPrice: gql`
    fragment bulkPrice on BulkPrice {
      id
      value
      quantityFrom
      validFrom
      validTo
    }
  `,
  bulkCostPrice: gql`
    fragment bulkCostPrice on BulkCostPrice {
      id
      value
      quantityFrom
      validFrom
      validTo
    }
  `,
};
