import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { DiscountCreate, DiscountDelete, DiscountUpdate } from 'src/app/shared/api/mutations/discount-mutations';
import {
  priceSheetAssign,
  priceSheetCreate,
  priceSheetDelete,
  priceSheetUnassign,
  priceSheetUpdate,
} from 'src/app/shared/api/mutations/price-sheet-mutations';
import { discountListQuery } from 'src/app/shared/api/queries/discount-querise';
import { getPriceSheet, priceSheetListQuery } from 'src/app/shared/api/queries/price-sheet-queries';
import {
  Discount,
  DiscountCreateInput,
  DiscountSearchInput,
  DiscountUpdateInput,
  Pricesheet,
  PricesheetAssignInput,
  PricesheetCreateInput,
  PricesheetSearchInput,
  PricesheetUnassignInput,
  PricesheetUpdateInput,
} from 'src/app/shared/api/types/GraphQL';
import {
  discountCreateResponse,
  discountUpdateResponse,
  discountsResponse,
  priceSheetAssignResponse,
  priceSheetCreateResponse,
  priceSheetResponse,
  priceSheetUnassignResponse,
  priceSheetUpdateResponse,
} from 'src/app/shared/api/types/types';

@Injectable({
  providedIn: 'root',
})
export class PriceSheetService {
  constructor(private apollo: Apollo) {}

  getPriceSheetList(input: PricesheetSearchInput): QueryRef<priceSheetResponse> {
    return this.apollo.watchQuery<priceSheetResponse>({
      query: priceSheetListQuery,
      variables: { input },
    });
  }
  getPriceSheet(id: String): QueryRef<Pricesheet> {
    return this.apollo.watchQuery<Pricesheet>({
      query: getPriceSheet,
      variables: { id },
    });
  }

  priceSheetCreate(input: PricesheetCreateInput) {
    return this.apollo.mutate<priceSheetCreateResponse>({
      mutation: priceSheetCreate,
      variables: { input },
    });
  }

  priceSheetUpdate(variables: { id: string; input: PricesheetUpdateInput }) {
    return this.apollo.mutate<priceSheetUpdateResponse>({
      mutation: priceSheetUpdate,
      variables,
    });
  }

  priceSheetDelete(id: string) {
    return this.apollo.mutate<Pricesheet>({
      mutation: priceSheetDelete,
      variables: { id },
    });
  }

  priceSheetAssign(id: string, input: PricesheetAssignInput) {
    return this.apollo.mutate<priceSheetAssignResponse>({
      mutation: priceSheetAssign,
      variables: { id, input },
    });
  }

  priceSheetUnassign(id: string, input: PricesheetUnassignInput) {
    return this.apollo.mutate<priceSheetUnassignResponse>({
      mutation: priceSheetUnassign,
      variables: { id, input },
    });
  }

  // Discount
  getDiscountList(input?: DiscountSearchInput): QueryRef<discountsResponse> {
    return this.apollo.watchQuery<discountsResponse>({
      query: discountListQuery,
      variables: { input },
    });
  }

  discountCreate(input: DiscountCreateInput) {
    return this.apollo.mutate<discountCreateResponse>({
      mutation: DiscountCreate,
      variables: { input },
    });
  }

  discountUpdate(variables: { id: string; input: DiscountUpdateInput }) {
    return this.apollo.mutate<discountUpdateResponse>({
      mutation: DiscountUpdate,
      variables,
    });
  }

  discountDelete(id: string) {
    return this.apollo.mutate<Discount>({
      mutation: DiscountDelete,
      variables: { id },
    });
  }
}
