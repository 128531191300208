import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ConfirmDialogType, DialogButtonsText } from '../../api/types/enums';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  type: ConfirmDialogType;
  title: string;
  message: string;
  confirmTxt: string;
  discardTxt: string;
  cancelTxt: string;

  display: string | string[] | Set<string> | { [klass: string]: any };

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel,
  ) {
    this.title = data.title;
    this.message = data.message;
    this.confirmTxt = data.confirmBtn ? data.confirmBtn : 'Ok';
    this.discardTxt = data.discardBtn ? data.discardBtn : null;
    this.type = data.type ? data.type : ConfirmDialogType.CONFIRM;

    this.cancelTxt =
      this.type === ConfirmDialogType.SAVE && data.cancelBtn
        ? data.cancelBtn
        : this.type === ConfirmDialogType.SAVE && !data.cancelBtn
          ? null
          : 'Cancel';
  }

  ngOnInit(): void {}

  onConfirm(): void {
    this.dialogRef.close(DialogButtonsText.CONFIRM);
  }

  onDiscard(): void {
    this.dialogRef.close(DialogButtonsText.DISCARD);
  }

  onDismiss(): void {
    this.dialogRef.close(DialogButtonsText.CANCEL);
  }

  getDialogWidth(): string {
    return this.discardTxt ? '130%' : '100%';
  }
}

export class ConfirmDialogModel {
  constructor(
    public title: string,
    public message: string,
    public confirmBtn?: string,
    public discardBtn?: string,
    public type?: ConfirmDialogType,
    public cancelBtn?: string,
  ) {}
}
