<div class="search-wrapper">
  <!-- search button -->
  <mat-form-field appearance="outline" class="generalFilter">
    <mat-icon matPrefix>search</mat-icon>
    <input
      matInput
      [disabled]="isFilterSelectDisabled"
      (input)="searchFilterInput$.next($event)"
      placeholder="Search"
      floatPlaceholder=" never"
      matTooltip="Minimum of 3 characters needed"
      matTooltipPosition="below"
    />
  </mat-form-field>
  <!-- /search button -->

  <!-- search by term button -->
  <div>
    <mat-form-field appearance="outline" color="primary" class="filters-dropdown selected-search-filters">
      <mat-select
        multiple
        [(ngModel)]="selectedFields"
        placeholder="Selected search fields ({{ selectedFields?.length }})"
      >
        <mat-select-trigger class="selected-fields"
          >Selected search fields ({{ selectedFields?.length }})</mat-select-trigger
        >
        <button mat-stroked-button (click)="toggleSelect()">
          {{ selectedFields?.length === companiesSearchFields?.length ? 'Unselect all' : 'Select all' }}
        </button>
        <mat-option *ngFor="let field of companiesSearchFields" [value]="field.value" (click)="togglePerOne()">{{
          field.label
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <!-- /search by term button -->

  <!-- filter button -->
  <div>
    <mat-form-field appearance="outline" color="primary" class="filters-dropdown" (click)="toggleFiltersDrawer()">
      <mat-select
        [disabled]="isFilterSelectDisabled"
        placeholder="Filters ({{ numOfSelectedFilters }})"
        floatPlaceholder="never"
        name="filters"
      >
      </mat-select>
    </mat-form-field>
  </div>
  <!-- /filter button -->

  <!-- selected-filters -->
  <div class="selected-filters general">
    <!-- singularFilters/selected-filters  -->
    <ng-container *ngFor="let filter of singularFilters; let i = index">
      <div *ngIf="companiesFilters[filter.filterKey]" class="general">
        <mat-chip-row class="filter-chip" (removed)="remove(filter.filterKey)">
          <span class="chip-label">{{ filter.title + ': ' + companiesFilters[filter.filterKey] }}</span>
          <button matChipRemove [attr.aria-label]="'remove ' + companiesFilters[filter.filterKey]">
            <mat-icon>close</mat-icon>
          </button>
        </mat-chip-row>
      </div>
    </ng-container>
    <!-- /singularFilters/selected-filters  -->

    <!-- arrayedFilters/selected-filters  -->
    <ng-container *ngFor="let filter of arrayedFilters; let i = index">
      <div *ngIf="companiesFilters[filter.filterKey]" class="general">
        <mat-chip-row
          class="filter-chip"
          *ngFor="let data of companiesFilters[filter.filterKey]; let j = index"
          (removed)="removeArray(j, filter.filterKey)"
        >
          <span class="chip-label">{{ filter.subTitle + ': ' + data }}</span>
          <button matChipRemove [attr.aria-label]="'remove ' + data">
            <mat-icon>close</mat-icon>
          </button>
        </mat-chip-row>
      </div>
    </ng-container>
    <!-- /arrayedFilters/selected-filters  -->

    <!-- checkBoxFilters/selected-filters  -->
    <ng-container *ngFor="let filter of checkBoxFilters; let i = index">
      <div *ngIf="companiesFilters[filter.filterKey]" class="general">
        <div *ngFor="let option of filter.options; let j = index">
          <mat-chip-row
            class="filter-chip"
            *ngIf="option.isChecked"
            (removed)="removeCheckBox(filter.filterKey, option)"
          >
            <span class="chip-label">{{ filter.subTitle + ': ' + option.name }}</span>
            <button matChipRemove [attr.aria-label]="'remove ' + option.name">
              <mat-icon>close</mat-icon>
            </button>
          </mat-chip-row>
        </div>
      </div>
    </ng-container>
    <!-- /checkBoxFilters/selected-filters  -->

    <!-- optionFilters/selected-filters  -->
    <ng-container *ngFor="let filter of optionFilters; let i = index">
      <div
        *ngIf="companiesFilters[filter.filterKey] !== null && companiesFilters[filter.filterKey] !== undefined"
        class="general"
      >
        <mat-chip-row class="filter-chip" (removed)="removeRadioButton(filter.filterKey)">
          <span class="chip-label">{{ getChipLabel(filter.filterKey, companiesFilters[filter.filterKey]) }}</span>
          <button matChipRemove [attr.aria-label]="'remove ' + companiesFilters[filter.filterKey]">
            <mat-icon>close</mat-icon>
          </button>
        </mat-chip-row>
      </div>
    </ng-container>
    <!-- /optionFilters/selected-filters  -->

    <!-- dateFilters/selected-filters  -->
    <ng-container *ngFor="let filter of dateFilters; let i = index">
      <div *ngIf="companiesFilters[filter.filterKey]" class="general">
        <mat-chip-row
          class="filter-chip"
          *ngIf="companiesFilters[filter.filterKey] && !isEmpty(companiesFilters[filter.filterKey])"
          (removed)="removeDatePicker(filter.filterKey)"
        >
          <div class="chip-label-date">
            {{ filter.title }}
            <span *ngIf="companiesFilters[filter.filterKey]?.greaterThan">
              From: {{ companiesFilters[filter.filterKey].greaterThan | date: 'shortDate' }}
            </span>
            <span *ngIf="companiesFilters[filter.filterKey]?.lessThan">
              To: {{ companiesFilters[filter.filterKey].lessThan | date: 'shortDate' }}
            </span>
            <button matChipRemove [attr.aria-label]="'removeArray ' + filter.filterKey">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </mat-chip-row>
      </div>
    </ng-container>
    <!-- /dateFilters/selected-filters  -->

    <!-- /selected-filters -->
  </div>

  <!-- filters drawer -->
  <div class="amp-app">
    <div class="filter-drawer-wrapper" [hidden]="!filtersDrawerOpened">
      <div class="filter-drawer">
        <div class="drawer-header">
          <div class="mat-h4">More filters</div>
          <div class="close-drawer" (click)="filtersDrawerOpened = !filtersDrawerOpened">
            <mat-icon>close</mat-icon>
          </div>
        </div>
        <mat-accordion class="dialog-accordion" multi>
          <!-- singularFilters -->
          <mat-expansion-panel *ngFor="let filter of singularFilters; let i = index" #panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="title mat-body-2">{{ filter.title }}</div>
              </mat-panel-title>
              <mat-panel-description *ngIf="!panel.expanded">
                <div class="selected-filters inner">
                  <mat-chip-row
                    *ngIf="companiesFilters[filter.filterKey]"
                    class="filter-chip"
                    (removed)="remove(filter.filterKey)"
                  >
                    <span class="chip-label">{{ companiesFilters[filter.filterKey] }}</span>
                    <button matChipRemove [attr.aria-label]="'remove' + companiesFilters[filter.filterKey]">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-chip-row>
                </div>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <form class="object-mt-2">
              <mat-form-field class="object-mt-2" appearance="outline" class="chip-list">
                <mat-chip-grid #chipGrid>
                  <mat-chip-row
                    *ngIf="companiesFilters[filter.filterKey]"
                    class="filter-chip"
                    (removed)="remove(filter.filterKey)"
                  >
                    <span class="chip-label">{{ companiesFilters[filter.filterKey] }}</span>
                    <button matChipRemove [attr.aria-label]="'remove ' + companiesFilters[filter.filterKey]">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-chip-row>
                </mat-chip-grid>
                <input
                  placeholder="Enter {{ filter.title | lowercase }}"
                  #chipInput
                  [formControl]="filtersCtrl[filter.filterKey]"
                  [matChipInputFor]="chipGrid"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="add($event, filtersCtrl[filter.filterKey].value, filter.filterKey)"
                />
              </mat-form-field>
            </form>
          </mat-expansion-panel>
          <!-- /singularFilters -->

          <!-- arrayedFilters -->
          <ng-container *ngFor="let filter of arrayedFilters; let i = index">
            <mat-expansion-panel #panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="title mat-body-2">{{ filter.title }}</div>
                </mat-panel-title>
                <mat-panel-description *ngIf="!panel.expanded">
                  <div class="selected-filters inner">
                    <mat-chip-row
                      class="filter-chip"
                      *ngFor="let data of companiesFilters[filter.filterKey]; let j = index"
                      (removed)="removeArray(j, filter.filterKey)"
                    >
                      <span class="chip-label">{{ data }}</span>
                      <button matChipRemove [attr.aria-label]="'remove ' + data">
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-chip-row>
                  </div>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <form class="object-mt-2">
                <mat-form-field class="object-mb-2" appearance="outline" class="chip-list">
                  <mat-chip-grid #chipGrid>
                    <mat-chip-row
                      class="filter-chip"
                      *ngFor="let data of companiesFilters[filter.filterKey]; let j = index"
                      (removed)="removeArray(j, filter.filterKey)"
                    >
                      <span class="chip-label">{{ data }}</span>
                      <button matChipRemove [attr.aria-label]="'remove ' + data">
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-chip-row>
                  </mat-chip-grid>
                  <input
                    placeholder="Enter {{ filter.title | lowercase }}"
                    #chipInput
                    [formControl]="filtersCtrl[filter.filterKey]"
                    [matChipInputFor]="chipGrid"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="add($event, filtersCtrl[filter.filterKey].value, filter.filterKey)"
                  />
                </mat-form-field>
              </form>
            </mat-expansion-panel>
          </ng-container>
          <!-- /arrayedFilters -->

          <!-- checkBoxFilters -->
          <mat-expansion-panel *ngFor="let filter of checkBoxFilters; let i = index" #panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="title mat-body-2">{{ filter.title }}</div>
              </mat-panel-title>
              <mat-panel-description *ngIf="!panel.expanded">
                <div class="selected-filters inner">
                  <div *ngFor="let option of filter.options; let j = index">
                    <mat-chip-row
                      class="filter-chip"
                      *ngIf="option.isChecked"
                      (removed)="removeCheckBox(filter.filterKey, option)"
                    >
                      <span class="chip-label">{{ option.name }}</span>
                      <button matChipRemove [attr.aria-label]="'remove ' + option.name">
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-chip-row>
                  </div>
                </div>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <form>
              <div class="object-mb-2 object-mt-2">
                <mat-checkbox
                  class="checkbox-button"
                  color="primary"
                  *ngFor="let option of filter.options"
                  [(ngModel)]="option.isChecked"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="toggleCheckbox(filter.filterKey, option)"
                >
                  {{ option.name }}
                </mat-checkbox>
              </div>
            </form>
          </mat-expansion-panel>
          <!-- /checkBoxFilters -->

          <!-- optionFilters -->
          <mat-expansion-panel *ngFor="let filter of optionFilters; let i = index" #panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="title mat-body-2">{{ filter.title }}</div>
              </mat-panel-title>
              <mat-panel-description *ngIf="!panel.expanded">
                <div class="selected-filters inner">
                  <div
                    *ngIf="
                      companiesFilters[filter.filterKey] !== null && companiesFilters[filter.filterKey] !== undefined
                    "
                  >
                    <mat-chip-row class="filter-chip" (removed)="removeRadioButton(filter.filterKey)">
                      <span class="chip-label">{{
                        getChipLabel(filter.filterKey, companiesFilters[filter.filterKey])
                      }}</span>
                      <button matChipRemove [attr.aria-label]="'remove ' + companiesFilters[filter.filterKey]">
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-chip-row>
                  </div>
                </div>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <form>
              <div class="object-mb-2 object-mt-2">
                <mat-radio-group
                  class="radio-button"
                  [(ngModel)]="selectedOptions[filter.filterKey]"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <mat-radio-button
                    color="primary"
                    *ngFor="let option of filter.options"
                    [value]="option.value"
                    (change)="add($event, option.value, filter.filterKey)"
                  >
                    {{ option.label }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </form>
          </mat-expansion-panel>
          <!-- /optionFilters -->

          <!-- DatePickers -->
          <ng-container *ngFor="let dateFilter of dateFilters">
            <mat-expansion-panel [attr.id]="dateFilter.filterKey" #panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="title mat-body-2">{{ dateFilter.title }}</div>
                </mat-panel-title>
                <mat-panel-description *ngIf="!panel.expanded">
                  <div class="selected-filters inner">
                    <mat-chip-row
                      class="filter-chip"
                      *ngIf="companiesFilters[dateFilter.filterKey] && !isEmpty(companiesFilters[dateFilter.filterKey])"
                      (removed)="removeDatePicker(dateFilter.filterKey)"
                    >
                      <div class="chip-label-date">
                        <span *ngIf="companiesFilters[dateFilter.filterKey]?.greaterThan">
                          From: {{ companiesFilters[dateFilter.filterKey].greaterThan | date: 'shortDate' }}
                        </span>
                        <span *ngIf="companiesFilters[dateFilter.filterKey]?.lessThan">
                          To: {{ companiesFilters[dateFilter.filterKey].lessThan | date: 'shortDate' }}
                        </span>
                        <button matChipRemove [attr.aria-label]="'removeArray ' + dateFilter.filterKey">
                          <mat-icon>close</mat-icon>
                        </button>
                      </div>
                    </mat-chip-row>
                  </div>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="date-picker">
                <form [formGroup]="dateFilter.control">
                  <mat-form-field class="object-mt-2" appearance="outline">
                    <mat-label>Enter a date range</mat-label>
                    <mat-date-range-input [formGroup]="dateFilter.control" [rangePicker]="picker">
                      <input matStartDate formControlName="greaterThan" placeholder="Start date" />
                      <input matEndDate formControlName="lessThan" placeholder="End date" />
                    </mat-date-range-input>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker
                      #picker
                      (closed)="add($event, dateFilter.control.value, dateFilter.filterKey, 'datePicker')"
                    ></mat-date-range-picker>
                  </mat-form-field>
                </form>
              </div>
            </mat-expansion-panel>
          </ng-container>
          <!-- /DatePickers -->
        </mat-accordion>
      </div>
    </div>
  </div>

  <!-- /filters drawer -->
</div>
