import { Action } from '@ngrx/store';
import { Order, OrderSearchArguments } from 'src/app/shared/api/types/GraphQL';

export const GET_CURRENT_SP_PAGE = '[Orders] Get Current SP Page';
export const GET_CURRENT_BO_PAGE = '[Orders] Get Current BO Page';
export const GET_CURRENT_SP_PAGE_SIZE = '[Orders] Get Current SP Page Size';
export const GET_CURRENT_BO_PAGE_SIZE = '[Orders] Get Current BO Page Size';
export const GET_ORDERS_COUNT = '[Orders] Get Orders Count';
export const LOAD_ORDERS = '[Orders] Load Orders';
export const REFETCH_ORDERS = '[Orders] Refetch Orders';
export const LOAD_ORDERS_SUCCESS = '[Orders] Load Orders Success';
export const LOAD_ORDERS_FAILURE = '[Orders] Load Orders Failure';

export const ADD_ORDER = '[Orders] Add Order';
export const UPDATE_ORDER = '[Orders] Update Order';
export const DELETE_ORDER = '[Orders] Delete Order';

export const ADD_ORDER_TO_LIST = '[Orders] Add Selected Order to List';
export const DELETE_ORDER_FROM_LIST = '[Orders] Delete Order from List';
export const CLEAR_ORDERS_LIST = '[Orders] Clear Orders List';

export const GET_COLUMN_FILTERS = '[Orders] Get Column Filters';
export const GET_MULTISELECT_FILTERS = '[Orders] Get Multiselect Filters';
export const GET_GENERAL_FILTER = '[Orders] Get General Filter';
export const ADD_GENERAL_FILTER = '[Orders] Add General Filter';
export const ADD_GENERAL_FILTER_ORDER = '[Orders] Add General Filter Order';
export const REMOVE_GENERAL_FILTER = '[Orders] Remove General Filter';
export const CLEAR_ALL_FILTERS = '[Orders] Clear All Filters';

export const PRODUCT_TABLE_COLUMNS = '[Orders] Product Table Columns';
export const SP_ORDERS_TABLE_COLUMNS = '[Orders] SP Orders Table Columns';
export const BO_ORDERS_TABLE_COLUMNS = '[Orders] BO Orders Table Columns';

export const ORDERS_PAGE_INITIALIZED = '[Orders Page] Initialized';
export const ORDERS_PAGE_DESTROYED = '[Orders Page] Destroyed';

export class GetCurrentSpPage implements Action {
  readonly type = GET_CURRENT_SP_PAGE;

  constructor(public payload: number) {}
}

export class GetCurrentBoPage implements Action {
  readonly type = GET_CURRENT_BO_PAGE;

  constructor(public payload: number) {}
}

export class GetCurrentSpPageSize implements Action {
  readonly type = GET_CURRENT_SP_PAGE_SIZE;

  constructor(public payload: number) {}
}

export class GetCurrentBoPageSize implements Action {
  readonly type = GET_CURRENT_BO_PAGE_SIZE;

  constructor(public payload: number) {}
}

export class GetOrdersCount implements Action {
  readonly type = GET_ORDERS_COUNT;

  constructor(public payload: number) {}
}

export class LoadOrders implements Action {
  readonly type = LOAD_ORDERS;

  constructor(public payload: { input: OrderSearchArguments; app: string }) {}
}

export class RefetchOrders implements Action {
  readonly type = REFETCH_ORDERS;

  constructor(public payload: any) {}
}

export class LoadOrdersSuccess implements Action {
  readonly type = LOAD_ORDERS_SUCCESS;

  constructor(public payload: Order[]) {}
}

export class LoadOrdersFailure implements Action {
  readonly type = LOAD_ORDERS_FAILURE;

  constructor(public payload: Error) {}
}

export class AddOrder implements Action {
  readonly type = ADD_ORDER;

  constructor(public payload: Order) {}
}

export class UpdateOrder implements Action {
  readonly type = UPDATE_ORDER;

  constructor(public payload: { id: number; updatedOrder: Order }) {}
}

export class DeleteOrder implements Action {
  readonly type = DELETE_ORDER;

  constructor(public payload: number) {}
}

export class AddOrderToList implements Action {
  readonly type = ADD_ORDER_TO_LIST;

  constructor(public payload: Order) {}
}

export class DeleteOrderFromList implements Action {
  readonly type = DELETE_ORDER_FROM_LIST;

  constructor(public payload: number) {}
}

export class ClearOrdersList implements Action {
  readonly type = CLEAR_ORDERS_LIST;
}

export class GetColumnFilters implements Action {
  readonly type = GET_COLUMN_FILTERS;

  constructor(public payload: { columnName; filterValue }) {}
}
export class GetMultiselectFilters implements Action {
  readonly type = GET_MULTISELECT_FILTERS;

  constructor(public payload: OrderSearchArguments) {}
}

export class GetGeneralFilter implements Action {
  readonly type = GET_GENERAL_FILTER;

  constructor(public payload: string) {}
}

export class AddGeneralFilter implements Action {
  readonly type = ADD_GENERAL_FILTER;

  constructor(public payload: OrderSearchArguments) {}
}

export class AddGeneralFilterOrder implements Action {
  readonly type = ADD_GENERAL_FILTER_ORDER;

  constructor(public payload: OrderSearchArguments) {}
}

export class RemoveGeneralFilter implements Action {
  readonly type = REMOVE_GENERAL_FILTER;

  constructor(public payload: { columnName: string; index?: number }) {}
}

export class ClearAllFilters implements Action {
  readonly type = CLEAR_ALL_FILTERS;
}

export class ProductTableColumns implements Action {
  readonly type = PRODUCT_TABLE_COLUMNS;

  constructor(
    public payload: {
      displayedColumns: string[];
      hiddenColumns: string[];
      availableColumns: string[];
      disabledColumns: string[];
      disabledSuffixLength: number;
    },
  ) {}
}

export class SpOrdersTableColumns implements Action {
  readonly type = SP_ORDERS_TABLE_COLUMNS;

  constructor(
    public payload: {
      displayedColumns: string[];
      hiddenColumns: string[];
      availableColumns: string[];
      disabledColumns: string[];
      disabledSuffixLength: number;
    },
  ) {}
}

export class BoOrdersTableColumns implements Action {
  readonly type = BO_ORDERS_TABLE_COLUMNS;

  constructor(
    public payload: {
      displayedColumns: string[];
      hiddenColumns: string[];
      availableColumns: string[];
      disabledColumns: string[];
      disabledSuffixLength: number;
    },
  ) {}
}

export class OrdersPageInitialized implements Action {
  readonly type = ORDERS_PAGE_INITIALIZED;
}

export class OrdersPageDestroyed implements Action {
  readonly type = ORDERS_PAGE_DESTROYED;
}

export type OrdersActions =
  | GetCurrentSpPage
  | GetCurrentBoPage
  | GetCurrentSpPageSize
  | GetCurrentBoPageSize
  | GetOrdersCount
  | LoadOrders
  | RefetchOrders
  | LoadOrdersSuccess
  | LoadOrdersFailure
  | AddOrder
  | UpdateOrder
  | DeleteOrder
  | AddOrderToList
  | DeleteOrderFromList
  | ClearOrdersList
  | GetColumnFilters
  | GetMultiselectFilters
  | GetGeneralFilter
  | AddGeneralFilter
  | RemoveGeneralFilter
  | ClearAllFilters
  | ProductTableColumns
  | SpOrdersTableColumns
  | BoOrdersTableColumns
  | OrdersPageInitialized
  | AddGeneralFilterOrder
  | OrdersPageDestroyed;
