import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { CarriersService } from 'src/app/order-management/carriers/carriers.service';

@Pipe({
  name: 'logoValidity',
})
export class LogoValidityPipe implements PipeTransform {
  constructor(private carriersService: CarriersService) {}

  transform(imageUrl: string): Observable<boolean> {
    return this.carriersService.checkLogoValidity(imageUrl);
  }
}
