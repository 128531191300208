import { gql } from 'apollo-angular';
import { globals } from 'src/app/shared/globals';

export const FavoriteListsQuery = gql`
  query favoriteLists($input: FavoriteListsSearchInput!) {
    favoriteLists(input: $input) {
      page
      itemsFound
      offset
      items {
        id
        name
        companyId
        contactId
        customerId
        isDefault
        slug
        updatedAt
        createdAt
        products(input: {}) {
          itemsFound
        }
      }
    }
  }
`;

export const FavoriteListDetailsQuery = gql`
  query favoriteList($id: String!) {
    favoriteList(id: $id) {
      name
      companyId
      contactId
      customerId
      isDefault
      slug
      products(input: {}) {
        items {
          ... on Product {
            id
            language
            class
            hidden
            names(language: "${globals.appLanguage}") {
            language
              value
            }
            productId
            class
            supplier
            manufacturerCode
            sku
            media {
              images(search: {sort: ASC, offset: ${globals.globalOffset}}) {
                items {
                  imageVariants(
                    input: {
                    transformations: [{
                        name: "xs"
                        transformation: { width: 100, height: 100, fit: BOUNDS, bgColor: "transparent", canvas: {width: 100, height: 100} }
                    }]
                    }
                ) {
                    language
                    name
                    url
                  }
                }
              }
            }
          }
        }
        itemsFound
      }
      clusters(input: {}) {
        items {
          id
          language
          class
          hidden
          names(language: "${globals.appLanguage}") {
            language
            value
          }
          sku
          categoryId
        }
        itemsFound
      }
      updatedAt
      createdAt
    }
  }
`;
