import { Category } from 'src/app/shared/api/types/GraphQL';
import * as CategoriesActions from './categories.actions';
import { CategoryNode } from 'src/app/shared/api/types/interfaces';
import { StateEntry } from 'src/app/shared/api/types/enums';

export interface State {
  listing: StateProperties;
  picker: StateProperties;
  stateEntry: StateEntry;
}
export interface StateProperties {
  loading: boolean;
  loaded: boolean;
  categories: CategoryNode[];
  error: Error;
}

const initialStateProperties: StateProperties = {
  loading: false,
  loaded: false,
  categories: [],
  error: undefined,
};

const initialState: State = {
  stateEntry: StateEntry.listing,
  listing: initialStateProperties,
  picker: initialStateProperties,
};

function updateCategory(categories: Category[], categoryId: number, categoryName: string): Category[] {
  return categories.map((category: Category) => {
    if (category.categoryId === categoryId) {
      return {
        ...category,
        name: [{ ...category.name[0], value: categoryName }],
      };
    }
    if (category.categories && category.categories.length > 0) {
      return {
        ...category,
        categories: updateCategory(category.categories, categoryId, categoryName),
      };
    }
    return category;
  });
}

export function categoriesReducer(state: State = initialState, action: CategoriesActions.CategoriesActions) {
  switch (action.type) {
    case CategoriesActions.RESET_STATE_ENTRY_STATE:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          ...initialStateProperties,
        },
      };
    case CategoriesActions.SET_STATE_ENTRY:
      return {
        ...state,
        stateEntry: action.payload,
      };
    case CategoriesActions.SET_LOADER:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          loading: action.payload.isLoading,
        },
      };
    case CategoriesActions.LOAD_CATEGORIES:
      let loading;
      state[action.payload.stateEntry].loaded ? (loading = false) : (loading = true);
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          loading: loading,
        },
      };
    case CategoriesActions.LOAD_CATEGORIES_SUCCESS:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          categories: [action.payload.category],
          loading: false,
          loaded: true,
        },
      };
    case CategoriesActions.LOAD_INNER_CATEGORIES:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          categories: action.payload.arguments,
        },
      };
    case CategoriesActions.LOAD_CATEGORIES_FAILURE:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          error: action.payload.error,
          loading: false,
        },
      };
    case CategoriesActions.ADD_CATEGORY:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          categories: [...state[action.payload.stateEntry].categories, action.payload.category],
        },
      };
    case CategoriesActions.UPDATE_CATEGORY:
      const { categoryId, categoryName, stateEntry } = action.payload;
      return {
        ...state,
        [stateEntry]: {
          ...state[stateEntry],
          categories: updateCategory(state[stateEntry].categories, categoryId, categoryName),
        },
      };

    default:
      return state;
  }
}
