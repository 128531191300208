<div class="table-wrapper">
  <table mat-table class="main-list-table full" [dataSource]="usergroups" matTableExporter #exporter="matTableExporter">
    <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column.name">
      <th mat-header-cell *matHeaderCellDef>
        <span>{{ column.label }}</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!loading">{{ column | content: element }}</span>
        <ngx-skeleton-loader
          count="1"
          *ngIf="loading"
          [theme]="{ height: '24px', display: 'flex', 'margin-bottom': '0' }"
        >
        </ngx-skeleton-loader>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
    <tr mat-row *matRowDef="let row; columns: columnNames" (click)="closeDialog(row.usergroupId)"></tr>
  </table>

  <mat-paginator
    [length]="usergroupsCount"
    [pageSize]="5"
    [pageSizeOptions]="[5, 10, 25, 100]"
    aria-label="Select page of usergroups"
  ></mat-paginator>
</div>
