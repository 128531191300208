<div class="add-tags-wrapper" *ngIf="tagsValuesetId">
  <button mat-button class="mat-body-strong" (click)="onAddTagsOpen()">Add Groups</button>
  <mat-form-field appearance="outline" class="tags-select">
    <mat-select
      [(ngModel)]="selectedUserTags"
      (openedChange)="onOpenedChange($event)"
      #tagSelect
      panelClass="tags-select-panel"
      multiple
    >
      <mat-select-trigger></mat-select-trigger>
      <div class="tags-popup">
        <div class="tags-header-wrapper">
          <div class="mat-subtitle-1 caption-color">Group as:</div>
          <mat-form-field appearance="outline" class="generalFilter">
            <mat-icon matPrefix>search</mat-icon>
            <input
              matInput
              placeholder="Search"
              floatPlaceholder="never"
              [(ngModel)]="tagsSearchTerm"
              (input)="onTagSearch($event)"
              (keydown)="$event.stopPropagation()"
            />
          </mat-form-field>
        </div>
        <div
          class="tags-list-wrapper"
          *ngIf="userTags"
          (scroll)="loadMore($event)"
          [ngClass]="{ 'apply-height': isTagsListChanged }"
        >
          <ng-container *ngIf="!isSearchTagMode">
            <mat-option *ngFor="let item of userTags" [value]="item">
              <mat-checkbox
                color="primary"
                [indeterminate]="!item.isInAll && item.isPreselected && !item.isRemoved"
                [checked]="item.isInAll"
                (change)="toggleSelection(item, $event)"
                (click)="$event.stopPropagation()"
              >
                {{ item.value }}
              </mat-checkbox>
            </mat-option>
          </ng-container>
          <ng-container *ngIf="isSearchTagMode">
            <mat-option *ngFor="let item of searchUserTags" [value]="item">
              <mat-checkbox
                color="primary"
                [indeterminate]="!item.isInAll && item.isPreselected && !item.isRemoved"
                [checked]="item.isInAll"
                (change)="toggleSelection(item, $event)"
                (click)="$event.stopPropagation()"
              >
                {{ item.value }}
              </mat-checkbox>
            </mat-option>
          </ng-container>
        </div>
        <div class="tags-footer-wrapper">
          <div class="general-actions" *ngIf="!isTagsListChanged && (!isSearchTagMode || searchUserTags.length > 0)">
            <button mat-flat-button class="footer-action caption-color" (click)="onCreateNewTag()">Create new</button>
            <!-- <button mat-flat-button class="footer-action caption-color">Manage label</button> -->
          </div>
          <div class="selection-actions" *ngIf="isTagsListChanged" [@fadeInAnimation]>
            <button mat-flat-button class="footer-action caption-color" (click)="onAddTags()">Apply</button>
          </div>
          <div class="search-actions" *ngIf="isSearchTagMode && searchUserTags.length === 0">
            <button mat-flat-button class="footer-action caption-color" (click)="onTagCreataeAndApply()">
              "{{ tagsSearchTerm }}" (create new and apply)
            </button>
          </div>
        </div>
      </div>
    </mat-select>
  </mat-form-field>
</div>
