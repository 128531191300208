import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import {
  CreateOrderStatusInput,
  OrderStatus,
  OrderStatusSearchByInput,
  OrderStatusSet,
  OrderStatusSetsSearchInput,
  OrderStatusesSearchInput,
  UpdateOrderStatusInput,
} from '../../shared/api/types/GraphQL';
import {
  OrderStatusNextStatuses,
  orderStatusListDropDownQuery,
  orderStatusesListQuery,
} from '../../shared/api/queries/order-status-query';
import {
  orderStatusCreate,
  orderStatusDelete,
  orderStatusUpdate,
} from '../../shared/api/mutations/order-status-mutations';
import {
  orderStatusCreateResponse,
  orderStatusResponse,
  orderStatusSetCreateResponse,
  orderStatusSetUpdateResponse,
  orderStatusSetsResponse,
  orderStatusUpdateResponse,
  orderStatusesResponse,
} from '../../shared/api/types/types';
import {
  orderStatusSetCreate,
  orderStatusSetDelete,
  orderStatusSetUpdate,
} from '../../shared/api/mutations/order-status-set-mutations';
import {
  orderStatusSetsListDropDownQuery,
  orderStatusSetsListQuery,
} from '../../shared/api/queries/order-status-set-query';

@Injectable({
  providedIn: 'root',
})
export class OrderStatusService {
  constructor(private apollo: Apollo) {}

  //OrderStatuses
  getOrderStatus(input: OrderStatusSearchByInput): QueryRef<orderStatusResponse> {
    return this.apollo.watchQuery<orderStatusResponse>({
      query: OrderStatusNextStatuses,
      variables: { input },
    });
  }

  getOrderStatusesList(input: OrderStatusesSearchInput): QueryRef<orderStatusesResponse> {
    return this.apollo.watchQuery<orderStatusesResponse>({
      query: orderStatusesListQuery,
      variables: { input },
    });
  }
  getOrderStatusListDropDown(input: OrderStatusesSearchInput): QueryRef<orderStatusesResponse> {
    return this.apollo.watchQuery<orderStatusesResponse>({
      query: orderStatusListDropDownQuery,
      variables: { input },
    });
  }
  orderStatusCreate(variables: { input: CreateOrderStatusInput }) {
    return this.apollo.mutate<orderStatusCreateResponse>({
      mutation: orderStatusCreate,
      variables,
    });
  }

  orderStatusUpdate(variables: { id: number; input: UpdateOrderStatusInput }) {
    return this.apollo.mutate<orderStatusUpdateResponse>({
      mutation: orderStatusUpdate,
      variables,
    });
  }

  orderStatusDelete(id: number) {
    return this.apollo.mutate<OrderStatus>({
      mutation: orderStatusDelete,
      variables: { id },
    });
  }

  //OrderStatusSet
  getOrderStatusSetList(input: OrderStatusSetsSearchInput): QueryRef<orderStatusSetsResponse> {
    return this.apollo.watchQuery<orderStatusSetsResponse>({
      query: orderStatusSetsListQuery,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
  }

  getOrderStatusSetListDropDown(input: OrderStatusSetsSearchInput): QueryRef<orderStatusSetsResponse> {
    return this.apollo.watchQuery<orderStatusSetsResponse>({
      query: orderStatusSetsListDropDownQuery,
      variables: { input },
    });
  }

  orderStatusSetCreate(variables: { input: CreateOrderStatusInput }) {
    return this.apollo.mutate<orderStatusSetCreateResponse>({
      mutation: orderStatusSetCreate,
      variables,
    });
  }

  orderStatusSetUpdate(variables: { id: number; input: UpdateOrderStatusInput }) {
    return this.apollo.mutate<orderStatusSetUpdateResponse>({
      mutation: orderStatusSetUpdate,
      variables,
    });
  }

  orderStatusSetDelete(id: number) {
    return this.apollo.mutate<OrderStatusSet>({
      mutation: orderStatusSetDelete,
      variables: { id },
    });
  }
}
