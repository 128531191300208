import { Action } from '@ngrx/store';
import { Carrier, CarriersSearchInput } from 'src/app/shared/api/types/GraphQL';

export const GET_CURRENT_PAGE = '[Carriers] Get Current Page';
export const GET_CURRENT_PAGE_SIZE = '[Carriers] Get Current Page Size';
export const GET_CARRIERS_COUNT = '[Carriers] Get Carriers Count';
export const LOAD_CARRIERS = '[Carriers] Load Carriers';
export const REFETCH_CARRIERS = '[Carriers] Refetch Carriers';
export const LOAD_CARRIERS_SUCCESS = '[Carriers] Load Carriers Success';
export const LOAD_CARRIERS_FAILURE = '[Carriers] Load Carriers Failure';

export const ADD_CARRIER = '[Carriers] Add Carrier';
export const UPDATE_CARRIER = '[Carriers] Update Carrier';
export const DELETE_CARRIER = '[Carriers] Delete Carrier';

export const GET_GENERAL_FILTER = '[Carriers] Get General Filter';
export const GET_ACTIVE_TAB = '[Carriers] Get Active Tab';
export const ADD_SORT_FILTERS = '[Carriers] Add Sort Filters';
export const GET_MULTISELECT_FILTERS = '[Carriers] Get Multiselect Filters';

export const ADD_CARRIER_TO_LIST = '[Carriers] Add Selected Carrier to List';
export const DELETE_CARRIER_FROM_LIST = '[Carriers] Delete Carrier from List';
export const CLEAR_CARRIERS_LIST = '[Carriers] Clear Carriers List';

export const CARRIERS_PAGE_INITIALIZED = '[Carriers] Page Initialized';
export const CARRIERS_PAGE_DESTROYED = '[Carriers] Page Destroyed';

export class GetCurrentPage implements Action {
  readonly type = GET_CURRENT_PAGE;

  constructor(public payload: number) {}
}

export class GetCurrentPageSize implements Action {
  readonly type = GET_CURRENT_PAGE_SIZE;

  constructor(public payload: number) {}
}

export class GetCarriersCount implements Action {
  readonly type = GET_CARRIERS_COUNT;

  constructor(public payload: number) {}
}

export class GetActiveTab implements Action {
  readonly type = GET_ACTIVE_TAB;

  constructor(public payload: number) {}
}

export class LoadCarriers implements Action {
  readonly type = LOAD_CARRIERS;

  constructor(public payload: {}) {}
}

export class RefetchCarriers implements Action {
  readonly type = REFETCH_CARRIERS;

  constructor(public payload: {}) {}
}

export class LoadCarriersSuccess implements Action {
  readonly type = LOAD_CARRIERS_SUCCESS;

  constructor(public payload: Carrier[]) {}
}

export class LoadCarriersFailure implements Action {
  readonly type = LOAD_CARRIERS_FAILURE;

  constructor(public payload: Error) {}
}

export class AddCarrier implements Action {
  readonly type = ADD_CARRIER;

  constructor(public payload: Carrier) {}
}

// export class UpdateCarrier implements Action {
//     readonly type = UPDATE_CARRIER;

//     constructor(public payload: {id: number, newCarrier: Order}) {}
// }
export class DeleteCarrier implements Action {
  readonly type = DELETE_CARRIER;

  constructor(public payload: number) {}
}

export class AddCarrierToList implements Action {
  readonly type = ADD_CARRIER_TO_LIST;

  constructor(public payload: Carrier) {}
}

export class DeleteCarrierFromList implements Action {
  readonly type = DELETE_CARRIER_FROM_LIST;

  constructor(public payload: number) {}
}

export class ClearCarriersList implements Action {
  readonly type = CLEAR_CARRIERS_LIST;
}

export class GetGeneralFilter implements Action {
  readonly type = GET_GENERAL_FILTER;

  constructor(public payload: string) {}
}

export class CarriersPageInitialized implements Action {
  readonly type = CARRIERS_PAGE_INITIALIZED;
}

export class CarriersPageDestroyed implements Action {
  readonly type = CARRIERS_PAGE_DESTROYED;
}

export class AddSortFilters implements Action {
  readonly type = ADD_SORT_FILTERS;

  constructor(public payload: CarriersSearchInput) {}
}

export class GetMultiselectFilters implements Action {
  readonly type = GET_MULTISELECT_FILTERS;

  constructor(public payload: CarriersSearchInput) {}
}

export type CarriersActions =
  | GetGeneralFilter
  | GetCurrentPage
  | GetCurrentPageSize
  | GetCarriersCount
  | GetActiveTab
  | LoadCarriers
  | RefetchCarriers
  | LoadCarriersSuccess
  | LoadCarriersFailure
  | AddCarrier
  //| UpdateCarrier
  | DeleteCarrier
  | AddCarrierToList
  | DeleteCarrierFromList
  | ClearCarriersList
  | CarriersPageInitialized
  | CarriersPageDestroyed
  | AddSortFilters
  | GetMultiselectFilters;
