import { Injectable } from '@angular/core';
import { Apollo, MutationResult, QueryRef } from 'apollo-angular';
import { Observable } from 'rxjs';
import { CreateSurcharge, DeleteSurcharge, UpdateSurcharges } from 'src/app/shared/api/mutations/surcharge-mutations';
import { SurchargesList } from 'src/app/shared/api/queries/surcharge-queries';
import { CreateSurchargeInput, SurchargeSearchInput, UpdateSurchargeInput } from 'src/app/shared/api/types/GraphQL';
import { SurchargeCreateResponse, SurchargeResponse, SurchargeUpdateResponse } from 'src/app/shared/api/types/types';

@Injectable({
  providedIn: 'root',
})
export class SurchargesService {
  constructor(private apollo: Apollo) {}

  getSurchargesList(input: SurchargeSearchInput): QueryRef<SurchargeResponse> {
    return this.apollo.watchQuery<SurchargeResponse>({
      query: SurchargesList,
      variables: { input },
    });
  }

  deleteSurcharge(id: string): Observable<MutationResult<Boolean>> {
    return this.apollo.mutate<Boolean>({
      mutation: DeleteSurcharge,
      variables: { id },
    });
  }

  createSurcharge(variables: CreateSurchargeInput): Observable<MutationResult<SurchargeCreateResponse>> {
    return this.apollo.mutate<SurchargeCreateResponse>({
      mutation: CreateSurcharge,
      variables: { input: variables },
    });
  }

  updateSurcharge(id: string, variables: UpdateSurchargeInput) {
    return this.apollo.mutate<SurchargeUpdateResponse>({
      mutation: UpdateSurcharges,
      variables: { id, input: variables },
    });
  }
}
