import { gql } from 'apollo-angular';

export const CreateCompanyMutation = gql`
  mutation CompanyCreate($input: CreateCompanyInput!) {
    companyCreate(input: $input) {
      companyId
    }
  }
`;

export const UpdateCompanyMutation = gql`
  mutation UpdateCompany($id: Int!, $input: UpdateCompanyInput!) {
    companyUpdate(id: $id, input: $input) {
      companyId
    }
  }
`;

export const UpdateCompanyAddressMutation = gql`
  mutation UpdateCompanyAddress($input: CompanyAddressUpdateInput!) {
    companyAddressUpdate(input: $input) {
      id
    }
  }
`;

export const CreateCompanyAddressMutation = gql`
  mutation CreateCompanyAddress($input: CompanyAddressCreateInput!) {
    companyAddressCreate(input: $input) {
      id
    }
  }
`;

export const DeleteCompanyAddressMutation = gql`
  mutation DeleteCompanyAddress($input: CompanyAddressDeleteInput!) {
    companyAddressDelete(input: $input)
  }
`;

export const DeleteCompanyMutation = gql`
  mutation DeleteCompany($id: Int!) {
    companyDelete(id: $id)
  }
`;
