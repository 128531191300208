import { gql } from 'apollo-angular';

export const UpdateContactMutation = gql`
  mutation UpdateContact($id: Int!, $input: UpdateContactInput!) {
    contactUpdate(id: $id, input: $input) {
      firstName
      middleName
      lastName
      gender
      email
      phone
      mobile
      dateOfBirth
      parentCompanyId
      primaryLanguage
    }
  }
`;

export const CreateContactMutation = gql`
  mutation ContactCreate($input: CreateContactInput!) {
    contactCreate(input: $input) {
      contactId
    }
  }
`;

export const DeleteContactMutation = gql`
  mutation DeleteContact($id: Int!) {
    contactDelete(id: $id)
  }
`;

export const ContactAddToCompanies = gql`
  mutation contactAddToCompanies($id: Int!, $input: ContactAddToCompaniesInput!) {
    contactAddToCompanies(id: $id, input: $input) {
      contact {
        companies {
          items {
            name
          }
        }
      }
      actions {
        data {
          message
          success
          companyId
        }
        messages
      }
    }
  }
`;

export const ContactRemoveFromCompanies = gql`
  mutation contactRemoveFromCompanies($id: Int!, $input: ContactRemoveFromCompaniesInput!) {
    contactRemoveFromCompanies(id: $id, input: $input) {
      contact {
        companies {
          items {
            name
          }
        }
      }
      actions {
        data {
          message
          success
          companyId
        }
        messages
      }
    }
  }
`;
