import { Action } from '@ngrx/store';
import {
  AttributeDescription,
  AttributeDescriptionClass,
  AttributeDescriptionSearchInput,
} from 'src/app/shared/api/types/GraphQL';

export const GET_CURRENT_PAGE = '[AttributeDescriptions] Get Current Page';
export const GET_CURRENT_PAGE_SIZE = '[AttributeDescriptions] Get Current Page Size';
export const GET_ATTRIBUTE_DESCRIPTIONS_COUNT = '[AttributeDescriptions] Get AttributeDescriptions Count';
export const LOAD_ATTRIBUTE_DESCRIPTIONS = '[AttributeDescriptions] Load AttributeDescriptions';
export const REFETCH_ATTRIBUTE_DESCRIPTIONS = '[AttributeDescriptions] Refetch AttributeDescriptions';
export const LOAD_ATTRIBUTE_DESCRIPTIONS_SUCCESS = '[AttributeDescriptions] Load AttributeDescriptions Success';
export const LOAD_ATTRIBUTE_DESCRIPTIONS_FAILURE = '[AttributeDescriptions] Load AttributeDescriptions Failure';

export const GET_GENERAL_FILTER = '[AttributeDescriptions] Get General Filter';
export const GET_MULTISELECT_FILTERS = '[AttributeDescriptions] Get Multiselect Filters';
export const ADD_SORT_FILTERS = '[AttributeDescriptions] Add Sort Filters';

export const GET_QUERY_VARIABLES = '[AttributeDescriptions] Get Query Variables';
export const GET_CLASS = '[AttributeDescriptions] Get class';

export const ADD_ATTRIBUTE_DESCRIPTION_TO_LIST = '[AttributeDescriptions] Add Selected AttributeDescription to List';
export const DELETE_ATTRIBUTE_DESCRIPTION_FROM_LIST = '[AttributeDescriptions] Delete AttributeDescription from List';
export const CLEAR_ATTRIBUTE_DESCRIPTIONS_LIST = '[AttributeDescriptions] Clear AttributeDescriptions List';

export class GetCurrentPage implements Action {
  readonly type = GET_CURRENT_PAGE;

  constructor(public payload: { page: number; attrClass: AttributeDescriptionClass }) {}
}

export class GetCurrentPageSize implements Action {
  readonly type = GET_CURRENT_PAGE_SIZE;

  constructor(public payload: { offset: number; attrClass: AttributeDescriptionClass }) {}
}

export class GetAttributeDescriptionsCount implements Action {
  readonly type = GET_ATTRIBUTE_DESCRIPTIONS_COUNT;

  constructor(public payload: { count: number; attrClass: AttributeDescriptionClass }) {}
}

export class LoadAttributeDescriptions implements Action {
  readonly type = LOAD_ATTRIBUTE_DESCRIPTIONS;

  constructor(public payload: { vars: AttributeDescriptionSearchInput; attrClass: AttributeDescriptionClass }) {}
}

export class RefetchAttributeDescriptions implements Action {
  readonly type = REFETCH_ATTRIBUTE_DESCRIPTIONS;

  constructor(public payload: { vars: AttributeDescriptionSearchInput; attrClass: AttributeDescriptionClass }) {}
}

export class LoadAttributeDescriptionsSuccess implements Action {
  readonly type = LOAD_ATTRIBUTE_DESCRIPTIONS_SUCCESS;

  constructor(public payload: { items: AttributeDescription[]; attrClass: AttributeDescriptionClass }) {}
}

export class LoadAttributeDescriptionsFailure implements Action {
  readonly type = LOAD_ATTRIBUTE_DESCRIPTIONS_FAILURE;

  constructor(public payload: { error: Error; attrClass: AttributeDescriptionClass }) {}
}

export class AddAttributeDescriptionToList implements Action {
  readonly type = ADD_ATTRIBUTE_DESCRIPTION_TO_LIST;

  constructor(public payload: { item: AttributeDescription; attrClass: AttributeDescriptionClass }) {}
}

export class DeleteAttributeDescriptionFromList implements Action {
  readonly type = DELETE_ATTRIBUTE_DESCRIPTION_FROM_LIST;

  constructor(public payload: { id: string; attrClass: AttributeDescriptionClass }) {}
}

export class ClearAttributeDescriptionsList implements Action {
  readonly type = CLEAR_ATTRIBUTE_DESCRIPTIONS_LIST;

  constructor(public payload: AttributeDescriptionClass) {}
}

export class GetGeneralFilter implements Action {
  readonly type = GET_GENERAL_FILTER;

  constructor(public payload: { filter: string; attrClass: AttributeDescriptionClass }) {}
}

export class AddSortFilters implements Action {
  readonly type = ADD_SORT_FILTERS;

  constructor(public payload: { sort: AttributeDescriptionSearchInput; attrClass: AttributeDescriptionClass }) {}
}

export class GetMultiselectFilters implements Action {
  readonly type = GET_MULTISELECT_FILTERS;

  constructor(public payload: { vars: AttributeDescriptionSearchInput; attrClass: AttributeDescriptionClass }) {}
}
export class GetQueryVariables implements Action {
  readonly type = GET_QUERY_VARIABLES;

  constructor(public payload: { vars: AttributeDescriptionSearchInput; attrClass: AttributeDescriptionClass }) {}
}

export class GetClass implements Action {
  readonly type = GET_CLASS;

  constructor(public payload: AttributeDescriptionClass) {}
}

export type AttributeDescriptionsActions =
  | GetGeneralFilter
  | GetCurrentPage
  | GetCurrentPageSize
  | GetAttributeDescriptionsCount
  | LoadAttributeDescriptions
  | RefetchAttributeDescriptions
  | LoadAttributeDescriptionsSuccess
  | LoadAttributeDescriptionsFailure
  | AddAttributeDescriptionToList
  | DeleteAttributeDescriptionFromList
  | ClearAttributeDescriptionsList
  | AddSortFilters
  | GetMultiselectFilters
  | GetQueryVariables
  | GetClass;
