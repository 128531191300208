import { gql } from 'apollo-angular';
import { CarrierFragments } from '../fragments/carrier-fragments';

export const AllCarriers = gql`
  query allCarriers($input: CarriersSearchInput) {
    carriers(input: $input) {
      page
      itemsFound
      offset
      items {
        id
        name
        type
      }
    }
  }
`;

export const CarriersList = gql`
  query carriersList($input: CarriersSearchInput) {
    carriers(input: $input) {
      page
      itemsFound
      offset
      items {
        ...carrier
      }
    }
  }
  ${CarrierFragments.carrier}
`;

export const CarrierDropdownList = gql`
  query CarrierDropdownList($input: CarriersSearchInput) {
    carriers(input: $input) {
      items {
        name
      }
    }
  }
`;
