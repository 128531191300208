import { gql } from 'apollo-angular';
import { AttributeFragments } from '../fragments/attribute-fragments';

export const AttributeDescriptionList = gql`
  query AttributeDescriptionList($input: AttributeDescriptionSearchInput!) {
    attributeDescriptions(input: $input) {
      page
      itemsFound
      offset
      items {
        ...attributeDescription
        defaultValue {
          ...attrValue
        }
      }
    }
  }
  ${AttributeFragments.attributeDescription}
  ${AttributeFragments.attributeValue}
`;
