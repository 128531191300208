import { Valueset, ValuesetSearchInput } from 'src/app/shared/api/types/GraphQL';
import * as ValueSetActions from './value-set.actions';
import { globals } from 'src/app/shared/globals';
import { AppComponentLocation, StateEntry } from 'src/app/shared/api/types/enums';

export interface State {
  listing: StateProperties;
  picker: StateProperties;
  stateEntry: StateEntry;
}
export interface StateProperties {
  loading: boolean;
  loaded: boolean;
  valueSetList: Valueset[];
  currentPage: number;
  currentPageSize: number;
  valueSetCount: number;
  error: Error;
  generalFilterValue: string;
  valueSetFilters: ValuesetSearchInput;
  activeTab: number;
  selectedValueSetList: Valueset[];
  location: AppComponentLocation;
}

const initialStateProperties: StateProperties = {
  loading: false,
  loaded: false,
  valueSetList: [],
  currentPage: 1,
  currentPageSize: globals.globalOffset,
  valueSetCount: 0,
  error: undefined,
  generalFilterValue: '',
  activeTab: 0,
  selectedValueSetList: [],
  valueSetFilters: {
    ids: [],
    names: [],
    createdAt: {},
    lastModifiedAt: {},
  },
  location: AppComponentLocation.valuesets,
};

const initialState: State = {
  listing: initialStateProperties,
  picker: initialStateProperties,
  stateEntry: StateEntry.listing,
};

export function valueSetReducer(state: State = initialState, action: ValueSetActions.ValueSetActions) {
  switch (action.type) {
    case ValueSetActions.RESET_STATE_ENTRY_STATE:
      return {
        ...state,
        picker: {
          ...state.picker,
          ...initialStateProperties,
        },
      };
    case ValueSetActions.SET_LOCATION:
      return {
        ...state,
        [state.stateEntry]: {
          ...state[state.stateEntry],
          location: action.payload,
        },
      };
    case ValueSetActions.GET_GENERAL_FILTER:
      return {
        ...state,
        [state.stateEntry]: {
          ...state[state.stateEntry],
          generalFilterValue: action.payload,
        },
      };
    case ValueSetActions.GET_CURRENT_PAGE:
      return {
        ...state,
        [state.stateEntry]: {
          ...state[state.stateEntry],
          currentPage: action.payload,
        },
      };
    case ValueSetActions.GET_CURRENT_PAGE_SIZE:
      return {
        ...state,
        [state.stateEntry]: {
          ...state[state.stateEntry],
          currentPageSize: action.payload,
        },
      };
    case ValueSetActions.GET_VALUE_SET_COUNT:
      return {
        ...state,
        [state.stateEntry]: {
          ...state[state.stateEntry],
          valueSetCount: action.payload,
        },
      };
    case ValueSetActions.LOAD_VALUE_SET:
      let loading;
      state[state.stateEntry].loaded ? (loading = false) : (loading = true);
      return {
        ...state,
        [state.stateEntry]: {
          ...state[state.stateEntry],
          loading: loading,
        },
      };
    case ValueSetActions.REFETCH_VALUE_SET:
      return {
        ...state,
        [state.stateEntry]: {
          ...state[state.stateEntry],
          loaded: false,
          loading: true,
        },
      };
    case ValueSetActions.LOAD_VALUE_SET_SUCCESS:
      return {
        ...state,
        [state.stateEntry]: {
          ...state[state.stateEntry],
          valueSetList: [...action.payload],
          loading: false,
          loaded: true,
        },
      };
    case ValueSetActions.LOAD_VALUE_SET_FAILURE:
      return {
        ...state,
        [state.stateEntry]: {
          ...state[state.stateEntry],
          error: action.payload,
          loading: false,
        },
      };
    case ValueSetActions.ADD_VALUE_SET:
      return {
        ...state,
        [state.stateEntry]: {
          ...state[state.stateEntry],
          valueSetList: [...state[state.stateEntry].valueSetList, action.payload],
        },
      };
    case ValueSetActions.ADD_VALUE_SET_TO_LIST:
      if (state[state.stateEntry].selectedValueSetList.map((x) => x.id).indexOf(action.payload.id) === -1) {
        return {
          ...state,
          [state.stateEntry]: {
            ...state[state.stateEntry],
            selectedValueSetList: [...state[state.stateEntry].selectedValueSetList, action.payload],
          },
        };
      } else {
        return {
          ...state,
        };
      }
    case ValueSetActions.DELETE_VALUE_SET_FROM_LIST:
      if (state[state.stateEntry].selectedValueSetList.map((x) => x.id).indexOf(action.payload) !== -1) {
        const index = state[state.stateEntry].selectedValueSetList.map((x) => x.id).indexOf(action.payload);
        return {
          ...state,
          [state.stateEntry]: {
            ...state[state.stateEntry],
            selectedValueSetList: state[state.stateEntry].selectedValueSetList.filter((list, i) => {
              return i !== index;
            }),
          },
        };
      }
      return {
        ...state,
      };
    case ValueSetActions.CLEAR_VALUE_SET_LIST:
      const { stateEntry: ClearStateEntry } = action.payload;
      return {
        ...state,
        [ClearStateEntry]: {
          ...state[ClearStateEntry],
          selectedValueSetList: [],
        },
      };
    case ValueSetActions.UPDATE_VALUE_SET:
      const updatedValueSetIndex = state[state.stateEntry].valueSetList.findIndex(
        (valueSet) => valueSet.id === action.payload.id,
      );
      if (updatedValueSetIndex > -1) {
        const updatedValueSetList = [...state[state.stateEntry].valueSetList];
        updatedValueSetList[updatedValueSetIndex] = action.payload.updatedValueSet;
        return {
          ...state,
          [state.stateEntry]: {
            ...state[state.stateEntry],
            valueSetList: [...updatedValueSetList],
          },
        };
      }
      return {
        ...state,
      }; // If the value set to update is not found, return the current state unchanged.

    case ValueSetActions.CREATE_VALUE_SET:
      return {
        ...state,
        [state.stateEntry]: {
          ...state[state.stateEntry],
          valueSetList: [...state[state.stateEntry].valueSetList, action.payload],
        },
      };
    case ValueSetActions.ADD_GENERAL_FILTER:
      return {
        ...state,
        [state.stateEntry]: {
          ...state[state.stateEntry],
          valueSetFilters: { ...state[state.stateEntry].valueSetFilters, ...action.payload },
        },
      };
    case ValueSetActions.REMOVE_GENERAL_FILTER:
      const { columnName, index } = action.payload;

      // Clone the state's valueSetFilters object to prevent mutation
      const updatedFilters = { ...state[state.stateEntry].valueSetFilters };

      // Check if valueSetFilters[columnName] is an array
      if (Array.isArray(updatedFilters[columnName])) {
        // If it's an array, clone the array, remove the item at the specified index, and update the valueSetFilters object
        updatedFilters[columnName] = [...updatedFilters[columnName]];
        updatedFilters[columnName].splice(index, 1);
      } else {
        // If it's not an array, simply delete the key corresponding to columnName
        delete updatedFilters[columnName];
      }
      return {
        ...state,
        [state.stateEntry]: {
          ...state[state.stateEntry],
          valueSetFilters: updatedFilters,
        },
      };
    case ValueSetActions.SET_STATE_ENTRY:
      return {
        ...state,
        stateEntry: action.payload,
      };
    default:
      return state;
  }
}
