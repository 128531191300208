import { Pipe, PipeTransform } from '@angular/core';
import { AttributeResult, AttributeTextValue } from '../../api/types/GraphQL';

@Pipe({
  name: 'attributeTextValue',
})
export class AttributeTextValuePipe implements PipeTransform {
  transform(attribute: AttributeResult, appLanguage: string): string {
    if (attribute && attribute.value && (attribute.value as AttributeTextValue).textValues) {
      const values = attribute.value['textValues'].find((item) => item.language === appLanguage).values;
      return values.length && values[0] !== '' ? values : '';
    }
    return '';
  }
}
