import { gql } from 'apollo-angular';

export const valueSetItemListQuery = gql`
  query valuesetItems($input: ValuesetItemSearchInput) {
    valuesetItems(input: $input) {
      items {
        id
        valuesetId
        value
        extra
        descriptions {
          language
          value
        }
      }
      itemsFound
      offset
      page
    }
  }
`;

export const userTagsDropdownQuery = gql`
  query userTagsDropdownQuery($input: ValuesetItemSearchInput) {
    valuesetItems(input: $input) {
      itemsFound
      offset
      page
      items {
        id
        value
      }
    }
  }
`;
