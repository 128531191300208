import { PayMethod, PayMethodSearchInput } from 'src/app/shared/api/types/GraphQL';
import * as PayMethodsActions from './paymethods.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  payMethods: PayMethod[];
  currentPage: number;
  currentPageSize: number;
  payMethodCount: number;
  error: Error;
  generalFilterValue: string;
  selectedPayMethodsList: PayMethod[];
  numberOfFiltersSelected: number;
  multiselectFilterValues: PayMethodSearchInput;
}

const initialState: State = {
  loading: false,
  loaded: false,
  payMethods: [],
  currentPage: 1,
  currentPageSize: 5,
  payMethodCount: 0,
  error: undefined,
  generalFilterValue: '',
  selectedPayMethodsList: [],
  numberOfFiltersSelected: 0,
  multiselectFilterValues: {
    ids: [],
    externalCodes: [],
    createdAt: {
      greaterThan: '',
      lessThan: '',
    },
    lastModifiedAt: {
      greaterThan: '',
      lessThan: '',
    },
    sortInputs: [],
  },
};

export function payMethodsReducer(state: State = initialState, action: PayMethodsActions.PayMethodsActions) {
  switch (action.type) {
    case PayMethodsActions.GET_GENERAL_FILTER:
      return {
        ...state,
        generalFilterValue: action.payload,
      };
    case PayMethodsActions.GET_MULTISELECT_FILTERS:
      let payloadCreatedAt =
        action.payload.createdAt?.greaterThan !== null && action.payload.createdAt?.lessThan !== null;
      let payloadUpdatedAt =
        action.payload.lastModifiedAt?.greaterThan !== null && action.payload.lastModifiedAt.lessThan !== null;
      let payloadLength =
        (payloadCreatedAt ? 1 : 0) +
        (payloadUpdatedAt ? 1 : 0) +
        action.payload.ids.length +
        action.payload.externalCodes.length;

      return {
        ...state,
        multiselectFilterValues: {
          ...state.multiselectFilterValues,
          createdAt: action.payload.createdAt,
          lastModifiedAt: action.payload.lastModifiedAt,
          ids: action.payload.ids,
          externalCodes: action.payload.externalCodes,
        },
        numberOfFiltersSelected: payloadLength,
      };
    case PayMethodsActions.ADD_SORT_FILTERS:
      const { sortInputs, ...otherPayload } = action.payload;
      return {
        ...state,
        multiselectFilterValues: {
          ...state.multiselectFilterValues,
          ...otherPayload,
          sortInputs: sortInputs,
        },
      };
    case PayMethodsActions.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case PayMethodsActions.GET_CURRENT_PAGE_SIZE:
      return {
        ...state,
        currentPageSize: action.payload,
      };
    case PayMethodsActions.GET_PAYMETHODS_COUNT:
      return {
        ...state,
        payMethodCount: action.payload,
      };
    case PayMethodsActions.LOAD_PAYMETHODS:
      let loading;
      state.loaded ? (loading = false) : (loading = true);
      return {
        ...state,
        loading: loading,
      };
    case PayMethodsActions.REFETCH_PAYMETHODS:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case PayMethodsActions.LOAD_PAYMETHODS_SUCCESS:
      return {
        ...state,
        payMethods: action.payload,
        loading: false,
        loaded: true,
      };
    case PayMethodsActions.LOAD_PAYMETHODS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case PayMethodsActions.ADD_PAYMETHOD:
      return {
        ...state,
        payMethods: [...state.payMethods, action.payload],
      };
    case PayMethodsActions.ADD_PAYMETHOD_TO_LIST:
      if (state.selectedPayMethodsList.map((x) => x.id).indexOf(action.payload.id) === -1) {
        return {
          ...state,
          selectedPayMethodsList: [...state.selectedPayMethodsList, action.payload],
        };
      } else {
        return {
          ...state,
        };
      }
    case PayMethodsActions.DELETE_PAYMETHOD_FROM_LIST:
      if (state.selectedPayMethodsList.map((x) => x.id).indexOf(action.payload) !== -1) {
        const index = state.selectedPayMethodsList.map((x) => x.id).indexOf(action.payload);
        return {
          ...state,
          selectedPayMethodsList: state.selectedPayMethodsList.filter((item, i) => {
            return i !== index;
          }),
        };
      } else {
        return {
          ...state,
        };
      }
    case PayMethodsActions.CLEAR_PAYMETHOD_LIST:
      return {
        ...state,
        selectedPayMethodsList: [],
      };

    default:
      return state;
  }
}
