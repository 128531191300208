import { BusinessRule } from 'src/app/shared/api/types/GraphQL';
import * as BusinessRulesActions from './business-rules.actions';
import { BusinessRulesLocation } from 'src/app/shared/api/types/enums';

export interface StateProperties {
  loading: boolean;
  loaded: boolean;
  businessRules: BusinessRule[];
  currentPage: number;
  currentPageSize: number;
  businessRulesCount: number;
  error: Error;
  generalFilterValue: string;
  selectedBusinessRulesList: BusinessRule[];
  businessRulesTableColumns: {
    displayedColumns: string[];
    hiddenColumns: string[];
    availableColumns: string[];
    disabledColumns: string[];
    disabledSuffixLength: number;
  };
}

export interface State {
  [key: string]: StateProperties | BusinessRulesLocation;
}

const initialStateProperties: StateProperties = {
  loading: false,
  loaded: false,
  businessRules: [],
  currentPage: 1,
  currentPageSize: 5,
  businessRulesCount: 0,
  error: undefined,
  generalFilterValue: '',
  selectedBusinessRulesList: [],
  businessRulesTableColumns: {
    displayedColumns: ['select', 'id', 'name', 'dateCreated', 'dateUpdated', 'action'],
    hiddenColumns: ['select', 'action'],
    disabledSuffixLength: 1,
    availableColumns: ['select', 'action'],
    disabledColumns: ['id', 'name'],
  },
};

const initialState: State = { ruleType: BusinessRulesLocation.incentives };
Object.values(BusinessRulesLocation).forEach((key) => {
  initialState[key] = initialStateProperties;
});

export function businessRulesReducer(state: State = initialState, action: BusinessRulesActions.BusinessRulesActions) {
  switch (action.type) {
    case BusinessRulesActions.GET_GENERAL_FILTER:
      return {
        ...state,
        [state.ruleType as BusinessRulesLocation]: {
          ...(state[state.ruleType as BusinessRulesLocation] as StateProperties),
          generalFilterValue: action.payload,
        },
      };
    case BusinessRulesActions.GET_CURRENT_PAGE:
      return {
        ...state,
        [state.ruleType as BusinessRulesLocation]: {
          ...(state[state.ruleType as BusinessRulesLocation] as StateProperties),
          currentPage: action.payload,
        },
      };
    case BusinessRulesActions.GET_CURRENT_PAGE_SIZE:
      return {
        ...state,
        [state.ruleType as BusinessRulesLocation]: {
          ...(state[state.ruleType as BusinessRulesLocation] as StateProperties),
          currentPageSize: action.payload,
        },
      };
    case BusinessRulesActions.GET_BUSINESS_RULES_COUNT:
      return {
        ...state,
        [state.ruleType as BusinessRulesLocation]: {
          ...(state[state.ruleType as BusinessRulesLocation] as StateProperties),
          businessRulesCount: action.payload,
        },
      };
    case BusinessRulesActions.LOAD_BUSINESS_RULES:
      let loading;
      (state[state.ruleType as BusinessRulesLocation] as StateProperties).loaded ? (loading = false) : (loading = true);
      return {
        ...state,
        [state.ruleType as BusinessRulesLocation]: {
          ...(state[state.ruleType as BusinessRulesLocation] as StateProperties),
          loading: loading,
        },
      };
    case BusinessRulesActions.REFETCH_BUSINESS_RULES:
      return {
        ...state,
        [state.ruleType as BusinessRulesLocation]: {
          ...(state[state.ruleType as BusinessRulesLocation] as StateProperties),
          loaded: false,
          loading: true,
        },
      };
    case BusinessRulesActions.LOAD_BUSINESS_RULES_SUCCESS:
      return {
        ...state,
        [state.ruleType as BusinessRulesLocation]: {
          ...(state[state.ruleType as BusinessRulesLocation] as StateProperties),
          businessRules: [...action.payload],
          loading: false,
          loaded: true,
        },
      };
    case BusinessRulesActions.LOAD_BUSINESS_RULES_FAILURE:
      return {
        ...state,
        [state.ruleType as BusinessRulesLocation]: {
          ...(state[state.ruleType as BusinessRulesLocation] as StateProperties),
          error: action.payload,
          loading: false,
        },
      };
    case BusinessRulesActions.ADD_BUSINESS_RULE:
      return {
        ...state,
        [state.ruleType as BusinessRulesLocation]: {
          ...(state[state.ruleType as BusinessRulesLocation] as StateProperties),
          businessRules: [
            ...(state[state.ruleType as BusinessRulesLocation] as StateProperties).businessRules,
            action.payload,
          ],
        },
      };
    case BusinessRulesActions.ADD_BUSINESS_RULE_TO_LIST:
      if (
        (state[state.ruleType as BusinessRulesLocation] as StateProperties).selectedBusinessRulesList
          .map((x) => x.id)
          .indexOf(action.payload.id) === -1
      ) {
        return {
          ...state,
          [state.ruleType as BusinessRulesLocation]: {
            ...(state[state.ruleType as BusinessRulesLocation] as StateProperties),
            selectedBusinessRulesList: [
              ...(state[state.ruleType as BusinessRulesLocation] as StateProperties).selectedBusinessRulesList,
              action.payload,
            ],
          },
        };
      } else {
        return {
          ...state,
        };
      }
    case BusinessRulesActions.DELETE_BUSINESS_RULE_FROM_LIST:
      if (
        (state[state.ruleType as BusinessRulesLocation] as StateProperties).selectedBusinessRulesList
          .map((x) => x.id)
          .indexOf(action.payload) !== -1
      ) {
        const index = (state[state.ruleType as BusinessRulesLocation] as StateProperties).selectedBusinessRulesList
          .map((x) => x.id)
          .indexOf(action.payload);
        return {
          ...state,
          [state.ruleType as BusinessRulesLocation]: {
            ...(state[state.ruleType as BusinessRulesLocation] as StateProperties),
            selectedBusinessRulesList: (
              state[state.ruleType as BusinessRulesLocation] as StateProperties
            ).selectedBusinessRulesList.filter((item, i) => {
              return i !== index;
            }),
          },
        };
      } else {
        return {
          ...state,
        };
      }
    case BusinessRulesActions.CLEAR_BUSINESS_RULES_LIST:
      return {
        ...state,
        [state.ruleType as BusinessRulesLocation]: {
          ...(state[state.ruleType as BusinessRulesLocation] as StateProperties),
          selectedBusinessRulesList: [],
        },
      };
    case BusinessRulesActions.BUSINESS_RULES_TABLE_COLUMNS:
      return {
        ...state,
        [state.ruleType as BusinessRulesLocation]: {
          ...(state[state.ruleType as BusinessRulesLocation] as StateProperties),
          businessRulesTableColumns: action.payload,
        },
      };
    case BusinessRulesActions.SET_RULE_TYPE:
      return {
        ...state,
        ruleType: action.payload,
      };
    default:
      return state;
  }
}
