import { gql } from 'apollo-angular';
import { AdminUserFragments } from '../fragments/admin-user-fragments';

export const AdminUserUpdate = gql`
  mutation AdminUserUpdate($email: String!, $input: UpdateUserDto!) {
    adminUserUpdate(email: $email, input: $input) {
      ...adminUser
    }
  }
  ${AdminUserFragments.adminUser}
`;

export const AdminUserCreate = gql`
  mutation AdminUserCreate($input: CreateUserDto!) {
    adminUserCreate(input: $input) {
      ...adminUser
    }
  }
  ${AdminUserFragments.adminUser}
`;

export const AdminUserDelete = gql`
  mutation AdminUserDelete($email: String!) {
    adminUserDelete(email: $email)
  }
`;

export const AdminUserAssignTenant = gql`
  mutation AdminUserAssignTenant($input: CreateTenantDto!) {
    adminUserTenantCreate(input: $input) {
      id
    }
  }
`;
