<div class="go-to-page-field">
  <span class="mat-mdc-paginator text">Go to Page:</span>
  <mat-form-field appearance="outline" color="primary" class="select-field">
    <mat-select hideSingleSelectionIndicator [(ngModel)]="selectedPage" (selectionChange)="onPageChange()">
      <mat-option *ngFor="let page of [].constructor(totalPages); let i = index" [value]="i + 1">
        {{ i + 1 }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
