import { gql } from 'apollo-angular';

export const AdminUserRoleUpdate = gql`
  mutation AdminUserRoleUpdate($id: ID!, $input: RoleUpdateInput!) {
    roleUpdate(id: $id, input: $input) {
      access
    }
  }
`;

export const AdminUserRoleCreate = gql`
  mutation AdminUserRoleCreate($input: RoleCreateInput!) {
    roleCreate(input: $input) {
      id
    }
  }
`;

export const AdminUserRoleDelete = gql`
  mutation AdminUserRoleDelete($id: ID!) {
    roleDelete(id: $id)
  }
`;
