import { gql } from 'apollo-angular';

//hidden field
//tenant
//categoryPath

export const DiscountCreate = gql`
  mutation DiscountCreate($input: DiscountCreateInput!) {
    discountCreate(input: $input) {
      id
      createdAt
      lastModifiedAt
      value
      quantityFrom
      validFrom
      validTo
      pricesheetId
      productId
      categoryId
      priceGroup
      discountType
    }
  }
`;

//hidden field
//tenant
//categoryPath

export const DiscountUpdate = gql`
  mutation DiscountUpdate($id: String!, $input: DiscountUpdateInput!) {
    discountUpdate(id: $id, input: $input) {
      id
      createdAt
      lastModifiedAt
      value
      quantityFrom
      validFrom
      validTo
      pricesheetId
      productId
      categoryId
      priceGroup
      discountType
    }
  }
`;
export const DiscountDelete = gql`
  mutation DiscountDelete($id: String!) {
    discountDelete(id: $id)
  }
`;
