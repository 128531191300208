<div class="form-group">
  <mat-label class="mat-caption" *ngIf="!isFilter">{{ headerName }}</mat-label>
  <mat-form-field appearance="outline">
    <input
      matInput
      [placeholder]="dropdownPlaceholder"
      [(ngModel)]="selectedData"
      [disabled]="isViewer || isDisabled"
      (click)="triggerDropdown()"
      readonly
      class="custom-input"
    />
    <button matSuffix mat-icon-button class="arrow-button" (click)="triggerDropdown()">
      <mat-icon>arrow_drop_down</mat-icon>
    </button>

    <mat-select
      #dropdownSelect
      panelClass="dropdown-search"
      [(ngModel)]="selectedData"
      [ngModelOptions]="{ standalone: true }"
      (selectionChange)="onDataChange($event)"
      (openedChange)="onDropdownChange($event)"
      [disabled]="isViewer || isDisabled"
      style="display: none"
    >
      <div class="tags-popup">
        <div class="tags-header-wrapper">
          <mat-form-field appearance="outline" class="generalFilter">
            <mat-icon matPrefix>search</mat-icon>
            <input
              matInput
              [placeholder]="searchPlaceholder"
              floatPlaceholder="never"
              (input)="onDataSearch($event)"
              (keydown)="$event.stopPropagation()"
            />
          </mat-form-field>
        </div>
        <ng-container>
          <div class="option-wrapper" (scroll)="loadMore($event)">
            <mat-option
              *ngFor="let data of customData"
              [value]="data.value"
              [disabled]="data.key === 'noResults'"
              [ngClass]="{ 'no-results': data.key === 'noResults' }"
            >
              {{ data.key === 'noResults' ? data.value : (data.descriptions | localizedString) }}
            </mat-option>
            <div *ngIf="loadingMoreOptions" class="load-more-spinner">
              <mat-spinner class="loader-icon"></mat-spinner>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="customData === undefined">
          <mat-option value="undefined" disabled>Loading {{ headerName | lowercase }}...</mat-option>
        </ng-container>
      </div>
    </mat-select>
  </mat-form-field>
</div>
