import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { OrdersService } from './orders.service';

@Injectable({
  providedIn: 'root',
})
export class OrderStatusesResolver implements Resolve<any> {
  constructor(private ordersService: OrdersService) {}

  resolve() {
    return this.ordersService.getOrderStatuses();
  }
}
