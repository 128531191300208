import { NgModule } from '@angular/core';
import { TimeAgoPipe } from './pipes/timeAgoPipe/time-ago.pipe';

import { ListingComponent } from './components/listing-component/listing.component';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../angular-material.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatTableExporterModule } from 'mat-table-exporter';
import { CapitalizeFirstLetterPipe } from './pipes/capitalize-first-letter.pipe';
import { UnderscoreToSpacePipe } from './pipes/underscore-to-space.pipe';
import { ContentPipe } from './pipes/content.pipe';
import { ItemDescriptionPipe } from './pipes/attributeDescriptionPipe/attribute-description.pipe';
import { AttributeTextValuePipe } from './pipes/attributeTextValuePipe/attribute-text-value.pipe';
import { AttributeDefaultValuePipe } from './pipes/attributeDefaultValuePipe/attribute-default-value.pipe';
import { LogoValidityPipe } from './pipes/logo-validity.pipe';
import { CategoryPathPipe } from './pipes/category-path.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TagsAddComponent } from './components/tags-add/tags-add.component';
import { RegionNamePipe } from './pipes/regionName/region-name.pipe';
import { localizedStringPipe } from './pipes/localazed-string.pipe';
import { DropdownWithSearchInputComponent } from './components/language-dropdown/dropdown-with-search-input.component';
import { GoToPageComponent } from './components/go-to-page/go-to-page.component';
import { LocalizedStringInputComponent } from './components/localized-string-input/localized-string-input.component';
import { ContactInCompanySelectComponent } from './components/contact-in-company-select/contact-in-company-select.component';

const PIPES = [
  TimeAgoPipe,
  CapitalizeFirstLetterPipe,
  UnderscoreToSpacePipe,
  ContentPipe,
  ItemDescriptionPipe,
  AttributeTextValuePipe,
  AttributeDefaultValuePipe,
  LogoValidityPipe,
  CategoryPathPipe,
  RegionNamePipe,
  localizedStringPipe,
];

@NgModule({
  imports: [
    CommonModule,
    AngularMaterialModule,
    NgxSkeletonLoaderModule,
    MatTableExporterModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    ListingComponent,
    TagsAddComponent,
    DropdownWithSearchInputComponent,
    ...PIPES,
    GoToPageComponent,
    LocalizedStringInputComponent,
    ContactInCompanySelectComponent,
  ],
  exports: [
    ListingComponent,
    TagsAddComponent,
    DropdownWithSearchInputComponent,
    LocalizedStringInputComponent,
    ContactInCompanySelectComponent,
    ...PIPES,
  ],
})
export class SharedModule {}
