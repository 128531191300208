import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import {
  ValuesetCreateInput,
  ValuesetItemCreateInput,
  ValuesetItemSearchInput,
  ValuesetItemUpdateInput,
  ValuesetSearchInput,
  ValuesetUpdateInput,
} from '../../shared/api/types/GraphQL';
import { valueSetListQuery, valueSetQuery, valueSetTagsQuery } from '../../shared/api/queries/value-set-query';
import {
  SingleValueSetResponse,
  valueSetCreateResponse,
  valueSetDeleteResponse,
  valueSetItemCreateResponse,
  valueSetItemDeleteResponse,
  valueSetItemUpdateResponse,
  valueSetListItemResponse,
  valueSetListResponse,
  valueSetUpdateResponse,
} from '../../shared/api/types/types';
import { userTagsDropdownQuery, valueSetItemListQuery } from '../../shared/api/queries/value-set-item-query';
import { ValueSetCreate, ValueSetDelete, ValueSetUpdate } from '../../shared/api/mutations/value-set-mutations';
import {
  ValueSetItemCreate,
  ValueSetItemDelete,
  ValueSetItemUpdate,
} from '../../shared/api/mutations/value-set-item-mutations';

@Injectable({
  providedIn: 'root',
})
export class ValueSetService {
  constructor(private apollo: Apollo) {}

  getValuesetById(id: number): QueryRef<SingleValueSetResponse> {
    return this.apollo.watchQuery<SingleValueSetResponse>({
      query: valueSetQuery,
      variables: { id },
    });
  }

  getValuesetByName(input: ValuesetSearchInput): QueryRef<valueSetListResponse> {
    return this.apollo.watchQuery<valueSetListResponse>({
      query: valueSetTagsQuery,
      variables: { input },
    });
  }

  getValueSetList(input: ValuesetSearchInput): QueryRef<valueSetListResponse> {
    return this.apollo.watchQuery<valueSetListResponse>({
      query: valueSetListQuery,
      variables: { input },
    });
  }
  valueSetCreate(variables: { input: ValuesetCreateInput }) {
    return this.apollo.mutate<valueSetCreateResponse>({
      mutation: ValueSetCreate,
      variables,
    });
  }
  valueSetUpdate(variables: { id: number; input: ValuesetUpdateInput }) {
    return this.apollo.mutate<valueSetUpdateResponse>({
      mutation: ValueSetUpdate,
      variables,
    });
  }
  valueSetDelete(id: number) {
    return this.apollo.mutate<valueSetDeleteResponse>({
      mutation: ValueSetDelete,
      variables: { id },
    });
  }

  // valueSetItem
  getValueSetItemList(input: ValuesetItemSearchInput): QueryRef<valueSetListItemResponse> {
    return this.apollo.watchQuery<valueSetListItemResponse>({
      query: valueSetItemListQuery,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
  }
  getUserTagsDropdownList(input: ValuesetItemSearchInput): QueryRef<valueSetListItemResponse> {
    return this.apollo.watchQuery<valueSetListItemResponse>({
      query: userTagsDropdownQuery,
      variables: { input },
    });
  }

  valueSetItemCreate(variables: { input: ValuesetItemCreateInput }) {
    return this.apollo.mutate<valueSetItemCreateResponse>({
      mutation: ValueSetItemCreate,
      variables,
    });
  }
  valueSetItemUpdate(variables: { id: number; input: ValuesetItemUpdateInput }) {
    return this.apollo.mutate<valueSetItemUpdateResponse>({
      mutation: ValueSetItemUpdate,
      variables,
    });
  }
  valueSetItemDelete(id: number) {
    return this.apollo.mutate<valueSetItemDeleteResponse>({
      mutation: ValueSetItemDelete,
      variables: { id },
    });
  }
}
