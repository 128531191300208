import { Role } from 'src/app/shared/api/types/GraphQL';
import * as AuthActions from './auth.actions';

export interface Tenant {
  id: number;
  tenant: string;
  name: string;
  default: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface State {
  isInitializing: boolean;
  loading: boolean;
  user: {};
  userId: number;
  tenants: Tenant[];
  activeTenant: Tenant;
  adminTenantId: string;
  error: Error;
  roles: Role[];
  appLanguage: string;
}

const initialState: State = {
  isInitializing: true,
  loading: false,
  user: null,
  userId: 0,
  tenants: [],
  activeTenant: null,
  adminTenantId: '',
  error: null,
  roles: [],
  appLanguage: 'NL',
};

export function authReducer(state = initialState, action: AuthActions.AuthActions) {
  switch (action.type) {
    case AuthActions.LOGIN_START:
      return {
        ...state,
        loading: true,
      };
    case AuthActions.LOGIN:
      return {
        ...state,
        user: action.payload,
        isInitializing: false,
        loading: true,
      };
    case AuthActions.AUTO_LOGIN:
      return {
        ...state,
        user: action.payload,
        isInitializing: false,
        loading: false,
      };
    case AuthActions.LOGIN_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case AuthActions.LOGOUT:
      return {
        ...state,
        user: null,
        tenants: [],
        activeTenant: null,
        isInitializing: false,
      };
    case AuthActions.SET_TENANTS:
      const defaultTenant = action.payload.find((item) => item.default === 1) || action.payload[0];
      return {
        ...state,
        tenants: action.payload,
        activeTenant: defaultTenant,
        loading: false,
      };
    case AuthActions.SET_VIEWER_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    case AuthActions.SET_ACTIVE_TENANT:
      return {
        ...state,
        activeTenant: action.payload,
        loading: false,
      };
    case AuthActions.SET_APP_LANGUAGE:
      return {
        ...state,
        appLanguage: action.payload,
      };
    case AuthActions.SET_ADMIN_TENANT:
      return {
        ...state,
        adminTenantId: action.payload,
      };
    case AuthActions.SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
    default:
      return state;
  }
}
