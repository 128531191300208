import { gql } from 'apollo-angular';
import { CarrierFragments } from '../fragments/carrier-fragments';

export const CarrierUpdate = gql`
  mutation CarrierUpdate($id: Int!, $input: CarrierUpdateInput!) {
    carrierUpdate(id: $id, input: $input) {
      ...carrier
    }
  }
  ${CarrierFragments.carrier}
`;

export const CarrierCreate = gql`
  mutation CarrierCreate($input: CarrierCreateInput!) {
    carrierCreate(input: $input) {
      ...carrier
    }
  }
  ${CarrierFragments.carrier}
`;

export const CarrierDelete = gql`
  mutation CarrierDelete($id: Int!) {
    carrierDelete(id: $id)
  }
`;
