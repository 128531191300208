import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, filter, map, Observable, of, switchMap, tap, withLatestFrom } from 'rxjs';
import * as BackofficeUsersActions from './backoffice-users.actions';
import * as fromApp from '../../../store/app.reducer';
import { QueryRef } from 'apollo-angular';
import { BackofficeUsersService } from '../backoffice-users.service';
import { AdminUsersResponse } from 'src/app/shared/api/types/types';

@Injectable()
export class BackofficeUsersEffects {
  backofficeUsersQuery: QueryRef<AdminUsersResponse>;
  backofficeUsersQuerySub: Observable<Action>;

  constructor(
    private actions: Actions,
    private backofficeUsersService: BackofficeUsersService,
    private store: Store<fromApp.AppState>,
  ) {}

  public readonly loadBackofficeUsers: Observable<Action> = createEffect(() => {
    return this.actions.pipe(
      ofType(BackofficeUsersActions.LOAD_BACKOFFICE_USERS),
      withLatestFrom(this.store.select((state) => state.backofficeUsers.loaded)),
      filter(([action, loaded]) => !loaded),
      switchMap(([action, loaded]: [BackofficeUsersActions.LoadBackofficeUsers, boolean]) => {
        this.backofficeUsersQuery = this.backofficeUsersService.getAdminUsers(action.payload);
        this.backofficeUsersQuerySub = this.backofficeUsersQuery.valueChanges.pipe(
          map((data) => {
            this.store.dispatch(new BackofficeUsersActions.GetBackofficeUsersCount(data.data.adminUsers.itemsFound));
            return new BackofficeUsersActions.LoadBackofficeUsersSuccess(data.data.adminUsers.items);
          }),
          catchError((error) => of(new BackofficeUsersActions.LoadBackofficeUsersFailure(error))),
        );
        return this.backofficeUsersQuerySub;
      }),
    );
  });

  public readonly refetchBackofficeUsers = createEffect(
    () =>
      this.actions.pipe(
        ofType(BackofficeUsersActions.REFETCH_BACKOFFICE_USERS),
        tap((action: BackofficeUsersActions.RefetchBackofficeUsers) => {
          this.backofficeUsersQuery.resetLastResults();
          this.backofficeUsersQuery.refetch(action.payload);
        }),
      ),
    { dispatch: false },
  );
}
