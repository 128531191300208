import { gql } from 'apollo-angular';
import { globals } from 'src/app/shared/globals';

export const valueSetListQuery = gql`
  query valueSetListQuery($input: ValuesetSearchInput) {
    valuesets(input: $input) {
      items {
        id
        name
        type
        descriptions {
          language
          value
        }
        valuesetItems {
          itemsFound
        }
        lastModifiedAt
        createdAt
      }
      itemsFound
      offset
      page
      pages
      start
      end
    }
  }
`;

export const valueSetQuery = gql`
  query valueSetQuery($id: Int!) {
    valueset(id: $id) {
      id
      name
      type
      descriptions {
        language
        value
      }
      lastModifiedBy
      lastModifiedAt
      createdBy
      createdAt
      valuesetItems {
        items {
          id
          value
        }
      }
    }
  }
`;

export const valueSetTagsQuery = gql`
  query valueSetTagsQuery($input: ValuesetSearchInput) {
    valuesets(input: $input) {
      items {
        id
      }
      itemsFound
    }
  }
`;
