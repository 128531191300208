import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-go-to-page',
  templateUrl: './go-to-page.component.html',
  styleUrls: ['./go-to-page.component.scss'],
})
export class GoToPageComponent {
  @Input() totalPages: number;
  @Output() onGoToPage = new EventEmitter<number>();

  selectedPage: number;

  onPageChange() {
    this.onGoToPage.emit(this.selectedPage);
  }

  resetPage() {
    this.selectedPage = null;
  }
}
