import { gql } from 'apollo-angular';
import { globals } from 'src/app/shared/globals';

export const OrderlistsQuery = gql`
  query orderlists($input: OrderlistSearchInput!) {
    orderlists(input: $input) {
      page
      itemsFound
      offset
      items {
        id
        type
        code
        partnerEntity
        active
        descriptions {
          language
          value
        }
        extras {
          language
          value
        }
        products(input: {}) {
          itemsFound
        }
        clusters(input: {}) {
          itemsFound
        }
        users {
          firstName
        }
        companies {
          companyId
        }
        validFrom
        validTo
        createdAt
        lastModifiedAt
      }
    }
  }
`;

export const OrderlistDetailsQuery = gql`
  query orderlist($id: Int!) {
    orderlist(id: $id) {
      id
      type
      code
      partnerEntity
      active
      descriptions {
        value
        language
      }
        extras {
        value
        language
      }
        validFrom
        validTo
      products(input: {}) {
        items {
          ... on Product {
            id
            language
            class
            hidden
            names {
              language
              value
            }
            productId
            class
            supplier
            manufacturerCode
            sku
            media {
              images(search: {sort: ASC, offset: ${globals.globalOffset}}) {
                items {
                  imageVariants(
                    input: {
                    transformations: [{
                        name: "xs"
                        transformation: { width: 100, height: 100, fit: BOUNDS, bgColor: "transparent", canvas: {width: 100, height: 100} }
                    }]
                    }
                ) {
                    language
                    name
                    url
                  }
                }
              }
            }
          }
        }
        itemsFound
      }
      users {
        ... on Contact {
          contactId
          firstName
          lastName
          email
        }
        ... on Customer {
          customerId
          lastName
          firstName
          email
        }
      }
      companies {
        companyId
        name
      }
      createdAt
      lastModifiedAt
    }
  }
`;
