import { Action } from '@ngrx/store';
import { FavoriteList } from 'src/app/shared/api/types/GraphQL';
import { FavoriteStateEntry } from 'src/app/shared/api/types/enums';
import { ManageDisplayedTableColumnsInput } from 'src/app/shared/api/types/types';

export const GET_CURRENT_PAGE = '[Favorites] Get Current Page';
export const GET_CURRENT_PAGE_SIZE = '[Favorites] Get Current Page Size';
export const GET_FAVORITE_LISTS_COUNT = '[Favorites] Get Favorites Count';
export const LOAD_FAVORITE_LISTS = '[Favorites] Load Favorite Lists';
export const REFETCH_FAVORITE_LISTS = '[Favorites] Refetch Favorite Lists';
export const LOAD_FAVORITE_LISTS_SUCCESS = '[Favorites] Load Favorites Success';
export const LOAD_FAVORITE_LISTS_FAILURE = '[Favorites] Load Favorites Failure';

export const GET_GENERAL_FILTER = '[Favorites] Get General Filter';

export const ADD_FAVORITE_LIST_TO_SELECTED_LISTS = '[Favorites] Add Favorite List To Selected List';
export const DELETE_FAVORITE_LIST_FROM_SELECTED_LIST = '[Favorites] Delete Favorite List From Selected List';
export const CLEAR_SELECTED_FAVORITES_LISTS = '[Favorites] Clear Selected Favorite List';

export const SET_STATE_ENTRY = '[Favorites] Set State Entry';
export const RESET_STATE_ENTRY_STATE = '[Favorites] Reset the state of one state entry';

export class GetCurrentPage implements Action {
  readonly type = GET_CURRENT_PAGE;

  constructor(public payload: { page: number; stateEntry?: FavoriteStateEntry }) {}
}

export class GetCurrentPageSize implements Action {
  readonly type = GET_CURRENT_PAGE_SIZE;

  constructor(public payload: { pageSize: number; stateEntry?: FavoriteStateEntry }) {}
}

export class GetFavoriteListsCount implements Action {
  readonly type = GET_FAVORITE_LISTS_COUNT;

  constructor(public payload: { count: number; stateEntry: FavoriteStateEntry }) {}
}

export class LoadFavoriteLists implements Action {
  readonly type = LOAD_FAVORITE_LISTS;

  constructor(public payload: { variables: any; stateEntry?: FavoriteStateEntry }) {}
}

export class RefetchFavoriteLists implements Action {
  readonly type = REFETCH_FAVORITE_LISTS;

  constructor(public payload: { variables?: {}; stateEntry: FavoriteStateEntry }) {}
}

export class LoadFavoriteListsSuccess implements Action {
  readonly type = LOAD_FAVORITE_LISTS_SUCCESS;

  constructor(
    public payload: {
      items: FavoriteList[];
      stateEntry: FavoriteStateEntry;
    },
  ) {}
}

export class LoadFavoriteListsFailure implements Action {
  readonly type = LOAD_FAVORITE_LISTS_FAILURE;

  constructor(public payload: { error: Error; stateEntry: FavoriteStateEntry }) {}
}

export class AddFavoriteListToSelectedLists implements Action {
  readonly type = ADD_FAVORITE_LIST_TO_SELECTED_LISTS;

  constructor(public payload: { selectedFavoriteListsList: FavoriteList; stateEntry: FavoriteStateEntry }) {}
}

export class DeleteFavoriteListFromSelectedLists implements Action {
  readonly type = DELETE_FAVORITE_LIST_FROM_SELECTED_LIST;

  constructor(public payload: { id: any; stateEntry: FavoriteStateEntry }) {}
}

export class ClearFavoriteListsList implements Action {
  readonly type = CLEAR_SELECTED_FAVORITES_LISTS;
  constructor(public payload: { stateEntry: FavoriteStateEntry }) {}
}

export class GetGeneralFilter implements Action {
  readonly type = GET_GENERAL_FILTER;

  constructor(public payload: { filterValue: string; stateEntry: FavoriteStateEntry }) {}
}

export class SetStateEntry implements Action {
  readonly type = SET_STATE_ENTRY;

  constructor(public payload: { stateEntry: FavoriteStateEntry }) {}
}

export class ResetStateEntryState implements Action {
  readonly type = RESET_STATE_ENTRY_STATE;

  constructor(public payload: FavoriteStateEntry) {}
}

export type FavoriteListsActions =
  | GetGeneralFilter
  | GetCurrentPage
  | GetCurrentPageSize
  | GetFavoriteListsCount
  | LoadFavoriteLists
  | RefetchFavoriteLists
  | LoadFavoriteListsSuccess
  | LoadFavoriteListsFailure
  | AddFavoriteListToSelectedLists
  | DeleteFavoriteListFromSelectedLists
  | ClearFavoriteListsList
  | SetStateEntry
  | ResetStateEntryState;
