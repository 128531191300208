import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { TaxDetailsQuery, TaxesQuery } from '../../shared/api/queries/tax-queries';
import { TaxesQueryResponce } from '../../shared/api/types/types';
import { Tax, TaxCreateInput, TaxSearchInput, TaxUpdateInput } from '../../shared/api/types/GraphQL';
import { CreateTaxMutation, DeleteTaxMutation, UpdateTaxMutation } from '../../shared/api/mutations/tax-mutations';

@Injectable({ providedIn: 'root' })
export class TaxesService {
  imgLoading: boolean = true;

  constructor(private apollo: Apollo) {}

  getTaxes(variables: { input: TaxSearchInput }): QueryRef<TaxesQueryResponce> {
    return this.apollo.watchQuery<TaxesQueryResponce>({
      query: TaxesQuery,
      variables,
    });
  }

  getTaxDetails(id: string): any {
    return this.apollo.watchQuery<Tax>({
      query: TaxDetailsQuery,
      variables: { id },
      fetchPolicy: 'no-cache',
    });
  }
  updateTax(id: string, variables: TaxUpdateInput) {
    return this.apollo.mutate<any>({
      mutation: UpdateTaxMutation,
      variables: { id, input: variables },
      fetchPolicy: 'no-cache',
    });
  }
  createTax(variables: TaxCreateInput) {
    return this.apollo.mutate<any>({
      mutation: CreateTaxMutation,
      variables: { input: variables },
      fetchPolicy: 'no-cache',
    });
  }

  deleteTax(id: string) {
    return this.apollo.mutate<Boolean>({
      mutation: DeleteTaxMutation,
      variables: { id },
    });
  }
}
