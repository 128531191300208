import { gql } from 'apollo-angular';
import { AttributeFragments } from '../fragments/attribute-fragments';
import { environment } from 'src/environments/environment';

export const CustomersDropdownQuery = gql`
  query CustomersDropdownQuery($input: CustomerSearchArguments!) {
    customers(input: $input) {
      page
      itemsFound
      offset
      items {
        customerId
        firstName
        middleName
        lastName
      }
    }
  }
`;

export const CustomerListQuery = gql`
  query CustomerListQuery($input: CustomerSearchArguments!) {
    customers(input: $input) {
      page
      itemsFound
      offset
      items {
        customerId
        firstName
        customerId
        email
        middleName
        lastName
        attributes(input: { attributeDescription: { names: "${environment.userTagsAttributeName}" } }) {
          items {
            attribute {
             id
            }
            attributeDescription {
              id
            }
            value {
              ... on AttributeEnumValue {
                enumValues
              }
            }
          }
        }
      }
    }
  }
`;

export const CustomerDetailsQuery = gql`
  query CustomerDetailsQuery($id: Int!) {
    customer(id: $id) {
      customerId
      firstName
      lastName
      middleName
      gender
      dateOfBirth
      primaryLanguage
      expires
      email
      phone
      mobile
      isLoggedIn
      mailingList
      notes
      createdAt
      lastModifiedAt
      iban
      bankAccount
      bic
      parentUsergroupId
      sources {
        name
        id
      }
    }
  }
`;

export const CustomerDetailsAttributesQuery = gql`
  query customerDetailsAttributes($id: Int!, $input: AttributeResultSearchInput) {
    customer(id: $id) {
      attributes(input: $input) {
        items {
          attribute {
            id
            value {
              id
            }
          }
          attributeDescription {
            id
            valuesetId
            name
            descriptions {
              language
              value
            }
            type
            group
            isSearchable
            isPublic
            isSystem
            isHidden
            defaultValue {
              ...attrValue
            }
            createdAt
            lastModifiedAt
          }
          value {
            id
            ... on AttributeColorValue {
              id
              type
              colorValue
            }
            ... on AttributeDateTimeValue {
              id
              type
              dateTimeValue
            }
            ... on AttributeDecimalValue {
              id
              type
              decimalValue
            }
            ... on AttributeEnumValue {
              enumValues
            }
            ... on AttributeIntValue {
              id
              type
              intValue
            }
            ... on AttributeTextValue {
              textValues {
                language
                values
              }
            }
          }
        }
        itemsFound
        page
      }
    }
  }
  ${AttributeFragments.attributeValue}
`;

export const CustomerAddressQuery = gql`
  query customerAddresses($type: AddressType!, $customerId: Float!) {
    addressesByCustomerId(type: $type, customerId: $customerId) {
      firstName
      middleName
      lastName
      company
      street
      number
      numberExtension
      postalCode
      city
      region
      country
      phone
      isDefault
    }
  }
`;
