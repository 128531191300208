import { gql } from 'apollo-angular';

// export const AllQuotesQuery = gql`
//   query orders($input: OrderSearchArguments!) {
//     orders(input: $input) {
//       page
//       itemsFound
//       offset
//       items {
//         id
//         date
//         status
//         statusDate
//         userId
//         addresses {
//           firstName
//           middleName
//           lastName
//           company
//         }
//       }
//     }
//   }
// `;
export const AllQuotesQuery = gql`
  query AllQuotesQuery($input: OrderSearchArguments!) {
    orders(input: $input) {
      page
      itemsFound
      offset
      items {
        id
        date
        status
        statusDate
        userId
        type
        source
        debtorId
        exportStatus
        exportedAt
        exportMessage
        total {
          gross
        }
        addresses {
          firstName
          middleName
          lastName
          company
        }
        paymentData {
          status
        }
        items {
          id
        }
      }
    }
  }
`;

export const orderStatusQuery = gql`
  query orderStatusQuery($orderId: Int) {
    order(orderId: $orderId) {
      status
    }
  }
`;

export const QuoteQuery = gql`
  query QuoteQuery($orderId: Int) {
    order(orderId: $orderId) {
      id
      remarks
      reference
      total {
        net
        gross
      }
      postageData {
        gross
      }
      items {
        id
        productId
        product {
          costPrice
          originalPrice
        }
        sku
        quantity
        name
        price
        discount
        supplier
        priceTotal
      }
      userId
      addresses {
        firstName
        lastName
        city
        country
        email
        company
        phone
        number
        numberExtension
        postalCode
        region
        street
        type
      }
    }
  }
`;

export const SingleListQuoteQuery = gql`
  query SingleListQuoteQuery($orderId: Int) {
    order(orderId: $orderId) {
      id
      date
      status
      statusDate
      userId
      type
      source
      debtorId
      validUntil
      total {
        gross
      }
      addresses {
        firstName
        middleName
        lastName
        company
      }
      paymentData {
        status
      }
      items {
        id
      }
    }
  }
`;

export const OrderSendConfirmationEmail = gql`
  mutation orderSendConfirmationEmail($orderId: Int!, $attachments: [Base64FileInput!]) {
    orderSendConfirmationEmail(orderId: $orderId, attachments: $attachments) {
      messageId
      success
    }
  }
`;

export const OrderGetPDF = gql`
  query orderGetPDF($orderId: Int!) {
    orderGetPDF(orderId: $orderId) {
      base64
      contentType
      fileName
    }
  }
`;
