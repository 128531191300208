<div class="popup-close" *ngIf="isPicker">
  <button mat-icon-button (click)="closeDialogEmpty()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="amp-app" [ngClass]="{ 'component-dialog': isPicker }">
  <div class="header-wrapper">
    <div class="mat-headline-4 header">Users</div>
    <button
      mat-flat-button
      color="primary"
      [disabled]="headerButton.disabled"
      (click)="headerButtonClick()"
      *ngIf="!isViewer && !isPicker"
    >
      {{ headerButton.title }}
    </button>
  </div>
  <div>
    <mat-tab-group [(selectedIndex)]="selectedTab" (selectedIndexChange)="tabChange($event)">
      <mat-tab
        class="mat-body-2"
        label="Companies (B2B)"
        [disabled]="dialogData?.disableTab && dialogData.disableTab.includes(UserTypes.company)"
        *ngIf="!dialogData.displayedTabs || dialogData.displayedTabs.includes(UserTypes.company)"
      >
        <ng-template matTabContent>
          <app-companies
            [singleValueSelect]="singleValueSelect"
            [stateEntry]="stateEntry"
            [canAddTags]="canAddTags"
            [isInPicker]="enablePickerView"
            (actionTriggered)="handleAction()"
          ></app-companies>
        </ng-template>
      </mat-tab>
      <mat-tab
        class="mat-body-2"
        label="Contacts (B2B)"
        [disabled]="dialogData?.disableTab && dialogData.disableTab.includes(UserTypes.contact)"
        *ngIf="!dialogData.displayedTabs || dialogData.displayedTabs.includes(UserTypes.contact)"
      >
        <ng-template matTabContent>
          <app-contacts
            [singleValueSelect]="singleValueSelect"
            [stateEntry]="stateEntry"
            [canAddTags]="canAddTags"
            [isInPicker]="enablePickerView"
            (actionTriggered)="handleAction()"
          ></app-contacts>
        </ng-template>
      </mat-tab>
      <mat-tab
        class="mat-body-2"
        label="Customers (B2C)"
        [disabled]="dialogData?.disableTab && dialogData.disableTab.includes(UserTypes.customer)"
        *ngIf="!dialogData.displayedTabs || dialogData.displayedTabs.includes(UserTypes.customer)"
      >
        <ng-template matTabContent>
          <app-customers
            [singleValueSelect]="singleValueSelect"
            [stateEntry]="stateEntry"
            [canAddTags]="canAddTags"
            [isInPicker]="enablePickerView"
            (actionTriggered)="handleAction()"
          ></app-customers>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div *ngIf="isLoading" class="loader">
    <mat-spinner></mat-spinner>
  </div>
</div>
