import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, filter, map, Observable, of, switchMap, tap, withLatestFrom } from 'rxjs';
import * as CategoriesActions from './categories.actions';
import * as fromApp from '../../../store/app.reducer';
import { CategoriesService } from '../categories.service';
import { QueryRef } from 'apollo-angular';
import { SingleCategoryResponse } from 'src/app/shared/api/types/types';
import { CategoryNode } from 'src/app/shared/api/types/interfaces';

@Injectable()
export class CategoriesEffects {
  categoriesQuery: QueryRef<SingleCategoryResponse>;
  categoriesQuerySub: Observable<Action>;

  constructor(
    private actions: Actions,
    private categoriesService: CategoriesService,
    private store: Store<fromApp.AppState>,
  ) {}

  public readonly loadCategories: Observable<Action> = createEffect(() => {
    return this.actions.pipe(
      ofType(CategoriesActions.LOAD_CATEGORIES),
      withLatestFrom(this.store),
      filter(
        ([action, state]: [CategoriesActions.LoadCategories, any]) =>
          !state.categories[action.payload.stateEntry].loaded,
      ),
      switchMap(([action, loaded]: [CategoriesActions.LoadCategories, boolean]) => {
        return this.categoriesService.getRootCategory(action.payload.arguments).valueChanges.pipe(
          map((data) => data.data.category),
          map((category) => {
            const transformedRoot: CategoryNode = this.categoriesService.transformRoot(category);
            return new CategoriesActions.LoadCategoriesSuccess({
              category: transformedRoot,
              stateEntry: action.payload.stateEntry,
            });
          }),
          catchError((error) => {
            return of(new CategoriesActions.LoadCategoriesFailure({ error, stateEntry: action.payload.stateEntry }));
          }),
        );
      }),
    );
  });
}
