import { Pipe, PipeTransform } from '@angular/core';
import { Category } from '../api/types/GraphQL';

@Pipe({
  name: 'categoryPath',
})
export class CategoryPathPipe implements PipeTransform {
  transform(categoryPath: Category[]): string {
    return categoryPath.map((obj) => obj.name[0]?.value).join('/');
  }
}
