import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { OrderDelete, OrderSetStatus } from '../../shared/api/mutations/order-mutations';
import {
  TenderAddItems,
  TenderApplyIncentives,
  TenderDelete,
  TenderDeleteItem,
  TenderProcess,
  TenderStartMutation,
  TenderUpdate,
  TenderUpdateAddress,
  TenderUpdateDiscount,
  TenderUpdateItem,
  TenderUpdatePayment,
  TenderUpdatePostage,
} from '../../shared/api/mutations/tender-mutations';
import {
  AllQuotesQuery,
  OrderGetPDF,
  OrderSendConfirmationEmail,
  QuoteQuery,
  SingleListQuoteQuery,
  orderStatusQuery,
} from '../../shared/api/queries/quote-queries';
import { TenderQuery } from '../../shared/api/queries/tender-queries';
import { WarehousesQuery } from '../../shared/api/queries/warehouse-queries';
import {
  Base64FileInput,
  OrderSearchArguments,
  OrderSetStatusInput,
  OrderStatusesSearchInput,
  OrderStatusOrderType,
  OrderStatusSearchByInput,
  TenderAddItemsInput,
  TenderDiscountInput,
  TenderPaymentInput,
  TenderPostageInput,
  TenderStartInput,
  TenderUpdateAddressInput,
  TenderUpdateInput,
  TenderUpdateItemInput,
  WarehousesSearchInput,
} from '../../shared/api/types/GraphQL';
import {
  OrderGetPdfResponse,
  OrderListResponse,
  OrderResponseType,
  OrderSearchInput,
  OrderSendConfirmationEmailResponse,
  OrderSetStatusResponse,
  orderStatusesResponse,
  orderStatusResponse,
  QueryTenderResponse,
  TenderAddItemsResponse,
  TenderDeleteItemResponse,
  TenderDeleteResponse,
  TenderIncentivesResponse,
  TenderQueryInput,
  TenderSaveResponse,
  TenderStartResponse,
  TenderUpdateAddressResponse,
  TenderUpdateDiscountResponse,
  TenderUpdateItemResponse,
  TenderUpdatePaymentResponse,
  TenderUpdatePostageResponse,
  TenderUpdateResponse,
  WarehouseListResponse,
} from '../../shared/api/types/types';
import { OrderStatuses } from '../../shared/api/types/interfaces';
import { OrderStatusIsEditable, orderStatusListNamesQuery } from 'src/app/shared/api/queries/order-status-query';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OrdersService {
  orderStatuses: OrderStatuses;

  constructor(private apollo: Apollo) {}

  getOrderStatuses(): Promise<OrderStatuses> {
    return new Promise((resolve) => {
      this.getOrderStatusNames({ offset: 100 })
        .valueChanges.pipe(map((data) => data.data.orderStatuses.items))
        .subscribe({
          next: (orderStatuses) => {
            const statusData = {
              dropshipmentStatuses: orderStatuses
                .filter((status) => status.orderType === OrderStatusOrderType.Order)
                .map((status) => status.code),
              quoteStatuses: orderStatuses
                .filter((status) => status.orderType === OrderStatusOrderType.Quotation)
                .map((status) => status.code),
              requestStatuses: orderStatuses
                .filter((status) => status.orderType === OrderStatusOrderType.Request)
                .map((status) => status.code),
              rejectedStatuses: ['REJECTED'],
            };
            resolve(statusData);
          },
        });
    });
  }

  getAllQuotes(input: OrderSearchArguments, app?: string): QueryRef<OrderListResponse> {
    return this.apollo.watchQuery<OrderListResponse>({
      query: AllQuotesQuery,
      variables: { input, app: app },
      fetchPolicy: 'no-cache',
    });
  }

  getOrderStatus(variables: OrderSearchInput): QueryRef<OrderResponseType> {
    return this.apollo.watchQuery<OrderResponseType>({
      query: orderStatusQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
  }

  getQuote(variables: OrderSearchInput): QueryRef<OrderResponseType> {
    return this.apollo.watchQuery<OrderResponseType>({
      query: QuoteQuery,
      variables,
    });
  }

  getSingleListQuote(variables: OrderSearchInput): QueryRef<OrderResponseType> {
    return this.apollo.watchQuery<OrderResponseType>({
      query: SingleListQuoteQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
  }

  getTender(variables: TenderQueryInput): QueryRef<QueryTenderResponse> {
    return this.apollo.watchQuery<QueryTenderResponse>({
      query: TenderQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
  }

  getWarehouseList(variables: WarehousesSearchInput): QueryRef<WarehouseListResponse> {
    return this.apollo.watchQuery<WarehouseListResponse>({
      query: WarehousesQuery,
      variables: { input: variables },
    });
  }

  orderSendConfirmationEmail(orderId: number, attachments?: Base64FileInput[]) {
    return this.apollo.mutate<OrderSendConfirmationEmailResponse>({
      mutation: OrderSendConfirmationEmail,
      variables: { orderId, attachments },
    });
  }

  orderGetPdf(orderId: number) {
    return this.apollo.watchQuery<OrderGetPdfResponse>({
      query: OrderGetPDF,
      variables: { orderId: orderId },
    });
  }

  initializeTender(variables: TenderStartInput) {
    return this.apollo.mutate<TenderStartResponse>({
      mutation: TenderStartMutation,
      variables: { input: variables },
    });
  }

  tenderUpdateAddress(variables: { id: string; input: TenderUpdateAddressInput }) {
    return this.apollo.mutate<TenderUpdateAddressResponse>({
      mutation: TenderUpdateAddress,
      variables,
    });
  }

  tenderAddItems(variables: { id: string; input: TenderAddItemsInput }) {
    return this.apollo.mutate<TenderAddItemsResponse>({
      mutation: TenderAddItems,
      variables,
    });
  }

  tenderDeleteItem(variables: { id: string; itemId: string }) {
    return this.apollo.mutate<TenderDeleteItemResponse>({
      mutation: TenderDeleteItem,
      variables,
    });
  }

  tenderUpdateItem(variables: { id: string; itemId: string; input: TenderUpdateItemInput }) {
    return this.apollo.mutate<TenderUpdateItemResponse>({
      mutation: TenderUpdateItem,
      variables,
    });
  }

  tenderUpdateDiscount(variables: { id: string; input: TenderDiscountInput }) {
    return this.apollo.mutate<TenderUpdateDiscountResponse>({
      mutation: TenderUpdateDiscount,
      variables,
    });
  }

  tenderUpdatePayment(variables: { id: string; input: TenderPaymentInput }) {
    return this.apollo.mutate<TenderUpdatePaymentResponse>({
      mutation: TenderUpdatePayment,
      variables,
    });
  }

  tenderUpdatePostage(variables: { id: string; input: TenderPostageInput }) {
    return this.apollo.mutate<TenderUpdatePostageResponse>({
      mutation: TenderUpdatePostage,
      variables,
    });
  }

  tenderUpdate(variables: { id: string; input: TenderUpdateInput }) {
    return this.apollo.mutate<TenderUpdateResponse>({
      mutation: TenderUpdate,
      variables,
    });
  }

  tenderProcess(tenderId: string) {
    return this.apollo.mutate<TenderSaveResponse>({
      mutation: TenderProcess,
      variables: { id: tenderId },
    });
  }

  tenderApplyIncentives(tenderId: string) {
    return this.apollo.mutate<TenderIncentivesResponse>({
      mutation: TenderApplyIncentives,
      variables: { id: tenderId },
    });
  }

  tenderDelete(tenderId: string) {
    return this.apollo.mutate<TenderDeleteResponse>({
      mutation: TenderDelete,
      variables: { id: tenderId },
    });
  }

  orderSetStatus(variables: OrderSetStatusInput) {
    return this.apollo.mutate<OrderSetStatusResponse>({
      mutation: OrderSetStatus,
      variables: { input: variables },
    });
  }

  orderDelete(orderId: number) {
    return this.apollo.mutate<OrderResponseType>({
      mutation: OrderDelete,
      variables: { orderId },
    });
  }

  getOrderStatusNames(input: OrderStatusesSearchInput): QueryRef<orderStatusesResponse> {
    return this.apollo.watchQuery<orderStatusesResponse>({
      query: orderStatusListNamesQuery,
      variables: { input },
    });
  }

  //OrderStatuses
  getOrderStatusObj(input: OrderStatusSearchByInput): QueryRef<orderStatusResponse> {
    return this.apollo.watchQuery<orderStatusResponse>({
      query: OrderStatusIsEditable,
      variables: { input },
    });
  }
}
