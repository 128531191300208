import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import * as fromApp from '../../store/app.reducer';
import { AuthService } from '../auth.service';
import { catchError, EMPTY, filter, from, map, Observable, of, switchMap, take, withLatestFrom } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { Router } from '@angular/router';
import { MixpanelService } from 'src/app/shared/services/mixpanel.service';
import { MatDialog } from '@angular/material/dialog';
import { SidenavService } from 'src/app/side-nav/sidenav.service';

import { GuardStatusService } from 'src/app/shared/services/guard-status.service';

@Injectable()
export class AuthEffects {
  constructor(
    private actions: Actions,
    private authService: AuthService,
    private store: Store<fromApp.AppState>,
    private afAuth: AngularFireAuth,
    public router: Router,
    private mixpanelService: MixpanelService,
    private dialog: MatDialog,
    private sidenavService: SidenavService,
    private guardStatusService: GuardStatusService,
  ) {}

  public readonly authLogin: Observable<Action> = createEffect(() => {
    return this.actions.pipe(
      ofType(AuthActions.LOGIN_START),
      switchMap((action: AuthActions.LoginStart) => {
        return from(
          this.authService.signInWithEmailAndPassword(
            action.payload.email,
            action.payload.password,
            action.payload.persistentLogin,
          ),
        ).pipe(
          catchError((error) => {
            return of(new AuthActions.LoginError(error));
          }),
        );
      }),
    );
  });

  public readonly autoLogin: Observable<Action> = createEffect(() => {
    return this.actions.pipe(
      ofType(AuthActions.AUTO_LOGIN),
      switchMap((action: AuthActions.AutoLogin) => {
        return this.store
          .select((store) => store.auth.activeTenant)
          .pipe(
            take(1),
            filter((activeTenant) => !activeTenant),
            map(() => new AuthActions.Login(action.payload)),
          );
      }),
    );
  });

  public readonly setTenants: Observable<any> = createEffect(() => {
    return this.actions.pipe(
      ofType(AuthActions.LOGIN),
      switchMap((action: AuthActions.Login) => {
        return this.authService.getTenants(action.payload).pipe(
          map((data: any) => {
            this.store.dispatch(new AuthActions.SetUserId(data.id));
            if (data && data.tenants && data.tenants.length != 0) {
              this.mixpanelService.identify(data.email);
              this.mixpanelService.setPeople({ $email: data.email });
              this.mixpanelService.trackEvent('sign_in');

              if (this.router.url === '/sign-in') {
                this.router.navigate(['/home']);
              }

              return new AuthActions.SetTenants(data.tenants);
            } else {
              return new AuthActions.LogoutStart();
            }
          }),
          catchError((error) => of(new AuthActions.LoginError(error))),
        );
      }),
    );
  });

  public readonly authLogout: Observable<any> = createEffect(() => {
    return this.actions.pipe(
      ofType(AuthActions.LOGOUT_START),
      withLatestFrom(this.guardStatusService.getGuardStatus()),
      switchMap(([action, guardActive]) => {
        if (guardActive) {
          this.router.navigate([], { queryParams: { action: 'logout' }, queryParamsHandling: 'merge' });
          return EMPTY;
        } else {
          return from(this.afAuth.signOut()).pipe(
            map(() => {
              //this.authService.refreshToken();
              if (localStorage['persistentLogin']) {
                localStorage.removeItem('persistentLogin');
              }
              if (localStorage['taxes']) {
                localStorage.removeItem('taxes');
              }
              this.router.navigate(['sign-in']);
              this.sidenavService.changeTab('Home');
              localStorage.setItem('selectedTab', 'Home');

              if (this.dialog) {
                this.dialog.closeAll();
              }
              return new AuthActions.Logout();
            }),
          );
        }
      }),
    );
  });
}
