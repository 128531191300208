export enum AppLocation {
  sp = 'sp',
  bo = 'bo',
}
export enum RoutePaths {
  Companies = 'companies',
  ProductCatalogue = 'product-catalogue',
  SignIn = 'sign-in',
  ResetPassword = 'reset-password',
  Settings = 'settings',
  RuleBuilder = 'rule-builder',
  UserManagementFavorites = 'user-management/favorites',
  UserManagementOrderlists = 'user-management/orderlists',
  UserManagementUsers = 'user-management/users',
  UserManagementUsersDetails = 'user-management/users/details',
  OrderManagementCarriers = 'order-management/carriers',
  OrderManagementWarehouses = 'order-management/warehouses',
  OrderManagementPaymethods = 'order-management/paymethods',
  OrderManagementOrderStatuses = 'order-management/order-statuses',
  PimProductsAndClusters = 'pim/products-and-clusters',
  PimProductsAndClustersProduct = 'pim/products-and-clusters/product',
  PimCategories = 'pim/categories',
  Pricing = 'pricing',
  PricingTaxes = 'pricing/taxes',
  PricingSurcharges = 'pricing/surcharges',
  EBusinessIncentives = 'ebusiness/incentives',
  AdminValueset = 'admin/valueset',
  AdminBackofficeUsers = 'admin/backoffice-users',
  AdminAttributes = 'admin/attributes',
  RequestsSp = 'sp/requests',
  RequestsBo = 'bo/requests',
  Quotes = 'sp/quotes',
}

export enum MediaType {
  images,
  logos,
  icons,
  banners,
  videos,
  documents,
  manuals,
  warranties,
  paidDocuments,
}

export interface MediaTypeInterface {
  images: 'images';
  logos: 'logos';
  icons: 'icons';
  banners: 'banners';
  videos: 'videos';
  documents: 'documents';
  manuals: 'manuals';
  warranties: 'warranties';
  paidDocuments: 'paidDocuments';
}

export enum OrderTypeLocal {
  dropshipment = 'order',
  quotation = 'quotation',
  request = 'request',
  reject = 'reject',
}

export enum OrderStatusTypeLocal {
  dropshipment = 'ORDER',
  quotation = 'QUOTATION',
  request = 'REQUEST',
  reject = 'REJECTED',
}

export enum OrderState {
  new = 'new',
  edit = 'edit',
  processed = 'processed',
}

export enum OrderLocation {
  quotes = 'quotes',
  orders = 'orders',
  requests = 'requests',
  reject = 'reject',
}

export enum BusinessRulesLocation {
  incentives = 'incentives',
  carriers = 'carriers',
}

export enum BusinessRulesLocationTypeMapping {
  incentives = 'orderIncentives',
  carriers = 'orderCarriers',
}

export enum RuleTypeToRouteMapping {
  incentives = '/ebusiness/incentives',
  carriers = '/order-management/carriers',
}

export enum AppComponentLocation {
  catalog = 'product-catalogue',
  quotes = 'quotes',
  orders = 'orders',
  products = 'products-and-clusters',
  product = 'product',
  incentives = 'incentives',
  favorites = 'favorites',
  categories = 'categories',
  orderlists = 'orderlists',
  carriers = 'carriers',
  orderLists = 'orderLists',
  valuesets = 'valuesets',
  attributes = 'attributes',
}

export enum ProductStateEntry {
  productList = 'productListing',
  productPicker = 'productPicker',
}

export enum FavoriteStateEntry {
  favoriteListing = 'favoriteListing',
  favoriteUserListing = 'favoriteUserListing',
}

export enum UserStateEntry {
  userListing = 'userListing',
  userPicking = 'userPicking',
}

export enum StateEntry {
  listing = 'listing',
  picker = 'picker',
}

export interface OrderStatus {
  order: {
    draft: string;
    new: string;
    confirmed: string;
    archived: string;
    unfinished: string;
    validated: string;
  };
  quotation: {
    draft: string;
    quotation: string;
  };
  request: {
    request: string;
    rejected: string;
  };
}

export interface TableColumns {
  displayedColumns: string[];
  hiddenColumns: string[];
  disabledSuffixLength: number;
  availableColumns: string[];
  disabledColumns: string[];
}

export enum OrderEditorMixpanelPrefix {
  order = 'OE',
  quote = 'QE',
  request = 'RE',
}

export interface OrderStatuses {
  dropshipmentStatuses: string[];
  quoteStatuses: string[];
  requestStatuses: string[];
  rejectedStatuses: string[];
}

export enum ProductStatus {
  AVAILABLE = 'A',
  NOT_AVAILABLE = 'N',
  PHASE_OUT = 'P',
  PRESALE = 'S',
  RESTRICTED = 'R',
  OUT_OF_STOCK = 'T',
}

export const ProductStatusLableMapping = {
  [ProductStatus.AVAILABLE]: 'Available',
  [ProductStatus.NOT_AVAILABLE]: 'Not available',
  [ProductStatus.PHASE_OUT]: 'Phase out',
  [ProductStatus.PRESALE]: 'Presale',
  [ProductStatus.RESTRICTED]: 'Restricted',
  [ProductStatus.OUT_OF_STOCK]: 'Out of stock',
};

export enum isPublic {
  true = 'Is public',
  false = 'Is not public',
}

export enum isSearchable {
  true = 'Is searchable',
  false = 'Is not searchable',
}

export enum isSystem {
  true = 'Is system',
  false = 'Is not system',
}

export enum isHidden {
  true = 'Is hidden',
  false = 'Is not hidden',
}

export enum SurchargeTaxCodes {
  H = 'High VAT',
  L = 'Low VAT',
  N = 'Zero VAT',
}

export enum TaxCodes {
  H = 'High VAT',
  L = 'Low VAT',
  N = 'No VAT',
}

export enum Enabled {
  true = 'Yes',
  false = 'No',
}

export enum UserTypesPlural {
  customers = 'customers',
  contacts = 'contacts',
  companies = 'companies',
  usergroups = 'usergroups',
}

export enum UserTypes {
  customer = 'customer',
  contact = 'contact',
  company = 'company',
  companySearch = 'companysearch',
  usergroup = 'usergroup',
}

export enum StringExpressionOperatorsTypeMapping {
  Equals = 'string',
  NotEquals = 'string',
  StartsWith = 'string',
  EndsWith = 'string',
  Contains = 'string',
  ExistsInArray = 'string',
}

export enum numberExpressionOperatorsTypeMapping {
  Equals = 'number',
  NotEquals = 'number',
  LowerThan = 'number',
  GreaterThan = 'number',
  LowerThanOrEqualTo = 'number',
  GreaterThanOrEqualTo = 'number',
}

export enum DateExpressionOperatorsTypeMapping {
  before = 'date',
  after = 'date',
  beforeDaysFromToday = 'number',
  afterDaysFromToday = 'number',
  onDaysFromToday = 'number',
  beforeMinutesFromNow = 'number',
  afterMinutesFromNow = 'number',
  onMinutesFromNow = 'number',
  onDayOfWeek = 'number',
  onDayOfMonth = 'number',
  onMonthOfYear = 'number',
  onTime = 'string',
  beforeTime = 'string',
  afterTime = 'string',
}

export enum RuleMode {
  new = 'new',
  edit = 'edit',
}

export enum ActionTypeSecondaryFields {
  Price = 'price',
  Discount = 'discount',
}

export enum IncentiveOutputFields {
  outputName = 'output.name',
  incentiveName = 'incentive.name',
  action = 'incentive.action',
  productId = 'incentive.params.productId',
  quantity = 'incentive.params.quantity',
  price = 'incentive.params.price',
  discount = 'incentive.params.discount',
  discountType = 'incentive.params.discountType',
  valuePoints = 'incentive.params.valuePoints',
  repeat = 'incentive.params.repeat',
  actionCode = 'incentive.params.actionCode',
  affectedOrderItems = 'incentive.affectedOrderItems',
  orderItemExpressionValue = 'incentive.orderItemsExpressionValue',
  orderItemsExpressionParams = 'incentive.orderItemsExpressionParam',
}

export enum IncentiveRuleActionFields {
  outputName = 'outputName',
  incentiveName = 'incentiveName',
  action = 'action',
  productId = 'productId',
  quantity = 'quantity',
  price = 'price',
  discount = 'discount',
  discountType = 'discountType',
  valuePoints = 'valuePoints',
  repeat = 'repeat',
  actionCode = 'actionCode',
  affectedOrderItems = 'affectedOrderItems',
  orderItemExpressionValue = 'orderItemExpressionValue',
  orderItemsExpressionParams = 'orderItemsExpressionParams',
}

export enum CarrierOutputFields {
  carrierRuleName = 'carrier.ruleName',
  action = 'carrier.action',
  carrierId = 'carrier.params.carrierId',
}

export enum CarrierRuleActionFields {
  carrierRuleName = 'carrierRuleName',
  action = 'action',
  carrierId = 'carrierId',
}

export enum BusinessRuleActionCodeExpressionOperators {
  Equals = 'equals',
}

export enum IncentiveActions {
  AddProductToBonusItems = 'ADD_PRODUCT_TO_BONUS_ITEMS',
  //DuplicateProductInBonusItems = "DUPLICATE_PRODUCT_IN_BONUS_ITEMS",
  //MoveProductToBonusItems = "MOVE_PRODUCT_TO_BONUS_ITEMS",
  //DuplicateCheapestInBonusItems = "DUPLICATE_CHEAPEST_IN_BONUS_ITEMS",
  GiveDiscountToCheapestItem = 'GIVE_DISCOUNT_TO_CHEAPEST_ITEM',
  GiveDiscountToOrderItem = 'GIVE_DISCOUNT_TO_ORDER_ITEM',
  GiveDiscountToOrderTotal = 'GIVE_DISCOUNT_TO_ORDER_TOTAL',
  AddValuepointsToOrder = 'ADD_VALUEPOINTS_TO_ORDER',
  SetShippingCosts = 'SET_SHIPPING_COSTS',
  SetTransactionCosts = 'SET_TRANSACION_COSTS',
}

export enum CarrierActions {
  SetCarrierToSelectable = 'SET_CARRIER_TO_SELECTABLE',
}

export enum BundleDiscountType {
  N = 'NO_DISCOUNT',
  P = 'PERCENTAGE',
  A = 'AMOUNT',
}

export enum ListingTypes {
  taxes = 'taxes',
  favorites = 'favorites',
  users = 'users',
  orderlists = 'orderlists',
  valueSet = 'valueSet',
  valueSetItem = 'valueSetItem',
  surcharges = 'surcharges',
  paymethods = 'paymethods',
  carriers = 'carriers',
  adminUsers = 'backofficeUsers',
  attributeDescriptions = 'attributeDescriptions',
  orderStatus = 'orderStatus',
  orderStatusSet = 'orderStatusSet',
  priceSheet = 'priceSheet',
  backofficeUsers = 'backofficeUsers',
}

export enum DayOfWeek {
  Sunday = 0,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export enum DeliveryMethods {
  REGULAR = 'Delivery',
  PICKUP = 'Pick up',
}

export enum RoleNames {
  order = 'order',
  product = 'product',
  configuration = 'configuration',
  logistics = 'logistics',
  pricing = 'pricing',
  role = 'role',
  shop = 'shop',
  user = 'user',
  sales = 'sales',
  backoffice = 'backoffice',
}

export enum DialogButtonsText {
  CONFIRM = 'confirm',
  DISCARD = 'discard',
  CANCEL = 'cancel',
}

export enum AttributeGroup {
  PRODUCT = 'product',
  CATEGORY = 'category',
  CUSTOMER = 'customer',
  CONTACT = 'contact',
  COMPANY = 'company',
}

export enum AttributeTypeMap {
  TEXT = 'text',
  ENUM = 'enum',
  COLOR = 'color',
  DATETIME = 'dateTime',
  INT = 'int',
  DECIMAL = 'decimal',
}

export enum OrderSearchFields {
  ID = 'Id',
  DEBTOR_ID = 'Debtor Id',
  INVOICE_ADDRESS_FIRST_NAME = 'Address First Name',
  INVOICE_ADDRESS_LAST_NAME = 'Address Last Name',
  INVOICE_ADDRESS_COMPANY = 'Company Address',
  RECIPIENT_COMPANY = 'Company Recipient',
  RECIPIENT_FIRST_NAME = 'Recipient First Name',
  RECIPIENT_LAST_NAME = 'Recipient Last Name',
  RECIPIENT_EMAIL = 'Recipient Email',
  REMARKS = 'Remarks',
  REFERENCE = 'Reference',
  EXTRA3 = 'Extra 3',
  EXTRA4 = 'Extra 4',
  EXTERNAL_ORDER_ID = 'External Order Id',
  ACCOUNTING_ID = 'Accounting Id',
  ITEM_NAME = 'Item Name',
  ITEM_SKU = 'Sku',
  ITEM_SUPPLIER = 'Supplier',
  ITEM_SUPPLIER_CODE = 'Supplier code',
  ITEM_MANUFACTURER = 'Manufacturer',
  ITEM_MANUFACTURER_CODE = 'Manufacturer Code',
  ITEM_EAN_CODE = 'Ean Code',
  ITEM_NOTES = 'Notes',
}

export enum CompanyTermFieldName {
  COMPANY_ADDRESS_CITY = 'Company address city',
  COMPANY_ADDRESS_CODE = 'Company address code',
  COMPANY_ADDRESS_COUNTRY = 'Company address country',
  COMPANY_ADDRESS_EMAIL = 'Company address email',
  COMPANY_ADDRESS_FIRST_NAME = 'Company address first name',
  COMPANY_ADDRESS_ID = 'Company address ID',
  COMPANY_ADDRESS_LAST_NAME = 'Company address last name',
  COMPANY_ADDRESS_MOBILE = 'Company address mobile',
  COMPANY_ADDRESS_NAME = 'Company address name',
  COMPANY_ADDRESS_NOTES = 'Company address notes',
  COMPANY_ADDRESS_NUMBER = 'Company address number',
  COMPANY_ADDRESS_NUMBER_EXTENSION = 'Company address number extension',
  COMPANY_ADDRESS_PHONE = 'Company address phone',
  COMPANY_ADDRESS_POSTAL_CODE = 'Company address postal code',
  COMPANY_ADDRESS_REGION = 'Company address region',
  COMPANY_ADDRESS_STREET = 'Company address street',
  COMPANY_ATTRIBUTE_DESCRIPTION_ID = 'Company attribute description ID',
  COMPANY_ATTRIBUTE_DESCRIPTION_NAME = 'Company attribute description name',
  COMPANY_ATTRIBUTE_DESCRIPTION_TYPE = 'Company attribute description type',
  COMPANY_ATTRIBUTE_ID = 'Company attribute ID',
  COMPANY_ATTRIBUTE_VALUE_COLOR_VALUE = 'Company attribute value color value',
  COMPANY_ATTRIBUTE_VALUE_DATE_VALUE = 'Company attribute value date value',
  COMPANY_ATTRIBUTE_VALUE_DECIMAL_VALUE = 'Company attribute value decimal value',
  COMPANY_ATTRIBUTE_VALUE_ID = 'Company attribute value ID',
  COMPANY_ATTRIBUTE_VALUE_INTEGER_VALUE = 'Company attribute value integer value',
  COMPANY_ATTRIBUTE_VALUE_NAME = 'Company attribute value name',
  COMPANY_ATTRIBUTE_VALUE_TEXT_VALUES = 'Company attribute value text values',
  COMPANY_ATTRIBUTE_VALUE_TYPE = 'Company attribute value type',
  COMPANY_COC_NUMBER = 'Company CoC number',
  COMPANY_ID = 'Company ID',
  COMPANY_NAME = 'Company name',
  COMPANY_TAX_NUMBER = 'Company tax number',
  CONTACT_ATTRIBUTE_DESCRIPTION_ID = 'Contact attribute description ID',
  CONTACT_ATTRIBUTE_DESCRIPTION_NAME = 'Contact attribute description name',
  CONTACT_ATTRIBUTE_DESCRIPTION_TYPE = 'Contact attribute description type',
  CONTACT_ATTRIBUTE_ID = 'Contact attribute ID',
  CONTACT_ATTRIBUTE_VALUE_COLOR_VALUE = 'Contact attribute value color value',
  CONTACT_ATTRIBUTE_VALUE_DATE_VALUE = 'Contact attribute value date value',
  CONTACT_ATTRIBUTE_VALUE_DECIMAL_VALUE = 'Contact attribute value decimal value',
  CONTACT_ATTRIBUTE_VALUE_ID = 'Contact attribute value ID',
  CONTACT_ATTRIBUTE_VALUE_INTEGER_VALUE = 'Contact attribute value integer value',
  CONTACT_ATTRIBUTE_VALUE_NAME = 'Contact attribute value name',
  CONTACT_ATTRIBUTE_VALUE_TEXT_VALUES = 'Contact attribute value text values',
  CONTACT_ATTRIBUTE_VALUE_TYPE = 'Contact attribute value type',
  CONTACT_BANK_ACCOUNT = 'Contact bank account',
  CONTACT_BIC = 'Contact BIC',
  CONTACT_DEBTOR_ID = 'Contact debtor ID',
  CONTACT_EMAIL = 'Contact email',
  CONTACT_FIRST_NAME = 'Contact first name',
  CONTACT_IBAN = 'Contact IBAN',
  CONTACT_ID = 'Contact ID',
  CONTACT_LAST_NAME = 'Contact last name',
  CONTACT_MOBILE = 'Contact mobile',
  CONTACT_PHONE = 'Contact phone',
}

export enum ConfirmDialogType {
  CONFIRM = 'Confirm',
  SAVE = 'Save',
}

export enum ProductClassLocal {
  Cluster = 'cluster',
  Product = 'product',
}

export enum PriceDisplay {
  DEFAULT = 'Default',
  FROM = 'Price from [Cheapest price]',
  FROM_FOR = 'Old price [Suggested price] new price [Sale price]',
  LISTPRICE_OURPRICE = 'List price [Suggested price] our price [Sale price]',
  ON_REQUEST = 'Price on request',
  PACKAGE = 'Price [Sale price/Order unit] per [Order UOM]',
  PER_UOM = 'Price from [Cheapest price/Package unit] per [Package UOM] ',
}

export enum OrderExportStatus {
  EMPTY = 'Empty',
  EXPORTED = 'Exported',
  FAILED = 'Failed',
  FINISHED = 'Finished',
}
