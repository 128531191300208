import { Pipe, PipeTransform } from '@angular/core';
import { AttributeType, AttributeResult } from '../../api/types/GraphQL';
import { globals } from 'src/app/shared/globals';

@Pipe({
  name: 'attributeDefaultValue',
})
export class AttributeDefaultValuePipe implements PipeTransform {
  transform(attributeResult: AttributeResult): any {
    if (!attributeResult || !attributeResult.value) {
      return null;
    }

    const { type } = attributeResult.attributeDescription;
    const { value } = attributeResult;

    switch (type) {
      case AttributeType.Text:
        return this.getTextDefaultValue(value);
      case AttributeType.Enum:
        return this.getEnumDefaultValue(value);
      case AttributeType.Datetime:
        return this.getDateTimeDefaultValue(value);
      default:
        return this.getDefaultValueByType(type, value);
    }
  }

  private getTextDefaultValue(attributeValue: any): any {
    if (attributeValue && attributeValue.textValues) {
      return attributeValue.textValues.find((item) => item.language === globals.appLanguage)?.values;
    }
    return null;
  }

  private getEnumDefaultValue(attributeValue: any): any {
    if (attributeValue && attributeValue.enumValues) {
      return attributeValue.enumValues;
    }
    return null;
  }

  private getDateTimeDefaultValue(attributeValue: any): any {
    if (attributeValue && attributeValue.dateTimeValue) {
      return attributeValue.dateTimeValue;
    }
    return null;
  }

  private getDefaultValueByType(type: string, attributeValue: any): any {
    if (attributeValue && attributeValue[type.toLowerCase() + 'Value'] !== null) {
      return attributeValue[type.toLowerCase() + 'Value'];
    }
    return null;
  }
}
