import { gql } from 'apollo-angular';

export const SurchargesFragments = {
  surcharge: gql`
    fragment surcharge on Surcharge {
      id
      name {
        language
        value
      }
      description {
        language
        value
      }
      type
      value
      taxCode
      taxZone
      enabled
      validFrom
      validTo
      createdBy
      changedBy
      dateCreated
      dateChanged
      shopId
    }
  `,
};
