import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, filter, map, Observable, of, switchMap, tap, withLatestFrom } from 'rxjs';
import * as fromApp from 'src/app/store/app.reducer';
import * as ValueSetActions from './value-set.actions';
import { ValueSetService } from '../../value-set.service';
import { QueryRef } from 'apollo-angular';

import { valueSetListResponse } from 'src/app/shared/api/types/types';
import { StateEntry } from 'src/app/shared/api/types/enums';

@Injectable()
export class ValueSetEffects {
  valueSetQuery: QueryRef<valueSetListResponse>;
  valueSetQuerySub: Observable<Action>;
  stateEntry: StateEntry;

  constructor(
    private actions: Actions,
    private valueSetService: ValueSetService,
    private store: Store<fromApp.AppState>,
  ) {
    this.store
      .select((state) => state.valueSet.stateEntry)
      .subscribe((stateEntry) => {
        this.stateEntry = stateEntry;
      });
  }

  public readonly loadValueSet: Observable<Action> = createEffect(() => {
    return this.actions.pipe(
      ofType(ValueSetActions.LOAD_VALUE_SET),
      withLatestFrom(this.store.select((state) => state.valueSet[this.stateEntry].loaded)),
      filter(([action, loaded]) => !loaded),
      switchMap(([action, loaded]: [ValueSetActions.LoadValueSet, boolean]) => {
        this.valueSetQuery = this.valueSetService.getValueSetList(action.payload);
        this.valueSetQuerySub = this.valueSetQuery.valueChanges.pipe(
          map((data) => {
            this.store.dispatch(new ValueSetActions.GetValueSetCount(data.data.valuesets.itemsFound));
            return new ValueSetActions.LoadValueSetSuccess(data.data.valuesets.items);
          }),
          catchError((error) => of(new ValueSetActions.LoadValueSetFailure(error))),
        );
        return this.valueSetQuerySub;
      }),
    );
  });

  public readonly refetchValueSet = createEffect(
    () =>
      this.actions.pipe(
        ofType(ValueSetActions.REFETCH_VALUE_SET),
        tap((action: ValueSetActions.RefetchValueSet) => {
          this.valueSetQuery.resetLastResults();
          this.valueSetQuery.refetch(action.payload);
        }),
      ),
    { dispatch: false },
  );
}
