import { gql } from 'apollo-angular';

export const ShopsQuery = gql`
  query shops {
    shops {
      shopId
      name
      userRootId
    }
  }
`;

export const ShopQuery = gql`
  query shop($shopId: Int!) {
    shop(shopId: $shopId) {
      shopId
      name
      userRootId
    }
  }
`;
