import { gql } from 'apollo-angular';
import { SurchargesFragments } from '../fragments/surcharges-fragments';

export const AddSurchargesToProductMutation = gql`
  mutation AddSurchargesToProduct($input: AddSurchargesToProductInput!) {
    addSurchargesToProduct(input: $input) {
      status
      message
    }
  }
`;

export const RemoveSurchargesFromProductMutation = gql`
  mutation RemoveSurchargeFromoProduct($input: RemoveSurchargesFromProductInput!) {
    removeSurchargesFromProduct(input: $input) {
      status
      message
    }
  }
`;

export const UpdateSurcharges = gql`
  mutation UpdateSurcharge($id: String!, $input: UpdateSurchargeInput!) {
    surchargeUpdate(id: $id, input: $input) {
      ...surcharge
    }
  }
  ${SurchargesFragments.surcharge}
`;

export const CreateSurcharge = gql`
  mutation CreateSurcharge($input: CreateSurchargeInput!) {
    surchargeCreate(input: $input) {
      ...surcharge
    }
  }
  ${SurchargesFragments.surcharge}
`;

export const DeleteSurcharge = gql`
  mutation DeleteSurcharge($id: String!) {
    surchargeDelete(id: $id) {
      id
    }
  }
`;
