import { Action } from '@ngrx/store';
import { Pricesheet, PricesheetSearchInput } from 'src/app/shared/api/types/GraphQL';

export const GET_CURRENT_PAGE = '[PriceSheet] Get Current Page';
export const GET_CURRENT_PAGE_SIZE = '[PriceSheet] Get Current Page Size';
export const GET_PRICE_SHEET_COUNT = '[PriceSheet] Get PriceSheet Count';
export const LOAD_PRICE_SHEET = '[PriceSheet] Load PriceSheet';
export const REFETCH_PRICE_SHEET = '[PriceSheet] Refetch PriceSheet';
export const LOAD_PRICE_SHEET_SUCCESS = '[PriceSheet] Load PriceSheet Success';
export const LOAD_PRICE_SHEET_FAILURE = '[PriceSheet] Load PriceSheet Failure';

export const ADD_PRICE_SHEET = '[PriceSheet] Add PriceSheet';
export const CREATE_PRICE_SHEET = '[PriceSheet] Create PriceSheet';
export const UPDATE_PRICE_SHEET = '[PriceSheet] Update PriceSheet';
export const DELETE_PRICE_SHEET = '[PriceSheet] Delete PriceSheet';

export const GET_GENERAL_FILTER = '[PriceSheet] Get General Filter';
export const ADD_GENERAL_FILTER = '[PriceSheet] Add General Filter';
export const REMOVE_GENERAL_FILTER = '[PriceSheet] Remove General Filter';

export const ADD_PRICE_SHEET_TO_LIST = '[PriceSheet] Add Selected PriceSheet to List';
export const DELETE_PRICE_SHEET_FROM_LIST = '[PriceSheet] Delete PriceSheet from List';
export const CLEAR_PRICE_SHEET_LIST = '[PriceSheet] Clear PriceSheet List';

export class GetCurrentPage implements Action {
  readonly type = GET_CURRENT_PAGE;

  constructor(public payload: number) {}
}

export class GetCurrentPageSize implements Action {
  readonly type = GET_CURRENT_PAGE_SIZE;

  constructor(public payload: number) {}
}

export class GetPriceSheetCount implements Action {
  readonly type = GET_PRICE_SHEET_COUNT;

  constructor(public payload: number) {}
}

export class LoadPriceSheet implements Action {
  readonly type = LOAD_PRICE_SHEET;

  constructor(public payload: {}) {}
}

export class RefetchPriceSheet implements Action {
  readonly type = REFETCH_PRICE_SHEET;

  constructor(public payload: {}) {}
}

export class LoadPriceSheetSuccess implements Action {
  readonly type = LOAD_PRICE_SHEET_SUCCESS;

  constructor(public payload: Pricesheet[]) {}
}

export class LoadPriceSheetFailure implements Action {
  readonly type = LOAD_PRICE_SHEET_FAILURE;

  constructor(public payload: Error) {}
}

export class AddPriceSheet implements Action {
  readonly type = ADD_PRICE_SHEET;

  constructor(public payload: Pricesheet) {}
}
export class CreatePriceSheet implements Action {
  readonly type = CREATE_PRICE_SHEET;

  constructor(public payload: Pricesheet) {}
}
export class UpdatePriceSheet implements Action {
  readonly type = UPDATE_PRICE_SHEET;

  constructor(public payload: { id: number; updatedPriceSheet: Pricesheet }) {}
}
export class DeletePriceSheet implements Action {
  readonly type = DELETE_PRICE_SHEET;

  constructor(public payload: Pricesheet) {}
}

export class AddPriceSheetToList implements Action {
  readonly type = ADD_PRICE_SHEET_TO_LIST;

  constructor(public payload: Pricesheet) {}
}

export class DeletePriceSheetFromList implements Action {
  readonly type = DELETE_PRICE_SHEET_FROM_LIST;

  constructor(public payload: number) {}
}

export class ClearPriceSheetList implements Action {
  readonly type = CLEAR_PRICE_SHEET_LIST;
}

export class GetGeneralFilter implements Action {
  readonly type = GET_GENERAL_FILTER;

  constructor(public payload: string) {}
}
export class AddGeneralFilter implements Action {
  readonly type = ADD_GENERAL_FILTER;

  constructor(public payload: PricesheetSearchInput) {}
}

export class RemoveGeneralFilter implements Action {
  readonly type = REMOVE_GENERAL_FILTER;

  constructor(public payload: { columnName: string; index?: number }) {}
}

export type PriceSheetActions =
  | GetGeneralFilter
  | AddGeneralFilter
  | RemoveGeneralFilter
  | GetCurrentPage
  | GetCurrentPageSize
  | GetPriceSheetCount
  | LoadPriceSheet
  | RefetchPriceSheet
  | LoadPriceSheetSuccess
  | LoadPriceSheetFailure
  | AddPriceSheet
  | UpdatePriceSheet
  | DeletePriceSheet
  | AddPriceSheetToList
  | DeletePriceSheetFromList
  | ClearPriceSheetList
  | CreatePriceSheet;
