import { Action } from '@ngrx/store';
import { PayMethod, PayMethodSearchInput } from 'src/app/shared/api/types/GraphQL';

export const GET_CURRENT_PAGE = '[PayMethods] Get Current Page';
export const GET_CURRENT_PAGE_SIZE = '[PayMethods] Get Current Page Size';
export const GET_PAYMETHODS_COUNT = '[PayMethods] Get PayMethods Count';
export const LOAD_PAYMETHODS = '[PayMethods] Load PayMethods';
export const REFETCH_PAYMETHODS = '[PayMethods] Refetch PayMethods';
export const LOAD_PAYMETHODS_SUCCESS = '[PayMethods] Load PayMethods Success';
export const LOAD_PAYMETHODS_FAILURE = '[PayMethods] Load PayMethods Failure';

export const ADD_PAYMETHOD = '[PayMethods] Add PayMethod';
export const UPDATE_PAYMETHOD = '[PayMethods] Update PayMethod';
export const DELETE_PAYMETHOD = '[PayMethods] Delete PayMethod';

export const GET_GENERAL_FILTER = '[PayMethods] Get General Filter';
export const GET_COLUMN_FILTERS = '[PayMethods] Get Column Filter';
export const GET_MULTISELECT_FILTERS = '[PayMethods] Get Multiselect Filters';

export const ADD_PAYMETHOD_TO_LIST = '[PayMethod] Add Selected PayMethod to List';
export const DELETE_PAYMETHOD_FROM_LIST = '[PayMethod] Delete PayMethod from List';
export const CLEAR_PAYMETHOD_LIST = '[PayMethod] Clear PayMethods List';

export const ADD_SORT_FILTERS = '[PayMethods] Add Sort Filters';

export const PAYMETHODS_PAGE_INITIALIZED = '[PayMethods] Page Initialized';
export const PAYMETHODS_PAGE_DESTROYED = '[PayMethods] Page Destroyed';

export class GetCurrentPage implements Action {
  readonly type = GET_CURRENT_PAGE;

  constructor(public payload: number) {}
}

export class GetCurrentPageSize implements Action {
  readonly type = GET_CURRENT_PAGE_SIZE;

  constructor(public payload: number) {}
}

export class GetPayMethodsCount implements Action {
  readonly type = GET_PAYMETHODS_COUNT;

  constructor(public payload: number) {}
}

export class LoadPayMethods implements Action {
  readonly type = LOAD_PAYMETHODS;

  constructor(public payload: {}) {}
}

export class RefetchPayMethods implements Action {
  readonly type = REFETCH_PAYMETHODS;

  constructor(public payload: {}) {}
}

export class LoadPayMethodsSuccess implements Action {
  readonly type = LOAD_PAYMETHODS_SUCCESS;

  constructor(public payload: PayMethod[]) {}
}

export class LoadPayMethodsFailure implements Action {
  readonly type = LOAD_PAYMETHODS_FAILURE;

  constructor(public payload: Error) {}
}

export class AddPayMethod implements Action {
  readonly type = ADD_PAYMETHOD;

  constructor(public payload: PayMethod) {}
}

// export class UpdatePayMethod implements Action {
//     readonly type = UPDATE_PAYMETHOD;

//     constructor(public payload: {id: number, newPayMethod: Order}) {}
// }
export class DeletePayMethod implements Action {
  readonly type = DELETE_PAYMETHOD;

  constructor(public payload: number) {}
}

export class AddPayMethodToList implements Action {
  readonly type = ADD_PAYMETHOD_TO_LIST;

  constructor(public payload: PayMethod) {}
}

export class DeletePayMethodFromList implements Action {
  readonly type = DELETE_PAYMETHOD_FROM_LIST;

  constructor(public payload: number) {}
}

export class ClearPayMethodList implements Action {
  readonly type = CLEAR_PAYMETHOD_LIST;
}

export class GetGeneralFilter implements Action {
  readonly type = GET_GENERAL_FILTER;

  constructor(public payload: string) {}
}

export class GetColumnFilters implements Action {
  readonly type = GET_COLUMN_FILTERS;

  constructor(public payload: { columnName; filterValue }) {}
}

export class GetMultiselectFilters implements Action {
  readonly type = GET_MULTISELECT_FILTERS;

  constructor(public payload: PayMethodSearchInput) {}
}

export class AddSortFilters implements Action {
  readonly type = ADD_SORT_FILTERS;

  constructor(public payload: PayMethodSearchInput) {}
}

export class PayMethodsPageInitialized implements Action {
  readonly type = PAYMETHODS_PAGE_INITIALIZED;
}

export class PayMethodsPageDestroyed implements Action {
  readonly type = PAYMETHODS_PAGE_DESTROYED;
}

export type PayMethodsActions =
  | GetGeneralFilter
  | GetColumnFilters
  | GetMultiselectFilters
  | GetCurrentPage
  | GetCurrentPageSize
  | GetPayMethodsCount
  | LoadPayMethods
  | RefetchPayMethods
  | LoadPayMethodsSuccess
  | LoadPayMethodsFailure
  | AddPayMethod
  | AddSortFilters
  //| UpdatePayMethod
  | DeletePayMethod
  | AddPayMethodToList
  | DeletePayMethodFromList
  | ClearPayMethodList
  | PayMethodsPageInitialized
  | PayMethodsPageDestroyed;
