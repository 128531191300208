import { gql } from 'apollo-angular';

import { globals } from 'src/app/shared/globals';
import { MediaFragments } from '../fragments/media-fragments';
import { ProductFragments } from '../fragments/product-fragments';

//$userId: Int
export const AllProductsQuery = gql`
  query AllProductsQuery($input: ProductSearchInput, $appLanguage: String) {
    products(input: $input) {
      page
      itemsFound
      pages
      offset
      start
      minPrice
      maxPrice
      items {
        ... on Cluster {
          names(language: $appLanguage) {
            value
          }
          categoryPath {
            categoryId
            name(language: $appLanguage) {
              value
            }
          }
          class
          sku
          id
          defaultProduct {
            media {
              ...imageSmall
            }
          }
        }
        ... on Product {
          class
          cluster {
            id
          }
          minimumQuantity
          supplier
          ...listProductData
          media {
            ...imageSmall
          }
        }
      }
    }
  }
  ${MediaFragments.imageSmall}
  ${ProductFragments.listProductData}
`;

//$userId: Int
export const AllProductsWithClusterItemsQuery = gql`
  query AllProductsWithClusterItemsQuery($input: ProductSearchInput, $appLanguage: String) {
    products(input: $input) {
      page
      itemsFound
      pages
      offset
      start
      minPrice
      maxPrice
      items {
        ... on Cluster {
          clusterId
          names(language: $appLanguage) {
            value
          }
          categoryId
          categoryPath {
            categoryId
            name(language: $appLanguage) {
              value
            }
          }
          class
          sku
          id
          products {
            ...listProductData
            media {
              ...imageSmall
            }
          }
          defaultProduct {
            ...listProductData
            media {
              ...imageSmall
            }
          }
        }
        ... on Product {
          class
          cluster {
            id
          }
          minimumQuantity
          supplier
          ...listProductData
          media {
            ...imageSmall
          }
        }
      }
    }
  }
  ${MediaFragments.imageSmall}
  ${ProductFragments.listProductData}
`;

// need to check
export const ProductsByProductCode = gql`
  query ProductsByProductCode($input: ProductSearchInput) {
    products(input: $input) {
      itemsFound
      items {
        ... on Product {
          sku
          productId
          minimumQuantity
        }
      }
    }
  }
`;

// , $userId: Int
export const CatalogQuery = gql`
  query CatalogQuery($input: ProductSearchInput, $contactId: Int, $companyId: Int) {
    products(input: $input) {
      page
      itemsFound
      pages
      offset
      filters(input: { isPublic: true, isSearchable: true }) {
        attributeDescription {
          name
          descriptions {
            language
            value
          }
        }
        decimalRangeFilter {
          max
          min
        }
        textFilters {
          count
          value
          countTotal
          countActive
          isSelected
        }
        type
      }
      items {
        ... on Cluster {
          names {
            value
          }
          class
          sku
          products {
            ...catalogProductData
            media {
              ...imageLarge
            }
          }
          defaultProduct {
            ...catalogProductData
            media {
              ...imageLarge
            }
          }
        }
        ... on Product {
          class
          ...catalogProductData
          media {
            ...imageLarge
          }
        }
      }
    }
  }
  ${MediaFragments.imageLarge}
  ${ProductFragments.catalogProductData}
`;

export const CatalogProductDetailsQuery = gql`
  query CatalogProductDetailsQuery(
    $id: Int
    $language: String
    $sku: String
    $productId: Int
    $slug: String
    $contactId: Int
    $companyId: Int
  ) {
    product(id: $id, language: $language, sku: $sku, productId: $productId, slug: $slug) {
      ...catalogDetailsProductData
      media {
        ...imageMediumXLarge
      }
    }
  }
  ${MediaFragments.imageMediumXLarge}
  ${ProductFragments.catalogDetailsProductData}
`;

export const ClusterTypeQuery = gql`
query ClusterTypeQuery ($id: Int, $language: String, $sku: String, $productId: Int, $slug: String) {
  product(id: $id, language: $language, sku: $sku, productId: $productId, slug: $slug) {
    id
    productId
    cluster {
      options {
        names {
          value
        }
        defaultProduct {
          names (language: "${globals.appLanguage}") {
            value
          }
        }
      }
      drillDowns {
        attributeId
        priority
      }
    }
  }
}
`;

export const NormalClasterQuery = gql`
query NormalClasterQuery ($id: Int, $language: String, $sku: String, $productId: Int, $slug: String, $userId: Int) {
  product(id: $id, language: $language, sku: $sku, productId: $productId, slug: $slug) {
     cluster {
      names {
        value
      }
      categoryPath {
        categoryId
        name(language: "${globals.appLanguage}") {
          value
        }
      }
      class
      clusterId
      sku
      id
      products {
        cluster {
          names {
            value
          }
          id
        }
        class
        ...catalogDetailsProductData
        media {
          ...imageMediumXLarge
        }
      }
      options {
        names {
          value
        }
        isRequired
        isHidden
        defaultProduct {
          names (language: "${globals.appLanguage}") {
            value
          }
        }
      }
     }
  }
}
${MediaFragments.imageMediumXLarge}
${ProductFragments.catalogDetailsProductData}
`;

export const LinearClusterQuery = gql`
query LinearClusterQuery ($id: Int, $language: String, $sku: String, $productId: Int, $slug: String, $userId: Int, $attributeIds:[String!]) {
  product(id: $id, language: $language, sku: $sku, productId: $productId, slug: $slug) {
     cluster {
      names {
        value
      }
      categoryPath {
        categoryId
        name(language: "${globals.appLanguage}") {
          value
        }
      }
      class
      sku
      id
      clusterId
      products {
        cluster {
          names {
            value
          }
          id
        }
        class
        ...catalogDetailsProductData
        media {
          ...imageMediumXLarge
        }
        attributes (input: {attributeDescription: {ids: $attributeIds}}) {
          itemsFound
          page
          items{
            attributeDescription {
              names
              descriptions {
                language
                value
              }
              type
            }
            value {
              ...on AttributeColorValue {
                type
                colorValue
              }
              ...on AttributeDateTimeValue {
                type
                dateTimeValue
              }
              ...on AttributeDecimalValue {
                type
                decimalValue
              }
              ...on AttributeEnumValue {
                type
                enumValues
              }
              ...on AttributeIntValue {
                type
                intValue
              }
              ...on AttributeTextValue {
                type
                textValues {
                  language
                  values
                }
              }
            }
          }
        }
      }
      drillDowns {
        attributeId
        priority
        displayType
      }
     }
  }
}
${MediaFragments.imageMediumXLarge}
${ProductFragments.catalogDetailsProductData}
`;

export const ConfigurableClusterQuery = gql`
  query ConfigurableClusterQuery(
    $id: Int
    $language: String
    $sku: String
    $productId: Int
    $slug: String
    $userId: Int
  ) {
    product(id: $id, language: $language, sku: $sku, productId: $productId, slug: $slug) {
      cluster {
        names {
          value
        }
        class
        sku
        id
        clusterId
        products {
          cluster {
            names {
              value
            }
            id
            products {
              id
            }
            options {
              products {
                id
              }
            }
          }
          class
          ...catalogDetailsProductData
          media {
            ...imageMediumXLarge
          }
        }
        options {
          names {
            value
          }
          isRequired
          defaultProduct {
            productId
            price {
              net
            }
          }
          products {
            cluster {
              names {
                value
              }
              id
            }
            class
            ...catalogDetailsProductData
            media {
              ...imageMediumXLarge
            }
          }
        }
      }
    }
  }
  ${MediaFragments.imageMediumXLarge}
  ${ProductFragments.catalogDetailsProductData}
`;

export const ProductSpecifications = gql`
  query ProductSpecifications($id: Int, $language: String, $sku: String, $productId: Int, $slug: String) {
    product(id: $id, language: $language, sku: $sku, productId: $productId, slug: $slug) {
      attributes(input: { attributeDescription: { isPublic: true }, offset: 1000 }) {
        itemsFound
        page
        items {
          attributeDescription {
            name
            descriptions {
              language
              value
            }
            type
          }
          value {
            ... on AttributeColorValue {
              type
              colorValue
            }
            ... on AttributeDateTimeValue {
              type
              dateTimeValue
            }
            ... on AttributeDecimalValue {
              type
              decimalValue
            }
            ... on AttributeEnumValue {
              type
              enumValues
            }
            ... on AttributeIntValue {
              type
              intValue
            }
            ... on AttributeTextValue {
              type
              textValues {
                language
                values
              }
            }
          }
        }
      }
    }
  }
`;

export const ProductDocuments = gql`
query ProductDocuments ($id: Int, $language: String, $sku: String, $productId: Int, $slug: String) {
  product(id: $id, language: $language, sku: $sku, productId: $productId, slug: $slug) {
    media {
      documents {
        items {
          alt(language: "${globals.appLanguage}") {
            value
          }
          tags(language: "${globals.appLanguage}") {
            values
          }
          description(language: "${globals.appLanguage}") {
            value
          }
          type
          documents {
            language
            originalUrl
            mimeType
          }
        }
      }
    }
  }
}
`;

export const ProductVideos = gql`
query ProductVideos ($id: Int, $language: String, $sku: String, $productId: Int, $slug: String) {
  product(id: $id, language: $language, sku: $sku, productId: $productId, slug: $slug) {
    media {
      videos {
        items {
          alt(language: "${globals.appLanguage}") {
            value
          }
          description(language: "${globals.appLanguage}") {
            value
          }
          tags(language: "${globals.appLanguage}") {
            values
          }
          type
          videos {
            language
            uri
            mimeType

          }
        }
      }
    }
  }
}
`;
