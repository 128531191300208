import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import * as fromApp from 'src/app/store/app.reducer';

@Component({
  selector: 'app-localized-string-input',
  templateUrl: './localized-string-input.component.html',
  styleUrls: ['./localized-string-input.component.scss'],
})
export class LocalizedStringInputComponent {
  @ViewChild('languageSelect') languageSelect: ElementRef;
  @Input() selectedLanguage: string;
  @Input() isTextArea: boolean = false;
  @Output() languageChanged = new EventEmitter<string>();

  customData: any[];

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit(): void {
    this.store
      .select((store) => store.customLanguages)
      .pipe(map((languages) => languages.customLanguage))
      .subscribe((data) => {
        this.customData = data;
      });
  }

  openLanguageSelect(selectElement: any): void {
    selectElement.open();
  }

  onLanguageChange(event: any): void {
    this.languageChanged.emit(event.value);
  }
}
