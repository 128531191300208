import { formatDate } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import {
  AttributeCreate,
  AttributeDelete,
  AttributeDescriptionCreate,
  AttributeDescriptionDelete,
  AttributeDescriptionUpdate,
  AttributeUpdate,
} from 'src/app/shared/api/mutations/attribute-mutations';
import { AttributeDescriptionList } from 'src/app/shared/api/queries/attribute-queries';
import {
  AttributeCreateInput,
  AttributeDescription,
  AttributeDescriptionCreateInput,
  AttributeDescriptionSearchInput,
  AttributeType,
  AttributeDescriptionUpdateInput,
  AttributeUpdateInput,
} from 'src/app/shared/api/types/GraphQL';
import {
  AttributeCreateResponse,
  AttributeDeleteResponse,
  AttributeDescriptionCreateResponse,
  AttributeDescriptionDeleteResponse,
  AttributeDescriptionUpdateResponse,
  AttributeDescriptionsResponse,
  AttributeUpdateResponse,
} from 'src/app/shared/api/types/types';
import { globals } from 'src/app/shared/globals';

@Injectable({
  providedIn: 'root',
})
export class AttributesService {
  constructor(
    private apollo: Apollo,
    @Inject(LOCALE_ID) private locale: string,
  ) {}

  getAttributeDescriptionList(input: AttributeDescriptionSearchInput): QueryRef<AttributeDescriptionsResponse> {
    return this.apollo.watchQuery<AttributeDescriptionsResponse>({
      query: AttributeDescriptionList,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
  }

  attributeDescriptionDelete(variables: { id: string }) {
    return this.apollo.mutate<AttributeDescriptionDeleteResponse>({
      mutation: AttributeDescriptionDelete,
      variables,
    });
  }

  attributeDescriptionUpdate(variables: { id: string; input: AttributeDescriptionUpdateInput }) {
    return this.apollo.mutate<AttributeDescriptionUpdateResponse>({
      mutation: AttributeDescriptionUpdate,
      variables,
    });
  }

  attributeDescriptionCreate(variables: { input: AttributeDescriptionCreateInput }) {
    return this.apollo.mutate<AttributeDescriptionCreateResponse>({
      mutation: AttributeDescriptionCreate,
      variables,
    });
  }

  getAttrDefaultValue(attr: AttributeDescription) {
    const attrType = attr.type;
    const attrValue = attr.defaultValue;
    if (attrValue) {
      if (attrType === AttributeType.Text) {
        return attrValue['textValues'].find((item) => item.language === globals.appLanguage)?.values || [];
      } else if (attrType === AttributeType.Enum) {
        return attrValue['enumValues'];
      } else if (attrType === AttributeType.Datetime) {
        if (attrValue['dateTimeValue']) {
          const dateValue = attrValue['dateTimeValue'];
          const parsedDate = Date.parse(dateValue);
          return formatDate(parsedDate, 'd MMMM, yyyy - HH:mm', this.locale);
        }
        return null;
      } else {
        return attrValue[attrType.toLowerCase() + 'Value'];
      }
    }
  }

  attributeCreate(variables: { input: AttributeCreateInput }) {
    return this.apollo.mutate<AttributeCreateResponse>({
      mutation: AttributeCreate,
      variables,
    });
  }

  AttributeUpdate(variables: { id: string; input: AttributeUpdateInput }) {
    return this.apollo.mutate<AttributeUpdateResponse>({
      mutation: AttributeUpdate,
      variables,
    });
  }

  attributeDelete(variables: { id: string }) {
    return this.apollo.mutate<AttributeDeleteResponse>({
      mutation: AttributeDelete,
      variables,
    });
  }
}
