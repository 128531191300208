import { gql } from 'apollo-angular';
import { globals } from 'src/app/shared/globals';

// Note: 'isOrderable' field does not exist in fragment listProductData on Product
// Add: "orderable, orderableTo"
// Removed: "supplier purchaseMinimumQuantity"
export const ProductFragments = {
  listProductData: gql`
    fragment listProductData on Product {
      names(language: $appLanguage) {
        value
      }
      categoryPath {
        categoryId
        name(language: $appLanguage) {
          value
        }
      }
      categoryId
      id
      productId
      manufacturer

      inventory {
        totalQuantity
      }
      unit

      price {
        gross
        net
      }
      sku
      orderable
      orderableTo
      status
    }
  `,

  catalogProductData: gql`
    fragment catalogProductData on Product {
        names (language: "${globals.appLanguage}") {
            value
        }
        manufacturerCode
        supplierCode
        inventory {
            totalQuantity
        }
        unit
        minimumQuantity
        price (input: {contactId: $contactId, companyId: $companyId, taxZone: "${globals.appTaxZone}"}) {
          gross
          net
        }
        priceData {
          list
          cost
        }
        sku
        productId
    }
  `,

  catalogDetailsProductData: gql`
    fragment catalogDetailsProductData on Product {
        sku
        names (language: "${globals.appLanguage}") {
          value
        }
        categoryPath {
          categoryId
          name(language: "${globals.appLanguage}") {
            value
          }
        }
        productId
        inventory {
          totalQuantity
        }
        manufacturerCode
        manufacturer
        supplier
        supplierCode
        minimumQuantity
        price (input: {contactId: $contactId, companyId: $companyId, taxZone: "${globals.appTaxZone}"}) {
          gross
          net
        }
        priceData {
          list
          cost
        }
        bulkPrices (taxZone: "${globals.appTaxZone}") {
          discountType
          gross
          discount {
            value
            quantityFrom
          }
        }
        unit
        package
        descriptions (language: "${globals.appLanguage}") {
          value
        }
        shortDescriptions (language: "${globals.appLanguage}") {
          value
        }
    }
  `,
};
