import { gql } from 'apollo-angular';
import { orderStatusFragments } from '../fragments/order-status-fragments';

export const OrderStatusNextStatuses = gql`
  query OrderStatusNextStatuses($input: OrderStatusSearchByInput!) {
    orderStatus(input: $input) {
      nextStatuses {
        items {
          id
          name
          code
          orderType
          isEditable
          nextStatuses {
            itemsFound
          }
        }
      }
    }
  }
`;

export const OrderStatusIsEditable = gql`
  query OrderStatusNextStatuses($input: OrderStatusSearchByInput!) {
    orderStatus(input: $input) {
      code
      isEditable
      nextStatuses {
        itemsFound
      }
    }
  }
`;

export const orderStatusesListQuery = gql`
  query orderStatuses($input: OrderStatusesSearchInput) {
    orderStatuses(input: $input) {
      items {
        ...orderStatus
        statusSet {
          id
          name
          description
        }
      }
      itemsFound
      offset
      page
      pages
      start
      end
    }
  }
  ${orderStatusFragments.orderStatus}
`;
export const orderStatusListDropDownQuery = gql`
  query orderStatuses($input: OrderStatusesSearchInput) {
    orderStatuses(input: $input) {
      items {
        id
        name
      }
      itemsFound
      offset
      page
      pages
      start
      end
    }
  }
`;

export const orderStatusListNamesQuery = gql`
  query orderStatuses($input: OrderStatusesSearchInput) {
    orderStatuses(input: $input) {
      items {
        id
        name
        code
        orderType
      }
      itemsFound
      offset
    }
  }
`;
export const orderStatusQuery = gql`
  query orderStatus($input: OrderStatusSearchByInput!) {
    orderStatus(input: $input) {
      ...orderStatus
    }
  }
  ${orderStatusFragments.orderStatus}
`;
