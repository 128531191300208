import { ActionReducer, ActionReducerMap } from '@ngrx/store';
import * as fromQuotes from '../order-management/orders/store/quotes/quotes.reducer';
import * as fromProducts from '../pim//products/store/products.reducer';
import * as fromOrders from '../order-management/orders/store/orders/orders.reducer';
import * as fromRequests from '../order-management/orders/store/requests/requests.reducer';
import * as fromCompanies from '../companies/store/companies/companies.reducer';
import * as fromMyCompanies from '../companies/store/my-companies/my-companies.reducer';
import * as fromCatalog from '../catalog/store/catalog.reducer';
import * as fromAuth from '../auth/store/auth.reducer';
import * as fromUsers from '../crm/users/store/users/users.reducer';
import * as fromBusinessRules from '../business-rules/store/business-rules.reducer';
import * as fromFavorites from '../crm/favorites/store/favorites.reducer';
import * as fromCarriers from '../order-management/carriers/store/carriers.reducer';
import * as fromTaxes from '../pricing/taxes/store/taxes.reducer';
import * as fromOrderlists from '../crm/orderlists/store/orderlists.reducer';
import * as fromWarehouses from '../order-management/warehouses/store/warehouses.reducer';
import * as fromCategories from '../pim/categories/store/categories.reducer';
import * as fromValueSet from '../admin/value-set/store/value-set/value-set.reducer';
import * as fromValueSetItem from '../admin/value-set/store/value-set-item/value-set-item.reducer';
import * as fromPayMethods from '../order-management/paymethods/store/paymethods.reducer';
import * as fromOrderStatus from '../order-management/order-status/store/order-status/order-status.reducer';
import * as fromOrderStatusSet from '../order-management/order-status/store/order-status-set/order-status-set.reducer';
import * as fromSurcharges from '../pricing/surcharges/store/surcharges.reducer';
import * as fromBackofficeUsers from '../admin/backoffice-users/store/backoffice-users.reducer';
import * as fromTableColumns from '../shared/store/table-columns-store/table-columns.reducer';
import * as fromAttributes from '../admin/attributes/store/attributes.reducer';
import * as fromPriceSheet from '../pricing/pricing/pricesheets/store/price-sheet.reducer';
import * as fromCustomLanguages from './custom-languages-store/custom-languages.reducer';

import * as AuthActions from '../auth/store/auth.actions';

export interface AppState {
  auth: any;
  customLanguages: any;
  quotes: fromQuotes.State;
  products: any;
  orders: fromOrders.State;
  requests: fromRequests.State;
  companies: fromCompanies.State;
  myCompanies: fromMyCompanies.State;
  catalog: any;
  users: fromUsers.State;
  businessRules: fromBusinessRules.State;
  favorites: fromFavorites.State;
  carriers: fromCarriers.State;
  taxes: fromTaxes.State;
  orderlists: fromOrderlists.State;
  warehouses: fromWarehouses.State;
  categories: fromCategories.State;
  valueSet: fromValueSet.State;
  valueSetItem: fromValueSetItem.State;
  surcharges: fromSurcharges.State;
  paymethods: fromPayMethods.State;
  orderStatus: fromOrderStatus.State;
  orderStatusSet: fromOrderStatusSet.State;
  backofficeUsers: fromBackofficeUsers.State;
  tableColumns: any;
  attributes: any;
  priceSheet: fromPriceSheet.State;
}

export const appReducer: ActionReducerMap<AppState> = {
  quotes: fromQuotes.quotesReducer,
  products: fromProducts.productsReducer,
  orders: fromOrders.ordersReducer,
  requests: fromRequests.requestsReducer,
  companies: fromCompanies.companiesReducer,
  myCompanies: fromMyCompanies.myCompaniesReducer,
  catalog: fromCatalog.catalogReducer,
  auth: fromAuth.authReducer,
  users: fromUsers.usersReducer,
  businessRules: fromBusinessRules.businessRulesReducer,
  carriers: fromCarriers.carriersReducer,
  taxes: fromTaxes.TaxesReducer,
  favorites: fromFavorites.favoriteListsReducer,
  orderlists: fromOrderlists.orderlistsReducer,
  warehouses: fromWarehouses.warehousesReducer,
  categories: fromCategories.categoriesReducer,
  valueSet: fromValueSet.valueSetReducer,
  valueSetItem: fromValueSetItem.valueSetItemReducer,
  surcharges: fromSurcharges.surchargesReducer,
  paymethods: fromPayMethods.payMethodsReducer,
  orderStatus: fromOrderStatus.orderStatusReducer,
  orderStatusSet: fromOrderStatusSet.orderStatusSetReducer,
  backofficeUsers: fromBackofficeUsers.backofficeUsersReducer,
  tableColumns: fromTableColumns.tableColumnsReducer,
  attributes: fromAttributes.attributeDescriptionsReducer,
  priceSheet: fromPriceSheet.priceSheetReducer,
  customLanguages: fromCustomLanguages.customLanguageReducer,
};

export function resetAppStateOnLogout(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return (state, action) => {
    if (action.type === AuthActions.LOGOUT || action.type === AuthActions.CLEAR_STATE) {
      let newState: Partial<AppState> = {};
      // Iterate over each slice and set it to its initialState
      Object.keys(state).forEach((slice) => {
        newState = reducer(state[slice], action);
      });
      return newState as AppState;
    }
    return reducer(state, action);
  };
}
