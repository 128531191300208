import { gql } from 'apollo-angular';
import { globals } from 'src/app/shared/globals';

export const MediaFragments = {
  imageSmall: gql`
    fragment imageSmall on ProductMedia {
      images(search: { sort: ASC, offset: 1 }) {
        items {
          alt(language: "${globals.appLanguage}") {
            value
          }
          imageVariants(
            input: {
              transformations: {
                name: "small"
                transformation: {
                  width: 50
                  height: 50
                  fit: BOUNDS
                  bgColor: "transparent"
                  canvas: { width: 50, height: 50 }
                }
              }
            }
          ) {
            url
          }
        }
      }
    }
  `,

  imageLarge: gql`
    fragment imageLarge on ProductMedia {
      images(search: { sort: ASC, offset: 1 }) {
        items {
          alt(language:"${globals.appLanguage}") {
            value
          }
          imageVariants(
            input: {
              transformations: {
                name: "large"
                transformation: {
                  width: 240
                  height: 240
                  fit: BOUNDS
                  bgColor: "transparent"
                  canvas: { width: 240, height: 240 }
                }
              }
            }
          ) {
            url
          }
        }
      }
    }
  `,

  imageMediumXLarge: gql`
    fragment imageMediumXLarge on ProductMedia {
      images(search: { sort: ASC }) {
        items {
          alt(language: "${globals.appLanguage}") {
            value
          }
          imageVariants(
            input: {
              transformations: [
                {
                  name: "medium"
                  transformation: {
                    width: 100
                    height: 100
                    fit: BOUNDS
                    bgColor: "transparent"
                    canvas: { width: 100, height: 100 }
                  }
                }
                {
                  name: "xLarge"
                  transformation: {
                    width: 410
                    height: 410
                    fit: BOUNDS
                    bgColor: "transparent"
                    canvas: { width: 410, height: 410 }
                  }
                }
              ]
            }
          ) {
            url
          }
        }
      }
    }
  `,

  imageVariants: gql`
    fragment imageVariants on MediaImage {
      imageVariants(
        input: {
          transformations: [
            {
              name: "small"
              transformation: {
                width: 120
                height: 120
                fit: BOUNDS
                bgColor: "transparent"
                canvas: { width: 120, height: 120 }
              }
            }
            {
              name: "medium"
              transformation: {
                width: 200
                height: 200
                fit: BOUNDS
                bgColor: "transparent"
                canvas: { width: 200, height: 200 }
              }
            }
          ]
        }
      ) {
        name
        url
      }
    }
  `,

  mediaDataImg: gql`
    fragment mediaDataImg on MediaImage {
      alt {
        language
        value
      }
      description {
        language
        value
      }
      tags {
        language
        values
      }
      type
      createdAt
      lastModifiedAt
      priority
    }
  `,

  mediaDataVideo: gql`
    fragment mediaDataVideo on MediaVideo {
      alt {
        language
        value
      }
      description {
        language
        value
      }
      tags {
        language
        values
      }
      type
      createdAt
      lastModifiedAt
      priority
    }
  `,

  mediaDataDocument: gql`
    fragment mediaDataDocument on MediaDocument {
      alt {
        language
        value
      }
      description {
        language
        value
      }
      tags {
        language
        values
      }
      type
      createdAt
      lastModifiedAt
      priority
    }
  `,

  videoData: gql`
    fragment videoData on MediaVideo {
        alt (language: "${globals.appLanguage}"){
            value
        }
        description (language: "${globals.appLanguage}"){
            value
        }
        tags (language: "${globals.appLanguage}"){
            values
        }
        type
        createdAt
        lastModifiedAt
        priority
        videos {
            uri
            mimeType
        }
  }
`,

  documentData: gql`
    fragment documentData on MediaDocument {
        alt (language: "${globals.appLanguage}"){
            value
         }
         description (language: "${globals.appLanguage}"){
             value
          }
         tags (language: "${globals.appLanguage}"){
             values
         }
         type
         createdAt
         lastModifiedAt
         priority
         documents {
             originalUrl
             mimeType
         }
  }
`,
};
