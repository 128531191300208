// @angular
import { OverlayContainer } from '@angular/cdk/overlay';
import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  Type,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { QueryRef } from 'apollo-angular';

// @rxjs
import { BehaviorSubject, EMPTY, Observable, Subject, Subscription, iif } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

// store
import { Store } from '@ngrx/store';
import * as AuthActions from './auth/store/auth.actions';
import { Tenant } from './auth/store/auth.reducer';
import * as fromApp from './store/app.reducer';

// services
import { TaxesService } from './pricing/taxes/taxes.service';
import { SidenavService } from './side-nav/sidenav.service';

// shared
import { TaxesQueryResponce } from './shared/api/types/types';
import { globals } from './shared/globals';

import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { CompaniesComponent } from './crm/users/tabs/companies/companies.component';
import { UsergroupsComponent } from './crm/users/usergroups/usergroups.component';
import { UsersService } from './crm/users/users.service';
import { ProductsService } from './pim/products/products.service';
import {
  CreateCompanyInput,
  CreateContactInput,
  CustomerInput,
  RoleAccess,
  Taxcode,
  ValuesetItem,
} from './shared/api/types/GraphQL';
import { BusinessRulesLocation, RoutePaths, UserTypes, UserTypesPlural } from './shared/api/types/enums';
import { GqlErrorService } from './shared/services/gql-error.service';
import { AuthService } from './auth/auth.service';
import { RoleNames } from './shared/api/types/enums';
import { ValueSetService } from './admin/value-set/value-set.service';
import * as CustomLanguagesActions from './store/custom-languages-store/custom-languages.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  title: string = 'the-amp-app-v1';

  selectedTab: string;
  sidenavOpen: boolean = false;
  sidenavOpenMode: string = 'over';
  showSidebar: boolean = false;
  showSidenav: boolean = true;
  showUserPopup: boolean = false;

  boAccess: boolean = environment.boAccess || false;
  spMainIconNavItems: { value: string; icon: string; role?: RoleNames | RoleNames[] }[] = globals.spNavItems;
  boMainIconNavItems: { value: string; icon: string; role?: RoleNames | RoleNames[] }[] = globals.boNavItems;

  error: Error;

  routePaths = RoutePaths;

  systemLanguages: ValuesetItem[];

  @ViewChild('sidenav') sidenav: MatSidenav;

  toggleControl = new UntypedFormControl(false);
  @HostBinding('class') className = '';

  tenants: Observable<Tenant[]>;
  activeTenant: Observable<Tenant>;
  activeLanguage: Observable<string>;
  tenantsOpenedSub: Subscription;
  languagesOpenedSub: Subscription;
  tenantsOpened = new BehaviorSubject<boolean>(false);
  languagesOpened = new BehaviorSubject<boolean>(false);
  @ViewChild('tenantsPopup') tenantsPopup: ElementRef;
  @ViewChild('languagesPopup') languagesPopup: ElementRef;
  @ViewChild('tenantsButton') tenantsButton: ElementRef;
  @ViewChild('languagesButton') languagesButton: ElementRef;
  @ViewChild('userPopup') userPopup: ElementRef;

  @ViewChild('floatingActions') floatingActions: ElementRef;
  @ViewChild('floatingActionsButton') floatingActionsButton: ElementRef;
  floatingActionsOpened = new BehaviorSubject<boolean>(false);
  floatingActionsOpenedSub: Subscription;
  openedTenantsListener: () => void;
  openedLanguagesListener: () => void;
  openedActionsListener: () => void;

  taxesQuery: QueryRef<TaxesQueryResponce>;
  taxesQuerySub: Subscription;

  timeoutId;
  timeoutTime: number = 1800000;

  isLoading: boolean = false;
  ruleType: BusinessRulesLocation = BusinessRulesLocation.incentives;
  private destroySubject: Subject<void> = new Subject();

  quickMenuActions = [
    { id: 'newProduct', title: 'New product', access: [RoleNames.backoffice, RoleNames.product] },
    { id: 'newCategory', title: 'New category', access: [RoleNames.backoffice, RoleNames.product] },
    { id: 'newOrderDropshipment', title: 'New order', access: RoleNames.order },
    { id: 'newOrderQuotation', title: 'New quote', access: [RoleNames.sales, RoleNames.order] },
    {
      id: 'newCompany',
      title: 'New company',
      selectedAction: UserTypesPlural.companies,
      access: [RoleNames.backoffice, RoleNames.user],
    },
    {
      id: 'newContact',
      title: 'New contact',
      selectedAction: UserTypesPlural.contacts,
      access: [RoleNames.backoffice, RoleNames.user],
    },
    {
      id: 'newCustomer',
      title: 'New customer',
      selectedAction: UserTypesPlural.customers,
      access: [RoleNames.backoffice, RoleNames.user],
    },
    { id: 'newRule', title: 'New incentive', access: [RoleNames.backoffice, RoleNames.pricing] },
    { id: 'newBackofficeUser', title: 'New backoffice user', access: [RoleNames.backoffice, RoleNames.role] },
  ];

  hasBackofficeRole: boolean = false;
  hasSalesRole: boolean = false;

  constructor(
    private sidenavService: SidenavService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private overlay: OverlayContainer,
    private renderer: Renderer2,
    private store: Store<fromApp.AppState>,
    private taxesService: TaxesService,
    private errorService: GqlErrorService,
    public authService: AuthService,
    public productsService: ProductsService,
    private usersService: UsersService,
    private dialog: MatDialog,
    private valuesetService: ValueSetService,
  ) {}

  ngOnInit(): void {
    this.store
      .select((state) => state.auth.roles)
      .pipe(takeUntil(this.destroySubject))
      .subscribe((roles) => {
        const salesRole = roles.find((role) => role.name === RoleNames.sales);
        if (salesRole && salesRole.access !== RoleAccess.None) this.hasSalesRole = true;
        else this.hasSalesRole = false;

        const backofficeRole = roles.find((role) => role.name === RoleNames.backoffice);
        if (backofficeRole && backofficeRole.access !== RoleAccess.None) this.hasBackofficeRole = true;
        else this.hasBackofficeRole = false;
      });

    this.tenants = this.store.select((store) => store.auth.tenants);
    this.activeTenant = this.store.select((store) => store.auth.activeTenant);
    this.activeLanguage = this.store.select((store) => store.auth.appLanguage);

    this.store
      .select((state) => state.auth.appLanguage)
      .pipe(takeUntil(this.destroySubject))
      .subscribe((language) => {
        globals.appLanguage = language;
      });

    this.toggleControl.valueChanges.subscribe((darkMode) => {
      const darkClassName = 'theme-alternate';
      const lightClassName = 'theme-default';
      this.className = darkMode ? darkClassName : lightClassName;
      if (darkMode) {
        this.overlay.getContainerElement().classList.add(darkClassName);
        this.overlay.getContainerElement().classList.remove(lightClassName);
      } else {
        this.overlay.getContainerElement().classList.add(lightClassName);
        this.overlay.getContainerElement().classList.remove(darkClassName);
      }
    });

    this.sidenavService.currentTab.subscribe((tabName) => {
      this.selectedTab = tabName;
      if (this.selectedTab === 'Home') {
        this.showSidenav = false;
      } else {
        this.showSidenav = true;
      }
    });

    this.router.events
      .pipe(
        tap((e) => {
          if (e instanceof NavigationEnd) {
            this.showSidebar =
              e.url.includes('sign-in') ||
              e.url.includes('orders/') ||
              e.url.includes('quotes/') ||
              e.url.includes('requests/');
          }
        }),
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;

          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data['title']) {
              return child.snapshot.data['title'];
            } else {
              return null;
            }
          }
          return null;
        }),
      )
      .subscribe((title: any) => {
        this.titleService.setTitle(title);
      });

    this.tenantsOpenedSub = this.tenantsOpened.subscribe((open) => {
      if (open) {
        this.openedTenantsListener = this.renderer.listen('window', 'click', (e: Event) => {
          if (
            !this.tenantsPopup.nativeElement.contains(e.target) &&
            !this.tenantsButton.nativeElement.contains(e.target)
          ) {
            this.tenantsOpened.next(false);
          }
        });
      } else if (this.openedTenantsListener) {
        this.openedTenantsListener();
      }
    });

    this.languagesOpenedSub = this.languagesOpened.subscribe((open) => {
      if (open) {
        this.openedLanguagesListener = this.renderer.listen('window', 'click', (e: Event) => {
          if (
            !this.languagesPopup.nativeElement.contains(e.target) &&
            !this.languagesButton.nativeElement.contains(e.target)
          ) {
            this.languagesOpened.next(false);
          }
        });
      } else if (this.openedLanguagesListener) {
        this.openedLanguagesListener();
      }
    });

    this.floatingActionsOpenedSub = this.floatingActionsOpened.subscribe((open) => {
      if (open) {
        this.openedActionsListener = this.renderer.listen('window', 'click', (e: Event) => {
          if (
            !this.floatingActions.nativeElement.contains(e.target) &&
            !this.floatingActionsButton.nativeElement.contains(e.target)
          ) {
            this.floatingActionsOpened.next(false);
          }
        });
      } else if (this.openedActionsListener) {
        this.openedActionsListener();
      }
    });

    // query taxes and store in local storage for app use
    if (!localStorage['taxes'] || !localStorage['taxes'].lenght) {
      this.taxesQuery = this.taxesService.getTaxes({
        input: { zone: globals.appTaxZone, shopId: globals.shopId },
      });

      this.taxesQuerySub = this.taxesQuery.valueChanges
        .pipe(
          take(1),
          map((data) => data.data.taxes.items),
        )
        .subscribe({
          next: (taxes) => {
            localStorage.setItem('taxes', JSON.stringify(taxes));
          },
          error: (error) => this.errorService.getGqlError(error),
        });
    }

    this.checkUserActivity();
    window.addEventListener('storage', this.syncActivity.bind(this));
    
    this.valuesetService
      .getValuesetByName({ names: [environment.valuesetByName.appLanguages] })
      .valueChanges.pipe(
        takeUntil(this.destroySubject),
        map((data) => data.data.valuesets.items[0]),
        filter((valueset) => valueset !== null && valueset !== undefined),
        switchMap((valueset) => {
          return this.valuesetService.getValueSetItemList({ valuesetIds: [valueset.id] }).valueChanges;
        }),
        map((data) => data.data.valuesetItems.items),
      )
      .subscribe({
        next: (valuesets) => {
          this.systemLanguages = valuesets;
        },
      });

    this.store.dispatch(
      new CustomLanguagesActions.LoadCustomLanguages({ name: environment.valuesetByName.dataLanguages }),
    );
  }

  checkTimeOut() {
    this.timeoutId = setTimeout(() => {
      const lastActivityTime = parseInt(localStorage.getItem('lastActivityTime') || '0', 10);
      const currentTime = Date.now();

      // Check if the timeout period has passed since the last activity
      if (currentTime - lastActivityTime >= this.timeoutTime) {
        this.store.dispatch(new AuthActions.LogoutStart());
        this.closeSidenav();
      } else {
        // Reset the timeout if activity was detected in another tab
        clearTimeout(this.timeoutId);
        this.checkTimeOut();
      }
    }, this.timeoutTime);
  }

  syncActivity(event: StorageEvent) {
    if (event.key === 'lastActivityTime') {
      clearTimeout(this.timeoutId);
      this.checkTimeOut();
    }
  }

  @HostListener('window:keydown')
  @HostListener('window:mousedown')
  checkUserActivity() {
    if (!localStorage['persistentLogin']) {
      clearTimeout(this.timeoutId);
      this.checkTimeOut();

      localStorage.setItem('lastActivityTime', Date.now().toString());
    }
  }

  switchTenant(tenant: Tenant) {
    this.store.dispatch(new AuthActions.SetActiveTenant(tenant));
    window.location.href = window.location.pathname;
    this.tenantsOpened.next(false);
  }

  switchLanguage(language: ValuesetItem) {
    this.store.dispatch(new AuthActions.SetAppLanguage(language.value));
    window.location.href = window.location.pathname;

    this.languagesOpened.next(false);
  }

  toggleSidnavFixed() {
    if (!this.showSidenav) return;
    this.sidenavOpen = !this.sidenavOpen;
    this.sidenavOpen ? this.openSidenav() : this.closeSidenav();
  }

  private openSidenav() {
    if (!this.showSidenav) return;
    this.sidenav.open();
    this.sidenavOpenMode = 'push';
  }

  private closeSidenav() {
    if (!this.showSidenav) return;
    this.sidenav.close();
    this.sidenavOpenMode = 'over';
  }

  onChangeTab(tabName: string) {
    this.sidenavService.changeTab(tabName);
    localStorage.setItem('selectedTab', tabName);

    if (tabName === 'Home') {
      this.router.navigate(['home']);
      if (this.showSidenav) this.sidenav.close();
      this.showSidenav = false;
    } else {
      this.showSidenav = true;
      setTimeout(() => {
        this.sidenav.open();
      });
    }
  }

  toggleTenantsPopup() {
    this.tenantsOpened.next(!this.tenantsOpened.getValue());
  }

  toggleLanguagesPopup() {
    this.languagesOpened.next(!this.languagesOpened.getValue());
  }

  toggleFloatingActionsPopup() {
    this.floatingActionsOpened.next(!this.floatingActionsOpened.getValue());
  }

  onNewOrder(orderType: string) {
    let url: string;
    if (orderType === 'dropshipment') {
      url = this.router.serializeUrl(this.router.createUrlTree(['sp/orders/new']));
    } else if (orderType === 'quotation') {
      url = this.router.serializeUrl(this.router.createUrlTree(['sp/quotes/new']));
    }
    window.open(url, '_blank');
  }

  onNewProduct() {
    this.productsService
      .productCreate({
        language: globals.appLanguage,
        names: [{ language: globals.appLanguage, value: 'New product' }],
        orderableFrom: null,
        orderableTo: null,
      })
      .pipe(
        map((data) => data.data.productCreate),
        takeUntil(this.destroySubject),
      )
      .subscribe({
        next: (product) => {
          this.openPdp(product);
        },
        error: (error) => this.errorService.getGqlError(error),
      });
  }

  openPdp(product) {
    if (product.names[0]) {
      let productName = product.names[0].value.replaceAll(/ |\/|\ - |\./g, '-').replace(/[(),]/g, '');
      this.router.navigate(['pim/products-and-clusters/product/' + product.productId + '/' + productName]);
    } else {
      this.router.navigate(['pim/products-and-clusters/product/' + product.productId]);
    }
  }

  private createEntity(actionId: string, id: number): void {
    const clickedAction = this.quickMenuActions.find((action) => action.selectedAction === actionId);

    if (!clickedAction) return;

    let newEntity: CreateCompanyInput | CreateContactInput | CustomerInput;
    this.isLoading = true;

    newEntity = {
      name: clickedAction.selectedAction === UserTypesPlural.companies ? clickedAction.title : undefined,
      firstName: clickedAction.selectedAction !== UserTypesPlural.companies ? clickedAction.title : undefined,
      lastName: clickedAction.selectedAction !== UserTypesPlural.companies ? '' : undefined,
      parentId: id,
    };

    const userType =
      clickedAction.selectedAction === UserTypesPlural.companies
        ? UserTypes.company
        : clickedAction.selectedAction.slice(0, -1);
    this.usersService
      .createUser(clickedAction.selectedAction, newEntity)
      .pipe(
        map((data) => {
          return data.data[`${userType}Create`];
        }),
      )
      .subscribe({
        next: (user) => {
          const userId = user[`${userType}Id`];
          this.router.navigateByUrl(`${RoutePaths.UserManagementUsersDetails}/${userType}/${userId}`);
          this.isLoading = false;
        },
        error: (error) => {
          this.errorService.getGqlError(error);
          this.isLoading = false;
        },
      });
  }

  openDialog(component: Type<UsergroupsComponent | CompaniesComponent>, userTypePlural: string) {
    const dialogConfig: any = this.dialog.open(component, {
      width: '75vw',
      height: '75vh',
      maxWidth: '90vw',
      maxHeight: '90vh',
      panelClass: 'dialog',
      data: { requestedFromDialog: true },
    });

    dialogConfig.componentInstance.dialogRef = dialogConfig;

    dialogConfig.afterClosed().subscribe((result: any) => {
      if (result !== undefined) {
        this.createEntity(userTypePlural, result.companyId);
      }
    });
  }

  handleQuickMenuAction(action: string) {
    const quickMenuActionMap = new Map<string, () => void>([
      ['newProduct', () => this.onNewProduct()],
      ['newCategory', () => this.router.navigateByUrl(RoutePaths.PimCategories)],
      ['newOrderDropshipment', () => this.onNewOrder('dropshipment')],
      ['newOrderQuotation', () => this.onNewOrder('quotation')],
      ['newCompany', () => this.openDialog(UsergroupsComponent, UserTypesPlural.companies)],
      ['newContact', () => this.openDialog(CompaniesComponent, UserTypesPlural.contacts)],
      ['newCustomer', () => this.openDialog(UsergroupsComponent, UserTypesPlural.customers)],
      ['newRule', () => this.router.navigate(['rule-builder', this.ruleType, 'new'])],
      ['newBackofficeUser', () => this.router.navigate([`${RoutePaths.AdminBackofficeUsers}/new`])],
    ]);

    const actionFunction = quickMenuActionMap.get(action);
    if (actionFunction) {
      actionFunction();
    }
  }

  toggleUserPopup(event: Event) {
    this.showUserPopup = !this.showUserPopup;
    this.tenantsOpened.next(false);
    event.stopPropagation();
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    if (!this.userPopup?.nativeElement.contains(event.target)) {
      this.showUserPopup = false;
    }
  }

  onLogout() {
    this.showUserPopup = false;
    this.store.dispatch(new AuthActions.LogoutStart());
    this.closeSidenav();
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.tenantsOpenedSub.unsubscribe();
    this.floatingActionsOpenedSub.unsubscribe();

    window.removeEventListener('storage', this.syncActivity.bind(this));
  }
}
