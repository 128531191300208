import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'itemDescription',
})
export class ItemDescriptionPipe implements PipeTransform {
  transform(item: any, appLanguage: string): string {
    if (item && item.attributeDescription && item.attributeDescription.descriptions) {
      const description = item.attributeDescription.descriptions.find((item) => item.language === appLanguage);
      return description ? description.value : item.attributeDescription.name;
    }
    return item.attributeDescription?.name;
  }
}
