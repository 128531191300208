import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { debounceTime, map, Subject, Subscription, switchMap, take, takeUntil } from 'rxjs';
import { ValueSetService } from 'src/app/admin/value-set/value-set.service';
import { GqlErrorService } from '../../services/gql-error.service';
import { QueryRef } from 'apollo-angular';
import { MatSelect } from '@angular/material/select';
import { globals } from '../../globals';

@Component({
  selector: 'app-dropdown-with-search-input',
  templateUrl: './dropdown-with-search-input.component.html',
  styleUrls: ['./dropdown-with-search-input.component.scss'],
})
export class DropdownWithSearchInputComponent implements OnInit, OnDestroy {
  @ViewChild('dropdownSelect') dropdownSelect: MatSelect;
  @Input() selectedData: string = '';
  @Input() headerName: string;
  @Input() dropdownPlaceholder: string;
  @Input() isFilter?: boolean = false;
  @Input() isViewer: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() searchPlaceholder: string = 'Search';
  @Output() languageChange = new EventEmitter<string>();
  customData: any[];
  valuesetId: number;
  @Input() valueSetName: string;

  valuesetsQuery: QueryRef<any>;
  valuesetsQuerySub: Subscription;
  loadingMoreOptions: boolean = false;

  private itemsFound: number;
  private itemsPerPage: number = globals.globalOffset;
  private itemsOffsetIncrement: number = 5;

  private searchSubject = new Subject<string>();
  private destroySubject: Subject<void> = new Subject<void>();

  constructor(
    private valuesetService: ValueSetService,
    private errorService: GqlErrorService,
  ) {}

  ngOnInit(): void {
    this.searchSubject.pipe(debounceTime(500), takeUntil(this.destroySubject)).subscribe((searchTerm) => {
      this.fetchLanguages(searchTerm);
    });
  }

  triggerDropdown(): void {
    if (!this.isDisabled) {
      this.dropdownSelect.open();
    }
  }

  onDropdownChange(event): void {
    if (event) this.fetchValuesetItems();
  }

  loadMore(event): void {
    const offsetHeight = event.target.offsetHeight;
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;

    if (offsetHeight + scrollTop >= scrollHeight && this.itemsFound > this.itemsPerPage) {
      this.loadingMoreOptions = true;
      this.itemsPerPage += this.itemsOffsetIncrement;
      this.fetchValuesetItems();
    }
  }

  private fetchValuesetItems(): void {
    this.valuesetsQuery = this.valuesetService.getValuesetByName({ names: [this.valueSetName] });
    this.valuesetsQuerySub = this.valuesetsQuery.valueChanges
      .pipe(
        take(1),
        map((data) => data.data.valuesets.items[0]),
        switchMap((valueset) => {
          this.valuesetId = valueset.id;

          return this.valuesetService.getValueSetItemList({
            valuesetIds: [this.valuesetId],
            offset: this.itemsPerPage,
          }).valueChanges;
        }),
        map((data) => {
          this.itemsFound = data.data.valuesetItems.itemsFound;
          return data.data.valuesetItems.items;
        }),
      )
      .subscribe({
        next: (items) => {
          this.loadingMoreOptions = false;
          this.customData = this.itemsPerPage > this.itemsOffsetIncrement ? [...this.customData, ...items] : items;
        },
        error: (error) => {
          this.loadingMoreOptions = false;
          this.errorService.getGqlError(error);
        },
      });
  }

  onDataChange(event): void {
    this.languageChange.emit(event.value);
  }

  onDataSearch(event) {
    const searchTerm = event.target.value;
    this.searchSubject.next(searchTerm);
  }

  private fetchLanguages(searchTerm?: string) {
    const query = { valuesetIds: [this.valuesetId], offset: this.itemsPerPage };
    if (searchTerm) query['values'] = [searchTerm];
    this.valuesetService
      .getValueSetItemList(query)
      .valueChanges.pipe(
        takeUntil(this.destroySubject),
        map((data) => data.data.valuesetItems.items),
      )
      .subscribe({
        next: (data) => {
          this.customData = data.length ? data : [{ key: 'noResults', value: 'No results found' }];
        },
        error: (error) => this.errorService.getGqlError(error),
      });
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
    if (this.valuesetsQuerySub) this.valuesetsQuerySub.unsubscribe();
  }
}
