import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as CustomLanguagesActions from './custom-languages.actions';
import { ValueSetService } from 'src/app/admin/value-set/value-set.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class CustomLanguagesEffects {
  constructor(
    private actions$: Actions,
    private valuesetService: ValueSetService,
  ) {}

  public readonly loadCustomLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomLanguagesActions.LOAD_CUSTOM_LANGUAGES),
      mergeMap((action: CustomLanguagesActions.LoadCustomLanguages) =>
        this.valuesetService.getValuesetByName({ names: [environment.valuesetByName.dataLanguages] }).valueChanges.pipe(
          map((data) => data.data.valuesets.items[0]),
          switchMap((valueset) => {
            const valuesetId = valueset?.id;
            return this.valuesetService.getValueSetItemList({ valuesetIds: [valuesetId] }).valueChanges.pipe(
              map((data) => data.data.valuesetItems.items),
              map((valuesetItems) => new CustomLanguagesActions.CustomLanguagesSuccess(valuesetItems)),
              catchError((error) => of(new CustomLanguagesActions.CustomLanguagesFailure(error))),
            );
          }),
        ),
      ),
    ),
  );
}
