<div mat-dialog-content>
  <div class="list-container">
    <h2>Available columns</h2>

    <div
      cdkDropList
      #availableList="cdkDropList"
      [cdkDropListData]="availableColumns"
      [cdkDropListConnectedTo]="[displayedList]"
      class="draggable-list"
      (cdkDropListDropped)="drop($event)"
    >
      <div class="draggable-box" *ngFor="let item of availableColumns" [class.hidden]="item.hidden">
        <button
          mat-button
          class="icon-btn handle"
          cdkDrag
          cdkDragHandle
          [cdkDragDisabled]="item.disabled"
          cdkDragRootElement=".draggable-box"
          cdkDragPreviewContainer="parent"
        >
          <mat-icon>drag_indicator</mat-icon>
        </button>
        {{ item.value }}
      </div>
    </div>
  </div>
  <div class="list-container-separator">
    <mat-icon class="icon">compare_arrows</mat-icon>
  </div>
  <div class="list-container">
    <h2>Columns in view</h2>

    <div
      cdkDropList
      #displayedList="cdkDropList"
      [cdkDropListData]="columnsInView"
      [cdkDropListConnectedTo]="[availableList]"
      class="draggable-list"
      (cdkDropListDropped)="drop($event)"
    >
      <!-- <div class="draggable-box cdk-drag-disabled"  
                    *ngFor="let item of data.disabledColumns">
                    <button mat-button 
                            class='icon-btn handle'>
                        <mat-icon>drag_indicator</mat-icon>
                    </button>   
                    {{item}}
                    <button mat-button 
                            class='icon-btn remove object-ml-auto'>
                        <mat-icon>close</mat-icon>
                    </button>   
                </div>          -->
      <div
        class="draggable-box"
        *ngFor="let item of columnsInView"
        [class.hidden]="item.hidden"
        [class.disabled]="item.disabled"
      >
        <button
          mat-button
          class="icon-btn handle"
          cdkDrag
          cdkDragHandle
          [cdkDragDisabled]="item.disabled"
          cdkDragRootElement=".draggable-box"
          cdkDragPreviewContainer="parent"
        >
          <mat-icon>drag_indicator</mat-icon>
        </button>
        {{ item.value }}
        <button
          mat-button
          class="icon-btn remove object-ml-auto"
          (click)="removeFromDisplayed(columnsInView, availableColumns, item)"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-flat-button color="primary" (click)="confirm()">Save</button>
  <button mat-button (click)="cancel()" class="primary-txt">Cancel</button>
</div>
