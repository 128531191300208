import { CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { TENANT_ID } from '@angular/fire/compat/auth';
import { Title } from '@angular/platform-browser';
import { CanDeactivateGuard } from './shared/services/can-deactivate-guard.service';
import { environment } from 'src/environments/environment';
import { MatDialogRef } from '@angular/material/dialog';

//import { HTTP_INTERCEPTORS } from '@angular/common/http';
//import { ApiInterceptorService } from './services/api-interceptor.service';

@NgModule({
  providers: [
    CurrencyPipe,
    Title,
    {
      provide: TENANT_ID,
      useValue: environment.firebase.tenantId,
    },
    {
      provide: MatDialogRef,
      useValue: {},
    },
    CanDeactivateGuard,
  ],
})

//{
//   provide: HTTP_INTERCEPTORS,
//   useClass: ApiInterceptorService,
//   multi: true, // Add this line when using multiple interceptors.
// },
export class CoreModule {}
