<div class="amp-app main-wrapper">
  <div class="side-bar" *ngIf="!showSidebar">
    <div
      class="logo"
      (click)="toggleSidnavFixed()"
      matTooltip="toggle menu"
      matTooltipPosition="right"
      matTooltipClass="navigation-tooltip"
    >
      <!-- <mat-icon class="expand-icon" [ngClass]="{ rotated: sidenavOpen }">keyboard_arrow_right</mat-icon> -->
      <img src="/assets/images/logo_BLUE.svg" alt="propeller-logo" />
    </div>
    <ul class="icon-nav" (mouseover)="showSidenav && !sidenavOpen ? sidenav.open() : ''">
      <ng-container *ngFor="let item of spMainIconNavItems; let i = index">
        <li
          *ngIf="(item.role && authService.checkAnyUserRole(item.role)) || !item.role"
          (click)="onChangeTab(item.value)"
          [class.active]="item.value === selectedTab"
          [matTooltip]="item.value"
          matTooltipPosition="above"
          matTooltipClass="navigation-tooltip"
        >
          <mat-icon>{{ item.icon }}</mat-icon>
        </li>
      </ng-container>
      <ng-container *ngFor="let item of boMainIconNavItems; let i = index">
        <li
          *ngIf="((item.role && authService.checkAnyUserRole(item.role)) || !item.role) && hasBackofficeRole"
          (click)="onChangeTab(item.value)"
          [class.active]="item.value === selectedTab"
          [matTooltip]="item.value"
          matTooltipPosition="above"
          matTooltipClass="navigation-tooltip"
        >
          <mat-icon>{{ item.icon }}</mat-icon>
        </li>
      </ng-container>
    </ul>
    <span class="sidebar-spacer" (mouseover)="showSidenav && !sidenavOpen ? sidenav.open() : ''"></span>
    <ul class="floating-actions" *ngIf="hasSalesRole || hasBackofficeRole">
      <li class="floating-actions-icon" (click)="toggleFloatingActionsPopup()" #floatingActionsButton>
        <mat-icon>add_circle_outline</mat-icon>
      </li>
    </ul>
    <span class="sidebar-spacer" (mouseover)="showSidenav && !sidenavOpen ? sidenav.open() : ''"></span>
    <ul class="settings">
      <li class="user-settings" (click)="toggleUserPopup($event)">
        <mat-icon>person</mat-icon>
      </li>
    </ul>
    <div class="user-popup" [style.display]="showUserPopup ? 'block' : 'none'" #userPopup>
      <div
        class="mat-caption"
        *ngIf="(tenants | async).length > 0"
        (click)="toggleTenantsPopup()"
        #tenantsButton
        [ngClass]="{ activeTenant: tenantsOpened | async }"
      >
        <span>Environments<mat-icon style="height: 16px; margin-left: 60px">chevron_right</mat-icon> </span>
      </div>
      <div
        class="mat-caption"
        *ngIf="systemLanguages && systemLanguages.length"
        (click)="toggleLanguagesPopup()"
        #languagesButton
      >
        <span>Languages<mat-icon style="height: 16px; margin-left: 60px">chevron_right</mat-icon> </span>
      </div>
      <div
        class="mat-caption"
        [routerLink]="routePaths.Settings"
        routerLinkActive="activeTenant"
        (click)="closePopup()"
      >
        <span>Settings</span>
      </div>
      <div class="mat-caption">
        <span (click)="onLogout()">Sign out</span>
      </div>
    </div>
    <div class="tenants-popup" *ngIf="tenantsOpened | async" #tenantsPopup>
      <div
        *ngFor="let tenant of tenants | async"
        (click)="switchTenant(tenant)"
        class="mat-caption"
        [ngClass]="{ activeTenant: tenant.tenant === (activeTenant | async).tenant }"
      >
        <span *ngIf="tenant.tenant === (activeTenant | async).tenant">Current view: </span>
        {{ tenant.name }}
      </div>
    </div>
    <div class="tenants-popup" *ngIf="languagesOpened | async" #languagesPopup>
      <div
        *ngFor="let language of systemLanguages"
        (click)="switchLanguage(language)"
        class="mat-caption"
        [ngClass]="{ activeTenant: language.value === (activeLanguage | async) }"
      >
        {{ language.descriptions | localizedString }}
      </div>
    </div>
    <div class="floating-actions-popup" *ngIf="floatingActionsOpened | async" #floatingActions>
      <ng-container *ngFor="let action of quickMenuActions">
        <div
          class="mat-caption"
          (click)="handleQuickMenuAction(action.id)"
          *ngIf="(action.access && authService.checkUserAccess(action.access)) || !action.access"
        >
          {{ action.title }}
        </div>
      </ng-container>
    </div>
  </div>
  <mat-sidenav-container class="main-container" hasBackdrop="false" [ngClass]="{ 'no-sidebar': showSidebar }">
    <mat-sidenav
      #sidenav
      [mode]="sidenavOpen ? 'side' : 'over'"
      (mouseleave)="!sidenavOpen ? sidenav.close() : ''"
      *ngIf="showSidenav"
    >
      <app-side-nav></app-side-nav>
    </mat-sidenav>
    <mat-sidenav-content>
      <!-- <div class="theme-toggle">
        <mat-slide-toggle [formControl]="toggleControl" class="object-mr-1">
        </mat-slide-toggle>
      </div> -->
      <div class="content">
        <!-- <app-ng-dynamic-breadcrumb
              [bgColor]="'transparent'"
              [fontSize]="'12px'"
              [fontColor]="'var(--txt-color-secondary)'"
              [lastLinkColor]="'var(--txt-color-secondary)'"
              [symbol]="' / '"></app-ng-dynamic-breadcrumb> -->
        <router-outlet></router-outlet>
      </div>
      <div *ngIf="isLoading" class="loader">
        <mat-spinner></mat-spinner>
        <p class="text-loader">Loading...</p>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
