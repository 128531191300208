import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, filter, map, Observable, of, switchMap, tap, withLatestFrom } from 'rxjs';
import * as fromApp from 'src/app/store/app.reducer';
import * as ValueSetItemActions from './value-set-item.actions';
import { ValueSetService } from '../../value-set.service';
import { QueryRef } from 'apollo-angular';

import { valueSetListItemResponse } from 'src/app/shared/api/types/types';

@Injectable()
export class ValueSetItemEffects {
  valueSetItemQuery: QueryRef<valueSetListItemResponse>;
  valueSetItemQuerySub: Observable<Action>;

  constructor(
    private actions: Actions,
    private valueSetService: ValueSetService,
    private store: Store<fromApp.AppState>,
  ) {}

  public readonly loadValueSetItem: Observable<Action> = createEffect(() => {
    return this.actions.pipe(
      ofType(ValueSetItemActions.LOAD_VALUE_SET_ITEM),
      withLatestFrom(this.store.select((state) => state.valueSetItem.loaded)),
      filter(([action, loaded]) => !loaded),
      switchMap(([action, loaded]: [ValueSetItemActions.LoadValueSetItem, boolean]) => {
        this.valueSetItemQuery = this.valueSetService.getValueSetItemList(action.payload);
        this.valueSetItemQuerySub = this.valueSetItemQuery.valueChanges.pipe(
          map((data) => {
            this.store.dispatch(new ValueSetItemActions.GetValueSetItemCount(data.data.valuesetItems.itemsFound));
            return new ValueSetItemActions.LoadValueSetItemSuccess(data.data.valuesetItems.items);
          }),
          catchError((error) => of(new ValueSetItemActions.LoadValueSetItemFailure(error))),
        );
        return this.valueSetItemQuerySub;
      }),
    );
  });

  public readonly refetchValueSet = createEffect(
    () =>
      this.actions.pipe(
        ofType(ValueSetItemActions.REFETCH_VALUE_SET_ITEM),
        tap((action: ValueSetItemActions.RefetchValueSetItem) => {
          this.valueSetItemQuery.resetLastResults();
          this.valueSetItemQuery.refetch(action.payload);
        }),
      ),
    { dispatch: false },
  );
}
