import { gql } from 'apollo-angular';
import { globals } from 'src/app/shared/globals';
import { AttributeFragments } from '../fragments/attribute-fragments';

//Note:tag error message "Cannot query field \"tag\" on type \"Product\"."

export const ProductDetailsDescriptionQuery = gql`
query productDetailsDescription ($id: Int, $language: String, $sku: String, $productId: Int, $slug: String) {
  product(id: $id, language: $language, sku: $sku, productId: $productId, slug: $slug) {
  categoryId
    sku
    class
    eanCode
    names {
    language
      value
    }
    shortName
    id
    createdAt
    lastModifiedAt
    productId
    categoryPath {
      name (language: "${globals.appLanguage}") {
        language
        value
      }
    }
    manufacturerCode
    manufacturer
    supplier
    supplierCode
    purchaseMinimumQuantity
    purchaseUnit
    unit
    package
    packageUnit
    packageUnitQuantity
    barCode
    descriptions {
    language
      value
    }
    shortDescriptions {
    language
      value
    }
    keywords {
      language
      value
    }
    customKeywords {
      language
      value
    }
    media {
      images(search: { sort: ASC, offset: ${globals.globalOffset} }) {
        itemsFound
        page
        offset
        items {
          alt {
            value
          }
          description {
            value
          }
          imageVariants(
            input: {
              transformations: [{
                name: "xs"
                transformation: { width: 100, height: 100, fit: BOUNDS, bgColor: "transparent", canvas: {width: 100, height: 100} }
              }]
            }
          ) {
            name
            url
          }
        }
      }
    }
  }
}
`;

// Note :
// Cannot query field "storePrice" on type "Product". Did you mean "price"?
// Cannot query field "suggestedPrice" on type "Product".
// Cannot query field "originalPrice" on type "Product".
// Cannot query field "taxCode" on type "Product". Did you mean "eanCode"?
// Cannot query field "costPrice" on type "Product". Did you mean "price"?
//eanCode removed

export const ProductDetailsPriceQuery = gql`
query productDetailsPrice ($id: Int, $language: String, $sku: String, $productId: Int, $slug: String) {
  product(id: $id, language: $language, sku: $sku, productId: $productId, slug: $slug) {
    names (language: "${globals.appLanguage}") {
      value
    }
    id
    economicOrderQuantity
    productId
    package
    packageUnit
    packageUnitQuantity
    packageDescriptions {
      language
      value
    }
    inventory {
      totalQuantity
    }
    manufacturerCode
    minimumQuantity
    supplierCode
    priceData {
      id
      per
      list
      cost
      suggested
      store
      defaultTaxCode
      bulkPriceDiscountType
      display
    }
    purchaseMinimumQuantity
    purchaseUnit
    unit
    package
    taxonomy
    priceGroup
    turnoverGroup
  }
}
`;

export const ProductDetailsMediaQuery = gql`
query product ($id: Int, $language: String, $sku: String, $productId: Int, $slug: String) {
  product(id: $id, language: $language, sku: $sku, productId: $productId, slug: $slug) {
    names (language: "${globals.appLanguage}") {
      value
    }
    id
    productId
    media {
      images(search: { sort: ASC, offset: ${globals.globalOffset} }) {
        itemsFound
        page
        offset
        items {
          alt {
            value
          }
          descriptions {
            value
          }
          id
          tags {
            values
          }
          type
          createdAt
          lastModifiedAt
          priority
          images {
            language
            originalUrl
            mimeType
          }
          imageVariants(
            input: {
              transformations: [{
                names: "small"
                transformation: { width: 120, height: 120, fit: BOUNDS, bgColor: "transparent", canvas: {width: 120, height: 120} }
              },
              {
                names: "medium"
                transformation: { width: 200, height: 200, fit: BOUNDS, bgColor: "transparent", canvas: {width: 200, height: 200} }
              }]
            }
          ) {
            names
            url
          }
        }
      }
    }
  }
}
`;

export const ExplainPriceQuery = gql`
  query explainPrice($input: PriceCalculateInput!) {
    priceExplain(input: $input) {
      type
      discountType
      list
      cost
      net
      gross
      discount {
        id
        value
        quantityFrom
        validFrom
        validTo
        ... on Discount {
          pricesheetId
          productId
          categoryId
          priceGroup
          pricesheet {
            names {
              language
              value
            }
          }
        }
      }
    }
  }
`;

export const productSurchargesQuery = gql`
  query productSurcharges($productId: Int!, $input: SurchargeSearchInput) {
    productSurcharges(productId: $productId, input: $input) {
      itemsFound
      page
      items {
        surchargeId
        surcharge {
          id
          name {
            language
            value
          }
          description {
            language
            value
          }
          type
          value
          taxCode
          taxZone
          enabled
        }
      }
    }
  }
`;

export const ProductDetailsLogisticsQuery = gql`
query productDetailsLogistics ($id: Int, $language: String, $sku: String, $productId: Int, $slug: String) {
  product(id: $id, language: $language, sku: $sku, productId: $productId, slug: $slug) {
    names (language: "${globals.appLanguage}") {
      value
    }
    status
    statusExtra
    returnable
    orderable
    orderableFrom
    orderableTo
    releaseDate
    physical
    inventory {
      totalQuantity
      supplierQuantity
      localQuantity
      nextDeliveryDate
      balance {
        id
        quantity
        costPrice
        supplier
        dateModified
        warehouseId
        location
        nextDeliveryDate
        notes
      }
    }
  }
}
`;

export const ProductDetailsStorefrontQuery = gql`
query productDetailsStorefront ($productId: Int, $types:[CrossupsellType!], $subTypes:[String!]) {
  product(productId: $productId) {
    crossupsellsFrom(input: {types: $types, subTypes: $subTypes}) {
      itemsFound
      items {
        id
        type
        subType
        productTo {
          names (language: "${globals.appLanguage}") {
            value
          }
          sku
          ... on Product {
            media {
              images (search: {page: 1, offset: 1}) {
                items {
                  alt (language: "${globals.appLanguage}") {
                    value
                  }
                  imageVariants(
                    input: {
                      transformations: [{
                        name: "small"
                        transformation: { width: 56, height: 56, fit: BOUNDS, bgColor: "transparent", canvas: {width: 56, height: 56} }
                      }]
                    }
                  ) {
                    name
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const CatalogCrossupsellsQuery = gql`
query CatalogCrossupsellsQuery ($productId: Int, $contactId: Int, $companyId: Int, $types:[CrossupsellType!]) {
  product(productId: $productId) {
    crossupsellsFrom(input: {types: $types}) {
      itemsFound
      items {
        id
        type
        subType
        productTo {
          names (language: "${globals.appLanguage}") {
            value
          }
          sku
          class
          ... on Product {
            cluster{
              clusterId
            }
            productId
            manufacturer
            status
            supplier
            orderable
            inventory {
              totalQuantity
            }
            categoryPath {
              categoryId
              name(language: "${globals.appLanguage}") {
                value
              }
            }
            minimumQuantity
            unit
            price(input:{contactId: $contactId, companyId: $companyId}) {
              gross
            }
            media {
              images (search: {page: 1, offset: 1}) {
                items {
                  alt (language: "${globals.appLanguage}") {
                    value
                  }
                  imageVariants(
                    input: {
                      transformations: [{
                        name: "medium"
                        transformation: { width: 200, height: 200, fit: BOUNDS, bgColor: "transparent", canvas: {width: 200, height: 200} }
                      }]
                    }
                  ) {
                    name
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const ProductDetailsAttributesQuery = gql`
  query productDetailsAttributes($productId: Int, $input: AttributeResultSearchInput) {
    product(productId: $productId) {
      attributes(input: $input) {
        items {
          attribute {
            id
            value {
              id
            }
          }
          attributeDescription {
            id
            valuesetId
            name
            descriptions {
              language
              value
            }
            type
            group
            isSearchable
            isPublic
            isSystem
            isHidden
            defaultValue {
              ...attrValue
            }
            createdAt
            lastModifiedAt
          }
          value {
            id
            ... on AttributeColorValue {
              id
              type
              colorValue
            }
            ... on AttributeDateTimeValue {
              id
              type
              dateTimeValue
            }
            ... on AttributeDecimalValue {
              id
              type
              decimalValue
            }
            ... on AttributeEnumValue {
              enumValues
            }
            ... on AttributeIntValue {
              id
              type
              intValue
            }
            ... on AttributeTextValue {
              textValues {
                language
                values
              }
            }
          }
        }
        itemsFound
        page
      }
    }
  }
  ${AttributeFragments.attributeValue}
`;
