import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, filter, map, Observable, of, switchMap, tap, withLatestFrom } from 'rxjs';
import * as AttributeDescriptionsActions from './attributes.actions';
import * as fromApp from '../../../store/app.reducer';
import { QueryRef } from 'apollo-angular';
import { AttributesService } from '../attributes.service';
import { AttributeDescriptionsResponse } from 'src/app/shared/api/types/types';
import { AttributeDescriptionClass } from 'src/app/shared/api/types/GraphQL';

@Injectable()
export class AttributeDescriptionsEffects {
  attributeDescriptionsQuery: QueryRef<AttributeDescriptionsResponse>;
  attributeDescriptionsQuerySub: Observable<Action>;

  class: AttributeDescriptionClass;
  offset: number;

  constructor(
    private actions: Actions,
    private attributesService: AttributesService,
    private store: Store<fromApp.AppState>,
  ) {
    this.store.select((state) => state.attributes.class).subscribe((attrClass) => (this.class = attrClass));
    this.store
      .select((state) => state.attributes[this.class].currentPageSize)
      .subscribe((offset) => (this.offset = offset));
  }

  public readonly loadAttributeDescriptions: Observable<Action> = createEffect(() => {
    return this.actions.pipe(
      ofType(AttributeDescriptionsActions.LOAD_ATTRIBUTE_DESCRIPTIONS),
      withLatestFrom(this.store),
      filter(
        ([action, state]: [AttributeDescriptionsActions.LoadAttributeDescriptions, any]) =>
          !state.attributes[action.payload.attrClass].loaded,
      ),
      switchMap(([action, loaded]: [AttributeDescriptionsActions.LoadAttributeDescriptions, boolean]) => {
        this.attributeDescriptionsQuery = this.attributesService.getAttributeDescriptionList(action.payload.vars);
        this.attributeDescriptionsQuerySub = this.attributeDescriptionsQuery.valueChanges.pipe(
          map((data) => {
            this.store.dispatch(
              new AttributeDescriptionsActions.GetAttributeDescriptionsCount({
                count: data.data.attributeDescriptions.itemsFound,
                attrClass: this.class,
              }),
            );
            return new AttributeDescriptionsActions.LoadAttributeDescriptionsSuccess({
              items: data.data.attributeDescriptions.items,
              attrClass: this.class,
            });
          }),
          catchError((error) =>
            of(
              new AttributeDescriptionsActions.LoadAttributeDescriptionsFailure({
                error,
                attrClass: this.class,
              }),
            ),
          ),
        );
        return this.attributeDescriptionsQuerySub;
      }),
    );
  });

  public readonly refetchAttributeDescriptions = createEffect(
    () =>
      this.actions.pipe(
        ofType(AttributeDescriptionsActions.REFETCH_ATTRIBUTE_DESCRIPTIONS),
        tap((action: AttributeDescriptionsActions.RefetchAttributeDescriptions) => {
          const variables = action.payload.vars;
          this.attributeDescriptionsQuery.resetLastResults();
          if (variables.hasOwnProperty('input')) {
            this.attributeDescriptionsQuery.refetch(action.payload.vars);
          } else if (Object.keys(variables).length === 0) {
            this.attributeDescriptionsQuery.refetch({
              input: { page: 1, offset: this.offset, classes: [this.class] },
            });
          } else {
            this.attributeDescriptionsQuery.refetch({ input: action.payload.vars });
          }
        }),
      ),
    { dispatch: false },
  );
}
