import { Action } from '@ngrx/store';
import { Orderlist, OrderlistSearchInput } from '../../../shared/api/types/GraphQL';

export const GET_CURRENT_PAGE = '[Orderlists] Get Current Page';
export const GET_CURRENT_PAGE_SIZE = '[Orderlists] Get Current Page Size';
export const GET_ORDER_LISTS_COUNT = '[Orderlists] Get Orderlists Count';
export const LOAD_ORDER_LISTS = '[Orderlists] Load Orderlists';
export const REFETCH_ORDER_LISTS = '[Orderlists] Refetch Orderlist ';
export const LOAD_ORDER_LISTS_SUCCESS = '[Orderlists] Load Orderlists Success';
export const LOAD_ORDER_LISTS_FAILURE = '[Orderlists] Load Orderlists Failure';

export const GET_GENERAL_FILTER = '[Orderlists] Get General Filter';
export const ADD_GENERAL_FILTER = '[Orderlists] Add General Filter';
export const REMOVE_GENERAL_FILTER = '[Orderlists] Remove General Filter';

export const ADD_ORDER_LIST_TO_SELECTED_LISTS = '[Orderlists] Add Orderlist To Selected List';
export const DELETE_ORDER_LIST_FROM_SELECTED_LIST = '[Orderlists] Delete Orderlist From Selected List';
export const CLEAR_SELECTED_ORDER_LISTS = '[Orderlists] Clear Selected Orderlist';

export const ORDERLISTS_PAGE_INITIALIZED = '[Orderlists] Page Initialized';
export const ORDERLISTS_PAGE_DESTROYED = '[Orderlists] Page Destroyed';

export class GetCurrentPage implements Action {
  readonly type = GET_CURRENT_PAGE;

  constructor(public payload: number) {}
}

export class GetCurrentPageSize implements Action {
  readonly type = GET_CURRENT_PAGE_SIZE;

  constructor(public payload: number) {}
}

export class GetFavoriteListsCount implements Action {
  readonly type = GET_ORDER_LISTS_COUNT;

  constructor(public payload: number) {}
}

export class LoadOrderLists implements Action {
  readonly type = LOAD_ORDER_LISTS;

  constructor(public payload: {}) {}
}

export class RefetchOrderLists implements Action {
  readonly type = REFETCH_ORDER_LISTS;
  constructor(public payload: {}) {}
}

export class LoadOrderListsSuccess implements Action {
  readonly type = LOAD_ORDER_LISTS_SUCCESS;

  constructor(
    public payload: {
      items: Orderlist[];
      itemsFound: number;
    },
  ) {}
}

export class LoadOrderListsFailure implements Action {
  readonly type = LOAD_ORDER_LISTS_FAILURE;

  constructor(public payload: Error) {}
}

export class AddOrderListToSelectedLists implements Action {
  readonly type = ADD_ORDER_LIST_TO_SELECTED_LISTS;

  constructor(public payload: Orderlist) {}
}

export class DeleteOrderListFromSelectedLists implements Action {
  readonly type = DELETE_ORDER_LIST_FROM_SELECTED_LIST;

  constructor(public payload: number) {}
}

export class ClearOrderlistsList implements Action {
  readonly type = CLEAR_SELECTED_ORDER_LISTS;
}

export class GetGeneralFilter implements Action {
  readonly type = GET_GENERAL_FILTER;

  constructor(public payload: string) {}
}

export class OrderListsPageInitialized implements Action {
  readonly type = ORDERLISTS_PAGE_INITIALIZED;
}

export class OrderListsPageDestroyed implements Action {
  readonly type = ORDERLISTS_PAGE_DESTROYED;
}

export class AddGeneralFilter implements Action {
  readonly type = ADD_GENERAL_FILTER;

  constructor(public payload: OrderlistSearchInput) {}
}
export class RemoveGeneralFilter implements Action {
  readonly type = REMOVE_GENERAL_FILTER;

  constructor(public payload: { columnName: string; index?: number }) {}
}

export type OrderListsActions =
  | GetGeneralFilter
  | GetCurrentPage
  | GetCurrentPageSize
  | GetFavoriteListsCount
  | LoadOrderLists
  | RefetchOrderLists
  | LoadOrderListsSuccess
  | LoadOrderListsFailure
  | AddOrderListToSelectedLists
  | DeleteOrderListFromSelectedLists
  | ClearOrderlistsList
  | OrderListsPageInitialized
  | OrderListsPageDestroyed
  | GetGeneralFilter
  | AddGeneralFilter
  | RemoveGeneralFilter;
