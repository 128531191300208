import { Order, OrderSearchArguments } from 'src/app/shared/api/types/GraphQL';
import { TableColumns } from 'src/app/shared/api/types/interfaces';
import * as OrdersActions from './orders.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  orders: Order[];
  currentSpPage: number;
  currentBoPage: number;
  currentSpPageSize: number;
  currentBoPageSize: number;
  ordersCount: number;
  error: Error;
  columnFilterValues: {
    name: string;
    sku: string;
  };
  multiselectFilterValues: {
    manufacturer: string[];
    supplier: string[];
  };
  numberOfFiltersSelected: number;
  generalFilterValue: string;
  selectedOrdersList: Order[];
  productTableColumns: TableColumns;
  spOrdersTableColumns: TableColumns;
  boOrdersTableColumns: TableColumns;
  orderFilters: OrderSearchArguments;
}

const initialState: State = {
  loading: false,
  loaded: false,
  orders: [],
  currentSpPage: 1,
  currentBoPage: 1,
  currentSpPageSize: 5,
  currentBoPageSize: 5,
  ordersCount: 0,
  error: undefined,
  columnFilterValues: {
    name: '',
    sku: '',
  },
  multiselectFilterValues: {
    manufacturer: [],
    supplier: [],
  },
  numberOfFiltersSelected: 0,
  generalFilterValue: '',
  selectedOrdersList: [],
  productTableColumns: {
    displayedColumns: [
      'select',
      'type',
      'image',
      'sku',
      'name',
      'supplier',
      'listPrice',
      'customerPrice',
      'discount',
      'costPrice',
      'salesDiscount',
      'salesPrice',
      'saleMargin',
      'totalDiscount',
      'quantity',
      'total',
      'defaultMargin',
      'attributedDiscount',
      'attributedMargin',
    ],
    hiddenColumns: ['select'],
    disabledSuffixLength: 0,
    availableColumns: ['select'],
    disabledColumns: ['sku', 'name'],
  },
  spOrdersTableColumns: {
    displayedColumns: [
      'select',
      'id',
      'company',
      'status',
      'paymentStatus',
      'debtorId',
      'source',
      'total',
      'itemsCount',
      'dateCreated',
      'statusDate',
      'exportStatus',
      'exportedAt',
      'exportMessage',
    ],
    hiddenColumns: ['select'],
    disabledSuffixLength: 0,
    availableColumns: [],
    disabledColumns: ['id', 'company'],
  },
  boOrdersTableColumns: {
    displayedColumns: [
      'select',
      'id',
      'company',
      'status',
      'paymentStatus',
      'debtorId',
      'source',
      'total',
      'itemsCount',
      'dateCreated',
      'statusDate',
      'exportStatus',
      'exportedAt',
      'exportMessage',
    ],
    hiddenColumns: ['select'],
    disabledSuffixLength: 0,
    availableColumns: [],
    disabledColumns: ['id', 'company'],
  },
  orderFilters: {},
};

export function ordersReducer(state: State = initialState, action: OrdersActions.OrdersActions) {
  switch (action.type) {
    case OrdersActions.GET_CURRENT_SP_PAGE:
      return {
        ...state,
        currentSpPage: action.payload,
      };
    case OrdersActions.GET_CURRENT_BO_PAGE:
      return {
        ...state,
        currentBoPage: action.payload,
      };
    case OrdersActions.GET_CURRENT_SP_PAGE_SIZE:
      return {
        ...state,
        currentSpPageSize: action.payload,
      };
    case OrdersActions.GET_CURRENT_BO_PAGE_SIZE:
      return {
        ...state,
        currentBoPageSize: action.payload,
      };
    case OrdersActions.GET_ORDERS_COUNT:
      return {
        ...state,
        ordersCount: action.payload,
      };
    case OrdersActions.LOAD_ORDERS:
      return {
        ...state,
        loading: true,
      };
    case OrdersActions.REFETCH_ORDERS:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case OrdersActions.LOAD_ORDERS_SUCCESS:
      return {
        ...state,
        orders: [...action.payload],
        loading: false,
        loaded: true,
      };
    case OrdersActions.LOAD_ORDERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case OrdersActions.ADD_ORDER:
      let ordersCount = state.ordersCount;
      let ordersList = [...state.orders];

      //add new order
      if (state.currentSpPage === 1) {
        if (ordersList.some((e) => e.id === action.payload.id)) {
          const index = ordersList.map((x) => x.id).indexOf(action.payload.id);
          ordersList.splice(index, 1);
        } else {
          ordersList.pop();
        }
        ordersList.splice(0, 0, action.payload);
      }

      ordersCount += 1;

      return {
        ...state,
        orders: ordersList,
        ordersCount,
      };
    case OrdersActions.UPDATE_ORDER:
      const index = state.orders.map((x) => x.id).indexOf(action.payload.id);
      const updatedQuote = {
        ...state.orders[index],
        ...action.payload.updatedOrder,
      };
      const updatedQuotes = [...state.orders];
      updatedQuotes[index] = updatedQuote;
      return {
        ...state,
        orders: updatedQuotes,
      };
    case OrdersActions.ADD_GENERAL_FILTER_ORDER:
      return {
        ...state,
        orderFilters: { ...state.orderFilters, ...action.payload },
      };
    case OrdersActions.DELETE_ORDER:
      if (state.orders.map((x) => x.id).indexOf(action.payload) !== -1) {
        let ordersCount = state.ordersCount;
        const index = state.orders.map((x) => x.id).indexOf(action.payload);

        ordersCount -= 1;
        return {
          ...state,
          orders: state.orders.filter((request, i) => {
            return i !== index;
          }),
          ordersCount,
        };
      } else {
        return {
          ...state,
        };
      }
    case OrdersActions.ADD_ORDER_TO_LIST:
      if (state.selectedOrdersList.map((x) => x.id).indexOf(action.payload.id) === -1) {
        return {
          ...state,
          selectedOrdersList: [...state.selectedOrdersList, action.payload],
        };
      } else {
        return {
          ...state,
        };
      }
    case OrdersActions.DELETE_ORDER_FROM_LIST:
      if (state.selectedOrdersList.map((x) => x.id).indexOf(action.payload) !== -1) {
        const index = state.selectedOrdersList.map((x) => x.id).indexOf(action.payload);
        return {
          ...state,
          selectedOrdersList: state.selectedOrdersList.filter((product, i) => {
            return i !== index;
          }),
        };
      } else {
        return {
          ...state,
        };
      }
    case OrdersActions.CLEAR_ORDERS_LIST:
      return {
        ...state,
        selectedOrdersList: [],
      };
    case OrdersActions.GET_MULTISELECT_FILTERS:
      let payloadCreatedAt =
        action.payload.createdAt?.greaterThan !== undefined && action.payload.createdAt?.lessThan !== undefined;
      let payloadUpdatedAt =
        action.payload.lastModifiedAt?.greaterThan !== undefined &&
        action.payload.lastModifiedAt.lessThan !== undefined;
      let payloadExportedAt =
        action.payload.exportedAt?.greaterThan !== undefined && action.payload.exportedAt?.lessThan !== undefined;
      let payloadPrice =
        action.payload.price?.greaterThan !== undefined || action.payload.price?.lessThan !== undefined;
      let payloadLength =
        (action.payload.type ? 1 : 0) +
        (payloadCreatedAt ? 1 : 0) +
        (payloadUpdatedAt ? 1 : 0) +
        (payloadExportedAt ? 1 : 0) +
        (action.payload.userId !== undefined ? action.payload.userId.length : 0) +
        (action.payload?.status?.length !== 6 ? 1 : 0) +
        (action.payload?.exportStatuses?.length ? 1 : 0) +
        (payloadPrice ? 1 : 0);
      return {
        ...state,
        orderFilters: {
          ...state.orderFilters,
          userId: action.payload.userId,
          status: action.payload.status,
          type: action.payload.type,
          createdAt: action.payload.createdAt,
          lastModifiedAt: action.payload.lastModifiedAt,
          exportedAt: action.payload.exportedAt,
          exportStatuses: action.payload.exportStatuses,
          price: action.payload.price,
          sortInputs: action.payload.sortInputs,
        },
        numberOfFiltersSelected: payloadLength,
      };
    case OrdersActions.GET_GENERAL_FILTER:
      return {
        ...state,
        generalFilterValue: action.payload,
      };
    case OrdersActions.CLEAR_ALL_FILTERS:
      return {
        ...state,
        columnFilterValues: {
          name: '',
          sku: '',
        },
        multiselectFilterValues: {
          manufacturer: [],
          supplier: [],
        },
        generalFilterValue: '',
        numberOfFiltersSelected: 0,
      };
    case OrdersActions.PRODUCT_TABLE_COLUMNS:
      return {
        ...state,
        productTableColumns: action.payload,
      };
    case OrdersActions.SP_ORDERS_TABLE_COLUMNS:
      return {
        ...state,
        spOrdersTableColumns: action.payload,
      };
    case OrdersActions.BO_ORDERS_TABLE_COLUMNS:
      return {
        ...state,
        boOrdersTableColumns: { ...action.payload },
      };

    default:
      return state;
  }
}
