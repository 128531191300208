import { gql } from 'apollo-angular';

export const priceSheetFragments = {
  priceSheet: gql`
    fragment priceSheet on Pricesheet {
      id
      createdAt
      lastModifiedAt
      code
      names {
        language
        value
      }
      descriptions {
        language
        value
      }
      priority
      readonly
    }
  `,
};
