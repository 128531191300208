import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialogRef } from '@angular/material/dialog';
import { QueryRef } from 'apollo-angular';
import { Subscription, map } from 'rxjs';
import { Usergroup } from '../../../shared/api/types/GraphQL';
import { UserTypesPlural } from '../../../shared/api/types/enums';
import { SnackBarService } from '../../../shared/components/snack-bar/snack-bar.service';
import { GqlErrorService } from '../../../shared/services/gql-error.service';
import { UsersService } from '../users.service';
@Component({
  selector: 'app-usergroups',
  templateUrl: './usergroups.component.html',
  styleUrls: ['./usergroups.component.scss'],
})
export class UsergroupsComponent implements OnInit {
  readonly usergroupsTabname = UserTypesPlural.companies;
  userQuery: QueryRef<any>;
  userQuerySub: Subscription;

  usergroups: Usergroup[];
  usergroupsCount: number;
  page: number = 1;
  itemsPerPage: number = 5;

  public columnNames: string[] = [];
  public dialogRef: MatDialogRef<UsergroupsComponent>;
  error: string = '';
  loading: boolean = true;

  displayedColumns: {
    name: string;
    label: string;
    content: (element: Usergroup) => string | number;
  }[] = [
    {
      name: 'name',
      label: 'Name',
      content: (element: Usergroup) => element.name,
    },
    {
      name: 'slug',
      label: 'Slug',
      content: (element: Usergroup) => element.slug,
    },
    {
      name: 'companies',
      label: 'Companies',
      content: (element: Usergroup) => element.companies.itemsFound,
    },
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private usersService: UsersService,
    public snackBarService: SnackBarService,
    private errorService: GqlErrorService,
  ) {}

  ngOnInit(): void {
    for (const column of this.displayedColumns) {
      this.columnNames.push(column.name);
    }
    this.userQuery = this.usersService.getUsergroups({
      page: 1,
      offset: this.itemsPerPage,
    });
    this.userQuerySub = this.userQuery.valueChanges
      .pipe(
        map((data) => {
          return data.data.usergroups;
        }),
      )
      .subscribe({
        next: (usergroups) => {
          this.usergroups = usergroups.items;
          this.usergroupsCount = usergroups.itemsFound;
          this.loading = false;
        },
        error: (error) => {
          this.errorService.getGqlError(error);
        },
      });
  }

  ngAfterViewInit() {
    this.paginator.page.subscribe({
      next: (page: PageEvent) => {
        if (this.itemsPerPage !== page.pageSize) {
          this.itemsPerPage = page.pageSize;
        }
        this.page = page.pageIndex + 1;

        let variables = {
          input: {
            page: this.page,
            offset: this.itemsPerPage,
          },
        };
        this.loading = true;
        this.userQuery.refetch(variables);
      },
      error: (error) => {
        this.error = error && error.graphQLErrors && error.graphQLErrors[0].message;
        this.snackBarService.openSnackBar(this.error, '', 'error');
      },
    });
    setTimeout(() => {
      this.paginator.pageIndex = this.page - 1;
    });
  }

  closeDialog(id: number) {
    this.dialogRef.close(id);
  }
}
