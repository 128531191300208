
// This file was autogenerated by dynamically running setEnv.ts and using dotenv for managing API key secrecy
export const environment = {
   production: false,
   debug: true,
   environment: "dev",
   mixpanel_token: "eb8d7ae62258be50965dfc0e21a3256e",
   appVersion: 21,
   boAccess: true,
   propeller: {
      api: {
         url: "https://api.helice.cloud/admin/dev/v2/graphql",
         key: "OqVbnb5wDlb4GF1udvK2trSW5Kf8XfBzS6vD3RL3SQl5vOQc"
      },
      rest: {
         url: "https://api.helice.cloud/api/g/admin/dev/v1/"
      }
   },
   firebase: {
      apiKey: "AIzaSyCW5zkUks1iQIqxMy5twA_4ZmgzBCEmV6Q",
      authDomain: "propeller-platform-dev.firebaseapp.com",
      projectId: "propeller-platform-dev",
      storageBucket: "propeller-platform-dev.appspot.com",
      messagingSenderId: "242087505159",
      appId: "1:242087505159:web:16e7b93cc325ad5199a9a2",
      tenantId: "admin-authentication-upgo5"
   },
   valuesetByName: {
      userTags:"SYSTEM_USER_TAGS",
      appLanguages:"SYSTEM_APP_LANGUAGES",
      dataLanguages:"SYSTEM_DATA_LANGUAGES",
      manufacturers:"SYSTEM_MANUFACTURERS",
      suppliers:"SYSTEM_SUPPLIERS",
      shippingMethod:"SYSTEM_SHIPPING_METHOD",
      paymentStatus:"SYSTEM_PAYMENT_STATUS",
      turnoverGroups:"SYSTEM_TURNOVER_GROUPS",
      source:"SYSTEM_SOURCE",
    },
    userTagsAttributeName:"SYSTEM_USER_GROUPS",
    recaptchaSiteKey:"6LdSZ1UqAAAAAJu-RygHN3Xx2W1S4zIQTKtoYTMl",
    appCheckDebugToken:"7D2F7BAF-36C6-4B1A-862B-F8D289C3286E",
    whitelistedCIQAEmails:"m.atanasovska+qa-ci-staging@propel.us,m.atanasovska@propel.us,testing-ci@example.com",
};
