import { AttributeFilter, Product, ProductSearchInput, ProductStatus } from 'src/app/shared/api/types/GraphQL';
import * as ProductsActions from './products.actions';
import { ManageDisplayedTableColumnsInput } from 'src/app/shared/api/types/types';
import { AppComponentLocation } from 'src/app/shared/api/types/enums';

type StateProperties = {
  location: AppComponentLocation;
  loading: boolean;
  loaded: boolean;
  products: Product[];
  queryVariables: ProductSearchInput;
  currentPage: number;
  currentPageSize: number;
  productsCount: number;
  error: Error;
  generalFilterValue: string;
  attributeFilters: AttributeFilter[];
  productList: Product[];
  productListChangeMessage: string;
  productListLength: number;
  productTableColumns: ManageDisplayedTableColumnsInput;
  productsFilters: ProductSearchInput;
  priceRange: {};
};

export interface State {
  productListing: StateProperties;
  productPicker: StateProperties;
}

const initialStateProperties: StateProperties = {
  location: AppComponentLocation.products,
  loading: false,
  loaded: false,
  products: [],
  queryVariables: {},
  currentPage: 1,
  currentPageSize: 5,
  productsCount: 0,
  error: undefined,
  generalFilterValue: '',
  attributeFilters: [],
  productList: [],
  productListChangeMessage: '',
  productListLength: 0,
  productTableColumns: {
    displayedColumns: [
      'select',
      'image',
      'sku',
      'name',
      'variants',
      'location',
      'supplier',
      'price',
      'stock',
      'orderable',
      'orderableExport',
      'status',
      'minQuantity',
      'qtySteps',
      'actionBtn',
    ],
    hiddenColumns: ['select', 'actionBtn', 'orderableExport'],
    availableColumns: ['select', 'actionBtn'],
    disabledColumns: ['sku', 'name'],
    disabledSuffixLength: 1,
  },
  productsFilters: {},
  priceRange: {},
};

const initialState: State = {
  productListing: initialStateProperties,
  productPicker: initialStateProperties,
};

export function productsReducer(state: State = initialState, action: ProductsActions.ProductsActions) {
  switch (action.type) {
    case ProductsActions.RESET_STATE_ENTRY_STATE:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          ...initialStateProperties,
        },
      };
    case ProductsActions.SET_LOCATION:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          location: action.payload.location,
        },
      };
    case ProductsActions.SET_QUERY_VARIABLES:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          queryVariables: { ...state[action.payload.stateEntry].queryVariables, ...action.payload.variables },
        },
      };

    case ProductsActions.GET_GENERAL_FILTER:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          generalFilterValue: action.payload.filterValue,
        },
      };
    case ProductsActions.GET_ATTRIBUTE_FILTRS:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          attributeFilters: action.payload.filterList,
        },
      };

    case ProductsActions.GET_CURRENT_PAGE:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          currentPage: action.payload.page,
        },
      };

    case ProductsActions.GET_CURRENT_PAGE_SIZE:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          currentPageSize: action.payload.pageSize,
        },
      };

    case ProductsActions.GET_PRODUCTS_COUNT:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          productsCount: action.payload.count,
        },
      };

    case ProductsActions.GET_PRODUCTS_IN_RANGE:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          priceRange: action.payload.range,
        },
      };

    case ProductsActions.LOAD_PRODUCTS:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          loaded: false,
          loading: true,
        },
      };

    case ProductsActions.REFETCH_PRODUCTS:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          loaded: false,
          loading: true,
        },
      };

    case ProductsActions.LOAD_PRODUCTS_SUCCESS:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          products: [...action.payload.products],
          loading: false,
          loaded: true,
        },
      };

    case ProductsActions.LOAD_PRODUCTS_FAILURE:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          error: action.payload.error,
          loading: false,
        },
      };

    case ProductsActions.SET_LOADER:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          loading: action.payload.isLoading,
        },
      };

    case ProductsActions.ADD_PRODUCT_TO_LIST:
      if (
        state[action.payload.stateEntry].productList
          .map((x) => x.productId)
          .indexOf(action.payload.product.productId) === -1
      ) {
        return {
          ...state,
          [action.payload.stateEntry]: {
            ...state[action.payload.stateEntry],
            productList: [...state[action.payload.stateEntry].productList, action.payload.product],
            productListLength: state[action.payload.stateEntry].productListLength + 1,
          },
        };
      } else {
        return {
          ...state,
        };
      }

    case ProductsActions.DELETE_PRODUCT_FROM_LIST:
      if (state[action.payload.stateEntry].productList.map((x) => x.id).indexOf(action.payload.id) !== -1) {
        const index = state[action.payload.stateEntry].productList.map((x) => x.id).indexOf(action.payload.id);
        return {
          ...state,
          [action.payload.stateEntry]: {
            ...state[action.payload.stateEntry],
            productList: state[action.payload.stateEntry].productList.filter((product, i) => {
              return i !== index;
            }),
            productListLength: state[action.payload.stateEntry].productListLength - 1,
          },
        };
      } else {
        return {
          ...state,
        };
      }

    case ProductsActions.CLEAR_PRODUCT_LIST:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          productList: [],
          productListLength: 0,
        },
      };
    case ProductsActions.ADD_CLUSTER_TO_LIST:
      if (state[action.payload.stateEntry].productList.map((x) => x.id).indexOf(action.payload.cluster.id) === -1) {
        return {
          ...state,
          [action.payload.stateEntry]: {
            ...state[action.payload.stateEntry],
            productList: [...state[action.payload.stateEntry].productList, action.payload.cluster],
            productListLength: state[action.payload.stateEntry].productListLength + 1,
          },
        };
      } else {
        return {
          ...state,
        };
      }
    case ProductsActions.ADD_CLUSTER_PRODUCT_TO_LIST:
      if (state[action.payload.stateEntry].productList.map((x) => x.id).indexOf(action.payload.cluster.id) === -1) {
        const numOfProducts = action.payload.cluster.products.length;
        return {
          ...state,
          [action.payload.stateEntry]: {
            ...state[action.payload.stateEntry],
            productList: [...state[action.payload.stateEntry].productList, action.payload.cluster],
            productListLength: state[action.payload.stateEntry].productListLength + numOfProducts,
          },
        };
      } else {
        const clusterIndex = state[action.payload.stateEntry].productList
          .map((x) => x.id)
          .indexOf(action.payload.cluster.id);
        const productsToAdd = [];
        for (let product of action.payload.cluster.products) {
          if (
            state[action.payload.stateEntry].productList[clusterIndex].cluster.products
              .map((x) => x.id)
              .indexOf(product.id) === -1
          ) {
            productsToAdd.push(product);
          }
        }
        if (!!productsToAdd.length) {
          return {
            ...state,
            [action.payload.stateEntry]: {
              ...state[action.payload.stateEntry],
              productList: [
                ...state[action.payload.stateEntry].productList.slice(0, clusterIndex),
                {
                  ...state[action.payload.stateEntry].productList[clusterIndex],
                  products: [
                    ...state[action.payload.stateEntry].productList[clusterIndex].cluster.products,
                    ...productsToAdd,
                  ],
                },
                ...state[action.payload.stateEntry].productList.slice(clusterIndex + 1),
              ],
              productListLength: state[action.payload.stateEntry].productListLength + productsToAdd.length,
            },
          };
        } else {
          return {
            ...state,
          };
        }
      }
    case ProductsActions.ADD_CONFIGURABLE_CLUSTER_PRODUCT_TO_LIST:
      let isDiffrentCluster = true;
      if (
        state[action.payload.stateEntry].productList.map((x) => x.id).indexOf(action.payload.cluster.id) !== -1 &&
        state[action.payload.stateEntry].productList[
          state[action.payload.stateEntry].productList.map((x) => x.id).indexOf(action.payload.cluster.id)
        ].cluster.products.length === action.payload.cluster.products.length
      ) {
        const clusterIndex = state[action.payload.stateEntry].productList
          .map((x) => x.id)
          .indexOf(action.payload.cluster.id);
        for (let [i, product] of action.payload.cluster.products.entries()) {
          if (
            state[action.payload.stateEntry].productList[clusterIndex].cluster.products
              .map((x) => x.id)
              .indexOf(product.id) === -1
          ) {
            isDiffrentCluster = true;
            break;
          } else if (i === action.payload.cluster.products.length - 1) {
            isDiffrentCluster = false;
          }
        }
      }
      if (isDiffrentCluster) {
        const numOfProducts = action.payload.cluster.products.filter((product) => product.cluster !== null).length;
        return {
          ...state,
          [action.payload.stateEntry]: {
            ...state[action.payload.stateEntry],
            productList: [...state[action.payload.stateEntry].productList, action.payload.cluster],
            productListLength: state[action.payload.stateEntry].productListLength + numOfProducts,
          },
        };
      } else {
        return {
          ...state,
        };
      }
    case ProductsActions.DELETE_PRODUCT_FROM_LIST:
      if (state[action.payload.stateEntry].productList.map((x) => x.id).indexOf(action.payload.id) !== -1) {
        const index = state[action.payload.stateEntry].productList.map((x) => x.id).indexOf(action.payload.id);
        return {
          ...state,
          [action.payload.stateEntry]: {
            ...state[action.payload.stateEntry],
            lproductList: state[action.payload.stateEntry].productList.filter((product, i) => {
              return i !== index;
            }),
            productListChangeMessage:
              'Product ' +
              state[action.payload.stateEntry].productList[index].names[0]?.value +
              ' was removed from the basket',
            productListLength: state[action.payload.stateEntry].productListLength - 1,
          },
        };
      } else {
        return {
          ...state,
        };
      }
    case ProductsActions.DELETE_PRODUCT_FROM_CLUSTER:
      if (state[action.payload.stateEntry].productList.map((x) => x.id).indexOf(action.payload.clusterId) !== -1) {
        const clusterIndex = state[action.payload.stateEntry].productList
          .map((x) => x.id)
          .indexOf(action.payload.clusterId);
        if (
          state[action.payload.stateEntry].productList[clusterIndex].cluster.products
            .map((x) => x.id)
            .indexOf(action.payload.productId) !== -1
        ) {
          if (state[action.payload.stateEntry].productList[clusterIndex].cluster.products.length > 1) {
            const productIndex = state[action.payload.stateEntry].productList[clusterIndex].cluster.products
              .map((x) => x.id)
              .indexOf(action.payload.productId);
            return {
              ...state,
              [action.payload.stateEntry]: {
                ...state[action.payload.stateEntry],
                productList: [
                  ...state[action.payload.stateEntry].productList.slice(0, clusterIndex),
                  {
                    ...state[action.payload.stateEntry].productList[clusterIndex],
                    products: [
                      ...state[action.payload.stateEntry].productList[clusterIndex].cluster.products.filter(
                        (product, i) => {
                          return i !== productIndex;
                        },
                      ),
                    ],
                  },
                  ...state[action.payload.stateEntry].productList.slice(clusterIndex + 1),
                ],
                productListLength: state[action.payload.stateEntry].productListLength - 1,
              },
            };
          } else {
            return {
              ...state,
              [action.payload.stateEntry]: {
                ...state[action.payload.stateEntry],
                productList: state[action.payload.stateEntry].productList.filter((product, i) => {
                  return i !== clusterIndex;
                }),
                productListLength: state[action.payload.stateEntry].productListLength - 1,
              },
            };
          }
        } else {
          return {
            ...state,
          };
        }
      } else {
        return {
          ...state,
        };
      }
    case ProductsActions.PRODUCT_TABLE_COLUMNS:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          productTableColumns: action.payload.columnsObj,
        },
      };
    case ProductsActions.ADD_GENERAL_FILTER:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          productsFilters: { ...state[action.payload.stateEntry].productsFilters, ...action.payload.filter },
        },
      };
    case ProductsActions.REMOVE_GENERAL_FILTER:
      const { columnName, index, clear, stateEntry } = action.payload;

      // Clone the state's productsFilters object to prevent mutation
      const updatedFilters = { ...state[stateEntry].productsFilters };
      const updatedQueryVariables = { ...state[stateEntry].queryVariables };
      // Check if productsFilters[columnName] is an array
      if (Array.isArray(updatedFilters[columnName])) {
        if (clear || updatedFilters[columnName].length === 0) {
          delete updatedFilters[columnName];
        } else {
          // If it's an array, clone the array, remove the item at the specified index, and update the productsFilters object
          updatedFilters[columnName] = [...updatedFilters[columnName]];
          updatedFilters[columnName].splice(index, 1);
        }
      } else {
        // If it's not an array, simply delete the key corresponding to columnName
        delete updatedFilters[columnName];
      }
      if (Array.isArray(updatedQueryVariables[columnName])) {
        // If it's an array, clone the array, remove the item at the specified index, and update the productsFilters object
        updatedQueryVariables[columnName] = [...updatedQueryVariables[columnName]];
        if (clear) {
          updatedQueryVariables[columnName] = [];
        } else {
          updatedQueryVariables[columnName].splice(index, 1);
        }

        if (updatedQueryVariables[columnName].length === 0) {
          if (columnName === 'statuses') {
            updatedQueryVariables[columnName] = [
              ProductStatus.A,
              ProductStatus.N,
              ProductStatus.P,
              ProductStatus.R,
              ProductStatus.S,
              ProductStatus.T,
            ];
          } else {
            delete updatedQueryVariables[columnName];
          }
        }
      } else {
        // If it's not an array, simply delete the key corresponding to columnName
        delete updatedQueryVariables[columnName];
      }

      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          productsFilters: updatedFilters,
          queryVariables: updatedQueryVariables,
        },
      };
    default:
      return state;
  }
}
