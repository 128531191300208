import { Injectable } from '@angular/core';
import { User } from './auth-user';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as fromApp from '../store/app.reducer';
import * as AuthActions from './store/auth.actions';
import { Store } from '@ngrx/store';
import { Tenant } from './store/auth.reducer';
import { RoleAccess } from '../shared/api/types/GraphQL';
import { RoleNames } from '../shared/api/types/enums';
import { JwtRole } from '../shared/api/types/types';
import { Observable, from, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: any;
  roles: JwtRole[];
  hasSalesRole: boolean = false;
  hasBackofficeRole: boolean = false;

  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
  ) {
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.user = JSON.parse(JSON.stringify(user));
        this.store.dispatch(new AuthActions.AutoLogin(this.user));

        user.getIdTokenResult().then((idTokenResult) => {
          if (idTokenResult.claims) {
            const { claims, firebase } = idTokenResult.claims;
            const [claimKey]: any = Object.values(claims);

            if (firebase.tenant) this.store.dispatch(new AuthActions.SetAdminTenant(firebase.tenant));
            if (claimKey.roles) {
              const roles = this.convertTokenRolesToObjects(claimKey.roles);
              this.store.dispatch(new AuthActions.SetViewerRoles(roles));
            }
          }
        });
      } else {
        this.store.dispatch(new AuthActions.LogoutStart());
      }
    });
    this.store.select((state) => state.auth.roles).subscribe((roles) => (this.roles = roles));
  }

  /* Setting up user data when sign in with username/password,
    sign up with username/password and sign in with social auth
    provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  setUserData(user: any) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    };
    return userRef.set(userData, {
      merge: true,
    });
  }

  getTenants(user) {
    if (user) {
      const headerDict = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        apikey: environment.propeller.api.key,
        Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
      };
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };
      return this.http.get(environment.propeller.rest.url + 'users/' + user.email, requestOptions);
    }
  }

  getToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.afAuth.onAuthStateChanged((user) => {
        if (user) {
          user.getIdToken().then((idToken) => {
            resolve(idToken);
          });
        }
      });
    });
  }

  getActiveTenant(): Promise<Tenant> {
    return new Promise((resolve, reject) => {
      this.store.select('auth').subscribe((authState) => {
        if (!!authState.activeTenant) {
          resolve(authState.activeTenant.tenant);
        }
      });
    });
  }

  refreshToken(): Observable<any> {
    return from(this.afAuth.currentUser).pipe(
      switchMap((user) => {
        return from(user.getIdTokenResult(true));
      }),
    );
  }

  checkAnyUserRole(routeData: RoleNames | RoleNames[]): boolean {
    if (Array.isArray(routeData)) {
      return routeData.some((roleName) => this.checkUserRole(roleName));
    } else {
      return this.roles.some((role) => role.name === routeData && role.access !== RoleAccess.None);
    }
  }

  checkUserRole(routeData: RoleNames | RoleNames[]): boolean {
    if (Array.isArray(routeData)) {
      return routeData.every((roleName) =>
        this.roles.some((role) => role.name === roleName && role.access !== RoleAccess.None),
      );
    } else {
      return this.roles.some((role) => role.name === routeData && role.access !== RoleAccess.None);
    }
  }

  checkUserAccess(routeData: RoleNames | RoleNames[]): boolean {
    if (Array.isArray(routeData)) {
      return routeData.every((roleName) =>
        this.roles.some(
          (role) => role.name === roleName && (role.access === RoleAccess.Owner || role.access === RoleAccess.Editor),
        ),
      );
    } else {
      return this.roles.some(
        (role) => role.name === routeData && (role.access === RoleAccess.Owner || role.access === RoleAccess.Editor),
      );
    }
  }

  convertTokenRolesToObjects(rolesArr) {
    const rolesObjects = [];

    rolesArr.forEach((item) => {
      const parts = item.split('.');
      const roleObject: JwtRole = {
        name: parts.length > 1 ? RoleNames[parts[0] as keyof typeof RoleNames] : parts[0],
        access: parts.length > 1 ? RoleAccess[parts[1].charAt(0) + parts[1].slice(1).toLowerCase()] : null,
      };

      rolesObjects.push(roleObject);
    });

    return rolesObjects;
  }
}
