import { gql } from 'apollo-angular';

export const orderStatusSetsListQuery = gql`
  query orderStatusSets($input: OrderStatusSetsSearchInput) {
    orderStatusSets(input: $input) {
      items {
        id
        name
        description
        orderStatuses {
          id
          name
        }
      }
      itemsFound
      offset
      page
      pages
      start
      end
    }
  }
`;

export const orderStatusSetsListDropDownQuery = gql`
  query orderStatusSets($input: OrderStatusSetsSearchInput) {
    orderStatusSets(input: $input) {
      items {
        id
        name
      }
      itemsFound
      offset
      page
      pages
      start
      end
    }
  }
`;

export const orderStatusSetQuery = gql`
  query orderStatusSet($input: OrderStatusSetSearchByInput!) {
    orderStatusSet(input: $input) {
      id
      name
      description
    }
  }
`;
