import { gql } from 'apollo-angular';
import { BulkPriceFragments, PriceFragments } from '../fragments/product-price-fragments';

export const ProductPriceCreate = gql`
  mutation ProductPriceCreate($input: PriceCreateInput!) {
    priceCreate(input: $input) {
      ...price
    }
  }
  ${PriceFragments.price}
`;

export const ProductPriceUpdate = gql`
  mutation ProductPriceUpdate($id: String!, $input: PriceUpdateInput!) {
    priceUpdate(id: $id, input: $input) {
      ...price
    }
  }
  ${PriceFragments.price}
`;

export const BulkPriceCreate = gql`
  mutation BulkPriceCreate($input: BulkPriceCreateInput!) {
    bulkPriceCreate(input: $input) {
      ...bulkPrice
    }
  }
  ${BulkPriceFragments.bulkPrice}
`;

export const BulkPriceUpdate = gql`
  mutation BulkPriceUpdate($id: String!, $input: BulkPriceUpdateInput!) {
    bulkPriceUpdate(id: $id, input: $input) {
      ...bulkPrice
    }
  }
  ${BulkPriceFragments.bulkPrice}
`;

export const BulkPriceDelete = gql`
  mutation BulkPriceDelete($id: String!) {
    bulkPriceDelete(id: $id)
  }
`;

export const BulkPricesDelete = gql`
  mutation BulkPricesDelete($priceId: String!) {
    bulkPricesDelete(priceId: $priceId)
  }
`;

export const BulkCostPriceCreate = gql`
  mutation BulkCostPriceCreate($input: BulkCostPriceCreateInput!) {
    bulkCostPriceCreate(input: $input) {
      ...bulkCostPrice
    }
  }
  ${BulkPriceFragments.bulkCostPrice}
`;

export const BulkCostPriceUpdate = gql`
  mutation BulkCostPriceUpdate($id: String!, $input: BulkCostPriceUpdateInput!) {
    bulkCostPriceUpdate(id: $id, input: $input) {
      ...bulkCostPrice
    }
  }
  ${BulkPriceFragments.bulkCostPrice}
`;

export const BulkCostPriceDelete = gql`
  mutation BulkCostPriceDelete($id: String!) {
    bulkCostPriceDelete(id: $id)
  }
`;

export const BulkCostPricseDelete = gql`
  mutation BulkCostPricesDelete($priceId: String!) {
    bulkCostPricesDelete(priceId: $priceId)
  }
`;
