import { gql } from 'apollo-angular';
import { PayMethodsFragments } from '../fragments/paymethods-fragments';

export const PayMethodCreate = gql`
  mutation PayMethodCreate($input: PayMethodCreateInput!) {
    payMethodCreate(input: $input) {
      ...payMethod
    }
  }
  ${PayMethodsFragments.payMethod}
`;

export const PayMethodUpdate = gql`
  mutation PayMethodUpdate($id: Int!, $input: PayMethodUpdateInput!) {
    payMethodUpdate(id: $id, input: $input) {
      ...payMethod
    }
  }
  ${PayMethodsFragments.payMethod}
`;

export const PayMethodDelete = gql`
  mutation PayMethodDelete($id: Int!) {
    payMethodDelete(id: $id)
  }
`;
