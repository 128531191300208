import { Action } from '@ngrx/store';
import { ValuesetItem } from 'src/app/shared/api/types/GraphQL';

export const LOAD_CUSTOM_LANGUAGES = '[Custom Language] Load Custom Languages';
export const CUSTOM_LANGUAGES_SUCCESS = '[Custom Languages Success] Custom Languages Success';
export const CUSTOM_LANGUAGES_FAILURE = '[Custom Languages Failure] Custom Languages Failure';

export class LoadCustomLanguages implements Action {
  readonly type = LOAD_CUSTOM_LANGUAGES;
  constructor(public payload: { name: string }) {}
}

export class CustomLanguagesSuccess implements Action {
  readonly type = CUSTOM_LANGUAGES_SUCCESS;

  constructor(public payload: ValuesetItem[]) {}
}

export class CustomLanguagesFailure implements Action {
  readonly type = CUSTOM_LANGUAGES_FAILURE;

  constructor(public payload: Error) {}
}

export type CustomLanguagesActions = LoadCustomLanguages | CustomLanguagesSuccess | CustomLanguagesFailure;
