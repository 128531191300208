import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, Observable, of, Subscription, tap } from 'rxjs';

import * as ProductsActions from './products.actions';
import * as fromApp from 'src/app/store/app.reducer';
import { globals } from 'src/app/shared/globals';
import { ProductsService } from '../products.service';
import { Router } from '@angular/router';
import { QueryRef } from 'apollo-angular';
import { ProductListResponse } from 'src/app/shared/api/types/types';
import { Product } from 'src/app/shared/api/types/GraphQL';
import { ProductStateEntry } from 'src/app/shared/api/types/enums';

@Injectable()
export class ProductsEffects {
  productsQuery: QueryRef<ProductListResponse>;
  productsQuerySub: Observable<any>;
  refreshSub: Subscription;
  queryRefetch: boolean = false;
  location: string = '';

  constructor(
    private actions: Actions,
    private productsService: ProductsService,
    private store: Store<fromApp.AppState>,
    private router: Router,
  ) {}

  public readonly loadProducts: Observable<any> = createEffect(() => {
    return this.actions.pipe(
      ofType(ProductsActions.LOAD_PRODUCTS),
      mergeMap((action: ProductsActions.LoadProducts) => {
        if (action.payload.stateEntry === ProductStateEntry.productList)
          this.productsQuery = this.productsService.getAllProducts({
            input: action.payload.variables,
            appLanguage: globals.appLanguage,
          });
        else
          this.productsQuery = this.productsService.getAllProductsWithClusterItems({
            input: action.payload.variables,
            appLanguage: globals.appLanguage,
          });

        this.productsQuerySub = this.productsQuery.valueChanges.pipe(
          map((data) => data.data.products),
          map((products) => {
            this.productsService.imgLoading = true;
            if (!this.queryRefetch) {
              this.store.dispatch(
                new ProductsActions.GetCurrentPage({ page: 1, stateEntry: action.payload.stateEntry }),
              );
              this.store.dispatch(
                new ProductsActions.GetCurrentPageSize({
                  pageSize: globals.globalOffset,
                  stateEntry: action.payload.stateEntry,
                }),
              );
              this.store.dispatch(
                new ProductsActions.LoadProductsFailure({ error: undefined, stateEntry: action.payload.stateEntry }),
              );
              this.store.dispatch(new ProductsActions.ClearProductList(action.payload.stateEntry));
            }
            this.queryRefetch = true;
            this.store.dispatch(
              new ProductsActions.GetProductsCount({
                count: products.itemsFound,
                stateEntry: action.payload.stateEntry,
              }),
            );
            const { minPrice, maxPrice } = products;
            let priceRange = { minPrice, maxPrice };
            this.store.dispatch(
              new ProductsActions.GetProductsInRange({
                range: priceRange,
                stateEntry: action.payload.stateEntry,
              }),
            );
            return new ProductsActions.LoadProductsSuccess({
              products: products.items as Product[],
              stateEntry: action.payload.stateEntry,
            });
          }),
          catchError((error) => {
            return of(new ProductsActions.LoadProductsFailure({ error, stateEntry: action.payload.stateEntry }));
          }),
        );

        return this.productsQuerySub;
      }),
    );
  });

  public readonly refetchProducts = createEffect(
    () =>
      this.actions.pipe(
        ofType(ProductsActions.REFETCH_PRODUCTS),
        tap((action: ProductsActions.RefetchProducts) => {
          if (this.productsQuery) this.productsQuery.getLastResult();
          this.productsQuery.refetch({ input: action.payload.variables });
        }),
      ),
    { dispatch: false },
  );
}
