import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class QueryParamService {
  selectedTab: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<any>,
  ) {}

  handleQueryParams(action: any) {
    const backQueryParam = this.route.snapshot.queryParams['back'];
    const tabQueryParam = parseInt(this.route.snapshot.queryParams['tab']);

    if (!isNaN(tabQueryParam)) {
      this.selectedTab = tabQueryParam;
    }
    if (backQueryParam) {
      if (!!JSON.parse(backQueryParam)) {
        this.dispatchAction(action);
      }
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { back: null, tab: null },
        queryParamsHandling: 'merge',
      });
    }
  }
  private dispatchAction(action: any) {
    this.store.dispatch(action);
  }
}
