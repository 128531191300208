import { Pipe, PipeTransform } from '@angular/core';
import { LocalizedString } from '../api/types/GraphQL';
import { globals } from 'src/app/shared/globals';

@Pipe({
  name: 'localizedString',
})
export class localizedStringPipe implements PipeTransform {
  transform(localizedStringArray: LocalizedString[]): string {
    if (localizedStringArray && localizedStringArray.length) {
      const description = localizedStringArray.find((item) => item.language === globals.appLanguage);
      return description ? description.value : '';
    }
    return '';
  }
}
