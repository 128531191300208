import { Pricesheet, PricesheetSearchInput } from 'src/app/shared/api/types/GraphQL';
import { globals } from 'src/app/shared/globals';
import { sortBy } from 'lodash';
import * as PriceSheetActions from './price-sheet.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  dragDisabled: boolean;
  priceSheetList: Pricesheet[];
  currentPage: number;
  currentPageSize: number;
  priceSheetCount: number;
  error: Error;
  generalFilterValue: string;
  activeTab: number;
  selectedPriceSheetList: Pricesheet[];
  priceSheetFilters: PricesheetSearchInput;
}

const initialState: State = {
  loading: false,
  loaded: false,
  dragDisabled: false,
  priceSheetList: [],
  currentPage: 1,
  currentPageSize: globals.globalOffset,
  priceSheetCount: 0,
  error: undefined,
  generalFilterValue: '',
  activeTab: 0,
  selectedPriceSheetList: [],
  priceSheetFilters: {},
};

let sortPriceSheetByPriority = (priceSheet: Pricesheet[]) => {
  return sortBy(priceSheet, ['priority']);
};

let shouldDisableDrag = (obj) => {
  if (obj.sortInputs && obj.sortInputs.field !== 'PRIORITY') {
    return true;
  }
  if (Object.keys(obj).length > 0) {
    return true;
  }
  return false;
};

export function priceSheetReducer(state: State = initialState, action: PriceSheetActions.PriceSheetActions) {
  switch (action.type) {
    case PriceSheetActions.GET_GENERAL_FILTER:
      return {
        ...state,
        generalFilterValue: action.payload,
      };
    case PriceSheetActions.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case PriceSheetActions.GET_CURRENT_PAGE_SIZE:
      return {
        ...state,
        currentPageSize: action.payload,
      };
    case PriceSheetActions.GET_PRICE_SHEET_COUNT:
      return {
        ...state,
        priceSheetCount: action.payload,
      };
    case PriceSheetActions.LOAD_PRICE_SHEET:
      let loading;
      state.loaded ? (loading = false) : (loading = true);
      return {
        ...state,
        loading: loading,
      };
    case PriceSheetActions.REFETCH_PRICE_SHEET:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case PriceSheetActions.LOAD_PRICE_SHEET_SUCCESS:
      let newPriceSheetListLoad = [...action.payload];
      let sortedPriceSheetListLoad = sortPriceSheetByPriority(newPriceSheetListLoad);
      return {
        ...state,
        priceSheetList: sortedPriceSheetListLoad,
        loading: false,
        loaded: true,
      };
    case PriceSheetActions.LOAD_PRICE_SHEET_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case PriceSheetActions.ADD_PRICE_SHEET:
      let newPriceSheetListAdd = [...state.priceSheetList, action.payload];
      let sortedPriceSheetListAdd = sortPriceSheetByPriority(newPriceSheetListAdd);
      return {
        ...state,
        priceSheetList: sortedPriceSheetListAdd,
      };
    case PriceSheetActions.ADD_PRICE_SHEET_TO_LIST:
      if (state.selectedPriceSheetList.map((x) => x.id).indexOf(action.payload.id) === -1) {
        return {
          ...state,
          selectedPriceSheetList: [...state.selectedPriceSheetList, action.payload],
        };
      } else {
        return {
          ...state,
        };
      }
    case PriceSheetActions.DELETE_PRICE_SHEET_FROM_LIST:
      const payloadString = action.payload.toString(); // Convert payload to string
      if (state.selectedPriceSheetList.map((x) => x.id).indexOf(payloadString) !== -1) {
        const index = state.selectedPriceSheetList.map((x) => x.id).indexOf(payloadString);
        return {
          ...state,
          selectedPriceSheetList: state.selectedPriceSheetList.filter((list, i) => {
            return i !== index;
          }),
        };
      }
      return state;

    case PriceSheetActions.CLEAR_PRICE_SHEET_LIST:
      return {
        ...state,
        selectedPriceSheetList: [],
      };
    case PriceSheetActions.UPDATE_PRICE_SHEET:
      const updatedPriceSheetIndex = state.priceSheetList.findIndex(
        (priceSheet) => priceSheet.id === action.payload.id.toString(),
      ); // Convert payload to string
      if (updatedPriceSheetIndex > -1) {
        const updatedPriceSheetList = [...state.priceSheetList];
        updatedPriceSheetList[updatedPriceSheetIndex] = action.payload.updatedPriceSheet;
        let sortedPriceSheetListUpdate = sortPriceSheetByPriority(updatedPriceSheetList);
        return {
          ...state,
          priceSheetList: sortedPriceSheetListUpdate,
        };
      }
      return state; // If the pricesheet to update is not found, return the current state unchanged.

    case PriceSheetActions.CREATE_PRICE_SHEET:
      let newPriceSheetListCreate = [...state.priceSheetList, action.payload];
      let sortedPriceSheetListCreate = sortPriceSheetByPriority(newPriceSheetListCreate);
      return {
        ...state,
        priceSheetList: sortedPriceSheetListCreate,
      };
    case PriceSheetActions.ADD_GENERAL_FILTER:
      return {
        ...state,
        priceSheetFilters: { ...state.priceSheetFilters, ...action.payload },
        dragDisabled: shouldDisableDrag(action.payload),
      };
    case PriceSheetActions.REMOVE_GENERAL_FILTER:
      const { columnName, index } = action.payload;

      // Clone the state's priceSheetFilters object to prevent mutation
      const updatedFilters = { ...state.priceSheetFilters };

      // Check if orderStatusFilters[columnName] is an array
      if (Array.isArray(updatedFilters[columnName])) {
        // If it's an array, clone the array, remove the item at the specified index, and update the orderStatusFilters object
        updatedFilters[columnName] = [...updatedFilters[columnName]];
        updatedFilters[columnName].splice(index, 1);
        if (updatedFilters[columnName].length === 0) {
          delete updatedFilters[columnName];
        }
      } else {
        // If it's not an array, simply delete the key corresponding to columnName
        delete updatedFilters[columnName];
      }

      return {
        ...state,
        priceSheetFilters: updatedFilters,
        dragDisabled: shouldDisableDrag({ ...updatedFilters }),
      };
    default:
      return state;
  }
}
