import { Injectable } from '@angular/core';
import { QueryRef } from 'apollo-angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, catchError, map, mergeMap, of, tap } from 'rxjs';
import * as OrderListsActions from './orderlists.actions';
import { OrderlistsService } from '../orderlists.service';

@Injectable()
export class OrderlistsEffects {
  query: QueryRef<any>;
  querySub: Observable<Action>;

  constructor(
    private actions: Actions,
    private orderlistsService: OrderlistsService,
  ) {}

  public readonly loadOrderlists: Observable<Action> = createEffect(() => {
    return this.actions.pipe(
      ofType(OrderListsActions.LOAD_ORDER_LISTS),
      mergeMap((action: OrderListsActions.LoadOrderLists) => {
        this.query = this.orderlistsService.getOrderLists(action.payload);
        this.querySub = this.query.valueChanges.pipe(
          map((data) => {
            return new OrderListsActions.LoadOrderListsSuccess({
              items: data.data.orderlists.items,
              itemsFound: data.data.orderlists.itemsFound,
            });
          }),
          catchError((error) => of(new OrderListsActions.LoadOrderListsFailure(error))),
        );
        return this.querySub;
      }),
    );
  });

  public readonly refetchOrderlists = createEffect(
    () =>
      this.actions.pipe(
        ofType(OrderListsActions.REFETCH_ORDER_LISTS),
        tap((action: OrderListsActions.RefetchOrderLists) => {
          if (this.query) this.query.resetLastResults();
          this.query.refetch(action.payload);
        }),
      ),
    { dispatch: false },
  );
}
