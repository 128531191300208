import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { RoleNames } from './shared/api/types/enums';
import { RoleGuard } from './shared/guards/role.guard';
import { RoutePaths } from './shared/api/types/enums';
import { OrderStatusesResolver } from './order-management/orders/orders.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: `/home`,
    pathMatch: 'full',
  },
  {
    path: 'home',
    data: {
      title: 'Home',
    },
    loadChildren: () => import('./home/home-page.module').then((mod) => mod.HomePageModule),
  },
  {
    path: RoutePaths.Settings,
    loadChildren: () => import('./settings/settings.module').then((mod) => mod.SettingsModule),
  },
  {
    path: RoutePaths.EBusinessIncentives,
    loadChildren: () => import('./e-business/incentives/incentives.module').then((mod) => mod.IncentivesModule),
    data: { role: [RoleNames.pricing, RoleNames.backoffice] },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: RoutePaths.OrderManagementCarriers,
    loadChildren: () => import('./order-management/carriers/carriers.module').then((mod) => mod.CarriersModule),
    data: { role: [RoleNames.logistics, RoleNames.backoffice] },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: RoutePaths.OrderManagementWarehouses,
    loadChildren: () => import('./order-management/warehouses/warehouses.module').then((mod) => mod.WarehousesModule),
    data: { role: RoleNames.logistics },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: RoutePaths.OrderManagementPaymethods,
    loadChildren: () => import('./order-management/paymethods/paymethods.module').then((mod) => mod.PaymethodsModule),
    data: { role: [RoleNames.shop, RoleNames.backoffice] },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: RoutePaths.OrderManagementOrderStatuses,
    loadChildren: () =>
      import('./order-management/order-status/order-status.module').then((mod) => mod.OrderStatusModule),
    data: { role: [RoleNames.order, RoleNames.backoffice] },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: RoutePaths.RuleBuilder,
    loadChildren: () => import('./rule-builder/rule-builder.module').then((mod) => mod.RuleBuilderModule),
    canActivate: [AuthGuard],
  },
  {
    path: RoutePaths.ProductCatalogue,
    loadChildren: () => import('./catalog/catalog.module').then((mod) => mod.CatalogModule),
    data: { role: [RoleNames.sales] },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: RoutePaths.PimProductsAndClustersProduct,
    loadChildren: () =>
      import('./pim/products/product-details/product-details.module').then((mod) => mod.ProductDetailsModule),
    data: { role: [RoleNames.product, RoleNames.backoffice] },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: RoutePaths.PimProductsAndClusters,
    loadChildren: () => import('./pim/products/products.module').then((mod) => mod.ProductsModule),
    data: { role: [RoleNames.product, RoleNames.backoffice] },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: RoutePaths.PimCategories,
    loadChildren: () => import('./pim/categories/categories.module').then((mod) => mod.CategoriesModule),
    data: { role: [RoleNames.product, RoleNames.backoffice] },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: RoutePaths.UserManagementFavorites,
    loadChildren: () => import('./crm/favorites/favorites.module').then((mod) => mod.FavoritesModule),
    data: { role: [RoleNames.shop, RoleNames.backoffice] },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: RoutePaths.UserManagementOrderlists,
    loadChildren: () => import('./crm/orderlists/orderlists.module').then((mod) => mod.OrderlistsModule),
    data: { role: [RoleNames.shop, RoleNames.backoffice] },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: RoutePaths.SignIn,
    loadChildren: () => import('./auth/auth.module').then((mod) => mod.AuthModule),
  },
  {
    path: RoutePaths.UserManagementUsers,
    loadChildren: () => import('./crm/users/users.module').then((mod) => mod.UsersModule),
    data: { role: [RoleNames.user, RoleNames.backoffice] },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: RoutePaths.UserManagementUsersDetails,
    loadChildren: () => import('./crm/users/user-details/user-details.module').then((mod) => mod.UserDetailsModule),
    data: { role: [RoleNames.user, RoleNames.backoffice] },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: RoutePaths.Companies,
    loadChildren: () => import('./companies/companies.module').then((mod) => mod.CompaniesModule),
    data: { role: [RoleNames.sales] },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: '',
    loadChildren: () => import('./order-management/orders/order-edit/order-edit.module').then((m) => m.OrderEditModule),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    loadChildren: () => import('./order-management/orders/orders.module').then((mod) => mod.OrdersModule),
    resolve: {
      orderStatuses: OrderStatusesResolver,
    },
    canActivate: [AuthGuard],
  },
  {
    path: RoutePaths.Pricing,
    loadChildren: () => import('./pricing/pricing/pricesheets/price-sheet.module').then((mod) => mod.PriceSheetModule),
    data: { role: [RoleNames.pricing, RoleNames.backoffice] },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: RoutePaths.PricingTaxes,
    loadChildren: () => import('./pricing/taxes/taxes.module').then((mod) => mod.TaxesModule),
    data: { role: [RoleNames.pricing, RoleNames.backoffice] },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: RoutePaths.PricingSurcharges,
    loadChildren: () => import('./pricing/surcharges/surcharges.module').then((mod) => mod.SurchargesModule),
    data: { role: [RoleNames.pricing, RoleNames.backoffice] },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: RoutePaths.AdminValueset,
    loadChildren: () => import('./admin/value-set/value-set.module').then((mod) => mod.ValueSetModule),
    data: { role: [RoleNames.configuration, RoleNames.backoffice] },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: RoutePaths.AdminAttributes,
    loadChildren: () => import('./admin/attributes/attributes.module').then((mod) => mod.AttributesModule),
    data: { role: [RoleNames.configuration, RoleNames.backoffice] },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: RoutePaths.AdminBackofficeUsers,
    loadChildren: () =>
      import('./admin/backoffice-users/backoffice-users.module').then((mod) => mod.BackofficeUsersModule),
    data: { role: [RoleNames.role, RoleNames.backoffice] },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: '404',
    component: PageNotFoundComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Sales Portal | Page not found',
    },
  },
  { path: '**', redirectTo: '/404', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
