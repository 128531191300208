<div class="mat-caption tab-header object-mb-2">Companies are all your B2B clients.</div>

<app-company-filter
  (goOnFirstPage)="listingComponent.goOnFirstPage()"
  [tab]="tab"
  [stateEntry]="stateEntry"
></app-company-filter>

<div class="popup-close">
  <button mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>
<app-listing
  [dataSource]="companyList"
  [displayedColumns]="displayedColumns"
  [page]="page"
  [itemsPerPage]="itemsPerPage"
  [itemsCount]="companyListCount"
  [selectedItems]="selectedCompanyList"
  [showSelectedItems]="showSelectedCompanyList"
  [loading]="loading"
  [listingType]="listingType"
  [stateEntry]="stateEntry"
  [tab]="tab"
  [isViewer]="userManagementService.isViewer"
  [shouldShowDeleteBtn]="shouldShowDeleteBtn && !isInPicker"
  [canAddTags]="canAddTags"
  [navigateToDetails]="!isInPicker ? openUserDetails.bind(this) : null"
  [handleRow]="enableHandleRow"
  [handleRowAction]="isInPicker ? openContactsInCompany.bind(this) : null"
  [deleteItem]="performUserDeletion.bind(this)"
  [additionalSelectedItemsActions]="isInPicker ? additionalSelectedItemsActions : null"
  [singleValueSelect]="singleValueSelect"
  [isInPicker]="isInPicker"
  [showCheckbox]="showCheckBox"
  [shouldShowTooltip]="shouldShowTooltip"
  [tooltipMessage]="tooltipMessage"
  [actionItems]="actionItems"
>
</app-listing>
