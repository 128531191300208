import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PDFDialogData } from 'src/app/order-management/orders/order-edit/order-edit.component';

@Component({
  selector: 'app-file-dialog',
  templateUrl: './file-dialog.component.html',
  styleUrls: ['./file-dialog.component.scss'],
})
export class FileDialogComponent implements OnInit {
  pdfLink: string;
  pdfName: string;
  nameEditMode: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<FileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PDFDialogData,
  ) {}

  ngOnInit(): void {
    this.pdfName = this.data.file.fileName;
    this.pdfLink = `data:application/pdf;base64,${this.data.file.base64}`;
  }

  changeName() {
    this.nameEditMode = false;
  }

  resetName() {
    this.pdfName = this.data.file.fileName;
    this.nameEditMode = false;
  }

  downloadPdf() {
    const link = document.createElement('a');
    link.href = this.pdfLink;
    link.download = this.pdfName;
    link.click();
    link.remove();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
