import { Component, Inject, OnInit, QueryList, Type, ViewChildren } from '@angular/core';
import { MatTab } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { CompaniesComponent } from '../users/tabs/companies/companies.component';
import * as UsersActions from './store/users/users.actions';
import * as fromApp from '../../store/app.reducer';
import { globals } from '../../shared/globals';
import { Store } from '@ngrx/store';
import { RoleNames, UserStateEntry, UserTypes, UserTypesPlural } from '../../shared/api/types/enums';
import { UsersService } from './users.service';
import { Subject, map, takeUntil } from 'rxjs';
import { GqlErrorService } from '../../shared/services/gql-error.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CreateCompanyInput, CreateContactInput, CustomerInput, RoleAccess } from '../../shared/api/types/GraphQL';
import { UsergroupsComponent } from './usergroups/usergroups.component';
import { QueryParamService } from 'src/app/shared/services/query-param.service';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  @ViewChildren(MatTab) tabs: QueryList<MatTab>;

  usersTabsLoaded: { [key: number]: boolean } = [];
  headerButton: { title: string; disabled?: boolean } = {
    title: 'New company',
  };

  selectedTab: number = 0;
  selectedTabName: UserTypesPlural = UserTypesPlural.companies;
  isLoading: boolean = false;
  stateEntry: UserStateEntry = UserStateEntry.userListing;
  page: number;
  itemsPerPage: number;

  isViewer: boolean = false;
  isPicker: boolean = false;

  enablePickerView: boolean = false;
  singleValueSelect: boolean = false;

  shopId = globals.shopId;
  userRootId: number;
  canAddTags: boolean = true;

  readonly UserTypes = UserTypes;

  private destroySubject: Subject<void> = new Subject();

  constructor(
    private store: Store<fromApp.AppState>,
    private usersService: UsersService,
    private errorService: GqlErrorService,
    private dialog: MatDialog,
    public router: Router,
    private queryParamService: QueryParamService,
    public usersDialogRef: MatDialogRef<UsersComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      singleValue: boolean;
      displayedTabs: UserTypes[];
      disableTab: UserTypes[];
      enableOrderListView: boolean;
    },
    private appService: AppService,
  ) {}

  ngOnInit(): void {
    // this.appService
    //   .getShop(this.shopId)
    //   .valueChanges.pipe(
    //     take(1),
    //     map((data) => data.data.shop),
    //   )
    //   .subscribe((data) => {
    //     this.userRootId = data.userRootId;
    //   });

    if (this.usersDialogRef.id) {
      this.isPicker = true;
      this.stateEntry = UserStateEntry.userPicking;
      this.enablePickerView = true;
      this.store.dispatch(new UsersActions.ResetStateEntryState(UserStateEntry.userPicking));
    }
    if (this.dialogData) {
      this.singleValueSelect = this.dialogData.singleValue;
      if (this.dialogData.enableOrderListView) {
        this.canAddTags = false;
      }
    }
    this.store
      .select((state) => state.auth.roles)
      .pipe(takeUntil(this.destroySubject))
      .subscribe((roles) => {
        if (roles.length) {
          const userRole = roles.find((role) => role.name === RoleNames.user);
          if (userRole && userRole.access === RoleAccess.Viewer) this.isViewer = true;
          else if (userRole && userRole.access !== RoleAccess.Viewer) this.isViewer = false;
        }
      });
    const referringUrl = this.usersService.getReferringUrl();
    if (referringUrl) {
      this.router.navigateByUrl(referringUrl);
      this.usersService.setReferringUrl(null);
    } else {
      this.queryParamService.handleQueryParams(new UsersActions.RefetchUsers({ stateEntry: this.stateEntry }));
    }
    if (!isNaN(this.queryParamService.selectedTab)) {
      this.selectedTab = this.queryParamService.selectedTab;
      this.defineHeaderButton(this.selectedTab);
    }
    this.store.dispatch(new UsersActions.TabChange({ tab: this.selectedTabName, stateEntry: this.stateEntry }));
  }

  tabChange(event: number) {
    this.defineHeaderButton(event);
    this.store.dispatch(new UsersActions.TabChange({ tab: this.selectedTabName, stateEntry: this.stateEntry }));
  }

  openDialog() {
    let componentToOpen: Type<UsergroupsComponent | CompaniesComponent> = CompaniesComponent;
    if (this.selectedTabName !== UserTypesPlural.contacts) {
      componentToOpen = UsergroupsComponent;
    }

    const dialogConfig = this.dialog.open(componentToOpen, {
      width: '75vw',
      height: '75vh',
      maxWidth: '90vw',
      maxHeight: '90vh',
      panelClass: 'dialog',
      data: { requestedFromDialog: true },
    });

    dialogConfig.componentInstance.dialogRef = dialogConfig as any;

    dialogConfig.afterClosed().subscribe((result) => {
      if (result != undefined && this.selectedTabName == UserTypesPlural.contacts) {
        this.createEntity(result.companyId);
        this.fetchContactsPageInfoAndLoadUsers();
      }
    });
  }

  fetchContactsPageInfoAndLoadUsers() {
    this.store
      .select((store) => store.users[this.stateEntry].users.contacts)
      .pipe(takeUntil(this.destroySubject))
      .subscribe(({ currentPage, currentPageSize }) => {
        this.page = currentPage;
        this.itemsPerPage = currentPageSize;
      });
    let variables = { page: this.page, offset: this.itemsPerPage };
    this.store.dispatch(new UsersActions.TabChange({ tab: UserTypesPlural.contacts, stateEntry: this.stateEntry }));
    this.store.dispatch(
      new UsersActions.LoadUsers({ variables, stateEntry: this.stateEntry }, UserTypesPlural.contacts),
    );
  }

  headerButtonClick() {
    if (this.selectedTabName == UserTypesPlural.contacts) {
      this.openDialog();
    } else {
      // this.createEntity(this.userRootId)
      this.createEntity(106);
    }
  }

  private createEntity(id: number): void {
    const currentUrl = this.router.url;
    let newEntity: CreateCompanyInput | CreateContactInput | CustomerInput;
    this.isLoading = true;
    newEntity = {
      name: this.selectedTabName === UserTypesPlural.companies ? this.headerButton.title : undefined,
      firstName: this.selectedTabName !== UserTypesPlural.companies ? this.headerButton.title : undefined,
      lastName: this.selectedTabName !== UserTypesPlural.companies ? '' : undefined,
      parentId: id,
    };
    const userType =
      this.selectedTabName == UserTypesPlural.companies ? UserTypes.company : this.selectedTabName.slice(0, -1);
    this.usersService
      .createUser(this.selectedTabName, newEntity)
      .pipe(
        map((data) => {
          return data.data[`${userType}Create`];
        }),
      )
      .subscribe({
        next: (user) => {
          const userId = user[`${userType}Id`];
          this.router.navigateByUrl(`${currentUrl}/details/${userType}/${userId}`);
          this.isLoading = false;
        },
        error: (error) => {
          this.errorService.getGqlError(error);
          this.isLoading = false;
        },
      });
  }
  private defineHeaderButton(tabNumber: number) {
    switch (tabNumber) {
      case 0:
        this.headerButton = { title: 'New company' };
        this.selectedTabName = UserTypesPlural.companies;
        break;
      case 1:
        this.headerButton = { title: 'New contact' };
        this.selectedTabName = UserTypesPlural.contacts;
        break;
      case 2:
        this.headerButton = { title: 'New customer' };
        this.selectedTabName = UserTypesPlural.customers;
        break;
      case 3:
        this.headerButton = { title: 'New employee', disabled: true };
        break;
    }
  }

  closeDialogEmpty() {
    this.usersDialogRef.close(false);
  }

  closeDialog() {
    const dialogData = {
      selectedUserType: this.selectedTabName,
    };
    this.usersDialogRef.close(dialogData);
  }

  closeDialogWithUserId(id: number) {
    //this.productsDialogRef.close(id);
  }

  handleAction() {
    this.closeDialog();
  }
  ngOnDestroy() {
    this.destroySubject.next();
  }
}
