import { Injectable } from '@angular/core';
import { User } from './auth-user';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore'; // TODO: REMOVE NOT USED/NEEDED!
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as fromApp from '../store/app.reducer';
import * as AuthActions from './store/auth.actions';
import { Action, Store } from '@ngrx/store';
import { Tenant } from './store/auth.reducer';
import { RoleAccess } from '../shared/api/types/GraphQL';
import { RoleNames } from '../shared/api/types/enums';
import { JwtRole } from '../shared/api/types/types';
import { Observable, from, of, switchMap } from 'rxjs';
import {
  getAuth,
  getMultiFactorResolver,
  MultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier, // TODO: ACTUALLY USE THE RECAPTCHA VERIFIER FROM APP CHECK NOT THE BUILD-IN ONE!
  UserCredential,
  RecaptchaParameters,
} from '@angular/fire/auth';
import { getApp } from '@angular/fire/app';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: any;
  roles: JwtRole[];
  hasSalesRole: boolean = false;
  hasBackofficeRole: boolean = false;
  auth = getAuth(getApp('[DEFAULT]'));
  recaptchaVerifier: RecaptchaVerifier;
  recaptchaWidgetId: number | null = null;
  verificationId: string;
  resolver: MultiFactorResolver;
  persistentLogin: boolean = false;

  constructor(
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    //private apollo: Apollo,
  ) {
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.user = JSON.parse(JSON.stringify(user));
        this.store.dispatch(new AuthActions.AutoLogin(this.user));

        user.getIdTokenResult().then((idTokenResult) => {
          if (idTokenResult.claims) {
            const { claims, firebase } = idTokenResult.claims;
            const [claimKey]: any = Object.values(claims);

            if (firebase.tenant) this.store.dispatch(new AuthActions.SetAdminTenant(firebase.tenant));
            if (claimKey.roles) {
              const roles = this.convertTokenRolesToObjects(claimKey.roles);
              this.store.dispatch(new AuthActions.SetViewerRoles(roles));
            }
          }
        });
      } else {
        this.store.dispatch(new AuthActions.LogoutStart());
      }
    });
    this.store.select((state) => state.auth.roles).subscribe((roles) => (this.roles = roles));
    // this.recaptchaVerifier = this.initializeRecaptchaVerifier(); // TODO: this one is failing on init if done like this in constructor!
  }

  getTenants(user) {
    if (user) {
      const headerDict = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        apikey: environment.propeller.api.key,
        Authorization: `Bearer ${user.stsTokenManager?.accessToken}`,
      };
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };
      return this.http.get(environment.propeller.rest.url + 'users/' + user.email, requestOptions);
    }
  }

  getToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.afAuth.onAuthStateChanged((user) => {
        if (user) {
          user
            .getIdToken()
            .then((idToken) => {
              resolve(idToken);
            })
            .catch((error) => reject(error));
        } else {
          reject('No user logged in');
        }
      });
    });
  }

  getActiveTenant(): Promise<Tenant> {
    return new Promise((resolve, reject) => {
      this.store.select('auth').subscribe((authState) => {
        if (!!authState.activeTenant) {
          resolve(authState.activeTenant.tenant);
        } else {
          reject('No active tenant');
        }
      });
    });
  }

  refreshToken(): Observable<any> {
    return from(this.afAuth.currentUser).pipe(
      switchMap((user) => {
        return from(user.getIdTokenResult(true));
      }),
    );
  }

  checkAnyUserRole(routeData: RoleNames | RoleNames[]): boolean {
    if (Array.isArray(routeData)) {
      return routeData.some((roleName) => this.checkUserRole(roleName));
    } else {
      return this.roles.some((role) => role.name === routeData && role.access !== RoleAccess.None);
    }
  }

  checkUserRole(routeData: RoleNames | RoleNames[]): boolean {
    if (Array.isArray(routeData)) {
      return routeData.every((roleName) =>
        this.roles.some((role) => role.name === roleName && role.access !== RoleAccess.None),
      );
    } else {
      return this.roles.some((role) => role.name === routeData && role.access !== RoleAccess.None);
    }
  }

  checkUserAccess(routeData: RoleNames | RoleNames[]): boolean {
    if (Array.isArray(routeData)) {
      return routeData.every((roleName) =>
        this.roles.some(
          (role) => role.name === roleName && (role.access === RoleAccess.Owner || role.access === RoleAccess.Editor),
        ),
      );
    } else {
      return this.roles.some(
        (role) => role.name === routeData && (role.access === RoleAccess.Owner || role.access === RoleAccess.Editor),
      );
    }
  }

  convertTokenRolesToObjects(rolesArr) {
    const rolesObjects = [];

    rolesArr.forEach((item) => {
      const parts = item.split('.');
      const roleObject: JwtRole = {
        name: parts.length > 1 ? RoleNames[parts[0] as keyof typeof RoleNames] : parts[0],
        access: parts.length > 1 ? RoleAccess[parts[1].charAt(0) + parts[1].slice(1).toLowerCase()] : null,
      };

      rolesObjects.push(roleObject);
    });

    return rolesObjects;
  }

  public async signInWithEmailAndPassword(email: string, password: string, persistentLogin: boolean): Promise<any> {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then(async (result) => {
        const userCredential = result as unknown as UserCredential;
        return this.signInUserCredential(userCredential, persistentLogin);
      })
      .catch(async (error) => {
        // TODO: EXTRACT TO CONSTANT/ENUM or import from firebase errors lib. if available...
        if (error.code === 'auth/multi-factor-auth-required') {
          // The user is a multi-factor user. Second factor challenge is required.
          // ...
          this.resolver = getMultiFactorResolver(this.auth, error); // TODO: RENAME THIS VARIABLE, TOO VAGUE... RESOLVES WHAT?

          if (this.resolver.hints.length > 1) {
            //  then go to mfa selection
          } else {
            // send sms,go to mfa input
            const phoneInfoOptions = {
              multiFactorHint: this.resolver.hints[0],
              session: this.resolver.session,
            };
            const verifier = this.recaptchaVerifier; // TODO: [POTENTIAL UNDEFINED] not sure that this will always be initialized here... it is initialized in the sing-in component after view init not in this service constructor...
            const phoneAuthProvider = new PhoneAuthProvider(this.auth);
            return phoneAuthProvider
              .verifyPhoneNumber(phoneInfoOptions, verifier)
              .then((verificationId) => {
                this.store.dispatch(new AuthActions.LoginVerificationStart());
                this.verificationId = verificationId;
                this.persistentLogin = persistentLogin;

                return new AuthActions.WaitForVerificationCode();
              })
              .catch((mfaError) => {
                // Handle the phone verification error
                return new AuthActions.LoginError(mfaError);
              });
          }
        } else {
          // Handle other errors such as wrong password.
          return new AuthActions.LoginError(error);
        }
      });
  }

  public verifyMFACode(verificationCode: string) {
    const cred = PhoneAuthProvider.credential(this.verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

    return this.resolver
      .resolveSignIn(multiFactorAssertion)
      .then((userCredentials) => {
        this.signInUserCredential(userCredentials, this.persistentLogin);
      })
      .catch((error) => {
        this.store.dispatch(new AuthActions.LoginError(error));
      });
  }

  public signInUserCredential(userCredential: UserCredential, persistentLogin: boolean): Action {
    const user = JSON.parse(JSON.stringify(userCredential)); // THE FUCK, also this is userCredential not user, and all typing is lost...

    // TODO: don't know what this is but it smells like an external helper function...
    if (
      localStorage.getItem('appVersion') === null ||
      (!!localStorage.getItem('appVersion') && +localStorage.getItem('appVersion') !== environment.appVersion)
    ) {
      localStorage.clear();
      this.store.dispatch(new AuthActions.ClearState());
    }
    localStorage.setItem('appVersion', environment.appVersion.toString());

    if (persistentLogin) {
      localStorage.setItem('persistentLogin', 'true');
    }

    return new AuthActions.Login(user);
  }

  initializeRecaptchaVerifier() {
    try {
      return new RecaptchaVerifier(this.auth, 'recaptcha-container', {
        size: 'invisible',
        callback: (data) => {
          console.log('[recaptcha-container] recaptcha solved');
          console.log({ data });
        },
        onerror: (e) => {
          throw new Error(`[recaptcha-container] Error in reCAPTCHA initialization: ${e.message}`);
        },
      });
    } catch (error) {
      console.error('[initializeRecaptchaVerifier] Error initializing reCAPTCHA');
      console.error(error);
    }
  }
}
