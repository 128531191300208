import { Company, CompanySearch, CompanySearchInput } from 'src/app/shared/api/types/GraphQL';
import * as CompaniesActions from './companies.actions';

type CompanyOrSearch = Company[] | CompanySearch[];

export interface State {
  loading: boolean;
  loaded: boolean;
  companies: CompanyOrSearch;
  currentPage: number;
  currentPageSize: number;
  companiesCount: number;
  error: Error;
  generalFilterValue: string;
  selectedCompaniesList: Company[];
  companiesTableColumns: {};
  companiesFilters: CompanySearchInput;
  refetchType: string;
}

const initialState: State = {
  loading: false,
  loaded: false,
  companies: [],
  currentPage: 1,
  currentPageSize: 5,
  companiesCount: 0,
  error: undefined,
  generalFilterValue: '',
  selectedCompaniesList: [],
  companiesTableColumns: {
    displayedColumns: [
      'select',
      'companyId',
      'name',
      'tags',
      'phoneNumber',
      'location',
      'billingAddress',
      'dateCreated',
    ],
    hiddenColumns: ['select'],
    disabledSuffixLength: 0,
    availableColumns: [],
    disabledColumns: ['companyId', 'name'],
  },
  companiesFilters: {},
  refetchType: null,
};

export function companiesReducer(state: State = initialState, action: CompaniesActions.CompaniesActions) {
  switch (action.type) {
    case CompaniesActions.GET_GENERAL_FILTER:
      return {
        ...state,
        generalFilterValue: action.payload,
      };
    case CompaniesActions.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case CompaniesActions.GET_CURRENT_PAGE_SIZE:
      return {
        ...state,
        currentPageSize: action.payload,
      };
    case CompaniesActions.GET_COMPANIES_COUNT:
      return {
        ...state,
        companiesCount: action.payload,
      };
    case CompaniesActions.LOAD_COMPANIES:
      let loading;
      state.loaded ? (loading = false) : (loading = true);
      return {
        ...state,
        loading: loading,
      };
    case CompaniesActions.REFETCH_COMPANIES:
      return {
        ...state,
        loaded: false,
        loading: true,
        refetchType: action.type,
      };
    case CompaniesActions.REFETCH_COMPANY_SEARCH:
      return {
        ...state,
        loaded: false,
        loading: true,
        refetchType: action.type,
      };
    case CompaniesActions.LOAD_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: [...action.payload],
        loading: false,
        loaded: true,
      };
    case CompaniesActions.LOAD_COMPANIES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case CompaniesActions.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case CompaniesActions.ADD_COMPANY:
      return {
        ...state,
        companies: [...state.companies, action.payload],
      };
    case CompaniesActions.ADD_COMPANY_TO_LIST:
      if (state.selectedCompaniesList.map((x) => x.companyId).indexOf(action.payload.companyId) === -1) {
        return {
          ...state,
          selectedCompaniesList: [...state.selectedCompaniesList, action.payload],
        };
      } else {
        return {
          ...state,
        };
      }
    case CompaniesActions.DELETE_COMPANY_FROM_LIST:
      if (state.selectedCompaniesList.map((x) => x.companyId).indexOf(action.payload) !== -1) {
        const index = state.selectedCompaniesList.map((x) => x.companyId).indexOf(action.payload);
        return {
          ...state,
          selectedCompaniesList: state.selectedCompaniesList.filter((product, i) => {
            return i !== index;
          }),
        };
      } else {
        return {
          ...state,
        };
      }
    case CompaniesActions.CLEAR_COMPANIES_LIST:
      return {
        ...state,
        selectedCompaniesList: [],
      };
    case CompaniesActions.COMPANIES_TABLE_COLUMNS:
      return {
        ...state,
        companiesTableColumns: action.payload,
      };

    case CompaniesActions.ADD_GENERAL_FILTER:
      return {
        ...state,
        companiesFilters: { ...state.companiesFilters, ...action.payload },
      };

    case CompaniesActions.REMOVE_GENERAL_FILTER:
      const { columnName, index } = action.payload;

      // Clone the state's companiesFilters object to prevent mutation
      const updatedFilters = { ...state.companiesFilters };

      // Check if companiesFilters[columnName] is an array
      if (Array.isArray(updatedFilters[columnName])) {
        // If it's an array, clone the array, remove the item at the specified index, and update the companiesFilters object
        updatedFilters[columnName] = [...updatedFilters[columnName]];
        updatedFilters[columnName].splice(index, 1);
        if (updatedFilters[columnName].length === 0) {
          delete updatedFilters[columnName];
        }
      } else {
        // If it's not an array, simply delete the key corresponding to columnName
        delete updatedFilters[columnName];
      }

      return {
        ...state,
        companiesFilters: updatedFilters,
      };

    default:
      return state;
  }
}
