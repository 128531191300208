import { gql } from 'apollo-angular';
import { AdminUserFragments } from '../fragments/admin-user-fragments';

export const AdminUserList = gql`
  query AdminUserList($input: AdminUsersSearchInput) {
    adminUsers(input: $input) {
      page
      itemsFound
      offset
      items {
        id
        email
        firstName
        middleName
        lastName
        createdAt
        lastModifiedAt
      }
    }
  }
`;

export const AdminUser = gql`
  query AdminUser($email: String!) {
    adminUser(email: $email) {
      ...adminUser
    }
  }
  ${AdminUserFragments.adminUser}
`;
