import { gql } from 'apollo-angular';
import { globals } from 'src/app/shared/globals';

export const TenderAddressFragment = gql`
  fragment TenderAddressFields on TenderAddress {
    city
    code
    company
    country
    email
    fax
    firstName
    gender
    icp
    lastName
    middleName
    mobile
    notes
    number
    numberExtension
    phone
    postalCode
    region
    street
    url
  }
`;

export const TenderFragments = {
  data: gql`
    fragment TenderData on Tender {
      actionCode
      creditPoints
      createdAt
      debtorId
      isEditable
      email
      externalId
      extra3
      extra4
      firstName
      lastName
      middleName
      invoiceUserId
      itemCount
      orderId
      incentivesApplied
      reference
      remarks
      shopId
      siteId
      source
      status
      tenderId
      userId
      valuePoints
      validUntil
    }
  `,
  user: gql`
    fragment TenderUser on IBaseUser {
      firstName
      middleName
      lastName
      email
      phone
      ... on Contact {
        contactId
        company {
          companyId
          name
          addresses {
            firstName
            middleName
            lastName
            company
            email
            code
            city
            country
            gender
            icp
            isDefault
            number
            numberExtension
            mobile
            phone
            postalCode
            region
            street
            type
          }
        }
      }
      ... on Customer {
        customerId
        addresses {
          id
          firstName
          middleName
          lastName
          company
          email
          code
          city
          country
          gender
          icp
          isDefault
          number
          numberExtension
          mobile
          phone
          postalCode
          region
          street
          type
        }
      }
    }
  `,
  company: gql`
    fragment TenderCompany on Company {
      companyId
      addresses {
        city
        code
        company
        country
        email
        firstName
        gender
        icp
        lastName
        middleName
        mobile
        notes
        number
        numberExtension
        phone
        postalCode
        region
        street
        type
      }
    }
  `,
  address: gql`
    fragment TenderAddress on TenderAddress {
      city
      code
      company
      country
      email
      firstName
      gender
      icp
      lastName
      middleName
      mobile
      notes
      number
      numberExtension
      phone
      postalCode
      region
      street
    }
  `,
  paymentData: gql`
    fragment TenderPayment on TenderPayment {
      accountingId
      method
      overruled
      price
      priceNet
      status
      statusDate
      tax
      taxPercentage
      # transactionId # TODO: TEMP REMOVED - UNTIL FURTHER NOTICE
    }
  `,
  postageData: gql`
    fragment TenderPostage on TenderPostage {
      carrier
      method
      overruled
      partialDeliveryAllowed
      pickUpLocationId
      price
      priceNet
      requestDate
      taxPercentage
      # trackTrace # TODO: TEMP REMOVED - UNTIL FURTHER NOTICE
      warehouse {
        name
      }
    }
  `,
  total: gql`
    fragment TenderTotal on TenderTotal {
      discountGross
      discountNet
      discountOverruled # TODO: remove, deprecated field!
      discountPercentage
      discountType
      subTotalGross
      subTotalNet
      totalGross
      totalNet
      totalTax
    }
  `,
  items: gql`
        fragment TenderMainItem on TenderMainItem {
            attributedSaleDiscount
            attributedSaleDiscountSum
            attributedSaleMargin
            attributedSaleMarginSum
            costPrice
            customerDiscountPercentage
            customerPrice
            discountPercentage
            expectedDeliveryDate
            externalOrderitemId
            id
            incentive {
              name
              quantity
              price
              code
            }
            uuid
            marginAmount
            marginPercentage
            name
            notes
            orderItemId
            originalPrice
            price
            priceNet
            product {
              productId
              hasBundle
              isBundleLeader
              bundles {
                  id
                  name
                  discount
                  items {
                    productId
                  }
              }
              cluster {
                  class
                  clusterId
                  names (language: "${globals.appLanguage}") {
                    value
                  }
                  sku
                  options {
                    isRequired
                  }
              }
              media {
                images(search: {sort: ASC, offset: 1}) {
                  items {
                  alt(language: "NL") {
                      value
                  }
                  tags(language: "NL") {
                      values
                  }
                  imageVariants(
                      input: {
                      transformations: {
                          name: "medium"
                          transformation: { width: 50, height: 50, fit: BOUNDS, bgColor: "transparent", canvas: {width: 50, height: 50 }}
                      }
                      }
                  ) {
                      url
                    }
                  }
                }
              }
            }
            productId
            quantity
            saleMarginAmount
            saleMarginAmountSum
            saleMarginPercentage
            saleMarginPercentageSum
            sku
            sum
            sumNet
            supplier
            supplierCode
            surcharges {
              names {
                language
                value
              }
              taxCode
              taxPercentage
              quantity
              price
              totalPrice
              priceNet
              totalPriceNet
            }
            taxCode
            taxPercentage
            totalDiscountPercentage
            totalDiscountPercentageSum
            totalPrice
            totalPriceNet
            totalSum
            totalSumNet
            valuePoints
        }
    `,
  childItems: gql`
    fragment TenderChildItems on ITenderBaseItem {
      attributedSaleDiscount
      attributedSaleMargin
      costPrice
      customerDiscountPercentage
      customerPrice
      discountPercentage
      expectedDeliveryDate
      externalOrderitemId
      id
      uuid
      marginAmount
      marginPercentage
      name
      notes
      orderItemId
      originalPrice
      price
      priceNet
      productId
      quantity
      saleMarginAmount
      saleMarginPercentage
      sku
      supplier
      supplierCode
      taxCode
      taxPercentage
      totalDiscountPercentage
      totalPrice
      totalPriceNet
      valuePoints
    }
  `,
  carriers: gql`
    fragment TenderCarrier on TenderCarrier {
      name
      description
      logo
      amount
    }
  `,
  paymethods: gql`
    fragment TenderPaymethod on TenderPaymethod {
      code
      externalCode
      type
      description
      amount
      allowed
    }
  `,
};
