import { ActionReducer } from '@ngrx/store';
import { localStorageSync, rehydrateApplicationState } from 'ngrx-store-localstorage';
import { AppState } from '../../store/app.reducer';
import * as LocalStorageActions from './actions';

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state: AppState, action: LocalStorageActions.Storage) => {
    const keys = ['quotes', 'orders', 'requests', 'auth', 'tableColumns'];

    //let keyExists = false;
    // keys.forEach(obj => {
    //   if (action.payload in obj) {
    //     keyExists = true;
    //     return
    //   }
    // })
    //{products: ['productListProducts', 'productTableColumns']},

    if (action.type === LocalStorageActions.STORAGE && keys.includes(action.payload)) {
      const rehydratedState = rehydrateApplicationState([action.payload], localStorage, (k) => k, true);
      return { ...state, ...rehydratedState };
    }

    return localStorageSync({
      keys,
      rehydrate: true,
    })(reducer)(state, action);
  };
}
