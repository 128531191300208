import { gql } from 'apollo-angular';

export const ValueSetItemCreate = gql`
  mutation ValueSetItemCreate($input: ValuesetItemCreateInput!) {
    valuesetItemCreate(input: $input) {
      id
      valuesetId
      value
      descriptions {
        language
        value
      }
      extra
    }
  }
`;
export const ValueSetItemUpdate = gql`
  mutation ValueSetItemUpdate($id: Int!, $input: ValuesetItemUpdateInput!) {
    valuesetItemUpdate(id: $id, input: $input) {
      id
      valuesetId
      value
      descriptions {
        language
        value
      }
      extra
    }
  }
`;
export const ValueSetItemDelete = gql`
  mutation ValueSetItemDelete($id: Int!) {
    valuesetItemDelete(id: $id)
  }
`;
