<div class="amp-app" [ngClass]="{ 'component-dialog': contactsInCompanyDialog }">
  <div class="popup-close" *ngIf="contactsInCompanyDialog">
    <button mat-icon-button (click)="closeDialogEmpty()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="header-wrapper">
    <div class="title-wrapper">
      <button
        *ngIf="contactsInCompanyDialog"
        mat-icon-button
        matTooltip="Back to list"
        matTooltipPosition="above"
        (click)="backToListing()"
      >
        <mat-icon matPrefix>arrow_back</mat-icon>
      </button>
      <div class="mat-headline-6 section-header">
        {{ contactsInCompanyDialog ? 'Contacts at ' + companyName : 'Contacts list' }}
      </div>
    </div>
    <div>
      <button
        class="create-button"
        mat-flat-button
        color="primary"
        (click)="createContact()"
        *ngIf="!isViewer && !contactsInCompanyDialog"
      >
        Add new contact
      </button>
      <button
        class="create-button"
        mat-flat-button
        color="primary"
        (click)="openAssignUsersDialog()"
        *ngIf="!isViewer && !contactsInCompanyDialog"
      >
        {{ buttonText }}
      </button>
    </div>
  </div>

  <div class="table-wrapper">
    <div class="btn-wrapper">
      <div class="mat-body-strong product-total" *ngIf="!loading && !selectedContactsList.length">
        {{ totalItems }} Contacts
      </div>
      <div mat-button *ngIf="!loading && !showSelectedItems && selectedContactsList.length" class="mat-body-strong">
        Selected
        <span (click)="!singleValueSelect ? (showSelectedItems = !showSelectedItems) : null" class="table-switch">
          {{ selectedContactsList.length }} items:
        </span>
      </div>
      <ngx-skeleton-loader
        count="1"
        [theme]="{ width: '100px', height: '22px', 'border-radius': '4px' }"
        *ngIf="loading"
      >
      </ngx-skeleton-loader>
      <div
        mat-button
        *ngIf="showSelectedItems"
        class="mat-body-strong"
        (click)="showSelectedItems = !showSelectedItems"
        class="table-switch"
      >
        Back to full list:
      </div>
      <button
        mat-stroked-button
        color="primary"
        *ngIf="selectedContactsList.length && !contactsInCompanyDialog"
        (click)="deleteMultipleItems()"
        [disabled]="isViewer"
      >
        Delete
      </button>
      <button
        mat-stroked-button
        color="primary"
        *ngIf="selectedContactsList.length"
        (click)="unassignMultipleItems()"
        [disabled]="isViewer"
      >
        Unassign from Company
      </button>
      <app-tags-add
        *ngIf="selectedContactsList.length && !contactsInCompanyDialog"
        [selectedItems]="selectedContactsList"
        (clearSelectionAndHideItems)="clearContactListAndRefetch()"
      >
      </app-tags-add>
      <button
        class="create-button"
        mat-flat-button
        color="primary"
        (click)="addUsers()"
        *ngIf="selectedContactsList.length && contactsInCompanyDialog"
      >
        Add users
      </button>
    </div>

    <div>
      <table
        mat-table
        class="main-list-table full"
        [dataSource]="!showSelectedItems ? (loading ? dataSourceLoading : dataSourceContact) : selectedContactsList"
        matTableExporter
        #exporter="matTableExporter"
      >
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <ng-container>
              <mat-checkbox
                (change)="masterCheckboxChange(!showSelectedItems ? dataSourceContact : selectedContactsList, $event)"
                [checked]="isAllInBasket(dataSourceContact, selectedContactsList)"
                [disabled]="isViewer"
                color="primary"
                *ngIf="!loading && !showSelectedItems && !singleValueSelect"
              >
              </mat-checkbox>
            </ng-container>
          </th>

          <td mat-cell *matCellDef="let row">
            <ng-container>
              <mat-checkbox
                *ngIf="!loading && !singleValueSelect"
                (click)="$event.stopPropagation()"
                [checked]="checkIfSelected(row, selectedContactsList)"
                (change)="checkboxChange(row, $event)"
                color="primary"
              >
              </mat-checkbox>
              <ngx-skeleton-loader
                count="1"
                *ngIf="loading"
                [theme]="{ height: '24px', display: 'flex', 'margin-bottom': '0' }"
              >
              </ngx-skeleton-loader>
            </ng-container>
          </td>
        </ng-container>

        <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column.name">
          <th mat-header-cell *matHeaderCellDef>
            <span>{{ column.label }}</span>
          </th>

          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="column.type === TableColumnType.array">
              <mat-chip-set *ngIf="(column | content: element).length">
                <mat-chip *ngFor="let item of column | content: element" (click)="$event.stopPropagation()">
                  <span class="mat-caption chip-item">{{ item }}</span>
                </mat-chip>
              </mat-chip-set>
            </ng-container>

            <span *ngIf="!column.type && !loading">{{ column | content: element }}</span>
            <ngx-skeleton-loader
              count="1"
              *ngIf="loading"
              [theme]="{
                height: '24px',
                display: 'flex',
                'margin-bottom': '0'
              }"
            >
            </ngx-skeleton-loader>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnNames"></tr>

        <tr mat-row *matRowDef="let row; columns: columnNames" (click)="onRowClick(row)"></tr>
      </table>
      <div class="loader" *ngIf="createLoading">
        <mat-spinner></mat-spinner>
      </div>
    </div>
    <mat-paginator
      [length]="totalItems"
      [pageSize]="itemsPerPage"
      [pageSizeOptions]="[5, 10, 25, 100]"
      aria-label="Select page of contacts"
      [style.display]="showSelectedItems ? 'none' : 'block'"
    >
    </mat-paginator>
  </div>
</div>
