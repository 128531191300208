import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';

import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { getApp, provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { initializeAppCheck, ReCaptchaV3Provider, provideAppCheck } from '@angular/fire/app-check';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';

import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { GraphQLModule } from './graphql.module';
import { AngularMaterialModule } from './angular-material.module';
import { CoreModule } from './core.module';

import { AppComponent } from './app.component';
import { SideNavComponent } from './side-nav/side-nav.component';

import { SharedModule } from './shared/shared.module';
import * as fromLocalStorage from './shared/store/reducers';
import { AuthEffects } from './auth/store/auth.effects';

import { TableEditorDialogComponent } from './shared/components/table-editor-dialog/table-editor-dialog.component';
import { MessageDialogComponent } from './shared/components/message-dialog/message-dialog.component';
import { SnackBarComponent } from './shared/components/snack-bar/snack-bar.component';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';
import { FileDialogComponent } from './shared/components/file-dialog/file-dialog.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';

import * as fromApp from './store/app.reducer';
import { CustomLanguagesEffects } from './store/custom-languages-store/custom-languages.effects';

import { AttributeDescriptionsEffects } from './admin/attributes/store/attributes.effects';
import { BackofficeUsersEffects } from './admin/backoffice-users/store/backoffice-users.effects';
import { BusinessRulesEffects } from './business-rules/store/business-rules.effects';
import { CarriersEffects } from './order-management/carriers/store/carriers.effects';
import { CatalogEffects } from './catalog/store/catalog.effects';
import { CategoriesEffects } from './pim/categories/store/categories.effects';
import { CompaniesEffects } from './companies/store/companies/companies.effects';
import { FavoritesEffects } from './crm/favorites/store/favorites.effects';
import { MyCompaniesEffects } from './companies/store/my-companies/my-companies.effects';
import { OrderlistsEffects } from './crm/orderlists/store/orderlists.effects';
import { OrdersEffects } from './order-management/orders/store/orders/orders.effects';
import { OrderStatusEffects } from './order-management/order-status/store/order-status/order-status.effects';
import { OrderStatusSetEffects } from './order-management/order-status/store/order-status-set/order-status-set.effects';
import { PayMethodsEffects } from './order-management/paymethods/store/paymethods.effects';
import { PriceSheetEffects } from './pricing/pricing/pricesheets/store/price-sheet.effects';
import { ProductsEffects } from './pim/products/store/products.effects';
import { QuotesEffects } from './order-management/orders/store/quotes/quotes.effects';
import { RequestsEffects } from './order-management/orders/store/requests/requests.effects';
import { SurchargesEffects } from './pricing/surcharges/store/surcharges.effects';
import { TaxesEffects } from './pricing/taxes/store/taxes.effects';
import { UsersEffects } from './crm/users/store/users/users.effects';
import { ValueSetEffects } from './admin/value-set/store/value-set/value-set.effects';
import { ValueSetItemEffects } from './admin/value-set/store/value-set-item/value-set-item.effects';
import { WarehousesEffects } from './order-management/warehouses/store/warehouses.effects';

const metaReducers: Array<MetaReducer<any, any>> = [
  fromLocalStorage.localStorageSyncReducer,
  fromApp.resetAppStateOnLogout,
];

@NgModule({
  declarations: [
    AppComponent,
    SideNavComponent,
    TableEditorDialogComponent,
    MessageDialogComponent,
    SnackBarComponent,
    ConfirmDialogComponent,
    FileDialogComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    GraphQLModule,
    SharedModule,
    StoreModule.forRoot(fromApp.appReducer, { metaReducers }),
    EffectsModule.forRoot([
      QuotesEffects,
      OrdersEffects,
      RequestsEffects,
      ProductsEffects,
      CompaniesEffects,
      MyCompaniesEffects,
      CatalogEffects,
      AuthEffects,
      UsersEffects,
      BusinessRulesEffects,
      CarriersEffects,
      TaxesEffects,
      FavoritesEffects,
      OrderlistsEffects,
      WarehousesEffects,
      CategoriesEffects,
      ValueSetEffects,
      ValueSetItemEffects,
      PayMethodsEffects,
      SurchargesEffects,
      BackofficeUsersEffects,
      AttributeDescriptionsEffects,
      OrderStatusEffects,
      OrderStatusSetEffects,
      PriceSheetEffects,
      CustomLanguagesEffects,
    ]),
    StoreDevtoolsModule.instrument({ logOnly: environment.production }),
    FormsModule,
    provideFirebaseApp(() => initializeApp(environment.firebase, '[DEFAULT]')),
    provideAppCheck(() => {
      try {
        if (!environment.production) {
          (window as any).FIREBASE_APPCHECK_DEBUG_TOKEN = environment.appCheckDebugToken;
        }

        const appCheckInstance = initializeAppCheck(getApp('[DEFAULT]'), {
          provider: new ReCaptchaV3Provider(environment.recaptchaSiteKey),
          isTokenAutoRefreshEnabled: true,
        });

        return appCheckInstance;
      } catch (error) {
        console.error('[provideAppCheck] Error initializing Firebase App Check');
        console.error(error);
      }
    }),
    provideAuth(() => getAuth(getApp('[DEFAULT]'))),
    PdfViewerModule,
    NgxMatNativeDateModule,
    ReactiveFormsModule,
  ],
  providers: [{ provide: FIREBASE_OPTIONS, useValue: environment.firebase }],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(overlayContainer: OverlayContainer) {
    overlayContainer.getContainerElement().classList.add('theme-default', 'amp-app');
  }
}
