import { gql } from 'apollo-angular';
import { WarehouseFragments } from '../fragments/warehouse.fragments';

export const WarehousesQuery = gql`
  query warehouses($input: WarehousesSearchInput) {
    warehouses(input: $input) {
      items {
        id
        name
        description
      }
      itemsFound
      offset
      page
      pages
    }
  }
`;

export const WarehouseListQuery = gql`
  query warehouseList($input: WarehousesSearchInput) {
    warehouses(input: $input) {
      items {
        ...warehouseInList
      }
      itemsFound
      offset
      page
      pages
    }
  }
  ${WarehouseFragments.warehouseInListFragment}
`;

export const WarehouseQuery = gql`
query warehouse ($id: Int!) {
  warehouse(id: $id) {    
    address {
     ...address
    }
    ...allFields
  }
}
${WarehouseFragments.warehouseAllFields},
${WarehouseFragments.address},

`;
