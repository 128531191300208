import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { BusinessRuleDeleteResponse, BusinessRuleListResponse } from '../shared/api/types/types';
import { BusinessRulesListQuery } from '../shared/api/queries/rule-builder-queries';
import { BusinessRuleSearchInput } from '../shared/api/types/GraphQL';
import { BusinessRuleDeleteMutation } from '../shared/api/mutations/rule-builder-mutations';

@Injectable({ providedIn: 'root' })
export class BusinessRulesService {
  constructor(private apollo: Apollo) {}

  getBusinessRulesList(filter: BusinessRuleSearchInput): QueryRef<BusinessRuleListResponse> {
    return this.apollo.watchQuery<BusinessRuleListResponse>({
      query: BusinessRulesListQuery,
      variables: { filter },
      fetchPolicy: 'no-cache',
    });
  }

  businessRuleDelete(id: string) {
    return this.apollo.mutate<BusinessRuleDeleteResponse>({
      mutation: BusinessRuleDeleteMutation,
      variables: { id },
    });
  }
}
