import { Pipe, PipeTransform } from '@angular/core';
import { globals } from '../../../shared/globals';

@Pipe({
  name: 'regionName',
})
export class RegionNamePipe implements PipeTransform {
  private regionNames = new Intl.DisplayNames([globals.appLanguage], { type: 'region' });

  transform(countryCode: string): string {
    try {
      return this.regionNames.of(countryCode);
    } catch (error) {
      return countryCode;
    }
  }
}
