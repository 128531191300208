import { gql } from 'apollo-angular';

export const orderStatusFragments = {
  orderStatus: gql`
    fragment orderStatus on OrderStatus {
      id
      name
      code
      description
      orderType
      type
      priority
      isDefault
      isPublic
      isEditable
      isDeletable
      isExportable
      isConfirmable
      isArchivable
      createdAt
      lastModifiedAt
      nextStatuses {
        items {
          id
          name
        }
      }
      statusSet {
        id
        name
        description
      }
    }
  `,
  orderStatusTransition: gql`
    fragment orderStatusTransition on OrderStatusTransition {
      id
      name
      description
      allowBackward
    }
  `,
};
