import { Action } from '@ngrx/store';
import { AdminUser, AdminUsersSearchInput } from 'src/app/shared/api/types/GraphQL';

export const GET_CURRENT_PAGE = '[BackofficeUsers] Get Current Page';
export const GET_CURRENT_PAGE_SIZE = '[BackofficeUserListComponent] Get Current Page Size';
export const GET_BACKOFFICE_USERS_COUNT = '[BackofficeUserListComponent] Get BackofficeUsers Count';
export const LOAD_BACKOFFICE_USERS = '[BackofficeUsers] Load BackofficeUsers';
export const REFETCH_BACKOFFICE_USERS = '[BackofficeUsers] Refetch BackofficeUsers';
export const LOAD_BACKOFFICE_USERS_SUCCESS = '[BackofficeUsers] Load BackofficeUsers Success';
export const LOAD_BACKOFFICE_USERS_FAILURE = '[BackofficeUsers] Load BackofficeUsers Failure';

export const ADD_BACKOFFICE_USER = '[BackofficeUsers] Add BackofficeUser';
export const UPDATE_BACKOFFICE_USER = '[BackofficeUsers] Update BackofficeUser';
export const DELETE_BACKOFFICE_USER = '[BackofficeUsers] Delete BackofficeUser';

export const GET_GENERAL_FILTER = '[BackofficeUsers] Get General Filter';
export const ADD_SORT_FILTERS = '[BackofficeUsers] Add Sort Filters';

export const ADD_BACKOFFICE_USER_TO_LIST = '[BackofficeUsers] Add Selected BackofficeUser to List';
export const DELETE_BACKOFFICE_USER_FROM_LIST = '[BackofficeUsers] Delete BackofficeUser from List';
export const CLEAR_BACKOFFICE_USERS_LIST = '[BackofficeUsers] Clear BackofficeUsers List';

export class GetCurrentPage implements Action {
  readonly type = GET_CURRENT_PAGE;

  constructor(public payload: number) {}
}

export class GetCurrentPageSize implements Action {
  readonly type = GET_CURRENT_PAGE_SIZE;

  constructor(public payload: number) {}
}

export class GetBackofficeUsersCount implements Action {
  readonly type = GET_BACKOFFICE_USERS_COUNT;

  constructor(public payload: number) {}
}

export class LoadBackofficeUsers implements Action {
  readonly type = LOAD_BACKOFFICE_USERS;

  constructor(public payload: {}) {}
}

export class RefetchBackofficeUsers implements Action {
  readonly type = REFETCH_BACKOFFICE_USERS;

  constructor(public payload: {}) {}
}

export class LoadBackofficeUsersSuccess implements Action {
  readonly type = LOAD_BACKOFFICE_USERS_SUCCESS;

  constructor(public payload: AdminUser[]) {}
}

export class LoadBackofficeUsersFailure implements Action {
  readonly type = LOAD_BACKOFFICE_USERS_FAILURE;

  constructor(public payload: Error) {}
}

export class AddBackofficeUser implements Action {
  readonly type = ADD_BACKOFFICE_USER;

  constructor(public payload: AdminUser) {}
}

export class DeleteBackofficeUser implements Action {
  readonly type = DELETE_BACKOFFICE_USER;

  constructor(public payload: string) {}
}

export class AddBackofficeUserToList implements Action {
  readonly type = ADD_BACKOFFICE_USER_TO_LIST;

  constructor(public payload: AdminUser) {}
}

export class DeleteBackofficeUserFromList implements Action {
  readonly type = DELETE_BACKOFFICE_USER_FROM_LIST;

  constructor(public payload: string) {}
}

export class ClearBackofficeUsersList implements Action {
  readonly type = CLEAR_BACKOFFICE_USERS_LIST;
}

export class GetGeneralFilter implements Action {
  readonly type = GET_GENERAL_FILTER;

  constructor(public payload: string) {}
}

export class AddSortFilters implements Action {
  readonly type = ADD_SORT_FILTERS;

  constructor(public payload: AdminUsersSearchInput) {}
}

export type BackofficeUsersActions =
  | GetGeneralFilter
  | GetCurrentPage
  | GetCurrentPageSize
  | GetBackofficeUsersCount
  | LoadBackofficeUsers
  | RefetchBackofficeUsers
  | LoadBackofficeUsersSuccess
  | LoadBackofficeUsersFailure
  | AddBackofficeUser
  | DeleteBackofficeUser
  | AddBackofficeUserToList
  | DeleteBackofficeUserFromList
  | ClearBackofficeUsersList
  | AddSortFilters;
