import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MixpanelService } from '../shared/services/mixpanel.service';
import { SidenavService } from './sidenav.service';
import { Store } from '@ngrx/store';
import * as fromApp from '../store/app.reducer';
import { RoleNames } from '../shared/api/types/enums';
import { AuthService } from '../auth/auth.service';
import { fadeInAnimation } from '../shared/animations';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: [fadeInAnimation],
})
export class SideNavComponent implements OnInit {
  selectedTab: string;
  catalogQueryParams: Observable<{}>;

  navObject: Record<string, { value: string; path: string; role?: RoleNames }[]> = {
    Sales: [
      {
        value: 'Companies',
        path: 'companies',
        role: RoleNames.user,
      },
      {
        value: 'Requests',
        path: 'sp/requests',
        role: RoleNames.order,
      },
      {
        value: 'Quotes',
        path: 'sp/quotes',
        role: RoleNames.order,
      },
      {
        value: 'Orders',
        path: 'sp/orders',
        role: RoleNames.order,
      },
      {
        value: 'Product catalogue',
        path: `product-catalogue`,
        role: RoleNames.product,
      },
    ],
    PIM: [
      {
        value: 'Products & Clusters',
        path: 'pim/products-and-clusters',
        role: RoleNames.product,
      },
      {
        value: 'Categories',
        path: 'pim/categories',
        role: RoleNames.product,
      },
    ],
    'User Management': [
      {
        value: 'Users',
        path: 'user-management/users',
        role: RoleNames.user,
      },
      {
        value: 'Favorites',
        path: 'user-management/favorites',
        role: RoleNames.shop,
      },
      {
        value: 'Orderlists',
        path: 'user-management/orderlists',
        role: RoleNames.product,
      },
    ],
    'Order Management': [
      {
        value: 'Orders',
        path: `bo/orders`,
        role: RoleNames.order,
      },
      {
        value: 'Carriers',
        path: `order-management/carriers`,
        role: RoleNames.logistics,
      },
      {
        value: 'Warehouses',
        path: `order-management/warehouses`,
        role: RoleNames.logistics,
      },
      {
        value: 'Paymethods',
        path: `order-management/paymethods`,
        role: RoleNames.shop,
      },
      {
        value: 'Order statuses',
        path: `order-management/order-statuses`,
        role: RoleNames.order,
      },
    ],
    Pricing: [
      {
        value: 'Pricing',
        path: `pricing`,
        role: RoleNames.pricing,
      },
      {
        value: 'Taxes',
        path: 'pricing/taxes',
        role: RoleNames.pricing,
      },
      {
        value: 'Surcharges',
        path: 'pricing/surcharges',
        role: RoleNames.pricing,
      },
    ],
    'E-business': [
      {
        value: 'Incentives',
        path: 'ebusiness/incentives',
        role: RoleNames.pricing,
      },
    ],
    Admin: [
      {
        value: 'Backoffice Users',
        path: 'admin/backoffice-users',
        role: RoleNames.role,
      },
      {
        value: 'Emails',
        path: 'admin/emails',
        role: RoleNames.configuration,
      },
      {
        value: 'Attributes',
        path: 'admin/attributes',
        role: RoleNames.configuration,
      },
      {
        value: 'Valuesets',
        path: 'admin/valueset',
        role: RoleNames.configuration,
      },
    ],
  };

  constructor(
    private sidenavService: SidenavService,
    private mixpanelService: MixpanelService,
    private store: Store<fromApp.AppState>,
    public authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.sidenavService.currentTab.subscribe((tabName) => {
      this.selectedTab = tabName;
    });
    this.catalogQueryParams = this.store.select((store) => store.catalog.queryParams);
  }

  trackMenuClick(menu: string) {
    this.mixpanelService.track(menu + ' click', {
      propertyOne: 'valueOne',
      propertyTwo: 'valueTwo',
    });
  }
}
