import { gql } from 'apollo-angular';
import { RuleBuilderFragments } from '../fragments/rule-builder.fragments';

export const CreateRule = gql`
  mutation businessRuleCreate($input: BusinessRuleCreateInput!) {
    businessRuleCreate(input: $input) {
      ...jdm
    }
  }
  ${RuleBuilderFragments.jdm}
  ${RuleBuilderFragments.expression}
  ${RuleBuilderFragments.array}
  ${RuleBuilderFragments.number}
  ${RuleBuilderFragments.date}
  ${RuleBuilderFragments.string}
  ${RuleBuilderFragments.complex1}
  ${RuleBuilderFragments.complex2}
  ${RuleBuilderFragments.complex3}
  ${RuleBuilderFragments.complex4}
  ${RuleBuilderFragments.complex5}
  ${RuleBuilderFragments.subexpression1}
  ${RuleBuilderFragments.subexpression2}
  ${RuleBuilderFragments.subexpression3}
  ${RuleBuilderFragments.subexpression4}
`;

export const CreateIncentiveRule = gql`
  mutation incentiveRuleCreate($input: BusinessRuleCreateInput!) {
    incentiveRuleCreate(input: $input) {
      ...jdm
    }
  }
  ${RuleBuilderFragments.jdm}
  ${RuleBuilderFragments.expression}
  ${RuleBuilderFragments.array}
  ${RuleBuilderFragments.number}
  ${RuleBuilderFragments.date}
  ${RuleBuilderFragments.string}
  ${RuleBuilderFragments.complex1}
  ${RuleBuilderFragments.complex2}
  ${RuleBuilderFragments.complex3}
  ${RuleBuilderFragments.complex4}
  ${RuleBuilderFragments.complex5}
  ${RuleBuilderFragments.subexpression1}
  ${RuleBuilderFragments.subexpression2}
  ${RuleBuilderFragments.subexpression3}
  ${RuleBuilderFragments.subexpression4}
`;

export const UpdateRuleName = gql`
  mutation businessRuleUpdateName($id: ID!, $input: BusinessRuleUpdateInput!) {
    businessRuleUpdate(id: $id, input: $input) {
      name {
        language
        value
      }
    }
  }
`;

export const UpdateDescisionTable = gql`
  mutation businessRuleUpdateDescisionTable($input: BusinessRuleDecisionTableUpdateInput!) {
    businessRuleDecisionTableUpdate(input: $input) {
      content {
        hitPolicy
      }
    }
  }
`;

export const AddColumn = gql`
  mutation addColumn($input: BusinessRuleDecisionTableAddColumnInput!) {
    businessRuleDecisionTableAddColumn(input: $input) {
      ...decisionTable
    }
  }
  ${RuleBuilderFragments.decisionTable}
  ${RuleBuilderFragments.expression}
  ${RuleBuilderFragments.array}
  ${RuleBuilderFragments.number}
  ${RuleBuilderFragments.string}
  ${RuleBuilderFragments.date}
  ${RuleBuilderFragments.complex1}
  ${RuleBuilderFragments.complex2}
  ${RuleBuilderFragments.complex3}
  ${RuleBuilderFragments.complex4}
  ${RuleBuilderFragments.complex5}
  ${RuleBuilderFragments.subexpression1}
  ${RuleBuilderFragments.subexpression2}
  ${RuleBuilderFragments.subexpression3}
  ${RuleBuilderFragments.subexpression4}
`;

export const AddRow = gql`
  mutation addRow($input: BusinessRuleDecisionTableSearchInput!) {
    businessRuleDecisionTableAddRow(input: $input) {
      ...decisionTable
    }
  }
  ${RuleBuilderFragments.decisionTable}
  ${RuleBuilderFragments.expression}
  ${RuleBuilderFragments.array}
  ${RuleBuilderFragments.number}
  ${RuleBuilderFragments.string}
  ${RuleBuilderFragments.date}
  ${RuleBuilderFragments.complex1}
  ${RuleBuilderFragments.complex2}
  ${RuleBuilderFragments.complex3}
  ${RuleBuilderFragments.complex4}
  ${RuleBuilderFragments.complex5}
  ${RuleBuilderFragments.subexpression1}
  ${RuleBuilderFragments.subexpression2}
  ${RuleBuilderFragments.subexpression3}
  ${RuleBuilderFragments.subexpression4}
`;

export const DeleteRow = gql`
  mutation deleteRow($input: BusinessRuleDecisionTableDeleteRowInput!) {
    businessRuleDecisionTableDeleteRow(input: $input) {
      ...decisionTable
    }
  }
  ${RuleBuilderFragments.decisionTable}
  ${RuleBuilderFragments.expression}
  ${RuleBuilderFragments.array}
  ${RuleBuilderFragments.number}
  ${RuleBuilderFragments.string}
  ${RuleBuilderFragments.date}
  ${RuleBuilderFragments.complex1}
  ${RuleBuilderFragments.complex2}
  ${RuleBuilderFragments.complex3}
  ${RuleBuilderFragments.complex4}
  ${RuleBuilderFragments.complex5}
  ${RuleBuilderFragments.subexpression1}
  ${RuleBuilderFragments.subexpression2}
  ${RuleBuilderFragments.subexpression3}
  ${RuleBuilderFragments.subexpression4}
`;

export const MoveRow = gql`
  mutation moveRow($input: BusinessRuleDecisionTableMoveRowInput!) {
    businessRuleDecisionTableMoveRow(input: $input) {
      ...decisionTable
    }
  }
  ${RuleBuilderFragments.decisionTable}
  ${RuleBuilderFragments.expression}
  ${RuleBuilderFragments.array}
  ${RuleBuilderFragments.number}
  ${RuleBuilderFragments.string}
  ${RuleBuilderFragments.date}
  ${RuleBuilderFragments.complex1}
  ${RuleBuilderFragments.complex2}
  ${RuleBuilderFragments.complex3}
  ${RuleBuilderFragments.complex4}
  ${RuleBuilderFragments.complex5}
  ${RuleBuilderFragments.subexpression1}
  ${RuleBuilderFragments.subexpression2}
  ${RuleBuilderFragments.subexpression3}
  ${RuleBuilderFragments.subexpression4}
`;

export const SetCell = gql`
  mutation setCell($input: BusinessRuleDecisionTableSetCellInput!) {
    businessRuleDecisionTableSetCell(input: $input) {
      ...decisionTable
    }
  }
  ${RuleBuilderFragments.decisionTable}
  ${RuleBuilderFragments.expression}
  ${RuleBuilderFragments.array}
  ${RuleBuilderFragments.number}
  ${RuleBuilderFragments.string}
  ${RuleBuilderFragments.date}
  ${RuleBuilderFragments.complex1}
  ${RuleBuilderFragments.complex2}
  ${RuleBuilderFragments.complex3}
  ${RuleBuilderFragments.complex4}
  ${RuleBuilderFragments.complex5}
  ${RuleBuilderFragments.subexpression1}
  ${RuleBuilderFragments.subexpression2}
  ${RuleBuilderFragments.subexpression3}
  ${RuleBuilderFragments.subexpression4}
`;

export const IncentivesSetOrderItemCell = gql`
  mutation incentivesSetOrderItemCell($input: IncentiveRuleOrderItemSetCellInput!) {
    incentiveRuleSetOrderItemConditions(input: $input) {
      ...jdm
    }
  }
  ${RuleBuilderFragments.jdm}
  ${RuleBuilderFragments.expression}
  ${RuleBuilderFragments.array}
  ${RuleBuilderFragments.number}
  ${RuleBuilderFragments.string}
  ${RuleBuilderFragments.date}
  ${RuleBuilderFragments.complex1}
  ${RuleBuilderFragments.complex2}
  ${RuleBuilderFragments.complex3}
  ${RuleBuilderFragments.complex4}
  ${RuleBuilderFragments.complex5}
  ${RuleBuilderFragments.subexpression1}
  ${RuleBuilderFragments.subexpression2}
  ${RuleBuilderFragments.subexpression3}
  ${RuleBuilderFragments.subexpression4}
`;

export const IncentiveSetAction = gql`
  mutation incentiveSetAction($input: IncentiveRuleSetActionInput!) {
    incentiveRuleSetAction(input: $input) {
      ...jdm
    }
  }
  ${RuleBuilderFragments.jdm}
  ${RuleBuilderFragments.expression}
  ${RuleBuilderFragments.array}
  ${RuleBuilderFragments.number}
  ${RuleBuilderFragments.date}
  ${RuleBuilderFragments.string}
  ${RuleBuilderFragments.complex1}
  ${RuleBuilderFragments.complex2}
  ${RuleBuilderFragments.complex3}
  ${RuleBuilderFragments.complex4}
  ${RuleBuilderFragments.complex5}
  ${RuleBuilderFragments.subexpression1}
  ${RuleBuilderFragments.subexpression2}
  ${RuleBuilderFragments.subexpression3}
  ${RuleBuilderFragments.subexpression4}
`;

export const BusinessRuleDeleteMutation = gql`
  mutation BusinessRuleDelete($id: ID!) {
    businessRuleDelete(id: $id)
  }
`;
