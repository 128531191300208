import { gql } from 'apollo-angular';
import { globals } from 'src/app/shared/globals';

export const UpdateFavoriteListMutation = gql`
  mutation UpdateFavoriteList($id: String!, $input: FavoriteListsUpdateInput!) {
    favoriteListUpdate(id: $id, input: $input) {
      id
      name
      slug
      isDefault
    }
  }
`;

export const AddProductsToFavoriteListMutation = gql`
  mutation AddProductsToFavoriteList($id: String!, $input: FavoriteListsItemsInput!) {
    favoriteListAddItems(id: $id, input: $input) {
      id
      products(input: {}) {
        items {
          ... on Product {
            id
            language
            class
            hidden
            names(language: "${globals.appLanguage}") {
              language
              value
            }
            class
            productId
            supplier
            manufacturerCode
            sku
            media {
              images(search: {sort: ASC, offset: ${globals.globalOffset}}) {
                items {
                  imageVariants(
                    input: {
                    transformations: [{
                        name: "xs"
                        transformation: { width: 100, height: 100, fit: BOUNDS, bgColor: "transparent", canvas: {width: 100, height: 100} }
                    }]
                    }
                ) {
                    language
                    name
                    url
                  }
                }
              }
            }
          }
        }
        itemsFound
      }      
      clusters(input: {}) {
        itemsFound
        items {
          ... on Cluster {
            id
            language
            class
            clusterId
            hidden
            names(language: "${globals.appLanguage}") {
              language
              value
            }
            class
            sku
            
          }
        }
      }
    }
  }
`;

export const CreateFavoriteListMutation = gql`
  mutation CreateFavoriteList($input: FavoriteListsCreateInput!) {
    favoriteListCreate(input: $input) {
      id
      name
    }
  }
`;

export const RemoveProductsFromFavoriteListMutation = gql`
  mutation RemoveProductsFromFavoriteList($id: String!, $input: FavoriteListsItemsInput!) {
    favoriteListRemoveItems(id: $id, input: $input) {
      id
      products(input: {}) {
        items {
          ... on Product {
            id
            language
            class
            hidden
            names(language: "${globals.appLanguage}") {
              language
              value
            }
            productId
            class
            supplier
            manufacturerCode
            sku
            media {
              images(search: {sort: ASC, offset: ${globals.globalOffset}}) {
                items {
                  imageVariants(
                    input: {
                    transformations: [{
                        name: "xs"
                        transformation: { width: 100, height: 100, fit: BOUNDS, bgColor: "transparent", canvas: {width: 100, height: 100} }
                    }]
                    }
                ) {
                    language
                    name
                    url
                  }
                }
              }
            }
          }
        }
        itemsFound
      }
      clusters(input: {}) {
        itemsFound
        items {
          ... on Cluster {
            id
            language
            class
            clusterId
            hidden
            names(language: "${globals.appLanguage}") {
              language
              value
            }
            class
            sku
          }
        }
      }
    }
  }
`;

export const DeleteFavoriteListMutation = gql`
  mutation DeleteFavoriteList($id: String!) {
    favoriteListDelete(id: $id)
  }
`;
