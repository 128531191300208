import { ValuesetItem } from 'src/app/shared/api/types/GraphQL';
import * as CustomLanguagesActions from './custom-languages.actions';

export interface CustomLanguageState {
  customLanguage: ValuesetItem[];
  error: Error;
}

export const initialState: CustomLanguageState = {
  customLanguage: [],
  error: undefined,
};

export function customLanguageReducer(
  state: CustomLanguageState = initialState,
  action: CustomLanguagesActions.CustomLanguagesActions,
) {
  switch (action.type) {
    case CustomLanguagesActions.LOAD_CUSTOM_LANGUAGES:
      return {
        ...state,
      };
    case CustomLanguagesActions.CUSTOM_LANGUAGES_SUCCESS:
      return {
        ...state,
        customLanguage: [...action.payload],
      };
    case CustomLanguagesActions.CUSTOM_LANGUAGES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
}
