import { OrderStatus, OrderStatusesSearchInput } from 'src/app/shared/api/types/GraphQL';
import * as OrderStatusActions from './order-status.actions';
import { globals } from 'src/app/shared/globals';
import { sortBy } from 'lodash';

export interface State {
  loading: boolean;
  loaded: boolean;
  dragDisabled: boolean;
  orderStatusList: OrderStatus[];
  currentPage: number;
  currentPageSize: number;
  orderStatusCount: number;
  error: Error;
  generalFilterValue: string;
  activeTab: number;
  selectedOrderStatusList: OrderStatus[];
  orderStatusFilters: OrderStatusesSearchInput;
}

const initialState: State = {
  loading: false,
  loaded: false,
  dragDisabled: false,
  orderStatusList: [],
  currentPage: 1,
  currentPageSize: globals.globalOffset,
  orderStatusCount: 0,
  error: undefined,
  generalFilterValue: '',
  activeTab: 0,
  selectedOrderStatusList: [],
  orderStatusFilters: {},
};

let sortOrderStatusByPriority = (orderStatus: OrderStatus[]) => {
  return sortBy(orderStatus, ['priority']);
};

let shouldDisableDrag = (obj) => {
  if (obj.sortInputs && obj.sortInputs.field !== 'PRIORITY') {
    return true;
  }
  if (Object.keys(obj).length > 0) {
    return true;
  }
  return false;
};

export function orderStatusReducer(state: State = initialState, action: OrderStatusActions.OrderStatusActions) {
  switch (action.type) {
    case OrderStatusActions.GET_GENERAL_FILTER:
      return {
        ...state,
        generalFilterValue: action.payload,
      };
    case OrderStatusActions.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case OrderStatusActions.GET_CURRENT_PAGE_SIZE:
      return {
        ...state,
        currentPageSize: action.payload,
      };
    case OrderStatusActions.GET_ORDER_STATUS_COUNT:
      return {
        ...state,
        orderStatusCount: action.payload,
      };
    case OrderStatusActions.LOAD_ORDER_STATUS:
      let loading;
      state.loaded ? (loading = false) : (loading = true);
      return {
        ...state,
        loading: loading,
      };
    case OrderStatusActions.REFETCH_ORDER_STATUS:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case OrderStatusActions.LOAD_ORDER_STATUS_SUCCESS:
      let newOrderStatusListLoad = [...action.payload];
      let sortedOrderStatusListLoad = sortOrderStatusByPriority(newOrderStatusListLoad);
      return {
        ...state,
        orderStatusList: sortedOrderStatusListLoad,
        loading: false,
        loaded: true,
      };
    case OrderStatusActions.LOAD_ORDER_STATUS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case OrderStatusActions.ADD_ORDER_STATUS:
      let newOrderStatusListAdd = [...state.orderStatusList, action.payload];
      let sortedOrderStatusListAdd = sortOrderStatusByPriority(newOrderStatusListAdd);
      return {
        ...state,
        orderStatusList: sortedOrderStatusListAdd,
      };
    case OrderStatusActions.ADD_ORDER_STATUS_TO_LIST:
      if (state.selectedOrderStatusList.map((x) => x.id).indexOf(action.payload.id) === -1) {
        return {
          ...state,
          selectedOrderStatusList: [...state.selectedOrderStatusList, action.payload],
        };
      } else {
        return {
          ...state,
        };
      }
    case OrderStatusActions.DELETE_ORDER_STATUS_FROM_LIST:
      if (state.selectedOrderStatusList.map((x) => x.id).indexOf(action.payload) !== -1) {
        const index = state.selectedOrderStatusList.map((x) => x.id).indexOf(action.payload);
        return {
          ...state,
          selectedOrderStatusList: state.selectedOrderStatusList.filter((list, i) => {
            return i !== index;
          }),
        };
      }
      return state;
    case OrderStatusActions.CLEAR_ORDER_STATUS_LIST:
      return {
        ...state,
        selectedOrderStatusList: [],
      };
    case OrderStatusActions.UPDATE_ORDER_STATUS:
      const updatedOrderStatusIndex = state.orderStatusList.findIndex(
        (orderStatus) => orderStatus.id === action.payload.id,
      );
      if (updatedOrderStatusIndex > -1) {
        const updatedOrderStatusList = [...state.orderStatusList];
        updatedOrderStatusList[updatedOrderStatusIndex] = action.payload.updatedOrderStatus;
        let sortedOrderStatusListUpdate = sortOrderStatusByPriority(updatedOrderStatusList);
        return {
          ...state,
          orderStatusList: sortedOrderStatusListUpdate,
        };
      }
      return state; // If the order status to update is not found, return the current state unchanged.
    case OrderStatusActions.CREATE_ORDER_STATUS:
      let newOrderStatusListCreate = [...state.orderStatusList, action.payload];
      let sortedOrderStatusListCreate = sortOrderStatusByPriority(newOrderStatusListCreate);
      return {
        ...state,
        orderStatusList: sortedOrderStatusListCreate,
      };
    case OrderStatusActions.ADD_GENERAL_FILTER:
      return {
        ...state,
        orderStatusFilters: { ...state.orderStatusFilters, ...action.payload },
        dragDisabled: shouldDisableDrag(action.payload),
      };
    case OrderStatusActions.REMOVE_GENERAL_FILTER:
      const { columnName, index } = action.payload;

      // Clone the state's orderStatusFilters object to prevent mutation
      const updatedFilters = { ...state.orderStatusFilters };

      // Check if orderStatusFilters[columnName] is an array
      if (Array.isArray(updatedFilters[columnName])) {
        // If it's an array, clone the array, remove the item at the specified index, and update the orderStatusFilters object
        updatedFilters[columnName] = [...updatedFilters[columnName]];
        updatedFilters[columnName].splice(index, 1);
        if (updatedFilters[columnName].length === 0) {
          delete updatedFilters[columnName];
        }
      } else {
        // If it's not an array, simply delete the key corresponding to columnName
        delete updatedFilters[columnName];
      }

      return {
        ...state,
        orderStatusFilters: updatedFilters,
        dragDisabled: shouldDisableDrag({ ...updatedFilters }),
      };
    default:
      return state;
  }
}
