import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { globals } from 'src/app/shared/globals';
import * as _ from 'lodash';
import { ValuesetItemSelection } from '../../api/types/types';
import { MatSelect } from '@angular/material/select';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ValueSetService } from 'src/app/admin/value-set/value-set.service';
import { AttributesService } from 'src/app/admin/attributes/attributes.service';
import {
  AttributeCreateInput,
  AttributeEnumValue,
  Company,
  Contact,
  Customer,
  ValuesetItem,
  ValuesetType,
} from '../../api/types/GraphQL';
import { Subject, catchError, forkJoin, map, of, switchMap, takeUntil } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SnackBarService } from '../snack-bar/snack-bar.service';
import { GqlErrorService } from '../../services/gql-error.service';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../store/app.reducer';
import { fadeInAnimation } from '../../animations';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tags-add',
  templateUrl: './tags-add.component.html',
  styleUrls: ['./tags-add.component.scss'],
  animations: [fadeInAnimation],
})
export class TagsAddComponent implements OnInit {
  @Input() selectedItems;
  @Input() refetchAction;
  @Output() clearSelectionAndHideItems: EventEmitter<void> = new EventEmitter();

  tagsValuesetId: number;

  selectedUserTags: ValuesetItemSelection[] = [];
  @ViewChild('tagSelect') tagSelect: MatSelect;

  tagsDropdownPage = 1;
  tagsDropdownItemsPerPage = 12;
  tagsDropdownItemsFound: number;
  userTags: ValuesetItemSelection[];
  preselectedTags: ValuesetItemSelection[];
  initialTagsSelection: ValuesetItemSelection[];
  removedTags: ValuesetItemSelection[] = [];
  isTagsListChanged: boolean = false;
  isSearchTagMode: boolean = false;
  searchUserTags: ValuesetItemSelection[] = [];
  tagsSearchTerm: string;

  destroySubject: Subject<void> = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<any>,
    private dialog: MatDialog,
    private valuesetSerice: ValueSetService,
    private attributesService: AttributesService,
    private snackBarService: SnackBarService,
    private errorService: GqlErrorService,
    private store: Store<fromApp.AppState>,
  ) {}

  ngOnInit(): void {
    this.valuesetSerice
      .getValuesetByName({ names: [environment.valuesetByName.userTags], offset: 1 })
      .valueChanges.pipe(
        takeUntil(this.destroySubject),
        map((data) => data.data.valuesets.items[0]),
      )
      .subscribe({
        next: (tagsValueset) => {
          if (tagsValueset) this.tagsValuesetId = tagsValueset.id;
        },
        error: (error) => this.errorService.getGqlError(error),
      });
  }

  onAddTagsOpen() {
    if (!this.tagsValuesetId) return;
    const uniqueTagsSet = new Set<string>();

    const allTags = (this.selectedItems as Contact[] | Customer[] | Company[]).flatMap((obj) =>
      obj.attributes.items.flatMap((item) => item.value.enumValues),
    );

    allTags.forEach((value) => uniqueTagsSet.add(value));
    const uniqueTagsArray: string[] = Array.from(uniqueTagsSet);

    this.valuesetSerice
      .getUserTagsDropdownList({ valuesetIds: [this.tagsValuesetId], values: uniqueTagsArray })
      .valueChanges.pipe(
        takeUntil(this.destroySubject),
        map((data) => data.data.valuesetItems.items),
        switchMap((selectedValuesetItems) => {
          this.preselectedTags = this.checkTagPresence(selectedValuesetItems);
          this.selectedUserTags = _.cloneDeep(this.preselectedTags);
          this.initialTagsSelection = _.cloneDeep(this.preselectedTags);
          this.userTags = _.cloneDeep(this.preselectedTags);

          return this.valuesetSerice.getUserTagsDropdownList({
            valuesetIds: [this.tagsValuesetId],
            page: this.tagsDropdownPage,
          }).valueChanges;
        }),
        map((data) => data.data.valuesetItems),
      )
      .subscribe({
        next: (valuesetItemsObj) => {
          this.tagsDropdownItemsFound = valuesetItemsObj.itemsFound;

          const selectionItems = this.convertToSelection(valuesetItemsObj.items);

          this.userTags = [...this.userTags, ...this.filterOutPreselectedTags(selectionItems)];

          setTimeout(() => {
            this.tagSelect.open();
          });
        },
      });
  }

  onOpenedChange(event) {
    if (!event) {
      this.isSearchTagMode = false;
      this.searchUserTags = [];
      this.tagsSearchTerm = '';
    }
  }

  private convertToSelection(valuesetItems: ValuesetItem[]): ValuesetItemSelection[] {
    return valuesetItems.map((item) => ({
      ...item,
      isInAll: false,
      isInSome: false,
      isPreselected: false,
      isRemoved: false,
    }));
  }

  private filterOutPreselectedTags(items: ValuesetItemSelection[]) {
    return items.filter((item) => !this.preselectedTags.some((preselected) => item.value === preselected.value));
  }

  private checkTagPresence(tagsArray: ValuesetItem[]) {
    const enumValuePresenceMapToTagSelectionObjects: ValuesetItemSelection[] = tagsArray.map((item) => {
      let isInAll = true;
      let isInSome = false;

      for (const obj of this.selectedItems) {
        const objEnumValues = (obj as Contact | Customer | Company).attributes.items.flatMap(
          (item) => (item.value as AttributeEnumValue).enumValues,
        );

        if (!objEnumValues.includes(item.value)) {
          isInAll = false;
          isInSome = true;
        }
      }

      return {
        ...item,
        isInAll,
        isInSome,
        isRemoved: false,
        isPreselected: true,
      };
    });

    return enumValuePresenceMapToTagSelectionObjects;
  }

  toggleSelection(item: ValuesetItemSelection, event: MatCheckboxChange) {
    if (!event.source.indeterminate) {
      if (event.checked) {
        if (item.isPreselected && item.isInSome && item.isRemoved) {
          item.isRemoved = false;
          this.selectedUserTags.push(item);
        } else {
          item.isInAll = true;
          this.selectedUserTags.push(item);
        }
      } else {
        this.selectedUserTags = this.selectedUserTags.filter((selected) => selected.value !== item.value);
        this.removedTags.push(item);
        item.isRemoved = true;
        item.isInAll = false;
      }
    } else if (item.isPreselected && item.isInSome && !item.isRemoved) {
      item.isInAll = true;
      this.selectedUserTags[this.selectedUserTags.findIndex((obj) => obj.id === item.id)] = item;
      event.source.toggle();
    }

    if (!_.isEqual(this.initialTagsSelection, this.selectedUserTags)) {
      this.isTagsListChanged = true;
    } else {
      this.isTagsListChanged = false;
    }
  }

  loadMore(event) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      if (this.tagsDropdownItemsFound > this.userTags.length) {
        this.tagsDropdownPage += 1;

        this.valuesetSerice
          .getUserTagsDropdownList({ valuesetIds: [this.tagsValuesetId], page: this.tagsDropdownPage })
          .valueChanges.pipe(
            takeUntil(this.destroySubject),
            map((data) => data.data.valuesetItems.items),
          )
          .subscribe({
            next: (valuesetItems) => {
              const selectionItems = this.convertToSelection(valuesetItems);

              this.userTags = [...this.userTags, ...this.filterOutPreselectedTags(selectionItems)];
            },
          });
      }
    }
  }

  onTagSearch(event) {
    if (event.target.value) {
      if (!this.isSearchTagMode) this.isSearchTagMode = true;
      this.valuesetSerice
        .getUserTagsDropdownList({ valuesetIds: [this.tagsValuesetId], page: 1, values: [event.target.value] })
        .valueChanges.pipe(
          takeUntil(this.destroySubject),
          map((data) => data.data.valuesetItems.items),
        )
        .subscribe({
          next: (valuesetItems) => {
            this.searchUserTags = this.convertToSelection(valuesetItems);
          },
        });
    } else this.isSearchTagMode = false;
  }

  onTagCreataeAndApply() {
    this.valuesetSerice
      .valueSetItemCreate({ input: { valuesetId: this.tagsValuesetId, value: this.tagsSearchTerm } })
      .pipe(
        takeUntil(this.destroySubject),
        map((data) => data.data.valuesetItemCreate),
      )
      .subscribe({
        next: (valuesetItem) => {
          const valuesetItemSelection: ValuesetItemSelection = {
            ...valuesetItem,
            isInAll: true,
            isInSome: false,
            isPreselected: false,
            isRemoved: false,
          };

          this.selectedUserTags.push(valuesetItemSelection);

          this.onAddTags();
        },
      });
  }

  onCreateNewTag() {
    import('src/app/admin/value-set/value-set.module')
      .then((mod) => mod.ValueSetModule)
      .then((modType) => {
        const dialogRef = this.dialog.open(modType.components.ValueSetItemDetailsComponent, {
          width: '860px',
          maxHeight: '95vh',
          panelClass: 'amp-app',
          disableClose: true,
          data: {
            valueSetItem: { valuesetId: this.tagsValuesetId },
            type: ValuesetType.System,
          },
        });
        dialogRef.afterClosed().subscribe((item) => {
          if (item) {
            const valuesetItemSelection: ValuesetItemSelection = {
              ...item,
              isInAll: true,
              isInSome: false,
              isPreselected: false,
              isRemoved: false,
            };

            this.selectedUserTags.push(valuesetItemSelection);

            this.onAddTags();
          }
        });
      });
  }

  addTagsMutationsObservable() {
    const fullySellectedTags = this.selectedUserTags.filter((tag) => tag.isInAll).map((item) => item.value);
    const partiallySelectedTags = this.selectedUserTags.filter((tag) => !tag.isInAll).map((item) => item.value);

    const mutations = [];

    for (let item of this.selectedItems as Contact[] | Customer[] | Company[]) {
      const itemAttrObject = item.attributes.items[0];

      let variables: { [key: string]: any } = {
        value: { enumValues: [] },
      };

      if (!itemAttrObject.attribute) {
        if (fullySellectedTags.length) {
          variables.attributeDescriptionId = itemAttrObject.attributeDescription.id;

          if (item.hasOwnProperty('contactId')) {
            variables.contactId = (item as Contact).contactId;
          } else if (item.hasOwnProperty('customerId')) {
            variables.customerId = (item as Customer).customerId;
          } else if (item.hasOwnProperty('companyId')) {
            variables.companyId = (item as Company).companyId;
          }

          variables.value.enumValues = fullySellectedTags;
          mutations.push(
            this.attributesService.attributeCreate({ input: variables as AttributeCreateInput }).pipe(
              takeUntil(this.destroySubject),
              catchError((error) => {
                return of(error);
              }),
            ),
          );
        }
      } else {
        let existingPartialAttrValues = (itemAttrObject.value as AttributeEnumValue).enumValues.filter((value) =>
          partiallySelectedTags.includes(value),
        );
        variables.value.enumValues = [...existingPartialAttrValues, ...fullySellectedTags];

        mutations.push(
          this.attributesService
            .AttributeUpdate({
              id: itemAttrObject.attribute.id,
              input: variables as AttributeCreateInput,
            })
            .pipe(
              takeUntil(this.destroySubject),
              catchError((error) => {
                return of(error);
              }),
            ),
        );
      }
    }

    return mutations;
  }

  executeMutations(mutations) {
    forkJoin(mutations).subscribe({
      next: (results: []) => {
        this.clearSelectionAndHideItems.emit();

        //const refetchAction = this.actionsMapper[this.listingType]?.refetch;
        this.snackBarService.openSnackBar(`${results.length} tags were successfully updated`, '');
        if (this.refetchAction) {
          const refetchActionInstance = this.refetchAction({});
          this.store.dispatch(refetchActionInstance);
          return of(null);
        }
      },
      error: (error) => {
        this.errorService.getGqlError(error);
      },
    });
  }

  onAddTags() {
    const mutations = this.addTagsMutationsObservable();
    this.executeMutations(mutations);
  }
}
