import { Order, OrderSearchArguments } from 'src/app/shared/api/types/GraphQL';
import { TableColumns } from 'src/app/shared/api/types/interfaces';
import * as QuotesActions from './quotes.actions';

export interface State {
  loading: boolean;
  loaded: boolean;
  quotes: Order[];
  currentPage: number;
  currentPageSize: number;
  quotesCount: number;
  error: Error;
  columnFilterValues: {
    name: string;
    sku: string;
  };
  multiselectFilterValues: {
    manufacturer: string[];
    supplier: string[];
  };
  numberOfFiltersSelected: number;
  generalFilterValue: string;
  selectedQuotesList: Order[];
  productTableColumns: TableColumns;
  ordersTableColumns: TableColumns;
  quoteFilters: OrderSearchArguments;
}

const initialState: State = {
  loading: false,
  loaded: false,
  quotes: [],
  currentPage: 1,
  currentPageSize: 5,
  quotesCount: 0,
  error: undefined,
  columnFilterValues: {
    name: '',
    sku: '',
  },
  multiselectFilterValues: {
    manufacturer: [],
    supplier: [],
  },
  numberOfFiltersSelected: 0,
  generalFilterValue: '',
  selectedQuotesList: [],
  productTableColumns: {
    displayedColumns: [
      'select',
      'image',
      'sku',
      'name',
      'supplier',
      'listPrice',
      'customerPrice',
      'discount',
      'costPrice',
      'salesDiscount',
      'salesPrice',
      'saleMargin',
      'totalDiscount',
      'quantity',
      'total',
      'defaultMargin',
      'attributedDiscount',
      'attributedMargin',
    ],
    hiddenColumns: ['select'],
    disabledSuffixLength: 0,
    availableColumns: ['select'],
    disabledColumns: ['sku', 'name'],
  },
  ordersTableColumns: {
    displayedColumns: [
      'select',
      'id',
      'company',
      'status',
      'debtorId',
      'source',
      'total',
      'itemsCount',
      'dateCreated',
      'statusDate',
      'exportStatus',
      'exportedAt',
      'exportMessage',
    ],
    hiddenColumns: ['select'],
    disabledSuffixLength: 0,
    availableColumns: [],
    disabledColumns: ['id', 'company'],
  },
  quoteFilters: {},
};

export function quotesReducer(state: State = initialState, action: QuotesActions.QuotesActions) {
  switch (action.type) {
    case QuotesActions.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case QuotesActions.GET_CURRENT_PAGE_SIZE:
      return {
        ...state,
        currentPageSize: action.payload,
      };
    case QuotesActions.GET_QUOTES_COUNT:
      return {
        ...state,
        quotesCount: action.payload,
      };
    case QuotesActions.LOAD_QUOTES:
      return {
        ...state,
        loading: true,
      };
    case QuotesActions.REFETCH_QUOTES:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case QuotesActions.LOAD_QUOTES_SUCCESS:
      return {
        ...state,
        quotes: [...action.payload],
        loading: false,
        loaded: true,
      };
    case QuotesActions.LOAD_QUOTES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case QuotesActions.ADD_QUOTE:
      let quotesList = [...state.quotes];
      let quotesCount = state.quotesCount;

      //add new quote
      if (state.currentPage === 1) {
        if (quotesList.some((e) => e.id === action.payload.id)) {
          const index = quotesList.map((x) => x.id).indexOf(action.payload.id);
          quotesList.splice(index, 1);
        } else {
          quotesList.pop();
        }
        quotesList.splice(0, 0, action.payload);
      }

      quotesCount += 1;
      return {
        ...state,
        quotes: quotesList,
        quotesCount,
      };

    case QuotesActions.UPDATE_QUOTE:
      const index = state.quotes.map((x) => x.id).indexOf(action.payload.id);
      const updatedQuote = {
        ...state.quotes[index],
        ...action.payload.updatedQuote,
      };
      const updatedQuotes = [...state.quotes];
      updatedQuotes[index] = updatedQuote;
      return {
        ...state,
        quotes: updatedQuotes,
      };
    case QuotesActions.DELETE_QUOTE:
      if (state.quotes.map((x) => x.id).indexOf(action.payload) !== -1) {
        let quotesCount = state.quotesCount;
        const index = state.quotes.map((x) => x.id).indexOf(action.payload);

        quotesCount -= 1;
        return {
          ...state,
          quotes: state.quotes.filter((request, i) => {
            return i !== index;
          }),
          quotesCount,
        };
      } else {
        return {
          ...state,
        };
      }
    case QuotesActions.ADD_QUOTE_TO_LIST:
      if (state.selectedQuotesList.map((x) => x.id).indexOf(action.payload.id) === -1) {
        return {
          ...state,
          selectedQuotesList: [...state.selectedQuotesList, action.payload],
        };
      } else {
        return {
          ...state,
        };
      }
    case QuotesActions.DELETE_QUOTE_FROM_LIST:
      if (state.selectedQuotesList.map((x) => x.id).indexOf(action.payload) !== -1) {
        const index = state.selectedQuotesList.map((x) => x.id).indexOf(action.payload);
        return {
          ...state,
          selectedQuotesList: state.selectedQuotesList.filter((product, i) => {
            return i !== index;
          }),
        };
      } else {
        return {
          ...state,
        };
      }
    case QuotesActions.CLEAR_QUOTES_LIST:
      return {
        ...state,
        selectedQuotesList: [],
      };
    case QuotesActions.GET_COLUMN_FILTERS:
      let numOfFilters = state.numberOfFiltersSelected;
      if (action.payload.filterValue === '' && state.columnFilterValues[action.payload.columnName] !== '') {
        numOfFilters -= 1;
      } else if (action.payload.filterValue !== '' && state.columnFilterValues[action.payload.columnName] === '') {
        numOfFilters += 1;
      }
      return {
        ...state,
        columnFilterValues: {
          ...state.columnFilterValues,
          [action.payload.columnName]: action.payload.filterValue,
        },
        numberOfFiltersSelected: numOfFilters,
      };
    case QuotesActions.GET_MULTISELECT_FILTERS:
      let payloadCreatedAt =
        action.payload.createdAt?.greaterThan !== undefined && action.payload.createdAt?.lessThan !== undefined;
      let payloadUpdatedAt =
        action.payload.lastModifiedAt?.greaterThan !== undefined &&
        action.payload.lastModifiedAt.lessThan !== undefined;
      let payloadExportedAt =
        action.payload.exportedAt?.greaterThan !== undefined && action.payload.exportedAt?.lessThan !== undefined;
      let payloadPrice =
        action.payload.price?.greaterThan !== undefined || action.payload.price?.lessThan !== undefined;
      let payloadLength =
        (action.payload.type ? 1 : 0) +
        (payloadCreatedAt ? 1 : 0) +
        (payloadUpdatedAt ? 1 : 0) +
        (payloadExportedAt ? 1 : 0) +
        (action.payload.userId !== undefined ? action.payload.userId.length : 0) +
        (action.payload?.status?.length !== 2 ? 1 : 0) +
        (action.payload?.exportStatuses?.length ? 1 : 0) +
        (payloadPrice ? 1 : 0);
      return {
        ...state,
        quoteFilters: {
          ...state.quoteFilters,
          userId: action.payload.userId,
          status: action.payload.status,
          type: action.payload.type,
          createdAt: action.payload.createdAt,
          lastModifiedAt: action.payload.lastModifiedAt,
          exportedAt: action.payload.exportedAt,
          exportStatuses: action.payload.exportStatuses,
          price: action.payload.price,
          sortInputs: action.payload.sortInputs,
        },
        numberOfFiltersSelected: payloadLength,
      };
    case QuotesActions.GET_GENERAL_FILTER:
      return {
        ...state,
        generalFilterValue: action.payload,
      };
    case QuotesActions.ADD_GENERAL_FILTER_QUOTE:
      return {
        ...state,
        quoteFilters: { ...state.quoteFilters, ...action.payload },
      };
    case QuotesActions.CLEAR_ALL_FILTERS:
      return {
        ...state,
        columnFilterValues: {
          name: '',
          sku: '',
        },
        multiselectFilterValues: {
          manufacturer: [],
          supplier: [],
        },
        generalFilterValue: '',
        numberOfFiltersSelected: 0,
      };
    case QuotesActions.PRODUCT_TABLE_COLUMNS:
      return {
        ...state,
        productTableColumns: action.payload,
      };

    case QuotesActions.ORDERS_TABLE_COLUMNS:
      return {
        ...state,
        ordersTableColumns: action.payload,
      };
    default:
      return state;
  }
}
