import { Component, Inject } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Input, OnInit, ViewChild } from '@angular/core';
import {
  AttributeEnumValue,
  Contact,
  ContactAddToCompaniesInput,
  ContactRemoveFromCompaniesInput,
  ContactSortableField,
  CreateContactInput,
} from 'src/app/shared/api/types/GraphQL';
import { UserDetailsService } from '../../user-details.service';
import { Subscription, catchError, forkJoin, map, of, take } from 'rxjs';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/shared/components/snack-bar/snack-bar.service';
import { GqlErrorService } from 'src/app/shared/services/gql-error.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { fadeAnimation } from 'src/app/shared/animations';
import { DialogButtonsText, UserStateEntry, UserTypes, UserTypesPlural } from 'src/app/shared/api/types/enums';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { TableColumn, TableColumnType } from 'src/app/shared/components/listing-component/listing.component';
import { cloneDeep } from '@apollo/client/utilities';
import { Router } from '@angular/router';
import { UsersService } from '../../../users.service';
import { QueryRef } from 'apollo-angular';
import { CompanyResponse } from 'src/app/shared/api/types/types';
import { MatCheckboxChange } from '@angular/material/checkbox';
import * as UsersActions from '../../../store/users/users.actions';
import { Store } from '@ngrx/store';
import * as fromApp from 'src/app/store/app.reducer';
import { UsersComponent } from '../../../users.component';

@Component({
  selector: 'app-contacts-in-company',
  templateUrl: './contacts-in-company.component.html',
  styleUrls: [
    './contacts-in-company.component.scss',
    '../../../../../shared/components/listing-component/listing.component.scss',
  ],
  animations: [fadeAnimation],
})
export class ContactsInCompanyComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() isViewer: boolean;
  @Input() companyId: number;

  loading: boolean;
  buttonText: string = 'Add existing contact';
  createLoading: boolean;
  error: string = '';

  dataSourceContact: MatTableDataSource<Contact>;
  dataSourceLoading: MatTableDataSource<{}> = new MatTableDataSource(Array.from({ length: 5 }, () => ({})));

  totalItems: number = 0;
  page: number = 1;
  itemsPerPage: number = 5;

  contactsInCompanyQuery: QueryRef<CompanyResponse>;
  contactsInCompanyQuerySub: Subscription;

  selection = new SelectionModel<Contact>(true, []);
  selectedContactsList: Contact[] = [];
  showSelectedItems: boolean = false;
  contactsInCompanyDialog: boolean = false;
  companyName: string;

  public columnNames: string[] = [];
  TableColumnType = TableColumnType;

  displayedColumns: TableColumn[] = [
    {
      name: 'fullName',
      label: 'Name',
      sortable: true,
      sortField: ContactSortableField.FirstName,
      content: (element: Contact) => element.firstName + ' ' + element.lastName,
    },
    {
      name: 'tags',
      label: 'Groups',
      sortable: false,
      type: TableColumnType.array,
      content: (element: Contact) => (element.attributes?.items[0]?.value as AttributeEnumValue)?.enumValues || [],
    },
    {
      name: 'email',
      label: 'Email',
      sortable: false,
      content: (element: Contact) => element.email,
    },
    {
      name: 'phone',
      label: 'Phone',
      sortable: false,
      content: (element: Contact) => element.phone,
    },
    {
      name: 'source',
      label: 'Source',
      sortable: false,
      content: (element: Contact) => 'Source data',
    },
  ];
  singleValueSelect: boolean;
  allowSelectionForContacts: boolean;

  constructor(
    private store: Store<fromApp.AppState>,
    private router: Router,
    private usersService: UsersService,
    private userDetailsService: UserDetailsService,
    private dialog: MatDialog,
    public snackBarService: SnackBarService,
    private errorService: GqlErrorService,
    private dialogRef: MatDialogRef<ContactsInCompanyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data && data.contactsInCompanyDialog) {
      this.companyId = data.companyId;
      this.companyName = data.companyName;
      this.contactsInCompanyDialog = data.contactsInCompanyDialog;
      this.allowSelectionForContacts = data.allowSelectionForContacts;
      if (this.allowSelectionForContacts) {
        this.singleValueSelect = false;
      } else this.singleValueSelect = data.singleValueSelect;
    }
  }

  ngOnInit(): void {
    this.columnNames = ['select', ...this.displayedColumns.map((column) => column.name)];

    let variables = {
      id: this.companyId,
      input: {
        page: this.page,
        offset: this.itemsPerPage,
      },
    };
    this.contactsInCompanyQuery = this.userDetailsService.getContactsInCompany(variables.id, variables.input);
    this.contactsInCompanyQuerySub = this.contactsInCompanyQuery.valueChanges.subscribe({
      next: (result: any) => {
        let data = result.data;
        let contactData = data.company.contacts.items;
        this.totalItems = data.company.contacts.itemsFound;
        this.dataSourceContact = new MatTableDataSource(cloneDeep(contactData));
        this.loading = false;
      },
      error: (error: any) => {
        this.loading = false;
        this.errorService.getGqlError(error);
      },
    });
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.paginator.page.subscribe((page: PageEvent) => {
        if (this.itemsPerPage !== page.pageSize) {
          this.itemsPerPage = page.pageSize;
        }
        this.page = page.pageIndex + 1;
        this.loading = true;

        let variables = {
          id: this.companyId,
          input: {
            page: this.page,
            offset: this.itemsPerPage,
          },
        };

        this.contactsInCompanyQuery.refetch(variables);
      });
      setTimeout(() => {
        this.paginator.pageIndex = this.page - 1;
      });
    }
  }

  createContact() {
    this.createEntity(this.companyId);
  }

  private createEntity(id: number): void {
    let newEntity: CreateContactInput;
    this.createLoading = true;
    newEntity = {
      firstName: 'New contact',
      lastName: '',
      parentId: id,
    };
    const userType = UserTypes.contact;
    this.usersService
      .createUser(UserTypesPlural.contacts, newEntity)
      .pipe(
        map((data) => {
          return data.data[`${userType}Create`];
        }),
      )
      .subscribe({
        next: (user) => {
          const userId = user[`${userType}Id`];
          this.openUserDetails({ contactId: userId, __typename: 'Contact' });
          this.createLoading = false;
        },
        error: (error) => {
          this.errorService.getGqlError(error);
          this.createLoading = false;
        },
      });
  }

  openAssignUsersDialog() {
    const dialogRef = this.dialog.open(UsersComponent, {
      width: '90vw',
      height: '90vh',
      maxWidth: '90vw',
      maxHeight: '90vh',
      panelClass: ['dialog', 'wCloseBtn', 'fullHeight'],
      disableClose: true,
      data: {
        displayedTabs: [UserTypes.company],
        enableHandleRow: true,
        singleValue: true,
        shouldShowActionColumn: true,
        allowSelectionForContacts: true,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.store
          .select((store) => store.users[UserStateEntry.userPicking].users[result.selectedUserType])
          .pipe(take(1))
          .subscribe((data) => {
            if (data) {
              const ids = data.selectedUsersList.map((u) => u.companyId || u.contactId || u.customerId);
              this.loading = true;
              this.assignUsers(ids);
            }
          });
      }
    });
  }

  private assignUsers(ids: number[]) {
    let successfulAdditions = 0;
    let failedAdditions = 0;
    const companyId: ContactAddToCompaniesInput = {
      companyIds: [this.companyId],
    };

    const assignUserObservables: any = ids.map((id: number) => {
      return this.userAddToCompany(id, companyId);
    });

    forkJoin(assignUserObservables).subscribe({
      next: (results: any) => {
        results.forEach((response) => {
          if (!response) return;
          this.loading = true;

          const actionsData = response?.contactAddToCompanies?.actions?.data || [];

          actionsData.forEach((action: any) => {
            if (action.success) {
              successfulAdditions++;
            } else {
              failedAdditions++;
            }
          });
        });
      },
      error: (error) => {
        this.errorService.getGqlError(error);
      },
      complete: () => {
        // Calculate total additions
        const totalAdditions = successfulAdditions + failedAdditions;

        // Show snackBar message based on success/failure counts
        if (failedAdditions > 0) {
          this.snackBarService.openSnackBar(
            `${failedAdditions} out of ${totalAdditions} contact(s) were not successfully added to the company`,
            '',
            'error',
          );
        } else {
          this.snackBarService.openSnackBar(`${successfulAdditions} contact(s) successfully added to the company`, '');
        }

        // Clear contact list and refetch
        this.clearContactListAndRefetch();
        this.loading = false;
      },
    });
  }

  private userAddToCompany(id: number, input: ContactAddToCompaniesInput) {
    return this.usersService.contactAddToCompanies(id, input).pipe(
      map((data) => data.data),
      catchError((error) => {
        this.errorService.getGqlError(error);
        return of(false);
      }),
    );
  }

  onRowClick(row: any): void {
    if (!this.singleValueSelect) {
      this.openUserDetails(row);
    } else if (this.singleValueSelect) {
      this.closeDialogWithElement(row);
    }
  }

  closeDialogWithElement(row) {
    this.dialogRef.close({ shouldClose: true, ...row });
  }

  openUserDetails(element: any) {
    const referringUrl = this.router.url;
    this.usersService.setReferringUrl(referringUrl);
    try {
      const userType = element.__typename ? element.__typename.toLowerCase() : null;
      const userId = element[`${userType}Id`];
      if (userType && userId) {
        this.router
          .navigateByUrl(`/user-management/users`)
          .then((success) => {
            if (success) {
              this.router.navigateByUrl(`/user-management/users/details/${userType}/${userId}`);
            } else {
              console.error('Navigation failed');
            }
          })
          .catch((err) => {
            console.error('Navigation error:', err);
          });
      } else {
        console.error('Invalid userType or userId');
      }
    } catch (error) {
      console.error('Navigation error:', error);
    }
  }

  deleteItem(id) {
    return this.usersService.deleteUser(id, UserTypes.contact).pipe(
      map((data) => data.data),
      catchError((error) => {
        this.errorService.getGqlError(error);
        return of(false);
      }),
    );
  }

  deleteMultipleItems() {
    const selectedItems = this.selectedContactsList;

    if (selectedItems.length === 0) return;

    const dialogData = new ConfirmDialogModel(
      'Are you sure?',
      `Are you sure you want to delete the selected contact(s)?`,
      'Delete',
    );

    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      width: '324px',
      position: { top: '136px' },
      data: dialogData,
      panelClass: ['confirm-dialog', 'warning', 'dialog'],
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult === DialogButtonsText.CONFIRM) {
        let successfulDeletions = 0;
        let failedDeletions = 0;
        let totalDeletions = 0;

        const deletionObservables: any = selectedItems.map((item: any) => {
          return this.deleteItem(item.contactId);
        });

        forkJoin(deletionObservables).subscribe({
          next: (results: any) => {
            results.forEach((deleted: any, index: number) => {
              if (deleted) {
                successfulDeletions++;
                // Remove the deleted item from the data source
                const indexInDataSource = this.dataSourceContact.data.findIndex(
                  (a) => a.contactId === selectedItems[index].contactId,
                );
                if (indexInDataSource !== -1) {
                  this.dataSourceContact.data.splice(indexInDataSource, 1);
                }
              } else {
                failedDeletions++;
              }
            });

            const loaderTimeout = 1000; // 1 second

            setTimeout(() => {
              this.selection.clear();
              this.clearContactList();
              this.dataSourceContact.data = [...this.dataSourceContact.data];
              let variables = {
                id: this.companyId,
                input: {
                  page: 1,
                  offset: this.itemsPerPage,
                },
              };
              this.contactsInCompanyQuery.refetch(variables);
              this.paginator.firstPage();
              // Update the count of total items after deletion
              this.totalItems -= successfulDeletions;

              if (failedDeletions > 0) {
                totalDeletions = successfulDeletions + failedDeletions;
                this.snackBarService.openSnackBar(
                  `${failedDeletions} out of ${totalDeletions} contact(s) were not successfully deleted`,
                  '',
                  'error',
                );
              } else {
                this.snackBarService.openSnackBar(`${successfulDeletions} contact(s) successfully deleted`, '');
              }
            }, loaderTimeout);
          },
          error: (error) => {
            this.errorService.getGqlError(error);
          },
          complete: () => {},
        });
      }
    });
  }

  unassignMultipleItems() {
    const selectedItems = this.selectedContactsList;
    let successfully = 0;
    let unsuccessfully = 0;
    if (selectedItems.length === 0) return;

    const dialogData = new ConfirmDialogModel(
      'Are you sure?',
      `Are you sure you want to unassign the selected contact(s) from the company(ies)?`,
      'Unassign',
    );

    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      width: '324px',
      position: { top: '136px' },
      data: dialogData,
      panelClass: ['confirm-dialog', 'warning', 'dialog'],
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult === DialogButtonsText.CONFIRM) {
        const companyId: ContactAddToCompaniesInput = {
          companyIds: [this.companyId],
        };

        const assignUserObservables: any = selectedItems.map((item: Contact) => {
          return this.unassignItem(item.contactId, companyId);
        });

        forkJoin(assignUserObservables).subscribe({
          next: (results: any) => {
            results.forEach((response) => {
              const actionsData = response?.contactRemoveFromCompanies?.actions?.data || [];

              // Count successes and failures
              actionsData.forEach((action: any) => {
                if (action.success) {
                  successfully++;
                } else {
                  unsuccessfully++;
                }
              });
            });
          },
          error: (error) => {
            this.errorService.getGqlError(error);
          },
          complete: () => {
            if (unsuccessfully === 0 && successfully > 0) {
              this.snackBarService.openSnackBar(
                `${successfully} contact(s) successfully unassigned from the company(ies)`,
                '',
              );
            } else if (unsuccessfully > 0) {
              this.snackBarService.openSnackBar(
                `${unsuccessfully} out of ${
                  successfully + unsuccessfully
                } contact(s) were not successfully unassigned from the company(ies)`,
                '',
                'error',
              );
            }
            this.clearContactListAndRefetch();
            this.loading = false;
          },
        });
      }
    });
  }

  private unassignItem(id, input: ContactRemoveFromCompaniesInput) {
    return this.usersService.contactRemoveFromCompanies(id, input).pipe(
      map((data) => data.data),
      catchError((error) => {
        this.errorService.getGqlError(error);
        return of(false);
      }),
    );
  }

  // Function to handle master checkbox change
  masterCheckboxChange(list: any, event: MatCheckboxChange) {
    if (event.checked) {
      list.data.forEach((element) => {
        this.addContactToList(element);
      });
    } else {
      list.data.forEach((element) => {
        this.deleteContactFromList(element);
      });
    }
  }

  // Function to check if all items are in basket
  isAllInBasket(data: any, selectedItems: Contact[]) {
    if (data && data.data && data.data.length) {
      for (let item of data.data) {
        if (!this.checkIfSelected(item, selectedItems)) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  // Function to check if an item is selected
  checkIfSelected(item: any, selectedItems: Contact[]) {
    return selectedItems.some((e) => e.contactId === item.contactId);
  }

  // Function to handle individual checkbox change
  checkboxChange(row: Contact, event: MatCheckboxChange) {
    if (event.checked) {
      this.addContactToList(row);
    } else {
      this.deleteContactFromList(row);
    }
  }

  // Function to add an item to selected list
  addContactToList(actionPayload: Contact) {
    if (this.selectedContactsList.findIndex((x) => x.contactId === actionPayload.contactId) === -1) {
      this.selectedContactsList = [...this.selectedContactsList, actionPayload];
    }
  }

  // Function to remove an item from selected list
  deleteContactFromList(actionPayload: Contact) {
    this.selectedContactsList = this.selectedContactsList.filter((x) => x.contactId !== actionPayload.contactId);
  }

  // Function to clear selected list
  clearContactList() {
    this.selectedContactsList = [];
  }

  clearContactListAndRefetch() {
    this.selectedContactsList = [];
    let variables = {
      id: this.companyId,
      input: {
        page: 1,
        offset: this.itemsPerPage,
      },
    };
    this.contactsInCompanyQuery.refetch(variables);
  }

  closeDialogEmpty() {
    this.dialogRef.close({ shouldClose: true });
  }

  backToListing() {
    this.dialogRef.close({ shouldClose: false });
  }
  addUsers() {
    this.store.dispatch(new UsersActions.AddContactsToSelectedList({ contacts: this.selectedContactsList }));
    this.dialogRef.close({ selectedUserType: 'contacts', shouldClose: true });
  }

  ngOnDestroy(): void {
    if (this.contactsInCompanyQuerySub) {
      this.contactsInCompanyQuerySub.unsubscribe();
    }
  }
}
