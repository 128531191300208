<div class="table-wrapper">
  <div class="btn-wrapper">
    <div class="mat-body-strong" *ngIf="!selectedItems?.length">
      {{ itemsCount | async }} {{ generateListingTypeLabel }}
    </div>
    <ng-container *ngIf="selectedItems?.length">
      <div mat-button *ngIf="!showSelectedItems" class="mat-body-strong">
        Selected
        <span (click)="!singleValueSelect ? (showSelectedItems = !showSelectedItems) : null" class="table-switch"
          >{{ selectedItems.length }} items:</span
        >
      </div>
      <div
        mat-button
        *ngIf="showSelectedItems"
        class="mat-body-strong"
        (click)="showSelectedItems = !showSelectedItems"
        class="table-switch"
      >
        Back to full list:
      </div>

      <button mat-button class="mat-body-strong" (click)="deleteMultipleItemsCallback()" *ngIf="shouldShowDeleteBtn">
        Delete {{ generateListingTypeLabel }}
      </button>

      <app-tags-add
        *ngIf="canAddTags"
        [selectedItems]="selectedItems"
        [refetchAction]="actionsMapper[this.listingType]?.refetch"
        (clearSelectionAndHideItems)="clearSelectionAndHideItems()"
      >
      </app-tags-add>

      <button
        mat-flat-button
        color="primary"
        class="mat-body-strong"
        (click)="closeDialogWithMultipleItems()"
        *ngIf="isDialog && listingType !== ListingTypes.valueSet"
      >
        Add {{ generateListingTypeLabel }}
      </button>
      <ng-container *ngIf="additionalSelectedItemsActions?.length > 0">
        <button
          mat-flat-button
          color="primary"
          class="mat-body-strong"
          *ngFor="let action of additionalSelectedItemsActions"
          (click)="performAction(action)"
        >
          {{ action.label }}
        </button>
      </ng-container>
    </ng-container>
    <div class="object-ml-auto">
      <button
        mat-icon-button
        class="object-ml-auto"
        (click)="exporter.exportTable('csv')"
        matTooltip="Export table"
        matTooltipPosition="above"
      >
        <mat-icon matPrefix>download</mat-icon>
      </button>
      <button
        mat-icon-button
        class="object-ml-auto"
        matTooltip="Table editor"
        matTooltipPosition="above"
        (click)="openTableEditor()"
      >
        <mat-icon matPrefix>calendar_view_month</mat-icon>
      </button>
    </div>
  </div>

  <table
    #exporter="matTableExporter"
    mat-table
    matTableExporter
    matSort
    cdkDropList
    class="main-list-table full"
    [ngClass]="listingType"
    [dataSource]="!showSelectedItems ? ((loading | async) ? dataSourceLoading : dataSource) : selectedItems"
    [cdkDropListData]="!showSelectedItems ? ((loading | async) ? dataSourceLoading : dataSource) : selectedItems"
    (matSortChange)="sortColumn($event)"
    (cdkDropListDropped)="drop($event)"
    [cdkDropListDisabled]="dragDisabled"
  >
    <ng-container matColumnDef="handle">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef>
        <button
          *ngIf="!showSelectedItems && !isViewer"
          mat-button
          [disabled]="isDragEnabled"
          (mousedown)="dragDisabled = false"
          (click)="$event.stopPropagation()"
        >
          <mat-icon>drag_indicator</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <ng-container *ngIf="!(isDialog && listingType === ListingTypes.valueSet) && showCheckbox">
          <mat-checkbox
            (change)="
              checkboxService.masterCheckboxChange(
                !showSelectedItems ? dataSource : selectedItems,
                $event,
                listingType,
                stateEntry,
                selectedTab
              )
            "
            [checked]="checkboxService.isAllInBasket(dataSource, selectedItems)"
            [disabled]="isViewer"
            color="primary"
            *ngIf="!(loading | async) && !showSelectedItems && !singleValueSelect"
          >
          </mat-checkbox>
          <div class="actionBtns" *ngIf="showSelectedItems">
            <button
              mat-button
              class="icon-btn"
              (click)="
                checkboxService.clearSelectionList(listingType, stateEntry, selectedTab); showSelectedItems = false
              "
            >
              <mat-icon>remove_circle_outline</mat-icon>
            </button>
          </div>
        </ng-container>
      </th>

      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="!(isDialog && listingType === ListingTypes.valueSet) && showCheckbox">
          <mat-checkbox
            *ngIf="!(loading | async) && !singleValueSelect"
            (click)="$event.stopPropagation()"
            [checked]="checkboxService.checkIfSelected(row, selectedItems)"
            [disabled]="isViewer || checkboxService.shouldDisableCheckbox(row, listingType)"
            (change)="checkboxService.checkboxChange(row, $event, listingType, stateEntry, selectedTab)"
            color="primary"
          >
          </mat-checkbox>
          <ngx-skeleton-loader
            count="1"
            *ngIf="loading | async"
            [theme]="{ height: '24px', display: 'flex', 'margin-bottom': '0' }"
          >
          </ngx-skeleton-loader>
        </ng-container>
      </td>
    </ng-container>

    <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column.name">
      <ng-container *ngIf="column.sortable; else nonSortableHeader">
        <th mat-header-cell *matHeaderCellDef [mat-sort-header]="column.name" [disabled]="showSelectedItems">
          {{ column.label }}
        </th>
      </ng-container>
      <ng-template #nonSortableHeader>
        <th mat-header-cell *matHeaderCellDef>{{ column.label }}</th>
      </ng-template>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="column.name === 'logo'">
          <span *ngIf="!(loading | async)">
            <img
              *ngIf="element[column.name] | logoValidity | async; else noImage"
              [src]="element[column.name]"
              alt="Logo"
            />
            <ng-template #noImage>
              <img src="../../../../assets/images/no-image.jpg" alt="No Image" />
            </ng-template>
          </span>
        </ng-container>
        <ng-container *ngIf="!(loading | async)">
          <div *ngIf="column.type === TableColumnType.date">
            <span>{{ column | content: element | timeAgo }}</span>
            <br />
            <span>{{ column | content: element | date: 'd MMM y' }}</span>
          </div>
          <ng-container *ngIf="column.type === TableColumnType.html">
            <div [innerHtml]="sanitizer.bypassSecurityTrustHtml(column | content: element)"></div>
          </ng-container>
          <ng-container *ngIf="column.type === TableColumnType.icon">
            <mat-icon [class]="column | content: element">{{ column | content: element }}</mat-icon>
          </ng-container>
          <ng-container *ngIf="column.type === TableColumnType.array">
            <mat-chip-set *ngIf="(column | content: element).length">
              <mat-chip *ngFor="let item of column | content: element" (click)="$event.stopPropagation()">
                <span class="mat-caption chip-item">{{ item }}</span>
              </mat-chip>
            </mat-chip-set>
          </ng-container>
          <ng-container>
            <span *ngIf="!column.type">{{ column | content: element }}</span>
          </ng-container>
        </ng-container>
        <ngx-skeleton-loader
          count="1"
          *ngIf="loading | async"
          [theme]="{ height: '24px', display: 'flex', 'margin-bottom': '0' }"
        ></ngx-skeleton-loader>

        <!-- Include action buttons here -->
        <ng-container *ngIf="column.name === 'actions' && !(loading | async)">
          <button
            mat-button
            class="mat-body-strong"
            *ngFor="let action of actionItems"
            [class]="'custom-button ' + action.type"
            (click)="performActionItem(action, element); $event.stopPropagation()"
          >
            <mat-icon *ngIf="action.icon">{{ action.icon }}</mat-icon>
            {{ action.label }}
          </button>
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumnsObj.displayedColumns"></tr>
    <tr
      mat-row
      cdkDrag
      cdkDragPreviewContainer="parent"
      [cdkDragData]="row"
      *matRowDef="let row; columns: tableColumnsObj.displayedColumns"
      (click)="onRowClick(row)"
      matTooltipPosition="above"
      [matTooltip]="tooltipMessage"
      [matTooltipShowDelay]="500"
      [matTooltipDisabled]="!shouldShowTooltip"
    ></tr>
  </table>

  <div class="paginator-wrapper">
    <app-go-to-page
      #goToPageComponent
      [totalPages]="totalPages | async"
      (onGoToPage)="onGoToPage($event)"
      [style.display]="showSelectedItems ? 'none' : 'block'"
    ></app-go-to-page>

    <mat-paginator
      [length]="itemsCount | async"
      [pageSize]="itemsPerPage"
      [pageSizeOptions]="showPageSizeOptions ? [5, 10, 25, 100] : [itemsCount | async]"
      aria-label="Select page of users"
      [style.display]="showSelectedItems ? 'none' : 'block'"
    ></mat-paginator>
  </div>
</div>
