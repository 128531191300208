import { gql } from 'apollo-angular';
import { globals } from 'src/app/shared/globals';
import { TenderAddressFragment, TenderFragments } from '../fragments/tender.fragments';

export const TenderQuery = gql`
  query Tender($orderId: Int, $tenderId: String) {
    tender(orderId: $orderId, tenderId: $tenderId) {
      ...TenderData
      user {
        firstName
        middleName
        lastName
        email
        phone
        debtorId
        ... on Contact {
          contactId
          company {
            companyId
            name
          }
        }
        ... on Customer {
          customerId
        }
      }
      company {
        ...TenderCompany
      }
      deliveryAddress {
        ...TenderAddress
      }
      invoiceAddress {
        ...TenderAddress
      }
      items {
        childItems {
          ...TenderChildItems
        }
        ...TenderMainItem
      }
      bonusItems {
        ...TenderMainItem
      }
      carriers {
        ...TenderCarrier
      }
      payMethods {
        ...TenderPaymethod
      }
      paymentData {
        ...TenderPayment
      }
      postageData {
        ...TenderPostage
      }
      total {
        ...TenderTotal
      }
    }
  }
  ${TenderFragments.address}
  ${TenderFragments.total}
  ${TenderFragments.paymentData}
  ${TenderFragments.postageData}
  ${TenderFragments.items}
  ${TenderFragments.childItems}
  ${TenderFragments.carriers}
  ${TenderFragments.paymethods}
  ${TenderFragments.data}
  ${TenderFragments.company}
`;
