import { gql } from 'apollo-angular';
import { CarrierFragments } from '../fragments/carrier-fragments';
import { WarehouseFragments } from '../fragments/warehouse.fragments';

export const WarehouseUpdate = gql`
  mutation WarehouseUpdate($id: Float!, $input:UpdateWarehouseInput!) {
    warehouseUpdate(id: $id, input:$input) {
        address {
            ...address
        }
        ...allFields
        }
    }
    ${WarehouseFragments.warehouseAllFields},
    ${WarehouseFragments.address},
`;

export const WarehouseAddressCreate = gql`
  mutation WarehouseAddressCreate($id: Float!, $input:CreateWarehouseAddressInput!) {
    warehouseAddressCreate(id: $id, input:$input) {
        ...address
    }
}
    ${WarehouseFragments.address},
`;

export const WarehouseAddressUpdate = gql`
  mutation WarehouseAddressUpdate($id: Float!, $input:UpdateWarehouseAddressInput!) {
    warehouseAddressUpdate(id: $id, input:$input) {
        ...address
    }
}
    ${WarehouseFragments.address},
`;

export const WarehouseAddressDelete = gql`
  mutation WarehouseAddressDelete($id: Float!, $input: DeleteWarehouseAddressInput!) {
    warehouseAddressDelete(id: $id, input: $input)
  }
`;

export const WarehouseCreate = gql`
  mutation WarehouseCreate($input:CreateWarehouseInput!) {
    warehouseCreate(input:$input) {
      address {
          ...address
        }
      ...allFields
      }
    }
    ${WarehouseFragments.warehouseAllFields},
    ${WarehouseFragments.address},
`;

export const WarehouseDelete = gql`
  mutation WarehouseDelete($id: Float!) {
    warehouseDelete(id: $id) {
      name
    }
  }
`;
