import { gql } from 'apollo-angular';
import { SurchargesFragments } from '../fragments/surcharges-fragments';

export const SurchargesQuery = gql`
  query SurchargesQuery($input: SurchargeSearchInput) {
    surcharges(input: $input) {
      itemsFound
      page
      items {
        id
        name {
          language
          value
        }
        description {
          language
          value
        }
        type
        value
        taxCode
        taxZone
        enabled
        validFrom
        validTo
        createdBy
        changedBy
        dateCreated
        dateChanged
        shopId
      }
    }
  }
`;

export const SurchargesList = gql`
  query surcharges($input: SurchargeSearchInput) {
    surcharges(input: $input) {
      items {
        ...surcharge
      }
      itemsFound
      offset
      page
      pages
      start
      end
    }
  }
  ${SurchargesFragments.surcharge}
`;
