import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { ContactsListQuery } from '../../shared/api/queries/contact-query';
import { CustomerListQuery } from '../../shared/api/queries/customer-queries';
import {
  CompanyListResponse,
  CompanySearchListResponse,
  ContactListResponse,
  CustomerListResponse,
} from '../../shared/api/types/types';
import { AllCompaniesQuery, GetCompaniesSearchQuery } from '../../shared/api/queries/company-queries';
import {
  CompanySearchInput,
  ContactAddToCompaniesInput,
  ContactAddToCompaniesResponse,
  ContactRemoveFromCompaniesInput,
  ContactRemoveFromCompaniesResponse,
  CreateCompanyInput,
  CreateContactInput,
  CustomerInput,
  UsergroupsResponse,
} from '../../shared/api/types/GraphQL';
import {
  ContactAddToCompanies,
  ContactRemoveFromCompanies,
  CreateContactMutation,
  DeleteContactMutation,
} from '../../shared/api/mutations/contact-mutations';
import { CreateCustomerMutation, DeleteCustomerMutation } from '../../shared/api/mutations/customer-mutations';
import { CreateCompanyMutation, DeleteCompanyMutation } from '../../shared/api/mutations/company-mutations';
import { UserTypes, UserTypesPlural } from '../../shared/api/types/enums';
import { UsergroupsListQuery } from '../../shared/api/queries/usergroup-queries';

@Injectable({ providedIn: 'root' })
export class UsersService {
  constructor(private apollo: Apollo) {}

  getContacts(variables: {}): QueryRef<ContactListResponse> {
    return this.apollo.watchQuery<ContactListResponse>({
      query: ContactsListQuery,
      variables: { input: variables },
    });
  }
  getCustomers(variables: {}): QueryRef<CustomerListResponse> {
    return this.apollo.watchQuery<CustomerListResponse>({
      query: CustomerListQuery,
      variables: { input: variables },
    });
  }
  getCompanies(variables: {}): QueryRef<CompanyListResponse> {
    return this.apollo.watchQuery<CompanyListResponse>({
      query: AllCompaniesQuery,
      variables: { input: variables },
    });
  }

  getUsergroups(variables: {}): QueryRef<UsergroupsResponse> {
    return this.apollo.watchQuery<UsergroupsResponse>({
      query: UsergroupsListQuery,
      variables: { input: variables },
    });
  }

  private createContact(variables: CreateContactInput) {
    return this.apollo.mutate<any>({
      mutation: CreateContactMutation,
      variables: { input: variables },
    });
  }

  private createCustomer(variables: CustomerInput) {
    return this.apollo.mutate<any>({
      mutation: CreateCustomerMutation,
      variables: { input: variables },
    });
  }

  private createCompany(variables: CreateCompanyInput) {
    return this.apollo.mutate<any>({
      mutation: CreateCompanyMutation,
      variables: { input: variables },
    });
  }

  private deleteContact(id: number) {
    return this.apollo.mutate<any>({
      mutation: DeleteContactMutation,
      variables: { id },
    });
  }
  private deleteCompany(id: number) {
    return this.apollo.mutate<any>({
      mutation: DeleteCompanyMutation,
      variables: { id },
    });
  }
  private deleteCustomer(id: number) {
    return this.apollo.mutate<any>({
      mutation: DeleteCustomerMutation,
      variables: { id },
    });
  }
  deleteUser(id: number, userType: UserTypes) {
    switch (userType) {
      case UserTypes.customer:
        return this.deleteCustomer(id);
      case UserTypes.company:
      case UserTypes.companySearch:
        return this.deleteCompany(id);
      case UserTypes.contact:
        return this.deleteContact(id);
      default:
        throw new Error('Invalid user type');
    }
  }

  createUser(selectedTab: UserTypesPlural, user: CreateCompanyInput | CustomerInput | CreateContactInput) {
    if (selectedTab === UserTypesPlural.customers) {
      return this.createCustomer(user as CustomerInput);
    } else if (selectedTab === UserTypesPlural.contacts) {
      return this.createContact(user as CreateContactInput);
    } else {
      return this.createCompany(user as CreateCompanyInput);
    }
  }
  getCompanySearchList(variables: { input: CompanySearchInput }): QueryRef<CompanySearchListResponse> {
    return this.apollo.watchQuery<CompanySearchListResponse>({
      query: GetCompaniesSearchQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
  }
  private referringUrl: string;

  setReferringUrl(url: string) {
    this.referringUrl = url;
  }

  getReferringUrl(): string {
    return this.referringUrl;
  }

  contactAddToCompanies(id: number, variables: ContactAddToCompaniesInput) {
    return this.apollo.mutate<ContactAddToCompaniesResponse>({
      mutation: ContactAddToCompanies,
      variables: { id, input: variables },
    });
  }

  contactRemoveFromCompanies(id: number, variables: ContactRemoveFromCompaniesInput) {
    return this.apollo.mutate<ContactRemoveFromCompaniesResponse>({
      mutation: ContactRemoveFromCompanies,
      variables: { id, input: variables },
    });
  }
}
