import { gql } from 'apollo-angular';

export const ValueSetCreate = gql`
  mutation ValueSetCreate($input: ValuesetCreateInput!) {
    valuesetCreate(input: $input) {
      id
      name
      type
      descriptions {
        language
        value
      }
      valuesetItems {
        itemsFound
      }
      lastModifiedBy
      lastModifiedAt
      createdBy
      createdAt
    }
  }
`;
export const ValueSetUpdate = gql`
  mutation ValueSetUpdate($id: Int!, $input: ValuesetUpdateInput!) {
    valuesetUpdate(id: $id, input: $input) {
      id
      name
      type
      descriptions {
        language
        value
      }
      valuesetItems {
        itemsFound
      }
      lastModifiedBy
      lastModifiedAt
      createdBy
      createdAt
    }
  }
`;
export const ValueSetDelete = gql`
  mutation ValueSetDelete($id: Int!) {
    valuesetDelete(id: $id)
  }
`;
