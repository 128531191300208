import { gql } from 'apollo-angular';
import { AttributeFragments } from '../fragments/attribute-fragments';
import { environment } from 'src/environments/environment';
export const AllCompaniesQuery = gql`
  query AllCompaniesQuery($input: CompanySearchArguments!) {
    companies(input: $input) {
      page
      itemsFound
      offset
      items {
        companyId
        contacts {
          itemsFound
        }
          addresses(type: invoice, isDefault: Y) {
          city
          country
          phone
        }
        name
        attributes(input: { attributeDescription: { names: "${environment.userTagsAttributeName}" } }) {
          items {
            attribute {
             id
            }
            attributeDescription {
              id
            }
            value {
              ... on AttributeEnumValue {
                enumValues
              }
            }
          }
        }

      }
    }
  }
`;

export const CompanyListQuery = gql`
  query CompanyListQuery(
    $name: String
    $page: Int
    $offset: Int
    $sort: [CompanySortInput!]
    $managedCompaniesOnly: Boolean
  ) {
    companies(
      input: { name: $name, page: $page, offset: $offset, sort: $sort, managedCompaniesOnly: $managedCompaniesOnly }
    ) {
      page
      itemsFound
      offset
      items {
        createdAt
        companyId
        name
        notes
        cocNumber
        taxNumber
        createdAt
        lastModifiedAt
        addresses(type: invoice) {
          city
          country
          street
          phone
        }
        usergroup {
          name
        }
        attributes(input: { attributeDescription: { names: "${environment.userTagsAttributeName}" } }) {
          items {
            attribute {
             id
            }
            attributeDescription {
              id
            }
            value {
              ... on AttributeEnumValue {
                enumValues
              }
            }
          }
        }
      }
    }
  }
`;

export const CompaniesDropdownQuery = gql`
  query CompaniesDropdownQuery($input: CompanySearchArguments!) {
    companies(input: $input) {
      page
      itemsFound
      offset
      items {
        companyId
        name
      }
    }
  }
`;

export const CompaySearchDropdownQuery = gql`
  query CompaySearchDropdownQuery($input: CompanySearchInput) {
    companySearch(input: $input) {
      page
      pages
      itemsFound
      offset
      items {
        id
        name
      }
    }
  }
`;

export const CompanyQuery = gql`
  query CompanyQuery($id: Int!) {
    company(id: $id) {
      name
      contacts {
        itemsFound
        offset
        page
        items {
          email
          firstName
          contactId
          lastName
          middleName
          mobile
          phone
          primaryLanguage
          mailingList
        }
      }
      addresses {
        id
        firstName
        middleName
        lastName
        company
        email
        code
        city
        country
        gender
        icp
        isDefault
        number
        numberExtension
        mobile
        phone
        postalCode
        region
        street
        type
      }
    }
  }
`;

export const SelectedCompanyConatctsQuery = gql`
  query SelectedCompanyConatctsQuery($id: Int!, $page: Int!) {
    company(id: $id) {
      name
      companyId
      contacts(input: { page: $page }) {
        itemsFound
        offset
        page
        pages
        items {
          firstName
          contactId
          lastName
          middleName
          company {
            companyId
          }
        }
      }
    }
  }
`;

export const CompanyDetailsQuery = gql`
  query CompanyDetailsQuery($id: Int!) {
    company(id: $id) {
      companyId
      name
      taxNumber
      cocNumber
      debtorId
      notes
      path
      slug
      tag
      createdAt
      lastModifiedAt
      parentUsergroupId
      sources {
        name
        id
      }
    }
  }
`;

export const CompanyAdressesQuery = gql`
  query CompanyAdressesQuery($type: AddressType!, $companyId: Float!) {
    addressesByCompanyId(type: $type, companyId: $companyId) {
      id
      firstName
      middleName
      lastName
      gender
      company
      street
      number
      numberExtension
      postalCode
      city
      region
      country
      phone
      mobile
      email
      fax
      code
      notes
      isDefault
      type
      icp
      url
      active
      name
    }
  }
`;

export const CompanyDetailsAttributesQuery = gql`
  query companyDetailsAttributes($id: Int!, $input: AttributeResultSearchInput) {
    company(id: $id) {
      attributes(input: $input) {
        items {
          attribute {
            id
            value {
              id
            }
          }
          attributeDescription {
            id
            name
            valuesetId
            descriptions {
              language
              value
            }
            type
            group
            isSearchable
            isPublic
            isSystem
            isHidden
            defaultValue {
              ...attrValue
            }
            createdAt
            lastModifiedAt
          }
          value {
            id
            ... on AttributeColorValue {
              id
              type
              colorValue
            }
            ... on AttributeDateTimeValue {
              id
              type
              dateTimeValue
            }
            ... on AttributeDecimalValue {
              id
              type
              decimalValue
            }
            ... on AttributeEnumValue {
              enumValues
            }
            ... on AttributeIntValue {
              id
              type
              intValue
            }
            ... on AttributeTextValue {
              textValues {
                language
                values
              }
            }
          }
        }
        itemsFound
        page
      }
    }
  }
  ${AttributeFragments.attributeValue}
`;

export const GetCompaniesSearchQuery = gql`
  query getCompaniesSearchQuery($input: CompanySearchInput!) {
    companySearch(input: $input) {
      items {
        id
        name
        taxNumber
        cocNumber
        notes

        createdAt
        lastModifiedAt
        addresses {
          mobile
          phone
          country
          city
        }
        contacts {
          debtorId
          phone
          mobile
        }
        attributes {
          id
          value {
            id
            type
          }
          attributeDescription {
            id
            name
          }
        }
      }
      itemsFound
      offset
      page
      pages
      start
      end
    }
  }
`;

export const ContactsInCompanyQuery = gql`
  query ContactsInCompanyQuery($id: Int!, $input: ContactSearchArguments) {
  company(id: $id) {
    contacts(input: $input) {
      items {
        contactId
        firstName
        lastName
        middleName
        attributes(
          input: {
            attributeDescription: { names: "${environment.userTagsAttributeName}" }
          }
        ) {
          items {
            attribute {
              id
            }
            attributeDescription {
              id
            }
            value {
              ... on AttributeEnumValue {
                enumValues
              }
            }
          }
        }
        phone
        email
      }
      page
      itemsFound
    }
  }
}
`;
