import { TableColumns } from 'src/app/shared/api/types/interfaces';
import { FavoriteList } from '../../../shared/api/types/GraphQL';
import * as FavoritesActions from './favorites.actions';
import { FavoriteStateEntry } from 'src/app/shared/api/types/enums';

type StateProperties = {
  loading: boolean;
  loaded: boolean;
  currentPage: number;
  currentPageSize: number;
  favoriteLists: FavoriteList[];
  selectedFavoriteListsList: FavoriteList[];
  generalFilterValue: string;
  favoriteListsCount: number;
  productTableColumns: TableColumns;
};

export interface State {
  favoriteListing: StateProperties;
  favoriteUserListing: StateProperties;
  stateEntry: FavoriteStateEntry;
}

const initialStateProperties: StateProperties = {
  loading: false,
  loaded: false,
  currentPage: 1,
  currentPageSize: 5,
  favoriteLists: [],
  selectedFavoriteListsList: [],
  generalFilterValue: '',
  favoriteListsCount: 0,
  productTableColumns: {
    displayedColumns: ['select', 'sku', 'name', 'supplier'],
    hiddenColumns: [],
    disabledSuffixLength: 0,
    availableColumns: [],
    disabledColumns: [],
  },
};

const initialState: State = {
  favoriteListing: initialStateProperties,
  favoriteUserListing: initialStateProperties,
  stateEntry: FavoriteStateEntry.favoriteListing,
};

export function favoriteListsReducer(state: State = initialState, action: FavoritesActions.FavoriteListsActions) {
  switch (action.type) {
    case FavoritesActions.SET_STATE_ENTRY:
      return {
        ...state,
        stateEntry: action.payload.stateEntry,
      };
    case FavoritesActions.RESET_STATE_ENTRY_STATE:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          ...initialStateProperties,
        },
      };
    case FavoritesActions.GET_CURRENT_PAGE:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          currentPage: action.payload.page,
        },
      };
    case FavoritesActions.GET_CURRENT_PAGE_SIZE:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          currentPageSize: action.payload.pageSize,
        },
      };
    case FavoritesActions.LOAD_FAVORITE_LISTS:
      let loading;
      state[action.payload.stateEntry].loaded ? (loading = false) : (loading = true);
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          loading: loading,
          loaded: false,
        },
      };
    case FavoritesActions.REFETCH_FAVORITE_LISTS:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          loaded: false,
          loading: true,
        },
      };
    case FavoritesActions.LOAD_FAVORITE_LISTS_SUCCESS:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          favoriteLists: [...action.payload.items],
          loading: false,
          loaded: true,
        },
      };
    case FavoritesActions.LOAD_FAVORITE_LISTS_FAILURE:
      return {
        ...state,
        [action.payload.stateEntry]: {
          error: action.payload.error,
          loading: false,
        },
      };
    case FavoritesActions.GET_FAVORITE_LISTS_COUNT:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          favoriteListsCount: action.payload.count,
        },
      };
    case FavoritesActions.ADD_FAVORITE_LIST_TO_SELECTED_LISTS:
      const { selectedFavoriteListsList, stateEntry } = action.payload;
      if (state[stateEntry].selectedFavoriteListsList.map((x) => x.id).indexOf(selectedFavoriteListsList.id) === -1) {
        return {
          ...state,
          [stateEntry]: {
            ...state[stateEntry],
            selectedFavoriteListsList: [...state[stateEntry].selectedFavoriteListsList, selectedFavoriteListsList],
          },
        };
      }
      return state;

    case FavoritesActions.DELETE_FAVORITE_LIST_FROM_SELECTED_LIST:
      const { id: idToDelete, stateEntry: deleteStateEntry } = action.payload;
      const index = state[deleteStateEntry].selectedFavoriteListsList.map((x) => x.id).indexOf(idToDelete);
      if (index !== -1) {
        const updatedSelectedLists = state[deleteStateEntry].selectedFavoriteListsList.filter((list, i) => {
          return i !== index;
        });
        return {
          ...state,
          [deleteStateEntry]: {
            ...state[deleteStateEntry],
            selectedFavoriteListsList: updatedSelectedLists,
          },
        };
      }
      return state;
    case FavoritesActions.CLEAR_SELECTED_FAVORITES_LISTS:
      const { stateEntry: ClearStateEntry } = action.payload;
      return {
        ...state,
        [ClearStateEntry]: {
          ...state[ClearStateEntry],
          selectedFavoriteListsList: [],
        },
      };
    case FavoritesActions.GET_GENERAL_FILTER:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          generalFilterValue: action.payload.filterValue,
        },
      };

    default:
      return state;
  }
}
