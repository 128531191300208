import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { QueryRef } from 'apollo-angular';
import { takeUntil, tap, map, Subject, debounceTime, fromEvent, catchError, of } from 'rxjs';
import { CompanySearch, Company, Contact, CompanyTermFieldName } from '../../api/types/GraphQL';
import { CompaniesService } from 'src/app/companies/companies.service';
import { GqlErrorService } from '../../services/gql-error.service';
import * as CatalogActions from 'src/app/catalog/store/catalog.actions';
import { Store } from '@ngrx/store';
import * as fromApp from 'src/app/store/app.reducer';

@Component({
  selector: 'app-contact-in-company-select',
  templateUrl: './contact-in-company-select.component.html',
  styleUrls: ['./contact-in-company-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactInCompanySelectComponent implements OnInit, AfterViewInit, OnDestroy {
  companiesQuery: QueryRef<any>;

  companiesDropdownObject: CompanySearch[] = [];
  companiesFilteredDropdownObject: CompanySearch[] = [];
  @Input() selectedCompany: CompanySearch;
  @Input() selectedContact: Contact;
  @Input() isAnonymous: boolean;

  @Output() contactSelected = new EventEmitter<Contact>();
  @Output() isAnonymousEmit = new EventEmitter<boolean>();

  companiesDropdownPage = 1;
  companiesDropdownPages: number;
  isCompaniesFiltered: boolean = false;

  contactsDropdownPage = 1;
  contactsDropdownTotalItems: number;

  selectedUser: string = 'anonymous';
  selectedUserObj: Contact;

  allContacts: Contact[] = [];
  allContactsFiltered: Contact[] = [];

  @Input() onContactSelect!: (company: CompanySearch, contact: Contact) => void;

  destroySubject: Subject<void> = new Subject<void>();

  @ViewChild('companySearchInput', { static: true }) companySearchInput: ElementRef;

  constructor(
    private companiesService: CompaniesService,
    private errorService: GqlErrorService,
    private store: Store<fromApp.AppState>,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (this.selectedCompany) {
      const preselectedCompanyInList = this.companiesDropdownObject.some(
        (company) => company.id === this.selectedCompany.id,
      );

      if (!preselectedCompanyInList) {
        this.companiesDropdownObject.unshift(this.selectedCompany);
      }
      if (this.selectedContact) this.onContactsLoad(1, false);
    }

    this.initCompanyQuery();
    this.loadCompanies();
  }

  ngAfterViewInit(): void {
    this.setupDebouncedInput(this.companySearchInput.nativeElement, 'company-search');
  }

  initCompanyQuery(): void {
    this.companiesQuery = this.companiesService.getCompanysearchDropdown({
      page: this.companiesDropdownPage,
    });
  }

  loadCompanies(): void {
    this.companiesQuery.valueChanges
      .pipe(
        takeUntil(this.destroySubject),
        tap((data) => {
          this.companiesDropdownPages = data.data.companySearch.pages;
        }),
        map((data) => data.data.companySearch.items),
        catchError(() => {
          return of([]);
        }),
      )
      .subscribe({
        next: (companies) => {
          this.companiesDropdownObject = [...this.companiesDropdownObject, ...companies];
          this.companiesFilteredDropdownObject = [...companies];
        },
      });
  }

  handleCompanySearch(filter: string): void {
    if (filter.length < 2) {
      let error = 'Search term must be at least 2 characters long';

      this.isCompaniesFiltered = false;
      this.companiesDropdownObject = [];

      if (filter.length === 0) {
        this.companiesQuery
          .refetch({
            input: {
              page: 1, // Reset to the first page
              termFields: [
                {
                  fieldNames: [CompanyTermFieldName.CompanyName],
                  boost: 1,
                },
              ],
            },
          })
          .then((result) => {
            let data = result.data.companySearch.items;
            this.companiesDropdownObject = [...data];
          });
        return;
      }
      this.errorService.getGqlError(error);
      console.warn(error);
      return;
    }

    this.isCompaniesFiltered = true;
    this.companiesDropdownPage = 1;
    this.companiesDropdownObject = [];

    this.companiesQuery.refetch({
      input: {
        term: filter,
        page: this.companiesDropdownPage,
        termFields: [
          {
            fieldNames: [CompanyTermFieldName.CompanyName],
            boost: 1,
          },
        ],
      },
    });
  }

  setupDebouncedInput(element: HTMLElement, className: string) {
    fromEvent(element, 'keyup')
      .pipe(
        debounceTime(300),
        map((event: any) => ({ value: event.target.value, classList: event.target.classList })),
      )
      .subscribe((data) => this.onKey(data.value, data.classList, className));
  }

  onContactsLoad(page: number, isNewCompanySelected: boolean = false): void {
    this.store.dispatch(new CatalogActions.SetImpersonateCompany(this.selectedCompany));

    this.companiesService
      .getSelectedCompanyContacts({ id: this.selectedCompany.id, page })
      .valueChanges.pipe(
        takeUntil(this.destroySubject),
        map((data) => data.data.company),
      )
      .subscribe({
        next: (company) => this.handleCompanyContactsLoad(company, isNewCompanySelected),
        error: (error) => this.errorService.getGqlError(error),
      });
  }

  handleCompanyContactsLoad(company: Company, isNewCompanySelected: boolean): void {
    if (isNewCompanySelected) {
      this.resetContactsSelection();
    }
    this.allContacts = isNewCompanySelected
      ? company.contacts?.items || []
      : [...this.allContacts, ...company.contacts?.items];
    this.contactsDropdownTotalItems = company.contacts?.itemsFound || 0;

    if (this.selectedContact) {
      const preselectedContactInList = this.allContacts.some(
        (contact) => contact.contactId === this.selectedContact.contactId,
      );

      if (!preselectedContactInList) {
        this.allContacts.unshift(this.selectedContact);
      } else {
        this.selectedContact =
          this.allContacts.find((contact) => contact.contactId === this.selectedContact.contactId) || null;
      }
    }
  }

  resetContactsSelection(): void {
    this.isAnonymous = true;
    this.isAnonymousChanged();
    this.selectedContact = undefined;
    this.selectContact();
    this.contactsDropdownPage = 1;
  }

  selectContact(): void {
    this.contactSelected.emit(this.selectedContact);
  }

  isAnonymousChanged(): void {
    this.isAnonymousEmit.emit(this.isAnonymous);
  }

  onKey(filter: string, classList: DOMTokenList, className: string): void {
    if (classList.contains(className)) {
      if (className === 'company-search') {
        this.handleCompanySearch(filter);
      }
    }
  }

  loadMore(event: any, loadMoreFor: string): void {
    if (loadMoreFor === 'companies') {
      this.loadMoreCompanies(event);
    } else if (loadMoreFor === 'contacts') {
      this.loadMoreContacts(event);
    }
  }

  loadMoreCompanies(event: any): void {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      if (this.companiesDropdownPages > this.companiesDropdownPage) {
        this.companiesDropdownPage++;
        this.companiesQuery.refetch({ input: { page: this.companiesDropdownPage } });
      }
    }
  }

  loadMoreContacts(event: any): void {
    if (event.target.offsetHeight + event.target.scrollTop + 1 >= event.target.scrollHeight) {
      if (this.contactsDropdownTotalItems > this.allContacts.length) {
        this.contactsDropdownPage++;
        this.onContactsLoad(this.contactsDropdownPage);
      }
    }
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
