import { Injectable } from '@angular/core';
import { Actions, createEffect, EffectNotification, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, exhaustMap, filter, map, Observable, of, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs';
import * as CompaniesActions from './companies.actions';
import * as fromApp from '../../../store/app.reducer';
import { CompaniesService } from '../../companies.service';
import { QueryRef } from 'apollo-angular';
import { CompanyListResponse } from 'src/app/shared/api/types/types';

@Injectable()
export class CompaniesEffects {
  companiesQuery: QueryRef<CompanyListResponse>;
  companiesQuerySub: Observable<Action>;

  constructor(
    private actions: Actions,
    private companiesService: CompaniesService,
    private store: Store<fromApp.AppState>,
  ) {}

  public readonly loadCompanies: Observable<Action> = createEffect(() => {
    return this.actions.pipe(
      ofType(CompaniesActions.LOAD_COMPANIES),
      withLatestFrom(this.store.select((state) => state.companies.loaded)),
      filter(([action, loaded]) => !loaded),
      switchMap(([action, loaded]: [CompaniesActions.LoadCompanies, boolean]) => {
        this.companiesQuery = this.companiesService.getCompanyList(action.payload);
        // this.companiesQuery = this.companiesService.getCompanySearchList(action.payload);
        this.companiesQuerySub = this.companiesQuery.valueChanges.pipe(
          map((data) => {
            this.store.dispatch(new CompaniesActions.GetCompaniesCount(data.data.companies.itemsFound));
            return new CompaniesActions.LoadCompaniesSuccess(data.data.companies.items);
          }),
          catchError((error) => of(new CompaniesActions.LoadCompaniesFailure(error))),
        );
        return this.companiesQuerySub;
      }),
    );
  });

  public readonly refetchCompanies = createEffect(
    () =>
      this.actions.pipe(
        ofType(CompaniesActions.REFETCH_COMPANIES),
        tap((action: CompaniesActions.RefetchCompanies) => {
          this.companiesQuery.resetLastResults();
          this.companiesQuery.refetch(action.payload);
        }),
      ),
    { dispatch: false },
  );

  public readonly refetchCompanySearch = createEffect(() =>
    this.actions.pipe(
      ofType(CompaniesActions.REFETCH_COMPANY_SEARCH),
      switchMap((action: CompaniesActions.RefetchCompanySearch) => {
        const searchQuery = this.companiesService.getCompanySearchList({ input: action.payload });
        return searchQuery.valueChanges.pipe(
          map((data) => {
            this.store.dispatch(new CompaniesActions.GetCompaniesCount(data.data.companySearch.itemsFound));
            return new CompaniesActions.LoadCompaniesSuccess(data.data.companySearch.items);
          }),
          catchError((error) => of(new CompaniesActions.LoadCompaniesFailure(error))),
        );
      }),
    ),
  );

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>): Observable<EffectNotification> {
    return this.actions.pipe(
      ofType(CompaniesActions.COMPANIES_PAGE_INITIALIZED),
      exhaustMap(() =>
        resolvedEffects$.pipe(takeUntil(this.actions.pipe(ofType(CompaniesActions.COMPANIES_PAGE_DESTROYED)))),
      ),
    );
  }
}
