import { Action } from '@ngrx/store';
import { Tenant } from './auth.reducer';
import { Role } from 'src/app/shared/api/types/GraphQL';
import { Auth, MultiFactorResolver, PhoneAuthProvider } from '@angular/fire/auth';

export const LOGIN_START = '[Auth] Login Start';
export const LOGIN = '[Auth] Login';
export const LOGIN_VERIFICATION_START = '[Auth] Login Verification Start';
export const LOGIN_VERIFICATION_SEND = '[Auth] Login Verification Send';
export const WAIT_FOR_VERIFICATION_CODE = '[Auth] Wait for verification code';
export const AUTO_LOGIN = '[Auth] Auto Login';
export const LOGIN_ERROR = '[Auth] Login Error';
export const LOGOUT_START = '[Auth] Logout Start';
export const LOGOUT = '[Auth] Logout';
export const SET_TENANTS = '[Auth] Set tenants';
export const SET_VIEWER_ROLES = '[Auth] Set viewer roles';
export const SET_ACTIVE_TENANT = '[Auth] Set active tenant';
export const SET_ADMIN_TENANT = '[Auth] Set admin tenant id';
export const SET_USER_ID = '[Auth] Set user id';
export const CLEAR_STATE = '[Auth] Clear state';

export const SET_APP_LANGUAGE = '[Auth] Set app language';

export class Login implements Action {
  readonly type = LOGIN;

  constructor(public payload: {}) {}
}

export class AutoLogin implements Action {
  readonly type = AUTO_LOGIN;

  constructor(public payload: {}) {}
}

export class SetTenants implements Action {
  readonly type = SET_TENANTS;

  constructor(public payload: Tenant[]) {}
}

export class SetViewerRoles implements Action {
  readonly type = SET_VIEWER_ROLES;

  constructor(public payload: Role[]) {}
}

export class SetActiveTenant implements Action {
  readonly type = SET_ACTIVE_TENANT;

  constructor(public payload: Tenant) {}
}

export class SetAdminTenant implements Action {
  readonly type = SET_ADMIN_TENANT;

  constructor(public payload: string) {}
}

export class SetUserId implements Action {
  readonly type = SET_USER_ID;

  constructor(public payload: number) {}
}

export class LoginStart implements Action {
  readonly type = LOGIN_START;

  constructor(
    public payload: {
      email: string;
      password: string;
      persistentLogin: boolean;
      recaptchaToken?: any;
    },
  ) {}
}

export class LoginVerificationStart implements Action {
  readonly type = LOGIN_VERIFICATION_START;
}

export class LoginVerificationSend implements Action {
  readonly type = LOGIN_VERIFICATION_SEND;

  constructor(
    public payload: {
      verificationCode: string;
    },
  ) {}
}

export class WaitForVerificationCode implements Action {
  readonly type = WAIT_FOR_VERIFICATION_CODE;
}

export class LoginError implements Action {
  readonly type = LOGIN_ERROR;

  constructor(public payload: Error) {}
}

export class Logout implements Action {
  readonly type = LOGOUT;
}

export class LogoutStart implements Action {
  readonly type = LOGOUT_START;
}

export class ClearState implements Action {
  readonly type = CLEAR_STATE;
}

export class SetAppLanguage implements Action {
  readonly type = SET_APP_LANGUAGE;

  constructor(public payload: string) {}
}

export type AuthActions =
  | Login
  | AutoLogin
  | LoginVerificationStart
  | LoginVerificationSend
  | WaitForVerificationCode
  | Logout
  | SetTenants
  | SetViewerRoles
  | SetActiveTenant
  | SetAdminTenant
  | SetUserId
  | LoginStart
  | LoginError
  | LogoutStart
  | ClearState
  | SetAppLanguage;
