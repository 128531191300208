import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import {
  WarehouseCreateAddressResponse,
  WarehouseCreateResponse,
  WarehouseDeleteResponse,
  WarehouseListResponse,
  WarehouseResponse,
  WarehouseUpdateAddressResponse,
  WarehouseUpdateResponse,
} from '../../shared/api/types/types';
import {
  CreateWarehouseAddressInput,
  CreateWarehouseInput,
  DeleteWarehouseAddressInput,
  UpdateWarehouseAddressInput,
  UpdateWarehouseInput,
  WarehousesSearchInput,
} from '../../shared/api/types/GraphQL';
import { WarehouseListQuery, WarehouseQuery } from '../../shared/api/queries/warehouse-queries';
import {
  WarehouseAddressCreate,
  WarehouseAddressDelete,
  WarehouseAddressUpdate,
  WarehouseCreate,
  WarehouseDelete,
  WarehouseUpdate,
} from '../../shared/api/mutations/warehouse-mutations';

@Injectable({ providedIn: 'root' })
export class WarehousesService {
  constructor(private apollo: Apollo) {}

  getWarehousesList(input: WarehousesSearchInput): QueryRef<WarehouseListResponse> {
    return this.apollo.watchQuery<WarehouseListResponse>({
      query: WarehouseListQuery,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
  }

  getSingleWarehouse(id: number): QueryRef<WarehouseResponse> {
    return this.apollo.watchQuery<WarehouseResponse>({
      query: WarehouseQuery,
      variables: { id },
    });
  }

  warehouseDelete(id: number) {
    return this.apollo.mutate<WarehouseDeleteResponse>({
      mutation: WarehouseDelete,
      variables: { id },
    });
  }

  warehouseUpdate(variables: { id: number; input: UpdateWarehouseInput }) {
    return this.apollo.mutate<WarehouseUpdateResponse>({
      mutation: WarehouseUpdate,
      variables,
    });
  }

  warehouseAddressCreate(variables: { id: number; input: CreateWarehouseAddressInput }) {
    return this.apollo.mutate<WarehouseCreateAddressResponse>({
      mutation: WarehouseAddressCreate,
      variables,
    });
  }

  warehouseAddressUpdate(variables: { id: number; input: UpdateWarehouseAddressInput }) {
    return this.apollo.mutate<WarehouseUpdateAddressResponse>({
      mutation: WarehouseAddressUpdate,
      variables,
    });
  }

  warehouseAddressDelete(variables: { id: number; input: DeleteWarehouseAddressInput }) {
    return this.apollo.mutate<Boolean>({
      mutation: WarehouseAddressDelete,
      variables,
    });
  }

  warehouseCreate(variables: { input: CreateWarehouseInput }) {
    return this.apollo.mutate<WarehouseCreateResponse>({
      mutation: WarehouseCreate,
      variables,
    });
  }
}
