import { Injectable } from '@angular/core';
import { Actions, createEffect, EffectNotification, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, tap, map, mergeMap, Observable, of, Subscription, takeUntil } from 'rxjs';
import { OrdersService } from '../../orders.service';
import * as QuotesActions from './quotes.actions';
import * as fromApp from 'src/app/store/app.reducer';
import { NavigationStart, Router } from '@angular/router';
import { QueryRef } from 'apollo-angular';
import { OrderListResponse } from 'src/app/shared/api/types/types';

@Injectable()
export class QuotesEffects {
  quotesQuery: QueryRef<OrderListResponse>;
  quotesQuerySub: Observable<any>;
  browserRefresh: boolean;
  refreshSub: Subscription;
  queryRefetch: boolean = false;

  constructor(
    private actions: Actions,
    private ordersService: OrdersService,
    private store: Store<fromApp.AppState>,
    private router: Router,
  ) {
    this.refreshSub = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.browserRefresh = !router.navigated;
      }
    });
  }

  public readonly loadQuotes: Observable<any> = createEffect(() => {
    return this.actions.pipe(
      ofType(QuotesActions.LOAD_QUOTES),
      mergeMap((action: QuotesActions.LoadQuotes) => {
        this.quotesQuery = this.ordersService.getAllQuotes(action.payload);

        //this.quotesQuery = this.ordersService.getAllQuotes({ status: ['DRAFT_QUOTATION', 'QUOTATION'], page: 1, offset: 5 });

        this.quotesQuerySub = this.quotesQuery.valueChanges.pipe(
          map((data) => {
            if (!this.queryRefetch) {
              this.store.dispatch(new QuotesActions.GetCurrentPage(1));
              this.store.dispatch(new QuotesActions.GetCurrentPageSize(5));
              this.store.dispatch(new QuotesActions.ClearAllFilters());
              this.store.dispatch(new QuotesActions.LoadQuotesFailure(undefined));
              this.store.dispatch(new QuotesActions.ClearQuotesList());
            }
            this.queryRefetch = true;
            this.store.dispatch(new QuotesActions.GetQuotesCount(data.data.orders.itemsFound));
            return new QuotesActions.LoadQuotesSuccess(data.data.orders.items);
          }),
          catchError((error) => of(new QuotesActions.LoadQuotesFailure(error))),
        );
        return this.quotesQuerySub;
      }),
    );
  });

  public readonly refetchQuotes = createEffect(
    () =>
      this.actions.pipe(
        ofType(QuotesActions.REFETCH_QUOTES),
        tap((action: QuotesActions.RefetchQuotes) => {
          this.quotesQuery.resetLastResults();
          this.quotesQuery.refetch(action.payload);
        }),
      ),
    { dispatch: false },
  );

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>): Observable<EffectNotification> {
    return this.actions.pipe(
      ofType(QuotesActions.QUOTES_PAGE_INITIALIZED),
      exhaustMap(() =>
        resolvedEffects$.pipe(takeUntil(this.actions.pipe(ofType(QuotesActions.QUOTES_PAGE_DESTROYED)))),
      ),
    );
  }
}
