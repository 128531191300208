import { gql } from 'apollo-angular';
import { PayMethodsFragments } from '../fragments/paymethods-fragments';

export const AllPayMethods = gql`
  query allPayMethods($input: PayMethodSearchInput) {
    payMethods(input: $input) {
      page
      itemsFound
      offset
      pages
      items {
        id
        names
      }
    }
  }
`;

export const PayMethodsList = gql`
  query PayMethodsList($input: PayMethodSearchInput) {
    payMethods(input: $input) {
      page
      itemsFound
      offset
      pages
      items {
        ...payMethod
      }
    }
  }
  ${PayMethodsFragments.payMethod}
`;

export const PayMethodsDropdownListList = gql`
  query PayMethodsDropdownListList($input: PayMethodSearchInput) {
    payMethods(input: $input) {
      items {
        names {
          language
          value
        }
        externalCode
      }
    }
  }
`;
