import { Action } from '@ngrx/store';
import { AttributeFilter, Cluster, Product, ProductSearchInput } from 'src/app/shared/api/types/GraphQL';
import { AppComponentLocation, ProductStateEntry } from 'src/app/shared/api/types/enums';
import { ManageDisplayedTableColumnsInput } from 'src/app/shared/api/types/types';

export const RESET_STATE_ENTRY_STATE = '[Products] Reset the state of one state entry';
export const SET_LOCATION = '[Products] Set product listing location';

export const SET_QUERY_VARIABLES = '[Products] Set query variables';

export const GET_CURRENT_PAGE = '[Products] Get Current Page';
export const GET_CURRENT_PAGE_SIZE = '[Products] Get Current Page Size';
export const GET_PRODUCTS_COUNT = '[Products] Get Products Count';
export const LOAD_PRODUCTS = '[Products] Load Products';
export const REFETCH_PRODUCTS = '[Products] Refetch Products';
export const LOAD_PRODUCTS_SUCCESS = '[Products] Load Products Success';
export const LOAD_PRODUCTS_FAILURE = '[Products] Load Products Failure';

export const SET_LOADER = '[Products] Set Loader';

export const ADD_PRODUCT_TO_LIST = '[Products] Add Product to List';
export const DELETE_PRODUCT_FROM_LIST = '[Products] Delete Product from List';
export const CLEAR_PRODUCT_LIST = '[Products] Clear ProductList';

export const ADD_CLUSTER_PRODUCT_TO_LIST = '[Products] Add Cluster Product to List';
export const ADD_CLUSTER_TO_LIST = '[Products] Add Cluster to List';
export const ADD_CONFIGURABLE_CLUSTER_PRODUCT_TO_LIST = '[Products] Add Configurable Cluster Product to List';
export const DELETE_PRODUCT_FROM_CLUSTER = '[Products] Delete Product from Cluster';
export const DELETE_PRODUCT_FROM_CONFIGURABLE_CLUSTER = '[Products] Delete Product from Configurable Cluster';

export const ADD_PRODUCT_TO_LIST_ORDERLIST = '[Products] Add Product to List in ORDERLIST';
export const DELETE_PRODUCT_FROM_LIST_ORDERLIST = '[Products] Delete Product from list in ORDERLIST';
export const CLEAR_PRODUCT_LIST_ORDERLIST = '[Products] Clear ProductList in ORDERLIST';

export const GET_PRODUCTS_IN_RANGE = '[Products] Get Products In Range';
export const GET_GENERAL_FILTER = '[Products] Get General Filter';
export const ADD_GENERAL_FILTER = '[Products] Add General Filter';
export const REMOVE_GENERAL_FILTER = '[Products] Remove General Filter';
export const GET_ATTRIBUTE_FILTRS = '[Products] Get Attribute Filters';

export const PRODUCT_TABLE_COLUMNS = '[Products] Product Table Columns';

export const PRODUCTS_PAGE_INITIALIZED = '[Products Page] Initialized';
export const PRODUCTS_PAGE_DESTROYED = '[Products Page] Destroyed';

export class ResetStateEntryState implements Action {
  readonly type = RESET_STATE_ENTRY_STATE;

  constructor(public payload: ProductStateEntry) {}
}

export class SetLocation implements Action {
  readonly type = SET_LOCATION;

  constructor(public payload: { location: AppComponentLocation; stateEntry: ProductStateEntry }) {}
}

export class SetQueryVariables implements Action {
  readonly type = SET_QUERY_VARIABLES;

  constructor(public payload: { variables: ProductSearchInput; stateEntry: ProductStateEntry }) {}
}

export class GetGeneralFilter implements Action {
  readonly type = GET_GENERAL_FILTER;

  constructor(public payload: { filterValue: string; stateEntry: ProductStateEntry }) {}
}

export class AddGeneralFilter implements Action {
  readonly type = ADD_GENERAL_FILTER;

  constructor(public payload: { filter: ProductSearchInput; stateEntry: ProductStateEntry }) {}
}

export class RemoveGeneralFilter implements Action {
  readonly type = REMOVE_GENERAL_FILTER;

  constructor(public payload: { columnName: string; index?: number; clear?: boolean; stateEntry: ProductStateEntry }) {}
}

export class GetAttributeFilters implements Action {
  readonly type = GET_ATTRIBUTE_FILTRS;

  constructor(public payload: { filterList: AttributeFilter[]; stateEntry: ProductStateEntry }) {}
}

export class GetCurrentPage implements Action {
  readonly type = GET_CURRENT_PAGE;

  constructor(public payload: { page: number; stateEntry: ProductStateEntry }) {}
}

export class GetCurrentPageSize implements Action {
  readonly type = GET_CURRENT_PAGE_SIZE;

  constructor(public payload: { pageSize: number; stateEntry: ProductStateEntry }) {}
}

export class GetProductsCount implements Action {
  readonly type = GET_PRODUCTS_COUNT;

  constructor(public payload: { count: number; stateEntry: ProductStateEntry }) {}
}

export class LoadProducts implements Action {
  readonly type = LOAD_PRODUCTS;

  constructor(public payload: { variables: any; stateEntry: ProductStateEntry }) {}
}

export class RefetchProducts implements Action {
  readonly type = REFETCH_PRODUCTS;

  constructor(public payload: { variables?: {}; stateEntry: ProductStateEntry }) {}
}

export class LoadProductsSuccess implements Action {
  readonly type = LOAD_PRODUCTS_SUCCESS;

  constructor(public payload: { products: Product[]; stateEntry: ProductStateEntry }) {}
}

export class LoadProductsFailure implements Action {
  readonly type = LOAD_PRODUCTS_FAILURE;

  constructor(public payload: { error: Error; stateEntry: ProductStateEntry }) {}
}

export class SetLoader implements Action {
  readonly type = SET_LOADER;

  constructor(public payload: { isLoading: boolean; stateEntry: ProductStateEntry }) {}
}

export class AddProductToList implements Action {
  readonly type = ADD_PRODUCT_TO_LIST;

  constructor(public payload: { product: Product; stateEntry: ProductStateEntry }) {}
}

export class DeleteProductFromList implements Action {
  readonly type = DELETE_PRODUCT_FROM_LIST;

  constructor(public payload: { id: number; stateEntry: ProductStateEntry }) {}
}

export class ClearProductList implements Action {
  readonly type = CLEAR_PRODUCT_LIST;
  constructor(public payload: ProductStateEntry) {}
}

export class AddClusterProductToList implements Action {
  readonly type = ADD_CLUSTER_PRODUCT_TO_LIST;

  constructor(public payload: { cluster: Cluster; stateEntry: ProductStateEntry }) {}
}

export class AddClusterToList implements Action {
  readonly type = ADD_CLUSTER_TO_LIST;

  constructor(public payload: { cluster: Cluster; stateEntry: ProductStateEntry }) {}
}

export class AddConfigurableClusterProductToList implements Action {
  readonly type = ADD_CONFIGURABLE_CLUSTER_PRODUCT_TO_LIST;

  constructor(public payload: { cluster: Cluster; stateEntry: ProductStateEntry }) {}
}

export class DeleteProductFromCluster implements Action {
  readonly type = DELETE_PRODUCT_FROM_CLUSTER;

  constructor(public payload: { clusterId: number; productId: number; stateEntry: ProductStateEntry }) {}
}

export class DeleteProductFromConfigurableCluster implements Action {
  readonly type = DELETE_PRODUCT_FROM_CONFIGURABLE_CLUSTER;

  constructor(public payload: { clusterId: number; productId: number; stateEntry: ProductStateEntry }) {}
}

export class AddProductToListOrderlist implements Action {
  readonly type = ADD_PRODUCT_TO_LIST_ORDERLIST;

  constructor(public payload: Product) {}
}

export class DeleteProductFromListOrderlist implements Action {
  readonly type = DELETE_PRODUCT_FROM_LIST_ORDERLIST;

  constructor(public payload: number) {}
}

export class ClearProductListOrderlist implements Action {
  readonly type = CLEAR_PRODUCT_LIST_ORDERLIST;
}

export class ProductTableColumns implements Action {
  readonly type = PRODUCT_TABLE_COLUMNS;

  constructor(public payload: { columnsObj: ManageDisplayedTableColumnsInput; stateEntry: ProductStateEntry }) {}
}
export class GetProductsInRange implements Action {
  readonly type = GET_PRODUCTS_IN_RANGE;

  constructor(public payload: { range: {}; stateEntry: ProductStateEntry }) {}
}
export type ProductsActions =
  | ResetStateEntryState
  | SetLocation
  | SetQueryVariables
  | GetGeneralFilter
  | RemoveGeneralFilter
  | AddGeneralFilter
  | GetAttributeFilters
  | GetCurrentPage
  | GetCurrentPageSize
  | GetProductsCount
  | LoadProducts
  | RefetchProducts
  | LoadProductsSuccess
  | LoadProductsFailure
  | SetLoader
  | AddProductToList
  | DeleteProductFromList
  | ClearProductList
  | AddClusterProductToList
  | AddClusterToList
  | AddConfigurableClusterProductToList
  | DeleteProductFromCluster
  | DeleteProductFromConfigurableCluster
  | GetProductsInRange
  | ProductTableColumns;
