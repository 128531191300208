import { Injectable } from '@angular/core';
import { QueryRef } from 'apollo-angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, catchError, filter, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import * as UsersActions from './users.actions';
import { UsersService } from '../../users.service';
import * as fromApp from '../../../../store/app.reducer';
import { UserStateEntry, UserTypesPlural } from '../../../../shared/api/types/enums';
import { CompanyListResponse, ContactListResponse, CustomerListResponse } from '../../../../shared/api/types/types';
import { UsergroupsResponse } from '../../../../shared/api/types/GraphQL';

type UserListResponse = ContactListResponse | CustomerListResponse | CompanyListResponse | UsergroupsResponse;

@Injectable()
export class UsersEffects {
  query: QueryRef<UserListResponse | any>;
  querySub: Observable<Action>;
  companySearchQuery: QueryRef<any>;
  companySearchQuerySub: Observable<Action>;

  constructor(
    private actions: Actions,
    private usersService: UsersService,
    private store: Store<fromApp.AppState>,
  ) {}

  public readonly loadUsers: Observable<Action> = createEffect(() => {
    return this.actions.pipe(
      ofType(UsersActions.LOAD_USERS),
      switchMap((action: UsersActions.LoadUsers) =>
        this.store
          .select(() => action.payload.stateEntry)
          .pipe(
            filter((stateEntry) => !!stateEntry),
            withLatestFrom(
              this.store.select((state) => state.users[action.payload.stateEntry].users[action.tab].loaded),
              this.store.select((state) => state.users[action.payload.stateEntry].tab),
            ),
            filter(([stateEntry, loaded, currentTab]) => currentTab === action.tab || !loaded),
            switchMap(([stateEntry, loaded, currentTab]: [UserStateEntry, boolean, UserTypesPlural]) => {
              this.defineQuery(action);
              this.querySub = this.query.valueChanges.pipe(
                map((data) => {
                  return new UsersActions.LoadUsersSuccess({
                    data: data.data[action.tab].items,
                    itemsFound: data.data[action.tab].itemsFound,
                    tab: action.tab,
                    stateEntry: stateEntry,
                  });
                }),
                catchError((error) => of(new UsersActions.LoadUsersFailure(error))),
              );
              return this.querySub;
            }),
          ),
      ),
    );
  });

  public readonly refetchUsers = createEffect(
    () =>
      this.actions.pipe(
        ofType(UsersActions.REFETCH_USERS),
        tap((action: UsersActions.RefetchUsers) => {
          if (this.query) {
            this.query.resetLastResults();
          }
          this.query.refetch(action.payload.variables);
        }),
      ),
    { dispatch: false },
  );

  public readonly refetchCompanySearch = createEffect(() => {
    return this.actions.pipe(
      ofType(UsersActions.REFETCH_COMPANY_SEARCH),
      switchMap((action: UsersActions.RefetchCompanySearch) =>
        this.store
          .select(() => action.stateEntry)
          .pipe(
            filter((stateEntry) => !!stateEntry),
            withLatestFrom(
              this.store.select((state) => state.users[action.stateEntry].users[action.tab].loaded),
              this.store.select((state) => state.users[action.stateEntry].tab),
            ),
            filter(([stateEntry, loaded, currentTab]) => currentTab === action.tab || !loaded),
            switchMap(([stateEntry, loaded, currentTab]: [UserStateEntry, boolean, UserTypesPlural]) => {
              this.companySearchQuery = this.usersService.getCompanySearchList({ input: action.payload });
              this.companySearchQuerySub = this.companySearchQuery.valueChanges.pipe(
                map((data) => {
                  return new UsersActions.LoadUsersSuccess({
                    data: data.data.companySearch.items,
                    itemsFound: data.data.companySearch.itemsFound,
                    tab: action.tab,
                    stateEntry: stateEntry,
                  });
                }),
                catchError((error) => of(new UsersActions.LoadUsersFailure(error))),
              );
              return this.companySearchQuerySub;
            }),
          ),
      ),
    );
  });

  private defineQuery(action: UsersActions.LoadUsers) {
    if (action.tab === UserTypesPlural.customers) {
      this.query = this.usersService.getCustomers(action.payload.variables);
    } else if (action.tab === UserTypesPlural.contacts) {
      this.query = this.usersService.getContacts(action.payload.variables);
    } else {
      this.query = this.usersService.getCompanies(action.payload.variables);
    }
  }
}
