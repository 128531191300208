import { gql } from 'apollo-angular';
import { globals } from 'src/app/shared/globals';

export const AttributeFragments = {
  attributes: gql`
    fragment attributes on AttributeValue {                
        attributeId
        textValue(language: "${globals.appLanguage}") {
                values
        }
        enumValue
        intValue
        decimalValue
        dateValue
        attributeDescription {
            id
            searchId
            name
            description(language: "${globals.appLanguage}") {
                value
            }
            type
            typeParam
            group
            isSearchable
            isPublic
        }     
    }
  `,

  attributeDescription: gql`
    fragment attributeDescription on AttributeDescription {
      id
      name
      descriptions {
        language
        value
      }
      units {
        language
        value
      }
      attributeClass
      type
      valuesetId
      group
      isSearchable
      isPublic
      isSystem
      isHidden
      createdAt
      lastModifiedAt
    }
  `,

  attributeValue: gql`
    fragment attrValue on AttributeValue {
      id
      ... on AttributeTextValue {
        type
        textValues {
          language
          values
        }
      }
      ... on AttributeEnumValue {
        type
        enumValues
      }
      ... on AttributeIntValue {
        type
        intValue
      }
      ... on AttributeDecimalValue {
        type
        decimalValue
      }
      ... on AttributeColorValue {
        type
        colorValue
      }
      ... on AttributeDateTimeValue {
        type
        dateTimeValue
      }
    }
  `,
};
