import { gql } from 'apollo-angular';
import { orderStatusFragments } from '../fragments/order-status-fragments';

export const orderStatusSetCreate = gql`
  mutation orderStatusSetCreate($input: CreateOrderStatusSetInput!) {
    orderStatusSetCreate(input: $input) {
      id
      name
      description
      orderStatuses {
        ...orderStatus
      }
    }
  }
  ${orderStatusFragments.orderStatus}
`;

export const orderStatusSetUpdate = gql`
  mutation orderStatusSetUpdate($id: Int!, $input: UpdateOrderStatusSetInput!) {
    orderStatusSetUpdate(id: $id, input: $input) {
      id
      name
      description
      orderStatuses {
        ...orderStatus
      }
    }
  }
  ${orderStatusFragments.orderStatus}
`;

export const orderStatusSetDelete = gql`
  mutation orderStatusSetDelete($id: Int!) {
    orderStatusSetDelete(id: $id)
  }
`;
