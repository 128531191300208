import { gql } from 'apollo-angular';

export const CarrierFragments = {
  carrier: gql`
    fragment carrier on Carrier {
      id
      name
      type
      descriptions {
        language
        value
      }
      shippingCost
      trackAndTraceURL
      logo
      warehouses {
        id
      }
      createdAt
      lastModifiedAt
      createdBy
      lastModifiedBy
    }
  `,
};
