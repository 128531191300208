import { Injectable } from '@angular/core';
import { Actions, createEffect, EffectNotification, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, exhaustMap, filter, map, Observable, of, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs';
import * as MyCompaniesActions from './my-companies.actions';
import * as fromApp from '../../../store/app.reducer';
import { CompaniesService } from '../../companies.service';
import { QueryRef } from 'apollo-angular';
import { MyCompanyListResponse } from 'src/app/shared/api/types/types';

@Injectable()
export class MyCompaniesEffects {
  myCompaniesQuery: QueryRef<MyCompanyListResponse>;
  myCompaniesQuerySub: Observable<Action>;

  constructor(
    private actions: Actions,
    private companiesService: CompaniesService,
    private store: Store<fromApp.AppState>,
  ) {}

  public readonly loadMyCompanies: Observable<Action> = createEffect(() => {
    return this.actions.pipe(
      ofType(MyCompaniesActions.LOAD_MY_COMPANIES),
      withLatestFrom(this.store.select((state) => state.myCompanies.loaded)),
      filter(([action, loaded]) => !loaded),
      switchMap(([action, loaded]: [MyCompaniesActions.LoadMyCompanies, boolean]) => {
        this.myCompaniesQuery = this.companiesService.getCompanyList(action.payload);
        this.myCompaniesQuerySub = this.myCompaniesQuery.valueChanges.pipe(
          map((data) => {
            this.store.dispatch(new MyCompaniesActions.GetMyCompaniesCount(data.data.companies.itemsFound));
            return new MyCompaniesActions.LoadMyCompaniesSuccess(data.data.companies.items);
          }),
          catchError((error) => of(new MyCompaniesActions.LoadMyCompaniesFailure(error))),
        );
        return this.myCompaniesQuerySub;
      }),
    );
  });

  public readonly refetchMyCompanies = createEffect(
    () =>
      this.actions.pipe(
        ofType(MyCompaniesActions.REFETCH_MY_COMPANIES),
        tap((action: MyCompaniesActions.RefetchMyCompanies) => {
          this.myCompaniesQuery.resetLastResults();
          this.myCompaniesQuery.refetch(action.payload);
        }),
      ),
    { dispatch: false },
  );

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>): Observable<EffectNotification> {
    return this.actions.pipe(
      ofType(MyCompaniesActions.MY_COMPANIES_PAGE_INITIALIZED),
      exhaustMap(() =>
        resolvedEffects$.pipe(takeUntil(this.actions.pipe(ofType(MyCompaniesActions.MY_COMPANIES_PAGE_DESTROYED)))),
      ),
    );
  }
}
