import { gql } from 'apollo-angular';

export const WarehouseFragments = {
  address: gql`
    fragment address on WarehouseAddress {
      id
      firstName
      middleName
      lastName
      gender
      company
      street
      number
      numberExtension
      postalCode
      city
      region
      country
      phone
      mobile
      email
      fax
      code
      notes
      icp
      url
      name
    }
  `,

  warehouseInListFragment: gql`
    fragment warehouseInList on Warehouse {
      id
      name
      isActive
      isStore
      isPickupLocation
      createdAt
      lastModifiedAt
    }
  `,

  warehouseAllFields: gql`
    fragment allFields on Warehouse {
      id
      name
      description
      notes
      isActive
      isStore
      isPickupLocation
      shopId
      businessHours {
        dayOfWeek
        openingTime
        closingTime
        lunchBeakStartTime
        lunchBeakEndTime
      }
      createdAt
      lastModifiedAt
    }
  `,
};
