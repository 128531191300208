<div class="form-group">
  <mat-label class="mat-caption" *ngIf="!isFilter">{{ headerName }}</mat-label>
  <mat-form-field appearance="outline">
    <mat-select
      [placeholder]="dropdownPlaceholder"
      [(ngModel)]="selectedData"
      [ngModelOptions]="{ standalone: true }"
      (selectionChange)="onDataChange($event)"
      [disabled]="isViewer || isDisabled"
    >
      <div class="tags-popup">
        <div class="tags-header-wrapper">
          <mat-form-field appearance="outline" class="generalFilter">
            <mat-icon matPrefix>search</mat-icon>
            <input
              matInput
              [placeholder]="searchPlaceholder"
              floatPlaceholder="never"
              (input)="onDataSearch($event)"
              (keydown)="$event.stopPropagation()"
            />
          </mat-form-field>
        </div>
        <mat-option
          *ngFor="let data of customData"
          [value]="data.value"
          [disabled]="data.key === 'noResults'"
          [ngClass]="{ 'no-results': data.key === 'noResults' }"
        >
          {{ data.key === 'noResults' ? data.value : (data.descriptions | localizedString) }}
        </mat-option>
      </div>
    </mat-select>
  </mat-form-field>
</div>
