import { Surcharge, SurchargeSearchInput, SurchargeType, TaxCode } from 'src/app/shared/api/types/GraphQL';
import * as SurchargesActions from './surcharges.actions';
import { TableColumns, TaxCodes } from 'src/app/shared/api/types/enums';

export interface State {
  loading: boolean;
  loaded: boolean;
  surcharges: Surcharge[];
  currentPage: number;
  currentPageSize: number;
  surchargesCount: number;
  error: Error;
  generalFilterValue: string;
  selectedSurchargesList: Surcharge[];
  numberOfFiltersSelected: number;
  multiselectFilterValues: SurchargeSearchInput;
}

const initialState: State = {
  loading: false,
  loaded: false,
  surcharges: [],
  currentPage: 1,
  currentPageSize: 5,
  surchargesCount: 0,
  error: undefined,
  generalFilterValue: '',
  selectedSurchargesList: [],
  numberOfFiltersSelected: 0,
  multiselectFilterValues: {
    page: 1,
    offset: 5,
    type: undefined,
    taxCode: undefined,
    enabled: null,
    taxZone: '',
  },
};

export function surchargesReducer(state: State = initialState, action: SurchargesActions.SurchargesActions) {
  switch (action.type) {
    case SurchargesActions.GET_GENERAL_FILTER:
      return {
        ...state,
        generalFilterValue: action.payload,
      };
    case SurchargesActions.GET_COLUMN_FILTERS:
      let numOfFilters = state.numberOfFiltersSelected;
      if (action.payload.filterValue === '' && state.multiselectFilterValues[action.payload.columnName] !== '') {
        numOfFilters -= 1;
      } else if (action.payload.filterValue !== '' && state.multiselectFilterValues[action.payload.columnName] === '') {
        numOfFilters += 1;
      }
      return {
        ...state,
        columnFilterValues: {
          ...state.multiselectFilterValues,
          [action.payload.columnName]: action.payload.filterValue,
        },
        numberOfFiltersSelected: numOfFilters,
      };

    case SurchargesActions.GET_MULTISELECT_FILTERS:
      const { type, taxCode, taxZone, enabled } = action.payload;
      let payloadLength =
        (action.payload.enabled !== null ? 1 : 0) +
        (action.payload.taxCode ? 1 : 0) +
        (action.payload.taxZone ? 1 : 0) +
        (action.payload.type ? 1 : 0);
      return {
        ...state,
        multiselectFilterValues: {
          ...state.multiselectFilterValues,
          type: type,
          taxCode: taxCode,
          taxZone: taxZone,
          enabled: enabled,
        },
        numberOfFiltersSelected: payloadLength,
      };
    case SurchargesActions.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SurchargesActions.GET_CURRENT_PAGE_SIZE:
      return {
        ...state,
        currentPageSize: action.payload,
      };
    case SurchargesActions.GET_SURCHARGES_COUNT:
      return {
        ...state,
        surchargesCount: action.payload,
      };
    case SurchargesActions.LOAD_SURCHARGES:
      let loading;
      state.loaded ? (loading = false) : (loading = true);
      return {
        ...state,
        loading: loading,
      };
    case SurchargesActions.REFETCH_SURCHARGES:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case SurchargesActions.LOAD_SURCHARGES_SUCCESS:
      return {
        ...state,
        surcharges: [...action.payload],
        loading: false,
        loaded: true,
      };
    case SurchargesActions.LOAD_SURCHARGES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case SurchargesActions.ADD_SURCHARGE:
      return {
        ...state,
        surcharges: [...state.surcharges, action.payload],
      };
    case SurchargesActions.ADD_SURCHARGE_TO_LIST:
      if (state.selectedSurchargesList.map((x) => x.id).indexOf(action.payload.id) === -1) {
        return {
          ...state,
          selectedSurchargesList: [...state.selectedSurchargesList, action.payload],
        };
      } else {
        return {
          ...state,
        };
      }
    case SurchargesActions.DELETE_SURCHARGE_FROM_LIST:
      if (state.selectedSurchargesList.map((x) => x.id).indexOf(action.payload) !== -1) {
        const index = state.selectedSurchargesList.map((x) => x.id).indexOf(action.payload);
        return {
          ...state,
          selectedSurchargesList: state.selectedSurchargesList.filter((item, i) => {
            return i !== index;
          }),
        };
      } else {
        return {
          ...state,
        };
      }
    case SurchargesActions.CLEAR_SURCHARGE_LIST:
      return {
        ...state,
        selectedSurchargesList: [],
      };

    default:
      return state;
  }
}
