<div class="file-viewer-action-bar">
  <div class="file-name-editor">
    <mat-icon class="edit-icon" (click)="nameEditMode = true" *ngIf="!nameEditMode">edit</mat-icon>
    <mat-form-field class="example-full-width" appearance="outline">
      <input matInput type="text" [(ngModel)]="pdfName" [disabled]="!nameEditMode" />
    </mat-form-field>
    <button mat-button (click)="changeName()" *ngIf="nameEditMode" class="mat-caption green">Save</button>
    <button mat-button (click)="resetName()" *ngIf="nameEditMode" class="mat-caption">Cancel</button>
  </div>
  <div class="action-btnss">
    <button mat-flat-button color="primary" (click)="downloadPdf()">Download PDF</button>
    <mat-icon class="close-icon" (click)="onNoClick()">close</mat-icon>
  </div>
</div>
<pdf-viewer
  [src]="pdfLink"
  [rotation]="0"
  [original-size]="false"
  [show-all]="true"
  [fit-to-page]="false"
  [zoom-scale]="'page-width'"
  [zoom]="0.9"
  [render-text]="true"
  [external-link-target]="'blank'"
  [autoresize]="true"
  [show-borders]="false"
  style="width: 100%; height: 100%"
>
</pdf-viewer>
