export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: any; output: any };
  JSONObject: { input: any; output: any };
  Upload: { input: any; output: any };
};

export type AddOrderStatusesToOrderStatusSetInput = {
  /** The ids of the order statuses that will be added to the set */
  orderStatusIds: Array<Scalars['Int']['input']>;
};

export type AddSurchargesToProductInput = {
  /** Product identifier */
  productId: Scalars['Int']['input'];
  /** List of surcharge ids */
  surchargeIds: Array<Scalars['String']['input']>;
};

export type Address = {
  __typename?: 'Address';
  /** Indicates whether the address is active */
  active?: Maybe<YesNo>;
  /** Address city */
  city: Scalars['String']['output'];
  /** Address code */
  code?: Maybe<Scalars['String']['output']>;
  /** Company the address applies to */
  company?: Maybe<Scalars['String']['output']>;
  /** Address country */
  country: Scalars['String']['output'];
  /** Email that applies to this address [valid email format needed] */
  email?: Maybe<Scalars['String']['output']>;
  /** Fax number that applies to this address [min: 6, max: 30] */
  fax?: Maybe<Scalars['String']['output']>;
  /** First name the address applies to */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Address gender */
  gender?: Maybe<Gender>;
  /** ICP address field defines whether tax should be applied to an order if the address is selected as a delivery address. Used when shipping b2b order abroad */
  icp?: Maybe<YesNo>;
  /** Address primary identifier */
  id: Scalars['Int']['output'];
  /** Indicates whether the address is default for its type */
  isDefault: YesNo;
  /** Last name the address applies to */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Middle name the address applies to */
  middleName?: Maybe<Scalars['String']['output']>;
  /** Mobile phone number that applies to this address [min: 6, max: 30] */
  mobile?: Maybe<Scalars['String']['output']>;
  /** Address friendly descriptive name */
  name?: Maybe<Scalars['String']['output']>;
  /** Address delivery notes */
  notes?: Maybe<Scalars['String']['output']>;
  /** Address street number */
  number?: Maybe<Scalars['String']['output']>;
  /** Address street number extension */
  numberExtension?: Maybe<Scalars['String']['output']>;
  /** Fixed phone number that applies to this address [min: 6, max: 30] */
  phone?: Maybe<Scalars['String']['output']>;
  /** Address postal code */
  postalCode: Scalars['String']['output'];
  /** Address region */
  region?: Maybe<Scalars['String']['output']>;
  /** Address street */
  street: Scalars['String']['output'];
  /** Address type [one of 'home', 'delivery', 'invoice'] */
  type: AddressType;
  /** Address company domain url */
  url?: Maybe<Scalars['String']['output']>;
};

/** Address types [one of 'delivery','invoice' or 'home'] */
export enum AddressType {
  /** Delivery address */
  Delivery = 'delivery',
  /** Home address */
  Home = 'home',
  /** Invoice address */
  Invoice = 'invoice',
}

export type AdminUser = {
  __typename?: 'AdminUser';
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Int']['output'];
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Gender>;
  id: Scalars['ID']['output'];
  job?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  lastModifiedAt: Scalars['DateTime']['output'];
  lastModifiedBy: Scalars['Int']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  tenants: TenantResponse;
};

export type AdminUserTenantsArgs = {
  input?: InputMaybe<AdminUserTenantsSearchInput>;
};

/** Fields the AdminUsers search query can be sorted on */
export enum AdminUserSortField {
  CreatedAt = 'CREATED_AT',
  LastModifiedAt = 'LAST_MODIFIED_AT',
}

export type AdminUserSortInput = {
  field: AdminUserSortField;
  order: SortOrder;
};

export type AdminUserTenant = {
  __typename?: 'AdminUserTenant';
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Int']['output'];
  default: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  lastModifiedAt: Scalars['DateTime']['output'];
  lastModifiedBy: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  tenant: Scalars['String']['output'];
  user?: Maybe<AdminUser>;
};

export type AdminUserTenantsSearchInput = {
  offset?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
};

export type AdminUsersSearchInput = {
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  offset?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
  sortInputs?: InputMaybe<Array<AdminUserSortInput>>;
};

export type Attribute = {
  attributeDescription?: Maybe<AttributeDescription>;
  /** The date when the attribute was created */
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the user that created the attribute */
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** The ID of the attribute */
  id: Scalars['String']['output'];
  /** The date when the attribute was last modified */
  lastModifiedAt: Scalars['DateTime']['output'];
  /** The ID of the user that last modified the attribute */
  lastModifiedBy?: Maybe<Scalars['Int']['output']>;
  /** The value of the attribute */
  value: AttributeValue;
};

export type AttributeAttributeDescriptionSearchInput = {
  /** Array of attributeDescription groups to search attributes for */
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Array of attributeDescriptionIds to search attributes for */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search for hidden attributes */
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search for public attributes */
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search for searchable attributes */
  isSearchable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search for system attributes */
  isSystem?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of attributeDescription names to search attributes for */
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Array of attributeDescription types to search attributes for */
  types?: InputMaybe<Array<AttributeType>>;
};

export type AttributeBulkResponse = {
  __typename?: 'AttributeBulkResponse';
  /** Action performed on the attribute description */
  action: AttributeBulkResponse;
  /** The categoryId of the attribute value */
  categoryId?: Maybe<Scalars['Int']['output']>;
  /** The clusterId of the attribute value */
  clusterId?: Maybe<Scalars['Int']['output']>;
  /** The companyId of the attribute value */
  companyId?: Maybe<Scalars['Int']['output']>;
  /** The contactId of the attribute value */
  contactId?: Maybe<Scalars['Int']['output']>;
  /** The customerId of the attribute value */
  customerId?: Maybe<Scalars['Int']['output']>;
  /** ID of the created or updated attribute description */
  id?: Maybe<Scalars['String']['output']>;
  /** Name of the attribute description the attribute is created for */
  key: Scalars['String']['output'];
  /** Success or error message */
  message?: Maybe<Scalars['String']['output']>;
  /** Name of the created or updated attribute description */
  name: Scalars['String']['output'];
  /** The productId of the attribute value */
  productId?: Maybe<Scalars['Int']['output']>;
  /** Whether the action was successful or not */
  success: Scalars['Boolean']['output'];
  /** The value for this attribute */
  value?: Maybe<AttributeValue>;
};

export type AttributeColorValue = AttributeValue & {
  __typename?: 'AttributeColorValue';
  colorValue?: Maybe<Scalars['String']['output']>;
  /** Attribute value id */
  id: Scalars['String']['output'];
  /** Attribute color value type */
  type: AttributeType;
};

export type AttributeCreateInput = {
  /** The ID of the AttributeDescription */
  attributeDescriptionId: Scalars['String']['input'];
  /** The ID of the category the attribute belongs to */
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the cluster the attribute belongs to */
  clusterId?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the company the attribute belongs to */
  companyId?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the contact the attribute belongs to */
  contactId?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the customer the attribute belongs to */
  customerId?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the product the attribute belongs to */
  productId?: InputMaybe<Scalars['Int']['input']>;
  /** The value of the attribute */
  value: AttributeValueInput;
};

export type AttributeDateTimeValue = AttributeValue & {
  __typename?: 'AttributeDateTimeValue';
  dateTimeValue?: Maybe<Scalars['DateTime']['output']>;
  /** Attribute value id */
  id: Scalars['String']['output'];
  /** Attribute datetime value type */
  type: AttributeType;
};

export type AttributeDecimalRangeFilter = IAttributeDecimalRangeFilter & {
  __typename?: 'AttributeDecimalRangeFilter';
  /** The number of products found with this attribute value.  */
  max: Scalars['Float']['output'];
  /** Minumum value for this attribute */
  min: Scalars['Float']['output'];
};

export type AttributeDecimalValue = AttributeValue & {
  __typename?: 'AttributeDecimalValue';
  decimalValue: Scalars['Float']['output'];
  /** Attribute value id */
  id: Scalars['String']['output'];
  /** Attribute decimal value type */
  type: AttributeType;
};

export type AttributeDescription = {
  __typename?: 'AttributeDescription';
  /** The class for this attribute. One of: [PRODUCT, CATEGORY, CLUSTER, CUSTOMER, CONTACT, COMPANY] */
  attributeClass: AttributeDescriptionClass;
  /** Attribute description creation date */
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the user that created the attribute description */
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** The default value for this attribute */
  defaultValue: AttributeValue;
  /**
   * The human readable description of the attribute's name.
   *         This is a language specific value.
   */
  descriptions: Array<LocalizedString>;
  /** The group this attribute belongs to, generally linked to a valueset. */
  group?: Maybe<Scalars['String']['output']>;
  /** Autogenerated ID of the AttributeDescription */
  id: Scalars['String']['output'];
  /**
   * Flags whether this attribute is hidden and should
   *         only be used for background  operations.
   */
  isHidden: Scalars['Boolean']['output'];
  /**
   * Flags whether this attribute should be used when generating
   *     product specifications.
   */
  isPublic: Scalars['Boolean']['output'];
  /**
   * Flags whether this attribute should be used when generating
   *         catalog page filters
   */
  isSearchable: Scalars['Boolean']['output'];
  /** When true, only admin users can access the attribute and its values */
  isSystem: Scalars['Boolean']['output'];
  /** Attribute description last modified date */
  lastModifiedAt: Scalars['DateTime']['output'];
  /** The ID of the user that last modified the attribute description */
  lastModifiedBy?: Maybe<Scalars['Int']['output']>;
  /** Descriptive identifier for this AttributeDescription */
  name: Scalars['String']['output'];
  /** The type of the attribute. One of: [PRODUCT, CATEGORY, CLUSTER, CUSTOMER, CONTACT, COMPANY] */
  type: AttributeType;
  /** Attribute unit per language */
  units?: Maybe<Array<LocalizedString>>;
  /** The ID of the connected valueset to use when type is ENUM */
  valuesetId?: Maybe<Scalars['Int']['output']>;
};

/** The class the Attribute or AttributeDescription belongs to */
export enum AttributeDescriptionClass {
  Category = 'CATEGORY',
  Cluster = 'CLUSTER',
  Company = 'COMPANY',
  Contact = 'CONTACT',
  Customer = 'CUSTOMER',
  Product = 'PRODUCT',
}

export type AttributeDescriptionCreateInput = {
  /** Attribute class. One of: [product, category, cluster, customer, contact, company] */
  attributeClass: AttributeDescriptionClass;
  /** The default value for this attribute */
  defaultValue: AttributeValueInput;
  /** Attribute names per language */
  descriptions: Array<LocalizedStringInput>;
  /** The attribute group */
  group?: InputMaybe<Scalars['String']['input']>;
  /**
   * Flags whether this attribute is hidden and should
   *       only be used for background  operations.
   */
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Flags whether this attribute should be used when generating
   *       product specifications.
   */
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Flags whether this attribute should be used when generating
   *       catalog page filters
   */
  isSearchable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Flags whether this attribute is only available to system users */
  isSystem?: InputMaybe<Scalars['Boolean']['input']>;
  /** Descriptive identifier (Screaming Snake Case) */
  name: Scalars['String']['input'];
  /** Attribute type. One of: [text, enum, color, datetime, integer, decimal] */
  type: AttributeType;
  /** Attribute unit per language */
  units?: InputMaybe<Array<LocalizedStringInput>>;
  /** The name of the connected valueset to use when type is ENUM */
  valuesetId?: InputMaybe<Scalars['Int']['input']>;
};

export type AttributeDescriptionResponse = {
  __typename?: 'AttributeDescriptionResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type AttributeDescription */
  items: Array<AttributeDescription>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type AttributeDescriptionSearchInput = {
  /** List of attribute classes to search for */
  classes?: InputMaybe<Array<AttributeDescriptionClass>>;
  /** List of group names to search for */
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  /** List of attribute ids to search for */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search for the attribute if it is hidden */
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search for the attribute if it is public */
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search for the attribute if it is searchable */
  isSearchable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search for the attribute if it is a system attribute */
  isSystem?: InputMaybe<Scalars['Boolean']['input']>;
  /** List of attribute names to search for */
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Pagination offset number */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination page number */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** List of sorting options */
  sortInputs?: InputMaybe<Array<AttributeDescriptionSortInput>>;
  /** List of attribute types to search for */
  types?: InputMaybe<Array<AttributeType>>;
};

/** The fields by which to sort the AttributeDescriptions search */
export enum AttributeDescriptionSortField {
  CreatedAt = 'CREATED_AT',
  LastModifiedAt = 'LAST_MODIFIED_AT',
  Name = 'NAME',
}

export type AttributeDescriptionSortInput = {
  /** The field to sort by */
  field: AttributeDescriptionSortField;
  /** The order to sort by */
  order: SortOrder;
};

export type AttributeDescriptionUpdateInput = {
  /** The default value for this attribute */
  defaultValue?: InputMaybe<AttributeValueInput>;
  /** Attribute names per language */
  descriptions?: InputMaybe<Array<LocalizedStringInput>>;
  /** The attribute group */
  group?: InputMaybe<Scalars['String']['input']>;
  /**
   * Flags whether this attribute is hidden and should
   *       only be used for background  operations.
   */
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Flags whether this attribute should be used when generating
   *       product specifications.
   */
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Flags whether this attribute should be used when generating
   *       catalog page filters
   */
  isSearchable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Flags whether this attribute is only available to system users */
  isSystem?: InputMaybe<Scalars['Boolean']['input']>;
  /** Attribute unit per language */
  units?: InputMaybe<Array<LocalizedStringInput>>;
};

export type AttributeEnumValue = AttributeValue & {
  __typename?: 'AttributeEnumValue';
  /** Attribute enum values */
  enumValues: Array<Scalars['String']['output']>;
  /** Attribute value id */
  id: Scalars['String']['output'];
  /** Attribute enum value type */
  type: AttributeType;
};

export type AttributeFilter = IAttributeFilter & {
  __typename?: 'AttributeFilter';
  attributeDescription?: Maybe<AttributeDescription>;
  /** Minimum and maximum value range for decimal attributes */
  decimalRangeFilter?: Maybe<AttributeDecimalRangeFilter>;
  /** Attribute filter id */
  id: Scalars['String']['output'];
  /** Minimum and maximum value range for integer attributes */
  integerRangeFilter?: Maybe<AttributeIntegerRangeFilter>;
  /** All available attribute text values within this search result for this particular attribute. */
  textFilters?: Maybe<Array<AttributeTextFilter>>;
  /**
   * The type of the attribute.
   *         Possible values:
   *         - text
   *         - list
   *         - enum
   *         - enumlist
   *         - color
   *         - date
   *         - datetime
   *         - object
   *         - integer
   *         - decimal
   */
  type: AttributeType;
};

export type AttributeIntValue = AttributeValue & {
  __typename?: 'AttributeIntValue';
  /** Attribute value id */
  id: Scalars['String']['output'];
  /** Attribute integer value */
  intValue: Scalars['Int']['output'];
  /** Attribute int value type */
  type: AttributeType;
};

export type AttributeIntegerRangeFilter = IAttributeIntegerRangeFilter & {
  __typename?: 'AttributeIntegerRangeFilter';
  /** The number of products found with this attribute value.  */
  max: Scalars['Int']['output'];
  /** Minumum value for this attribute */
  min: Scalars['Int']['output'];
};

export type AttributeResponse = {
  __typename?: 'AttributeResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type Attribute */
  items: Array<Attribute>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

/**
 * The AttributeResult is an entity that gets returned when a search is done on attributes from a Class' perspective.
 * The AttributeResult has 3 fields,
 * - attributeDescription: The AttributeDescription Entity
 * - attribute: The Entity that link the value to the AttributeDescription Entity and the Class. This field can be null. In that case the Class does not have an AttributeValue set on it.
 * - value: The AttributeValue, either from the Attribute on the Class, or from the AttributeDescription when it's an inherited defaultValue.
 */
export type AttributeResult = {
  __typename?: 'AttributeResult';
  /** The Attribute Entity that link the value to the AttributeDescription Entity and the Class. This field can be null. In that case the Class does not have an AttributeValue set on */
  attribute?: Maybe<Attribute>;
  /** The Attribute Description */
  attributeDescription: AttributeDescription;
  /** The Attribute Value, either from the Attribute on the Class, or from the AttributeDescription when it's an inherited defaultValue. */
  value: AttributeValue;
};

export type AttributeResultResponse = {
  __typename?: 'AttributeResultResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type AttributeResult */
  items: Array<AttributeResult>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type AttributeResultSearchInput = {
  /** AttributeDescription fields to search attributes for */
  attributeDescription?: InputMaybe<AttributeAttributeDescriptionSearchInput>;
  /** LIst of categoryIds to search attributes for */
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of clusterIds to search attributes for */
  clusterIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of companyIds to search attributes for */
  companyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of contactIds to search attributes for */
  contactIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of customerIds to search attributes for */
  customerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Include default values */
  includeDefaultValues?: InputMaybe<Scalars['Boolean']['input']>;
  /** Pagination offset number */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination page number */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** List of productIds to search attributes for */
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type AttributeSearchInput = {
  /** AttributeDescription fields to search attributes for */
  attributeDescription?: InputMaybe<AttributeAttributeDescriptionSearchInput>;
  /** LIst of categoryIds to search attributes for */
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of clusterIds to search attributes for */
  clusterIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of companyIds to search attributes for */
  companyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of contactIds to search attributes for */
  contactIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of customerIds to search attributes for */
  customerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Pagination offset number */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination page number */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** List of productIds to search attributes for */
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type AttributeTextFilter = IAttributeTextFilter & {
  __typename?: 'AttributeTextFilter';
  /** The number of products found with this attribute value with all attributes applied.     */
  count: Scalars['Int']['output'];
  /**
   * The number of products found with this attribute value without all attributes except it's own applied.
   *     This can be used to display the active filter's totals
   */
  countActive?: Maybe<Scalars['Int']['output']>;
  /** The number of products found with this attribute value  without any attribute filters applied.  */
  countTotal?: Maybe<Scalars['Int']['output']>;
  /** Indicates whehter this values is currently selected */
  isSelected: Scalars['Boolean']['output'];
  /** The value of the attribute */
  value: Scalars['String']['output'];
};

export type AttributeTextValue = AttributeValue & {
  __typename?: 'AttributeTextValue';
  /** Attribute value id */
  id: Scalars['String']['output'];
  /** Attribute textValues per language */
  textValues: Array<LocalizedStringArray>;
  /** Attribute text value type */
  type: AttributeType;
};

/** Available attribute types */
export enum AttributeType {
  Color = 'COLOR',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Enum = 'ENUM',
  Int = 'INT',
  Text = 'TEXT',
}

export type AttributeUpdateInput = {
  /** The value of the attribute */
  value: AttributeValueInput;
};

/** Interface for the AttributeValue, where attribute value type derive from. */
export type AttributeValue = {
  /** Attribute value id */
  id: Scalars['String']['output'];
};

export type AttributeValueInput = {
  /** Attribute color value, required for COLOR type attributes (example: #000000 ) */
  colorValue?: InputMaybe<Scalars['String']['input']>;
  /** Attribute datetime value, required for DATETIME type attributes */
  dateTimeValue?: InputMaybe<Scalars['String']['input']>;
  /** Attribute decimal value, required for DECIMAL type attributes */
  decimalValue?: InputMaybe<Scalars['Float']['input']>;
  /** Attribute enum values, required for ENUM type attributes */
  enumValues?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Attribute integer value, required for INTEGER type attributes */
  intValue?: InputMaybe<Scalars['Int']['input']>;
  /** Attribute value descriptions per language */
  textValues?: InputMaybe<Array<LocalizedStringArrayInput>>;
};

/** Auto image optimization */
export enum Auto {
  /** If the browser's Accept header indicates compatibility, deliver a WebP image. */
  Webp = 'WEBP',
}

export type Base64File = {
  __typename?: 'Base64File';
  /** The base64 representation of the file. */
  base64: Scalars['String']['output'];
  /** The contentType of the base64 content. */
  contentType: Scalars['String']['output'];
  /** Indication of the fileName to use when converting the base64 to a file. */
  fileName: Scalars['String']['output'];
};

export type Base64FileInput = {
  /** The base64 representation of the file contents. */
  base64: Scalars['String']['input'];
  /** The contentType of the base64 content. */
  contentType: Scalars['String']['input'];
  /** Indication of the fileName to use when converting the base64 to a file. */
  fileName: Scalars['String']['input'];
};

export type BulkCostPrice = IDiscount & {
  __typename?: 'BulkCostPrice';
  /** Creation date */
  createdAt: Scalars['DateTime']['output'];
  /** Primary identifier */
  id: Scalars['String']['output'];
  /** Last modified date */
  lastModifiedAt: Scalars['DateTime']['output'];
  /** price foreign identifier */
  priceId: Scalars['String']['output'];
  /** Indicates the minimum quantity threshold needed to qualify for the bulk price. */
  quantityFrom: Scalars['Int']['output'];
  /** Start date from which the bulk pricing is effective. Must be a valid ISO 8601 date string */
  validFrom?: Maybe<Scalars['DateTime']['output']>;
  /** Start date from which the bulk pricing is effective. Must be a valid ISO 8601 date string */
  validTo?: Maybe<Scalars['DateTime']['output']>;
  /** Discount on bulk purchases, expressed either as a percentage of the standard price or as a specific reduced price per unit, depending on the bulk price discount type */
  value: Scalars['Float']['output'];
};

export type BulkCostPriceCreateInput = {
  /** Price id */
  priceId: Scalars['String']['input'];
  /** Indicates the minimum quantity threshold needed to qualify for the bulk price. */
  quantityFrom?: Scalars['Int']['input'];
  /** Start date from which the bulk pricing is effective. Must be a valid ISO 8601 date string */
  validFrom?: InputMaybe<Scalars['String']['input']>;
  /** End date of the bulk pricing offer. Must be a valid ISO 8601 date string */
  validTo?: InputMaybe<Scalars['String']['input']>;
  /** Discount on bulk purchases, expressed either as a percentage of the standard price or as a specific reduced price per unit, depending on the bulk price discount type */
  value: Scalars['Float']['input'];
};

export type BulkCostPriceResponse = {
  __typename?: 'BulkCostPriceResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type BulkCostPrice */
  items: Array<BulkCostPrice>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type BulkCostPriceSearchInput = {
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** Search by bulk price ids */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Pagination offset number. [Default to `12`] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination page number. [Default to `1`] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Search by price ids */
  priceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search by quantity from */
  quantityFrom?: InputMaybe<NumberSearchInput>;
  /** Inputs to sort by */
  sortInputs?: InputMaybe<Array<BulkCostPriceSortInput>>;
  /** Search by valid from */
  validFrom?: InputMaybe<DateSearchInput>;
  /** Search by valid to */
  validTo?: InputMaybe<DateSearchInput>;
  /** Search by values */
  value?: InputMaybe<DecimalSearchInput>;
};

export type BulkCostPriceSortInput = {
  /** Field to sort by */
  field: BulkPriceSortField;
  /** Order option to sort by. [Default to `ASC`] */
  order: SortOrder;
};

export type BulkCostPriceUpdateInput = {
  /** Indicates the minimum quantity threshold needed to qualify for the bulk price. */
  quantityFrom: Scalars['Int']['input'];
  /** Start date from which the bulk pricing is effective. Must be a valid ISO 8601 date string */
  validFrom?: InputMaybe<Scalars['String']['input']>;
  /** End date of the bulk pricing offer. Must be a valid ISO 8601 date string */
  validTo?: InputMaybe<Scalars['String']['input']>;
  /** Discount on bulk purchases, expressed either as a percentage of the standard price or as a specific reduced price per unit, depending on the bulk price discount type */
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type BulkPrice = IDiscount & {
  __typename?: 'BulkPrice';
  /** Creation date */
  createdAt: Scalars['DateTime']['output'];
  /** Primary identifier */
  id: Scalars['String']['output'];
  /** Last modified date */
  lastModifiedAt: Scalars['DateTime']['output'];
  /** Price foreign identifier */
  priceId: Scalars['String']['output'];
  /** Indicates the minimum quantity threshold needed to qualify for the bulk price. */
  quantityFrom: Scalars['Int']['output'];
  /** Start date from which the bulk pricing is effective. Must be a valid ISO 8601 date string */
  validFrom?: Maybe<Scalars['DateTime']['output']>;
  /** Start date from which the bulk pricing is effective. Must be a valid ISO 8601 date string */
  validTo?: Maybe<Scalars['DateTime']['output']>;
  /** Discount on bulk purchases, expressed either as a percentage of the standard price or as a specific reduced price per unit, depending on the bulk price discount type */
  value: Scalars['Float']['output'];
};

export type BulkPriceCreateInput = {
  /** Price id */
  priceId: Scalars['String']['input'];
  /** Indicates the minimum quantity threshold needed to qualify for the bulk price. */
  quantityFrom?: Scalars['Int']['input'];
  /** Start date from which the bulk pricing is effective. Must be a valid ISO 8601 date string */
  validFrom?: InputMaybe<Scalars['String']['input']>;
  /** End date of the bulk pricing offer. Must be a valid ISO 8601 date string */
  validTo?: InputMaybe<Scalars['String']['input']>;
  /** Discount on bulk purchases, expressed either as a percentage of the standard price or as a specific reduced price per unit, depending on the bulk price discount type */
  value: Scalars['Float']['input'];
};

export type BulkPriceResponse = {
  __typename?: 'BulkPriceResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type BulkPrice */
  items: Array<BulkPrice>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type BulkPriceSearchInput = {
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** Search by bulk price ids */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Pagination offset number. [Default to `12`] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination page number. [Default to `1`] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Search by price ids */
  priceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search by quantity from */
  quantityFrom?: InputMaybe<NumberSearchInput>;
  /** Inputs to sort by */
  sortInputs?: InputMaybe<Array<BulkPriceSortInput>>;
  /** Search by valid from */
  validFrom?: InputMaybe<DateSearchInput>;
  /** Search by valid to */
  validTo?: InputMaybe<DateSearchInput>;
  /** Search by values */
  value?: InputMaybe<DecimalSearchInput>;
};

export enum BulkPriceSortField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  LastModifiedAt = 'LAST_MODIFIED_AT',
  QuantityFrom = 'QUANTITY_FROM',
  ValidFrom = 'VALID_FROM',
  ValidTo = 'VALID_TO',
  Value = 'VALUE',
}

export type BulkPriceSortInput = {
  /** Field to sort by */
  field: BulkPriceSortField;
  /** Order option to sort by. [Default to `ASC`] */
  order: SortOrder;
};

export type BulkPriceUpdateInput = {
  /** Indicates the minimum quantity threshold needed to qualify for the bulk price. */
  quantityFrom: Scalars['Int']['input'];
  /** Start date from which the bulk pricing is effective. Must be a valid ISO 8601 date string */
  validFrom?: InputMaybe<Scalars['String']['input']>;
  /** End date of the bulk pricing offer. Must be a valid ISO 8601 date string */
  validTo?: InputMaybe<Scalars['String']['input']>;
  /** Discount on bulk purchases, expressed either as a percentage of the standard price or as a specific reduced price per unit, depending on the bulk price discount type */
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type BulkResponseData = {
  __typename?: 'BulkResponseData';
  /** Number of created items */
  created: Scalars['Int']['output'];
  /** Total number of created and updated items */
  total: Scalars['Int']['output'];
  /** Number of updated items */
  updated: Scalars['Int']['output'];
};

export type Bundle = {
  __typename?: 'Bundle';
  /** Discount condition for this bundle. Discount can be applied for Extra Products (EP) or All Products (ALL). */
  condition?: Maybe<BundleCondition>;
  /** Bundle creation date [default: now] */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Bundle creator */
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** Bundle description */
  description?: Maybe<Scalars['String']['output']>;
  /** Bundle discount amount */
  discount?: Maybe<Scalars['Float']['output']>;
  /** Bundle primary identifier */
  id: Scalars['String']['output'];
  /** Items in the bundle. */
  items?: Maybe<Array<BundleItem>>;
  /** Last modified date */
  lastModifiedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Last modified by */
  lastModifiedBy?: Maybe<Scalars['Int']['output']>;
  /** Bundle name */
  name?: Maybe<Scalars['String']['output']>;
  /** Bundle price */
  price?: Maybe<BundlePrice>;
};

export type BundleAddItemsInput = {
  /** Items for this bundle. */
  items?: InputMaybe<Array<BundleItemInput>>;
};

/** Bundle discount condition */
export enum BundleCondition {
  /** All items in the bundle */
  All = 'ALL',
  /** Except for the primary item in the bundle, apply discount only to the additional items */
  Ep = 'EP',
}

export type BundleCreateInput = {
  /** Items for this bundle. */
  addBundleItems?: InputMaybe<Array<BundleItemInput>>;
  /** The discount condition for this bundle. Discount can be applied for Extra Products (EP) or All Products (ALL). */
  condition?: InputMaybe<BundleCondition>;
  /** The description for this bundle. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The discount percentage to be applied to this bundle. */
  discount?: InputMaybe<Scalars['Float']['input']>;
  /** The name for this bundle. */
  name: Scalars['String']['input'];
  /** The bundle prices. */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** The bundle tax zone. */
  taxZone?: InputMaybe<Scalars['String']['input']>;
};

export type BundleItem = {
  __typename?: 'BundleItem';
  /** Bundle creation date */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Bundle creator */
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** Indicates whether the product is leader for this bundle. */
  isLeader?: Maybe<YesNo>;
  /** Last modified date */
  lastModifiedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Last modified by */
  lastModifiedBy?: Maybe<Scalars['Int']['output']>;
  /** Bundle price */
  price?: Maybe<BundlePrice>;
  product: Product;
  /** The bundles primary product identifier */
  productId: Scalars['Int']['output'];
};

export type BundleItemInput = {
  /** Indicates whether the product is leader for this bundle. */
  isLeader?: InputMaybe<YesNo>;
  /** The bundles primary product identifier */
  productId: Scalars['Int']['input'];
};

export type BundlePrice = {
  __typename?: 'BundlePrice';
  /** Gross price of the bundle after discounts applied */
  gross?: Maybe<Scalars['Float']['output']>;
  /** Net price of the bundle after discounts applied */
  net?: Maybe<Scalars['Float']['output']>;
  /** Original gross price of the bundle before discounts applied */
  originalGross?: Maybe<Scalars['Float']['output']>;
  /** Original net price of the bundle before discounts applied */
  originalNet?: Maybe<Scalars['Float']['output']>;
};

export type BundleSearchInput = {
  /** Bundle IDs */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  offset?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
  /** Product IDs */
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Inputs to sort by */
  sortInputs?: InputMaybe<Array<BundleSortInput>>;
  /** Tax zone */
  taxZone?: InputMaybe<Scalars['String']['input']>;
};

/** Field to sort bundles by */
export enum BundleSortField {
  /** Sort by creation date */
  CreatedAt = 'CREATED_AT',
  /** Sort by unique identifier */
  Id = 'ID',
  /** Sort by last modified date */
  LastModifiedAt = 'LAST_MODIFIED_AT',
  /** Sort by name */
  Name = 'NAME',
}

export type BundleSortInput = {
  /** Field to sort by */
  field: BundleSortField;
  /** Order option to sort by. [Default to `ASC`] */
  order?: InputMaybe<SortOrder>;
};

export type BundleUpdateInput = {
  /** The discount condition for this bundle. Discount can be applied for Extra Products (EP) or All Products (ALL). */
  condition?: InputMaybe<BundleCondition>;
  /** The description for this bundle. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The discount percentage to be applied to this bundle. */
  discount?: InputMaybe<Scalars['Float']['input']>;
  /** The name for this bundle. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The bundle prices. */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** The bundle tax zone. */
  taxZone?: InputMaybe<Scalars['String']['input']>;
};

export type BundlesResponse = {
  __typename?: 'BundlesResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type Bundle */
  items: Array<Bundle>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type BusinessHours = {
  __typename?: 'BusinessHours';
  closingTime?: Maybe<Scalars['String']['output']>;
  dayOfWeek: Scalars['Float']['output'];
  lunchBeakEndTime?: Maybe<Scalars['String']['output']>;
  lunchBeakStartTime?: Maybe<Scalars['String']['output']>;
  openingTime?: Maybe<Scalars['String']['output']>;
};

export type BusinessHoursInput = {
  closingTime?: InputMaybe<Scalars['String']['input']>;
  dayOfWeek: Scalars['Float']['input'];
  lunchBeakEndTime?: InputMaybe<Scalars['String']['input']>;
  lunchBeakStartTime?: InputMaybe<Scalars['String']['input']>;
  openingTime?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Represents a BusinessRule Graph or Set, each business rule set consist of at least one inputNode,
 *   decisionTableNode and outputNode witn edges between them.
 *   A decisionTable can have mutiple rows, each row representing a single rule that gets executed.
 */
export type BusinessRule = {
  __typename?: 'BusinessRule';
  /** BusinessRule date created */
  createdAt: Scalars['DateTime']['output'];
  edges: Array<BusinessRuleEdge>;
  /** BusinessRule identifier */
  id: Scalars['ID']['output'];
  jdm: Scalars['JSONObject']['output'];
  name: Array<LocalizedString>;
  nodes: Array<IBusinessRuleNode>;
  type: BusinessRuleType;
  /** BusinessRule List date modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type BusinessRuleActionField = {
  __typename?: 'BusinessRuleActionField';
  name: Array<LocalizedString>;
  path: Scalars['String']['output'];
};

/** Represents a business rule array expression */
export type BusinessRuleArrayExpression = IBusinessRuleExpression & {
  __typename?: 'BusinessRuleArrayExpression';
  id: Scalars['ID']['output'];
  number?: Maybe<Scalars['Float']['output']>;
  operator: BusinessRuleArrayExpressionOperators;
  /**
   * Path to the field, can be used to add additional expressions and calulations on the field.
   *       Use $ to target the field's value.
   *       When omitted the field's value is used.
   *       The expression will be used on the left hand side of the operator.
   *       Can also be used to target a specific property in a complex expression's sub-expression
   *       Check https://gorules.io/docs/rules-engine/expression-language/array for options.
   */
  path?: Maybe<Scalars['String']['output']>;
  string?: Maybe<Scalars['String']['output']>;
  type: BusinessRuleExpressionTypes;
};

export type BusinessRuleArrayExpressionInput = {
  number?: InputMaybe<Scalars['Float']['input']>;
  operator: BusinessRuleArrayExpressionOperators;
  /**
   * Path to the field, can be used to add additional expressions and calulations on the field.
   *       Use $ to target the field's value.
   *       When omitted the field's value is used.
   *       The expression will be used on the left hand side of the operator.
   *       Can also be used to target a specific property in a complex expression's sub-expression
   *       Check https://gorules.io/docs/rules-engine/expression-language/string for options.
   */
  path?: InputMaybe<Scalars['String']['input']>;
  string?: InputMaybe<Scalars['String']['input']>;
};

/** Types of BusinessRuleArrayExpression Operators */
export enum BusinessRuleArrayExpressionOperators {
  All = 'all',
  Contains = 'contains',
  None = 'none',
  Some = 'some',
}

/** Represents a business rule  complex expression */
export type BusinessRuleComplexExpression = IBusinessRuleExpression & {
  __typename?: 'BusinessRuleComplexExpression';
  /**
   * The action to execute with this complex expression
   *         , either 'sum' a sub field that is present in the expression's path
   *         or 'count' to count the length of the filtered array.
   */
  action: BusinessRuleComplexExpressionAction;
  expressions?: Maybe<Array<BusinessRuleSubExpressionGroup>>;
  /** The path to the field to apply the action to in case the action equals 'sum' */
  field?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  number: Scalars['Float']['output'];
  operator?: Maybe<BusinessRuleNumberExpressionOperators>;
  /** The path to the field this complex expression targets, defaults to $. Has to be set in case of it being a sub-expression */
  path?: Maybe<Scalars['String']['output']>;
  type: BusinessRuleExpressionTypes;
};

/** Types of BusinessRuleComplexExpression Actions */
export enum BusinessRuleComplexExpressionAction {
  Collect = 'collect',
  Count = 'count',
  Sum = 'sum',
}

/** Represents the input for a complex epression */
export type BusinessRuleComplexExpressionInput = {
  /**
   * The action to execute with this complex expression
   *         , either 'sum' a sub field that is present in the expression's path
   *         or 'count' to count the length of the filtered array.
   */
  action: BusinessRuleComplexExpressionAction;
  /** The sub-expressions for this complex expression */
  expressions?: InputMaybe<Array<BusinessRuleExpressionGroupInput>>;
  /** The path to the field to apply the action to in case the action equals 'sum' */
  field?: InputMaybe<Scalars['String']['input']>;
  /** The value to compare the result of the action applied to the sub-expressions to */
  number: Scalars['Float']['input'];
  /** The operator to use on the result of the action applied to the sub-expressions */
  operator: BusinessRuleNumberExpressionOperators;
  /**
   * Path to the field, can be used to add additional expressions and calulations on the field.
   *       Use $ to target the field's value.
   *       When omitted the field's value is used.
   *       The expression will be used on the left hand side of the operator.
   *       Can also be used to target a specific property in a complex expression's sub-expression
   *       Check https://gorules.io/docs/rules-engine/expression-language/string for options.
   */
  path?: InputMaybe<Scalars['String']['input']>;
};

export type BusinessRuleCreateInput = {
  name: Array<LocalizedStringInput>;
  shopId: Scalars['Int']['input'];
  type: BusinessRuleType;
};

/** Represents a business rule date expression */
export type BusinessRuleDateExpression = IBusinessRuleExpression & {
  __typename?: 'BusinessRuleDateExpression';
  /** Use when operator is before or after. */
  date?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Use when operator calculates days or minutes from now or today, or when targeting specific days, weeks or months */
  number?: Maybe<Scalars['Int']['output']>;
  operator: BusinessRuleDateExpressionOperators;
  /**
   * Path to the field, can be used to add additional expressions and calulations on the field.
   *       Use $ to target the field's value.
   *       When omitted the field's value is used.
   *       The expression will be used on the left hand side of the operator.
   *       Can also be used to target a specific property in a complex expression's sub-expression
   *       Check https://gorules.io/docs/rules-engine/expression-language/date-and-time for options.
   */
  path?: Maybe<Scalars['String']['output']>;
  /** Use on time based operators, 00:00:00 24h format. */
  string?: Maybe<Scalars['String']['output']>;
  type: BusinessRuleExpressionTypes;
};

/** Represents the input for a business rule date expression */
export type BusinessRuleDateExpressionInput = {
  /** Date value, use when the condition is one of: before, after */
  date?: InputMaybe<Scalars['DateTime']['input']>;
  /**
   * Numeric value, use when the condition is one of:
   *    beforeDaysFromToday, afterDaysFromToday, onDaysFromToday,
   *    beforeMinutesFromNow, afterMinutesFromNow, onMinutesFromNow,
   *    onDayOfWeek, onDayOfMonth, onMonthOfYear
   */
  number?: InputMaybe<Scalars['Int']['input']>;
  /** The operator used for this expression */
  operator: BusinessRuleDateExpressionOperators;
  /**
   * Path to the field, can be used to add additional expressions and calculations on the field.
   *         Use $ to target the field's value.
   *         When omitted, the field's value is used.
   *         The expression will be used on the left-hand side of the operator.
   *         Can also be used to target a specific property in a complex expression's sub-expression.
   *         Check https://gorules.io/docs/rules-engine/expression-language/date-and-time for options.
   */
  path?: InputMaybe<Scalars['String']['input']>;
  /** Time value in HH:mm:ss format, use when the condition is one of: onTime, beforeTime, afterTime */
  string?: InputMaybe<Scalars['String']['input']>;
};

/** Types of BusinessRuleDateExpression Operators */
export enum BusinessRuleDateExpressionOperators {
  After = 'after',
  AfterDaysFromToday = 'afterDaysFromToday',
  AfterMinutesFromNow = 'afterMinutesFromNow',
  AfterTime = 'afterTime',
  Before = 'before',
  BeforeDaysFromToday = 'beforeDaysFromToday',
  BeforeMinutesFromNow = 'beforeMinutesFromNow',
  BeforeTime = 'beforeTime',
  OnDayOfMonth = 'onDayOfMonth',
  OnDayOfWeek = 'onDayOfWeek',
  OnDaysFromToday = 'onDaysFromToday',
  OnMinutesFromNow = 'onMinutesFromNow',
  OnMonthOfYear = 'onMonthOfYear',
  OnTime = 'onTime',
}

/**
 * Represents a single decision table node in the graph, each table can contain multiple rows.
 *   Each row respresents a rule that get executed. The hitPolicy determines how the rule is executed.
 *   When the hitPolicy is set to 'first', the first matching row is used.
 *   When the hitPolicy is set to 'collect', all matching rows are used.
 */
export type BusinessRuleDecisionTable = IBusinessRuleNode & {
  __typename?: 'BusinessRuleDecisionTable';
  /** The node's table content in case the type is 'decisionTableNode' */
  content: BusinessRuleDecisionTableContent;
  /** The description of the node */
  description: Scalars['String']['output'];
  /** The generated ID for this node */
  id: Scalars['ID']['output'];
  /** The name of the node */
  name: Scalars['String']['output'];
  /** The type of the node.  */
  type: BusinessRuleNodeType;
};

export type BusinessRuleDecisionTableAddColumnInput = {
  /**
   * The field of the input or output column.
   *   In case of an input column this is the full path the root of the input payload of the rule.
   *   In case of an output column it's the fieldname you want to output after the rule has been executed.
   *   Output fields can be organized by using the dot notation.
   *   For example:
   *   - input: "input.name"
   *   - output: "output.name.firstname"
   *   - output: "output.name.lastname"
   */
  field: Scalars['String']['input'];
  /** The name for the input or output column */
  name: Scalars['String']['input'];
  /** The ID of the BusinessRule to search for */
  ruleId: Scalars['ID']['input'];
  /** The ID of the DecisionTable inside BusinessRule to add the column to */
  tableId: Scalars['ID']['input'];
  type: BusinessRuleDecisionTableColumnType;
};

/**
 * Represents a single cell in a decisionTable,
 *   each cell either hold an expression, string value or numeric value based on the column type and field
 */
export type BusinessRuleDecisionTableCell = {
  __typename?: 'BusinessRuleDecisionTableCell';
  /** The columnId of the cell */
  columnId: Scalars['ID']['output'];
  /** The expressions value for this cell */
  expression?: Maybe<BusinessRuleExpressionGroup>;
  /** The localized string value of the cell, used in output cells. Used when you want a rule to generate a translated string in the output */
  localizedValue?: Maybe<Array<LocalizedString>>;
  /** The numeric value for this cell, used in output cells */
  number?: Maybe<Scalars['Float']['output']>;
  /** The string value of the cell, used in output cells. Can contain a string or an expression. Strings need to be wrapped in "" */
  value?: Maybe<Scalars['String']['output']>;
};

/** BusinessRule DecisionTable column types */
export enum BusinessRuleDecisionTableColumnType {
  Input = 'input',
  Output = 'output',
}

export type BusinessRuleDecisionTableContent = {
  __typename?: 'BusinessRuleDecisionTableContent';
  /**
   * The table's hitPolicy.
   *   Possible values: first, collect.
   *   'first' means that the first matching row is used.
   *   'collect' means that all matching rows are used.
   */
  hitPolicy: BusinessRuleHitPolicyOption;
  /** The table's input columns */
  inputs: Array<BusinessRuleDecisionTableInputOutput>;
  /** The table's output columns */
  outputs: Array<BusinessRuleDecisionTableInputOutput>;
  /** The table's rows */
  rows: Array<BusinessRuleDecisionTableRow>;
};

export type BusinessRuleDecisionTableDeleteColumnInput = {
  /** The ID of the column to delete */
  columnId: Scalars['ID']['input'];
  /** The ID of the BusinessRule to search for */
  ruleId: Scalars['ID']['input'];
  /** The ID of the DecisionTable inside BusinessRule that contains the column */
  tableId: Scalars['ID']['input'];
  /** The type of the column to delete, either 'input' or 'output' */
  type: BusinessRuleDecisionTableColumnType;
};

export type BusinessRuleDecisionTableDeleteRowInput = {
  /** The ID of the row to delete */
  rowId: Scalars['ID']['input'];
  /** The ID of the BusinessRule that contains the row */
  ruleId: Scalars['ID']['input'];
  /** The ID of the DecisionTable inside BusinessRule that contains the row */
  tableId: Scalars['ID']['input'];
};

/** Definition of a decisionTable's input or output column */
export type BusinessRuleDecisionTableInputOutput = {
  __typename?: 'BusinessRuleDecisionTableInputOutput';
  /**
   * The field of the input or output column.
   *   In case of inputs this is the full path of from the root of the input payload of the rule.
   *   In case of an output column it's the fieldname you want to output after the rule has been executed.
   *   Output fields can be organized by using the dot notation.
   *   For example:
   *   - input: "input.name"
   *   - output: "output.name.firstname"
   *   - output: "output.name.lastname"
   */
  field: Scalars['String']['output'];
  /** The generated ID for this input or output column */
  id: Scalars['ID']['output'];
  /** The name for the input or output column */
  name: Scalars['String']['output'];
  /** The type of the input or output column, currently only "expression" is used. */
  type: Scalars['String']['output'];
};

export type BusinessRuleDecisionTableMoveRowInput = {
  /** The direction of the move action relative to the target row, either 'above' or 'below' */
  direction: BusinessRuleDecisionTableRowReorderDirection;
  /** The ID of the row to move */
  rowId: Scalars['ID']['input'];
  /** The ID of the BusinessRule that contains the table */
  ruleId: Scalars['ID']['input'];
  /** The ID of the DecisionTable inside BusinessRule that contains the rows to move */
  tableId: Scalars['ID']['input'];
  /** The ID of the row to move to */
  targetRowId: Scalars['ID']['input'];
};

/** Represents a single row in a business rule decision table. */
export type BusinessRuleDecisionTableRow = {
  __typename?: 'BusinessRuleDecisionTableRow';
  /** The generated ID for the row */
  id: Scalars['ID']['output'];
  /** The row's input columns */
  inputs: Array<BusinessRuleDecisionTableCell>;
  /** The row's output columns */
  outputs: Array<BusinessRuleDecisionTableCell>;
};

/** The direction when reordering rows by source and target */
export enum BusinessRuleDecisionTableRowReorderDirection {
  Above = 'above',
  Below = 'below',
}

export type BusinessRuleDecisionTableSearchInput = {
  /** The ID of the BusinessRule to search for */
  ruleId: Scalars['ID']['input'];
  /** The ID of the DecisionTable inside BusinessRule */
  tableId: Scalars['ID']['input'];
};

export type BusinessRuleDecisionTableSetCellInput = {
  /** The ID of the column that contains the table cell */
  columnId: Scalars['ID']['input'];
  /** The expressions value for this cell */
  expression?: InputMaybe<BusinessRuleExpressionGroupInput>;
  /** The localized string value of the cell, used in output cells. Used when you want a rule to generate a translated string in the output */
  localizedValue?: InputMaybe<Array<LocalizedStringInput>>;
  /** The numeric value for this cell, used in output cells */
  number?: InputMaybe<Scalars['Float']['input']>;
  /** The ID of the row the table cell */
  rowId: Scalars['ID']['input'];
  /** The ID of the BusinessRule that contains the table cell */
  ruleId: Scalars['ID']['input'];
  /** The ID of the DecisionTable inside BusinessRule that contains the table cell */
  tableId: Scalars['ID']['input'];
  /** The string value of the cell, used in output cells. Can contain a string or an expression. Strings need to be wrapped in "" */
  value?: InputMaybe<Scalars['String']['input']>;
};

export type BusinessRuleDecisionTableUpdateInput = {
  /**
   * The table's hitPolicy.
   *   Possible values: first, collect.
   *   'first' means that the first matching row is used.
   *   'collect' means that all matching rows are used.
   */
  hitPolicy: BusinessRuleHitPolicyOption;
  /** The name of the table */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the BusinessRule that contains the table to update */
  ruleId: Scalars['ID']['input'];
  /** The ID of the DecisionTable inside BusinessRule to update */
  tableId: Scalars['ID']['input'];
};

/**
 * Edges link nodes in a grapgh together,
 *     most commonly a graph contains an inputNode with an edge between a decisionTableNode
 *     and the decisionTableNode has an edge with the outputNode
 */
export type BusinessRuleEdge = {
  __typename?: 'BusinessRuleEdge';
  /** The generated ID of the business rule edge */
  id: Scalars['ID']['output'];
  /** The ID of source Node of the business rule egde */
  sourceId: Scalars['ID']['output'];
  /** The ID of target Node of the business rule egde */
  targetId: Scalars['ID']['output'];
  /** The type of the business rule egde, only posible value now being 'edge' */
  type: Scalars['String']['output'];
};

/** Represents a business rule expression group */
export type BusinessRuleExpressionGroup = {
  __typename?: 'BusinessRuleExpressionGroup';
  expressions: Array<IBusinessRuleExpression>;
  id: Scalars['ID']['output'];
  operator?: Maybe<BusinessRuleExpressionGroupOperators>;
};

export type BusinessRuleExpressionGroupInput = {
  /** All expressions in this group */
  expressions: Array<BusinessRuleExpressionUnionInput>;
  /** The operator to apply between each expression in this group */
  operator?: InputMaybe<BusinessRuleExpressionGroupOperators>;
};

/** Types of BusinessRuleExpressionGroup Operators */
export enum BusinessRuleExpressionGroupOperators {
  And = 'and',
  Or = 'or',
}

/** Types of BusinessRuleExpressions */
export enum BusinessRuleExpressionTypes {
  Array = 'array',
  Boolean = 'boolean',
  Complex = 'complex',
  Date = 'date',
  Datetime = 'datetime',
  Number = 'number',
  String = 'string',
}

/** Expression union type that is used to list expressions inside an expression group. Each instance can contain one field  */
export type BusinessRuleExpressionUnionInput = {
  array?: InputMaybe<BusinessRuleArrayExpressionInput>;
  complex?: InputMaybe<BusinessRuleComplexExpressionInput>;
  date?: InputMaybe<BusinessRuleDateExpressionInput>;
  number?: InputMaybe<BusinessRuleNumberExpressionInput>;
  string?: InputMaybe<BusinessRuleStringExpressionInput>;
};

/** Represents a business rule field definition */
export type BusinessRuleFieldDefinition = {
  __typename?: 'BusinessRuleFieldDefinition';
  /** The potential sub-fields when the type is 'complex' */
  expressions?: Maybe<Array<BusinessRuleFieldDefinition>>;
  /** The actionable sub-fields when the type is 'complex' */
  fields?: Maybe<Array<BusinessRuleActionField>>;
  /** The ID for this field definition */
  id: Scalars['ID']['output'];
  /** The descriptive name of the field */
  name: Array<LocalizedString>;
  /** The expected path to the field in the input payload */
  path: Scalars['String']['output'];
  /** The expected type of the field */
  type: BusinessRuleExpressionTypes;
};

/** Represents a business rule field definition group */
export type BusinessRuleFieldDefinitionGroup = {
  __typename?: 'BusinessRuleFieldDefinitionGroup';
  /** The business rule field definitions in the business rule field definition group */
  definitions: Array<BusinessRuleFieldDefinition>;
  /** The ID of the business rule field definition group */
  id: Scalars['ID']['output'];
  /** Indicates whether the business rule field definition group is a root group or not */
  isRoot: Scalars['Boolean']['output'];
  /** The name of the business rule field definition group */
  name: Scalars['String']['output'];
};

/** BusinessRule DecisionTable hitPolicy options */
export enum BusinessRuleHitPolicyOption {
  Collect = 'collect',
  First = 'first',
}

export type BusinessRuleInput = IBusinessRuleNode & {
  __typename?: 'BusinessRuleInput';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: BusinessRuleNodeType;
};

/** Type of Business Rule Node */
export enum BusinessRuleNodeType {
  DecisionTableNode = 'decisionTableNode',
  FunctionNode = 'functionNode',
  InputNode = 'inputNode',
  OutputNode = 'outputNode',
}

/** Represents a business rule number expression */
export type BusinessRuleNumberExpression = IBusinessRuleExpression & {
  __typename?: 'BusinessRuleNumberExpression';
  id: Scalars['ID']['output'];
  number: Scalars['Float']['output'];
  operator: BusinessRuleNumberExpressionOperators;
  /**
   * Path to the field, can be used to add additional expressions and calculations on the field.
   *       Use $ to target the field's value.
   *       When omitted the field's value is used.
   *       The expression will be used on the left hand side of the operator.
   *       Can also be used to target a specific property in a complex expression's sub-expression
   *       Check https://gorules.io/docs/rules-engine/expression-language/number for options.
   */
  path?: Maybe<Scalars['String']['output']>;
  type: BusinessRuleExpressionTypes;
};

/** Represent the input for a business rule number expression */
export type BusinessRuleNumberExpressionInput = {
  /** The value for this expression */
  number: Scalars['Float']['input'];
  /** The operator for this expression */
  operator: BusinessRuleNumberExpressionOperators;
  /**
   * Path to the field, can be used to add additional expressions and calulations on the field.
   *       Use $ to target the field's value.
   *       When omitted the field's value is used.
   *       The expression will be used on the left hand side of the operator.
   *       Can also be used to target a specific property in a complex expression's sub-expression
   *       Check https://gorules.io/docs/rules-engine/expression-language/string for options.
   */
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Types of BusinessRuleNumberExpression Operators */
export enum BusinessRuleNumberExpressionOperators {
  Equals = 'equals',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqualTo = 'greaterThanOrEqualTo',
  LowerThan = 'lowerThan',
  LowerThanOrEqualTo = 'lowerThanOrEqualTo',
  NotEquals = 'notEquals',
}

export type BusinessRuleOutput = IBusinessRuleNode & {
  __typename?: 'BusinessRuleOutput';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: BusinessRuleNodeType;
};

/** Paginated response of a BusinessRule search query */
export type BusinessRuleResponse = {
  __typename?: 'BusinessRuleResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type BusinessRule */
  items: Array<BusinessRule>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type BusinessRuleSearchInput = {
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  shopId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Array<BusinessRuleType>>;
};

/** Represents a business rule string expression */
export type BusinessRuleStringExpression = IBusinessRuleExpression & {
  __typename?: 'BusinessRuleStringExpression';
  id: Scalars['ID']['output'];
  operator: BusinessRuleStringExpressionOperators;
  /**
   * Path to the field, can be used to add additional expressions and calulations on the field.
   *       Use $ to target the field's value.
   *       When omitted the field's value is used.
   *       The expression will be used on the left hand side of the operator.
   *       Can also be used to target a specific property in a complex expression's sub-expression
   *       Check https://gorules.io/docs/rules-engine/expression-language/string for options.
   */
  path?: Maybe<Scalars['String']['output']>;
  string: Scalars['String']['output'];
  type: BusinessRuleExpressionTypes;
};

/** Represents the input for a business rule string expression */
export type BusinessRuleStringExpressionInput = {
  /** The operator for the expression */
  operator: BusinessRuleStringExpressionOperators;
  /**
   * Path to the field, can be used to add additional expressions and calulations on the field.
   *       Use $ to target the field's value.
   *       When omitted the field's value is used.
   *       The expression will be used on the left hand side of the operator.
   *       Can also be used to target a specific property in a complex expression's sub-expression
   *       Check https://gorules.io/docs/rules-engine/expression-language/string for options.
   */
  path?: InputMaybe<Scalars['String']['input']>;
  /** The value for the expression */
  string: Scalars['String']['input'];
};

/** Types of BusinessRuleStringExpression Operators */
export enum BusinessRuleStringExpressionOperators {
  Contains = 'contains',
  EndsWith = 'endsWith',
  Equals = 'equals',
  ExistsInArray = 'existsInArray',
  NotEquals = 'notEquals',
  StartsWith = 'startsWith',
}

/** Represents a business rule expression group when used as sub-expressions in a complex expression */
export type BusinessRuleSubExpressionGroup = {
  __typename?: 'BusinessRuleSubExpressionGroup';
  expressions: Array<IBusinessRuleExpression>;
  field: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  operator?: Maybe<BusinessRuleExpressionGroupOperators>;
};

/** Type of Business Rule */
export enum BusinessRuleType {
  OrderCarriers = 'orderCarriers',
  OrderIcp = 'orderICP',
  OrderIncentives = 'orderIncentives',
  OrderPaymentMethods = 'orderPaymentMethods',
  OrderPickupLocations = 'orderPickupLocations',
  OrderShippingMethods = 'orderShippingMethods',
  ProductEvent = 'productEvent',
  UserAction = 'userAction',
  UserEvent = 'userEvent',
  UserPricing = 'userPricing',
}

export type BusinessRuleUpdateInput = {
  name: Array<LocalizedStringInput>;
};

/**
 * Cache Control Input options!
 *
 *   Defaults:
 *
 *   	- type=public
 *
 *   	- maxAge=7days
 */
export type CacheControlInput = {
  /** Cache max-age value */
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  /** Cache control type */
  type?: InputMaybe<CacheControlType>;
};

/** Cache Control Type [no-store, public or none] */
export enum CacheControlType {
  /** Adds 'no-store' to the Cache Control header */
  NoStore = 'NO_STORE',
  /** Adds 'public' to the Cache Control header */
  Public = 'PUBLIC',
}

export type CanvasInput = {
  height?: InputMaybe<Scalars['Int']['input']>;
  heightRatio?: InputMaybe<Scalars['Int']['input']>;
  offsetX?: InputMaybe<Scalars['Int']['input']>;
  offsetY?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
  widthRatio?: InputMaybe<Scalars['Int']['input']>;
  x?: InputMaybe<Scalars['Int']['input']>;
  y?: InputMaybe<Scalars['Int']['input']>;
};

export type Carrier = {
  __typename?: 'Carrier';
  /** Carrier creation date */
  createdAt: Scalars['DateTime']['output'];
  /** The userId of the user that created the carrier */
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** Carrier description per language */
  descriptions?: Maybe<Array<LocalizedString>>;
  /** Carrier primary identifier */
  id: Scalars['Int']['output'];
  /** Carrier last modified date */
  lastModifiedAt: Scalars['DateTime']['output'];
  /** The userId of the user that last modified the carrier */
  lastModifiedBy?: Maybe<Scalars['Int']['output']>;
  /** Logo url */
  logo?: Maybe<Scalars['String']['output']>;
  /** Carrier name */
  name: Scalars['String']['output'];
  shippingCost: Scalars['Float']['output'];
  /** Track and Trace redirect URL */
  trackAndTraceURL?: Maybe<Scalars['String']['output']>;
  /** Carrier type */
  type: CarrierType;
  warehouses?: Maybe<Array<Warehouse>>;
};

export type CarrierCreateInput = {
  /** Carrier descriptions per language */
  descriptions: Array<LocalizedStringInput>;
  /** Logo url, must be public reachable url [maxLogoSize: 200kB, maxLogoDimensions: 200x200px ] */
  logo?: InputMaybe<Scalars['String']['input']>;
  /** Carrier unique name */
  name: Scalars['String']['input'];
  /** Carrier specific shipping cost */
  shippingCost: Scalars['Float']['input'];
  /** Track and Trace redirect URL */
  trackAndTraceURL?: InputMaybe<Scalars['String']['input']>;
  /** Carrier type! One of: [PICKUP, DELIVERY] */
  type: CarrierType;
  /** List of supported warehouse ids for this carrier */
  warehouseIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum CarrierSortField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  LastModifiedAt = 'LAST_MODIFIED_AT',
  Name = 'NAME',
}

export type CarrierSortInput = {
  /** Field to sort by */
  field: CarrierSortField;
  /** Order option to sort by. [Default to `ASC`] */
  order: SortOrder;
};

export enum CarrierType {
  Delivery = 'DELIVERY',
  Pickup = 'PICKUP',
}

export type CarrierUpdateInput = {
  /** Carrier descriptions per language */
  descriptions?: InputMaybe<Array<LocalizedStringInput>>;
  /** Logo url, must be public reachable url [maxLogoSize: 200kB, maxLogoDimensions: 200x200px ] */
  logo?: InputMaybe<Scalars['String']['input']>;
  /** Carrier unique name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Carrier specific shipping cost */
  shippingCost?: InputMaybe<Scalars['Float']['input']>;
  /** Track and Trace redirect URL */
  trackAndTraceURL?: InputMaybe<Scalars['String']['input']>;
  /** Carrier type! One of: [PICKUP, DELIVERY] */
  type?: InputMaybe<CarrierType>;
  /** List of supported warehouse ids for this carrier */
  warehouseIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type CarriersResponse = {
  __typename?: 'CarriersResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type Carrier */
  items: Array<Carrier>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type CarriersSearchInput = {
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** Search by ids */
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Search by carrier names */
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Pagination offset number. [Default to `12`] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination page number. [Default to `1`] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Inputs to sort by */
  sortInputs?: InputMaybe<Array<CarrierSortInput>>;
  /** Search by carrier types */
  types?: InputMaybe<Array<CarrierType>>;
  /** Search by warehouse ids */
  warehouseIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Cart = ICart & {
  __typename?: 'Cart';
  /** Action code that is applied to this cart. Is only filled when a valid action code is applied */
  actionCode?: Maybe<Scalars['String']['output']>;
  /** All incentives that apply to this cart. */
  appliedIncentives?: Maybe<Array<CartIncentive>>;
  /** Bonus items that are added to this cart through incentives. */
  bonusItems?: Maybe<Array<CartBaseItem>>;
  /** List of selectable carriers for this cart. */
  carriers?: Maybe<Array<CartCarrier>>;
  /** Cart's primary identifier */
  cartId: Scalars['String']['output'];
  /** Channel identifier this cart belongs to. */
  channelId?: Maybe<Scalars['Int']['output']>;
  /** Tender Company */
  company?: Maybe<Company>;
  /** CompanyId the contact belongs to. Goes in combination with contactId */
  companyId?: Maybe<Scalars['Int']['output']>;
  /** Tender Contact */
  contact?: Maybe<Contact>;
  /** ContactId for this cart. Goes in combination with companyId */
  contactId?: Maybe<Scalars['Int']['output']>;
  /** Date this cart has been created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** User which created the cart. */
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** Tender Customer */
  customer?: Maybe<Customer>;
  /** CustomerId for this cart. */
  customerId?: Maybe<Scalars['Int']['output']>;
  /** Address the order should be sent to. */
  deliveryAddress: CartAddress;
  /** Additional information field that can be stored with a cart and order. */
  extra3?: Maybe<Scalars['String']['output']>;
  /** Additional information field that can be stored with a cart and order. */
  extra4?: Maybe<Scalars['String']['output']>;
  /** Address the invoice for the order should be sent to. */
  invoiceAddress: CartAddress;
  /** Cart's main items */
  items?: Maybe<Array<CartMainItem>>;
  /** Language of the order that will be created from this cart. Order confirmation email will be sent in that language. */
  language?: Maybe<Scalars['String']['output']>;
  /** Date this cart has been last updated. */
  lastModifiedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User which last updated the cart. */
  lastModifiedBy?: Maybe<Scalars['Int']['output']>;
  /** User's remarks for this cart. */
  notes?: Maybe<Scalars['String']['output']>;
  /** Status of the order after processing the cart. When order status is UNFINISHED, the cart is pending payment from PSP. */
  orderStatus?: Maybe<Scalars['String']['output']>;
  /** List of selectable paymethods for this cart and applied user. */
  payMethods?: Maybe<Array<CartPaymethod>>;
  /** Payment data for this cart. */
  paymentData?: Maybe<CartPaymentData>;
  /** Postage data for this cart. */
  postageData?: Maybe<CartPostageData>;
  /** User's reference for this cart. */
  reference?: Maybe<Scalars['String']['output']>;
  /** All shipping methods available for this cart. */
  shippingMethods?: Maybe<Array<CartShippingMethod>>;
  /** Shop identifier this cart belongs to. */
  shopId: Scalars['Int']['output'];
  /** Amount of tax that applies to this cart per tax code */
  taxLevels?: Maybe<Array<CartTaxLevel>>;
  /** Totals for this cart. */
  total?: Maybe<CartTotal>;
  /** Items that are added to the Cart, but can't be ordered. */
  unOrderableItems?: Maybe<Array<CartUnOrderableItem>>;
  /**
   * Cart User
   * @deprecated Deprecated in favor of `contact` or `customer`
   */
  user?: Maybe<IBaseUser>;
  /** @deprecated This field will be removed in a future release, use contact+companyId or customerId instead */
  userId?: Maybe<Scalars['Int']['output']>;
  /** Total amount of value-points that apply to this cart */
  valuePoints?: Maybe<Scalars['Int']['output']>;
  /** Array of voucher codes that are applied to this cart. Is only filled when a valid action is applied. */
  vouchers?: Maybe<Array<CartVoucher>>;
};

export type CartActionCodeInput = {
  /** Action code to apply to this cart */
  actionCode: Scalars['String']['input'];
};

export type CartAddBundleInput = {
  /** Bundle identifier */
  bundleId: Scalars['String']['input'];
  /** Item notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Item quantity */
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type CartAddItemInput = {
  /** Adds child items to this cart item, this field is used when adding configurable clusters to the Cart. */
  childItems?: InputMaybe<Array<CartChildItemInput>>;
  /** Cluster ID the item belongs to. Any child items provided must be part of the cluster's configuration as well. */
  clusterId?: InputMaybe<Scalars['Int']['input']>;
  /** Item notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Cart item unit price. Defaults to calculated price. Only use for external pricing. */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** Product unique identifier */
  productId: Scalars['Int']['input'];
  /** Item quantity */
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type CartAddress = {
  __typename?: 'CartAddress';
  /** City */
  city: Scalars['String']['output'];
  /** Code */
  code?: Maybe<Scalars['String']['output']>;
  /** Company name */
  company?: Maybe<Scalars['String']['output']>;
  /** Country */
  country: Scalars['String']['output'];
  /** Email */
  email?: Maybe<Scalars['String']['output']>;
  /** First name */
  firstName: Scalars['String']['output'];
  /** Gender */
  gender?: Maybe<Gender>;
  /** Last name */
  lastName: Scalars['String']['output'];
  /** Middle name */
  middleName?: Maybe<Scalars['String']['output']>;
  /** Mobile phone number */
  mobile?: Maybe<Scalars['String']['output']>;
  /** Notes */
  notes?: Maybe<Scalars['String']['output']>;
  /** Street number */
  number?: Maybe<Scalars['String']['output']>;
  /** Street number extension */
  numberExtension?: Maybe<Scalars['String']['output']>;
  /** Phone number */
  phone?: Maybe<Scalars['String']['output']>;
  /** Postal code */
  postalCode: Scalars['String']['output'];
  /** Region */
  region?: Maybe<Scalars['String']['output']>;
  /** Street */
  street: Scalars['String']['output'];
};

/** Address types for Carts */
export enum CartAddressType {
  Delivery = 'DELIVERY',
  Invoice = 'INVOICE',
}

export type CartBaseItem = ICartBaseItem & {
  __typename?: 'CartBaseItem';
  /** Cart item's bundleId if applicable */
  bundleId?: Maybe<Scalars['String']['output']>;
  /** Cart item's clusterId if applicable */
  clusterId?: Maybe<Scalars['Int']['output']>;
  /** Expected delivery date for this cart item. */
  deliveryDate?: Maybe<Scalars['DateTime']['output']>;
  /** Deadline for delivery of this cart item. */
  deliveryDeadline?: Maybe<Scalars['DateTime']['output']>;
  /** Discount applied to this cart item */
  discount: Scalars['Float']['output'];
  /** Discount percentage applied to this cart item */
  discountPercentage: Scalars['Float']['output'];
  /** Incentive that applied to this cart item */
  incentive?: Maybe<CartItemIncentive>;
  /** Item ID of the cart item, this id can be used for update or delete mutation for this cart item. */
  itemId: Scalars['String']['output'];
  /** User notes on cart item level */
  notes?: Maybe<Scalars['String']['output']>;
  /** Id of the parent cart item. */
  parentItemUUID?: Maybe<Scalars['String']['output']>;
  /** Gross price per UOM of this cart item, no item specific discounts are applied to this price. */
  price: Scalars['Float']['output'];
  /** Price mode for this cart item, if not set the platform default will be used. */
  priceMode?: Maybe<PriceMode>;
  /** Net price per UOM of this cart item, no item specific discounts are applied to this price. */
  priceNet: Scalars['Float']['output'];
  product: Product;
  /** Cart item's productId if applicable */
  productId?: Maybe<Scalars['Int']['output']>;
  /** Quantity for this cart item  */
  quantity: Scalars['Int']['output'];
  /** Gross sum of the main cart price and the prices of its child items per UOM. No item specific discounts are applied to this price. */
  sum: Scalars['Float']['output'];
  /** Net sum of the main cart price and the prices of its child items per UOM. No item specific discounts are applied to this price. */
  sumNet: Scalars['Float']['output'];
  /** List of surcharges for this cart item */
  surcharges?: Maybe<Array<CartItemSurcharge>>;
  /** Tax code for this cart item. */
  taxCode: Taxcode;
  /** Total gross price for this cart item, including item specific discounts. */
  totalPrice: Scalars['Float']['output'];
  /** Total net price for this cart item, including item specific discounts. */
  totalPriceNet: Scalars['Float']['output'];
  /** Total gross sum of the main cart price and the prices of its child items. Including item specific discounts. */
  totalSum: Scalars['Float']['output'];
  /** Total net sum of the main cart price and the prices of its child items. Including item specific discounts. */
  totalSumNet: Scalars['Float']['output'];
};

export type CartCarrier = {
  __typename?: 'CartCarrier';
  /** Carrier's delivery deadline */
  deliveryDeadline?: Maybe<Scalars['DateTime']['output']>;
  /** Carrier unique identifier */
  id: Scalars['Int']['output'];
  /** Carrier's logo */
  logo?: Maybe<Scalars['String']['output']>;
  /** Carrier name */
  name: Scalars['String']['output'];
  /** Targeted costs for delivery through this carrier. Use the costs as an indication, the actual costs are calculated through business rules */
  price: Scalars['Float']['output'];
};

export type CartChildItemInput = {
  /** Item notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Cart item unit price. Defaults to calculated price. Only use for external pricing. */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** Product unique identifier */
  productId: Scalars['Int']['input'];
  /** Item quantity */
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type CartDeleteItemInput = {
  /** Item unique identifier */
  itemId: Scalars['String']['input'];
};

export type CartIncentive = {
  __typename?: 'CartIncentive';
  /** Incentive action */
  action: Scalars['String']['output'];
  /** List of affected items */
  affectedItems?: Maybe<Array<CartBaseItem>>;
  /** Incentive name */
  name: Scalars['String']['output'];
  /** Incentive parameters */
  params: CartIncentiveParams;
};

export type CartIncentiveParams = {
  __typename?: 'CartIncentiveParams';
  /** Action code */
  actionCode?: Maybe<Scalars['String']['output']>;
  /** Discount value */
  discount?: Maybe<Scalars['Float']['output']>;
  /** Discount type */
  discountType?: Maybe<DiscountType>;
  /** Incentive price */
  price?: Maybe<Scalars['Float']['output']>;
  /** Product identifier */
  productId?: Maybe<Scalars['Int']['output']>;
  /** Minimum quantity of items to be affected by the incentive */
  quantity?: Maybe<Scalars['Int']['output']>;
  /** Repeat incentive */
  repeat?: Maybe<YesNo>;
  /** Value points amount */
  valuePoints?: Maybe<Scalars['Int']['output']>;
};

export type CartItemIncentive = {
  __typename?: 'CartItemIncentive';
  /** Cart item incentive code */
  code: OrderItemIncentiveCode;
  /** Cart item incentive name */
  name?: Maybe<Scalars['String']['output']>;
  /** Cart item incentive price */
  price: Scalars['Float']['output'];
  /** Quantity of cart items to be affected by the incentive */
  quantity: Scalars['Int']['output'];
};

export type CartItemSurcharge = {
  __typename?: 'CartItemSurcharge';
  /** Localized descriptions */
  descriptions: Array<LocalizedString>;
  /** Localized names */
  names: Array<LocalizedString>;
  /** Price */
  price?: Maybe<Scalars['Float']['output']>;
  /** Net price */
  priceNet?: Maybe<Scalars['Float']['output']>;
  /** Quantity */
  quantity?: Maybe<Scalars['Int']['output']>;
  /** Tax code */
  taxCode: Taxcode;
  /** Tax percentage */
  taxPercentage?: Maybe<Scalars['Int']['output']>;
  /** Total price */
  totalPrice?: Maybe<Scalars['Float']['output']>;
  /** Total net price */
  totalPriceNet?: Maybe<Scalars['Float']['output']>;
  /** Type */
  type: Scalars['String']['output'];
  /** Value */
  value: Scalars['Float']['output'];
};

export type CartMainItem = ICartBaseItem & {
  __typename?: 'CartMainItem';
  /** Bundle */
  bundle?: Maybe<Bundle>;
  /** Cart item's bundleId if applicable */
  bundleId?: Maybe<Scalars['String']['output']>;
  /** Cart item's child items, used when ordering products that are part of a configuration or bundle */
  childItems?: Maybe<Array<CartBaseItem>>;
  /** Cart item's clusterId if applicable */
  clusterId?: Maybe<Scalars['Int']['output']>;
  /** Expected delivery date for this cart item. */
  deliveryDate?: Maybe<Scalars['DateTime']['output']>;
  /** Deadline for delivery of this cart item. */
  deliveryDeadline?: Maybe<Scalars['DateTime']['output']>;
  /** Discount applied to this cart item */
  discount: Scalars['Float']['output'];
  /** Discount percentage applied to this cart item */
  discountPercentage: Scalars['Float']['output'];
  /** Incentive that applied to this cart item */
  incentive?: Maybe<CartItemIncentive>;
  /** Item ID of the cart item, this id can be used for update or delete mutation for this cart item. */
  itemId: Scalars['String']['output'];
  /** User notes on cart item level */
  notes?: Maybe<Scalars['String']['output']>;
  /** Id of the parent cart item. */
  parentItemUUID?: Maybe<Scalars['String']['output']>;
  /** Gross price per UOM of this cart item, no item specific discounts are applied to this price. */
  price: Scalars['Float']['output'];
  /** Price mode for this cart item, if not set the platform default will be used. */
  priceMode?: Maybe<PriceMode>;
  /** Net price per UOM of this cart item, no item specific discounts are applied to this price. */
  priceNet: Scalars['Float']['output'];
  product?: Maybe<Product>;
  /** Cart item's productId if applicable */
  productId?: Maybe<Scalars['Int']['output']>;
  /** Quantity for this cart item  */
  quantity: Scalars['Int']['output'];
  /** Gross sum of the main cart price and the prices of its child items per UOM. No item specific discounts are applied to this price. */
  sum: Scalars['Float']['output'];
  /** Net sum of the main cart price and the prices of its child items per UOM. No item specific discounts are applied to this price. */
  sumNet: Scalars['Float']['output'];
  /** List of surcharges for this cart item */
  surcharges?: Maybe<Array<CartItemSurcharge>>;
  /** Tax code for this cart item. */
  taxCode: Taxcode;
  /** Total gross price for this cart item, including item specific discounts. */
  totalPrice: Scalars['Float']['output'];
  /** Total net price for this cart item, including item specific discounts. */
  totalPriceNet: Scalars['Float']['output'];
  /** Total gross sum of the main cart price and the prices of its child items. Including item specific discounts. */
  totalSum: Scalars['Float']['output'];
  /** Total net sum of the main cart price and the prices of its child items. Including item specific discounts. */
  totalSumNet: Scalars['Float']['output'];
};

export type CartMainItemBundleArgs = {
  taxZone?: InputMaybe<Scalars['String']['input']>;
};

export type CartPaymentData = {
  __typename?: 'CartPaymentData';
  /** Selected paymethod for this cart. Defaults to ACCOUNT. */
  method: Scalars['String']['output'];
  /** Costs associated with the selected paymethod on this cart. Excluding VAT. */
  price?: Maybe<Scalars['Float']['output']>;
  priceMode?: Maybe<PriceMode>;
  /** Costs associated with the selected paymethod on this cart. Including VAT. */
  priceNet?: Maybe<Scalars['Float']['output']>;
  /** Payment status */
  status?: Maybe<Scalars['String']['output']>;
  /** Payment status date */
  statusDate?: Maybe<Scalars['DateTime']['output']>;
  /** Tax associated with the selected paymethod on this cart. */
  tax: Scalars['Float']['output'];
  /** Tax percentage associated with the selected paymethod on this cart. */
  taxPercentage?: Maybe<Scalars['Int']['output']>;
};

export type CartPaymentDataInput = {
  /** Selected paymethod for this cart. */
  method?: InputMaybe<Scalars['String']['input']>;
  /** Transaction cost excluding VAT based on external payment calculation. If value is set, payment priceMode is set to 'EXTERNAL' for this cart. */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** Status of the payment */
  status?: InputMaybe<Scalars['String']['input']>;
};

export type CartPaymethod = {
  __typename?: 'CartPaymethod';
  /** Paymethod code */
  code: Scalars['String']['output'];
  /** External code */
  externalCode?: Maybe<Scalars['String']['output']>;
  /** Paymethod name */
  name: Scalars['String']['output'];
  /** Paymethod price */
  price: Scalars['Float']['output'];
  /** Tax code */
  taxCode?: Maybe<Taxcode>;
  /** Paymethod type */
  type?: Maybe<Scalars['String']['output']>;
};

export type CartPostageData = {
  __typename?: 'CartPostageData';
  /** Selected carrier for this cart. */
  carrier?: Maybe<Scalars['String']['output']>;
  /** Selected shipping method for this cart. Defaults to DELIVERY. */
  method: Scalars['String']['output'];
  /** This order can be shipped in parts when items different delivery dates */
  partialDeliveryAllowed?: Maybe<YesNo>;
  /** Pick up location ID */
  pickUpLocationId?: Maybe<Scalars['Int']['output']>;
  /** Costs associated with the selected shipping method and business rules that apply to this cart. Excluding VAT. */
  price?: Maybe<Scalars['Float']['output']>;
  /** Indicates where the price is calculated. PLATFORM - Price was calculated based on product price/postage rules set in Propeller. EXTERNAL - price was set externally */
  priceMode?: Maybe<PriceMode>;
  /** Costs associated with the selected shipping method and business rules that apply to this cart. Including VAT. */
  priceNet?: Maybe<Scalars['Float']['output']>;
  /** Preferred delivery date for this order as requested by the user. */
  requestDate?: Maybe<Scalars['DateTime']['output']>;
  /** Tax percentage */
  taxPercentage?: Maybe<Scalars['Int']['output']>;
  warehouse?: Maybe<Warehouse>;
};

export type CartPostageDataInput = {
  /** Selected carrier for this cart. */
  carrier?: InputMaybe<Scalars['String']['input']>;
  /** Selected shipping method for this cart. */
  method?: InputMaybe<Scalars['String']['input']>;
  /** Is partial delivery allowed for this cart. */
  partialDeliveryAllowed?: InputMaybe<YesNo>;
  /** Pick up location ID */
  pickUpLocationId?: InputMaybe<Scalars['Int']['input']>;
  /** Postage cost excluding VAT based on external postage calculation. If value is set, postage priceMode is set to 'EXTERNAL' for this cart. */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** Preferred delivery date for this order as requested by the user. */
  requestDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CartProcessInput = {
  /** Language of the order that will be created from this cart. Order confirmation email will be sent in that language. */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Initial order status to set on the order that will be created from this cart */
  orderStatus: Scalars['String']['input'];
};

export type CartProcessResponse = {
  __typename?: 'CartProcessResponse';
  /** Cart object */
  cart: Cart;
  /** Cart order unique identifier */
  cartOrderId: Scalars['Int']['output'];
  order: Order;
};

export type CartResponse = {
  __typename?: 'CartResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type Cart */
  items: Array<Cart>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type CartSearchInput = {
  /** Company unique identifiers */
  companyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Contact unique identifiers */
  contactIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** Customer unique identifiers */
  customerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Cart unique identifiers */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Offset number */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Page number */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Inputs to sort by */
  sortInputs?: InputMaybe<Array<CartSortInput>>;
};

export type CartSetContactInput = {
  /** Company that the contact belongs to [If not provided the original parent company will be used] */
  companyId?: InputMaybe<Scalars['Int']['input']>;
  /** Contact unique identifier */
  contactId: Scalars['Int']['input'];
};

export type CartSetCustomerInput = {
  /** Customer unique identifier */
  customerId: Scalars['Int']['input'];
};

export type CartSetUserInput = {
  /** A company that contact belongs to. Goes in combination with contactId */
  companyId?: InputMaybe<Scalars['Int']['input']>;
  /** Cart contact identifier. Goes in combination with companyId */
  contactId?: InputMaybe<Scalars['Int']['input']>;
  /** Cart customer identifier */
  customerId?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type CartShippingMethod = {
  __typename?: 'CartShippingMethod';
  /** Shipping method code */
  code: Scalars['String']['output'];
  /** Shipping method name */
  name: Scalars['String']['output'];
};

export enum CartSortField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  LastModifiedAt = 'LAST_MODIFIED_AT',
}

export type CartSortInput = {
  /** Field to sort by */
  field: CartSortField;
  /** Order option to sort by. [Default to `ASC`] */
  order: SortOrder;
};

export type CartStartInput = {
  /** A company that contact belongs to. Goes in combination with contactId */
  companyId?: InputMaybe<Scalars['Int']['input']>;
  /** Cart contact identifier. Goes in combination with companyId */
  contactId?: InputMaybe<Scalars['Int']['input']>;
  /** Cart customer identifier */
  customerId?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type CartTaxLevel = {
  __typename?: 'CartTaxLevel';
  /** Total discount for the tax level's code */
  discount?: Maybe<Scalars['Float']['output']>;
  /** Total tax added for to the cart for the tax level's tax code */
  price: Scalars['Float']['output'];
  /** Tax level's percentage */
  taxPercentage: Scalars['Int']['output'];
};

export type CartTotal = {
  __typename?: 'CartTotal';
  /** Discount that is applied to the order total through one or multiple incentives. Excluding VAT. */
  discount: Scalars['Float']['output'];
  /** Discount that is applied to the order total through one or multiple incentives. Including VAT. */
  discountNet: Scalars['Float']['output'];
  /** Discount percentage that is applied to the order total through one or multiple incentives. */
  discountPercentage: Scalars['Float']['output'];
  /**
   * Total payable amount of all items in this cart,
   *     excluding postage, paymethods costs and discounts. Excluding  VAT
   */
  subTotal: Scalars['Float']['output'];
  /**
   * Total payable amount of all items in this cart,
   *     excluding postage, paymethods costs and discounts. Including  VAT
   */
  subTotalNet: Scalars['Float']['output'];
  /** Total payable amount for this cart. Excluding VAT */
  totalGross: Scalars['Float']['output'];
  /** Total payable amount for this cart. Including VAT */
  totalNet: Scalars['Float']['output'];
};

export type CartUnOrderableItem = {
  __typename?: 'CartUnOrderableItem';
  /** Cluster ID the item belongs to. Any child items provided must be part of the cluster's configuration as well. */
  bundleId?: Maybe<Scalars['String']['output']>;
  /** Cluster ID the item belongs to. Any child items provided must be part of the cluster's configuration as well. */
  clusterId?: Maybe<Scalars['Int']['output']>;
  /** Item ID of the cart item, this id can be used for update or delete mutation for this cart item. */
  itemId: Scalars['String']['output'];
  /** Customer notes for the cart item */
  notes?: Maybe<Scalars['String']['output']>;
  /** Id of the parent cart item. */
  parentItemUUID?: Maybe<Scalars['String']['output']>;
  /** Price for the cart item, used when priceMode === EXTERNAL */
  price?: Maybe<Scalars['Float']['output']>;
  /** Price mode for this cart item, if not set the platform default will be used. */
  priceMode?: Maybe<PriceMode>;
  /** Product identifier */
  productId: Scalars['Int']['output'];
  /** Quantity for the cart item */
  quantity?: Maybe<Scalars['Int']['output']>;
};

export type CartUpdateAddressInput = {
  /** City */
  city: Scalars['String']['input'];
  /** Address code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Company name */
  company?: InputMaybe<Scalars['String']['input']>;
  /** Country code */
  country?: InputMaybe<Scalars['String']['input']>;
  /** Address name */
  email?: InputMaybe<Scalars['String']['input']>;
  /** First name */
  firstName: Scalars['String']['input'];
  /** Gender */
  gender?: InputMaybe<Gender>;
  /** Last name */
  lastName: Scalars['String']['input'];
  /** Middle name */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Mobile phone number */
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** Notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Street number */
  number?: InputMaybe<Scalars['String']['input']>;
  /** Street number extension */
  numberExtension?: InputMaybe<Scalars['String']['input']>;
  /** Phone number */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Postal code */
  postalCode: Scalars['String']['input'];
  /** Region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** Street name */
  street: Scalars['String']['input'];
  /** Address type */
  type: CartAddressType;
};

export type CartUpdateInput = {
  /** Additional information field that can be stored with a cart and order. */
  extra3?: InputMaybe<Scalars['String']['input']>;
  /** Additional information field that can be stored with a cart and order. */
  extra4?: InputMaybe<Scalars['String']['input']>;
  /** Cart notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Payment data for this cart. */
  paymentData?: InputMaybe<CartPaymentDataInput>;
  /** Postage data for this cart. */
  postageData?: InputMaybe<CartPostageDataInput>;
  /** Cart reference */
  reference?: InputMaybe<Scalars['String']['input']>;
};

export type CartUpdateItemInput = {
  /** Item notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Cart item unit price. Defaults to calculated price. Only use for external pricing. */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** Item quantity */
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type CartVoucher = {
  __typename?: 'CartVoucher';
  /** Available amount when the voucher represents a payable amount */
  available: Scalars['Float']['output'];
  /** Voucher code */
  code: Scalars['String']['output'];
  /** Can this voucher be combined with other vouchers */
  combinable: YesNo;
  /** Description of the rule that generated this voucher */
  description: Scalars['String']['output'];
  /** Name of the rule that generated this voucher */
  name: Scalars['String']['output'];
  /** Can this voucher be partially redeemed when type is a payable amount */
  partialRedemption: YesNo;
  /** Is this voucher already redeemed */
  redeemed: YesNo;
  /** Remaining amount when the voucher represent a payable amount and can be partially redeemed */
  remaining: Scalars['Float']['output'];
  /** Id of the rule that generated this voucher */
  ruleId: Scalars['Int']['output'];
};

export type Category = {
  __typename?: 'Category';
  /** Lists attributes for this category based on the search input. */
  attributes?: Maybe<AttributeResultResponse>;
  categories?: Maybe<Array<Category>>;
  categoryId: Scalars['Int']['output'];
  categoryPath: Array<Category>;
  defaultLanguage: Scalars['String']['output'];
  /**
   * The long description for this category,
   *             this field can contain html formatting,
   *             this field can be translated is case of multilingual catalog
   */
  description: Array<LocalizedString>;
  /** @deprecated This property is no longer relevant or necessary, use categoryId instead. */
  id: Scalars['Int']['output'];
  /**
   * Localized category name as it is available in
   *         the supported language defaults to the category's default language.
   */
  name: Array<LocalizedString>;
  /** The category's parent Category when available */
  parent?: Maybe<Category>;
  /**
   * The path in id's to the root of the inheritance tree,
   *     can be passed as parameter to the productsearch API
   */
  path: Scalars['String']['output'];
  products: ProductsResponse;
  /**
   * The short description for this category,
   *             this field can contain html formatting,
   *             this field can be translated is case of multilingual catalog
   */
  shortDescription: Array<LocalizedString>;
  /**
   * The slug for this category,
   *             each available language contains a different slug
   */
  slug: Array<LocalizedString>;
};

export type CategoryAttributesArgs = {
  input?: InputMaybe<AttributeResultSearchInput>;
};

export type CategoryDescriptionArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type CategoryNameArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type CategoryProductsArgs = {
  input?: InputMaybe<CategoryProductSearchInput>;
};

export type CategoryShortDescriptionArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type CategorySlugArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type CategoryAttribute = Attribute & {
  __typename?: 'CategoryAttribute';
  attributeDescription?: Maybe<AttributeDescription>;
  /** The ID of the category this attribute belongs to */
  categoryId: Scalars['Int']['output'];
  /** The date when the attribute was created */
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the user that created the attribute */
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** The ID of the attribute */
  id: Scalars['String']['output'];
  /** The date when the attribute was last modified */
  lastModifiedAt: Scalars['DateTime']['output'];
  /** The ID of the user that last modified the attribute */
  lastModifiedBy?: Maybe<Scalars['Int']['output']>;
  /** The value of the attribute */
  value: AttributeValue;
};

export type CategoryProductSearchInput = {
  /** List of product EAN codes */
  EANCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Product class */
  class?: InputMaybe<ProductClass>;
  /** List of clusterIds to search for */
  clusterIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Browse catalog as a specific Company. Permissions, orderlists, favorite will be merged  with the logged in user. */
  companyId?: InputMaybe<Scalars['Int']['input']>;
  /** Product path slug(s) */
  containerPathSlugs?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Product container slug(s) */
  containerSlugs?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** Get all descending products (true) or only the direct child products of this category (false) */
  getDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  /** Is product in a bundle */
  hasBundle?: InputMaybe<YesNo>;
  /** Is product hidden? */
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** List of unique product identifiers */
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Is product a bundle leader */
  isBundleLeader?: InputMaybe<YesNo>;
  /** Specify through which language to search in, has no effect on other returned fields (eg. names,slugs) */
  language?: Scalars['String']['input'];
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** List of product manufacturer codes */
  manufacturerCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** List of product manufacturers */
  manufacturers?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Pagination offset number [default=12] */
  offset?: Scalars['Int']['input'];
  /** Pagination page number [default=1] */
  page?: Scalars['Int']['input'];
  /** Product price filter */
  price?: InputMaybe<ProductPriceFilterInput>;
  /** List of productIds to search for */
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of range filters */
  rangeFilters?: InputMaybe<Array<ProductRangeFilterInput>>;
  /** List of product search fields */
  searchFields?: InputMaybe<Array<SearchFieldsInput>>;
  /** List of product SKUs */
  skus?: InputMaybe<Array<Scalars['String']['input']>>;
  /** List of product sort filters */
  sortInputs?: InputMaybe<Array<ProductSortInput>>;
  /** List of product status filters */
  statuses?: Array<ProductStatus>;
  /** List of product supplier codes */
  supplierCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** List of product suppliers */
  suppliers?: InputMaybe<Array<Scalars['String']['input']>>;
  /** List of product tags */
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Product search term */
  term?: InputMaybe<Scalars['String']['input']>;
  /** List of text filters  */
  textFilters?: InputMaybe<Array<ProductTextFilterInput>>;
  /** Browse catalog as a specific User. Permissions, orderlists, favorite will be merged  with the logged in user. */
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type CategoryResponse = {
  __typename?: 'CategoryResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type Category */
  items: Array<Category>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type CategorySearchInput = {
  /** Find categories by one or more categoryIds */
  categoryId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** The language to use when searching categories by name or slug. When omitted the categories default language is used */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Find categories by name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The amount of categories return per page */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** The page number to return */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Find categories by one or more parentCategoryIds */
  parentCategoryId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Find categories by one or more slugs */
  slug?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Available sortable fields One of: [name, dateCreated, entityDateChanged] */
  sortField?: InputMaybe<CategorySortableFields>;
  /** Sort ordering ['desc' or 'asc'] */
  sortOrder?: SortOrder;
};

/** Fields available for sorting categories */
export enum CategorySortableFields {
  DateChanged = 'dateChanged',
  DateCreated = 'dateCreated',
  Name = 'name',
}

export type Channel = {
  __typename?: 'Channel';
  /** Channel anonymous user id */
  anonymousUserId?: Maybe<Scalars['Int']['output']>;
  /** Catalog root id */
  catalogRootId?: Maybe<Scalars['Int']['output']>;
  channelId: Scalars['Int']['output'];
  /** Channel default letter id */
  defaultLetterId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  shop?: Maybe<Shop>;
};

export type Cluster = IBaseProduct &
  ICluster &
  IResource & {
    __typename?: 'Cluster';
    /** Lists attributes for this cluster based on the search input. */
    attributes?: Maybe<AttributeResultResponse>;
    category: Category;
    categoryId: Scalars['Int']['output'];
    categoryPath: Array<Category>;
    /** The class of the request resource */
    class: ProductClass;
    /** The classID of this cluster */
    clusterId: Scalars['Int']['output'];
    config?: Maybe<ClusterConfig>;
    /** The default language for this product or cluster, this is the language to fall back too, when there is no translation available in a specific language. */
    defaultLanguage: Scalars['String']['output'];
    defaultProduct?: Maybe<Product>;
    descriptions: Array<LocalizedString>;
    /**
     * The drilldown configuration for this cluster
     * @deprecated Use config.setting instead
     */
    drillDowns?: Maybe<Array<ClusterDrillDown>>;
    favoriteLists?: Maybe<FavoriteListsResponse>;
    /** The hidden status of this resource. */
    hidden: YesNo;
    /** Resource primary identifier */
    id: Scalars['Int']['output'];
    /**
     * The requested language for this this resource, default to the
     *           products default language.
     *           ISO 639-1 format.
     */
    language?: Maybe<Scalars['String']['output']>;
    names: Array<LocalizedString>;
    /** The configuration options for this cluster */
    options?: Maybe<Array<ClusterOption>>;
    orderlists: OrderlistsResponse;
    /** Array of selectable main products for this cluster, when the array contains more than one. Only one can be added per cart/order item */
    products?: Maybe<Array<Product>>;
    shortDescriptions: Array<LocalizedString>;
    /** The SKU (stock keeping unit) of this product */
    sku: Scalars['String']['output'];
    slugs: Array<LocalizedString>;
  };

export type ClusterAttributesArgs = {
  input?: InputMaybe<AttributeResultSearchInput>;
};

export type ClusterDescriptionsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type ClusterFavoriteListsArgs = {
  input?: InputMaybe<FavoriteListsSearchInput>;
};

export type ClusterNamesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type ClusterOrderlistsArgs = {
  input?: InputMaybe<OrderlistSearchInput>;
};

export type ClusterShortDescriptionsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type ClusterSlugsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type ClusterAttribute = Attribute & {
  __typename?: 'ClusterAttribute';
  attributeDescription?: Maybe<AttributeDescription>;
  /** The ID of the cluster this attribute belongs to */
  clusterId: Scalars['Int']['output'];
  /** The date when the attribute was created */
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the user that created the attribute */
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** The ID of the attribute */
  id: Scalars['String']['output'];
  /** The date when the attribute was last modified */
  lastModifiedAt: Scalars['DateTime']['output'];
  /** The ID of the user that last modified the attribute */
  lastModifiedBy?: Maybe<Scalars['Int']['output']>;
  /** The value of the attribute */
  value: AttributeValue;
};

export type ClusterConfig = {
  __typename?: 'ClusterConfig';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  settings?: Maybe<Array<ClusterConfigSetting>>;
};

export type ClusterConfigCreateInput = {
  name: Scalars['String']['input'];
  settings?: InputMaybe<Array<ClusterConfigSettingInput>>;
};

export type ClusterConfigResponse = {
  __typename?: 'ClusterConfigResponse';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  settings?: Maybe<Array<ClusterConfigSettingResponse>>;
};

export type ClusterConfigSetting = {
  __typename?: 'ClusterConfigSetting';
  displayType: ClusterConfigSettingDisplayType;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  priority: Scalars['String']['output'];
  type: ClusterConfigSettingType;
};

/** The way the drill down option for a linear cluster should be displayed. */
export enum ClusterConfigSettingDisplayType {
  Color = 'COLOR',
  Dropdown = 'DROPDOWN',
  Image = 'IMAGE',
  Radio = 'RADIO',
}

export type ClusterConfigSettingInput = {
  attributeName: Scalars['String']['input'];
  displayType: ClusterConfigSettingDisplayType;
  priority?: InputMaybe<Scalars['Int']['input']>;
};

export type ClusterConfigSettingResponse = {
  __typename?: 'ClusterConfigSettingResponse';
  attributeName: Scalars['String']['output'];
  clusterConfigId: Scalars['String']['output'];
  displayType: ClusterConfigSettingDisplayType;
  id: Scalars['Int']['output'];
  priority?: Maybe<Scalars['Int']['output']>;
};

/** The type of the cluster config setting. */
export enum ClusterConfigSettingType {
  EditableAttribute = 'EDITABLE_ATTRIBUTE',
  EditableField = 'EDITABLE_FIELD',
  SelectableAttribute = 'SELECTABLE_ATTRIBUTE',
  SharedAttribute = 'SHARED_ATTRIBUTE',
  SharedField = 'SHARED_FIELD',
}

export type ClusterConfigSettingUpdateInput = {
  displayType?: InputMaybe<ClusterConfigSettingDisplayType>;
  priority?: InputMaybe<Scalars['Int']['input']>;
};

export type ClusterCreateInput = {
  clusterConfigId: Scalars['Int']['input'];
  defaultLanguage?: InputMaybe<Scalars['String']['input']>;
  descriptions?: InputMaybe<Array<LocalizedStringInput>>;
  names?: InputMaybe<Array<LocalizedStringInput>>;
  parentId?: InputMaybe<Scalars['Int']['input']>;
  shortDescriptions?: InputMaybe<Array<LocalizedStringInput>>;
};

export type ClusterDrillDown = IClusterDrillDown & {
  __typename?: 'ClusterDrillDown';
  /** The id of the Attribute to create the drilldown matrix for  */
  attributeId: Scalars['String']['output'];
  /** Indicator how to display the current drilldown selection */
  displayType: ClusterDrillDownDisplayType;
  /** The order in which the drilldown should be generated */
  priority: Scalars['Int']['output'];
};

/** Ways to display a cluster's drilldown selection. */
export enum ClusterDrillDownDisplayType {
  Color = 'COLOR',
  Dropdown = 'DROPDOWN',
  Image = 'IMAGE',
  Radio = 'RADIO',
}

export type ClusterOption = IClusterOption & {
  __typename?: 'ClusterOption';
  /** The classID of the cluster that represents this cluster */
  clusterOptionId: Scalars['Int']['output'];
  /** The default product that should be selected for this option */
  defaultProduct?: Maybe<Product>;
  descriptions: Array<LocalizedString>;
  /** Indicates whether an option is hidden or not */
  hidden: YesNo;
  id: Scalars['Int']['output'];
  /** Indicates whether an option is required or not */
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  names: Array<LocalizedString>;
  products?: Maybe<Array<Product>>;
  shortDescriptions: Array<LocalizedString>;
};

export type ClusterOptionDescriptionsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type ClusterOptionNamesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type ClusterOptionShortDescriptionsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type ClusterOptionCreateInput = {
  defaultLanguage?: InputMaybe<Scalars['String']['input']>;
  descriptions?: InputMaybe<Array<LocalizedStringInput>>;
  isRequired?: InputMaybe<YesNo>;
  names?: InputMaybe<Array<LocalizedStringInput>>;
  shortDescriptions?: InputMaybe<Array<LocalizedStringInput>>;
};

export type ClusterOptionUpdateInput = {
  defaultLanguage?: InputMaybe<Scalars['String']['input']>;
  descriptions?: InputMaybe<Array<LocalizedStringInput>>;
  isRequired?: InputMaybe<YesNo>;
  names?: InputMaybe<Array<LocalizedStringInput>>;
  shortDescriptions?: InputMaybe<Array<LocalizedStringInput>>;
};

export type ClusterUpdateInput = {
  clusterConfigId?: InputMaybe<Scalars['Int']['input']>;
  defaultLanguage?: InputMaybe<Scalars['String']['input']>;
  descriptions?: InputMaybe<Array<LocalizedStringInput>>;
  names?: InputMaybe<Array<LocalizedStringInput>>;
  parentId?: InputMaybe<Scalars['Int']['input']>;
  shortDescriptions?: InputMaybe<Array<LocalizedStringInput>>;
};

export type CompaniesResponse = {
  __typename?: 'CompaniesResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type Company */
  items: Array<Company>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /**  The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type Company = {
  __typename?: 'Company';
  addresses: Array<Address>;
  /** Lists attributes for this company based on the search input. */
  attributes?: Maybe<AttributeResultResponse>;
  /** The coc number of the company. */
  cocNumber?: Maybe<Scalars['String']['output']>;
  /** The id of the company. */
  companyId: Scalars['Int']['output'];
  /** Children contacts of the company. */
  contacts?: Maybe<ContactsResponse>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** The debtor ID of the company. */
  debtorId?: Maybe<Scalars['String']['output']>;
  favoriteLists?: Maybe<FavoriteListsResponse>;
  /** Shows whether this company is hidden and should only be used for background operations. */
  hidden?: Maybe<YesNo>;
  inheritProductList?: Maybe<YesNo>;
  lastModifiedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Users/ contacts which are account managers of the company. */
  managers?: Maybe<Array<IBaseUser>>;
  /** The name of the company. */
  name: Scalars['String']['output'];
  /** Notes of the company. */
  notes?: Maybe<Scalars['String']['output']>;
  orderlists?: Maybe<OrderlistsResponse>;
  parentUsergroupId: Scalars['Int']['output'];
  /** The path in id's to the root of the inheritance tree. */
  path?: Maybe<Scalars['String']['output']>;
  pricesheets?: Maybe<Array<Pricesheet>>;
  /** The slug of the company. */
  slug?: Maybe<Scalars['String']['output']>;
  sources: Array<Source>;
  /** The tag of the company. */
  tag?: Maybe<Scalars['String']['output']>;
  /** The tax number of the company. */
  taxNumber?: Maybe<Scalars['String']['output']>;
  /** Parent usergroup of the company. */
  usergroup?: Maybe<Usergroup>;
  usergroupPath: Array<Usergroup>;
};

export type CompanyAddressesArgs = {
  isDefault?: InputMaybe<YesNo>;
  type?: InputMaybe<AddressType>;
};

export type CompanyAttributesArgs = {
  input?: InputMaybe<AttributeResultSearchInput>;
};

export type CompanyContactsArgs = {
  input?: InputMaybe<ContactSearchArguments>;
};

export type CompanyFavoriteListsArgs = {
  input?: InputMaybe<FavoriteListsBaseSearchInput>;
};

export type CompanyOrderlistsArgs = {
  input?: InputMaybe<OrderlistSearchInput>;
};

export type CompanyAddressCreateInput = {
  /** Address city */
  city: Scalars['String']['input'];
  /** Address code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** User company the address applies to */
  company?: InputMaybe<Scalars['String']['input']>;
  /** Address company primary identifier */
  companyId: Scalars['Int']['input'];
  /** Address country */
  country: Scalars['String']['input'];
  /** Address email [valid email format required] */
  email?: InputMaybe<Scalars['String']['input']>;
  /** User first name the address applies to */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** User gender the address applies to */
  gender?: InputMaybe<Gender>;
  /** ICP address field defines whether tax should be applied to an order if the address is selected as a delivery address. Used when shipping b2b order abroad */
  icp?: InputMaybe<YesNo>;
  /** Indicates whether the address is default for its type */
  isDefault?: InputMaybe<YesNo>;
  /** User last name the address applies to */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** User middle name the address applies to */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Address mobile number [min: 6, max: 30] */
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** Address delivery notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** User street number */
  number?: InputMaybe<Scalars['String']['input']>;
  /** User street number extension */
  numberExtension?: InputMaybe<Scalars['String']['input']>;
  /** Address fixed phone number [min: 6, max: 30] */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Address postal code */
  postalCode: Scalars['String']['input'];
  /** Address region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** User street address */
  street: Scalars['String']['input'];
  /** Address type [one of 'home', 'delivery' or 'invoice'] */
  type: AddressType;
};

export type CompanyAddressDeleteInput = {
  /** Company primary identifier */
  companyId: Scalars['Int']['input'];
  /** Address primary identifier */
  id: Scalars['Int']['input'];
};

export type CompanyAddressSearch = {
  __typename?: 'CompanyAddressSearch';
  /** Indicates whether the address is active */
  active?: Maybe<YesNo>;
  /** Address city */
  city: Scalars['String']['output'];
  /** Address code */
  code?: Maybe<Scalars['String']['output']>;
  /** Address country */
  country: Scalars['String']['output'];
  /** Email that applies to this address [valid email format needed] */
  email?: Maybe<Scalars['String']['output']>;
  /** First name the address applies to */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Address primary identifier */
  id: Scalars['Int']['output'];
  /** Indicates whether the address is default for its type */
  isDefault: YesNo;
  /** Last name the address applies to */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Mobile phone number that applies to this address [min: 6, max: 30] */
  mobile?: Maybe<Scalars['String']['output']>;
  /** Address friendly descriptive name */
  name?: Maybe<Scalars['String']['output']>;
  /** Address delivery notes */
  notes?: Maybe<Scalars['String']['output']>;
  /** Address street number */
  number?: Maybe<Scalars['String']['output']>;
  /** Address street number extension */
  numberExtension?: Maybe<Scalars['String']['output']>;
  /** Fixed phone number that applies to this address [min: 6, max: 30] */
  phone?: Maybe<Scalars['String']['output']>;
  /** Address postal code */
  postalCode: Scalars['String']['output'];
  /** Address region */
  region?: Maybe<Scalars['String']['output']>;
  /** Address street */
  street: Scalars['String']['output'];
  /** Address type [one of 'home', 'delivery', 'invoice'] */
  type: AddressType;
};

export type CompanyAddressUpdateInput = {
  /** Address city */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Address code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** User company the address applies to */
  company?: InputMaybe<Scalars['String']['input']>;
  /** Address company primary identifier */
  companyId: Scalars['Int']['input'];
  /** Address country */
  country?: InputMaybe<Scalars['String']['input']>;
  /** Address email [valid email format required] */
  email?: InputMaybe<Scalars['String']['input']>;
  /** User first name the address applies to */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** User gender the address applies to */
  gender?: InputMaybe<Gender>;
  /** ICP address field defines whether tax should be applied to an order if the address is selected as a delivery address. Used when shipping b2b order abroad */
  icp?: InputMaybe<YesNo>;
  /** Address primary identifier */
  id: Scalars['Int']['input'];
  /** Indicates whether the address is default for its type */
  isDefault?: InputMaybe<YesNo>;
  /** User last name the address applies to */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** User middle name the address applies to */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Address mobile number [min: 6, max: 30] */
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** Address delivery notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** User street number */
  number?: InputMaybe<Scalars['String']['input']>;
  /** User street number extension */
  numberExtension?: InputMaybe<Scalars['String']['input']>;
  /** Address fixed phone number [min: 6, max: 30] */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Address postal code */
  postalCode?: InputMaybe<Scalars['String']['input']>;
  /** Address region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** User street address */
  street?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyAttribute = Attribute & {
  __typename?: 'CompanyAttribute';
  attributeDescription?: Maybe<AttributeDescription>;
  /** The ID of the company this attribute belongs to */
  companyId: Scalars['Int']['output'];
  /** The date when the attribute was created */
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the user that created the attribute */
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** The ID of the attribute */
  id: Scalars['String']['output'];
  /** The date when the attribute was last modified */
  lastModifiedAt: Scalars['DateTime']['output'];
  /** The ID of the user that last modified the attribute */
  lastModifiedBy?: Maybe<Scalars['Int']['output']>;
  /** The value of the attribute */
  value: AttributeValue;
};

export type CompanyAttributeDescriptionSearch = {
  __typename?: 'CompanyAttributeDescriptionSearch';
  /** AttributeDescription id */
  id: Scalars['String']['output'];
  /** Descriptive identifier for this AttributeDescription */
  name: Scalars['String']['output'];
};

export type CompanyAttributeSearch = {
  __typename?: 'CompanyAttributeSearch';
  /** Attribute Description */
  attributeDescription?: Maybe<CompanyAttributeDescriptionSearch>;
  /** Creation date */
  createdAt: Scalars['DateTime']['output'];
  /** Created by id */
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** Attribute id */
  id: Scalars['String']['output'];
  /** Last modified date */
  lastModifiedAt: Scalars['DateTime']['output'];
  /** Last modified by id */
  lastModifiedBy?: Maybe<Scalars['Int']['output']>;
  /** Attribute value */
  value: CompanyAttributeValueSearch;
};

export type CompanyAttributeValueSearch = {
  __typename?: 'CompanyAttributeValueSearch';
  /** AttributeValue id */
  id: Scalars['String']['output'];
  /** AttributeValue type */
  type: Scalars['String']['output'];
};

export type CompanyContactSearch = {
  __typename?: 'CompanyContactSearch';
  /** Attributes of the contact */
  attributes: Array<CompanyAttributeSearch>;
  /** Bank Account Number of the contact */
  bankAccount?: Maybe<Scalars['String']['output']>;
  /** Bank Identification Code of the contact */
  bic?: Maybe<Scalars['String']['output']>;
  /** Contract creation date */
  createdAt: Scalars['DateTime']['output'];
  /** Date of birth of the contact. */
  dateOfBirth?: Maybe<Scalars['DateTime']['output']>;
  /** Contact debtor identifier */
  debtorId?: Maybe<Scalars['String']['output']>;
  /** Email of the contact. */
  email: Scalars['String']['output'];
  /** First name of the contact. */
  firstName: Scalars['String']['output'];
  /** International Bank Account Number of the contact */
  iban?: Maybe<Scalars['String']['output']>;
  /** Contact id */
  id: Scalars['Int']['output'];
  /** Contact last modified date */
  lastModifiedAt: Scalars['DateTime']['output'];
  /** Last name of the contact. */
  lastName: Scalars['String']['output'];
  /** Mobile number of the contact. */
  mobile?: Maybe<Scalars['String']['output']>;
  /** Contact notes */
  notes?: Maybe<Scalars['String']['output']>;
  /** Phone number of the contact. */
  phone?: Maybe<Scalars['String']['output']>;
};

export type CompanySearch = {
  __typename?: 'CompanySearch';
  /** Addresses assigned to this company. */
  addresses: Array<CompanyAddressSearch>;
  /** Attributes of the company. */
  attributes: Array<CompanyAttributeSearch>;
  /** Company coc number */
  cocNumber?: Maybe<Scalars['String']['output']>;
  /** Contacts part of this company. */
  contacts: Array<CompanyContactSearch>;
  /** Company creation date */
  createdAt: Scalars['DateTime']['output'];
  /** Company id */
  id: Scalars['Int']['output'];
  /** Company last modified date */
  lastModifiedAt: Scalars['DateTime']['output'];
  /** Company name */
  name: Scalars['String']['output'];
  /** Notes of the company */
  notes?: Maybe<Scalars['String']['output']>;
  /** Company tax number */
  taxNumber?: Maybe<Scalars['String']['output']>;
};

export type CompanySearchArguments = {
  /** List of unique company ids */
  companyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** When true, only companies managed by the viewer will be listed */
  managedCompaniesOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** Company's name */
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
  /** List of company sort filters */
  sort?: InputMaybe<Array<CompanySortInput>>;
  /** Usergroup unique identifier */
  usergroupId?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanySearchInput = {
  /** Search by active address */
  addressActive?: InputMaybe<YesNo>;
  /** Search by default address */
  addressIsDefault?: InputMaybe<YesNo>;
  /** Search by address types */
  addressTypes?: InputMaybe<Array<AddressType>>;
  /** Search by attribute date created */
  companyAttributeCreatedAt?: InputMaybe<DateSearchInput>;
  /** Search by attribute date changed */
  companyAttributeLastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Search by company date created */
  companyCreatedAt?: InputMaybe<DateSearchInput>;
  /** Search by company date changed */
  companyLastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Search by contact attribute date created */
  contactAttributeCreatedAt?: InputMaybe<DateSearchInput>;
  /** Search by contact attribute date changed */
  contactAttributeLastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Search by contact date created */
  contactCreatedAt?: InputMaybe<DateSearchInput>;
  /** Search by contact date of birth */
  contactDateOfBirth?: InputMaybe<DateSearchInput>;
  /** Search by contact date changed */
  contactLastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Pagination offset number [default=12] */
  offset?: Scalars['Int']['input'];
  /** Pagination page number [default=1] */
  page?: Scalars['Int']['input'];
  /**
   * Inputs to sort by!
   *     NOTE: Defaults to [{ field: RELEVANCE, order: DESC }] if not provided.
   */
  sortInputs?: InputMaybe<Array<CompanySearchSortInput>>;
  /** Company search term */
  term?: InputMaybe<Scalars['String']['input']>;
  /** List of fields to apply in term search. Defaults to [{ boost: 1, fieldNames: ALL_AVAILABLE_TERM_FIELDS }] if not provided. */
  termFields?: InputMaybe<Array<CompanyTermFieldInput>>;
};

export type CompanySearchResponse = {
  __typename?: 'CompanySearchResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type CompanySearch */
  items: Array<CompanySearch>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export enum CompanySearchSortField {
  CompanyAddressActive = 'COMPANY_ADDRESS_ACTIVE',
  CompanyAddressCity = 'COMPANY_ADDRESS_CITY',
  CompanyAddressCode = 'COMPANY_ADDRESS_CODE',
  CompanyAddressCountry = 'COMPANY_ADDRESS_COUNTRY',
  CompanyAddressEmail = 'COMPANY_ADDRESS_EMAIL',
  CompanyAddressFirstName = 'COMPANY_ADDRESS_FIRST_NAME',
  CompanyAddressId = 'COMPANY_ADDRESS_ID',
  CompanyAddressIsDefault = 'COMPANY_ADDRESS_IS_DEFAULT',
  CompanyAddressLastName = 'COMPANY_ADDRESS_LAST_NAME',
  CompanyAddressMobile = 'COMPANY_ADDRESS_MOBILE',
  CompanyAddressName = 'COMPANY_ADDRESS_NAME',
  CompanyAddressNotes = 'COMPANY_ADDRESS_NOTES',
  CompanyAddressNumber = 'COMPANY_ADDRESS_NUMBER',
  CompanyAddressNumberExtension = 'COMPANY_ADDRESS_NUMBER_EXTENSION',
  CompanyAddressPhone = 'COMPANY_ADDRESS_PHONE',
  CompanyAddressPostalCode = 'COMPANY_ADDRESS_POSTAL_CODE',
  CompanyAddressRegion = 'COMPANY_ADDRESS_REGION',
  CompanyAddressStreet = 'COMPANY_ADDRESS_STREET',
  CompanyAddressType = 'COMPANY_ADDRESS_TYPE',
  CompanyAttributeCreatedAt = 'COMPANY_ATTRIBUTE_CREATED_AT',
  CompanyAttributeCreatedBy = 'COMPANY_ATTRIBUTE_CREATED_BY',
  CompanyAttributeDescriptionId = 'COMPANY_ATTRIBUTE_DESCRIPTION_ID',
  CompanyAttributeDescriptionName = 'COMPANY_ATTRIBUTE_DESCRIPTION_NAME',
  CompanyAttributeDescriptionType = 'COMPANY_ATTRIBUTE_DESCRIPTION_TYPE',
  CompanyAttributeId = 'COMPANY_ATTRIBUTE_ID',
  CompanyAttributeLastModifiedAt = 'COMPANY_ATTRIBUTE_LAST_MODIFIED_AT',
  CompanyAttributeLastModifiedBy = 'COMPANY_ATTRIBUTE_LAST_MODIFIED_BY',
  CompanyAttributeValueColorValue = 'COMPANY_ATTRIBUTE_VALUE_COLOR_VALUE',
  CompanyAttributeValueDateValue = 'COMPANY_ATTRIBUTE_VALUE_DATE_VALUE',
  CompanyAttributeValueDecimalValue = 'COMPANY_ATTRIBUTE_VALUE_DECIMAL_VALUE',
  CompanyAttributeValueId = 'COMPANY_ATTRIBUTE_VALUE_ID',
  CompanyAttributeValueIntegerValue = 'COMPANY_ATTRIBUTE_VALUE_INTEGER_VALUE',
  CompanyAttributeValueName = 'COMPANY_ATTRIBUTE_VALUE_NAME',
  CompanyAttributeValueTextValues = 'COMPANY_ATTRIBUTE_VALUE_TEXT_VALUES',
  CompanyAttributeValueType = 'COMPANY_ATTRIBUTE_VALUE_TYPE',
  CompanyCocNumber = 'COMPANY_COC_NUMBER',
  CompanyCreatedAt = 'COMPANY_CREATED_AT',
  CompanyId = 'COMPANY_ID',
  CompanyLastModifiedAt = 'COMPANY_LAST_MODIFIED_AT',
  CompanyName = 'COMPANY_NAME',
  CompanyNotes = 'COMPANY_NOTES',
  CompanyTaxNumber = 'COMPANY_TAX_NUMBER',
  ContactAttributeCreatedAt = 'CONTACT_ATTRIBUTE_CREATED_AT',
  ContactAttributeCreatedBy = 'CONTACT_ATTRIBUTE_CREATED_BY',
  ContactAttributeDescriptionId = 'CONTACT_ATTRIBUTE_DESCRIPTION_ID',
  ContactAttributeDescriptionName = 'CONTACT_ATTRIBUTE_DESCRIPTION_NAME',
  ContactAttributeDescriptionType = 'CONTACT_ATTRIBUTE_DESCRIPTION_TYPE',
  ContactAttributeId = 'CONTACT_ATTRIBUTE_ID',
  ContactAttributeLastModifiedAt = 'CONTACT_ATTRIBUTE_LAST_MODIFIED_AT',
  ContactAttributeLastModifiedBy = 'CONTACT_ATTRIBUTE_LAST_MODIFIED_BY',
  ContactAttributeValueColorValue = 'CONTACT_ATTRIBUTE_VALUE_COLOR_VALUE',
  ContactAttributeValueDateValue = 'CONTACT_ATTRIBUTE_VALUE_DATE_VALUE',
  ContactAttributeValueDecimalValue = 'CONTACT_ATTRIBUTE_VALUE_DECIMAL_VALUE',
  ContactAttributeValueId = 'CONTACT_ATTRIBUTE_VALUE_ID',
  ContactAttributeValueIntegerValue = 'CONTACT_ATTRIBUTE_VALUE_INTEGER_VALUE',
  ContactAttributeValueName = 'CONTACT_ATTRIBUTE_VALUE_NAME',
  ContactAttributeValueTextValues = 'CONTACT_ATTRIBUTE_VALUE_TEXT_VALUES',
  ContactAttributeValueType = 'CONTACT_ATTRIBUTE_VALUE_TYPE',
  ContactBankAccount = 'CONTACT_BANK_ACCOUNT',
  ContactBic = 'CONTACT_BIC',
  ContactCreatedAt = 'CONTACT_CREATED_AT',
  ContactDateOfBirth = 'CONTACT_DATE_OF_BIRTH',
  ContactDebtorId = 'CONTACT_DEBTOR_ID',
  ContactEmail = 'CONTACT_EMAIL',
  ContactFirstName = 'CONTACT_FIRST_NAME',
  ContactIban = 'CONTACT_IBAN',
  ContactId = 'CONTACT_ID',
  ContactLastModifiedAt = 'CONTACT_LAST_MODIFIED_AT',
  ContactLastName = 'CONTACT_LAST_NAME',
  ContactMobile = 'CONTACT_MOBILE',
  ContactNotes = 'CONTACT_NOTES',
  ContactPhone = 'CONTACT_PHONE',
  Relevance = 'RELEVANCE',
}

export type CompanySearchSortInput = {
  /** Field to sort by */
  field: CompanySearchSortField;
  /** Order option to sort by. [Default to `ASC`] */
  order: SortOrder;
};

export type CompanySortInput = {
  /** Available sortable fields */
  field: CompanySortableField;
  /** Sort ordering ['ASC' or 'DESC'] */
  order?: SortOrder;
};

/** Company sortable fields */
export enum CompanySortableField {
  CreatedAt = 'CREATED_AT',
  LastModifiedAt = 'LAST_MODIFIED_AT',
  Name = 'NAME',
}

export type CompanyTermFieldInput = {
  /** Search boost value */
  boost?: InputMaybe<Scalars['Int']['input']>;
  /** List of available fields */
  fieldNames: Array<CompanyTermFieldName>;
};

export enum CompanyTermFieldName {
  CompanyAddressCity = 'COMPANY_ADDRESS_CITY',
  CompanyAddressCode = 'COMPANY_ADDRESS_CODE',
  CompanyAddressCountry = 'COMPANY_ADDRESS_COUNTRY',
  CompanyAddressEmail = 'COMPANY_ADDRESS_EMAIL',
  CompanyAddressFirstName = 'COMPANY_ADDRESS_FIRST_NAME',
  CompanyAddressId = 'COMPANY_ADDRESS_ID',
  CompanyAddressLastName = 'COMPANY_ADDRESS_LAST_NAME',
  CompanyAddressMobile = 'COMPANY_ADDRESS_MOBILE',
  CompanyAddressName = 'COMPANY_ADDRESS_NAME',
  CompanyAddressNotes = 'COMPANY_ADDRESS_NOTES',
  CompanyAddressNumber = 'COMPANY_ADDRESS_NUMBER',
  CompanyAddressNumberExtension = 'COMPANY_ADDRESS_NUMBER_EXTENSION',
  CompanyAddressPhone = 'COMPANY_ADDRESS_PHONE',
  CompanyAddressPostalCode = 'COMPANY_ADDRESS_POSTAL_CODE',
  CompanyAddressRegion = 'COMPANY_ADDRESS_REGION',
  CompanyAddressStreet = 'COMPANY_ADDRESS_STREET',
  CompanyAttributeDescriptionId = 'COMPANY_ATTRIBUTE_DESCRIPTION_ID',
  CompanyAttributeDescriptionName = 'COMPANY_ATTRIBUTE_DESCRIPTION_NAME',
  CompanyAttributeDescriptionType = 'COMPANY_ATTRIBUTE_DESCRIPTION_TYPE',
  CompanyAttributeId = 'COMPANY_ATTRIBUTE_ID',
  CompanyAttributeValueColorValue = 'COMPANY_ATTRIBUTE_VALUE_COLOR_VALUE',
  CompanyAttributeValueDateValue = 'COMPANY_ATTRIBUTE_VALUE_DATE_VALUE',
  CompanyAttributeValueDecimalValue = 'COMPANY_ATTRIBUTE_VALUE_DECIMAL_VALUE',
  CompanyAttributeValueId = 'COMPANY_ATTRIBUTE_VALUE_ID',
  CompanyAttributeValueIntegerValue = 'COMPANY_ATTRIBUTE_VALUE_INTEGER_VALUE',
  CompanyAttributeValueName = 'COMPANY_ATTRIBUTE_VALUE_NAME',
  CompanyAttributeValueTextValues = 'COMPANY_ATTRIBUTE_VALUE_TEXT_VALUES',
  CompanyAttributeValueType = 'COMPANY_ATTRIBUTE_VALUE_TYPE',
  CompanyCocNumber = 'COMPANY_COC_NUMBER',
  CompanyId = 'COMPANY_ID',
  CompanyName = 'COMPANY_NAME',
  CompanyNotes = 'COMPANY_NOTES',
  CompanyTaxNumber = 'COMPANY_TAX_NUMBER',
  ContactAttributeDescriptionId = 'CONTACT_ATTRIBUTE_DESCRIPTION_ID',
  ContactAttributeDescriptionName = 'CONTACT_ATTRIBUTE_DESCRIPTION_NAME',
  ContactAttributeDescriptionType = 'CONTACT_ATTRIBUTE_DESCRIPTION_TYPE',
  ContactAttributeId = 'CONTACT_ATTRIBUTE_ID',
  ContactAttributeValueColorValue = 'CONTACT_ATTRIBUTE_VALUE_COLOR_VALUE',
  ContactAttributeValueDateValue = 'CONTACT_ATTRIBUTE_VALUE_DATE_VALUE',
  ContactAttributeValueDecimalValue = 'CONTACT_ATTRIBUTE_VALUE_DECIMAL_VALUE',
  ContactAttributeValueId = 'CONTACT_ATTRIBUTE_VALUE_ID',
  ContactAttributeValueIntegerValue = 'CONTACT_ATTRIBUTE_VALUE_INTEGER_VALUE',
  ContactAttributeValueName = 'CONTACT_ATTRIBUTE_VALUE_NAME',
  ContactAttributeValueTextValues = 'CONTACT_ATTRIBUTE_VALUE_TEXT_VALUES',
  ContactAttributeValueType = 'CONTACT_ATTRIBUTE_VALUE_TYPE',
  ContactBankAccount = 'CONTACT_BANK_ACCOUNT',
  ContactBic = 'CONTACT_BIC',
  ContactDebtorId = 'CONTACT_DEBTOR_ID',
  ContactEmail = 'CONTACT_EMAIL',
  ContactFirstName = 'CONTACT_FIRST_NAME',
  ContactIban = 'CONTACT_IBAN',
  ContactId = 'CONTACT_ID',
  ContactLastName = 'CONTACT_LAST_NAME',
  ContactMobile = 'CONTACT_MOBILE',
  ContactNotes = 'CONTACT_NOTES',
  ContactPhone = 'CONTACT_PHONE',
}

export type ConfirmationResponse = {
  __typename?: 'ConfirmationResponse';
  /** Confirmation message */
  message: Scalars['String']['output'];
  /** Confirmation status value */
  status: Scalars['String']['output'];
};

export type Contact = IBaseUser & {
  __typename?: 'Contact';
  /** Lists attributes for this contacts based on the search input. */
  attributes?: Maybe<AttributeResultResponse>;
  /** The Bank Account Number of the user */
  bankAccount?: Maybe<Scalars['String']['output']>;
  /** The Bank Identification Code of the user */
  bic?: Maybe<Scalars['String']['output']>;
  /** All the companies that a contact belongs to */
  companies?: Maybe<CompaniesResponse>;
  company?: Maybe<Company>;
  /** The unique identifier of the contact. */
  contactId: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** The date of birth of the contact. */
  dateOfBirth?: Maybe<Scalars['DateTime']['output']>;
  /**
   * The debtor ID of the contact.
   * @deprecated Deprecated in favor of company debtorId
   */
  debtorId?: Maybe<Scalars['String']['output']>;
  /** The email of the user. */
  email: Scalars['String']['output'];
  /** The expiration date of the contact. After this date expires contact will be disabled. */
  expires?: Maybe<Scalars['DateTime']['output']>;
  /** External ID */
  externalId?: Maybe<Scalars['String']['output']>;
  favoriteLists?: Maybe<FavoriteListsResponse>;
  /** The first name of the user. */
  firstName: Scalars['String']['output'];
  /** The gender of the user. */
  gender?: Maybe<Gender>;
  /** The International Bank Account Number of the user */
  iban?: Maybe<Scalars['String']['output']>;
  isLoggedIn?: Maybe<Scalars['Boolean']['output']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The last name of the user. */
  lastName: Scalars['String']['output'];
  /** The login name (email) the account is linked to, generally the same as email address. When null a account has not been linked yet. */
  login?: Maybe<Scalars['String']['output']>;
  mailingList?: Maybe<YesNo>;
  /** Companies managed by the contact. */
  managedCompanies?: Maybe<Array<Company>>;
  /** The middle name of the user. */
  middleName?: Maybe<Scalars['String']['output']>;
  /** The mobile number of the user. */
  mobile?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  orderlists?: Maybe<OrderlistsResponse>;
  /** The company id of parent company. */
  parentCompanyId: Scalars['Int']['output'];
  /** The phone number of the user. */
  phone?: Maybe<Scalars['String']['output']>;
  pricesheets?: Maybe<Array<Pricesheet>>;
  /** The primary language of the user */
  primaryLanguage?: Maybe<Scalars['String']['output']>;
  sources: Array<Source>;
  /** The unique identifier of the user. */
  userId?: Maybe<Scalars['Int']['output']>;
};

export type ContactAttributesArgs = {
  input?: InputMaybe<AttributeResultSearchInput>;
};

export type ContactCompaniesArgs = {
  input?: InputMaybe<ContactCompaniesSearchInput>;
};

export type ContactFavoriteListsArgs = {
  input?: InputMaybe<FavoriteListsBaseSearchInput>;
};

export type ContactOrderlistsArgs = {
  input?: InputMaybe<OrderlistSearchInput>;
};

export type ContactActionsData = {
  __typename?: 'ContactActionsData';
  /** Company ID the action was performed to */
  companyId: Scalars['Int']['output'];
  /** Output message response [INFO/WARNING/ERROR] */
  message: Scalars['String']['output'];
  /** Success status */
  success: Scalars['Boolean']['output'];
};

export type ContactActionsResponse = {
  __typename?: 'ContactActionsResponse';
  /** List of output messaged for actions performed */
  data: Array<ContactActionsData>;
  /** List of extra messages */
  messages: Array<Scalars['String']['output']>;
};

export type ContactAddToCompaniesInput = {
  /** List of company unique identifiers */
  companyIds: Array<Scalars['Int']['input']>;
};

export type ContactAddToCompaniesResponse = {
  __typename?: 'ContactAddToCompaniesResponse';
  /** Output messages about the actions performed. */
  actions: ContactActionsResponse;
  /** The contact object */
  contact: Contact;
};

export type ContactAttribute = Attribute & {
  __typename?: 'ContactAttribute';
  attributeDescription?: Maybe<AttributeDescription>;
  /** The ID of the contact this attribute belongs to */
  contactId: Scalars['Int']['output'];
  /** The date when the attribute was created */
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the user that created the attribute */
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** The ID of the attribute */
  id: Scalars['String']['output'];
  /** The date when the attribute was last modified */
  lastModifiedAt: Scalars['DateTime']['output'];
  /** The ID of the user that last modified the attribute */
  lastModifiedBy?: Maybe<Scalars['Int']['output']>;
  /** The value of the attribute */
  value: AttributeValue;
};

export type ContactCompaniesSearchInput = {
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type ContactRemoveFromCompaniesInput = {
  /** List of company unique identifiers */
  companyIds: Array<Scalars['Int']['input']>;
};

export type ContactRemoveFromCompaniesResponse = {
  __typename?: 'ContactRemoveFromCompaniesResponse';
  /** Output messages about the actions performed. */
  actions: ContactActionsResponse;
  /** The contact object */
  contact: Contact;
};

export type ContactSearchArguments = {
  /** List of unique contact ids */
  contactIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** Contact's email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Contact's first name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Contact's gender */
  gender?: InputMaybe<Gender>;
  /** Search by date modified */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Contact's last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Contact's middle name */
  middleName?: InputMaybe<Scalars['String']['input']>;
  offset?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
  /** List of contact sort filters */
  sort?: InputMaybe<Array<ContactSortInput>>;
};

export type ContactSortInput = {
  /** Available contact sortable fields */
  field: ContactSortableField;
  /** Sort contact ordering ['ASC' or 'DESC'] */
  order?: SortOrder;
};

/** Contact sortable fields */
export enum ContactSortableField {
  CreatedAt = 'CREATED_AT',
  FirstName = 'FIRST_NAME',
  LastModifiedAt = 'LAST_MODIFIED_AT',
  LastName = 'LAST_NAME',
}

export type ContactsResponse = {
  __typename?: 'ContactsResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type Contact */
  items: Array<Contact>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /**  The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type CreateAccountInput = {
  /** Optionally provide a custom password */
  password?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAuthenticationClaimsInput = {
  class: Scalars['String']['input'];
  companyId?: InputMaybe<Scalars['Int']['input']>;
  companyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
  userId: Scalars['Int']['input'];
};

export type CreateAuthenticationInput = {
  claims?: InputMaybe<CreateAuthenticationClaimsInput>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCategoryInput = {
  defaultLanguage: Scalars['String']['input'];
  /**
   * The long description for this category,
   *     this field can contain html formatting,
   *     this field can be translated is case of multilingual catalog.
   */
  description: Array<LocalizedStringInput>;
  /**
   * Localized category name as it is available in
   *     the supported language defaults to the category's default language.
   */
  name: Array<LocalizedStringInput>;
  /** The category's parent Category when available */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /**
   * The long description for this category,
   *     this field can contain html formatting,
   *     this field can be translated is case of multilingual catalog.
   */
  shortDescription: Array<LocalizedStringInput>;
};

export type CreateCompanyInput = {
  cocNumber?: InputMaybe<Scalars['String']['input']>;
  debtorId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  /** The id of the usergroup the company should be created in. */
  parentId?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  taxNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateContactInput = {
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  /** The debtor id of the contact. Deprecated in favor of company debtorId */
  debtorId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailingList?: InputMaybe<YesNo>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** The id of the company the contact should be created in. */
  parentId: Scalars['Int']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  /** The primary language of the contact. */
  primaryLanguage?: InputMaybe<Scalars['String']['input']>;
};

export type CreateInventoryInput = {
  /** Inventory cost price */
  costPrice?: InputMaybe<Scalars['Float']['input']>;
  /** Inventory location */
  location?: InputMaybe<Scalars['String']['input']>;
  /** Inventory estimated next delivery date in ISO8601 format */
  nextDeliveryDate?: InputMaybe<Scalars['String']['input']>;
  /** Inventory note */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Inventory product identifier */
  productId: Scalars['Int']['input'];
  /** Inventory quantity */
  quantity: Scalars['Int']['input'];
  /**
   * Inventory supplier!
   *
   *     defaults:
   *
   *       - 'INTERN' for local inventory type
   *
   *       - '[inherited from product supplier]' for supplier inventory type
   */
  supplier?: InputMaybe<Scalars['String']['input']>;
  /** Inventory type to create */
  type: InventoryType;
  /** Inventory warehouse identifier */
  warehouseId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateOrUpdateOrderItemInput = {
  /** OrderItem create payload */
  create?: InputMaybe<OrderItemCreateInput>;
  /** OrderItem update payload */
  update?: InputMaybe<OrderItemUpdateInput>;
};

export type CreateOrderStatusInput = {
  /** The order status set to add this order status to */
  addOrderStatusToSet?: InputMaybe<OrderStatusSetSearchByInput>;
  /** The order status code value */
  code: Scalars['String']['input'];
  /** The order status description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Marks if the order status can be archived */
  isArchivable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is confirmable */
  isConfirmable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order status is default */
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is deletable */
  isDeletable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is editable */
  isEditable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is exportable */
  isExportable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is public */
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  /** The order status name */
  name: Scalars['String']['input'];
  /** The IDs of the next possible order statuses */
  nextStatusesIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** The order status for order type.  */
  orderType?: Scalars['String']['input'];
  /** The order status priority */
  priority?: InputMaybe<Scalars['Int']['input']>;
  /** The order status type. SYSTEM types are only created by the system */
  type?: Scalars['String']['input'];
};

export type CreateOrderStatusSetInput = {
  /** The order status set description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The order status set name */
  name: Scalars['String']['input'];
  /** The ids of the order statuses that will be added to the set */
  orderStatusIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type CreatePaymentInput = {
  /** Add a transaction related to the specified payment */
  addTransaction?: InputMaybe<CreateTransactionInput>;
  /** Payment amount [denomination in cents] */
  amount: Scalars['Int']['input'];
  /** Guest User ID */
  anonymousId?: InputMaybe<Scalars['Int']['input']>;
  /** Payment currency - ISO 4217 currency code => https://www.xe.com/iso4217.php */
  currency: Scalars['String']['input'];
  /** Payment method used by the PSP */
  method: Scalars['String']['input'];
  /** Unique orderId reference for the Payment */
  orderId: Scalars['Int']['input'];
  /** Unique paymentId reference for the Payment - provided by the PSP */
  paymentId?: InputMaybe<Scalars['String']['input']>;
  /** Payment status */
  status: PaymentStatuses;
  /** Logged in User ID */
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateProductInput = {
  /** The bar code of this product */
  barCode?: InputMaybe<Scalars['String']['input']>;
  /** The id of the category the product should be created in. */
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  /** Additional keywords of this product per language. Added via the PIM */
  customKeywords?: InputMaybe<Array<LocalizedStringInput>>;
  /** The descriptions of this product per language */
  descriptions?: InputMaybe<Array<LocalizedStringInput>>;
  /** The European Article Number (EAN) code of this product */
  eanCode?: InputMaybe<Scalars['String']['input']>;
  /** The quantity that provides the best value for money for this product */
  economicOrderQuantity?: InputMaybe<Scalars['Int']['input']>;
  /** Additional keywords of this product per language. Added by the supplier */
  keywords?: InputMaybe<Array<LocalizedStringInput>>;
  /** The language of the product. */
  language: Scalars['String']['input'];
  /** The manufacturer of this product */
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  /** The Original Equipment Manufacturer code (OEM) of this product */
  manufacturerCode?: InputMaybe<Scalars['String']['input']>;
  /** The minimum quantity of products that can be ordered */
  minimumQuantity?: InputMaybe<Scalars['Int']['input']>;
  /** The names of this product per language */
  names?: InputMaybe<Array<LocalizedStringInput>>;
  /** Notes on the product for internal use */
  notes?: InputMaybe<Array<LocalizedStringInput>>;
  /** Is this product orderable */
  orderable?: InputMaybe<YesNo>;
  /** The orderable from date of this product */
  orderableFrom?: InputMaybe<Scalars['String']['input']>;
  /** The orderable to date of this product */
  orderableTo?: InputMaybe<Scalars['String']['input']>;
  /** The package type of this product. i.e.: PIECE, BOX, BLISTER */
  package?: InputMaybe<Scalars['String']['input']>;
  /** The package description of this product per language */
  packageDescriptions?: InputMaybe<Array<LocalizedStringInput>>;
  /** The amount of items per package */
  packageUnit?: InputMaybe<Scalars['Int']['input']>;
  /** The quantifier for the package unit, i.e.: PIECE or BOX */
  packageUnitQuantity?: InputMaybe<Scalars['String']['input']>;
  /** Is it a physical product or a service, download or warranty */
  physical?: InputMaybe<YesNo>;
  /** The price group the product belongs to, this field can be used for price calculations */
  priceGroup?: InputMaybe<Scalars['String']['input']>;
  /** The minimum quantity of products that can be ordered when creating a purchase order */
  purchaseMinimumQuantity?: InputMaybe<Scalars['Int']['input']>;
  /** The unit in which the product can be ordered when creating a purchase order */
  purchaseUnit?: InputMaybe<Scalars['Int']['input']>;
  /** The release date of this product */
  releaseDate?: InputMaybe<Scalars['String']['input']>;
  /** Is this product returnable */
  returnable?: InputMaybe<YesNo>;
  /** The short descriptions of this product per language */
  shortDescriptions?: InputMaybe<Array<LocalizedStringInput>>;
  /** The short name for the product, used for abbreviated orderlines */
  shortName?: InputMaybe<Scalars['String']['input']>;
  /** The SKU (stock keeping unit) of this product */
  sku?: InputMaybe<Scalars['String']['input']>;
  /** The status of this product */
  status?: InputMaybe<ProductStatus>;
  /** The extra status of this product, commonly linked to a custom valueset */
  statusExtra?: InputMaybe<Scalars['String']['input']>;
  /** The supplier of this product */
  supplier?: InputMaybe<Scalars['String']['input']>;
  /** The supplier code of this product */
  supplierCode?: InputMaybe<Scalars['String']['input']>;
  /** The taxonomy the product belongs to */
  taxonomy?: InputMaybe<Scalars['String']['input']>;
  /** The turnover group that classifies the product within the financial structure */
  turnoverGroup?: InputMaybe<Scalars['String']['input']>;
  /** The unit in which the product can be ordered */
  unit?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateSparePartInput = {
  /** Spare PartsMachine name */
  name?: InputMaybe<Array<LocalizedStringInput>>;
  quantity: Scalars['Int']['input'];
  sku: Scalars['String']['input'];
};

export type CreateSparePartsMachineInput = {
  /** Machine description */
  description?: InputMaybe<Array<LocalizedStringInput>>;
  externalReference: ExternalReferenceInput;
  /** Level 1 SubMachines input */
  machines?: InputMaybe<Array<CreateSparePartsMachineInput>>;
  /** Spare PartsMachine name */
  name: Array<LocalizedStringInput>;
  /** Spare parts for this machine */
  parts?: InputMaybe<Array<CreateSparePartInput>>;
};

export type CreateSurchargeInput = {
  /** A brief description of the surcharge */
  description?: InputMaybe<Array<LocalizedStringInput>>;
  /** Indicates whether the surcharge is active or not */
  enabled: Scalars['Boolean']['input'];
  /** A descriptive name for the surcharge */
  name: Array<LocalizedStringInput>;
  /** Shop identifier for the surcharge to apply to... If not provided the default will be inferred from the channel */
  shopId?: InputMaybe<Scalars['Int']['input']>;
  /** Tax code */
  taxCode: TaxCode;
  /** Tax zone */
  taxZone: Scalars['String']['input'];
  /** Type of the surcharge */
  type: SurchargeType;
  /** The date and time the surcharge is valid from */
  validFrom?: InputMaybe<Scalars['String']['input']>;
  /** The date and time the surcharge is valid to */
  validTo?: InputMaybe<Scalars['String']['input']>;
  /** The value to be applied as surcharge (e.g., percentage or amount) */
  value: Scalars['Float']['input'];
};

export type CreateTenantDto = {
  default: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  tenant: Scalars['String']['input'];
};

export type CreateTransactionInput = {
  /** Transaction amount [denomination in cents] */
  amount: Scalars['Int']['input'];
  /** Transaction currency - ISO 4217 currency code => https://www.xe.com/iso4217.php */
  currency: Scalars['String']['input'];
  /** Description of the transaction */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Payment ID - usually provided by the PSP */
  paymentId?: InputMaybe<Scalars['String']['input']>;
  /** PSP Provider Name */
  provider?: InputMaybe<Scalars['String']['input']>;
  /** Transaction status */
  status: TransactionStatuses;
  /** Transaction timestamp */
  timestamp?: InputMaybe<Scalars['DateTime']['input']>;
  /** Unique transactionId reference for the Payment - usually provided by the PSP */
  transactionId: Scalars['String']['input'];
  /** Transaction type */
  type: TransactionTypes;
};

export type CreateUserDto = {
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  job?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUsergroupInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  /** The id of the usergroup the company should be created in. */
  parentId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateWarehouseAddressInput = {
  /** Address city */
  city: Scalars['String']['input'];
  /** Address code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** User company the address applies to */
  company?: InputMaybe<Scalars['String']['input']>;
  /** Address country */
  country: Scalars['String']['input'];
  /** Address email [valid email format required] */
  email?: InputMaybe<Scalars['String']['input']>;
  /** User first name the address applies to */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** User gender the address applies to */
  gender?: InputMaybe<Gender>;
  /** User last name the address applies to */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** User middle name the address applies to */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Address mobile number [min: 6, max: 30] */
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** Address delivery notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** User street number */
  number?: InputMaybe<Scalars['String']['input']>;
  /** User street number extension */
  numberExtension?: InputMaybe<Scalars['String']['input']>;
  /** Address fixed phone number [min: 6, max: 30] */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Address postal code */
  postalCode: Scalars['String']['input'];
  /** Address region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** User street address */
  street: Scalars['String']['input'];
};

export type CreateWarehouseInput = {
  /**
   * Warehouse business hours per week day
   *
   *     Example:
   *         [{
   *           dayOfWeek: 0
   *           openingTime: "08:00"
   *           closingTime: "20:00"
   *           lunchBeakStartTime: "12:00"
   *           lunchBeakEndTime: "12:30"
   *         },
   *         {
   *           dayOfWeek: 1
   *           openingTime: "08:00"
   *           closingTime: "20:00"
   *           lunchBeakStartTime: "12:00"
   *           lunchBeakEndTime: "12:30"
   *         },
   *         {
   *           dayOfWeek: 2
   *           openingTime: "08:00"
   *           closingTime: "20:00"
   *           lunchBeakStartTime: "12:00"
   *           lunchBeakEndTime: "12:30"
   *         },
   *         {
   *           dayOfWeek: 3
   *           openingTime: "08:00"
   *           closingTime: "20:00"
   *           lunchBeakStartTime: "12:00"
   *           lunchBeakEndTime: "12:30"
   *         },
   *         {
   *           dayOfWeek: 4
   *           openingTime: "08:00"
   *           closingTime: "20:00"
   *           lunchBeakStartTime: "12:00"
   *           lunchBeakEndTime: "12:30"
   *         },
   *         {
   *           dayOfWeek: 5
   *           openingTime: "08:00"
   *           closingTime: "20:00"
   *           lunchBeakStartTime: "12:00"
   *           lunchBeakEndTime: "12:30"
   *         },
   *         {
   *           dayOfWeek: 6
   *           openingTime: "08:00"
   *           closingTime: "20:00"
   *           lunchBeakStartTime: "12:00"
   *           lunchBeakEndTime: "12:30"
   *         }]
   */
  businessHours: Array<BusinessHoursInput>;
  /** Add a address related to the specified warehouse */
  createWarehouseAddress?: InputMaybe<CreateWarehouseAddressInput>;
  /** Warehouse description */
  description: Scalars['String']['input'];
  /** Warehouse isActive status */
  isActive: Scalars['Boolean']['input'];
  /** Warehouse isPickupLocation status */
  isPickupLocation: Scalars['Boolean']['input'];
  /** Warehouse isStore status */
  isStore: Scalars['Boolean']['input'];
  /** Warehouse name */
  name: Scalars['String']['input'];
  /** Warehouse notes */
  notes: Scalars['String']['input'];
  /** Optional warehouse shopId! Default value will be internally inferred from the appropriate channel */
  shopId?: InputMaybe<Scalars['Int']['input']>;
};

export type CropInput = {
  height?: InputMaybe<Scalars['Int']['input']>;
  heightRatio?: InputMaybe<Scalars['Int']['input']>;
  offsetX?: InputMaybe<Scalars['Int']['input']>;
  offsetY?: InputMaybe<Scalars['Int']['input']>;
  /** safe */
  safe?: InputMaybe<Scalars['String']['input']>;
  /** smart */
  smart?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
  widthRatio?: InputMaybe<Scalars['Int']['input']>;
  x?: InputMaybe<Scalars['Int']['input']>;
  y?: InputMaybe<Scalars['Int']['input']>;
};

export type Crossupsell = {
  __typename?: 'Crossupsell';
  clusterFrom: IBaseProduct;
  clusterTo: IBaseProduct;
  /** Creation date */
  createdAt: Scalars['DateTime']['output'];
  /** Primary identifier */
  id: Scalars['String']['output'];
  /** Last modified date */
  lastModifiedAt: Scalars['DateTime']['output'];
  productFrom: IBaseProduct;
  productTo: IBaseProduct;
  /** Cross/Up Sell subtype */
  subType?: Maybe<Scalars['String']['output']>;
  /** Cross/Upsell type */
  type: CrossupsellType;
};

export type CrossupsellCreateInput = {
  /** clusterId from */
  clusterIdFrom?: InputMaybe<Scalars['Int']['input']>;
  /** clusterId to */
  clusterIdTo?: InputMaybe<Scalars['Int']['input']>;
  /** productId from */
  productIdFrom?: InputMaybe<Scalars['Int']['input']>;
  /** productId to */
  productIdTo?: InputMaybe<Scalars['Int']['input']>;
  /** Crossupsell subtype */
  subType?: InputMaybe<Scalars['String']['input']>;
  /** Crossupsell type! One of: [accessories, alternatives, options, parts, related] */
  type: CrossupsellType;
};

export type CrossupsellSearchInput = {
  /** Search by cluster ids from */
  clusterIdsFrom?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Search by cluster ids to */
  clusterIdsTo?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** Search by ids  */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Pagination offset number. [Default to `12`] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination page number. [Default to `1`] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Search by product ids from */
  productIdsFrom?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Search by product ids to */
  productIdsTo?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Inputs to sort by */
  sortInputs?: InputMaybe<Array<CrossupsellSortInput>>;
  /** Search by subtype */
  subTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Crossupsell type. One of: [accessories, alternatives, options, parts, related] */
  types?: InputMaybe<Array<CrossupsellType>>;
};

export enum CrossupsellSortField {
  ClusterIdFrom = 'CLUSTER_ID_FROM',
  ClusterIdTo = 'CLUSTER_ID_TO',
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  LastModifiedAt = 'LAST_MODIFIED_AT',
  ProductIdFrom = 'PRODUCT_ID_FROM',
  ProductIdTo = 'PRODUCT_ID_TO',
  Subtype = 'SUBTYPE',
  Type = 'TYPE',
}

export type CrossupsellSortInput = {
  /** Field to sort by */
  field: CrossupsellSortField;
  /** Order option to sort by. [Default to `ASC`] */
  order?: InputMaybe<SortOrder>;
};

export enum CrossupsellType {
  Accessories = 'ACCESSORIES',
  Alternatives = 'ALTERNATIVES',
  Options = 'OPTIONS',
  Parts = 'PARTS',
  Related = 'RELATED',
}

export type CrossupsellUpdateInput = {
  /** Crossupsell subtype */
  subType?: InputMaybe<Scalars['String']['input']>;
};

export type CrossupsellsResponse = {
  __typename?: 'CrossupsellsResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type Crossupsell */
  items: Array<Crossupsell>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type Customer = IBaseUser & {
  __typename?: 'Customer';
  addresses: Array<Address>;
  /** Lists attributes for this customer based on the search input. */
  attributes?: Maybe<AttributeResultResponse>;
  /** The Bank Account Number of the user */
  bankAccount?: Maybe<Scalars['String']['output']>;
  /** The Bank Identification Code of the user */
  bic?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** The id of the customer. */
  customerId: Scalars['Int']['output'];
  /** The date of birth of the contact. */
  dateOfBirth?: Maybe<Scalars['DateTime']['output']>;
  debtorId?: Maybe<Scalars['String']['output']>;
  /** The email of the user. */
  email: Scalars['String']['output'];
  /** The expiration date of the contact. After this date expires contact will be disabled. */
  expires?: Maybe<Scalars['DateTime']['output']>;
  /** External ID */
  externalId?: Maybe<Scalars['String']['output']>;
  favoriteLists?: Maybe<FavoriteListsResponse>;
  /** The first name of the user. */
  firstName: Scalars['String']['output'];
  /** The gender of the user. */
  gender?: Maybe<Gender>;
  /** The International Bank Account Number of the user */
  iban?: Maybe<Scalars['String']['output']>;
  isLoggedIn?: Maybe<Scalars['Boolean']['output']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The last name of the user. */
  lastName: Scalars['String']['output'];
  /** The login name (email) the account is linked to, generally the same as email address. When null a account has not been linked yet. */
  login?: Maybe<Scalars['String']['output']>;
  mailingList?: Maybe<YesNo>;
  /** The middle name of the user. */
  middleName?: Maybe<Scalars['String']['output']>;
  /** The mobile number of the user. */
  mobile?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  orderlist?: Maybe<OrderlistsResponse>;
  orderlists: OrderlistsResponse;
  /** The usergroup id of parent usergroup. */
  parentUsergroupId: Scalars['Int']['output'];
  /** The phone number of the user. */
  phone?: Maybe<Scalars['String']['output']>;
  pricesheets?: Maybe<Array<Pricesheet>>;
  /** The primary language of the user */
  primaryLanguage?: Maybe<Scalars['String']['output']>;
  sources: Array<Source>;
  /** The unique identifier of the user. */
  userId?: Maybe<Scalars['Int']['output']>;
  usergroup?: Maybe<Usergroup>;
  usergroupPath: Array<Usergroup>;
};

export type CustomerAddressesArgs = {
  isDefault?: InputMaybe<YesNo>;
  type?: InputMaybe<AddressType>;
};

export type CustomerAttributesArgs = {
  input?: InputMaybe<AttributeResultSearchInput>;
};

export type CustomerFavoriteListsArgs = {
  input?: InputMaybe<FavoriteListsBaseSearchInput>;
};

export type CustomerOrderlistsArgs = {
  input?: InputMaybe<OrderlistSearchInput>;
};

export type CustomerAddressCreateInput = {
  /** Address city */
  city: Scalars['String']['input'];
  /** Address code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** User company the address applies to */
  company?: InputMaybe<Scalars['String']['input']>;
  /** Address country */
  country: Scalars['String']['input'];
  /** Address customer primary identifier */
  customerId: Scalars['Int']['input'];
  /** Address email [valid email format required] */
  email?: InputMaybe<Scalars['String']['input']>;
  /** User first name the address applies to */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** User gender the address applies to */
  gender?: InputMaybe<Gender>;
  /** ICP address field defines whether tax should be applied to an order if the address is selected as a delivery address. Used when shipping b2b order abroad */
  icp?: InputMaybe<YesNo>;
  /** Indicates whether the address is default for its type */
  isDefault?: InputMaybe<YesNo>;
  /** User last name the address applies to */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** User middle name the address applies to */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Address mobile number [min: 6, max: 30] */
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** Address delivery notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** User street number */
  number?: InputMaybe<Scalars['String']['input']>;
  /** User street number extension */
  numberExtension?: InputMaybe<Scalars['String']['input']>;
  /** Address fixed phone number [min: 6, max: 30] */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Address postal code */
  postalCode: Scalars['String']['input'];
  /** Address region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** User street address */
  street: Scalars['String']['input'];
  /** Address type [one of 'home', 'delivery' or 'invoice'] */
  type: AddressType;
};

export type CustomerAddressDeleteInput = {
  /** Customer primary identifier */
  customerId: Scalars['Int']['input'];
  /** Address primary identifier */
  id: Scalars['Int']['input'];
};

export type CustomerAddressUpdateInput = {
  /** Address city */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Address code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** User company the address applies to */
  company?: InputMaybe<Scalars['String']['input']>;
  /** Address country */
  country?: InputMaybe<Scalars['String']['input']>;
  /** Address customer primary identifier */
  customerId: Scalars['Int']['input'];
  /** Address email [valid email format required] */
  email?: InputMaybe<Scalars['String']['input']>;
  /** User first name the address applies to */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** User gender the address applies to */
  gender?: InputMaybe<Gender>;
  /** ICP address field defines whether tax should be applied to an order if the address is selected as a delivery address. Used when shipping b2b order abroad */
  icp?: InputMaybe<YesNo>;
  /** Address primary identifier */
  id: Scalars['Int']['input'];
  /** Indicates whether the address is default for its type */
  isDefault?: InputMaybe<YesNo>;
  /** User last name the address applies to */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** User middle name the address applies to */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Address mobile number [min: 6, max: 30] */
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** Address delivery notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** User street number */
  number?: InputMaybe<Scalars['String']['input']>;
  /** User street number extension */
  numberExtension?: InputMaybe<Scalars['String']['input']>;
  /** Address fixed phone number [min: 6, max: 30] */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Address postal code */
  postalCode?: InputMaybe<Scalars['String']['input']>;
  /** Address region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** User street address */
  street?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerAttribute = Attribute & {
  __typename?: 'CustomerAttribute';
  attributeDescription?: Maybe<AttributeDescription>;
  /** The date when the attribute was created */
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the user that created the attribute */
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** The ID of the customer this attribute belongs to */
  customerId: Scalars['Int']['output'];
  /** The ID of the attribute */
  id: Scalars['String']['output'];
  /** The date when the attribute was last modified */
  lastModifiedAt: Scalars['DateTime']['output'];
  /** The ID of the user that last modified the attribute */
  lastModifiedBy?: Maybe<Scalars['Int']['output']>;
  /** The value of the attribute */
  value: AttributeValue;
};

export type CustomerInput = {
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailingList?: InputMaybe<YesNo>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** The id of the usergroup the customer should be created in. */
  parentId?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  /** The primary language of the customer. */
  primaryLanguage?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerSearchArguments = {
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** List of unique customer ids */
  customerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Customer's email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Customer's first name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Customer's gender */
  gender?: InputMaybe<Gender>;
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Customer's last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Customer's middle name */
  middleName?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  /** List of customer sort filters */
  sort?: InputMaybe<Array<CustomerSortInput>>;
};

export type CustomerSortInput = {
  /** Available customer sortable fields */
  field: CustomerSortableField;
  /** Sort customer ordering ['ASC' or 'DESC'] */
  order?: SortOrder;
};

/** Customer sortable fields */
export enum CustomerSortableField {
  CreatedAt = 'CREATED_AT',
  LastModifiedAt = 'LAST_MODIFIED_AT',
  Name = 'NAME',
}

export type CustomersResponse = {
  __typename?: 'CustomersResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type Customer */
  items: Array<Customer>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /**  The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type DateSearchInput = {
  /** Date greater than provided date */
  greaterThan?: InputMaybe<Scalars['String']['input']>;
  /** Date less than provided date */
  lessThan?: InputMaybe<Scalars['String']['input']>;
};

export type DecimalSearchInput = {
  /** greater than or equal */
  greaterThan?: InputMaybe<Scalars['Float']['input']>;
  /** less than or equal */
  lessThan?: InputMaybe<Scalars['Float']['input']>;
};

export type DeleteMediaAttachmentResponse = {
  __typename?: 'DeleteMediaAttachmentResponse';
  /** Media id of the deleted attachment */
  mediaId: Scalars['String']['output'];
};

export type DeleteMediaDocumentResponse = {
  __typename?: 'DeleteMediaDocumentResponse';
  /** Media id of the deleted document */
  mediaId: Scalars['String']['output'];
};

export type DeleteMediaImageResponse = {
  __typename?: 'DeleteMediaImageResponse';
  /** Media id of the deleted image */
  mediaId: Scalars['String']['output'];
};

export type DeleteMediaVideoResponse = {
  __typename?: 'DeleteMediaVideoResponse';
  /** Media id of the deleted video */
  mediaId: Scalars['String']['output'];
};

export type DeleteWarehouseAddressInput = {
  /** Address primary identifier */
  id: Scalars['Int']['input'];
};

export type Discount = IDiscount & {
  __typename?: 'Discount';
  category?: Maybe<Category>;
  /** Category Id */
  categoryId?: Maybe<Scalars['Int']['output']>;
  /** Creation date */
  createdAt: Scalars['DateTime']['output'];
  /** The method used to apply discounts for bulk prices / volume discounts. */
  discountType: PriceDiscountType;
  /** Primary identifier */
  id: Scalars['String']['output'];
  /** Last modified date */
  lastModifiedAt: Scalars['DateTime']['output'];
  /** Price group */
  priceGroup?: Maybe<Scalars['String']['output']>;
  pricesheet?: Maybe<Pricesheet>;
  /** pricesheet foreign identifier */
  pricesheetId: Scalars['String']['output'];
  product?: Maybe<Product>;
  /** Product Id */
  productId?: Maybe<Scalars['Int']['output']>;
  /** Indicates the minimum quantity threshold needed to qualify for the bulk price. */
  quantityFrom: Scalars['Int']['output'];
  /** Start date from which the bulk pricing is effective. Must be a valid ISO 8601 date string */
  validFrom?: Maybe<Scalars['DateTime']['output']>;
  /** End date of the bulk pricing offer. Must be a valid ISO 8601 date string */
  validTo?: Maybe<Scalars['DateTime']['output']>;
  /** Discount value, either percentage or fixed amount */
  value: Scalars['Float']['output'];
};

export type DiscountCreateInput = {
  /** Category id */
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  /** Discount type One of: [costpriceplus, listpricemin, netprice] */
  discountType: PriceDiscountType;
  /** Pricegroup */
  priceGroup?: InputMaybe<Scalars['String']['input']>;
  /** Pricesheet id */
  pricesheetId: Scalars['String']['input'];
  /** Product id */
  productId?: InputMaybe<Scalars['Int']['input']>;
  /** Indicates the minimum quantity threshold needed to qualify for the bulk price. */
  quantityFrom?: Scalars['Int']['input'];
  /** Start date from which the bulk pricing is effective. Must be a valid ISO 8601 date string */
  validFrom?: InputMaybe<Scalars['String']['input']>;
  /** End date of the bulk pricing offer. Must be a valid ISO 8601 date string */
  validTo?: InputMaybe<Scalars['String']['input']>;
  /** Discount value */
  value: Scalars['Float']['input'];
};

export type DiscountResponse = {
  __typename?: 'DiscountResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type Discount */
  items: Array<Discount>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type DiscountSearchInput = {
  /** Search by category ids */
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** Discount type. One of: [costpriceplus, listpricemin, netprice] */
  discountTypes?: InputMaybe<Array<PriceDiscountType>>;
  /** Search by ids */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Pagination offset number */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination page number */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Search by price groups */
  priceGroups?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search by pricesheet ids */
  pricesheetIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search by product ids */
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Search by quantity from */
  quantityFrom?: InputMaybe<NumberSearchInput>;
  /** Inputs to sort by */
  sortInputs?: InputMaybe<Array<DiscountSortInput>>;
  /** Search by valid from */
  validFrom?: InputMaybe<DateSearchInput>;
  /** Search by valid to */
  validTo?: InputMaybe<DateSearchInput>;
  /** Search by values */
  value?: InputMaybe<DecimalSearchInput>;
};

export enum DiscountSortField {
  Categoryid = 'CATEGORYID',
  CreatedAt = 'CREATED_AT',
  Discounttype = 'DISCOUNTTYPE',
  Id = 'ID',
  LastModifiedAt = 'LAST_MODIFIED_AT',
  Pricegroup = 'PRICEGROUP',
  Pricesheetid = 'PRICESHEETID',
  Productid = 'PRODUCTID',
  Quantityfrom = 'QUANTITYFROM',
  Validfrom = 'VALIDFROM',
  Validto = 'VALIDTO',
  Value = 'VALUE',
}

export type DiscountSortInput = {
  /** Field to sort by */
  field: DiscountSortField;
  order: SortOrder;
};

/** Discount types */
export enum DiscountType {
  FlatFee = 'FLAT_FEE',
  Percentage = 'PERCENTAGE',
}

export type DiscountUpdateInput = {
  /** Category id */
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  /** Discount type One of: [costpriceplus, listpricemin, netprice] */
  discountType?: InputMaybe<PriceDiscountType>;
  /** Pricegroup */
  priceGroup?: InputMaybe<Scalars['String']['input']>;
  /** Pricesheet id */
  pricesheetId?: InputMaybe<Scalars['String']['input']>;
  /** Product id */
  productId?: InputMaybe<Scalars['Int']['input']>;
  /** Indicates the minimum quantity threshold needed to qualify for the bulk price. */
  quantityFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Start date from which the bulk pricing is effective. Must be a valid ISO 8601 date string */
  validFrom?: InputMaybe<Scalars['String']['input']>;
  /** End date of the bulk pricing offer. Must be a valid ISO 8601 date string */
  validTo?: InputMaybe<Scalars['String']['input']>;
  /** Discount value */
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type EmailEventInput = {
  /** Attachments to add to the email */
  attachments?: InputMaybe<Array<Base64FileInput>>;
  /** The content of the email, use when the content can not be derived from other inputs */
  content?: InputMaybe<Scalars['String']['input']>;
  /** The sender of the email, use when the sender can not be derived from other inputs */
  from?: InputMaybe<EmailSendContactInput>;
  /** The language of the generated email */
  language?: InputMaybe<Scalars['String']['input']>;
  /** The id of the Propeller letter to use when type is 'campaign' */
  letterId?: InputMaybe<Scalars['String']['input']>;
  /** The id of the order to use when triggering an event of type orderconfirm */
  orderId?: InputMaybe<Scalars['Int']['input']>;
  /** The id of the site to use when sending the email, required when the site can not be derived from other inputs */
  siteId?: InputMaybe<Scalars['Int']['input']>;
  /** The subject of the email, use when the subject can not be derived from other inputs */
  subject?: InputMaybe<Scalars['String']['input']>;
  /** The recipient(s) of the email, use when the recipient(s) can not be derived from other inputs */
  to?: InputMaybe<Array<EmailSendContactInput>>;
  /** The type of email event to trigger */
  type: EmailEventType;
  /** The id of the user to send to email to. Can be used to replace to, when the user can't be derived from other inputs */
  userId?: InputMaybe<Scalars['Int']['input']>;
  /** Variables that can be used in the email template */
  variables?: InputMaybe<Scalars['JSONObject']['input']>;
};

/** Type of e-mail events that can be triggered */
export enum EmailEventType {
  Campaign = 'campaign',
  Custom = 'custom',
  Error = 'error',
  Orderconfirm = 'orderconfirm',
  Quotation = 'quotation',
  Registration = 'registration',
  System = 'system',
  Transactional = 'transactional',
}

export type EmailSendContactInput = {
  /** The email address of the contact */
  email: Scalars['String']['input'];
  /** The name of the contact */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type EmailSendEventInput = {
  /** The attachment(s) of the email */
  attachments?: InputMaybe<Array<Base64FileInput>>;
  /** The bcc recipient(s) of the email */
  bcc?: InputMaybe<Array<EmailSendContactInput>>;
  /** The campaingId of the email */
  campaignId?: InputMaybe<Scalars['String']['input']>;
  /** The cc recipient(s) of the email */
  cc?: InputMaybe<Array<EmailSendContactInput>>;
  /** The content of the email */
  content: Scalars['String']['input'];
  /** The sender of the email */
  from: EmailSendContactInput;
  /** The messageId of the email */
  messageId?: InputMaybe<Scalars['String']['input']>;
  /** The subject of the email */
  subject: Scalars['String']['input'];
  /** The recipient(s) of the email */
  to: Array<EmailSendContactInput>;
  /** The utmTags of the email */
  utmTags?: InputMaybe<Scalars['String']['input']>;
  /** Variables that can be used in the email template */
  variables?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type EnrolledFactor = {
  __typename?: 'EnrolledFactor';
  factorId: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
};

export type ExchangeRefreshTokenInput = {
  refreshToken: Scalars['String']['input'];
};

export type ExternalAddress = {
  __typename?: 'ExternalAddress';
  /** Address city */
  city: Scalars['String']['output'];
  /** Address code */
  code?: Maybe<Scalars['String']['output']>;
  /** Company the address applies to */
  company?: Maybe<Scalars['String']['output']>;
  /** Address country */
  country: Scalars['String']['output'];
  /** Email that applies to this address [valid email format needed] */
  email?: Maybe<Scalars['String']['output']>;
  /** Fax number that applies to this address [min: 6, max: 30] */
  fax?: Maybe<Scalars['String']['output']>;
  /** First name the address applies to */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Address gender */
  gender?: Maybe<Gender>;
  /** ICP address field defines whether tax should be applied to an order if the address is selected as a delivery address. Used when shipping b2b order abroad */
  icp?: Maybe<YesNo>;
  /** Address primary identifier */
  id: Scalars['Int']['output'];
  /** Last name the address applies to */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Middle name the address applies to */
  middleName?: Maybe<Scalars['String']['output']>;
  /** Mobile phone number that applies to this address [min: 6, max: 30] */
  mobile?: Maybe<Scalars['String']['output']>;
  /** Address friendly descriptive name */
  name?: Maybe<Scalars['String']['output']>;
  /** Address delivery notes */
  notes?: Maybe<Scalars['String']['output']>;
  /** Address street number */
  number?: Maybe<Scalars['String']['output']>;
  /** Address street number extension */
  numberExtension?: Maybe<Scalars['String']['output']>;
  /** Fixed phone number that applies to this address [min: 6, max: 30] */
  phone?: Maybe<Scalars['String']['output']>;
  /** Address postal code */
  postalCode: Scalars['String']['output'];
  /** Address region */
  region?: Maybe<Scalars['String']['output']>;
  /** Address street */
  street: Scalars['String']['output'];
  /** Address company domain url */
  url?: Maybe<Scalars['String']['output']>;
};

export type ExternalAddressCreateInput = {
  /** Address city */
  city: Scalars['String']['input'];
  /** Address code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** User company the address applies to */
  company?: InputMaybe<Scalars['String']['input']>;
  /** Address country */
  country: Scalars['String']['input'];
  /** Address email [valid email format required] */
  email?: InputMaybe<Scalars['String']['input']>;
  /** User first name the address applies to */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** User gender the address applies to */
  gender?: InputMaybe<Gender>;
  /** ICP address field defines whether tax should be applied to an order if the address is selected as a delivery address. Used when shipping b2b order abroad */
  icp?: InputMaybe<YesNo>;
  /** User last name the address applies to */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** User middle name the address applies to */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Address mobile number [min: 6, max: 30] */
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** Address delivery notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** User street number */
  number?: InputMaybe<Scalars['String']['input']>;
  /** User street number extension */
  numberExtension?: InputMaybe<Scalars['String']['input']>;
  /** Address fixed phone number [min: 6, max: 30] */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Address postal code */
  postalCode: Scalars['String']['input'];
  /** Address region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** User street address */
  street: Scalars['String']['input'];
};

export type ExternalAddressDeleteInput = {
  /** Address primary identifier */
  id: Scalars['Int']['input'];
};

export type ExternalAddressUpdateInput = {
  /** Address city */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Address code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** User company the address applies to */
  company?: InputMaybe<Scalars['String']['input']>;
  /** Address country */
  country?: InputMaybe<Scalars['String']['input']>;
  /** Address email [valid email format required] */
  email?: InputMaybe<Scalars['String']['input']>;
  /** User first name the address applies to */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** User gender the address applies to */
  gender?: InputMaybe<Gender>;
  /** ICP address field defines whether tax should be applied to an order if the address is selected as a delivery address. Used when shipping b2b order abroad */
  icp?: InputMaybe<YesNo>;
  /** Address primary identifier */
  id: Scalars['Int']['input'];
  /** User last name the address applies to */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** User middle name the address applies to */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Address mobile number [min: 6, max: 30] */
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** Address delivery notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** User street number */
  number?: InputMaybe<Scalars['String']['input']>;
  /** User street number extension */
  numberExtension?: InputMaybe<Scalars['String']['input']>;
  /** Address fixed phone number [min: 6, max: 30] */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Address postal code */
  postalCode?: InputMaybe<Scalars['String']['input']>;
  /** Address region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** User street address */
  street?: InputMaybe<Scalars['String']['input']>;
};

export type ExternalReferenceInput = {
  source: Scalars['String']['input'];
  sourceId: Scalars['String']['input'];
};

export type FavoriteList = {
  __typename?: 'FavoriteList';
  clusters?: Maybe<ProductsResponse>;
  /** Favorite List company ID */
  companyId?: Maybe<Scalars['Int']['output']>;
  /** Favorite List contact ID */
  contactId?: Maybe<Scalars['Int']['output']>;
  /** Favorite List date created */
  createdAt: Scalars['DateTime']['output'];
  /** Favorite List customer ID */
  customerId?: Maybe<Scalars['Int']['output']>;
  /** Favorite List unique identifier */
  id: Scalars['ID']['output'];
  /** Default Favorite List */
  isDefault: Scalars['Boolean']['output'];
  /** Favorite List name */
  name: Scalars['String']['output'];
  products?: Maybe<ProductsResponse>;
  /** Favorite List slug */
  slug: Scalars['String']['output'];
  /** Favorite List date modified */
  updatedAt: Scalars['DateTime']['output'];
};

export type FavoriteListClustersArgs = {
  input?: InputMaybe<ProductSearchInput>;
};

export type FavoriteListProductsArgs = {
  input?: InputMaybe<ProductSearchInput>;
};

export type FavoriteListsBaseSearchInput = {
  /** Favorite List is default */
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  /** Favorite List name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Favorite List offset */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Favorite List page */
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type FavoriteListsCreateInput = {
  /** Favorite List cluster IDs list */
  clusterIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of cluster sources */
  clusterSources?: InputMaybe<Array<SourceInput>>;
  /** Favorite List company ID */
  companyId?: InputMaybe<Scalars['Int']['input']>;
  /** A company source */
  companySource?: InputMaybe<SourceInput>;
  /** Favorite List contact ID */
  contactId?: InputMaybe<Scalars['Int']['input']>;
  /** A contact source */
  contactSource?: InputMaybe<SourceInput>;
  /** Favorite List customer ID */
  customerId?: InputMaybe<Scalars['Int']['input']>;
  /** A customer source */
  customerSource?: InputMaybe<SourceInput>;
  /** Favorite List is default */
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  /** Favorite List name */
  name: Scalars['String']['input'];
  /** Favorite List product IDs list */
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of product sources */
  productSources?: InputMaybe<Array<SourceInput>>;
};

export type FavoriteListsItemsInput = {
  /** Favorite List cluster IDs list */
  clusterIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of cluster sources */
  clusterSources?: InputMaybe<Array<SourceInput>>;
  /** Favorite List product IDs list */
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of product sources */
  productSources?: InputMaybe<Array<SourceInput>>;
};

export type FavoriteListsResponse = {
  __typename?: 'FavoriteListsResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type FavoriteList */
  items: Array<FavoriteList>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type FavoriteListsSearchInput = {
  /** List of cluster ids */
  clusterIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of cluster sources */
  clusterSources?: InputMaybe<Array<SourceInput>>;
  /** Favorite List company ID */
  companyId?: InputMaybe<Scalars['Int']['input']>;
  /** A company source */
  companySource?: InputMaybe<SourceInput>;
  /** Favorite List contact ID */
  contactId?: InputMaybe<Scalars['Int']['input']>;
  /** A contact source */
  contactSource?: InputMaybe<SourceInput>;
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** Favorite List customer ID */
  customerId?: InputMaybe<Scalars['Int']['input']>;
  /** A customer source */
  customerSource?: InputMaybe<SourceInput>;
  /** Favorite List is default */
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Favorite List name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Favorite List offset */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Favorite List page */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** List of product ids */
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of product sources */
  productSources?: InputMaybe<Array<SourceInput>>;
};

export type FavoriteListsUpdateInput = {
  /** Favorite List cluster IDs list */
  clusterIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of cluster sources */
  clusterSources?: InputMaybe<Array<SourceInput>>;
  /** Favorite List is default */
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  /** Favorite List name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Favorite List product IDs list */
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of product sources */
  productSources?: InputMaybe<Array<SourceInput>>;
};

/** Enable/Disable available image optimization */
export enum FeaturesEnableDisable {
  /**
   * [Disable] Prevent images being resized such that the output image's dimensions are larger than the source image.
   *
   *         [Enable] Allow images to be resized such that the output image's dimensions are larger than the source image.
   */
  Upscale = 'UPSCALE',
}

export type FilterAvailableAttributeInput = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isSearchable?: InputMaybe<Scalars['Boolean']['input']>;
  types?: InputMaybe<Array<AttributeType>>;
};

export type Firebase = {
  __typename?: 'Firebase';
  identities?: Maybe<Scalars['JSONObject']['output']>;
  second_factor_identifier?: Maybe<Scalars['String']['output']>;
  sign_in_provider: Scalars['String']['output'];
  sign_in_second_factor?: Maybe<Scalars['String']['output']>;
  tenant?: Maybe<Scalars['String']['output']>;
};

/**
 * The fit parameter controls how the image will be constrained within the provided size (width and height) values, in order to maintain the correct proportions.
 *
 *   NOTE: The fit parameter has no effect unless both width and height are specified.
 */
export enum Fit {
  /** Resize the image to fit entirely within the specified region, making one dimension smaller if needed. */
  Bounds = 'BOUNDS',
  /** Resize the image to entirely cover the specified region, making one dimension larger if needed. */
  Cover = 'COVER',
  /** Resize and crop the image centrally to exactly fit the specified region. */
  Crop = 'CROP',
}

/**
 * The Format parameter controls how the image will be constrained within the provided size (width and height) values, in order to maintain the correct proportions.
 *
 *   NOTE: The Format parameter has no effect unless both width and height are specified.
 */
export enum Format {
  /** Baseline JPEG (also bjpeg) */
  Bjpg = 'BJPG',
  /** Graphics Interchange Format */
  Gif = 'GIF',
  /** JPEG (also jpeg) */
  Jpg = 'JPG',
  /** MP4 (H.264) */
  Mp4 = 'MP4',
  /** Progressive JPEG (also pjpeg) */
  Pjpg = 'PJPG',
  /** Portable Network Graphics */
  Png = 'PNG',
  /** Portable Network Graphics palette image with 256 colors and 8-bit transparency */
  Png8 = 'PNG8',
  /** WebP */
  Webp = 'WEBP',
  /** WebP (Lossless) */
  Webpll = 'WEBPLL',
  /** WebP (Lossy) */
  Webply = 'WEBPLY',
}

export type GcipMetadata = {
  __typename?: 'GCIPMetadata';
  creationTime?: Maybe<Scalars['String']['output']>;
  lastRefreshTime?: Maybe<Scalars['String']['output']>;
  lastSignInTime?: Maybe<Scalars['String']['output']>;
};

export type GcipProviderData = {
  __typename?: 'GCIPProviderData';
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  federatedId?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  photoUrl?: Maybe<Scalars['String']['output']>;
  providerId?: Maybe<Scalars['String']['output']>;
  rawId?: Maybe<Scalars['String']['output']>;
  screenName?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
};

export type GcipUser = {
  __typename?: 'GCIPUser';
  accessToken: Scalars['String']['output'];
  authDomain?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  disabled?: Maybe<Scalars['Boolean']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailVerified?: Maybe<Scalars['Boolean']['output']>;
  expirationTime?: Maybe<Scalars['DateTime']['output']>;
  isAnonymous?: Maybe<Scalars['Boolean']['output']>;
  lastLoginAt?: Maybe<Scalars['DateTime']['output']>;
  metadata?: Maybe<GcipMetadata>;
  multiFactor?: Maybe<MultiFactor>;
  passwordHash?: Maybe<Scalars['String']['output']>;
  passwordSalt?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  photoUrl?: Maybe<Scalars['String']['output']>;
  providerData: Array<GcipProviderData>;
  refreshToken: Scalars['String']['output'];
  tenantId?: Maybe<Scalars['String']['output']>;
  tokensValidAfterTime?: Maybe<Scalars['String']['output']>;
  uid: Scalars['String']['output'];
};

/** A persons gender */
export enum Gender {
  /** Female */
  F = 'F',
  /** Male */
  M = 'M',
  /** Unknown */
  U = 'U',
}

export type IAttributeDecimalRangeFilter = {
  /** The number of products found with this attribute value.  */
  max: Scalars['Float']['output'];
  /** Minumum value for this attribute */
  min: Scalars['Float']['output'];
};

export type IAttributeFilter = {
  /** Minimum and maximum value range for decimal attributes */
  decimalRangeFilter?: Maybe<AttributeDecimalRangeFilter>;
  /** The ID of the attributeDescription this attribute filter is for */
  id: Scalars['String']['output'];
  /** Minimum and maximum value range for integer attributes */
  integerRangeFilter?: Maybe<AttributeIntegerRangeFilter>;
  /** All available attribute text values within this search result for this particular attribute. */
  textFilters?: Maybe<Array<AttributeTextFilter>>;
  /**
   * The type of the attribute.
   *         Possible values:
   *         - text
   *         - list
   *         - enum
   *         - enumlist
   *         - color
   *         - date
   *         - datetime
   *         - object
   *         - integer
   *         - decimal
   */
  type: AttributeType;
};

export type IAttributeIntegerRangeFilter = {
  /** The number of products found with this attribute value.  */
  max: Scalars['Int']['output'];
  /** Minumum value for this attribute */
  min: Scalars['Int']['output'];
};

export type IAttributeTextFilter = {
  /** The number of products found with this attribute value with all attributes applied.     */
  count: Scalars['Int']['output'];
  /**
   * The number of products found with this attribute value without all attributes except it's own applied.
   *     This can be used to display the active filter's totals
   */
  countActive?: Maybe<Scalars['Int']['output']>;
  /** The number of products found with this attribute value  without any attribute filters applied.  */
  countTotal?: Maybe<Scalars['Int']['output']>;
  /** Indicates whehter this values is currently selected */
  isSelected: Scalars['Boolean']['output'];
  /** The value of the attribute */
  value: Scalars['String']['output'];
};

/**
 * Interface for the BaseProduct Type, where both Products and
 *     clusters derive from
 */
export type IBaseProduct = {
  categoryId: Scalars['Int']['output'];
  /** The class of the request resource */
  class: ProductClass;
  /** The default language for this product or cluster, this is the language to fall back too, when there is no translation available in a specific language. */
  defaultLanguage: Scalars['String']['output'];
  /** The descriptions of this product per language */
  descriptions: Array<LocalizedString>;
  /** The hidden status of this resource. */
  hidden: YesNo;
  /** Resource primary identifier */
  id: Scalars['Int']['output'];
  /**
   * The requested language for this this resource, default to the
   *           products default language.
   *           ISO 639-1 format.
   */
  language?: Maybe<Scalars['String']['output']>;
  /** The names of this product per language */
  names: Array<LocalizedString>;
  /** The short descriptions of this product per language */
  shortDescriptions: Array<LocalizedString>;
  /** The SKU (stock keeping unit) of this product */
  sku: Scalars['String']['output'];
  /** The slugs for this product per language */
  slugs: Array<LocalizedString>;
};

/**
 * Interface for the BaseProduct Type, where both Products and
 *     clusters derive from
 */
export type IBaseProductDescriptionsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Interface for the BaseProduct Type, where both Products and
 *     clusters derive from
 */
export type IBaseProductNamesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Interface for the BaseProduct Type, where both Products and
 *     clusters derive from
 */
export type IBaseProductShortDescriptionsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Interface for the BaseProduct Type, where both Products and
 *     clusters derive from
 */
export type IBaseProductSlugsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

/** Interface for the BaseUser Type, where both Contacts and Customers derive from */
export type IBaseUser = {
  /** The Bank Account Number of the user */
  bankAccount?: Maybe<Scalars['String']['output']>;
  /** The Bank Identification Code of the user */
  bic?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** The date of birth of the contact. */
  dateOfBirth?: Maybe<Scalars['DateTime']['output']>;
  debtorId?: Maybe<Scalars['String']['output']>;
  /** The email of the user. */
  email: Scalars['String']['output'];
  /** The expiration date of the contact. After this date expires contact will be disabled. */
  expires?: Maybe<Scalars['DateTime']['output']>;
  /** External ID */
  externalId?: Maybe<Scalars['String']['output']>;
  /** The first name of the user. */
  firstName: Scalars['String']['output'];
  /** The gender of the user. */
  gender?: Maybe<Gender>;
  /** The International Bank Account Number of the user */
  iban?: Maybe<Scalars['String']['output']>;
  isLoggedIn?: Maybe<Scalars['Boolean']['output']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The last name of the user. */
  lastName: Scalars['String']['output'];
  /** The login name (email) the account is linked to, generally the same as email address. When null a account has not been linked yet. */
  login?: Maybe<Scalars['String']['output']>;
  mailingList?: Maybe<YesNo>;
  /** The middle name of the user. */
  middleName?: Maybe<Scalars['String']['output']>;
  /** The mobile number of the user. */
  mobile?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  /** The phone number of the user. */
  phone?: Maybe<Scalars['String']['output']>;
  /** The primary language of the user */
  primaryLanguage?: Maybe<Scalars['String']['output']>;
  /** The unique identifier of the user. */
  userId?: Maybe<Scalars['Int']['output']>;
};

/** Interface for the BaseUser Type, where deferent types of BusinessRuleNodes derive from */
export type IBusinessRuleExpression = {
  id: Scalars['ID']['output'];
  type: BusinessRuleExpressionTypes;
};

/** Interface for the BusinessRuleNodes Type, where different types of BusinessRuleNodes derive from */
export type IBusinessRuleNode = {
  /** The description of the node */
  description: Scalars['String']['output'];
  /** The generated ID of the node */
  id: Scalars['ID']['output'];
  /** The name of the node */
  name: Scalars['String']['output'];
  /** The type is the node */
  type: BusinessRuleNodeType;
};

export type ICart = {
  /** @deprecated Deprecated in favor of `contact` or `customer` */
  user?: Maybe<IBaseUser>;
};

export type ICartBaseItem = {
  product?: Maybe<Product>;
};

/** The interface for the Cluster type */
export type ICluster = {
  categoryId: Scalars['Int']['output'];
  /** The class of the request resource */
  class: ProductClass;
  /** The classID of this cluster */
  clusterId: Scalars['Int']['output'];
  config?: Maybe<ClusterConfig>;
  /** The default language for this product or cluster, this is the language to fall back too, when there is no translation available in a specific language. */
  defaultLanguage: Scalars['String']['output'];
  /** The default product that should be selected for this option */
  defaultProduct?: Maybe<Product>;
  /** The descriptions of this product per language */
  descriptions: Array<LocalizedString>;
  /**
   * The drilldown configuration for this cluster
   * @deprecated Use config.setting instead
   */
  drillDowns?: Maybe<Array<ClusterDrillDown>>;
  /** The hidden status of this resource. */
  hidden: YesNo;
  /** Resource primary identifier */
  id: Scalars['Int']['output'];
  /**
   * The requested language for this this resource, default to the
   *           products default language.
   *           ISO 639-1 format.
   */
  language?: Maybe<Scalars['String']['output']>;
  /** The names of this product per language */
  names: Array<LocalizedString>;
  /** The configuration options for this cluster */
  options?: Maybe<Array<ClusterOption>>;
  /** Array of selectable main products for this cluster, when the array contains more than one. Only one can be added per cart/order item */
  products?: Maybe<Array<Product>>;
  /** The short descriptions of this product per language */
  shortDescriptions: Array<LocalizedString>;
  /** The SKU (stock keeping unit) of this product */
  sku: Scalars['String']['output'];
  /** The slugs for this product per language */
  slugs: Array<LocalizedString>;
};

/** The interface for the Cluster type */
export type IClusterDescriptionsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

/** The interface for the Cluster type */
export type IClusterNamesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

/** The interface for the Cluster type */
export type IClusterShortDescriptionsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

/** The interface for the Cluster type */
export type IClusterSlugsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

/** The interface for the ClusterDrillDown type, this describes the a cluster's drill down configuration. */
export type IClusterDrillDown = {
  /** The id of the Attribute to create the drilldown matrix for  */
  attributeId: Scalars['String']['output'];
  /** Indicator how to display the current drilldown selection */
  displayType: ClusterDrillDownDisplayType;
  /** The order in which the drilldown should be generated */
  priority: Scalars['Int']['output'];
};

/** Container for a set of options belonging to a configurable cluster */
export type IClusterOption = {
  /** The classID of the cluster that represents this cluster */
  clusterOptionId: Scalars['Int']['output'];
  /** The default product that should be selected for this option */
  defaultProduct?: Maybe<Product>;
  /**
   * The long description for this product,
   *             this field can contain html formatting,
   *             this field can be translated is case of multilingual catalog
   */
  descriptions: Array<LocalizedString>;
  /** Indicates whether an option is hidden or not */
  hidden: YesNo;
  id: Scalars['Int']['output'];
  /** Indicates whether an option is required or not */
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Localized product name as it is available in
   *         the supported language defaults to the product's default language.
   */
  names: Array<LocalizedString>;
  /** The products that can be added as this typed of option. */
  products?: Maybe<Array<Product>>;
  /**
   * The short description for this product,
   *             this field can contain html formatting,
   *             this field can be translated is case of multilingual catalog
   */
  shortDescriptions: Array<LocalizedString>;
};

export type IDiscount = {
  /** Creation date */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  /** Last modified date */
  lastModifiedAt: Scalars['DateTime']['output'];
  quantityFrom: Scalars['Int']['output'];
  validFrom?: Maybe<Scalars['DateTime']['output']>;
  validTo?: Maybe<Scalars['DateTime']['output']>;
  value: Scalars['Float']['output'];
};

/** Interface for the Product Type */
export type IProduct = {
  /** The bar code of this product */
  barCode?: Maybe<Scalars['String']['output']>;
  categoryId: Scalars['Int']['output'];
  /** The class of the request resource */
  class: ProductClass;
  /** The cluster ID of the product's container */
  clusterId?: Maybe<Scalars['Int']['output']>;
  /** The container class of this product. */
  containerClass: ProductContainerClass;
  /** The creation date of this product */
  createdAt: Scalars['DateTime']['output'];
  /** Additional keywords of this product per language. Added via the PIM */
  customKeywords?: Maybe<Array<LocalizedString>>;
  /** The default language for this product or cluster, this is the language to fall back too, when there is no translation available in a specific language. */
  defaultLanguage: Scalars['String']['output'];
  /** The descriptions of this product per language */
  descriptions: Array<LocalizedString>;
  /** The European Article Number (EAN) code of this product */
  eanCode: Scalars['String']['output'];
  /** The quantity that provides the best value for money for this product */
  economicOrderQuantity: Scalars['Int']['output'];
  /** Is this product part of any product bundles. */
  hasBundle: YesNo;
  /** The hidden status of this resource. */
  hidden: YesNo;
  /** Resource primary identifier */
  id: Scalars['Int']['output'];
  /** Is this product leader of any product bundle. */
  isBundleLeader: YesNo;
  /** Additional keywords of this product per language. Added by the supplier */
  keywords?: Maybe<Array<LocalizedString>>;
  /**
   * The requested language for this this resource, default to the
   *           products default language.
   *           ISO 639-1 format.
   */
  language?: Maybe<Scalars['String']['output']>;
  /** The last modified date of this product */
  lastModifiedAt: Scalars['DateTime']['output'];
  /** The manufacturer of this product */
  manufacturer: Scalars['String']['output'];
  /** The Original Equipment Manufacturer code (OEM) of this product */
  manufacturerCode: Scalars['String']['output'];
  /** The minimum quantity of products that can be ordered */
  minimumQuantity: Scalars['Float']['output'];
  /** The names of this product per language */
  names: Array<LocalizedString>;
  /** Available offers for this Product and Customer/Contact. First item in the array should be regarded as the active price */
  offers?: Maybe<Array<ProductOffer>>;
  /** Is this product orderable */
  orderable: YesNo;
  /** The orderable from date of this product */
  orderableFrom?: Maybe<Scalars['DateTime']['output']>;
  /** The orderable to date of this product */
  orderableTo?: Maybe<Scalars['DateTime']['output']>;
  /** The package type of this product. i.e.: PIECE, BOX, BLISTER */
  package: Scalars['String']['output'];
  /** The package description of this product per language */
  packageDescriptions?: Maybe<Array<LocalizedString>>;
  /** The amount of items per package */
  packageUnit: Scalars['String']['output'];
  /** The quantifier for the package unit, i.e.: PIECE or BOX */
  packageUnitQuantity: Scalars['String']['output'];
  /** Is it a physical product or a service, download or warranty */
  physical: YesNo;
  /** The price group the product belongs to, this field can be used for price calculations */
  priceGroup?: Maybe<Scalars['String']['output']>;
  /** This product's unique identifier */
  productId: Scalars['Int']['output'];
  /** The minimum quantity of products that can be ordered when creating a purchase order */
  purchaseMinimumQuantity: Scalars['Int']['output'];
  /** The unit in which the product can be ordered when creating a purchase order */
  purchaseUnit: Scalars['Int']['output'];
  /** The release date of this product */
  releaseDate?: Maybe<Scalars['DateTime']['output']>;
  /** Is this product returnable */
  returnable: YesNo;
  /** The short descriptions of this product per language */
  shortDescriptions: Array<LocalizedString>;
  /** The short name for the product, used for abbreviated orderlines */
  shortName: Scalars['String']['output'];
  /** The SKU (stock keeping unit) of this product */
  sku: Scalars['String']['output'];
  /** The slugs for this product per language */
  slugs: Array<LocalizedString>;
  /** The status of this product */
  status: ProductStatus;
  /** The extra status of this product, commonly linked to a custom valueset */
  statusExtra?: Maybe<Scalars['String']['output']>;
  /** The supplier of this product */
  supplier: Scalars['String']['output'];
  /** The supplier code of this product */
  supplierCode: Scalars['String']['output'];
  /** The taxonomy the product belongs to */
  taxonomy?: Maybe<Scalars['String']['output']>;
  /** The turnover group that classifies the product within the financial structure */
  turnoverGroup?: Maybe<Scalars['String']['output']>;
  /** The unit in which the product can be ordered */
  unit: Scalars['Int']['output'];
};

/** Interface for the Product Type */
export type IProductDescriptionsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

/** Interface for the Product Type */
export type IProductNamesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

/** Interface for the Product Type */
export type IProductShortDescriptionsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

/** Interface for the Product Type */
export type IProductSlugsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

/** Interface for the ProductOffer Type */
export type IProductOffer = {
  formula: Scalars['String']['output'];
  /** The id of the offer. */
  id: Scalars['String']['output'];
  /** The calculated price for this offer. */
  price: Scalars['Float']['output'];
  validFrom: Scalars['DateTime']['output'];
  validTo: Scalars['DateTime']['output'];
};

export type IResource = {
  /** The class of the request resource */
  class: ProductClass;
  /** The hidden status of this resource. */
  hidden: YesNo;
  /** Resource primary identifier */
  id: Scalars['Int']['output'];
  /**
   * The requested language for this this resource, default to the
   *           products default language.
   *           ISO 639-1 format.
   */
  language?: Maybe<Scalars['String']['output']>;
};

/** Interface for the TenderBaseItem Type, where both TenderBaseItem and TenderMainItem derive from */
export type ITenderBaseItem = {
  /** Extra item discount (%) calculated based on tender/order total discount */
  attributedSaleDiscount: Scalars['Float']['output'];
  /** Margin percentage calculated based on tender/order total discount */
  attributedSaleMargin: Scalars['Float']['output'];
  /** Product cost price per UOM of this tender/order item. VAT excluding */
  costPrice: Scalars['Float']['output'];
  /** Customer discount (%) */
  customerDiscountPercentage: Scalars['Float']['output'];
  /** Customer special price per UOM of this tender/order item. VAT excluding */
  customerPrice: Scalars['Float']['output'];
  /** Sales discount (%) */
  discountPercentage: Scalars['Float']['output'];
  /** The preferred delivery date for this order as requested by the user */
  expectedDeliveryDate?: Maybe<Scalars['DateTime']['output']>;
  /** The order item's ID in an external system */
  externalOrderitemId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Deprecate, please use uuid instead */
  id: Scalars['Int']['output'];
  /** The incentives applied to this tender item */
  incentive?: Maybe<TenderItemIncentive>;
  /** Default margin amount (list price). VAT excluding */
  marginAmount: Scalars['Float']['output'];
  /** Default margin percentage (list price) */
  marginPercentage: Scalars['Float']['output'];
  /** Tender/order item name */
  name: Scalars['String']['output'];
  /** User notes on tender item level */
  notes?: Maybe<Scalars['String']['output']>;
  /** Id of the order item */
  orderItemId?: Maybe<Scalars['Int']['output']>;
  /** Product list price per UOM of this tender/order item. VAT excluding */
  originalPrice: Scalars['Float']['output'];
  /** The gross sales price per UOM of this tender/order item, no item specific incentives and extra costs are applied to this price. VAT excluding */
  price: Scalars['Float']['output'];
  /** The sales price per UOM of this tender/order item, no item specific incentives and extra costs are applied to this price. VAT including */
  priceNet: Scalars['Float']['output'];
  /** The tender/order item's productId, if applicable */
  productId: Scalars['Int']['output'];
  /** The quantity for this tender/order item */
  quantity: Scalars['Int']['output'];
  /** Margin amount. VAT excluding */
  saleMarginAmount: Scalars['Float']['output'];
  /** Margin percentage */
  saleMarginPercentage: Scalars['Float']['output'];
  /** Tender/order item SKU */
  sku: Scalars['String']['output'];
  /** Tender/order item supplier */
  supplier: Scalars['String']['output'];
  /** Tender/order item supplier code */
  supplierCode: Scalars['String']['output'];
  /** The surcharges applied to this tender item */
  surcharges?: Maybe<Array<TenderItemSurcharge>>;
  /** The tax code for this tender/order item */
  taxCode: Taxcode;
  /** The tax percentage for this tender/order item */
  taxPercentage: Scalars['Int']['output'];
  /** Total discount (%) */
  totalDiscountPercentage: Scalars['Float']['output'];
  /** The total gross price of this tender/order item, no item specific incentives and extra costs are applied to this price. VAT excluding */
  totalPrice: Scalars['Float']['output'];
  /** The total price of this tender/order item, no item specific incentives and extra costs are applied to this price. VAT including */
  totalPriceNet: Scalars['Float']['output'];
  /** Id of the tender item, this id can be used for update or delete mutation for this tender item */
  uuid: Scalars['String']['output'];
  /** Total amount of valuePoints that apply to this tender/order item */
  valuePoints?: Maybe<Scalars['Int']['output']>;
};

export type ImageTransformationInput = {
  /** Friendly name for your image transformation */
  name: Scalars['String']['input'];
  /** Image transformation options */
  transformation: TransformationInput;
};

export type ImageVariant = {
  __typename?: 'ImageVariant';
  /** Image variant language */
  language: Scalars['String']['output'];
  /** The MIME type of the image. */
  mimeType: Scalars['String']['output'];
  /** Friendly image name */
  name: Scalars['String']['output'];
  /** Image variant transformation computed url */
  url: Scalars['String']['output'];
};

/** Types of Incentive BusinessRule Actions */
export enum IncentiveActions {
  AddProductToBonusItems = 'ADD_PRODUCT_TO_BONUS_ITEMS',
  AddValuepointsToOrder = 'ADD_VALUEPOINTS_TO_ORDER',
  DuplicateCheapestInBonusItems = 'DUPLICATE_CHEAPEST_IN_BONUS_ITEMS',
  DuplicateProductInBonusItems = 'DUPLICATE_PRODUCT_IN_BONUS_ITEMS',
  GiveDiscountToCheapestItem = 'GIVE_DISCOUNT_TO_CHEAPEST_ITEM',
  GiveDiscountToOrderItem = 'GIVE_DISCOUNT_TO_ORDER_ITEM',
  GiveDiscountToOrderTotal = 'GIVE_DISCOUNT_TO_ORDER_TOTAL',
  MoveProductToBonusItems = 'MOVE_PRODUCT_TO_BONUS_ITEMS',
  SetShippingCosts = 'SET_SHIPPING_COSTS',
  SetShippingTaxcode = 'SET_SHIPPING_TAXCODE',
  SetTransactionCosts = 'SET_TRANSACTION_COSTS',
}

/** Available type of incentive discounts */
export enum IncentiveDiscountType {
  FlatFee = 'FlatFee',
  Percentage = 'Percentage',
}

export type IncentiveRuleOrderItemSetCellInput = {
  /** The expressions value for this cell */
  expression?: InputMaybe<BusinessRuleExpressionGroupInput>;
  /** The localized string value of the cell, used in output cells. Used when you want a rule to generate a translated string in the output */
  localizedValue?: InputMaybe<Array<LocalizedStringInput>>;
  /** The numeric value for this cell, used in output cells */
  number?: InputMaybe<Scalars['Float']['input']>;
  /** The ID of the row the table cell */
  rowId: Scalars['ID']['input'];
  /** The ID of the BusinessRule that contains the table cell */
  ruleId: Scalars['ID']['input'];
  /** The string value of the cell, used in output cells. Can contain a string or an expression. Strings need to be wrapped in "" */
  value?: InputMaybe<Scalars['String']['input']>;
};

export type IncentiveRuleSetActionInput = {
  action: IncentiveActions;
  /** The action code that was applied by this rule. Should match the order.actionCode field in the inputs */
  actionCode?: InputMaybe<Scalars['String']['input']>;
  discount?: InputMaybe<Scalars['Float']['input']>;
  discountType?: InputMaybe<IncentiveDiscountType>;
  name: Array<LocalizedStringInput>;
  price?: InputMaybe<Scalars['Float']['input']>;
  productId?: InputMaybe<Scalars['Int']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  repeat?: InputMaybe<YesNo>;
  rowId: Scalars['ID']['input'];
  ruleId: Scalars['ID']['input'];
  valuePoints?: InputMaybe<Scalars['Float']['input']>;
};

export type Inventory = {
  __typename?: 'Inventory';
  /** Inventory cost price */
  costPrice: Scalars['Float']['output'];
  /** Inventory last modified date */
  dateModified?: Maybe<Scalars['DateTime']['output']>;
  /** Inventory primary identifier */
  id: Scalars['ID']['output'];
  /** Inventory location */
  location: Scalars['String']['output'];
  /** Inventory estimated next delivery date */
  nextDeliveryDate: Scalars['DateTime']['output'];
  /** Inventory note */
  notes: Scalars['String']['output'];
  /** Inventory product identifier */
  productId: Scalars['Int']['output'];
  /** Inventory quantity */
  quantity: Scalars['Int']['output'];
  /** Inventory sku */
  sku: Scalars['String']['output'];
  /** Inventory supplier */
  supplier: Scalars['String']['output'];
  /** Inventory supplier code */
  supplierCode: Scalars['String']['output'];
  /** Inventory warehouse identifier */
  warehouseId: Scalars['Int']['output'];
};

export type InventoryDeleteResponse = {
  __typename?: 'InventoryDeleteResponse';
  /** Error messages */
  messages: Array<Scalars['String']['output']>;
};

export type InventoryResponse = {
  __typename?: 'InventoryResponse';
  /** Inventory cost price */
  costPrice: Scalars['Float']['output'];
  /** Inventory last modified date */
  dateModified?: Maybe<Scalars['DateTime']['output']>;
  /** Inventory primary identifier */
  id: Scalars['ID']['output'];
  /** Inventory location */
  location: Scalars['String']['output'];
  /** Error messages */
  messages: Array<Scalars['String']['output']>;
  /** Inventory estimated next delivery date */
  nextDeliveryDate: Scalars['DateTime']['output'];
  /** Inventory note */
  notes: Scalars['String']['output'];
  /** Inventory product identifier */
  productId: Scalars['Int']['output'];
  /** Inventory quantity */
  quantity: Scalars['Int']['output'];
  /** Inventory sku */
  sku: Scalars['String']['output'];
  /** Inventory supplier */
  supplier: Scalars['String']['output'];
  /** Inventory supplier code */
  supplierCode: Scalars['String']['output'];
  /** Total */
  total: Scalars['Int']['output'];
  warehouse?: Maybe<Warehouse>;
  /** Inventory warehouse identifier */
  warehouseId: Scalars['Int']['output'];
};

/** Available inventory types */
export enum InventoryType {
  Local = 'local',
  Supplier = 'supplier',
}

/**
 * When converting animated GIFs to the MP4 format and when used in conjunction with the profile parameter, the level parameter specifies a set of constraints indicating a degree of required decoder performance for a profile.
 *
 *   NOTE: If either level or profile parameters are missing, profile=baseline&level=3.0 will be used as the default.
 */
export enum Level {
  /** Level: 1.0 */
  Level_1_0 = 'LEVEL_1_0',
  /** Level: 1.1 */
  Level_1_1 = 'LEVEL_1_1',
  /** Level: 1.2 */
  Level_1_2 = 'LEVEL_1_2',
  /** Level: 2.0 */
  Level_2_0 = 'LEVEL_2_0',
  /** Level: 2.1 */
  Level_2_1 = 'LEVEL_2_1',
  /** Level: 2.2 */
  Level_2_2 = 'LEVEL_2_2',
  /** Level: 3.0 (Default) */
  Level_3_0 = 'LEVEL_3_0',
  /** Level: 3.1 */
  Level_3_1 = 'LEVEL_3_1',
  /** Level: 3.2 */
  Level_3_2 = 'LEVEL_3_2',
  /** Level: 4.0 */
  Level_4_0 = 'LEVEL_4_0',
  /** Level: 4.1 */
  Level_4_1 = 'LEVEL_4_1',
  /** Level: 4.2 */
  Level_4_2 = 'LEVEL_4_2',
  /** Level: 5.0 */
  Level_5_0 = 'LEVEL_5_0',
  /** Level: 5.1 */
  Level_5_1 = 'LEVEL_5_1',
  /** Level: 5.2 */
  Level_5_2 = 'LEVEL_5_2',
  /** Level: 6.0 */
  Level_6_0 = 'LEVEL_6_0',
  /** Level: 6.1 */
  Level_6_1 = 'LEVEL_6_1',
  /** Level: 6.2 */
  Level_6_2 = 'LEVEL_6_2',
}

export type LocalizedAttachment = {
  __typename?: 'LocalizedAttachment';
  /** Attachment language */
  language: Scalars['String']['output'];
  /** The MIME type of the Attachment. */
  mimeType: Scalars['String']['output'];
  /** Original Attachment url */
  originalUrl: Scalars['String']['output'];
};

export type LocalizedDocument = {
  __typename?: 'LocalizedDocument';
  /** Document language */
  language: Scalars['String']['output'];
  /** The MIME type of the document. */
  mimeType: Scalars['String']['output'];
  /** Original document url */
  originalUrl: Scalars['String']['output'];
};

export type LocalizedImage = {
  __typename?: 'LocalizedImage';
  /** Image language */
  language: Scalars['String']['output'];
  /** The MIME type of the image. */
  mimeType: Scalars['String']['output'];
  /** Original image url */
  originalUrl: Scalars['String']['output'];
};

/** A String representation of a type Field that is suitable for Localization */
export type LocalizedString = {
  __typename?: 'LocalizedString';
  /** Localized field language code */
  language: Scalars['String']['output'];
  /** Localized field value */
  value?: Maybe<Scalars['String']['output']>;
};

/** A multivalue string representation of a type Field that is suitable for Localization */
export type LocalizedStringArray = {
  __typename?: 'LocalizedStringArray';
  /** Language is set as 2 character country code. */
  language: Scalars['String']['output'];
  /** List of localized string values */
  values?: Maybe<Array<Scalars['String']['output']>>;
};

export type LocalizedStringArrayInput = {
  /** Language is set as 2 character country code. */
  language: Scalars['String']['input'];
  /** List of localized string values */
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** A String representation of a type Field that is suitable for Localization */
export type LocalizedStringInput = {
  /** Localized field language code */
  language: Scalars['String']['input'];
  /** Localized field value */
  value?: InputMaybe<Scalars['String']['input']>;
};

export type LocalizedVideo = {
  __typename?: 'LocalizedVideo';
  /** Video language */
  language: Scalars['String']['output'];
  /** The MIME type of the video. */
  mimeType?: Maybe<Scalars['String']['output']>;
  /** Video URI */
  uri: Scalars['String']['output'];
};

export type Login = {
  __typename?: 'Login';
  operationType?: Maybe<Scalars['String']['output']>;
  providerId?: Maybe<Scalars['String']['output']>;
  session?: Maybe<GcipUser>;
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  provider?: InputMaybe<Scalars['String']['input']>;
};

export type Logout = {
  __typename?: 'Logout';
  todo?: Maybe<Scalars['String']['output']>;
};

export type Media = {
  __typename?: 'Media';
  attachment: MediaAttachment;
  attachments: PaginatedMediaAttachmentResponse;
  /** Media document */
  document?: Maybe<MediaDocument>;
  /** List of media documents */
  documents?: Maybe<PaginatedMediaDocumentResponse>;
  /** Media image */
  image?: Maybe<MediaImage>;
  /** List of media images */
  images?: Maybe<PaginatedMediaImageResponse>;
  /** Media video */
  video?: Maybe<MediaVideo>;
  /** List of media videos */
  videos?: Maybe<PaginatedMediaVideoResponse>;
};

export type MediaAttachmentArgs = {
  id: Scalars['String']['input'];
};

export type MediaAttachmentsArgs = {
  input?: InputMaybe<MediaAttachmentSearchInput>;
};

export type MediaDocumentArgs = {
  mediaId: Scalars['String']['input'];
};

export type MediaDocumentsArgs = {
  search?: InputMaybe<MediaDocumentSearchInput>;
};

export type MediaImageArgs = {
  mediaId: Scalars['String']['input'];
};

export type MediaImagesArgs = {
  search?: InputMaybe<MediaImageSearchInput>;
};

export type MediaVideoArgs = {
  mediaId: Scalars['String']['input'];
};

export type MediaVideosArgs = {
  search?: InputMaybe<MediaVideoSearchInput>;
};

export type MediaAttachment = {
  __typename?: 'MediaAttachment';
  /** Media alt description that briefly explains the contents of the document. */
  alt: Array<LocalizedString>;
  /** List of attachment objects */
  attachments?: Maybe<Array<LocalizedAttachment>>;
  /** Unique company identifier that the attachment relates to. [Cannot use it together with customerId] */
  companyId?: Maybe<Scalars['Int']['output']>;
  /** The date and time (ISO 8601 format) when the media was created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Unique customer identifier that the attachment relates to. [Cannot use it together with companyId] */
  customerId?: Maybe<Scalars['Int']['output']>;
  /** Media short description. */
  description: Array<LocalizedString>;
  /** Media global unique identifier */
  id: Scalars['ID']['output'];
  /** The date and time (ISO 8601 format) when the media was last modified. */
  lastModifiedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Unique order identifier that the attachment relates to. [Cannot use it together with sparePartsMachineId] */
  orderId?: Maybe<Scalars['Int']['output']>;
  /** Media display priority [Lower value has higher priority] -  default: 1000 */
  priority?: Maybe<Scalars['Int']['output']>;
  /** Unique sparePartsMachine identifier that the media relates to */
  sparePartsMachineId?: Maybe<Scalars['ID']['output']>;
  /** Media tags. */
  tags: Array<LocalizedStringArray>;
  /** Media type */
  type?: Maybe<Scalars['String']['output']>;
};

export type MediaAttachmentInput = {
  /** Media item alt description that briefly explains its contents. */
  alt: Array<MediaLocalizedStringInput>;
  /** Unique company identifier that the attachment relates to. [Cannot use it together with customerId] */
  companyId?: InputMaybe<Scalars['Int']['input']>;
  /** Unique customer identifier that the attachment relates to. [Cannot use it together with companyId] */
  customerId?: InputMaybe<Scalars['Int']['input']>;
  /** Media item short description. */
  description: Array<MediaLocalizedStringInput>;
  /** Unique order identifier that the attachment relates to. [Cannot use it together with sparePartsMachineId] */
  orderId?: InputMaybe<Scalars['Int']['input']>;
  /** Media item display priority [Lower value has higher priority] -  default: 1000 */
  priority?: InputMaybe<Scalars['Int']['input']>;
  /** Unique machine identifier that the media item relates to */
  sparePartsMachineId?: InputMaybe<Scalars['ID']['input']>;
  /** Media item tags. */
  tags: Array<MediaLocalizedStringArrayInput>;
  /** Upload File input reference */
  uploadAttachments: Array<UploadFileInput>;
};

export type MediaAttachmentSearchInput = {
  /** Unique company identifier that the attachment relates to. [Cannot use it together with customerId] */
  companyId?: InputMaybe<Scalars['Int']['input']>;
  /** Unique customer identifier that the attachment relates to. [Cannot use it together with companyId] */
  customerId?: InputMaybe<Scalars['Int']['input']>;
  /** Search by media item short description. */
  description?: InputMaybe<MediaLocalizedStringInput>;
  /** Pagination - offset number [default=12] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Unique order identifier that the attachment relates to. [Cannot use it together with sparePartsMachineId] */
  orderId?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination - page number [default=1] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Media item search sort [Default=ASC] */
  sort?: InputMaybe<Sort>;
  /** Search by unique spareparts machine identifier */
  sparePartsMachineId?: InputMaybe<Scalars['String']['input']>;
  /** Search by media item tags. */
  tag?: InputMaybe<MediaLocalizedStringInput>;
};

export type MediaBase64FileInput = {
  /** The base64 representation of the file contents. */
  base64: Scalars['String']['input'];
  /** Name to use when converting the base64 to a file. */
  fileName: Scalars['String']['input'];
};

export type MediaDocument = {
  __typename?: 'MediaDocument';
  /** Media alt description that briefly explains the contents of the document. */
  alt: Array<LocalizedString>;
  /** Unique category identifier that the media relates to */
  categoryId?: Maybe<Scalars['ID']['output']>;
  /** Unique cluster identifier that the media relates to */
  clusterId?: Maybe<Scalars['ID']['output']>;
  /** The date and time (ISO 8601 format) when the media was created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Media short description. */
  description: Array<LocalizedString>;
  /** List of document objects */
  documents?: Maybe<Array<LocalizedDocument>>;
  /** Media global unique identifier */
  id: Scalars['ID']['output'];
  /** The date and time (ISO 8601 format) when the media was last modified. */
  lastModifiedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Media display priority [Lower value has higher priority] -  default: 1000 */
  priority?: Maybe<Scalars['Int']['output']>;
  /** Unique product identifier that the media relates to */
  productId?: Maybe<Scalars['ID']['output']>;
  /** Unique sparePartsMachine identifier that the media relates to */
  sparePartsMachineId?: Maybe<Scalars['ID']['output']>;
  /** Media tags. */
  tags: Array<LocalizedStringArray>;
  /** Media type */
  type?: Maybe<Scalars['String']['output']>;
};

export type MediaDocumentAltArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type MediaDocumentDescriptionArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type MediaDocumentTagsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type MediaDocumentInput = {
  /** Media item alt description that briefly explains its contents. */
  alt: Array<MediaLocalizedStringInput>;
  /** Unique category identifier that the media item relates to */
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  /** Unique cluster identifier that the media item relates to */
  clusterId?: InputMaybe<Scalars['ID']['input']>;
  /** Media item short description. */
  description: Array<MediaLocalizedStringInput>;
  /** Media item display priority [Lower value has higher priority] -  default: 1000 */
  priority?: InputMaybe<Scalars['Int']['input']>;
  /** Unique product identifier that the media item relates to */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** Unique machine identifier that the media item relates to */
  sparePartsMachineId?: InputMaybe<Scalars['ID']['input']>;
  /** Media item tags. */
  tags: Array<MediaLocalizedStringArrayInput>;
  /** Upload File input reference */
  uploadDocuments: Array<UploadFileInput>;
};

export type MediaDocumentProductSearchInput = {
  /** Search by media item short description. */
  description?: InputMaybe<MediaLocalizedStringInput>;
  /** Pagination - offset number [default=12] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination - page number [default=1] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Media item search sort [Default=ASC] */
  sort?: InputMaybe<Sort>;
  /** Search by media item tags. */
  tag?: InputMaybe<MediaLocalizedStringInput>;
};

export type MediaDocumentSearchInput = {
  /** Search by unique category identifier */
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  /** Search by unique cluster identifier */
  clusterId?: InputMaybe<Scalars['Int']['input']>;
  /** Search by media item short description. */
  description?: InputMaybe<MediaLocalizedStringInput>;
  /** Pagination - offset number [default=12] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination - page number [default=1] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Search by unique product identifier */
  productId?: InputMaybe<Scalars['Int']['input']>;
  /** Media item search sort [Default=ASC] */
  sort?: InputMaybe<Sort>;
  /** Search by unique spareparts machine identifier */
  sparePartsMachineId?: InputMaybe<Scalars['String']['input']>;
  /** Search by media item tags. */
  tag?: InputMaybe<MediaLocalizedStringInput>;
};

export type MediaImage = {
  __typename?: 'MediaImage';
  /** Media alt description that briefly explains the contents of the document. */
  alt: Array<LocalizedString>;
  /** Unique category identifier that the media relates to */
  categoryId?: Maybe<Scalars['ID']['output']>;
  /** Unique cluster identifier that the media relates to */
  clusterId?: Maybe<Scalars['ID']['output']>;
  /** The date and time (ISO 8601 format) when the media was created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Media short description. */
  description: Array<LocalizedString>;
  /** Media global unique identifier */
  id: Scalars['ID']['output'];
  /** List of image variant objects */
  imageVariants?: Maybe<Array<ImageVariant>>;
  /** List of image objects */
  images?: Maybe<Array<LocalizedImage>>;
  /** The date and time (ISO 8601 format) when the media was last modified. */
  lastModifiedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Media display priority [Lower value has higher priority] -  default: 1000 */
  priority?: Maybe<Scalars['Int']['output']>;
  /** Unique product identifier that the media relates to */
  productId?: Maybe<Scalars['ID']['output']>;
  /** Unique sparePartsMachine identifier that the media relates to */
  sparePartsMachineId?: Maybe<Scalars['ID']['output']>;
  /** Media tags. */
  tags: Array<LocalizedStringArray>;
  /** Media type */
  type?: Maybe<Scalars['String']['output']>;
};

export type MediaImageAltArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type MediaImageDescriptionArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type MediaImageImageVariantsArgs = {
  input: TransformationsInput;
};

export type MediaImageTagsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type MediaImageInput = {
  /** Media item alt description that briefly explains its contents. */
  alt: Array<MediaLocalizedStringInput>;
  /** Unique category identifier that the media item relates to */
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  /** Unique cluster identifier that the media item relates to */
  clusterId?: InputMaybe<Scalars['ID']['input']>;
  /** Media item short description. */
  description: Array<MediaLocalizedStringInput>;
  /** Media item display priority [Lower value has higher priority] -  default: 1000 */
  priority?: InputMaybe<Scalars['Int']['input']>;
  /** Unique product identifier that the media item relates to */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** Unique machine identifier that the media item relates to */
  sparePartsMachineId?: InputMaybe<Scalars['ID']['input']>;
  /** Media item tags. */
  tags: Array<MediaLocalizedStringArrayInput>;
  /** Upload File input reference */
  uploadImages: Array<UploadFileInput>;
};

export type MediaImageProductSearchInput = {
  /** Search by media item short description. */
  description?: InputMaybe<MediaLocalizedStringInput>;
  /** Pagination - offset number [default=12] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination - page number [default=1] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Media item search sort [Default=ASC] */
  sort?: InputMaybe<Sort>;
  /** Search by media item tags. */
  tag?: InputMaybe<MediaLocalizedStringInput>;
};

export type MediaImageSearchInput = {
  /** Search by unique category identifier */
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  /** Search by unique cluster identifier */
  clusterId?: InputMaybe<Scalars['Int']['input']>;
  /** Search by media item short description. */
  description?: InputMaybe<MediaLocalizedStringInput>;
  /** Pagination - offset number [default=12] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination - page number [default=1] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Search by unique product identifier */
  productId?: InputMaybe<Scalars['Int']['input']>;
  /** Media item search sort [Default=ASC] */
  sort?: InputMaybe<Sort>;
  /** Search by unique spareparts machine identifier */
  sparePartsMachineId?: InputMaybe<Scalars['String']['input']>;
  /** Search by media item tags. */
  tag?: InputMaybe<MediaLocalizedStringInput>;
};

export type MediaLocalizedStringArrayInput = {
  /** Media localized string language country code [min/max: 2] */
  language: Scalars['String']['input'];
  /** Media localized string value */
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** A String representation of a type Field that is suitable for Localization */
export type MediaLocalizedStringInput = {
  /** Media localized string language country code [min/max: 2] */
  language: Scalars['String']['input'];
  /** Media localized string value */
  value: Scalars['String']['input'];
};

export type MediaVideo = {
  __typename?: 'MediaVideo';
  /** Media alt description that briefly explains the contents of the document. */
  alt: Array<LocalizedString>;
  /** Unique category identifier that the media relates to */
  categoryId?: Maybe<Scalars['ID']['output']>;
  /** Unique cluster identifier that the media relates to */
  clusterId?: Maybe<Scalars['ID']['output']>;
  /** The date and time (ISO 8601 format) when the media was created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Media short description. */
  description: Array<LocalizedString>;
  /** Media global unique identifier */
  id: Scalars['ID']['output'];
  /** The date and time (ISO 8601 format) when the media was last modified. */
  lastModifiedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Media display priority [Lower value has higher priority] -  default: 1000 */
  priority?: Maybe<Scalars['Int']['output']>;
  /** Unique product identifier that the media relates to */
  productId?: Maybe<Scalars['ID']['output']>;
  /** Unique sparePartsMachine identifier that the media relates to */
  sparePartsMachineId?: Maybe<Scalars['ID']['output']>;
  /** Media tags. */
  tags: Array<LocalizedStringArray>;
  /** Media type */
  type?: Maybe<Scalars['String']['output']>;
  /** List of video objects */
  videos?: Maybe<Array<LocalizedVideo>>;
};

export type MediaVideoAltArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type MediaVideoDescriptionArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type MediaVideoTagsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type MediaVideoInput = {
  /** Media item alt description that briefly explains its contents. */
  alt: Array<MediaLocalizedStringInput>;
  /** Unique category identifier that the media item relates to */
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  /** Unique cluster identifier that the media item relates to */
  clusterId?: InputMaybe<Scalars['ID']['input']>;
  /** Media item short description. */
  description: Array<MediaLocalizedStringInput>;
  /** Media item display priority [Lower value has higher priority] -  default: 1000 */
  priority?: InputMaybe<Scalars['Int']['input']>;
  /** Unique product identifier that the media item relates to */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** Unique machine identifier that the media item relates to */
  sparePartsMachineId?: InputMaybe<Scalars['ID']['input']>;
  /** Media item tags. */
  tags: Array<MediaLocalizedStringArrayInput>;
  /** Store Video input reference */
  videos: Array<UploadVideoInput>;
};

export type MediaVideoProductSearchInput = {
  /** Search by media item short description. */
  description?: InputMaybe<MediaLocalizedStringInput>;
  /** Pagination - offset number [default=12] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination - page number [default=1] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Media item search sort [Default=ASC] */
  sort?: InputMaybe<Sort>;
  /** Search by media item tags. */
  tag?: InputMaybe<MediaLocalizedStringInput>;
};

export type MediaVideoSearchInput = {
  /** Search by unique category identifier */
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  /** Search by unique cluster identifier */
  clusterId?: InputMaybe<Scalars['Int']['input']>;
  /** Search by media item short description. */
  description?: InputMaybe<MediaLocalizedStringInput>;
  /** Pagination - offset number [default=12] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination - page number [default=1] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Search by unique product identifier */
  productId?: InputMaybe<Scalars['Int']['input']>;
  /** Media item search sort [Default=ASC] */
  sort?: InputMaybe<Sort>;
  /** Search by unique spareparts machine identifier */
  sparePartsMachineId?: InputMaybe<Scalars['String']['input']>;
  /** Search by media item tags. */
  tag?: InputMaybe<MediaLocalizedStringInput>;
};

export type MultiFactor = {
  __typename?: 'MultiFactor';
  enrolledFactors?: Maybe<Array<EnrolledFactor>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addCompanyManager: Scalars['String']['output'];
  /** Add surcharge to product */
  addSurchargesToProduct: ConfirmationResponse;
  adminUserCreate: AdminUser;
  adminUserDelete: Scalars['Boolean']['output'];
  adminUserTenantCreate: AdminUserTenant;
  adminUserTenantDelete: AdminUserTenant;
  adminUserUpdate: AdminUser;
  attributeCreate: Attribute;
  attributeDelete: Scalars['Boolean']['output'];
  attributeDescriptionCreate: AttributeDescription;
  attributeDescriptionDelete: Scalars['Boolean']['output'];
  attributeDescriptionUpdate: AttributeDescription;
  attributeUpdate: Attribute;
  authenticationCreate: Login;
  authenticationDelete: Scalars['Boolean']['output'];
  bulkCostPriceCreate: BulkCostPrice;
  bulkCostPriceDelete: Scalars['Boolean']['output'];
  bulkCostPriceUpdate: BulkCostPrice;
  bulkCostPricesBulk: BulkResponseData;
  bulkCostPricesDelete: Scalars['Boolean']['output'];
  bulkPriceCreate: BulkPrice;
  bulkPriceDelete: Scalars['Boolean']['output'];
  bulkPriceUpdate: BulkPrice;
  bulkPricesCreate: BulkResponseData;
  bulkPricesDelete: Scalars['Boolean']['output'];
  bundleAddItems: Array<BundleItem>;
  bundleCreate: Bundle;
  bundleDelete: Scalars['Boolean']['output'];
  bundleRemoveItem: Scalars['Boolean']['output'];
  bundleUpdate: Bundle;
  /** Creates a new business rule. */
  businessRuleCreate: BusinessRule;
  /** Adds an input or output column to a decisionTable */
  businessRuleDecisionTableAddColumn: BusinessRuleDecisionTable;
  /** Adds a new row to a decisionTable */
  businessRuleDecisionTableAddRow: BusinessRuleDecisionTable;
  /** Deletes an input or output column from a decisionTable */
  businessRuleDecisionTableDeleteColumn: BusinessRuleDecisionTable;
  /** Deletes a row from a decisionTable */
  businessRuleDecisionTableDeleteRow: BusinessRuleDecisionTable;
  /** Moves a row within a decisionTable */
  businessRuleDecisionTableMoveRow: BusinessRuleDecisionTable;
  /** Sets the value of a cell in a decisionTable */
  businessRuleDecisionTableSetCell: BusinessRuleDecisionTable;
  /** Updates a decisionTable */
  businessRuleDecisionTableUpdate: BusinessRuleDecisionTable;
  /** Deletes an existing business rule. */
  businessRuleDelete: Scalars['Boolean']['output'];
  /** Updates an existing business rule. */
  businessRuleUpdate: BusinessRule;
  carrierAssignWarehouse: Scalars['Boolean']['output'];
  carrierCreate: Carrier;
  carrierDelete: Scalars['Boolean']['output'];
  carrierUnassignWarehouse: Scalars['Boolean']['output'];
  carrierUpdate: Carrier;
  /** Add action code to the cart */
  cartAddActionCode: Cart;
  /** Add bundle item to the cart */
  cartAddBundle: Cart;
  /** Add item to the cart */
  cartAddItem: Cart;
  /** Delete existing cart */
  cartDelete: Scalars['Boolean']['output'];
  /** Delete item from the cart */
  cartDeleteItem: Cart;
  /** Invalidate cache for a specific cart or all carts if no id is provided */
  cartInvalidateCache: Scalars['Boolean']['output'];
  /** Process cart */
  cartProcess: CartProcessResponse;
  /** Remove action code from the cart */
  cartRemoveActionCode: Cart;
  /** Set contact for the cart */
  cartSetContact: Cart;
  /** Set customer for the cart */
  cartSetCustomer: Cart;
  /**
   * Set user for the cart
   * @deprecated Will be removed in the future, please use `cartSetContact` or `cartSetCustomer` mutations instead.
   */
  cartSetUser: Cart;
  /** Initialize new cart */
  cartStart: Cart;
  /** Update existing cart */
  cartUpdate: Cart;
  /** Update cart address */
  cartUpdateAddress: Cart;
  /** Update item in the cart */
  cartUpdateItem: Cart;
  /** Create a new category */
  categoryCreate: Category;
  /** Delete category by category Id */
  categoryDelete: Scalars['Boolean']['output'];
  /** Update category by category Id */
  categoryUpdate: Category;
  channelInvalidateCache: Scalars['Boolean']['output'];
  claimsReset: Scalars['Boolean']['output'];
  /** Creates a setting to specific cluster config */
  clusterConfigAddSetting: ClusterConfigSettingResponse;
  /** Creates a new cluster config */
  clusterConfigCreate: ClusterConfigResponse;
  /** Deletes a cluster config */
  clusterConfigDelete: Scalars['Boolean']['output'];
  /** Removes a setting from specific cluster config */
  clusterConfigRemoveSetting: Scalars['Boolean']['output'];
  /** Updates a setting on cluster config */
  clusterConfigUpdateSetting: UpdateClusterConfigSettingResponse;
  /** Creates a cluster */
  clusterCreate: Cluster;
  /** Deletes a cluster */
  clusterDelete: Scalars['Boolean']['output'];
  /** Creates a cluster option */
  clusterOptionCreate: ClusterOption;
  /** Deletes a cluster option */
  clusterOptionDelete: Scalars['Boolean']['output'];
  /** Upadtes a cluster option */
  clusterOptionUpdate: ClusterOption;
  /** Creates a cluster/option product */
  clusterProductCreate: Product;
  /** Deletes a cluster/option product */
  clusterProductDelete: Scalars['Boolean']['output'];
  /** Updates a cluster/option product */
  clusterProductUpdate: Product;
  /** Updates a cluster */
  clusterUpdate: Cluster;
  companyAddressCreate: Address;
  companyAddressDelete: Scalars['Boolean']['output'];
  companyAddressUpdate: Address;
  companyCreate: Company;
  companyDelete: Scalars['String']['output'];
  companyUpdate: Company;
  contactAddToCompanies: ContactAddToCompaniesResponse;
  /** Creates a new contact, if you want this new contact to be able to login, please use contactRegister */
  contactCreate: Contact;
  contactCreateAccount: RegisterContactResponse;
  contactDelete: Scalars['Boolean']['output'];
  contactDeleteAccount: Scalars['Boolean']['output'];
  /** Creates a new contact and a new login account for this contact */
  contactRegister: RegisterContactResponse;
  contactRemoveFromCompanies: ContactRemoveFromCompaniesResponse;
  contactUpdate: Contact;
  crossupsellCreate: Crossupsell;
  crossupsellDelete: Scalars['Boolean']['output'];
  crossupsellUpdate: Crossupsell;
  customerAddressCreate: Address;
  customerAddressDelete: Scalars['Boolean']['output'];
  customerAddressUpdate: Address;
  /** Creates a new customer, if you want this new customer to be able to login, please use customerRegister */
  customerCreate: Customer;
  customerCreateAccount: RegisterCustomerResponse;
  customerDelete: Scalars['Boolean']['output'];
  customerDeleteAccount: Scalars['Boolean']['output'];
  /** Creates a new customer and a new login account for this customer */
  customerRegister: RegisterCustomerResponse;
  customerUpdate: Customer;
  discountCreate: Discount;
  discountDelete: Scalars['Boolean']['output'];
  discountUpdate: Discount;
  exchangeRefreshToken: RefreshTokenResponse;
  externalAddressCreate: ExternalAddress;
  externalAddressDelete: Scalars['Boolean']['output'];
  externalAddressUpdate: ExternalAddress;
  favoriteListAddItems: FavoriteList;
  favoriteListClearItems: FavoriteList;
  favoriteListCreate: FavoriteList;
  favoriteListDelete: Scalars['Boolean']['output'];
  favoriteListRemoveItems: FavoriteList;
  favoriteListUpdate: FavoriteList;
  incentiveRuleAddOrderCondition: BusinessRule;
  incentiveRuleCreate: BusinessRule;
  incentiveRuleRemoveOrderCondition: BusinessRule;
  incentiveRuleSetAction: BusinessRule;
  incentiveRuleSetOrderItemConditions: BusinessRule;
  inventoryCreate: InventoryResponse;
  inventoryDelete: InventoryDeleteResponse;
  inventoryUpdate: InventoryResponse;
  /** Logs in the authenticated user and returns a GCIP user session which includes the accessToken and refreshToken. */
  login: Login;
  /** @deprecated signOut mutation will be available in the future */
  logout?: Maybe<Logout>;
  machineCreate: SparePartsMachine;
  machineDelete: Scalars['Boolean']['output'];
  machineUpsert: SparePartsMachine;
  mediaAttachmentCreate: MediaAttachment;
  mediaAttachmentDelete: DeleteMediaAttachmentResponse;
  mediaAttachmentUpdate: MediaAttachment;
  mediaDocumentCreate: MediaDocument;
  mediaDocumentDelete: DeleteMediaDocumentResponse;
  mediaDocumentUpdate: MediaDocument;
  mediaImageCreate: MediaImage;
  mediaImageDelete: DeleteMediaImageResponse;
  mediaImageUpdate: MediaImage;
  mediaVideoCreate: MediaVideo;
  mediaVideoDelete: DeleteMediaVideoResponse;
  mediaVideoUpdate: MediaVideo;
  orderCreate: Order;
  orderDelete: Scalars['Boolean']['output'];
  orderItemCreate: OrderItem;
  orderItemDelete: Scalars['Boolean']['output'];
  orderItemUpdate: OrderItem;
  orderSendConfirmationEmail: SendOrderConfirmResponseType;
  orderSetStatus: Order;
  orderStatusCreate: OrderStatus;
  orderStatusDelete: Scalars['Boolean']['output'];
  orderStatusSetAddOrderStatuses: OrderStatusSet;
  orderStatusSetCreate: OrderStatusSet;
  orderStatusSetDelete: Scalars['Boolean']['output'];
  orderStatusSetRemoveOrderStatuses: OrderStatusSet;
  orderStatusSetUpdate: OrderStatusSet;
  orderStatusUpdate: OrderStatus;
  orderUpdate: Order;
  orderUpdateAddress: Scalars['Boolean']['output'];
  orderlistAddItems: Orderlist;
  orderlistAssignCompanies: Orderlist;
  orderlistAssignUsers: Orderlist;
  orderlistCreate: Orderlist;
  orderlistDelete: Scalars['Boolean']['output'];
  orderlistRemoveItems: Orderlist;
  orderlistUnassignCompanies: Orderlist;
  orderlistUnassignUsers: Orderlist;
  orderlistUpdate: Orderlist;
  /** Generates a password recovery link, that can be included in a password recovery e-mail. */
  passwordResetLink: Scalars['String']['output'];
  payMethodCreate: PayMethod;
  payMethodDelete: Scalars['Boolean']['output'];
  payMethodUpdate: PayMethod;
  paymentCreate: Payment;
  paymentDelete: Payment;
  paymentUpdate: Payment;
  priceCreate: Price;
  priceDelete: Scalars['Boolean']['output'];
  priceUpdate: Price;
  pricesheetAssign: Pricesheet;
  pricesheetCreate: Pricesheet;
  pricesheetDelete: Scalars['Boolean']['output'];
  pricesheetUnassign: Pricesheet;
  pricesheetUpdate: Pricesheet;
  /** Creates a new product */
  productCreate: Product;
  productDelete: Scalars['Boolean']['output'];
  /** Updates a product */
  productUpdate: Product;
  /** Publishes an email trigger event to pub/sub, containing the abstract event data */
  publishEmailEvent: PublishEmailEventResponse;
  /** Publishes an email send trigger event to pub/sub containing all (processed) fields needed to send a single email */
  publishEmailSendEvent: PublishEmailEventResponse;
  /** Publishes an email trigger event to pub/sub, containing the abstract event data and the password reset link */
  publishPasswordResetEmailEvent: PublishEmailEventResponse;
  removeCompanyManager: Scalars['String']['output'];
  /** Remove surcharge from product */
  removeSurchargesFromProduct: ConfirmationResponse;
  roleCreate: Role;
  roleDefinitionCreate: RoleDefinition;
  roleDefinitionDelete: Scalars['Boolean']['output'];
  roleDefinitionUpdate: RoleDefinition;
  roleDelete: Scalars['Boolean']['output'];
  roleUpdate: Role;
  shopInvalidateCache: Scalars['Boolean']['output'];
  /** Starts an anonymous firebase session */
  startSession: Login;
  /** Create a surcharge */
  surchargeCreate: Surcharge;
  /** Delete surcharge by id */
  surchargeDelete: Surcharge;
  /** Update surcharge by id */
  surchargeUpdate: Surcharge;
  taxCreate: Tax;
  taxDelete: Scalars['Boolean']['output'];
  taxUpdate: Tax;
  tenderAddItems: TenderResponse;
  tenderApplyIncentives: Tender;
  tenderDelete: Scalars['String']['output'];
  tenderDeleteItem: Tender;
  tenderProcess: TenderProcessResponse;
  tenderStart: Tender;
  tenderUpdate: Tender;
  tenderUpdateAddress: Tender;
  tenderUpdateDiscount: Tender;
  tenderUpdateInvoiceUser: Tender;
  tenderUpdateItem: Tender;
  tenderUpdatePayment: Tender;
  tenderUpdatePostage: Tender;
  userAddressCreate: Address;
  userAddressDelete: Scalars['Boolean']['output'];
  userAddressUpdate: Address;
  usergroupCreate: Usergroup;
  usergroupDelete: Scalars['Boolean']['output'];
  usergroupUpdate: Usergroup;
  valuesetCreate: Valueset;
  valuesetDelete: Scalars['Boolean']['output'];
  valuesetItemCreate: ValuesetItem;
  valuesetItemDelete: Scalars['Boolean']['output'];
  valuesetItemUpdate: ValuesetItem;
  valuesetUpdate: Valueset;
  verifyToken: VerifyToken;
  warehouseAddressCreate: WarehouseAddress;
  warehouseAddressDelete: Scalars['Boolean']['output'];
  warehouseAddressUpdate: WarehouseAddress;
  warehouseCreate: Warehouse;
  warehouseDelete: Warehouse;
  warehouseUpdate: Warehouse;
  zoneTaxCodeCreate: ZoneTaxCode;
  zoneTaxCodeDelete: Scalars['Boolean']['output'];
  zoneTaxCodeUpdate: ZoneTaxCode;
  zoneTaxCodesCreate: ZoneTaxCode;
  zoneTaxCodesDelete: Scalars['Boolean']['output'];
};

export type MutationAddCompanyManagerArgs = {
  companyId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

export type MutationAddSurchargesToProductArgs = {
  input: AddSurchargesToProductInput;
};

export type MutationAdminUserCreateArgs = {
  input: CreateUserDto;
};

export type MutationAdminUserDeleteArgs = {
  email: Scalars['String']['input'];
};

export type MutationAdminUserTenantCreateArgs = {
  input: CreateTenantDto;
};

export type MutationAdminUserTenantDeleteArgs = {
  id: Scalars['Int']['input'];
};

export type MutationAdminUserUpdateArgs = {
  email: Scalars['String']['input'];
  input: UpdateUserDto;
};

export type MutationAttributeCreateArgs = {
  input: AttributeCreateInput;
};

export type MutationAttributeDeleteArgs = {
  id: Scalars['String']['input'];
};

export type MutationAttributeDescriptionCreateArgs = {
  input: AttributeDescriptionCreateInput;
};

export type MutationAttributeDescriptionDeleteArgs = {
  id: Scalars['String']['input'];
};

export type MutationAttributeDescriptionUpdateArgs = {
  id: Scalars['String']['input'];
  input: AttributeDescriptionUpdateInput;
};

export type MutationAttributeUpdateArgs = {
  id: Scalars['String']['input'];
  input: AttributeUpdateInput;
};

export type MutationAuthenticationCreateArgs = {
  input: CreateAuthenticationInput;
};

export type MutationAuthenticationDeleteArgs = {
  uid: Scalars['String']['input'];
};

export type MutationBulkCostPriceCreateArgs = {
  input: BulkCostPriceCreateInput;
};

export type MutationBulkCostPriceDeleteArgs = {
  id: Scalars['String']['input'];
};

export type MutationBulkCostPriceUpdateArgs = {
  id: Scalars['String']['input'];
  input: BulkCostPriceUpdateInput;
};

export type MutationBulkCostPricesBulkArgs = {
  input: Array<BulkCostPriceCreateInput>;
};

export type MutationBulkCostPricesDeleteArgs = {
  priceId: Scalars['String']['input'];
};

export type MutationBulkPriceCreateArgs = {
  input: BulkPriceCreateInput;
};

export type MutationBulkPriceDeleteArgs = {
  id: Scalars['String']['input'];
};

export type MutationBulkPriceUpdateArgs = {
  id: Scalars['String']['input'];
  input: BulkPriceUpdateInput;
};

export type MutationBulkPricesCreateArgs = {
  input: Array<BulkPriceCreateInput>;
};

export type MutationBulkPricesDeleteArgs = {
  priceId: Scalars['String']['input'];
};

export type MutationBundleAddItemsArgs = {
  id: Scalars['String']['input'];
  input: BundleAddItemsInput;
};

export type MutationBundleCreateArgs = {
  input: BundleCreateInput;
};

export type MutationBundleDeleteArgs = {
  id: Scalars['String']['input'];
};

export type MutationBundleRemoveItemArgs = {
  id: Scalars['String']['input'];
  productId: Scalars['Int']['input'];
};

export type MutationBundleUpdateArgs = {
  id: Scalars['String']['input'];
  input: BundleUpdateInput;
};

export type MutationBusinessRuleCreateArgs = {
  input: BusinessRuleCreateInput;
};

export type MutationBusinessRuleDecisionTableAddColumnArgs = {
  input: BusinessRuleDecisionTableAddColumnInput;
};

export type MutationBusinessRuleDecisionTableAddRowArgs = {
  input: BusinessRuleDecisionTableSearchInput;
};

export type MutationBusinessRuleDecisionTableDeleteColumnArgs = {
  input: BusinessRuleDecisionTableDeleteColumnInput;
};

export type MutationBusinessRuleDecisionTableDeleteRowArgs = {
  input: BusinessRuleDecisionTableDeleteRowInput;
};

export type MutationBusinessRuleDecisionTableMoveRowArgs = {
  input: BusinessRuleDecisionTableMoveRowInput;
};

export type MutationBusinessRuleDecisionTableSetCellArgs = {
  input: BusinessRuleDecisionTableSetCellInput;
};

export type MutationBusinessRuleDecisionTableUpdateArgs = {
  input: BusinessRuleDecisionTableUpdateInput;
};

export type MutationBusinessRuleDeleteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationBusinessRuleUpdateArgs = {
  id: Scalars['ID']['input'];
  input: BusinessRuleUpdateInput;
};

export type MutationCarrierAssignWarehouseArgs = {
  id: Scalars['Int']['input'];
  warehouseId: Scalars['Int']['input'];
};

export type MutationCarrierCreateArgs = {
  input: CarrierCreateInput;
};

export type MutationCarrierDeleteArgs = {
  id: Scalars['Int']['input'];
};

export type MutationCarrierUnassignWarehouseArgs = {
  id: Scalars['Int']['input'];
  warehouseId: Scalars['Int']['input'];
};

export type MutationCarrierUpdateArgs = {
  id: Scalars['Int']['input'];
  input: CarrierUpdateInput;
};

export type MutationCartAddActionCodeArgs = {
  id: Scalars['String']['input'];
  input: CartActionCodeInput;
};

export type MutationCartAddBundleArgs = {
  id: Scalars['String']['input'];
  input: CartAddBundleInput;
};

export type MutationCartAddItemArgs = {
  id: Scalars['String']['input'];
  input: CartAddItemInput;
};

export type MutationCartDeleteArgs = {
  id: Scalars['String']['input'];
};

export type MutationCartDeleteItemArgs = {
  id: Scalars['String']['input'];
  input: CartDeleteItemInput;
};

export type MutationCartInvalidateCacheArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCartProcessArgs = {
  id: Scalars['String']['input'];
  input: CartProcessInput;
};

export type MutationCartRemoveActionCodeArgs = {
  id: Scalars['String']['input'];
  input: CartActionCodeInput;
};

export type MutationCartSetContactArgs = {
  id: Scalars['String']['input'];
  input: CartSetContactInput;
};

export type MutationCartSetCustomerArgs = {
  id: Scalars['String']['input'];
  input: CartSetCustomerInput;
};

export type MutationCartSetUserArgs = {
  id: Scalars['String']['input'];
  input: CartSetUserInput;
};

export type MutationCartStartArgs = {
  input?: InputMaybe<CartStartInput>;
};

export type MutationCartUpdateArgs = {
  id: Scalars['String']['input'];
  input: CartUpdateInput;
};

export type MutationCartUpdateAddressArgs = {
  id: Scalars['String']['input'];
  input: CartUpdateAddressInput;
};

export type MutationCartUpdateItemArgs = {
  id: Scalars['String']['input'];
  input: CartUpdateItemInput;
  itemId: Scalars['String']['input'];
};

export type MutationCategoryCreateArgs = {
  input: CreateCategoryInput;
};

export type MutationCategoryDeleteArgs = {
  categoryId: Scalars['Float']['input'];
};

export type MutationCategoryUpdateArgs = {
  categoryId: Scalars['Float']['input'];
  input: UpdateCategoryInput;
};

export type MutationClaimsResetArgs = {
  email: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};

export type MutationClusterConfigAddSettingArgs = {
  clusterConfigId: Scalars['Int']['input'];
  input: ClusterConfigSettingInput;
};

export type MutationClusterConfigCreateArgs = {
  input: ClusterConfigCreateInput;
};

export type MutationClusterConfigDeleteArgs = {
  id: Scalars['Int']['input'];
};

export type MutationClusterConfigRemoveSettingArgs = {
  clusterConfigId: Scalars['Int']['input'];
  settingId: Scalars['Int']['input'];
};

export type MutationClusterConfigUpdateSettingArgs = {
  clusterConfigId: Scalars['Int']['input'];
  input: ClusterConfigSettingUpdateInput;
  settingId: Scalars['Int']['input'];
};

export type MutationClusterCreateArgs = {
  input: ClusterCreateInput;
};

export type MutationClusterDeleteArgs = {
  id: Scalars['Int']['input'];
};

export type MutationClusterOptionCreateArgs = {
  clusterId: Scalars['Int']['input'];
  input: ClusterOptionCreateInput;
};

export type MutationClusterOptionDeleteArgs = {
  clusterId: Scalars['Int']['input'];
  optionId: Scalars['Int']['input'];
};

export type MutationClusterOptionUpdateArgs = {
  clusterId: Scalars['Int']['input'];
  input: ClusterOptionUpdateInput;
  optionId: Scalars['Int']['input'];
};

export type MutationClusterProductCreateArgs = {
  id: Scalars['Int']['input'];
  input: CreateProductInput;
};

export type MutationClusterProductDeleteArgs = {
  id: Scalars['Int']['input'];
  productId: Scalars['Int']['input'];
};

export type MutationClusterProductUpdateArgs = {
  id: Scalars['Int']['input'];
  input: UpdateProductInput;
  productId: Scalars['Int']['input'];
};

export type MutationClusterUpdateArgs = {
  id: Scalars['Int']['input'];
  input: ClusterUpdateInput;
};

export type MutationCompanyAddressCreateArgs = {
  input: CompanyAddressCreateInput;
};

export type MutationCompanyAddressDeleteArgs = {
  input: CompanyAddressDeleteInput;
};

export type MutationCompanyAddressUpdateArgs = {
  input: CompanyAddressUpdateInput;
};

export type MutationCompanyCreateArgs = {
  input: CreateCompanyInput;
};

export type MutationCompanyDeleteArgs = {
  id: Scalars['Int']['input'];
};

export type MutationCompanyUpdateArgs = {
  id: Scalars['Int']['input'];
  input: UpdateCompanyInput;
};

export type MutationContactAddToCompaniesArgs = {
  id: Scalars['Int']['input'];
  input: ContactAddToCompaniesInput;
};

export type MutationContactCreateArgs = {
  input: CreateContactInput;
};

export type MutationContactCreateAccountArgs = {
  id: Scalars['Int']['input'];
  input?: InputMaybe<CreateAccountInput>;
};

export type MutationContactDeleteArgs = {
  id: Scalars['Int']['input'];
};

export type MutationContactDeleteAccountArgs = {
  id: Scalars['Int']['input'];
};

export type MutationContactRegisterArgs = {
  input: RegisterContactInput;
};

export type MutationContactRemoveFromCompaniesArgs = {
  id: Scalars['Int']['input'];
  input: ContactRemoveFromCompaniesInput;
};

export type MutationContactUpdateArgs = {
  id: Scalars['Int']['input'];
  input: UpdateContactInput;
};

export type MutationCrossupsellCreateArgs = {
  input: CrossupsellCreateInput;
};

export type MutationCrossupsellDeleteArgs = {
  id: Scalars['String']['input'];
};

export type MutationCrossupsellUpdateArgs = {
  id: Scalars['String']['input'];
  input: CrossupsellUpdateInput;
};

export type MutationCustomerAddressCreateArgs = {
  input: CustomerAddressCreateInput;
};

export type MutationCustomerAddressDeleteArgs = {
  input: CustomerAddressDeleteInput;
};

export type MutationCustomerAddressUpdateArgs = {
  input: CustomerAddressUpdateInput;
};

export type MutationCustomerCreateArgs = {
  input: CustomerInput;
};

export type MutationCustomerCreateAccountArgs = {
  id: Scalars['Int']['input'];
  input?: InputMaybe<CreateAccountInput>;
};

export type MutationCustomerDeleteArgs = {
  id: Scalars['Int']['input'];
};

export type MutationCustomerDeleteAccountArgs = {
  id: Scalars['Int']['input'];
};

export type MutationCustomerRegisterArgs = {
  input: RegisterCustomerInput;
};

export type MutationCustomerUpdateArgs = {
  id: Scalars['Int']['input'];
  input: UpdateCustomerInput;
};

export type MutationDiscountCreateArgs = {
  input: DiscountCreateInput;
};

export type MutationDiscountDeleteArgs = {
  id: Scalars['String']['input'];
};

export type MutationDiscountUpdateArgs = {
  id: Scalars['String']['input'];
  input: DiscountUpdateInput;
};

export type MutationExchangeRefreshTokenArgs = {
  input: ExchangeRefreshTokenInput;
};

export type MutationExternalAddressCreateArgs = {
  input: ExternalAddressCreateInput;
};

export type MutationExternalAddressDeleteArgs = {
  input: ExternalAddressDeleteInput;
};

export type MutationExternalAddressUpdateArgs = {
  input: ExternalAddressUpdateInput;
};

export type MutationFavoriteListAddItemsArgs = {
  id: Scalars['String']['input'];
  input: FavoriteListsItemsInput;
};

export type MutationFavoriteListClearItemsArgs = {
  clusters?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  products?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationFavoriteListCreateArgs = {
  input: FavoriteListsCreateInput;
};

export type MutationFavoriteListDeleteArgs = {
  id: Scalars['String']['input'];
};

export type MutationFavoriteListRemoveItemsArgs = {
  id: Scalars['String']['input'];
  input: FavoriteListsItemsInput;
};

export type MutationFavoriteListUpdateArgs = {
  id: Scalars['String']['input'];
  input: FavoriteListsUpdateInput;
};

export type MutationIncentiveRuleCreateArgs = {
  input: BusinessRuleCreateInput;
};

export type MutationIncentiveRuleSetActionArgs = {
  input: IncentiveRuleSetActionInput;
};

export type MutationIncentiveRuleSetOrderItemConditionsArgs = {
  input: IncentiveRuleOrderItemSetCellInput;
};

export type MutationInventoryCreateArgs = {
  input: CreateInventoryInput;
};

export type MutationInventoryDeleteArgs = {
  id: Scalars['Int']['input'];
};

export type MutationInventoryUpdateArgs = {
  id: Scalars['Int']['input'];
  input: UpdateInventoryInput;
};

export type MutationLoginArgs = {
  input: LoginInput;
};

export type MutationLogoutArgs = {
  siteId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationMachineCreateArgs = {
  input: CreateSparePartsMachineInput;
};

export type MutationMachineDeleteArgs = {
  id: Scalars['String']['input'];
};

export type MutationMachineUpsertArgs = {
  input: UpsertSparePartsMachineInput;
};

export type MutationMediaAttachmentCreateArgs = {
  input: MediaAttachmentInput;
};

export type MutationMediaAttachmentDeleteArgs = {
  id: Scalars['String']['input'];
};

export type MutationMediaAttachmentUpdateArgs = {
  id: Scalars['String']['input'];
  input: UpdateMediaAttachmentInput;
};

export type MutationMediaDocumentCreateArgs = {
  input: MediaDocumentInput;
};

export type MutationMediaDocumentDeleteArgs = {
  mediaId: Scalars['String']['input'];
};

export type MutationMediaDocumentUpdateArgs = {
  input: UpdateMediaDocumentInput;
};

export type MutationMediaImageCreateArgs = {
  input: MediaImageInput;
};

export type MutationMediaImageDeleteArgs = {
  mediaId: Scalars['String']['input'];
};

export type MutationMediaImageUpdateArgs = {
  input: UpdateMediaImageInput;
};

export type MutationMediaVideoCreateArgs = {
  input: MediaVideoInput;
};

export type MutationMediaVideoDeleteArgs = {
  mediaId: Scalars['String']['input'];
};

export type MutationMediaVideoUpdateArgs = {
  input: UpdateMediaVideoInput;
};

export type MutationOrderCreateArgs = {
  order: OrderCreateInput;
};

export type MutationOrderDeleteArgs = {
  orderId: Scalars['Int']['input'];
};

export type MutationOrderItemCreateArgs = {
  orderId: Scalars['Int']['input'];
  orderItem: OrderItemCreateInput;
};

export type MutationOrderItemDeleteArgs = {
  orderId: Scalars['Int']['input'];
  orderItemId: Scalars['Int']['input'];
};

export type MutationOrderItemUpdateArgs = {
  orderId: Scalars['Int']['input'];
  orderItem: OrderItemUpdateInput;
};

export type MutationOrderSendConfirmationEmailArgs = {
  attachments?: InputMaybe<Array<Base64FileInput>>;
  orderId: Scalars['Int']['input'];
};

export type MutationOrderSetStatusArgs = {
  input: OrderSetStatusInput;
};

export type MutationOrderStatusCreateArgs = {
  input: CreateOrderStatusInput;
};

export type MutationOrderStatusDeleteArgs = {
  id: Scalars['Int']['input'];
};

export type MutationOrderStatusSetAddOrderStatusesArgs = {
  id: Scalars['Int']['input'];
  input: AddOrderStatusesToOrderStatusSetInput;
};

export type MutationOrderStatusSetCreateArgs = {
  input: CreateOrderStatusSetInput;
};

export type MutationOrderStatusSetDeleteArgs = {
  id: Scalars['Int']['input'];
};

export type MutationOrderStatusSetRemoveOrderStatusesArgs = {
  id: Scalars['Int']['input'];
  input: RemoveOrderStatusesFromOrderStatusSetInput;
};

export type MutationOrderStatusSetUpdateArgs = {
  id: Scalars['Int']['input'];
  input: UpdateOrderStatusSetInput;
};

export type MutationOrderStatusUpdateArgs = {
  id: Scalars['Int']['input'];
  input: UpdateOrderStatusInput;
};

export type MutationOrderUpdateArgs = {
  order: OrderUpdateInput;
  orderId: Scalars['Int']['input'];
};

export type MutationOrderUpdateAddressArgs = {
  addressId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  input: OrderUpdateAddressInput;
};

export type MutationOrderlistAddItemsArgs = {
  id: Scalars['Int']['input'];
  input: OrderlistItemsInput;
};

export type MutationOrderlistAssignCompaniesArgs = {
  id: Scalars['Int']['input'];
  input: OrderlistCompaniesInput;
};

export type MutationOrderlistAssignUsersArgs = {
  id: Scalars['Int']['input'];
  input: OrderlistUsersInput;
};

export type MutationOrderlistCreateArgs = {
  input: OrderlistCreateInput;
};

export type MutationOrderlistDeleteArgs = {
  id: Scalars['Int']['input'];
};

export type MutationOrderlistRemoveItemsArgs = {
  id: Scalars['Int']['input'];
  input: OrderlistItemsInput;
};

export type MutationOrderlistUnassignCompaniesArgs = {
  id: Scalars['Int']['input'];
  input: OrderlistCompaniesInput;
};

export type MutationOrderlistUnassignUsersArgs = {
  id: Scalars['Int']['input'];
  input: OrderlistUsersInput;
};

export type MutationOrderlistUpdateArgs = {
  id: Scalars['Int']['input'];
  input: OrderlistUpdateInput;
};

export type MutationPasswordResetLinkArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  input?: InputMaybe<PasswordRecoveryLinkInput>;
  redirectUrl?: InputMaybe<Scalars['String']['input']>;
};

export type MutationPayMethodCreateArgs = {
  input: PayMethodCreateInput;
};

export type MutationPayMethodDeleteArgs = {
  id: Scalars['Int']['input'];
};

export type MutationPayMethodUpdateArgs = {
  id: Scalars['Int']['input'];
  input: PayMethodUpdateInput;
};

export type MutationPaymentCreateArgs = {
  input: CreatePaymentInput;
};

export type MutationPaymentDeleteArgs = {
  searchBy: SearchByInput;
};

export type MutationPaymentUpdateArgs = {
  input: UpdatePaymentInput;
  searchBy: SearchByInput;
};

export type MutationPriceCreateArgs = {
  input: PriceCreateInput;
};

export type MutationPriceDeleteArgs = {
  id: Scalars['String']['input'];
};

export type MutationPriceUpdateArgs = {
  id: Scalars['String']['input'];
  input: PriceUpdateInput;
};

export type MutationPricesheetAssignArgs = {
  id: Scalars['String']['input'];
  input: PricesheetAssignInput;
};

export type MutationPricesheetCreateArgs = {
  input: PricesheetCreateInput;
};

export type MutationPricesheetDeleteArgs = {
  id: Scalars['String']['input'];
};

export type MutationPricesheetUnassignArgs = {
  id: Scalars['String']['input'];
  input: PricesheetUnassignInput;
};

export type MutationPricesheetUpdateArgs = {
  id: Scalars['String']['input'];
  input: PricesheetUpdateInput;
};

export type MutationProductCreateArgs = {
  input: CreateProductInput;
};

export type MutationProductDeleteArgs = {
  productId: Scalars['Int']['input'];
};

export type MutationProductUpdateArgs = {
  input: UpdateProductInput;
  productId: Scalars['Int']['input'];
};

export type MutationPublishEmailEventArgs = {
  input: EmailEventInput;
};

export type MutationPublishEmailSendEventArgs = {
  input: EmailSendEventInput;
};

export type MutationPublishPasswordResetEmailEventArgs = {
  input: PasswordResetLinkEmailInput;
};

export type MutationRemoveCompanyManagerArgs = {
  companyId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

export type MutationRemoveSurchargesFromProductArgs = {
  input: RemoveSurchargesFromProductInput;
};

export type MutationRoleCreateArgs = {
  input: RoleCreateInput;
};

export type MutationRoleDefinitionCreateArgs = {
  input: RoleDefinitionCreateInput;
};

export type MutationRoleDefinitionDeleteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRoleDefinitionUpdateArgs = {
  id: Scalars['ID']['input'];
  input: RoleDefinitionUpdateInput;
};

export type MutationRoleDeleteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRoleUpdateArgs = {
  id: Scalars['ID']['input'];
  input: RoleUpdateInput;
};

export type MutationStartSessionArgs = {
  siteId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationSurchargeCreateArgs = {
  input: CreateSurchargeInput;
};

export type MutationSurchargeDeleteArgs = {
  id: Scalars['String']['input'];
};

export type MutationSurchargeUpdateArgs = {
  id: Scalars['String']['input'];
  input: UpdateSurchargeInput;
};

export type MutationTaxCreateArgs = {
  input: TaxCreateInput;
};

export type MutationTaxDeleteArgs = {
  id: Scalars['String']['input'];
};

export type MutationTaxUpdateArgs = {
  id: Scalars['String']['input'];
  input: TaxUpdateInput;
};

export type MutationTenderAddItemsArgs = {
  id: Scalars['String']['input'];
  input: TenderAddItemsInput;
};

export type MutationTenderApplyIncentivesArgs = {
  id: Scalars['String']['input'];
};

export type MutationTenderDeleteArgs = {
  id: Scalars['String']['input'];
};

export type MutationTenderDeleteItemArgs = {
  id: Scalars['String']['input'];
  itemId: Scalars['String']['input'];
};

export type MutationTenderProcessArgs = {
  id: Scalars['String']['input'];
};

export type MutationTenderStartArgs = {
  input: TenderStartInput;
};

export type MutationTenderUpdateArgs = {
  id: Scalars['String']['input'];
  input: TenderUpdateInput;
};

export type MutationTenderUpdateAddressArgs = {
  id: Scalars['String']['input'];
  input: TenderUpdateAddressInput;
};

export type MutationTenderUpdateDiscountArgs = {
  id: Scalars['String']['input'];
  input: TenderDiscountInput;
};

export type MutationTenderUpdateInvoiceUserArgs = {
  id: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

export type MutationTenderUpdateItemArgs = {
  id: Scalars['String']['input'];
  input: TenderUpdateItemInput;
  itemId: Scalars['String']['input'];
};

export type MutationTenderUpdatePaymentArgs = {
  id: Scalars['String']['input'];
  input: TenderPaymentInput;
};

export type MutationTenderUpdatePostageArgs = {
  id: Scalars['String']['input'];
  input: TenderPostageInput;
};

export type MutationUserAddressCreateArgs = {
  input: UserAddressCreateInput;
};

export type MutationUserAddressDeleteArgs = {
  input: UserAddressDeleteInput;
};

export type MutationUserAddressUpdateArgs = {
  input: UserAddressUpdateInput;
};

export type MutationUsergroupCreateArgs = {
  input: CreateUsergroupInput;
};

export type MutationUsergroupDeleteArgs = {
  id: Scalars['Int']['input'];
};

export type MutationUsergroupUpdateArgs = {
  id: Scalars['Int']['input'];
  input: UpdateUsergroupInput;
};

export type MutationValuesetCreateArgs = {
  input: ValuesetCreateInput;
};

export type MutationValuesetDeleteArgs = {
  id: Scalars['Int']['input'];
};

export type MutationValuesetItemCreateArgs = {
  input: ValuesetItemCreateInput;
};

export type MutationValuesetItemDeleteArgs = {
  id: Scalars['Int']['input'];
};

export type MutationValuesetItemUpdateArgs = {
  id: Scalars['Int']['input'];
  input: ValuesetItemUpdateInput;
};

export type MutationValuesetUpdateArgs = {
  id: Scalars['Int']['input'];
  input: ValuesetUpdateInput;
};

export type MutationVerifyTokenArgs = {
  input: VerifyTokenInput;
};

export type MutationWarehouseAddressCreateArgs = {
  id: Scalars['Float']['input'];
  input: CreateWarehouseAddressInput;
};

export type MutationWarehouseAddressDeleteArgs = {
  id: Scalars['Float']['input'];
  input: DeleteWarehouseAddressInput;
};

export type MutationWarehouseAddressUpdateArgs = {
  id: Scalars['Float']['input'];
  input: UpdateWarehouseAddressInput;
};

export type MutationWarehouseCreateArgs = {
  input: CreateWarehouseInput;
};

export type MutationWarehouseDeleteArgs = {
  id: Scalars['Float']['input'];
};

export type MutationWarehouseUpdateArgs = {
  id: Scalars['Float']['input'];
  input: UpdateWarehouseInput;
};

export type MutationZoneTaxCodeCreateArgs = {
  input: ZoneTaxCodeCreateInput;
};

export type MutationZoneTaxCodeDeleteArgs = {
  id: Scalars['String']['input'];
};

export type MutationZoneTaxCodeUpdateArgs = {
  id: Scalars['String']['input'];
  input: ZoneTaxCodeUpdateInput;
};

export type MutationZoneTaxCodesCreateArgs = {
  input: Array<ZoneTaxCodeCreateInput>;
};

export type MutationZoneTaxCodesDeleteArgs = {
  priceId: Scalars['String']['input'];
};

export type NumberSearchInput = {
  /** equal to */
  equal?: InputMaybe<Scalars['Int']['input']>;
  /** greater than or equal */
  greaterThan?: InputMaybe<Scalars['Int']['input']>;
  /** less than or equal */
  lessThan?: InputMaybe<Scalars['Int']['input']>;
};

export type ObjectMediaSearchInput = {
  /** Search by media item short description. */
  description?: InputMaybe<MediaLocalizedStringInput>;
  /** Pagination - offset number [default=12] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination - page number [default=1] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Media item search sort [Default=ASC] */
  sort?: InputMaybe<Sort>;
  /** Search by media item tags. */
  tag?: InputMaybe<MediaLocalizedStringInput>;
};

/**
 * The optimize parameter automatically applies optimal quality compression to produce an output image with as much visual fidelity as possible, while minimizing the file size.
 *
 *   NOTE:
 *   1. Optimize is currently supported by the following output formats: JPEG, WebP.
 *   2. If the quality parameter is also provided, quality overrides optimize because it is more precise.
 *   3. Because optimize attempts to produce an output image with as much visual quality as possible while minimizing the file size, the operations applied are subject to change.
 */
export enum Optimize {
  /** Minor visual artifacts may be visible. This produces the smallest file. */
  High = 'HIGH',
  /** Output image quality will be similar to the input image quality. */
  Low = 'LOW',
  /** More optimization is allowed. We attempt to preserve the visual quality of the input image. */
  Medium = 'MEDIUM',
}

export type Order = {
  __typename?: 'Order';
  /** The id of the Owner/Account Manager that will handle this order */
  accountManagerId?: Maybe<Scalars['Int']['output']>;
  /**
   * The accounting id
   * @deprecated Deprecated in favour of paymentData.accountingId
   */
  accountingId?: Maybe<Scalars['String']['output']>;
  addresses: Array<Address>;
  /** The cartId of the cart that this order is created from. */
  cartId?: Maybe<Scalars['String']['output']>;
  /** The channel identifier of the webshop this order originated from */
  channelId?: Maybe<Scalars['Int']['output']>;
  /** The companyId of the company that placed this order */
  companyId?: Maybe<Scalars['Int']['output']>;
  /** The currency used to place this order */
  currency: Scalars['String']['output'];
  /** The currency's exchange rate at the time the order was placed */
  currencyRatio: Scalars['Float']['output'];
  /** The date and time the order was placed */
  date: Scalars['DateTime']['output'];
  /** The debtorId of the Customer or Company that placed this Order */
  debtorId?: Maybe<Scalars['String']['output']>;
  /** The email address for transactional emails */
  email: Scalars['String']['output'];
  /** The date the last transactional email was sent for this order */
  emailDate?: Maybe<Scalars['DateTime']['output']>;
  /** Order export message */
  exportMessage?: Maybe<Scalars['String']['output']>;
  /** Order export status of this order */
  exportStatus?: Maybe<OrderExportStatus>;
  /** The date and time the order was exported */
  exportedAt?: Maybe<Scalars['DateTime']['output']>;
  /** External identifier for this order from an external source like an ERP system */
  externalId?: Maybe<Scalars['String']['output']>;
  /**
   * extra3: Open text field to add meta data on an Order.
   *     The field will not be visible to Propeller admins, but can be used to be displayed on front-ends or be used in integrations.
   */
  extra3?: Maybe<Scalars['String']['output']>;
  /**
   * extra4: Open text field to add meta data on an Order.
   *     The field will not be visible to Propeller admins, but can be used to be displayed on front-ends or be used in integrations.
   */
  extra4?: Maybe<Scalars['String']['output']>;
  /** The auto-incremental id for this order */
  id: Scalars['Int']['output'];
  /** The id of the User, contact or Customer that should receive the invoice for this Order */
  invoiceUserId?: Maybe<Scalars['Int']['output']>;
  /** The items included in the order */
  items: Array<OrderItem>;
  /** The selected language on the webshop at the time the order was placed */
  language: Scalars['String']['output'];
  /** The date and time the order was last modified */
  lastModifiedAt?: Maybe<Scalars['DateTime']['output']>;
  media?: Maybe<OrderMedia>;
  /** Original order id */
  originalOrderId?: Maybe<Scalars['Int']['output']>;
  /** The payment data of the order */
  paymentData: OrderPaymentData;
  /**
   * The userId of the store that this order can be picked up in case of the pickup shippingMethod is chosen
   * @deprecated Deprecated in favour of postageData.pickUpLocationId
   */
  pickupStoreId?: Maybe<Scalars['Int']['output']>;
  /** The postage data of the order */
  postageData: OrderPostageData;
  /** Reference added by the customer about this order */
  reference?: Maybe<Scalars['String']['output']>;
  /** Remarks added by the customer about this order */
  remarks?: Maybe<Scalars['String']['output']>;
  /** Shipments for this Order */
  shipments?: Maybe<Array<Shipment>>;
  /** The ID of the shop the Order belongs to */
  shopId: Scalars['Int']['output'];
  /**
   * The siteId of the webshop this order originated from
   * @deprecated Deprecated in favor of channelId
   */
  siteId?: Maybe<Scalars['Int']['output']>;
  /** The source this order originates from */
  source: Scalars['String']['output'];
  /** The processing status for this order */
  status: Scalars['String']['output'];
  /** The date and time the status of the order was last changed */
  statusDate?: Maybe<Scalars['DateTime']['output']>;
  /** The totals of the order */
  total: OrderTotals;
  /** The type of this order */
  type: OrderType;
  /** The userId of the user that placed this order */
  userId: Scalars['Int']['output'];
  /** Unique identifier for this order */
  uuid: Scalars['String']['output'];
  /** The date and time until the order is valid */
  validUntil?: Maybe<Scalars['DateTime']['output']>;
};

export type OrderAddressesArgs = {
  type?: InputMaybe<AddressType>;
};

export type OrderCreateInput = {
  /** User ID of the Account Manager of the order */
  accountManagerId?: InputMaybe<Scalars['Int']['input']>;
  /** Accounting ID of the Order */
  accountingId?: InputMaybe<Scalars['String']['input']>;
  /** Active actioncode that was applied to this Order  */
  actionCode?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Cart the Order belongs to */
  cartId?: InputMaybe<Scalars['String']['input']>;
  /** Channel ID the Order belongs to */
  channelId?: InputMaybe<Scalars['Int']['input']>;
  /** ID of the Company of the Order */
  companyId?: InputMaybe<Scalars['Int']['input']>;
  /** A company source */
  companySource?: InputMaybe<SourceInput>;
  /** Order currency - ISO 4217 currency code => https://www.xe.com/iso4217.php */
  currency: Scalars['String']['input'];
  /** Currency conversion ratio compared the the Order's Shop's base currency */
  currencyRatio?: InputMaybe<Scalars['Float']['input']>;
  /** Company name of the Debtor [USED FOR ORDER SEARCH ONLY] */
  debtorCompany?: InputMaybe<Scalars['String']['input']>;
  /** First name of the Debtor [USED FOR ORDER SEARCH ONLY] */
  debtorFirstName?: InputMaybe<Scalars['String']['input']>;
  /** Debtor ID of the  User, Contact or Customer  */
  debtorId?: InputMaybe<Scalars['String']['input']>;
  /** Last name of the Debtor [USED FOR ORDER SEARCH ONLY] */
  debtorLastName?: InputMaybe<Scalars['String']['input']>;
  /** Email address used for communication about this Order */
  email: Scalars['String']['input'];
  /** Order export message */
  exportMessage?: InputMaybe<Scalars['String']['input']>;
  /** Order export status of this order */
  exportStatus?: InputMaybe<OrderExportStatus>;
  /** The date and time the order was exported */
  exportedAt?: InputMaybe<Scalars['String']['input']>;
  /** ID of the Order in an external system */
  externalId?: InputMaybe<Scalars['String']['input']>;
  /**
   * extra3: Open text field to add meta data on an Order.
   *     The field will not be visible to Propeller admins, but can be used to be displayed on front-ends or be used in integrations.
   */
  extra3?: InputMaybe<Scalars['String']['input']>;
  /**
   * extra4: Open text field to add meta data on an Order.
   *     The field will not be visible to Propeller admins, but can be used to be displayed on front-ends or be used in integrations.
   */
  extra4?: InputMaybe<Scalars['String']['input']>;
  /** ID of the User, Contact or Customer that should receive the invoice for this Order */
  invoiceUserId?: InputMaybe<Scalars['Int']['input']>;
  /** The OrderItem to create with the Order as a single bulk request */
  items?: Array<OrderItemCreateInput>;
  /** Order language - ISO 639-1 language code => https://localizely.com/iso-639-1-list/ */
  language: Scalars['String']['input'];
  /** Original order id */
  originalOrderId?: InputMaybe<Scalars['Int']['input']>;
  /** Order's payment data */
  paymentData: OrderPaymentInput;
  /** Order's postage data */
  postageData: OrderPostageInput;
  /** Company name of the Recipient [USED FOR ORDER SEARCH ONLY] */
  recipientCompany?: InputMaybe<Scalars['String']['input']>;
  /** First name of the Recipient [USED FOR ORDER SEARCH ONLY] */
  recipientFirstName?: InputMaybe<Scalars['String']['input']>;
  /** Last name of the Recipient [USED FOR ORDER SEARCH ONLY] */
  recipientLastName?: InputMaybe<Scalars['String']['input']>;
  /** Reference by the customer */
  reference?: InputMaybe<Scalars['String']['input']>;
  /** Remarks by the customer */
  remarks?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the shop the Order belongs to */
  shopId: Scalars['Int']['input'];
  /** Source of the Order i.e. webshop, internal, external or api */
  source?: InputMaybe<Scalars['String']['input']>;
  /** Status of the Order */
  status: Scalars['String']['input'];
  /** Order's totals, tax and global discount */
  total: OrderTotalInput;
  /** Type of the Order */
  type: OrderType;
  /** ID of the User, Contact or Customer of the Order */
  userId: Scalars['Int']['input'];
  /** Date and time until the order is valid */
  validUntil?: InputMaybe<Scalars['String']['input']>;
  /** Value points collected with this Order */
  valuePoints?: InputMaybe<Scalars['Int']['input']>;
};

/** Order discount types */
export enum OrderDiscountType {
  /** Absolute discount */
  A = 'A',
  /** No discount */
  N = 'N',
  /** Percentage discount */
  P = 'P',
}

/** Order export status */
export enum OrderExportStatus {
  Empty = 'EMPTY',
  Exported = 'EXPORTED',
  Failed = 'FAILED',
  Finished = 'FINISHED',
}

export type OrderItem = {
  __typename?: 'OrderItem';
  /** The class of the orderItem. Either product, incentive, surcharge, postage, payment */
  class: OrderItemClass;
  /** The cost price of the OrderItem at the time the order was placed */
  costPrice?: Maybe<Scalars['Float']['output']>;
  /** The calculated customerPrice of the OrderItem at the time the order was placed */
  customerPrice?: Maybe<Scalars['Float']['output']>;
  /** The discount of the OrderItem */
  discount?: Maybe<Scalars['Float']['output']>;
  /** The eanCode of the product of the OrderItem */
  eanCode?: Maybe<Scalars['String']['output']>;
  /** The autoincerment ID for the OrderItem */
  id: Scalars['Int']['output'];
  /** Is the OrderItem a bonusItem? */
  isBonus: YesNo;
  /** The manufacturer of the product of the OrderItem */
  manufacturer?: Maybe<Scalars['String']['output']>;
  /** The manufacturerCode of the product of the OrderItem */
  manufacturerCode?: Maybe<Scalars['String']['output']>;
  /** The minimum quantity of the product of the OrderItem */
  minimumQuantity?: Maybe<Scalars['Int']['output']>;
  /**
   * The minimum quantity of the product of the OrderItem
   * @deprecated Use `minimumQuantity` instead
   */
  minumumQuantity?: Maybe<Scalars['Int']['output']>;
  /** The name of the product of the OrderItem */
  name?: Maybe<Scalars['String']['output']>;
  /** Remarks by the customer for the OrderItem */
  notes?: Maybe<Scalars['String']['output']>;
  /** The ID of the Order the OrderItem belongs to */
  orderId: Scalars['Int']['output'];
  /** The original price of the OrderItem, before applying any discounts */
  originalPrice?: Maybe<Scalars['Float']['output']>;
  /** The package of the product of the OrderItem */
  package?: Maybe<Scalars['String']['output']>;
  /** The package unit of the product of the OrderItem */
  packageUnit?: Maybe<Scalars['String']['output']>;
  /** The package unit quantity of the product of the OrderItem */
  packageUnitQuantity?: Maybe<Scalars['String']['output']>;
  /** The ID of the parent OrderItem */
  parentOrderItemId?: Maybe<Scalars['Int']['output']>;
  /** The calculated price per unit excluding tax */
  price: Scalars['Float']['output'];
  /** The calculated price per unit including tax */
  priceNet?: Maybe<Scalars['Float']['output']>;
  /** The total price of the OrderItem excluding tax */
  priceTotal: Scalars['Float']['output'];
  /** The total price of the OrderItem including tax */
  priceTotalNet?: Maybe<Scalars['Float']['output']>;
  product?: Maybe<Product>;
  /** The productId of the OrderItem */
  productId?: Maybe<Scalars['Int']['output']>;
  /** The purchase minimum quantity of the product of the OrderItem */
  purchaseMinimumQuantity?: Maybe<Scalars['Int']['output']>;
  /** The purchase unit of the product of the OrderItem */
  purchaseUnit?: Maybe<Scalars['Int']['output']>;
  /** The quantity of the OrderItem */
  quantity: Scalars['Int']['output'];
  /** The requested delivery date for this orderline */
  requestDate?: Maybe<Scalars['DateTime']['output']>;
  /** The SKU of the product of the OrderItem */
  sku: Scalars['String']['output'];
  /** The supplier of the product of the OrderItem */
  supplier?: Maybe<Scalars['String']['output']>;
  /** The supplierCode of the product of the OrderItem */
  supplierCode?: Maybe<Scalars['String']['output']>;
  /** The total tax of the OrderItem */
  tax?: Maybe<Scalars['Float']['output']>;
  /** The tax code of the OrderItem */
  taxCode: Taxcode;
  /** The tax percentage of the OrderItem */
  taxPercentage: Scalars['Int']['output'];
  /** The unit of the product of the OrderItem */
  unit?: Maybe<Scalars['Int']['output']>;
  /** The UUID for the OrderItem */
  uuid: Scalars['String']['output'];
};

/** Class of the order item */
export enum OrderItemClass {
  Incentive = 'incentive',
  Payment = 'payment',
  Postage = 'postage',
  Product = 'product',
  Surcharge = 'surcharge',
}

export type OrderItemCreateInput = {
  /** The class of the orderItem. Either product, incentive, surcharge, postage, payment */
  class: OrderItemClass;
  /** The cost price of the OrderItem */
  costPrice?: InputMaybe<Scalars['Float']['input']>;
  /** The calculated customerPrice of the OrderItem at the time the order was placed */
  customerPrice?: InputMaybe<Scalars['Float']['input']>;
  /** The discount of the OrderItem */
  discount?: InputMaybe<Scalars['Float']['input']>;
  /** The eanCode of the product of the OrderItem */
  eanCode?: InputMaybe<Scalars['String']['input']>;
  /** Is the OrderItem a bonusItem? */
  isBonus: YesNo;
  /** The manufacturer of the product of the OrderItem */
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  /** The manufacturerCode of the product of the OrderItem */
  manufacturerCode?: InputMaybe<Scalars['String']['input']>;
  /** The minimum quantity of the product of the OrderItem */
  minimumQuantity?: InputMaybe<Scalars['Int']['input']>;
  /** The minimum quantity of the product of the OrderItem */
  minumumQuantity?: InputMaybe<Scalars['Int']['input']>;
  /** The name of the product of the OrderItem */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Remarks by the customer for the OrderItem */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** The original price of the OrderItem, before applying any discounts */
  originalPrice: Scalars['Float']['input'];
  /** The package of the product of the OrderItem */
  package?: InputMaybe<Scalars['String']['input']>;
  /** The package unit of the product of the OrderItem */
  packageUnit?: InputMaybe<Scalars['String']['input']>;
  /** The package unit quantity of the product of the OrderItem */
  packageUnitQuantity?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the parent OrderItem */
  parentOrderItemId?: InputMaybe<Scalars['Int']['input']>;
  /** The UUID of the parent OrderItem, this can be used in bulk operations where the auto-increment ID's are not known */
  parentOrderItemUUID?: InputMaybe<Scalars['String']['input']>;
  /** The calculated price per unit excluding tax */
  price: Scalars['Float']['input'];
  /** The calculated price per unit including tax */
  priceNet?: InputMaybe<Scalars['Float']['input']>;
  /** The total price of the OrderItem excluding tax */
  priceTotal: Scalars['Float']['input'];
  /** The total price of the OrderItem including tax */
  priceTotalNet?: InputMaybe<Scalars['Float']['input']>;
  /** The productId of the OrderItem */
  productId?: InputMaybe<Scalars['Int']['input']>;
  /** A product source */
  productSource?: InputMaybe<SourceInput>;
  /** The purchase minimum quantity of the product of the OrderItem */
  purchaseMinimumQuantity?: InputMaybe<Scalars['Int']['input']>;
  /** The purchase unit of the product of the OrderItem */
  purchaseUnit?: InputMaybe<Scalars['Int']['input']>;
  /** The quantity of the OrderItem */
  quantity: Scalars['Int']['input'];
  /** Requested delivery date for this orderline */
  requestDate?: InputMaybe<Scalars['String']['input']>;
  /** The SKU of the product of the OrderItem */
  sku: Scalars['String']['input'];
  /** The supplier of the product of the OrderItem */
  supplier?: InputMaybe<Scalars['String']['input']>;
  /** The supplierCode of the product of the OrderItem */
  supplierCode?: InputMaybe<Scalars['String']['input']>;
  /** The total tax of the OrderItem */
  tax?: InputMaybe<Scalars['Float']['input']>;
  /** The tax code of the OrderItem */
  taxCode: Taxcode;
  /** The tax percentage of the OrderItem */
  taxPercentage: Scalars['Int']['input'];
  /** The unit of the product of the OrderItem */
  unit?: InputMaybe<Scalars['Int']['input']>;
  /** The UUID for the OrderItem */
  uuid: Scalars['String']['input'];
};

/** Order item incentive calculation types */
export enum OrderItemIncentiveCode {
  CostpricePlus = 'COSTPRICE_PLUS',
  Discount = 'DISCOUNT',
  DiscountPercentage = 'DISCOUNT_PERCENTAGE',
  Free = 'FREE',
  SalepriceMinus = 'SALEPRICE_MINUS',
  SetPrice = 'SET_PRICE',
}

export type OrderItemUpdateInput = {
  /** The costprice of the orderitem */
  costPrice?: InputMaybe<Scalars['Float']['input']>;
  /** The calculated user specific price of the orderitem, before applying any additional discounts */
  customerPrice?: InputMaybe<Scalars['Float']['input']>;
  /** The discount applied to this orderitem */
  discount: Scalars['Float']['input'];
  /** The ID of the orderitem to update. */
  id: Scalars['Int']['input'];
  /** The orderitem notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** The original price of the OrderItem, before applying any discounts */
  originalPrice: Scalars['Float']['input'];
  /** The gross price per unit excluding tax */
  price: Scalars['Float']['input'];
  /** The calculated price per unit including tax */
  priceNet?: InputMaybe<Scalars['Float']['input']>;
  /** The total price of the orderitem excluding tax */
  priceTotal: Scalars['Float']['input'];
  /** The total price of the OrderItem including tax */
  priceTotalNet?: InputMaybe<Scalars['Float']['input']>;
  /** The quantity of the orderitem */
  quantity: Scalars['Int']['input'];
  /** Requested delivery date for this orderline */
  requestDate?: InputMaybe<Scalars['String']['input']>;
  /** The amount of tax applied to this orderitem */
  tax: Scalars['Float']['input'];
  /** The tax code of the OrderItem */
  taxCode: Taxcode;
  /** The tax percentage applied to this orderitem */
  taxPercentage: Scalars['Int']['input'];
};

export type OrderMedia = {
  __typename?: 'OrderMedia';
  /** Media attachments */
  attachments?: Maybe<PaginatedMediaAttachmentResponse>;
};

export type OrderMediaAttachmentsArgs = {
  input?: InputMaybe<ObjectMediaSearchInput>;
};

export type OrderPaymentData = {
  __typename?: 'OrderPaymentData';
  /** The accountingId that belongs to this order */
  accountingId?: Maybe<Scalars['String']['output']>;
  /** The transaction cost excluding tax */
  gross: Scalars['Float']['output'];
  /** The paymethod for this Order */
  method: Scalars['String']['output'];
  /** The transaction cost including tax */
  net: Scalars['Float']['output'];
  /** Whether the transaction costs for this order are overruled, if N, the shipping costs will be recalculated on every mutation */
  overruled?: Maybe<YesNo>;
  /** The transaction status */
  status?: Maybe<Scalars['String']['output']>;
  /** Last time the transaction status was changed */
  statusDate?: Maybe<Scalars['DateTime']['output']>;
  /** The tax on the transaction costs */
  tax: Scalars['Float']['output'];
  /** The transaction costs tax percentage */
  taxPercentage?: Maybe<Scalars['Float']['output']>;
};

export type OrderPaymentInput = {
  /** The accountingId that belongs to this order */
  accountingId?: InputMaybe<Scalars['String']['input']>;
  /** The transaction cost excluding tax */
  gross: Scalars['Float']['input'];
  /** The paymethod for this Order */
  method: Scalars['String']['input'];
  /** The transaction cost including tax */
  net: Scalars['Float']['input'];
  /** Whether the transaction costs for this order are overruled, if N, the shipping costs will be recalculated on every mutation */
  overruled?: InputMaybe<YesNo>;
  /** The transaction status */
  status?: InputMaybe<Scalars['String']['input']>;
  /** Last time the transaction status was changed */
  statusDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The transaction cost tax */
  tax: Scalars['Float']['input'];
  /** The transaction costs tax percentage */
  taxPercentage: Scalars['Float']['input'];
};

export type OrderPaymentUpdateInput = {
  /** The accountingId that belongs to this order */
  accountingId?: InputMaybe<Scalars['String']['input']>;
  /** The transaction cost excluding tax */
  gross?: InputMaybe<Scalars['Float']['input']>;
  /** The paymethod for this Order */
  method?: InputMaybe<Scalars['String']['input']>;
  /** The transaction cost including tax */
  net?: InputMaybe<Scalars['Float']['input']>;
  /** Whether the transaction costs for this order are overruled, if N, the shipping costs will be recalculated on every mutation */
  overruled?: InputMaybe<YesNo>;
  /** The transaction status */
  status?: InputMaybe<Scalars['String']['input']>;
  /** Last time the transaction status was changed */
  statusDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The transaction cost tax */
  tax?: InputMaybe<Scalars['Float']['input']>;
  /** The transaction costs tax percentage */
  taxPercentage?: InputMaybe<Scalars['Float']['input']>;
};

export type OrderPostageData = {
  __typename?: 'OrderPostageData';
  /** The selected carrier for this Order */
  carrier?: Maybe<Scalars['String']['output']>;
  /** The shipping costs excluding tax */
  gross: Scalars['Float']['output'];
  /** The chosen shipping method */
  method: Scalars['String']['output'];
  /** The shipping costs including tax */
  net: Scalars['Float']['output'];
  /** Whether the shipping costs for this order are overruled, if N, the shipping costs will be recalculated on every mutation */
  overruled?: Maybe<YesNo>;
  /** Is partial delivery allowed for this Order */
  partialDeliveryAllowed?: Maybe<YesNo>;
  /** ID of the pick up location when the Order's shipping method is PICKUP */
  pickUpLocationId?: Maybe<Scalars['Int']['output']>;
  /** The preferred delivery date for the Order */
  requestDate?: Maybe<Scalars['DateTime']['output']>;
  /** The tax on the shipping costs */
  tax: Scalars['Float']['output'];
  /** The shipping costs tax percentage */
  taxPercentage: Scalars['Float']['output'];
  warehouse?: Maybe<Warehouse>;
};

export type OrderPostageInput = {
  /** The selected carrier for this Order */
  carrier?: InputMaybe<Scalars['String']['input']>;
  /** The shipping costs excluding tax */
  gross: Scalars['Float']['input'];
  /** The chosen shipping method */
  method: Scalars['String']['input'];
  /** The shipping costs including tax */
  net: Scalars['Float']['input'];
  /** Whether the shipping costs for this order are overruled, if N, the shipping costs will be recalculated on every mutation */
  overruled?: InputMaybe<YesNo>;
  /** Is partial delivery allowed for this Order */
  partialDeliveryAllowed?: InputMaybe<YesNo>;
  /** ID of the pick up location when the Order's shipping method is PICKUP */
  pickUpLocationId?: InputMaybe<Scalars['Int']['input']>;
  /** The provided delivery date for the Order */
  requestDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The shipping costs tax */
  tax: Scalars['Float']['input'];
  /** The shipping costs tax percentage */
  taxPercentage: Scalars['Float']['input'];
};

export type OrderPostageUpdateInput = {
  /** The selected carrier for this Order */
  carrier?: InputMaybe<Scalars['String']['input']>;
  /** The shipping costs excluding tax */
  gross?: InputMaybe<Scalars['Float']['input']>;
  /** The chosen shipping method */
  method?: InputMaybe<Scalars['String']['input']>;
  /** The shipping costs including tax */
  net?: InputMaybe<Scalars['Float']['input']>;
  /** Whether the shipping costs for this order are overruled, if N, the shipping costs will be recalculated on every mutation */
  overruled?: InputMaybe<YesNo>;
  /** Is partial delivery allowed for this Order */
  partialDeliveryAllowed?: InputMaybe<YesNo>;
  /** ID of the pick up location when the Order's shipping method is PICKUP */
  pickUpLocationId?: InputMaybe<Scalars['Int']['input']>;
  /** The provided delivery date for the Order */
  requestDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** The shipping costs tax */
  tax?: InputMaybe<Scalars['Float']['input']>;
  /** The shipping costs tax percentage */
  taxPercentage?: InputMaybe<Scalars['Float']['input']>;
};

export type OrderResponse = {
  __typename?: 'OrderResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type Order */
  items: Array<Order>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type OrderSearchArguments = {
  /** Search by company ids */
  companyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of company sources */
  companySources?: InputMaybe<Array<SourceInput>>;
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** Only search for orders with export status */
  exportStatuses?: InputMaybe<Array<OrderExportStatus>>;
  /** Search by export date */
  exportedAt?: InputMaybe<DateSearchInput>;
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Pagination offset number */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Search by original order ids */
  originalOrderIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Pagination page number */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Search by price range */
  price?: InputMaybe<DecimalSearchInput>;
  /** Inputs to sort by */
  sortInputs?: InputMaybe<Array<OrderSortInput>>;
  /** Only search for orders in these statuses */
  status?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search term */
  term?: InputMaybe<Scalars['String']['input']>;
  /** List of fields to search in */
  termFields?: InputMaybe<Array<OrderSearchFields>>;
  /** Only search for orders of the types */
  type?: InputMaybe<Array<OrderType>>;
  /** Pass one order more userId's to retrieve order for */
  userId?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Available fields to search for in an order */
export enum OrderSearchFields {
  /** Accounting Unique Identifier */
  AccountingId = 'ACCOUNTING_ID',
  /** Debtor Unique Identifier */
  DebtorId = 'DEBTOR_ID',
  /** External Order Unique Identifier */
  ExternalOrderId = 'EXTERNAL_ORDER_ID',
  /** Extra field 3 */
  Extra3 = 'EXTRA3',
  /** Extra field 4 */
  Extra4 = 'EXTRA4',
  Id = 'ID',
  /** Debtor Company [From Invoice Address] */
  InvoiceAddressCompany = 'INVOICE_ADDRESS_COMPANY',
  /** Debtor First Name [From Invoice Address] */
  InvoiceAddressFirstName = 'INVOICE_ADDRESS_FIRST_NAME',
  /** Debtor Last Name [From Invoice Address] */
  InvoiceAddressLastName = 'INVOICE_ADDRESS_LAST_NAME',
  /** Item EAN Code */
  ItemEanCode = 'ITEM_EAN_CODE',
  /** Item Manufacturer */
  ItemManufacturer = 'ITEM_MANUFACTURER',
  /** Item Manufacturer Code */
  ItemManufacturerCode = 'ITEM_MANUFACTURER_CODE',
  /** Item Name */
  ItemName = 'ITEM_NAME',
  /** Item Notes */
  ItemNotes = 'ITEM_NOTES',
  /** Item SKU */
  ItemSku = 'ITEM_SKU',
  /** Item Supplier */
  ItemSupplier = 'ITEM_SUPPLIER',
  /** Item Supplier Code */
  ItemSupplierCode = 'ITEM_SUPPLIER_CODE',
  /** Recipient Company [From Delivery Address] */
  RecipientCompany = 'RECIPIENT_COMPANY',
  /** Recipient Email [From Delivery Address] */
  RecipientEmail = 'RECIPIENT_EMAIL',
  /** Recipient First Name [From Delivery Address] */
  RecipientFirstName = 'RECIPIENT_FIRST_NAME',
  /** Recipient Last Name [From Delivery Address] */
  RecipientLastName = 'RECIPIENT_LAST_NAME',
  /** Reference */
  Reference = 'REFERENCE',
  /** Remarks */
  Remarks = 'REMARKS',
}

export type OrderSetStatusInput = {
  /** Add the order overview as attached PDF to the order confirmation email */
  addPDFAttachment?: InputMaybe<Scalars['Boolean']['input']>;
  /** Delete the cart that created this order if the cart is still available */
  deleteCart?: InputMaybe<Scalars['Boolean']['input']>;
  /** The id of the order to set the status for */
  orderId: Scalars['Int']['input'];
  /** The payment status of the order */
  payStatus?: InputMaybe<Scalars['String']['input']>;
  /** Send the order confirmation email when the status is changed */
  sendOrderConfirmationEmail?: InputMaybe<Scalars['Boolean']['input']>;
  /** The status of the order */
  status?: InputMaybe<Scalars['String']['input']>;
};

export enum OrderSortField {
  Company = 'COMPANY',
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  LastModifiedAt = 'LAST_MODIFIED_AT',
  Status = 'STATUS',
  TotalGross = 'TOTAL_GROSS',
}

export type OrderSortInput = {
  /** Field to sort by */
  field: OrderSortField;
  /** Order option to sort by. [Default to `ASC`] */
  order?: SortOrder;
};

export type OrderStatus = {
  __typename?: 'OrderStatus';
  /** The order status code value */
  code: Scalars['String']['output'];
  /** The order status creation date */
  createdAt: Scalars['DateTime']['output'];
  /** The order status description */
  description?: Maybe<Scalars['String']['output']>;
  /** The primary order status identifier */
  id: Scalars['Int']['output'];
  /** Marks if the order can be archived */
  isArchivable?: Maybe<Scalars['Boolean']['output']>;
  /** Marks if the order is confirmable */
  isConfirmable?: Maybe<Scalars['Boolean']['output']>;
  /** Marks if the order status is default */
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  /** Marks if the order is deletable */
  isDeletable?: Maybe<Scalars['Boolean']['output']>;
  /** Marks if the order is editable */
  isEditable?: Maybe<Scalars['Boolean']['output']>;
  /** Marks if the order is exportable */
  isExportable?: Maybe<Scalars['Boolean']['output']>;
  /** Marks if the order is public */
  isPublic?: Maybe<Scalars['Boolean']['output']>;
  /** The order status last modification date */
  lastModifiedAt: Scalars['DateTime']['output'];
  /** The order status name */
  name: Scalars['String']['output'];
  nextStatuses: OrderStatusesResponse;
  /** The order status for order type */
  orderType: OrderStatusOrderType;
  previousStatuses: OrderStatusesResponse;
  /** The order status priority */
  priority?: Maybe<Scalars['Int']['output']>;
  /** The order status set the current status belongs to */
  statusSet?: Maybe<OrderStatusSet>;
  /** The order status type */
  type: OrderStatusType;
};

export type OrderStatusNextStatusesArgs = {
  input?: InputMaybe<OrderStatusesSearchInput>;
};

export type OrderStatusPreviousStatusesArgs = {
  input?: InputMaybe<OrderStatusesSearchInput>;
};

export enum OrderStatusOrderType {
  Order = 'ORDER',
  Quotation = 'QUOTATION',
  Request = 'REQUEST',
}

export type OrderStatusSearchByInput = {
  /** Order status code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Order status unique identifier. */
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type OrderStatusSet = {
  __typename?: 'OrderStatusSet';
  /** The order status set description */
  description?: Maybe<Scalars['String']['output']>;
  /** The primary order status set identifier */
  id: Scalars['Int']['output'];
  /** The order status set name */
  name: Scalars['String']['output'];
  /** The order statuses in this set */
  orderStatuses?: Maybe<Array<OrderStatus>>;
};

export type OrderStatusSetSearchByInput = {
  /** Order status unique identifier. */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Order status name */
  name?: InputMaybe<Scalars['String']['input']>;
};

export enum OrderStatusSetSortField {
  Id = 'ID',
  Name = 'NAME',
}

export type OrderStatusSetSortInput = {
  /** Field to sort by */
  field: OrderStatusSetSortField;
  /** Order option to sort by. [Default to `ASC`] */
  order?: SortOrder;
};

export type OrderStatusSetsResponse = {
  __typename?: 'OrderStatusSetsResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type OrderStatusSet */
  items: Array<OrderStatusSet>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type OrderStatusSetsSearchInput = {
  /** Order status set name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Pagination offset number. [Default to `12`] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination page number. [Default to `1`] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Inputs to sort by */
  sortInputs?: InputMaybe<Array<OrderStatusSetSortInput>>;
};

export enum OrderStatusSortField {
  Code = 'CODE',
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  LastModifiedAt = 'LAST_MODIFIED_AT',
  Name = 'NAME',
  Priority = 'PRIORITY',
}

export type OrderStatusSortInput = {
  /** Field to sort by */
  field: OrderStatusSortField;
  /** Order option to sort by. [Default to `ASC`] */
  order?: SortOrder;
};

export enum OrderStatusType {
  Custom = 'CUSTOM',
  System = 'SYSTEM',
}

export type OrderStatusesResponse = {
  __typename?: 'OrderStatusesResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type OrderStatus */
  items: Array<OrderStatus>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type OrderStatusesSearchInput = {
  /** List of order status codes */
  codes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** List of order status ids */
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Marks if the order can be archived */
  isArchivable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is confirmable */
  isConfirmable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order status is default */
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is deletable */
  isDeletable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is editable */
  isEditable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is exportable */
  isExportable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is public */
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Order status name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Pagination offset number. [Default to `12`] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Order status for order type */
  orderType?: InputMaybe<Scalars['String']['input']>;
  /** Pagination page number. [Default to `1`] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Order status priority */
  priority?: InputMaybe<Scalars['Int']['input']>;
  /** Inputs to sort by */
  sortInputs?: InputMaybe<Array<OrderStatusSortInput>>;
  /** Order status type */
  type?: InputMaybe<Scalars['String']['input']>;
};

export type OrderTotalInput = {
  /** The type of global discount */
  discountType: OrderDiscountType;
  /** The discount value, can be absolute or a percentage depending on the discountType */
  discountValue: Scalars['Float']['input'];
  /** The total of this Order excluding tax */
  gross: Scalars['Float']['input'];
  /** The total of this Order including tax */
  net: Scalars['Float']['input'];
  /** The total amount of tax for this Order */
  tax: Scalars['Float']['input'];
};

export type OrderTotalTaxPercentage = {
  __typename?: 'OrderTotalTaxPercentage';
  /** The tax percentage */
  percentage: Scalars['Int']['output'];
  /** The total for the tax percentage */
  total: Scalars['Float']['output'];
};

export type OrderTotalUpdateInput = {
  /** The type of global discount */
  discountType?: InputMaybe<OrderDiscountType>;
  /** The discount value, can be absolute or a percentage depending on the discountType */
  discountValue?: InputMaybe<Scalars['Float']['input']>;
  /** The total of this Order excluding tax */
  gross?: InputMaybe<Scalars['Float']['input']>;
  /** The total of this Order including tax */
  net?: InputMaybe<Scalars['Float']['input']>;
  /** The total amount of tax for this Order */
  tax?: InputMaybe<Scalars['Float']['input']>;
};

export type OrderTotals = {
  __typename?: 'OrderTotals';
  /** The type of global discount */
  discountType: OrderDiscountType;
  /** The discount value, can be absolute or a percentage depending on the discountType */
  discountValue: Scalars['Float']['output'];
  /** The total of this Order excluding tax */
  gross: Scalars['Float']['output'];
  /** The total of this Order including tax */
  net: Scalars['Float']['output'];
  /** The orderId of this Order */
  orderId: Scalars['Int']['output'];
  /** The total amount of tax for this Order */
  tax: Scalars['Float']['output'];
  /** The totals per tax percentage */
  taxPercentages: Array<OrderTotalTaxPercentage>;
};

/** Available order types */
export enum OrderType {
  /** Dropshipment order */
  Dropshipment = 'dropshipment',
  /** Purchase order */
  Purchase = 'purchase',
  /** Quotation order */
  Quotation = 'quotation',
  /** Stock order */
  Stock = 'stock',
}

export type OrderUpdateAddressInput = {
  /** Address city */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Address code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** User company the address applies to */
  company?: InputMaybe<Scalars['String']['input']>;
  /** Address country */
  country?: InputMaybe<Scalars['String']['input']>;
  /** Address email [valid email format required] */
  email?: InputMaybe<Scalars['String']['input']>;
  /** User first name the address applies to */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** User gender the address applies to */
  gender?: InputMaybe<Gender>;
  /** ICP address field defines whether tax should be applied to an order if the address is selected as a delivery address. Used when shipping b2b order abroad */
  icp?: InputMaybe<YesNo>;
  /** User last name the address applies to */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** User middle name the address applies to */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Address mobile number [min: 6, max: 20] */
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** Address delivery notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** User street number */
  number?: InputMaybe<Scalars['String']['input']>;
  /** User street number extension */
  numberExtension?: InputMaybe<Scalars['String']['input']>;
  /** Address fixed phone number [min: 6, max: 20] */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Address postal code */
  postalCode?: InputMaybe<Scalars['String']['input']>;
  /** Address region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** User street address */
  street?: InputMaybe<Scalars['String']['input']>;
};

export type OrderUpdateInput = {
  /** User ID of the Account Manager of the order */
  accountManagerId?: InputMaybe<Scalars['Int']['input']>;
  /** Accounting ID of the Order */
  accountingId?: InputMaybe<Scalars['String']['input']>;
  /** Active actioncode that was applied to this Order  */
  actionCode?: InputMaybe<Scalars['String']['input']>;
  /** Channel ID the Order belongs to */
  channelId?: InputMaybe<Scalars['Int']['input']>;
  /** ID of the Company of the Order */
  companyId?: InputMaybe<Scalars['Int']['input']>;
  /** A company source */
  companySource?: InputMaybe<SourceInput>;
  /** Order currency - ISO 4217 currency code => https://www.xe.com/iso4217.php */
  currency?: InputMaybe<Scalars['String']['input']>;
  /** Currency conversion ratio compared the the Order's Shop's base currency */
  currencyRatio?: InputMaybe<Scalars['Float']['input']>;
  /** Company name of the Debtor [USED FOR ORDER SEARCH ONLY] */
  debtorCompany?: InputMaybe<Scalars['String']['input']>;
  /** First name of the Debtor [USED FOR ORDER SEARCH ONLY] */
  debtorFirstName?: InputMaybe<Scalars['String']['input']>;
  /** Debtor ID of the  User, Contact or Customer  */
  debtorId?: InputMaybe<Scalars['String']['input']>;
  /** Last name of the Debtor [USED FOR ORDER SEARCH ONLY] */
  debtorLastName?: InputMaybe<Scalars['String']['input']>;
  /** Email address used for communication about this Order */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Order export message */
  exportMessage?: InputMaybe<Scalars['String']['input']>;
  /** Order export status of this order */
  exportStatus?: InputMaybe<OrderExportStatus>;
  /** The date and time the order was exported */
  exportedAt?: InputMaybe<Scalars['String']['input']>;
  /** ID of the Order in an external system */
  externalId?: InputMaybe<Scalars['String']['input']>;
  /**
   * extra3: Open text field to add meta data on an Order.
   *     The field will not be visible to Propeller admins, but can be used to be displayed on front-ends or be used in integrations.
   */
  extra3?: InputMaybe<Scalars['String']['input']>;
  /**
   * extra4: Open text field to add meta data on an Order.
   *     The field will not be visible to Propeller admins, but can be used to be displayed on front-ends or be used in integrations.
   */
  extra4?: InputMaybe<Scalars['String']['input']>;
  /** ID of the User, Contact or Customer that should receive the invoice for this Order */
  invoiceUserId?: InputMaybe<Scalars['Int']['input']>;
  /** The OrderItem to create or update with the Order as a single bulk request */
  items?: InputMaybe<Array<CreateOrUpdateOrderItemInput>>;
  /** Order language - ISO 639-1 language code => https://localizely.com/iso-639-1-list/ */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Original order id */
  originalOrderId?: InputMaybe<Scalars['Int']['input']>;
  /** Order's payment data */
  paymentData?: InputMaybe<OrderPaymentUpdateInput>;
  /** Order's postage data */
  postageData?: InputMaybe<OrderPostageUpdateInput>;
  /** Company name of the Recipient [USED FOR ORDER SEARCH ONLY] */
  recipientCompany?: InputMaybe<Scalars['String']['input']>;
  /** First name of the Recipient [USED FOR ORDER SEARCH ONLY] */
  recipientFirstName?: InputMaybe<Scalars['String']['input']>;
  /** Last name of the Recipient [USED FOR ORDER SEARCH ONLY] */
  recipientLastName?: InputMaybe<Scalars['String']['input']>;
  /** Reference by the customer */
  reference?: InputMaybe<Scalars['String']['input']>;
  /** Remarks by the customer */
  remarks?: InputMaybe<Scalars['String']['input']>;
  /** Source of the Order i.e. webshop, internal, external or api */
  source?: InputMaybe<Scalars['String']['input']>;
  /** Status of the Order */
  status?: InputMaybe<Scalars['String']['input']>;
  /** Order's totals, tax and global discount */
  total?: InputMaybe<OrderTotalUpdateInput>;
  /** Type of the Order */
  type?: InputMaybe<OrderType>;
  /** ID of the User, Contact or Customer of the Order */
  userId?: InputMaybe<Scalars['Int']['input']>;
  /** Date and time until the order is valid */
  validUntil?: InputMaybe<Scalars['String']['input']>;
  /** Value points collected with this Order */
  valuePoints?: InputMaybe<Scalars['Int']['input']>;
};

export type Orderlist = {
  __typename?: 'Orderlist';
  /** Shows if the orderlist is active */
  active: YesNo;
  clusters?: Maybe<ProductsResponse>;
  /** Orderlist code */
  code?: Maybe<Scalars['String']['output']>;
  companies?: Maybe<Array<Company>>;
  /** Orderlist creation date */
  createdAt: Scalars['DateTime']['output'];
  /** Orderlist description per language */
  descriptions: Array<LocalizedString>;
  /** Extra field per language */
  extras?: Maybe<Array<LocalizedString>>;
  /** Orderlist primary identifier */
  id: Scalars['Int']['output'];
  /** Orderlist last modified date */
  lastModifiedAt: Scalars['DateTime']['output'];
  /** Partner entity */
  partnerEntity?: Maybe<Scalars['String']['output']>;
  products?: Maybe<ProductsResponse>;
  /** Orderlist type */
  type: OrderlistType;
  users?: Maybe<Array<IBaseUser>>;
  /** Valid from (Display only) */
  validFrom?: Maybe<Scalars['DateTime']['output']>;
  /** Valid to (Display only) */
  validTo?: Maybe<Scalars['DateTime']['output']>;
};

export type OrderlistClustersArgs = {
  input?: InputMaybe<ProductSearchInput>;
};

export type OrderlistProductsArgs = {
  input?: InputMaybe<ProductSearchInput>;
};

export type OrderlistCompaniesInput = {
  /** List of company ids */
  companyIds?: Array<Scalars['Int']['input']>;
  /** List of company sources */
  companySources?: InputMaybe<Array<SourceInput>>;
};

export type OrderlistCreateInput = {
  /** Shows if the orderlist is active */
  active?: InputMaybe<YesNo>;
  /** List of cluster ids */
  clusterIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of cluster sources */
  clusterSources?: InputMaybe<Array<SourceInput>>;
  /** Orderlist code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** List of company ids */
  companyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of company sources */
  companySources?: InputMaybe<Array<SourceInput>>;
  /** Orderlist descriptions per language */
  descriptions?: InputMaybe<Array<LocalizedStringInput>>;
  /** Extra field per language */
  extras?: InputMaybe<Array<LocalizedStringInput>>;
  /** Partner entity */
  partnerEntity?: InputMaybe<Scalars['String']['input']>;
  /** List of product ids */
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of product sources */
  productSources?: InputMaybe<Array<SourceInput>>;
  /** Orderlist type! One of: [POSITIVE, NEGATIVE] */
  type: OrderlistType;
  /** List of user ids */
  userIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of user sources */
  userSources?: InputMaybe<Array<SourceInput>>;
  /** Valid from (Display only) */
  validFrom?: InputMaybe<Scalars['String']['input']>;
  /** Valid to (Display only) */
  validTo?: InputMaybe<Scalars['String']['input']>;
};

export type OrderlistItemsInput = {
  /** List of cluster ids */
  clusterIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of cluster sources */
  clusterSources?: InputMaybe<Array<SourceInput>>;
  /** List of product ids */
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of product sources */
  productSources?: InputMaybe<Array<SourceInput>>;
};

export type OrderlistSearchInput = {
  /** Shows if the orderlist is active */
  active?: InputMaybe<YesNo>;
  /** List of cluster ids */
  clusterIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of cluster sources */
  clusterSources?: InputMaybe<Array<SourceInput>>;
  /** List of codes */
  codes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** List of company ids */
  companyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of company sources */
  companySources?: InputMaybe<Array<SourceInput>>;
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** List of Orderlist primary identifiers */
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Pagination offset number. [Default to `12`] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination page number. [Default to `1`] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Partner entity */
  partnerEntities?: InputMaybe<Array<Scalars['String']['input']>>;
  /** List of product ids */
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of product sources */
  productSources?: InputMaybe<Array<SourceInput>>;
  /** Inputs to sort by */
  sortInputs?: InputMaybe<Array<OrderlistSortInput>>;
  /** Orderlist type. One of: [POSITIVE, NEGATIVE] */
  type?: InputMaybe<OrderlistType>;
  /** List of user ids */
  userIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of user sources */
  userSources?: InputMaybe<Array<SourceInput>>;
  /** Search by valid from */
  validFrom?: InputMaybe<DateSearchInput>;
  /** Search by valid to */
  validTo?: InputMaybe<DateSearchInput>;
};

export enum OrderlistSortField {
  Active = 'ACTIVE',
  CreatedAt = 'CREATED_AT',
  Descriptions = 'DESCRIPTIONS',
  Id = 'ID',
  LastModifiedAt = 'LAST_MODIFIED_AT',
  PartnerEntity = 'PARTNER_ENTITY',
}

export type OrderlistSortInput = {
  /** Field to sort by */
  field: OrderlistSortField;
  /** Order option to sort by. [Default to `ASC`] */
  order?: SortOrder;
};

export enum OrderlistType {
  Negative = 'NEGATIVE',
  Positive = 'POSITIVE',
}

export type OrderlistUpdateInput = {
  /** Shows if the orderlist is active */
  active?: InputMaybe<YesNo>;
  /** List of cluster ids */
  clusterIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of cluster sources */
  clusterSources?: InputMaybe<Array<SourceInput>>;
  /** Orderlist code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** List of company ids */
  companyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of company sources */
  companySources?: InputMaybe<Array<SourceInput>>;
  /** Orderlist descriptions per language */
  descriptions?: InputMaybe<Array<LocalizedStringInput>>;
  /** Extra field per language */
  extras?: InputMaybe<Array<LocalizedStringInput>>;
  /** Partner entity */
  partnerEntity?: InputMaybe<Scalars['String']['input']>;
  /** List of product ids */
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of product sources */
  productSources?: InputMaybe<Array<SourceInput>>;
  /** Orderlist type! One of: [POSITIVE, NEGATIVE] */
  type?: InputMaybe<OrderlistType>;
  /** List of user ids */
  userIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of user sources */
  userSources?: InputMaybe<Array<SourceInput>>;
  /** Valid from (Display only) */
  validFrom?: InputMaybe<Scalars['String']['input']>;
  /** Valid to (Display only) */
  validTo?: InputMaybe<Scalars['String']['input']>;
};

export type OrderlistUsersInput = {
  /** List of user ids */
  userIds?: Array<Scalars['Int']['input']>;
  /** List of user sources */
  userSources?: InputMaybe<Array<SourceInput>>;
};

export type OrderlistsResponse = {
  __typename?: 'OrderlistsResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type Orderlist */
  items: Array<Orderlist>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

/**
 * The Orient parameter automatically applies optimal quality compression to produce an output image with as much visual fidelity as possible, while minimizing the file size.
 *
 *   NOTE:
 *   1. Orient is currently supported by the folring output formats: JPEG, WebP.
 *   2. If the quality parameter is also provided, quality overrides Orient because it is more precise.
 *   3. Because Orient attempts to produce an output image with as much visual quality as possible while minimizing the file size, the operations applied are subject to change.
 */
export enum Orient {
  /** Orient the image left. */
  Eight = 'EIGHT',
  /** Flip the image horizontally, then orient the image left (also rv or vr). */
  Five = 'FIVE',
  /** Flip the image vertically. */
  Four = 'FOUR',
  /** Flip the image horizontally. */
  HorizontalFlip = 'HORIZONTAL_FLIP',
  /** Flip the image both horizontally and vertically (also vh). */
  HvFlip = 'HV_FLIP',
  /** Orient the image left. */
  Left = 'LEFT',
  /** Default - don't do anything... */
  One = 'ONE',
  /** Orient the image right. */
  Right = 'RIGHT',
  /** Flip the image horizontally, then orient the image right (also lv or vl). */
  Seven = 'SEVEN',
  /** Orient the image right. */
  Six = 'SIX',
  /** Flip the image horizontally and vertically. */
  Three = 'THREE',
  /** Flip the image horizontally. */
  Two = 'TWO',
  /** Flip the image horizontally. */
  VerticalFlip = 'VERTICAL_FLIP',
}

export type PadInput = {
  /** Bottom - Pixels or Percentage - Default=0 */
  bottom?: InputMaybe<Scalars['Int']['input']>;
  /** Left - Pixels or Percentage - Default=0 */
  left?: InputMaybe<Scalars['Int']['input']>;
  /** Right - Pixels or Percentage - Default=0 */
  right?: InputMaybe<Scalars['Int']['input']>;
  /** Top - Pixels or Percentage - Default=0 */
  top?: InputMaybe<Scalars['Int']['input']>;
};

export type PaginatedMediaAttachmentResponse = {
  __typename?: 'PaginatedMediaAttachmentResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type MediaAttachment */
  items: Array<MediaAttachment>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /**  The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type PaginatedMediaDocumentResponse = {
  __typename?: 'PaginatedMediaDocumentResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type MediaDocument */
  items: Array<MediaDocument>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /**  The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type PaginatedMediaImageResponse = {
  __typename?: 'PaginatedMediaImageResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type MediaImage */
  items: Array<MediaImage>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /**  The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type PaginatedMediaVideoResponse = {
  __typename?: 'PaginatedMediaVideoResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type MediaVideo */
  items: Array<MediaVideo>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /**  The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type PasswordRecoveryLinkInput = {
  /** The email address of the user to generate the recovery link for */
  email: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  /** The page the user will be redirected to after the user changed his/her password. */
  redirectUrl: Scalars['String']['input'];
};

export type PasswordResetLinkEmailInput = {
  /** Attachments to add to the email */
  attachments?: InputMaybe<Array<Base64FileInput>>;
  /** The content of the email, use when the content can not be derived from other inputs */
  content?: InputMaybe<Scalars['String']['input']>;
  /** The email address of the user to generate the recovery link for */
  email: Scalars['String']['input'];
  /** The sender of the email, use when the sender can not be derived from other inputs */
  from?: InputMaybe<EmailSendContactInput>;
  language?: InputMaybe<Scalars['String']['input']>;
  /** The id of the Propeller letter to use when type is 'campaign' */
  letterId?: InputMaybe<Scalars['String']['input']>;
  /** Text to display that will lead to link when clicked */
  linkText?: InputMaybe<Scalars['String']['input']>;
  /** The id of the order to use when triggering an event of type orderconfirm */
  orderId?: InputMaybe<Scalars['Int']['input']>;
  /** The page the user will be redirected to after the user changed his/her password. */
  redirectUrl: Scalars['String']['input'];
  /** The id of the site to use when sending the email, required when the site can not be derived from other inputs */
  siteId?: InputMaybe<Scalars['Int']['input']>;
  /** The subject of the email, use when the subject can not be derived from other inputs */
  subject?: InputMaybe<Scalars['String']['input']>;
  /** The type of email event to trigger */
  type: EmailEventType;
  /** The id of the user to send to email to. Can be used to replace to, when the user can't be derived from other inputs */
  userId?: InputMaybe<Scalars['Int']['input']>;
  /** Variables that can be used in the email template */
  variables?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type PayMethod = {
  __typename?: 'PayMethod';
  /** Creation date */
  createdAt: Scalars['DateTime']['output'];
  /** External code */
  externalCode: Scalars['String']['output'];
  /** Primary identifier */
  id: Scalars['Int']['output'];
  /** Last modified date */
  lastModifiedAt: Scalars['DateTime']['output'];
  /** Logo url */
  logo?: Maybe<Scalars['String']['output']>;
  /** Name per language */
  names: Array<LocalizedString>;
};

export type PayMethodCreateInput = {
  /** External code (Screaming Snake Case) */
  externalCode: Scalars['String']['input'];
  /** Logo url */
  logo?: InputMaybe<Scalars['String']['input']>;
  /** Name per language */
  names?: Array<LocalizedStringInput>;
};

export type PayMethodSearchInput = {
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** List of external codes */
  externalCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** List of primary identifiers */
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Pagination offset number. [Default to `12`] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination page number. [Default to `1`] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Inputs to sort by */
  sortInputs?: InputMaybe<Array<PayMethodSortInput>>;
};

export enum PayMethodSortField {
  CreatedAt = 'CREATED_AT',
  ExternalCode = 'EXTERNAL_CODE',
  Id = 'ID',
  LastModifiedAt = 'LAST_MODIFIED_AT',
}

export type PayMethodSortInput = {
  /** Field to sort by */
  field: PayMethodSortField;
  /** Order option to sort by. [Default to `ASC`] */
  order?: SortOrder;
};

export type PayMethodUpdateInput = {
  /** External code (Screaming Snake Case) */
  externalCode?: InputMaybe<Scalars['String']['input']>;
  /** Logo url */
  logo?: InputMaybe<Scalars['String']['input']>;
  /** Name per language */
  names?: InputMaybe<Array<LocalizedStringInput>>;
};

export type PayMethodsResponse = {
  __typename?: 'PayMethodsResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type PayMethod */
  items: Array<PayMethod>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type Payment = {
  __typename?: 'Payment';
  /** Payment amount [denomination in cents] */
  amount: Scalars['Int']['output'];
  /** Guest User ID */
  anonymousId?: Maybe<Scalars['Int']['output']>;
  /** Payment initial creation timestamp */
  createdAt: Scalars['DateTime']['output'];
  /** Reference to the user that created the payment initially */
  createdBy?: Maybe<Scalars['String']['output']>;
  /** Payment currency - ISO 4217 currency code => https://www.xe.com/iso4217.php */
  currency: Scalars['String']['output'];
  /** Payment primary identifier */
  id: Scalars['ID']['output'];
  /** Payment last update timestamp */
  lastModifiedAt: Scalars['DateTime']['output'];
  /** Reference to the user that last modified the payment */
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  /** Payment method used by the PSP */
  method: Scalars['String']['output'];
  /** Unique orderId reference for the Payment */
  orderId: Scalars['Int']['output'];
  /** Unique paymentId reference for the Payment - provided by the PSP */
  paymentId?: Maybe<Scalars['String']['output']>;
  /** Payment status */
  status: PaymentStatuses;
  /** Transaction(s) related to the specified payment */
  transactions?: Maybe<Array<Transaction>>;
  /** Logged in User ID */
  userId?: Maybe<Scalars['Int']['output']>;
};

/** Available payment statuses */
export enum PaymentStatuses {
  Authorized = 'AUTHORIZED',
  Cancelled = 'CANCELLED',
  Chargeback = 'CHARGEBACK',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  Open = 'OPEN',
  Paid = 'PAID',
  Pending = 'PENDING',
  Refunded = 'REFUNDED',
}

export type PaymentsResponse = {
  __typename?: 'PaymentsResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type Payment */
  items: Array<Payment>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type PaymentsSearchInput = {
  /** Pagination offset number. [Default to `12`] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination page number. [Default to `1`] */
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type Price = {
  __typename?: 'Price';
  /** The method used to apply discounts for bulk prices / volume discounts. */
  bulkPriceDiscountType: PriceDiscountType;
  /** The cost associated to acquire the product. */
  cost?: Maybe<Scalars['Float']['output']>;
  /** Creation date */
  createdAt: Scalars['DateTime']['output'];
  /** Default tax code */
  defaultTaxCode: Taxcode;
  /** Describes how the price should be displayed on the Front-End */
  display?: Maybe<PriceDisplay>;
  /** Primary identifier */
  id: Scalars['String']['output'];
  /** Last modified date */
  lastModifiedAt: Scalars['DateTime']['output'];
  /** The default sales price */
  list: Scalars['Float']['output'];
  /** The quantity of items that the listed price will purchase */
  per: Scalars['Int']['output'];
  /** Product Id */
  productId: Scalars['Int']['output'];
  /** The price at which the product is sold in physical stores. This might differ from the standard price due to store-specific deals, overhead costs, or regional pricing strategies */
  store?: Maybe<Scalars['Float']['output']>;
  /** The manufacturer's or supplier's recommended retail price (RRP) for the product. */
  suggested?: Maybe<Scalars['Float']['output']>;
};

export type PriceCalculateDefaultInput = {
  /** Product id */
  productIds: Array<Scalars['Int']['input']>;
  /** Tax zone */
  taxZone?: Scalars['String']['input'];
};

export type PriceCalculateInput = {
  /** Company id to calculate the price for */
  companyId?: InputMaybe<Scalars['Int']['input']>;
  /** Contact id to calculate the price for */
  contactId?: InputMaybe<Scalars['Int']['input']>;
  /** Customer id to calculate the price for */
  customerId?: InputMaybe<Scalars['Int']['input']>;
  /** Product id */
  productId: Scalars['Int']['input'];
  /** Quantity */
  quantity?: Scalars['Int']['input'];
  /** Tax zone */
  taxZone?: Scalars['String']['input'];
};

export type PriceCalculateProductInput = {
  /** Company id to calculate the price for */
  companyId?: InputMaybe<Scalars['Int']['input']>;
  /** Contact id to calculate the price for */
  contactId?: InputMaybe<Scalars['Int']['input']>;
  /** Customer id to calculate the price for */
  customerId?: InputMaybe<Scalars['Int']['input']>;
  /** Tax zone */
  taxZone?: InputMaybe<Scalars['String']['input']>;
};

export type PriceCreateInput = {
  /** The method used to apply discounts for bulk prices / volume discounts. One of: [costpriceplus, listpricemin, netprice] */
  bulkDiscountType?: InputMaybe<PriceDiscountType>;
  /** The cost associated to acquire the product. */
  cost?: InputMaybe<Scalars['Float']['input']>;
  /** `Default tax code of this product One of: [H, L, N] */
  defaultTaxCode: Taxcode;
  /** Describes how the price should be displayed on the Front-End */
  display?: InputMaybe<PriceDisplay>;
  /** The default sales price */
  list: Scalars['Float']['input'];
  /** The quantity of items that the listed price will purchase */
  per?: InputMaybe<Scalars['Int']['input']>;
  /** Product id */
  productId: Scalars['Int']['input'];
  /** The price at which the product is sold in physical stores. This might differ from the standard price due to store-specific deals, overhead costs, or regional pricing strategies */
  store?: InputMaybe<Scalars['Float']['input']>;
  /** The manufacturer's or supplier's recommended retail price (RRP) for the product. */
  suggested?: InputMaybe<Scalars['Float']['input']>;
};

/** The type of discount calculation used to calculate the price for a product */
export enum PriceDiscountType {
  /** Cost price plus a percentage */
  CostPricePlus = 'COST_PRICE_PLUS',
  /** List price minus a percentage */
  ListPriceMin = 'LIST_PRICE_MIN',
  /** Fixed amount */
  NetPrice = 'NET_PRICE',
}

export enum PriceDisplay {
  /** Too blue. */
  Default = 'DEFAULT',
  /** Price from [Cheapest price]. */
  From = 'FROM',
  /** Old price [Suggested price] new price [Sale price] */
  FromFor = 'FROM_FOR',
  /** List price [Suggested price] our price [Sale price] */
  ListpriceOurprice = 'LISTPRICE_OURPRICE',
  /** Price on request */
  OnRequest = 'ON_REQUEST',
  /** Price [Sale price/Order unit] per [Order UOM]. */
  Package = 'PACKAGE',
  /** Price from [Cheapest price/Package unit] per [Package UOM] */
  PerUom = 'PER_UOM',
}

export enum PriceElementType {
  BulkCostPrice = 'BULK_COST_PRICE',
  BulkSalesPrice = 'BULK_SALES_PRICE',
  Default = 'DEFAULT',
  Pricesheet = 'PRICESHEET',
}

/** Indicates where the price is calculated. */
export enum PriceMode {
  /** Price is set externally */
  External = 'EXTERNAL',
  /** Price is calculated based on product price/postage rules set in Propeller. */
  Platform = 'PLATFORM',
}

export type PriceResponse = {
  __typename?: 'PriceResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type Price */
  items: Array<Price>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type PriceSearchInput = {
  /** Bulk discount type. One of: [costpriceplus, listpricemin, netprice] */
  bulkDiscountType?: InputMaybe<PriceDiscountType>;
  /** Search for cost price */
  cost?: InputMaybe<DecimalSearchInput>;
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** Search by ids */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Search for list price */
  list?: InputMaybe<DecimalSearchInput>;
  /** Pagination offset number */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination page number */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Search for price per */
  per?: InputMaybe<Scalars['Int']['input']>;
  /** Search by product ids */
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Inputs to sort by */
  sortInputs?: InputMaybe<Array<PriceSortInput>>;
  /** Search for store price */
  store?: InputMaybe<DecimalSearchInput>;
  /** Search for suggested price */
  suggested?: InputMaybe<DecimalSearchInput>;
};

export enum PriceSortField {
  BulkPriceDiscountType = 'BULK_PRICE_DISCOUNT_TYPE',
  CostPrice = 'COST_PRICE',
  CreatedAt = 'CREATED_AT',
  DefaultTaxCode = 'DEFAULT_TAX_CODE',
  Id = 'ID',
  LastModifiedAt = 'LAST_MODIFIED_AT',
  Price = 'PRICE',
  PricePer = 'PRICE_PER',
  ProductId = 'PRODUCT_ID',
  StorePrice = 'STORE_PRICE',
  SuggestedPrice = 'SUGGESTED_PRICE',
}

export type PriceSortInput = {
  /** Field to sort by */
  field: PriceSortField;
  order: SortOrder;
};

export type PriceUpdateInput = {
  /** The method used to apply discounts for bulk prices / volume discounts. One of: [costpriceplus, listpricemin, netprice] */
  bulkDiscountType?: InputMaybe<PriceDiscountType>;
  /** The cost associated to acquire the product. */
  cost?: InputMaybe<Scalars['Float']['input']>;
  /** `Default tax code of this product One of: [H, L, N] */
  defaultTaxCode?: InputMaybe<Taxcode>;
  /** Describes how the price should be displayed on the Front-End */
  display?: InputMaybe<PriceDisplay>;
  /** The default sales price */
  list?: InputMaybe<Scalars['Float']['input']>;
  /** The quantity of items that the listed price will purchase */
  per?: InputMaybe<Scalars['Int']['input']>;
  /** The price at which the product is sold in physical stores. This might differ from the standard price due to store-specific deals, overhead costs, or regional pricing strategies */
  store?: InputMaybe<Scalars['Float']['input']>;
  /** The manufacturer's or supplier's recommended retail price (RRP) for the product. */
  suggested?: InputMaybe<Scalars['Float']['input']>;
};

export type Pricesheet = {
  __typename?: 'Pricesheet';
  /** Pricesheet code */
  code: Scalars['String']['output'];
  companies?: Maybe<Array<Company>>;
  contacts?: Maybe<Array<Contact>>;
  /** Creation date */
  createdAt: Scalars['DateTime']['output'];
  customers?: Maybe<Array<Customer>>;
  /** Pricesheet description */
  descriptions?: Maybe<Array<LocalizedString>>;
  /** Primary identifier */
  id: Scalars['String']['output'];
  /** Last modified date */
  lastModifiedAt: Scalars['DateTime']['output'];
  /** Pricesheet name */
  names?: Maybe<Array<LocalizedString>>;
  /** Pricesheet priority */
  priority: Scalars['Int']['output'];
  /** Readonly */
  readonly: Scalars['Boolean']['output'];
  usergroups: Array<Scalars['String']['output']>;
};

export type PricesheetAssignInput = {
  /** Company ids */
  companyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Contact ids */
  contactIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Customer ids */
  customerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Usergroups */
  usergroups?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PricesheetCreateInput = {
  /** Pricesheet code */
  code: Scalars['String']['input'];
  /** Pricesheet description */
  descriptions?: InputMaybe<Array<LocalizedStringInput>>;
  /** Pricesheet name */
  names?: InputMaybe<Array<LocalizedStringInput>>;
  /** Pricesheet priority */
  priority?: Scalars['Int']['input'];
  /** Readonly */
  readonly?: Scalars['Boolean']['input'];
};

export type PricesheetResponse = {
  __typename?: 'PricesheetResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type Pricesheet */
  items: Array<Pricesheet>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type PricesheetSearchInput = {
  /** Search by pricesheet code */
  codes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search by company ids */
  companyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Search by contact ids */
  contactIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** Search by customer ids */
  customerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Search by ids */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Search by pricesheet name */
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Pagination offset number */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination page number */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Search by pricesheet priority */
  priority?: InputMaybe<NumberSearchInput>;
  /** Search by pricesheet readonly */
  readonly?: InputMaybe<Scalars['Boolean']['input']>;
  /** Inputs to sort by */
  sortInputs?: InputMaybe<Array<PricesheetSortInput>>;
};

export enum PricesheetSortField {
  Code = 'CODE',
  CreatedAt = 'CREATED_AT',
  Description = 'DESCRIPTION',
  Id = 'ID',
  LastModifiedAt = 'LAST_MODIFIED_AT',
  Name = 'NAME',
  Priority = 'PRIORITY',
  Readonly = 'READONLY',
  Type = 'TYPE',
}

export type PricesheetSortInput = {
  /** Field to sort by */
  field: PricesheetSortField;
  order: SortOrder;
};

export type PricesheetUnassignInput = {
  /** Company ids */
  companyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Contact ids */
  contactIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Customer ids */
  customerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Usergroups */
  usergroups?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PricesheetUpdateInput = {
  /** Pricesheet code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Pricesheet description */
  descriptions?: InputMaybe<Array<LocalizedStringInput>>;
  /** Pricesheet name */
  names?: InputMaybe<Array<LocalizedStringInput>>;
  /** Pricesheet priority */
  priority?: InputMaybe<Scalars['Int']['input']>;
  /** Readonly */
  readonly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Product = IBaseProduct &
  IProduct &
  IResource & {
    __typename?: 'Product';
    /** Lists attributes for this product based on the search input. */
    attributes?: Maybe<AttributeResultResponse>;
    /** The bar code of this product */
    barCode?: Maybe<Scalars['String']['output']>;
    bulkPrices?: Maybe<Array<ProductPrice>>;
    bundles?: Maybe<Array<Bundle>>;
    category?: Maybe<Category>;
    categoryId: Scalars['Int']['output'];
    categoryPath: Array<Category>;
    /** The class of the request resource */
    class: ProductClass;
    cluster?: Maybe<Cluster>;
    /** The cluster ID of the product's container */
    clusterId?: Maybe<Scalars['Int']['output']>;
    /** The container class of this product. */
    containerClass: ProductContainerClass;
    /** The creation date of this product */
    createdAt: Scalars['DateTime']['output'];
    crossupsellsFrom: CrossupsellsResponse;
    crossupsellsTo: CrossupsellsResponse;
    /** Additional keywords of this product per language. Added via the PIM */
    customKeywords?: Maybe<Array<LocalizedString>>;
    /** The default language for this product or cluster, this is the language to fall back too, when there is no translation available in a specific language. */
    defaultLanguage: Scalars['String']['output'];
    descriptions: Array<LocalizedString>;
    /** The European Article Number (EAN) code of this product */
    eanCode: Scalars['String']['output'];
    /** The quantity that provides the best value for money for this product */
    economicOrderQuantity: Scalars['Int']['output'];
    favoriteLists?: Maybe<FavoriteListsResponse>;
    /** Is this product part of any product bundles. */
    hasBundle: YesNo;
    /** The hidden status of this resource. */
    hidden: YesNo;
    /** Resource primary identifier */
    id: Scalars['Int']['output'];
    /** Product inventory */
    inventory?: Maybe<ProductInventory>;
    /** Is this product leader of any product bundle. */
    isBundleLeader: YesNo;
    /** Additional keywords of this product per language. Added by the supplier */
    keywords?: Maybe<Array<LocalizedString>>;
    /**
     * The requested language for this this resource, default to the
     *           products default language.
     *           ISO 639-1 format.
     */
    language?: Maybe<Scalars['String']['output']>;
    /** The last modified date of this product */
    lastModifiedAt: Scalars['DateTime']['output'];
    /** The manufacturer of this product */
    manufacturer: Scalars['String']['output'];
    /** The Original Equipment Manufacturer code (OEM) of this product */
    manufacturerCode: Scalars['String']['output'];
    /** Product media images */
    media?: Maybe<ProductMedia>;
    /** The minimum quantity of products that can be ordered */
    minimumQuantity: Scalars['Float']['output'];
    names: Array<LocalizedString>;
    /** Available offers for this Product and Customer/Contact. First item in the array should be regarded as the active price */
    offers?: Maybe<Array<ProductOffer>>;
    /** Is this product orderable */
    orderable: YesNo;
    /** The orderable from date of this product */
    orderableFrom?: Maybe<Scalars['DateTime']['output']>;
    /** The orderable to date of this product */
    orderableTo?: Maybe<Scalars['DateTime']['output']>;
    orderlists: OrderlistsResponse;
    /** The package type of this product. i.e.: PIECE, BOX, BLISTER */
    package: Scalars['String']['output'];
    /** The package description of this product per language */
    packageDescriptions?: Maybe<Array<LocalizedString>>;
    /** The amount of items per package */
    packageUnit: Scalars['String']['output'];
    /** The quantifier for the package unit, i.e.: PIECE or BOX */
    packageUnitQuantity: Scalars['String']['output'];
    /** Is it a physical product or a service, download or warranty */
    physical: YesNo;
    price?: Maybe<ProductPrice>;
    priceData?: Maybe<Price>;
    /** The price group the product belongs to, this field can be used for price calculations */
    priceGroup?: Maybe<Scalars['String']['output']>;
    /** This product's unique identifier */
    productId: Scalars['Int']['output'];
    /** The minimum quantity of products that can be ordered when creating a purchase order */
    purchaseMinimumQuantity: Scalars['Int']['output'];
    /** The unit in which the product can be ordered when creating a purchase order */
    purchaseUnit: Scalars['Int']['output'];
    /** The release date of this product */
    releaseDate?: Maybe<Scalars['DateTime']['output']>;
    /** Is this product returnable */
    returnable: YesNo;
    shortDescriptions: Array<LocalizedString>;
    /** The short name for the product, used for abbreviated orderlines */
    shortName: Scalars['String']['output'];
    /** The SKU (stock keeping unit) of this product */
    sku: Scalars['String']['output'];
    slugs: Array<LocalizedString>;
    /** The status of this product */
    status: ProductStatus;
    /** The extra status of this product, commonly linked to a custom valueset */
    statusExtra?: Maybe<Scalars['String']['output']>;
    /** The supplier of this product */
    supplier: Scalars['String']['output'];
    /** The supplier code of this product */
    supplierCode: Scalars['String']['output'];
    surcharges: Array<Surcharge>;
    /** The taxonomy the product belongs to */
    taxonomy?: Maybe<Scalars['String']['output']>;
    /** The turnover group that classifies the product within the financial structure */
    turnoverGroup?: Maybe<Scalars['String']['output']>;
    /** The unit in which the product can be ordered */
    unit: Scalars['Int']['output'];
  };

export type ProductAttributesArgs = {
  input?: InputMaybe<AttributeResultSearchInput>;
};

export type ProductBulkPricesArgs = {
  taxZone?: Scalars['String']['input'];
};

export type ProductBundlesArgs = {
  taxZone?: InputMaybe<Scalars['String']['input']>;
};

export type ProductCrossupsellsFromArgs = {
  input?: InputMaybe<CrossupsellSearchInput>;
};

export type ProductCrossupsellsToArgs = {
  input?: InputMaybe<CrossupsellSearchInput>;
};

export type ProductDescriptionsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type ProductFavoriteListsArgs = {
  input?: InputMaybe<FavoriteListsSearchInput>;
};

export type ProductNamesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type ProductOrderlistsArgs = {
  input?: InputMaybe<OrderlistSearchInput>;
};

export type ProductPriceArgs = {
  input?: InputMaybe<PriceCalculateProductInput>;
};

export type ProductShortDescriptionsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type ProductSlugsArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type ProductSurchargesArgs = {
  input?: InputMaybe<SurchargeSearchInput>;
};

export type ProductAttribute = Attribute & {
  __typename?: 'ProductAttribute';
  attributeDescription?: Maybe<AttributeDescription>;
  /** The date when the attribute was created */
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the user that created the attribute */
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** The ID of the attribute */
  id: Scalars['String']['output'];
  /** The date when the attribute was last modified */
  lastModifiedAt: Scalars['DateTime']['output'];
  /** The ID of the user that last modified the attribute */
  lastModifiedBy?: Maybe<Scalars['Int']['output']>;
  /** The ID of the product this attribute belongs to */
  productId: Scalars['Int']['output'];
  /** The value of the attribute */
  value: AttributeValue;
};

/** Available product classes */
export enum ProductClass {
  Cluster = 'CLUSTER',
  Product = 'PRODUCT',
}

/** Possible container classes for a product. */
export enum ProductContainerClass {
  Category = 'CATEGORY',
  Cluster = 'CLUSTER',
}

export type ProductInventory = {
  __typename?: 'ProductInventory';
  /** Inventory items */
  balance?: Maybe<Array<Inventory>>;
  /** Local product quantity */
  localQuantity: Scalars['Int']['output'];
  /** Inventory estimated next delivery date */
  nextDeliveryDate?: Maybe<Scalars['DateTime']['output']>;
  /** Inventory product identifier */
  productId: Scalars['Int']['output'];
  /** Supplier product quantity */
  supplierQuantity: Scalars['Int']['output'];
  /** Total product quantity */
  totalQuantity: Scalars['Int']['output'];
};

export type ProductMedia = {
  __typename?: 'ProductMedia';
  /** Product media documents */
  documents?: Maybe<PaginatedMediaDocumentResponse>;
  /** Product media images */
  images?: Maybe<PaginatedMediaImageResponse>;
  /** Product media videos */
  videos?: Maybe<PaginatedMediaVideoResponse>;
};

export type ProductMediaDocumentsArgs = {
  search?: InputMaybe<MediaDocumentProductSearchInput>;
};

export type ProductMediaImagesArgs = {
  search?: InputMaybe<MediaImageProductSearchInput>;
};

export type ProductMediaVideosArgs = {
  search?: InputMaybe<MediaVideoProductSearchInput>;
};

export type ProductOffer = IProductOffer & {
  __typename?: 'ProductOffer';
  formula: Scalars['String']['output'];
  /** The id of the offer. */
  id: Scalars['String']['output'];
  /** The calculated price for this offer. */
  price: Scalars['Float']['output'];
  validFrom: Scalars['DateTime']['output'];
  validTo: Scalars['DateTime']['output'];
};

export type ProductPrice = {
  __typename?: 'ProductPrice';
  cost?: Maybe<Scalars['Float']['output']>;
  discount?: Maybe<IDiscount>;
  discountType: PriceDiscountType;
  gross?: Maybe<Scalars['Float']['output']>;
  list?: Maybe<Scalars['Float']['output']>;
  net?: Maybe<Scalars['Float']['output']>;
  productId: Scalars['Int']['output'];
  taxCode?: Maybe<Taxcode>;
  type: PriceElementType;
};

export type ProductPriceFilterInput = {
  /** Price from filter */
  from: Scalars['Float']['input'];
  /** Price to filter */
  to: Scalars['Float']['input'];
};

export type ProductRangeFilterInput = {
  /** Range filter exclude */
  exclude?: Scalars['Boolean']['input'];
  /** Price from filter */
  from: Scalars['Float']['input'];
  /** ID of the attribute, either id or name is required */
  id?: InputMaybe<Scalars['String']['input']>;
  /** NAME of the attribute, either id or name is required */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Price to filter */
  to: Scalars['Float']['input'];
  /** Range filter type */
  type?: InputMaybe<AttributeType>;
};

export type ProductSearchInput = {
  /** List of product EAN codes */
  EANCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Enable or disable filtering for orderlists */
  applyOrderlists?: InputMaybe<Scalars['Boolean']['input']>;
  /** Product unique category identifier */
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  /** Product class */
  class?: InputMaybe<ProductClass>;
  /** List of clusterIds to search for */
  clusterIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Browse catalog as a specific Company. Permissions, orderlists, favorite will be merged  with the logged in user. */
  companyId?: InputMaybe<Scalars['Int']['input']>;
  /** Product path slug(s) */
  containerPathSlugs?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Product container slug(s) */
  containerSlugs?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** Get all descending products (true) or only the direct child products (false) of the given categoryId when supplied. */
  getDescendants?: InputMaybe<Scalars['Boolean']['input']>;
  /** Is product in a bundle */
  hasBundle?: InputMaybe<YesNo>;
  /** Is product hidden? */
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** List of unique product identifiers */
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Is product a bundle leader */
  isBundleLeader?: InputMaybe<YesNo>;
  /** Specify through which language to search in, has no effect on other returned fields (eg. names,slugs) */
  language?: Scalars['String']['input'];
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** List of product manufacturer codes */
  manufacturerCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** List of product manufacturers */
  manufacturers?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Pagination offset number [default=12] */
  offset?: Scalars['Int']['input'];
  /** List orderlists to apply */
  orderlistIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Pagination page number [default=1] */
  page?: Scalars['Int']['input'];
  /** Product path */
  path?: InputMaybe<Scalars['String']['input']>;
  /** Product price filter */
  price?: InputMaybe<ProductPriceFilterInput>;
  /** List of productIds to search for */
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of range filters */
  rangeFilters?: InputMaybe<Array<ProductRangeFilterInput>>;
  /** List of product search fields */
  searchFields?: InputMaybe<Array<SearchFieldsInput>>;
  /** List of product SKUs */
  skus?: InputMaybe<Array<Scalars['String']['input']>>;
  /** List of product sort filters */
  sortInputs?: InputMaybe<Array<ProductSortInput>>;
  /** List of product status filters */
  statuses?: Array<ProductStatus>;
  /** List of product supplier codes */
  supplierCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** List of product suppliers */
  suppliers?: InputMaybe<Array<Scalars['String']['input']>>;
  /** List of product tags */
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Product search term */
  term?: InputMaybe<Scalars['String']['input']>;
  /** List of text filters  */
  textFilters?: InputMaybe<Array<ProductTextFilterInput>>;
  /** Browse catalog as a specific User. Permissions, orderlists, favorite will be merged  with the logged in user. */
  userId?: InputMaybe<Scalars['Int']['input']>;
};

/** All fields that can be searched and boosted with the regular term input. */
export enum ProductSearchableField {
  BarCode = 'BAR_CODE',
  ClusterId = 'CLUSTER_ID',
  CustomKeywords = 'CUSTOM_KEYWORDS',
  Description = 'DESCRIPTION',
  EanCode = 'EAN_CODE',
  Keywords = 'KEYWORDS',
  Manufacturer = 'MANUFACTURER',
  ManufacturerCode = 'MANUFACTURER_CODE',
  Name = 'NAME',
  ProductId = 'PRODUCT_ID',
  ShortDescription = 'SHORT_DESCRIPTION',
  Sku = 'SKU',
  Supplier = 'SUPPLIER',
  SupplierCode = 'SUPPLIER_CODE',
}

/** Fields available for sorting */
export enum ProductSortField {
  CategoryOrder = 'CATEGORY_ORDER',
  CreatedAt = 'CREATED_AT',
  LastModifiedAt = 'LAST_MODIFIED_AT',
  Name = 'NAME',
  Price = 'PRICE',
  Relevance = 'RELEVANCE',
  ShortName = 'SHORT_NAME',
  Sku = 'SKU',
  SupplierCode = 'SUPPLIER_CODE',
}

export type ProductSortInput = {
  /** Available sortable fields */
  field: ProductSortField;
  /** Sort ordering ['desc' or 'asc'] */
  order?: InputMaybe<SortOrder>;
};

/** Available product statuses */
export enum ProductStatus {
  /** Available */
  A = 'A',
  /** Not available */
  N = 'N',
  /** Phase out */
  P = 'P',
  /** Restricted */
  R = 'R',
  /** Presale */
  S = 'S',
  /** Out of stock */
  T = 'T',
}

export type ProductTextFilterInput = {
  /** Text filter exclude */
  exclude?: Scalars['Boolean']['input'];
  /** ID of the attribute, either id or name is required */
  id?: InputMaybe<Scalars['String']['input']>;
  /** NAME of the attribute, either id or name is required */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Text filter type */
  type?: InputMaybe<AttributeType>;
  /** Text filter value */
  values: Array<Scalars['String']['input']>;
};

export type ProductsResponse = {
  __typename?: 'ProductsResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  filters?: Maybe<Array<AttributeFilter>>;
  /** List of items of type ProductResultUnion */
  items: Array<IBaseProduct>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The highest price of a product in this productlist */
  maxPrice: Scalars['Int']['output'];
  /** The lowest price of a product in this productlist */
  minPrice: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /**  The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type ProductsResponseFiltersArgs = {
  input?: InputMaybe<FilterAvailableAttributeInput>;
};

export type PublishEmailEventResponse = {
  __typename?: 'PublishEmailEventResponse';
  /** The  Google Pub/Sub message id */
  messageId?: Maybe<Scalars['String']['output']>;
  /** Did the publish event succeed? */
  success: Scalars['Boolean']['output'];
};

export type Query = {
  __typename?: 'Query';
  addressesByCompanyId: Array<Address>;
  addressesByCustomerId: Array<Address>;
  addressesByOrderId: Array<Address>;
  addressesByUserId: Array<Address>;
  adminUser: AdminUser;
  adminUserTenant: AdminUserTenant;
  adminUserViewer?: Maybe<AdminUser>;
  adminUsers: UserResponse;
  attribute: Attribute;
  attributeDescription: AttributeDescription;
  attributeDescriptions: AttributeDescriptionResponse;
  attributeResultByCategoryId: AttributeResultResponse;
  attributeResultByClusterId: AttributeResultResponse;
  attributeResultByCompanyId: AttributeResultResponse;
  attributeResultByContactId: AttributeResultResponse;
  attributeResultByCustomerId: AttributeResultResponse;
  attributeResultByProductId: AttributeResultResponse;
  attributes: AttributeResponse;
  attributesByProductId: AttributeResponse;
  /** Fetches a GCIP user by email address, used to check for existing email addresses in GCIP. */
  authentication: GcipUser;
  bulkCostPrices: BulkCostPriceResponse;
  bulkPrices: BulkPriceResponse;
  bundle: Bundle;
  bundles: BundlesResponse;
  /** Returns a business rule by its ID. */
  businessRule: BusinessRule;
  /** Returns a decisionTable by ruleId and tableId */
  businessRuleDecisionTable: BusinessRuleDecisionTable;
  businessRuleFieldDefinitions: BusinessRuleFieldDefinitionGroup;
  /** Returns the JDM Json representation of the business rule that can be use in the GoRules Zen Engine */
  businessRuleJDM: Scalars['JSONObject']['output'];
  /** Returns a paginated response of rules based on the provider filter */
  businessRules: BusinessRuleResponse;
  carrier: Carrier;
  carriers: CarriersResponse;
  cart: Cart;
  carts: CartResponse;
  categories: CategoryResponse;
  category: Category;
  channel: Channel;
  channels: Array<Channel>;
  cluster: Cluster;
  clusterConfig: ClusterConfigResponse;
  clusterConfigs: Array<ClusterConfigResponse>;
  companies: CompaniesResponse;
  company: Company;
  companySearch: CompanySearchResponse;
  contact: Contact;
  contacts: ContactsResponse;
  crossupsell: Crossupsell;
  crossupsells: CrossupsellsResponse;
  customer: Customer;
  customers: CustomersResponse;
  discount: Discount;
  discounts: DiscountResponse;
  externalAddress: ExternalAddress;
  /** Get favorite list by id */
  favoriteList: FavoriteList;
  /** Get favorite lists, use either an id or a source */
  favoriteLists: FavoriteListsResponse;
  incentiveRule: BusinessRule;
  inventory: InventoryResponse;
  /** Get a single SparePartsMachine, either by id, slug & langauge or by source & sourceId */
  machine?: Maybe<SparePartsMachine>;
  machines: SparePartsMachineResponse;
  media?: Maybe<Media>;
  order: Order;
  orderGetPDF: Base64File;
  /** @deprecated Deprecated, use mutation instead */
  orderSendConfirmationEmail: SendOrderConfirmResponseType;
  orderStatus: OrderStatus;
  orderStatusSet: OrderStatusSet;
  orderStatusSets: OrderStatusSetsResponse;
  orderStatuses: OrderStatusesResponse;
  orderlist: Orderlist;
  orderlists: OrderlistsResponse;
  orders: OrderResponse;
  payMethod: PayMethod;
  payMethods: PayMethodsResponse;
  payment?: Maybe<Payment>;
  payments: PaymentsResponse;
  price: Price;
  priceCalculate: ProductPrice;
  priceDefault: Array<ProductPrice>;
  priceExplain: Array<ProductPrice>;
  prices: PriceResponse;
  pricesheet: Pricesheet;
  pricesheets: PricesheetResponse;
  product: Product;
  /** Get all surcharges for a product */
  productSurcharges: SurchargeProductResponse;
  products: ProductsResponse;
  role: Role;
  roleDefinition: RoleDefinition;
  roleDefinitions: RoleDefinitionResponse;
  roles: RoleResponse;
  shop: Shop;
  shops: Array<Shop>;
  /** @deprecated Deprecated in favor of channel */
  site: Site;
  /** Get surcharge by id */
  surcharge: Surcharge;
  /** Get all surcharges */
  surcharges: SurchargesResponse;
  tax: Tax;
  taxes: TaxesResponse;
  tender: Tender;
  user: IBaseUser;
  usergroup: Usergroup;
  usergroups: UsergroupsResponse;
  valueset: Valueset;
  valuesetItems: ValuesetItemResponse;
  valuesets: ValuesetResponse;
  viewer: IBaseUser;
  warehouse: Warehouse;
  warehouses: WarehousesResponse;
  zoneTaxCode: ZoneTaxCode;
  zoneTaxCodes: ZoneTaxCodeResponse;
};

export type QueryAddressesByCompanyIdArgs = {
  companyId: Scalars['Float']['input'];
  type?: InputMaybe<AddressType>;
};

export type QueryAddressesByCustomerIdArgs = {
  customerId: Scalars['Float']['input'];
  type?: InputMaybe<AddressType>;
};

export type QueryAddressesByOrderIdArgs = {
  orderId: Scalars['Float']['input'];
  type?: InputMaybe<AddressType>;
};

export type QueryAddressesByUserIdArgs = {
  type?: InputMaybe<AddressType>;
  userId: Scalars['Float']['input'];
};

export type QueryAdminUserArgs = {
  email: Scalars['String']['input'];
};

export type QueryAdminUserTenantArgs = {
  id: Scalars['Int']['input'];
};

export type QueryAdminUsersArgs = {
  input?: InputMaybe<AdminUsersSearchInput>;
};

export type QueryAttributeArgs = {
  id: Scalars['String']['input'];
};

export type QueryAttributeDescriptionArgs = {
  id: Scalars['String']['input'];
};

export type QueryAttributeDescriptionsArgs = {
  input: AttributeDescriptionSearchInput;
};

export type QueryAttributeResultByCategoryIdArgs = {
  categoryId: Scalars['Int']['input'];
  input: AttributeResultSearchInput;
};

export type QueryAttributeResultByClusterIdArgs = {
  clusterId: Scalars['Int']['input'];
  input: AttributeResultSearchInput;
};

export type QueryAttributeResultByCompanyIdArgs = {
  companyId: Scalars['Int']['input'];
  input: AttributeResultSearchInput;
};

export type QueryAttributeResultByContactIdArgs = {
  contactId: Scalars['Int']['input'];
  input: AttributeResultSearchInput;
};

export type QueryAttributeResultByCustomerIdArgs = {
  customerId: Scalars['Int']['input'];
  input: AttributeResultSearchInput;
};

export type QueryAttributeResultByProductIdArgs = {
  input: AttributeResultSearchInput;
  productId: Scalars['Int']['input'];
};

export type QueryAttributesArgs = {
  input: AttributeSearchInput;
};

export type QueryAttributesByProductIdArgs = {
  input: AttributeSearchInput;
  productId: Scalars['Int']['input'];
};

export type QueryAuthenticationArgs = {
  email: Scalars['String']['input'];
};

export type QueryBulkCostPricesArgs = {
  input?: InputMaybe<BulkCostPriceSearchInput>;
};

export type QueryBulkPricesArgs = {
  input?: InputMaybe<BulkPriceSearchInput>;
};

export type QueryBundleArgs = {
  id: Scalars['String']['input'];
  taxZone?: InputMaybe<Scalars['String']['input']>;
};

export type QueryBundlesArgs = {
  input?: InputMaybe<BundleSearchInput>;
};

export type QueryBusinessRuleArgs = {
  id: Scalars['ID']['input'];
};

export type QueryBusinessRuleDecisionTableArgs = {
  input: BusinessRuleDecisionTableSearchInput;
};

export type QueryBusinessRuleFieldDefinitionsArgs = {
  name: Scalars['String']['input'];
};

export type QueryBusinessRuleJdmArgs = {
  id: Scalars['ID']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
};

export type QueryBusinessRulesArgs = {
  filter?: InputMaybe<BusinessRuleSearchInput>;
};

export type QueryCarrierArgs = {
  id: Scalars['Int']['input'];
};

export type QueryCarriersArgs = {
  input?: InputMaybe<CarriersSearchInput>;
};

export type QueryCartArgs = {
  id: Scalars['String']['input'];
};

export type QueryCartsArgs = {
  input: CartSearchInput;
};

export type QueryCategoriesArgs = {
  filter?: InputMaybe<CategorySearchInput>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryCategoryArgs = {
  categoryId?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryChannelArgs = {
  channelId: Scalars['Int']['input'];
};

export type QueryClusterArgs = {
  clusterId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type QueryClusterConfigArgs = {
  clusterConfigId: Scalars['Int']['input'];
};

export type QueryCompaniesArgs = {
  input?: InputMaybe<CompanySearchArguments>;
};

export type QueryCompanyArgs = {
  id: Scalars['Int']['input'];
};

export type QueryCompanySearchArgs = {
  input?: InputMaybe<CompanySearchInput>;
};

export type QueryContactArgs = {
  id: Scalars['Int']['input'];
};

export type QueryContactsArgs = {
  input?: InputMaybe<ContactSearchArguments>;
};

export type QueryCrossupsellArgs = {
  id: Scalars['String']['input'];
};

export type QueryCrossupsellsArgs = {
  input?: InputMaybe<CrossupsellSearchInput>;
};

export type QueryCustomerArgs = {
  id: Scalars['Int']['input'];
};

export type QueryCustomersArgs = {
  input?: InputMaybe<CustomerSearchArguments>;
};

export type QueryDiscountArgs = {
  id: Scalars['String']['input'];
};

export type QueryDiscountsArgs = {
  input?: InputMaybe<DiscountSearchInput>;
};

export type QueryExternalAddressArgs = {
  id: Scalars['Float']['input'];
};

export type QueryFavoriteListArgs = {
  id: Scalars['String']['input'];
};

export type QueryFavoriteListsArgs = {
  input?: InputMaybe<FavoriteListsSearchInput>;
};

export type QueryInventoryArgs = {
  id: Scalars['Int']['input'];
};

export type QueryMachineArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryMachinesArgs = {
  input?: InputMaybe<SearchSparePartsMachineInput>;
};

export type QueryOrderArgs = {
  orderId?: InputMaybe<Scalars['Int']['input']>;
  orderUUID?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOrderGetPdfArgs = {
  orderId: Scalars['Int']['input'];
};

export type QueryOrderSendConfirmationEmailArgs = {
  attachments?: InputMaybe<Array<Base64FileInput>>;
  orderId: Scalars['Int']['input'];
};

export type QueryOrderStatusArgs = {
  input: OrderStatusSearchByInput;
};

export type QueryOrderStatusSetArgs = {
  input: OrderStatusSetSearchByInput;
};

export type QueryOrderStatusSetsArgs = {
  input?: InputMaybe<OrderStatusSetsSearchInput>;
};

export type QueryOrderStatusesArgs = {
  input?: InputMaybe<OrderStatusesSearchInput>;
};

export type QueryOrderlistArgs = {
  id: Scalars['Int']['input'];
};

export type QueryOrderlistsArgs = {
  input?: InputMaybe<OrderlistSearchInput>;
};

export type QueryOrdersArgs = {
  input: OrderSearchArguments;
};

export type QueryPayMethodArgs = {
  id: Scalars['Int']['input'];
};

export type QueryPayMethodsArgs = {
  input?: InputMaybe<PayMethodSearchInput>;
};

export type QueryPaymentArgs = {
  searchBy: SearchByInput;
};

export type QueryPaymentsArgs = {
  input?: InputMaybe<PaymentsSearchInput>;
};

export type QueryPriceArgs = {
  id: Scalars['String']['input'];
};

export type QueryPriceCalculateArgs = {
  input: PriceCalculateInput;
};

export type QueryPriceDefaultArgs = {
  input: PriceCalculateDefaultInput;
};

export type QueryPriceExplainArgs = {
  input: PriceCalculateInput;
};

export type QueryPricesArgs = {
  input?: InputMaybe<PriceSearchInput>;
};

export type QueryPricesheetArgs = {
  id: Scalars['String']['input'];
};

export type QueryPricesheetsArgs = {
  input?: InputMaybe<PricesheetSearchInput>;
};

export type QueryProductArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  productId?: InputMaybe<Scalars['Int']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type QueryProductSurchargesArgs = {
  input?: InputMaybe<SurchargeSearchInput>;
  productId: Scalars['Int']['input'];
};

export type QueryProductsArgs = {
  input?: InputMaybe<ProductSearchInput>;
};

export type QueryRoleArgs = {
  id: Scalars['ID']['input'];
};

export type QueryRoleDefinitionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryRoleDefinitionsArgs = {
  input?: InputMaybe<RoleDefinitionSearchInput>;
};

export type QueryRolesArgs = {
  input: RoleSearchInput;
};

export type QueryShopArgs = {
  shopId: Scalars['Int']['input'];
};

export type QuerySiteArgs = {
  siteId: Scalars['Int']['input'];
};

export type QuerySurchargeArgs = {
  id: Scalars['String']['input'];
};

export type QuerySurchargesArgs = {
  input?: InputMaybe<SurchargeSearchInput>;
};

export type QueryTaxArgs = {
  code?: InputMaybe<Taxcode>;
  id?: InputMaybe<Scalars['String']['input']>;
  shopId?: InputMaybe<Scalars['Int']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
};

export type QueryTaxesArgs = {
  input?: InputMaybe<TaxSearchInput>;
};

export type QueryTenderArgs = {
  orderId?: InputMaybe<Scalars['Int']['input']>;
  tenderId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryUserArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  login?: InputMaybe<Scalars['String']['input']>;
};

export type QueryUsergroupArgs = {
  id: Scalars['Int']['input'];
};

export type QueryUsergroupsArgs = {
  input: UsergroupSearchArguments;
};

export type QueryValuesetArgs = {
  id: Scalars['Int']['input'];
};

export type QueryValuesetItemsArgs = {
  input?: InputMaybe<ValuesetItemSearchInput>;
};

export type QueryValuesetsArgs = {
  input?: InputMaybe<ValuesetSearchInput>;
};

export type QueryWarehouseArgs = {
  id: Scalars['Int']['input'];
};

export type QueryWarehousesArgs = {
  input?: InputMaybe<WarehousesSearchInput>;
};

export type QueryZoneTaxCodeArgs = {
  id: Scalars['String']['input'];
};

export type QueryZoneTaxCodesArgs = {
  input?: InputMaybe<ZoneTaxCodeSearchInput>;
};

export type RefreshTokenResponse = {
  __typename?: 'RefreshTokenResponse';
  access_token: Scalars['String']['output'];
  expires_in: Scalars['Int']['output'];
  refresh_token: Scalars['String']['output'];
  token_type: Scalars['String']['output'];
  user_id: Scalars['String']['output'];
};

export type RegisterContactInput = {
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  /** The debtor id of the contact. Deprecated in favor of company debtorId */
  debtorId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailingList?: InputMaybe<YesNo>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** The id of the company the contact should be created in. */
  parentId: Scalars['Int']['input'];
  /** Optionally create a prefilled password. When omitted the contact can set a new password using password reset(link) */
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  /** The primary language of the contact. */
  primaryLanguage?: InputMaybe<Scalars['String']['input']>;
};

export type RegisterContactResponse = {
  __typename?: 'RegisterContactResponse';
  contact: IBaseUser;
  session: RegisterUserSession;
};

export type RegisterCustomerInput = {
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailingList?: InputMaybe<YesNo>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** The id of the usergroup the customer should be created in. */
  parentId?: InputMaybe<Scalars['Int']['input']>;
  /** Optionally create a prefilled password. When omitted the customer can set a new password using password reset(link) */
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  /** The primary language of the customer. */
  primaryLanguage?: InputMaybe<Scalars['String']['input']>;
};

export type RegisterCustomerResponse = {
  __typename?: 'RegisterCustomerResponse';
  customer: IBaseUser;
  session: RegisterUserSession;
};

export type RegisterUserSession = {
  __typename?: 'RegisterUserSession';
  /** JWT Bearer access token */
  accessToken?: Maybe<Scalars['String']['output']>;
  /** Expiration time */
  expirationTime?: Maybe<Scalars['DateTime']['output']>;
  /** Refresh token */
  refreshToken?: Maybe<Scalars['String']['output']>;
};

export type RemoveOrderStatusesFromOrderStatusSetInput = {
  /** The ids of the order statuses that will be removed from the set */
  orderStatusIds: Array<Scalars['Int']['input']>;
};

export type RemoveSurchargesFromProductInput = {
  /** Product identifier */
  productId: Scalars['Int']['input'];
  /** List of surcharge ids */
  surchargeIds: Array<Scalars['String']['input']>;
};

/**
 * The resize-filter parameter enables control over the resizing filter used to generate a new image with a higher or lower number of pixels.
 *
 *   NOTES:
 *     1. When making an image smaller, use bicubic, which has a natural sharpening effect.
 *     2. When making an image larger, use bilinear, which has a natural smoothing effect.
 *     3. When resizing pixel art, use nearest, which has a natural pixelation effect.
 *     4. When quality is the main concern, use lanczos, which typically renders the best results.
 */
export enum ResizeFilter {
  /** Uses an average of a 4x4 environment of pixels, weighing the innermost pixels higher. */
  Bicubic = 'BICUBIC',
  /** Uses an average of a 2x2 environment of a pixels. */
  Bilinear = 'BILINEAR',
  /** Uses the Lanczos filter to increase the ability to detect edges and linear features within an image and uses sinc resampling to provide the best possible reconstruction. */
  Lanczos2 = 'LANCZOS2',
  /** Lanczos3 uses a better approximation of the sinc resampling function. (Default) */
  Lanczos3 = 'LANCZOS3',
  /** Uses the value of nearby translated pixel values. */
  Nearest = 'NEAREST',
}

export type Role = {
  __typename?: 'Role';
  /** The access for this role */
  access: RoleAccess;
  /** Creation date */
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** The unique identifier of the role */
  id: Scalars['ID']['output'];
  /** Last modified date */
  lastModifiedAt: Scalars['DateTime']['output'];
  lastModifiedBy?: Maybe<Scalars['Int']['output']>;
  /** The role definition for this role */
  roleDefinition: RoleDefinition;
  /** The id of the user this role belongs to */
  userId: Scalars['Int']['output'];
};

/** Access permissions that can be set on a role */
export enum RoleAccess {
  Editor = 'EDITOR',
  None = 'NONE',
  Owner = 'OWNER',
  Viewer = 'VIEWER',
}

export type RoleCreateInput = {
  /** The access for this role */
  access: RoleAccess;
  /** Primary identifier for role definition */
  roleDefinitionId: Scalars['String']['input'];
  /** The id of the user this role belongs to */
  userId: Scalars['Int']['input'];
};

export type RoleDefinition = {
  __typename?: 'RoleDefinition';
  /** Creation date */
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** The default access for the role definition */
  defaultAccess: RoleAccess;
  /** The descriptions of the role definition */
  descriptions: Array<LocalizedString>;
  /** The unique identifier of the role definition */
  id: Scalars['ID']['output'];
  /** Last modified date */
  lastModifiedAt: Scalars['DateTime']['output'];
  lastModifiedBy?: Maybe<Scalars['Int']['output']>;
  /** The domain name of the role definition */
  name: Scalars['String']['output'];
  roles: Array<Role>;
};

export type RoleDefinitionRolesArgs = {
  userId: Scalars['Int']['input'];
};

export type RoleDefinitionCreateInput = {
  /** The access for this role-definition */
  defaultAccess: RoleAccess;
  /** Role definition description */
  descriptions: Array<LocalizedStringInput>;
  /** Role definition name */
  name: Scalars['String']['input'];
};

export type RoleDefinitionResponse = {
  __typename?: 'RoleDefinitionResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type RoleDefinition */
  items: Array<RoleDefinition>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type RoleDefinitionSearchInput = {
  /** Pagination offset number */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination page number */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Inputs to sort by */
  sortInputs?: InputMaybe<Array<RoleDefinitionSortInput>>;
};

export type RoleDefinitionSortInput = {
  /** Field to sort by */
  field: RoleDefinitionSortableFields;
  /** Order option to sort by. [Default to `ASC`] */
  order?: SortOrder;
};

/** Fields a Role Definition search can be sorted by */
export enum RoleDefinitionSortableFields {
  CreatedAt = 'CREATED_AT',
  LastModifiedAt = 'LAST_MODIFIED_AT',
  Name = 'NAME',
}

export type RoleDefinitionUpdateInput = {
  /** The access for this role definition */
  defaultAccess: RoleAccess;
  /** Role definition descriptionRole definition description */
  descriptions: Array<LocalizedStringInput>;
  /** Role definition name */
  name: Scalars['String']['input'];
};

export type RoleResponse = {
  __typename?: 'RoleResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type Role */
  items: Array<Role>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type RoleSearchInput = {
  /** Pagination offset number */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination page number */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Inputs to sort by */
  sortInputs?: InputMaybe<Array<RoleSortInput>>;
  /** Search by user ids */
  userIds: Array<Scalars['Int']['input']>;
};

export type RoleSortInput = {
  /** Field to sort by */
  field: RoleSortableFields;
  /** Order option to sort by. [Default to `ASC`] */
  order?: SortOrder;
};

/** Fields a Role search can be sorted by */
export enum RoleSortableFields {
  CreatedAt = 'CREATED_AT',
  LastModifiedAt = 'LAST_MODIFIED_AT',
  UserId = 'USER_ID',
}

export type RoleUpdateInput = {
  /** The access for this role */
  access: RoleAccess;
};

export type SearchByInput = {
  /** Propeller payment identifier */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Propeller orderId unique payment identifier */
  orderId?: InputMaybe<Scalars['Float']['input']>;
  /** PSP payment identifier */
  paymentId?: InputMaybe<Scalars['String']['input']>;
};

export type SearchFieldsInput = {
  /** Search boost value */
  boost: Scalars['Int']['input'];
  /** List of available sortable fields */
  fieldNames: Array<ProductSearchableField>;
};

export type SearchSparePartsMachineInput = {
  offset?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
};

export type SendOrderConfirmResponseType = {
  __typename?: 'SendOrderConfirmResponseType';
  messageId: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type SharpenInput = {
  /** Sharpen amount value */
  amount?: InputMaybe<Scalars['Float']['input']>;
  /** Sharpen radius value */
  radius?: InputMaybe<Scalars['Int']['input']>;
  /** Sharpen threshold value */
  threshold?: InputMaybe<Scalars['Int']['input']>;
};

export type Shipment = {
  __typename?: 'Shipment';
  /** Shipment creation date */
  date: Scalars['DateTime']['output'];
  /** Shipment unique identifier */
  id: Scalars['Int']['output'];
  /** Items in the shipment */
  items?: Maybe<Array<ShipmentItem>>;
  /** Order unique identifier */
  orderId: Scalars['Int']['output'];
  /** Preferred delivery date for the shipment */
  printDate?: Maybe<Scalars['DateTime']['output']>;
  /** Shipment status */
  status: Scalars['String']['output'];
  /** Total discount value of the shipment */
  totalDiscountValue: Scalars['Float']['output'];
  /** Total gross amount of the shipment */
  totalGross: Scalars['Float']['output'];
  /** Total net amount of the shipment */
  totalNet: Scalars['Float']['output'];
  /** Total tax amount of the shipment */
  totalTax: Scalars['Float']['output'];
  /** Track and trace information */
  trackAndTrace?: Maybe<Array<TrackAndTrace>>;
};

export type ShipmentItem = {
  __typename?: 'ShipmentItem';
  /** Shipment item discount */
  discount: Scalars['Float']['output'];
  /** Shipment item unique identifier */
  id: Scalars['Int']['output'];
  /** Shipment item name */
  name: Scalars['String']['output'];
  /** Order item unique identifier */
  orderItemId: Scalars['Int']['output'];
  /** Shipment item price */
  price: Scalars['Float']['output'];
  /** Shipment item total price */
  priceTotal: Scalars['Float']['output'];
  /** Product unique identifier */
  productId?: Maybe<Scalars['Int']['output']>;
  /** Shipment item quantity */
  quantity: Scalars['Int']['output'];
  /** Shipment unique identifier */
  shipmentId: Scalars['Int']['output'];
  /** Product SKU */
  sku: Scalars['String']['output'];
  /** Shipment item description */
  supplier: Scalars['String']['output'];
  /** Shipment item supplier code */
  supplierCode: Scalars['String']['output'];
  /** Shipment item tax */
  tax: Scalars['Float']['output'];
  /** Shipment item tax percentage */
  taxPercentage: Scalars['Float']['output'];
  /** Shipment item UUID */
  uuid: Scalars['String']['output'];
};

export type Shop = {
  __typename?: 'Shop';
  cocNumber?: Maybe<Scalars['String']['output']>;
  debtorId?: Maybe<Scalars['String']['output']>;
  enableIncentives: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /** Shop price calculation configuration */
  priceCalculationConfig: ShopPriceCalculationConfig;
  /** Shop purchase root id */
  purchaseRootId?: Maybe<Scalars['Int']['output']>;
  shopId: Scalars['Int']['output'];
  taxNumber?: Maybe<Scalars['String']['output']>;
  /** Shop customer root id */
  userRootId?: Maybe<Scalars['Int']['output']>;
};

export type ShopPriceCalculationConfig = {
  __typename?: 'ShopPriceCalculationConfig';
  calculateDiscountOverBulkPrices: Scalars['Boolean']['output'];
  inheritCatalogDiscountOnPriceList: Scalars['Boolean']['output'];
  isVatIncludedLeading: Scalars['Boolean']['output'];
  useMultipleCostprices: Scalars['Boolean']['output'];
};

export type Site = {
  __typename?: 'Site';
  alternartiveUrl?: Maybe<Scalars['String']['output']>;
  anonymousUserId: Scalars['Int']['output'];
  fileUrl: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  mobileUrl: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** Image Search Sort */
export enum Sort {
  /** Sort ascending */
  Asc = 'ASC',
  /** Sort descending */
  Desc = 'DESC',
}

/** Order options for sorting */
export enum SortOrder {
  /** Ascending order */
  Asc = 'ASC',
  /** Descending order */
  Desc = 'DESC',
}

export type Source = {
  __typename?: 'Source';
  /** Source ID */
  id?: Maybe<Scalars['String']['output']>;
  /** Name of the source */
  name?: Maybe<Scalars['String']['output']>;
};

export type SourceInput = {
  /** Source ID */
  id: Scalars['String']['input'];
  /** Name of the source */
  name: Scalars['String']['input'];
};

export type SparePart = {
  __typename?: 'SparePart';
  /** Spare Part identifier */
  id: Scalars['ID']['output'];
  /** Spare Part name */
  name?: Maybe<Array<LocalizedString>>;
  product?: Maybe<IBaseProduct>;
  /** Spare Part quantity */
  quantity: Scalars['Int']['output'];
  /** Spare Part SKU */
  sku: Scalars['String']['output'];
};

export type SparePartNameArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type SparePartsMachine = {
  __typename?: 'SparePartsMachine';
  description?: Maybe<Array<LocalizedString>>;
  /** The unique identifier for this Machine */
  id: Scalars['ID']['output'];
  machines?: Maybe<Array<SparePartsMachine>>;
  media?: Maybe<SparePartsMachineMedia>;
  name: Array<LocalizedString>;
  parts?: Maybe<Array<SparePart>>;
  slug?: Maybe<Array<LocalizedString>>;
  sparePartProducts?: Maybe<SparePartsResponse>;
};

export type SparePartsMachineDescriptionArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type SparePartsMachineNameArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type SparePartsMachineSlugArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type SparePartsMachineSparePartProductsArgs = {
  input?: InputMaybe<SparePartsMachineProductSearchInput>;
};

export type SparePartsMachineMedia = {
  __typename?: 'SparePartsMachineMedia';
  /** Media attachments */
  attachments?: Maybe<PaginatedMediaAttachmentResponse>;
  /** Media documents */
  documents?: Maybe<PaginatedMediaDocumentResponse>;
  /** Media images */
  images?: Maybe<PaginatedMediaImageResponse>;
  /** Media videos */
  videos?: Maybe<PaginatedMediaVideoResponse>;
};

export type SparePartsMachineMediaAttachmentsArgs = {
  input?: InputMaybe<ObjectMediaSearchInput>;
};

export type SparePartsMachineMediaDocumentsArgs = {
  search?: InputMaybe<ObjectMediaSearchInput>;
};

export type SparePartsMachineMediaImagesArgs = {
  search?: InputMaybe<ObjectMediaSearchInput>;
};

export type SparePartsMachineMediaVideosArgs = {
  search?: InputMaybe<ObjectMediaSearchInput>;
};

export type SparePartsMachineProductSearchInput = {
  /** List of product EAN codes */
  EANCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Product class */
  class?: InputMaybe<ProductClass>;
  /** List of clusterIds to search for */
  clusterIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Browse catalog as a specific Company. Permissions, orderlists, favorite will be merged  with the logged in user. */
  companyId?: InputMaybe<Scalars['Int']['input']>;
  /** Product path slug(s) */
  containerPathSlugs?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Product container slug(s) */
  containerSlugs?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** Is product in a bundle */
  hasBundle?: InputMaybe<YesNo>;
  /** Is product hidden? */
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** List of unique product identifiers */
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Is product a bundle leader */
  isBundleLeader?: InputMaybe<YesNo>;
  /** Specify through which language to search in, has no effect on other returned fields (eg. names,slugs) */
  language?: Scalars['String']['input'];
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** List of product manufacturer codes */
  manufacturerCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** List of product manufacturers */
  manufacturers?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Pagination offset number [default=12] */
  offset?: Scalars['Int']['input'];
  /** Pagination page number [default=1] */
  page?: Scalars['Int']['input'];
  /** Product price filter */
  price?: InputMaybe<ProductPriceFilterInput>;
  /** List of productIds to search for */
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** List of range filters */
  rangeFilters?: InputMaybe<Array<ProductRangeFilterInput>>;
  /** List of product search fields */
  searchFields?: InputMaybe<Array<SearchFieldsInput>>;
  /** List of product SKUs */
  skus?: InputMaybe<Array<Scalars['String']['input']>>;
  /** List of product sort filters */
  sortInputs?: InputMaybe<Array<ProductSortInput>>;
  /** List of product status filters */
  statuses?: Array<ProductStatus>;
  /** List of product supplier codes */
  supplierCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** List of product suppliers */
  suppliers?: InputMaybe<Array<Scalars['String']['input']>>;
  /** List of product tags */
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Product search term */
  term?: InputMaybe<Scalars['String']['input']>;
  /** List of text filters  */
  textFilters?: InputMaybe<Array<ProductTextFilterInput>>;
  /** Browse catalog as a specific User. Permissions, orderlists, favorite will be merged  with the logged in user. */
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type SparePartsMachineResponse = {
  __typename?: 'SparePartsMachineResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type SparePartsMachine */
  items: Array<SparePartsMachine>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /**  The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type SparePartsResponse = {
  __typename?: 'SparePartsResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  filters?: Maybe<Array<AttributeFilter>>;
  /** List of items of type SpareParts */
  items: Array<SparePart>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The highest price of a product in this productlist */
  maxPrice: Scalars['Int']['output'];
  /** The lowest price of a product in this productlist */
  minPrice: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /**  The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type StandardFileUploadInput = {
  /** File reference from filesystem [GraphQL Upload form-data specification](https://github.com/jaydenseric/graphql-multipart-request-spec) */
  file: Scalars['Upload']['input'];
  /** Name to use for storing the file [if not provided the actual upload file name will be used] */
  fileName?: InputMaybe<Scalars['String']['input']>;
};

export type Surcharge = {
  __typename?: 'Surcharge';
  /** The userId of the user that changed the surcharge */
  changedBy?: Maybe<Scalars['Int']['output']>;
  /** The userId of the user that created the surcharge */
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** The date and time the surcharge is changed */
  dateChanged: Scalars['DateTime']['output'];
  /** The date and time the surcharge is created */
  dateCreated: Scalars['DateTime']['output'];
  /** A brief description of the surcharge */
  description?: Maybe<Array<LocalizedString>>;
  /** Indicates whether the surcharge is active or not */
  enabled: Scalars['Boolean']['output'];
  /** Unique identifier for the surcharge */
  id: Scalars['String']['output'];
  /** A descriptive name for the surcharge */
  name: Array<LocalizedString>;
  /** Shop identifier for the tax to apply to... If not provided the default will be inferred from the channel */
  shopId?: Maybe<Scalars['Int']['output']>;
  /** Tax code */
  taxCode: TaxCode;
  /** Tax zone */
  taxZone: Scalars['String']['output'];
  /** Type of surcharge */
  type: SurchargeType;
  /** The date and time the surcharge is valid from */
  validFrom?: Maybe<Scalars['DateTime']['output']>;
  /** The date and time the surcharge is valid to */
  validTo?: Maybe<Scalars['DateTime']['output']>;
  /** The value to be applied as surcharge (e.g., percentage or amount) */
  value: Scalars['Float']['output'];
};

export type SurchargeProduct = {
  __typename?: 'SurchargeProduct';
  product?: Maybe<Product>;
  /** The product identifier */
  productId?: Maybe<Scalars['Int']['output']>;
  /** The surcharge object */
  surcharge: Surcharge;
  /** The surcharge identifier */
  surchargeId: Scalars['String']['output'];
};

export type SurchargeProductResponse = {
  __typename?: 'SurchargeProductResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type SurchargeProduct */
  items: Array<SurchargeProduct>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type SurchargeSearchInput = {
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** Only search for already enabled surcharges */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Only search for surcharges with taxCode provided */
  taxCode?: InputMaybe<TaxCode>;
  /** Only search for surcharges with taxZone provided */
  taxZone?: InputMaybe<Scalars['String']['input']>;
  /** Only search for surcharges of the type provided */
  type?: InputMaybe<SurchargeType>;
};

/** Available type of surcharges */
export enum SurchargeType {
  FlatFee = 'FlatFee',
  Percentage = 'Percentage',
}

export type SurchargesResponse = {
  __typename?: 'SurchargesResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type Surcharge */
  items: Array<Surcharge>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type Tax = {
  __typename?: 'Tax';
  /** Tax code */
  code?: Maybe<Taxcode>;
  /** Tax initial creation timestamp */
  createdAt: Scalars['DateTime']['output'];
  /** Reference to the user that created the tax initially */
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** Tax export code */
  exportCode?: Maybe<Scalars['String']['output']>;
  /** Tax primary identifier */
  id: Scalars['String']['output'];
  /** Tax last update timestamp */
  lastModifiedAt: Scalars['DateTime']['output'];
  /** Reference to the user that last modified the tax */
  lastModifiedBy?: Maybe<Scalars['Int']['output']>;
  /** Tax percentage  */
  percentage?: Maybe<Scalars['Float']['output']>;
  /** Shop identifier for the tax to apply to */
  shopId: Scalars['Int']['output'];
  /** Tax zone */
  zone: Scalars['String']['output'];
};

/** All supported tax codes */
export enum TaxCode {
  H = 'H',
  L = 'L',
  N = 'N',
}

export type TaxCreateInput = {
  /** Tax code */
  code?: InputMaybe<Taxcode>;
  /** Tax export code */
  exportCode?: InputMaybe<Scalars['String']['input']>;
  /** Tax percentage */
  percentage?: InputMaybe<Scalars['Float']['input']>;
  /** Shop identifier for the tax to apply to... If not provided the default will be inferred from the channel */
  shopId: Scalars['Int']['input'];
  /** Tax zone */
  zone: Scalars['String']['input'];
};

export type TaxSearchInput = {
  /** Tax code. One of: [H, L, N] */
  code?: InputMaybe<Taxcode>;
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Pagination offset number */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination page number */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Shop identifier for the tax to apply to */
  shopId?: InputMaybe<Scalars['Int']['input']>;
  /** Inputs to sort by */
  sortInputs?: InputMaybe<Array<TaxSortInput>>;
  /** Tax zone */
  zone?: InputMaybe<Scalars['String']['input']>;
};

export enum TaxSortField {
  Code = 'CODE',
  CreatedAt = 'CREATED_AT',
  ExportCode = 'EXPORT_CODE',
  Id = 'ID',
  LastModifiedAt = 'LAST_MODIFIED_AT',
  Percentage = 'PERCENTAGE',
  ShopId = 'SHOP_ID',
  Zone = 'ZONE',
}

export type TaxSortInput = {
  /** Field to sort by */
  field: TaxSortField;
  /** Order option to sort by. [Default to `ASC`] */
  order?: SortOrder;
};

export type TaxUpdateInput = {
  /** Tax code */
  code?: InputMaybe<Taxcode>;
  /** Tax export code */
  exportCode?: InputMaybe<Scalars['String']['input']>;
  /** Tax percentage */
  percentage?: InputMaybe<Scalars['Float']['input']>;
  /** Shop identifier for the tax to apply to... If not provided the default will be inferred from the channel */
  shopId?: InputMaybe<Scalars['Int']['input']>;
  /** Tax zone */
  zone?: InputMaybe<Scalars['String']['input']>;
};

/** All supported tax codes */
export enum Taxcode {
  /** High tax rate */
  H = 'H',
  /** Low tax rate */
  L = 'L',
  /** No tax rate */
  N = 'N',
}

export type TaxesResponse = {
  __typename?: 'TaxesResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type Tax */
  items: Array<Tax>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type TenantResponse = {
  __typename?: 'TenantResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type Tenant */
  items: Array<AdminUserTenant>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type Tender = {
  __typename?: 'Tender';
  /** Action code that is applied to this tender. Is only filled if a valid action code was applied during checkout */
  actionCode?: Maybe<Scalars['String']['output']>;
  /** The tender/order bonusItems */
  bonusItems?: Maybe<Array<TenderMainItem>>;
  /** List of selectable carriers */
  carriers?: Maybe<Array<TenderCarrier>>;
  /** The channel id the tender/order is created from */
  channelId?: Maybe<Scalars['Int']['output']>;
  /** Tender Company */
  company?: Maybe<Company>;
  /** CompanyId the contact belongs to. Goes in combination with contactId */
  companyId?: Maybe<Scalars['Int']['output']>;
  /** Tender Contact */
  contact?: Maybe<Contact>;
  /** ContactId for this tender. Goes in combination with companyId */
  contactId?: Maybe<Scalars['Int']['output']>;
  /** The date this tender/order has been created */
  createdAt: Scalars['DateTime']['output'];
  /** User which created the tender. */
  createdBy?: Maybe<Scalars['Int']['output']>;
  /** Tender/order accumulated credit points */
  creditPoints?: Maybe<Scalars['Int']['output']>;
  /** The currency for this tender */
  currency?: Maybe<Scalars['String']['output']>;
  /** The currency ratio for this tender */
  currencyRatio?: Maybe<Scalars['Float']['output']>;
  /** Tender Customer */
  customer?: Maybe<Customer>;
  /** CustomerId for this tender. */
  customerId?: Maybe<Scalars['Int']['output']>;
  /** Invoice company debtor ID */
  debtorId?: Maybe<Scalars['String']['output']>;
  /** The address the order should be shipped to */
  deliveryAddress?: Maybe<TenderAddress>;
  /** User's email */
  email: Scalars['String']['output'];
  /** The order's ID in an external system */
  externalId?: Maybe<Scalars['String']['output']>;
  /** Additional information field that can be stored with a tender/order */
  extra3?: Maybe<Scalars['String']['output']>;
  /** Additional information field that can be stored with a tender/order */
  extra4?: Maybe<Scalars['String']['output']>;
  /** User's first name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Indicates whether incentives are already applied */
  incentivesApplied?: Maybe<Scalars['Boolean']['output']>;
  /** The address the invoice for the order should be sent to */
  invoiceAddress?: Maybe<TenderAddress>;
  /** The invoice userId for this tender */
  invoiceUserId?: Maybe<Scalars['Int']['output']>;
  /** Indicates whether tender can be edited */
  isEditable?: Maybe<Scalars['Boolean']['output']>;
  /** Tender/order main item count */
  itemCount?: Maybe<Scalars['Int']['output']>;
  /** The tender/order items */
  items?: Maybe<Array<TenderMainItem>>;
  /** The language for this tender */
  language?: Maybe<Scalars['String']['output']>;
  /** The date this tender/order has been last modified */
  lastModifiedAt: Scalars['DateTime']['output'];
  /** User which last updated the tender. */
  lastModifiedBy?: Maybe<Scalars['Int']['output']>;
  /** User's last name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** User's middle name */
  middleName?: Maybe<Scalars['String']['output']>;
  /** Tender's associated order ID */
  orderId?: Maybe<Scalars['Int']['output']>;
  /** Tender's associated owner ID */
  ownerId?: Maybe<Scalars['Int']['output']>;
  /** List of selectable payment methods for this tender, including non allowed for the applied user */
  payMethods?: Maybe<Array<TenderPaymethod>>;
  /** Payment data for this tender/order */
  paymentData?: Maybe<TenderPayment>;
  /** Postage data for this tender/order */
  postageData?: Maybe<TenderPostage>;
  /** User's reference for this tender */
  reference?: Maybe<Scalars['String']['output']>;
  /** User's remarks for this tender */
  remarks?: Maybe<Scalars['String']['output']>;
  /** The shop the tender/order belongs to */
  shopId: Scalars['Int']['output'];
  /**
   * The site id the tender/order is created from
   * @deprecated Deprecated in favor of channelId
   */
  siteId?: Maybe<Scalars['Int']['output']>;
  /** Tender/order origin */
  source?: Maybe<Scalars['String']['output']>;
  /** Tender/order status */
  status: Scalars['String']['output'];
  /** Amount of tax that applies to this tender/order per tax code */
  taxLevels?: Maybe<Array<TenderTaxLevel>>;
  /** The tender's primary identifier */
  tenderId: Scalars['String']['output'];
  /** Tender total */
  total?: Maybe<TenderTotal>;
  /** Order type */
  type: OrderType;
  /**
   * Tender User
   * @deprecated Deprecated in favor of `contact` or `customer`
   */
  user?: Maybe<IBaseUser>;
  /** @deprecated This field will be removed in a future release, use contact+companyId or customerId instead */
  userId?: Maybe<Scalars['Int']['output']>;
  /** Valid until date (Display Only!) */
  validUntil?: Maybe<Scalars['DateTime']['output']>;
  /** Total amount of valuePoints that apply to this tender/order */
  valuePoints?: Maybe<Scalars['Int']['output']>;
};

export type TenderAddItemInput = {
  /**
   * Adds child items to this tender item. This field is used when adding configurable clusters to the Tender.
   *   Provide an array of children options/ products of the parent item.
   */
  childItems?: InputMaybe<Array<TenderChildItemInput>>;
  /** Delivery date - ISO 8601 Date string */
  deliveryDate?: InputMaybe<Scalars['String']['input']>;
  /** Item name. Defaults to product name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Notes, remarks, etc. */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Item price. Defaults to calculated sales price */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** Product ID */
  productId: Scalars['Int']['input'];
  /** Item quantity */
  quantity: Scalars['Int']['input'];
  /** Item supplier. Defaults to product supplier */
  supplier?: InputMaybe<Scalars['String']['input']>;
};

export type TenderAddItemsInput = {
  /** If set to true and an existing item for the same product is found, a new tender item will be created. Existing items are updated by default. Defaults to false */
  forceAdd?: InputMaybe<Scalars['Boolean']['input']>;
  /** Tender items */
  products: Array<TenderAddItemInput>;
  /** If set to false, budget validation will be skipped. Defaults to true */
  validateBudget?: InputMaybe<Scalars['Boolean']['input']>;
  /** If set to false, the items will not be validated against customer order lists. Defaults to true */
  validateOrderList?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TenderAddress = {
  __typename?: 'TenderAddress';
  /** City */
  city: Scalars['String']['output'];
  /** Address code */
  code?: Maybe<Scalars['String']['output']>;
  /** Address company */
  company?: Maybe<Scalars['String']['output']>;
  /** Country code */
  country: Scalars['String']['output'];
  /** Address contact email */
  email?: Maybe<Scalars['String']['output']>;
  /** Address contact first name */
  firstName: Scalars['String']['output'];
  /** Address contact gender */
  gender?: Maybe<Gender>;
  /** ICP is Yes when taxes do not apply for shipping to the given address/ country */
  icp?: Maybe<YesNo>;
  /** Address contact last name */
  lastName: Scalars['String']['output'];
  /** Address contact middle name */
  middleName?: Maybe<Scalars['String']['output']>;
  /** Address contact mobile phone number */
  mobile?: Maybe<Scalars['String']['output']>;
  /** Notes, remarks, etc. */
  notes?: Maybe<Scalars['String']['output']>;
  /** Number */
  number?: Maybe<Scalars['String']['output']>;
  /** Number extension */
  numberExtension?: Maybe<Scalars['String']['output']>;
  /** Address contact phone number */
  phone?: Maybe<Scalars['String']['output']>;
  /** Postal code */
  postalCode: Scalars['String']['output'];
  /** Region */
  region?: Maybe<Scalars['String']['output']>;
  /** Street */
  street: Scalars['String']['output'];
};

/** Address types for Tenders */
export enum TenderAddressType {
  Delivery = 'DELIVERY',
  Invoice = 'INVOICE',
}

export type TenderBaseItem = ITenderBaseItem & {
  __typename?: 'TenderBaseItem';
  /** Extra item discount (%) calculated based on tender/order total discount */
  attributedSaleDiscount: Scalars['Float']['output'];
  /** Margin percentage calculated based on tender/order total discount */
  attributedSaleMargin: Scalars['Float']['output'];
  /** Product cost price per UOM of this tender/order item. VAT excluding */
  costPrice: Scalars['Float']['output'];
  /** Customer discount (%) */
  customerDiscountPercentage: Scalars['Float']['output'];
  /** Customer special price per UOM of this tender/order item. VAT excluding */
  customerPrice: Scalars['Float']['output'];
  /** Sales discount (%) */
  discountPercentage: Scalars['Float']['output'];
  /** The preferred delivery date for this order as requested by the user */
  expectedDeliveryDate?: Maybe<Scalars['DateTime']['output']>;
  /** The order item's ID in an external system */
  externalOrderitemId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Deprecate, please use uuid instead */
  id: Scalars['Int']['output'];
  /** The incentives applied to this tender item */
  incentive?: Maybe<TenderItemIncentive>;
  /** Default margin amount (list price). VAT excluding */
  marginAmount: Scalars['Float']['output'];
  /** Default margin percentage (list price) */
  marginPercentage: Scalars['Float']['output'];
  /** Tender/order item name */
  name: Scalars['String']['output'];
  /** User notes on tender item level */
  notes?: Maybe<Scalars['String']['output']>;
  /** Id of the order item */
  orderItemId?: Maybe<Scalars['Int']['output']>;
  /** Product list price per UOM of this tender/order item. VAT excluding */
  originalPrice: Scalars['Float']['output'];
  /** The gross sales price per UOM of this tender/order item, no item specific incentives and extra costs are applied to this price. VAT excluding */
  price: Scalars['Float']['output'];
  /** The sales price per UOM of this tender/order item, no item specific incentives and extra costs are applied to this price. VAT including */
  priceNet: Scalars['Float']['output'];
  /** The tender/order item's productId, if applicable */
  productId: Scalars['Int']['output'];
  /** The quantity for this tender/order item */
  quantity: Scalars['Int']['output'];
  /** Margin amount. VAT excluding */
  saleMarginAmount: Scalars['Float']['output'];
  /** Margin percentage */
  saleMarginPercentage: Scalars['Float']['output'];
  /** Tender/order item SKU */
  sku: Scalars['String']['output'];
  /** Tender/order item supplier */
  supplier: Scalars['String']['output'];
  /** Tender/order item supplier code */
  supplierCode: Scalars['String']['output'];
  /** The surcharges applied to this tender item */
  surcharges?: Maybe<Array<TenderItemSurcharge>>;
  /** The tax code for this tender/order item */
  taxCode: Taxcode;
  /** The tax percentage for this tender/order item */
  taxPercentage: Scalars['Int']['output'];
  /** Total discount (%) */
  totalDiscountPercentage: Scalars['Float']['output'];
  /** The total gross price of this tender/order item, no item specific incentives and extra costs are applied to this price. VAT excluding */
  totalPrice: Scalars['Float']['output'];
  /** The total price of this tender/order item, no item specific incentives and extra costs are applied to this price. VAT including */
  totalPriceNet: Scalars['Float']['output'];
  /** Id of the tender item, this id can be used for update or delete mutation for this tender item */
  uuid: Scalars['String']['output'];
  /** Total amount of valuePoints that apply to this tender/order item */
  valuePoints?: Maybe<Scalars['Int']['output']>;
};

export type TenderCarrier = {
  __typename?: 'TenderCarrier';
  /** Costs for delivery through this carrier. Use the costs as an indication, the actual costs are calculated through business rules */
  amount: Scalars['Float']['output'];
  /** Carrier description */
  description: Scalars['String']['output'];
  /** Carrier's logo */
  logo: Scalars['String']['output'];
  /** Carrier name */
  name: Scalars['String']['output'];
};

export type TenderChildItemInput = {
  /** Delivery date - ISO 8601 Date string */
  deliveryDate?: InputMaybe<Scalars['String']['input']>;
  /** Item name. Defaults to product name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Notes, remarks, etc. */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Item price. Defaults to calculated sales price */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** Product ID */
  productId: Scalars['Int']['input'];
  /** ChildItem quantity, when omitted, the quantity is linked with parent's quantity */
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type TenderDiscountInput = {
  /** Order discount type */
  type: OrderDiscountType;
  /** Discount amount/ percentage, depending on discount type */
  value: Scalars['Float']['input'];
};

export type TenderItemIncentive = {
  __typename?: 'TenderItemIncentive';
  code: OrderItemIncentiveCode;
  name?: Maybe<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
  quantity: Scalars['Int']['output'];
};

export type TenderItemSurcharge = {
  __typename?: 'TenderItemSurcharge';
  names: Array<LocalizedString>;
  price: Scalars['Float']['output'];
  priceNet: Scalars['Float']['output'];
  quantity: Scalars['Int']['output'];
  taxCode: Taxcode;
  taxPercentage: Scalars['Float']['output'];
  totalPrice: Scalars['Float']['output'];
  totalPriceNet: Scalars['Float']['output'];
};

export type TenderMainItem = ITenderBaseItem & {
  __typename?: 'TenderMainItem';
  /** Extra item discount (%) calculated based on tender/order total discount */
  attributedSaleDiscount: Scalars['Float']['output'];
  /** Extra item discount (%) calculated based on tender/order total discount. Includes item specific incentives and extra costs */
  attributedSaleDiscountSum: Scalars['Float']['output'];
  /** Margin percentage calculated based on tender/order total discount */
  attributedSaleMargin: Scalars['Float']['output'];
  /** Margin percentage calculated based on tender/order total discount. Includes item specific incentives and extra costs */
  attributedSaleMarginSum: Scalars['Float']['output'];
  /** The tender/order item's child items. E.g. surcharges, incentives, etc. */
  childItems?: Maybe<Array<ITenderBaseItem>>;
  /** Product cost price per UOM of this tender/order item. VAT excluding */
  costPrice: Scalars['Float']['output'];
  /** Customer discount (%) */
  customerDiscountPercentage: Scalars['Float']['output'];
  /** Customer special price per UOM of this tender/order item. VAT excluding */
  customerPrice: Scalars['Float']['output'];
  /** Sales discount (%) */
  discountPercentage: Scalars['Float']['output'];
  /** Sales discount (%). Includes item specific incentives and extra costs */
  discountPercentageSum: Scalars['Float']['output'];
  /** The preferred delivery date for this order as requested by the user */
  expectedDeliveryDate?: Maybe<Scalars['DateTime']['output']>;
  /** The order item's ID in an external system */
  externalOrderitemId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Deprecated, please use uuid instead */
  id: Scalars['Int']['output'];
  incentive?: Maybe<TenderItemIncentive>;
  /** Default margin amount (list price). VAT excluding */
  marginAmount: Scalars['Float']['output'];
  /** Default margin percentage (list price) */
  marginPercentage: Scalars['Float']['output'];
  /** Tender/order item name */
  name: Scalars['String']['output'];
  /** User notes on tender item level */
  notes?: Maybe<Scalars['String']['output']>;
  /** Id of the order item */
  orderItemId?: Maybe<Scalars['Int']['output']>;
  /** Product list price per UOM of this tender/order item. VAT excluding */
  originalPrice: Scalars['Float']['output'];
  /** The gross sales price per UOM of this tender/order item, no item specific incentives and extra costs are applied to this price. VAT excluding */
  price: Scalars['Float']['output'];
  /** The sales price per UOM of this tender/order item, no item specific incentives and extra costs are applied to this price. VAT including */
  priceNet: Scalars['Float']['output'];
  product?: Maybe<Product>;
  /** The tender/order item's productId, if applicable */
  productId: Scalars['Int']['output'];
  /** The quantity for this tender/order item */
  quantity: Scalars['Int']['output'];
  /** Margin amount. VAT excluding */
  saleMarginAmount: Scalars['Float']['output'];
  /** Margin amount. Includes item specific incentives and extra costs. VAT excluding */
  saleMarginAmountSum: Scalars['Float']['output'];
  /** Margin percentage */
  saleMarginPercentage: Scalars['Float']['output'];
  /** Margin percentage. Includes item specific incentives and extra costs */
  saleMarginPercentageSum: Scalars['Float']['output'];
  /** Tender/order item SKU */
  sku: Scalars['String']['output'];
  /** The gross price per UOM of this tender/order item, with item specific incentives and extra costs applied to this price. VAT excluding */
  sum: Scalars['Float']['output'];
  /** The price per UOM of this tender/order item, with item specific incentives and extra costs applied to this price. VAT including */
  sumNet: Scalars['Float']['output'];
  /** Tender/order item supplier */
  supplier: Scalars['String']['output'];
  /** Tender/order item supplier code */
  supplierCode: Scalars['String']['output'];
  surcharges?: Maybe<Array<TenderItemSurcharge>>;
  /** The tax code for this tender/order item */
  taxCode: Taxcode;
  /** The tax percentage for this tender/order item */
  taxPercentage: Scalars['Int']['output'];
  /** Total discount (%) */
  totalDiscountPercentage: Scalars['Float']['output'];
  /** Total discount (%). Includes item specific incentives and extra costs */
  totalDiscountPercentageSum: Scalars['Float']['output'];
  /** The total gross price of this tender/order item, no item specific incentives and extra costs are applied to this price. VAT excluding */
  totalPrice: Scalars['Float']['output'];
  /** The total price of this tender/order item, no item specific incentives and extra costs are applied to this price. VAT including */
  totalPriceNet: Scalars['Float']['output'];
  /** The total gross price of this tender/order item, with item specific incentives and extra costs applied to this price. VAT excluding */
  totalSum: Scalars['Float']['output'];
  /** The total price of this tender/order item, with item specific incentives and extra costs applied to this price. VAT including */
  totalSumNet: Scalars['Float']['output'];
  /** Id of the tender item, this id can be used for update or delete mutation for this tender item */
  uuid: Scalars['String']['output'];
  /** Total amount of valuePoints that apply to this tender/order item */
  valuePoints?: Maybe<Scalars['Int']['output']>;
};

export type TenderPayment = {
  __typename?: 'TenderPayment';
  /** Accounting ID for this tender/order */
  accountingId?: Maybe<Scalars['String']['output']>;
  /** The selected payment method for this tender/order */
  method: Scalars['String']['output'];
  /** If false, shipping costs are automatically calculated */
  overruled?: Maybe<YesNo>;
  /** The costs associated with the selected paymethod on this tender/order. VAT including */
  price: Scalars['Float']['output'];
  /** The costs associated with the selected paymethod on this tender/order. VAT excluding */
  priceNet: Scalars['Float']['output'];
  /** Payment status for this tender/order */
  status?: Maybe<Scalars['String']['output']>;
  /** Payment status change date for this tender/order */
  statusDate?: Maybe<Scalars['DateTime']['output']>;
  /** The tax associated with the selected paymethod on this tender/order */
  tax: Scalars['Float']['output'];
  /** The tax percentage associated with the selected paymethod on this tender/order */
  taxPercentage: Scalars['Float']['output'];
};

export type TenderPaymentInput = {
  /** Accounting ID for this tender/order */
  accountingId?: InputMaybe<Scalars['String']['input']>;
  /** Payment method for this tender/order */
  method?: InputMaybe<Scalars['String']['input']>;
  /** The costs associated with the selected paymethod on this tender/order. VAT excluding. If omitted, payment costs are automatically calculated */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** Payment status for this tender/order */
  status?: InputMaybe<Scalars['String']['input']>;
};

export type TenderPaymethod = {
  __typename?: 'TenderPaymethod';
  /** Indicates whether payment method is allowed for the tender user */
  allowed: Scalars['Boolean']['output'];
  /** Payment method amount */
  amount: Scalars['Float']['output'];
  /** Payment method code */
  code: Scalars['String']['output'];
  /** Payment method description */
  description: Scalars['String']['output'];
  /** Payment method external code */
  externalCode?: Maybe<Scalars['String']['output']>;
  /** Payment method type */
  type?: Maybe<Scalars['String']['output']>;
};

export type TenderPostage = {
  __typename?: 'TenderPostage';
  /** The selected carrier for this tender/order */
  carrier?: Maybe<Scalars['String']['output']>;
  /** The selected shipping method for this tender/order */
  method: Scalars['String']['output'];
  /** If false, shipping costs are automatically calculated */
  overruled?: Maybe<YesNo>;
  /** This order can be shipped in parts when items different delivery dates */
  partialDeliveryAllowed?: Maybe<YesNo>;
  /** Pick up location ID */
  pickUpLocationId?: Maybe<Scalars['Int']['output']>;
  /** The costs associated with the selected shipping method and business rules that apply to this tender/order. VAT excluding */
  price: Scalars['Float']['output'];
  /** The costs associated with the selected shipping method and business rules that apply to this tender/order. VAT including */
  priceNet: Scalars['Float']['output'];
  /** The preferred delivery date for this order as requested by the user */
  requestDate?: Maybe<Scalars['DateTime']['output']>;
  /** The VAT associated with the selected shipping method and business rules that apply to this tender/order. */
  tax: Scalars['Float']['output'];
  /** Postage tax percentage */
  taxPercentage: Scalars['Float']['output'];
  warehouse?: Maybe<Warehouse>;
};

export type TenderPostageInput = {
  /** The carrier for this tender/order */
  carrier?: InputMaybe<Scalars['String']['input']>;
  /** The shipping method for this tender/order */
  method?: InputMaybe<Scalars['String']['input']>;
  /** This order can be shipped in parts when items have different delivery dates */
  partialDeliveryAllowed?: InputMaybe<YesNo>;
  /** Pick up location ID */
  pickUpLocationId?: InputMaybe<Scalars['Int']['input']>;
  /** Shipping costs, VAT excluding. If omitted, shipping costs are automatically calculated */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** The preferred delivery date for this order as requested by the user - ISO 8601 Date string */
  requestDate?: InputMaybe<Scalars['String']['input']>;
};

export type TenderProcessResponse = {
  __typename?: 'TenderProcessResponse';
  orderId: Scalars['Int']['output'];
};

export type TenderResponse = {
  __typename?: 'TenderResponse';
  /** Tender response data */
  response: TenderResponseData;
  /** Tender */
  tender: Tender;
};

export type TenderResponseData = {
  __typename?: 'TenderResponseData';
  /** Response data */
  data: Scalars['JSONObject']['output'];
  /** Error */
  error: Scalars['Boolean']['output'];
  /** Response message(s) */
  messages?: Maybe<Array<Scalars['String']['output']>>;
};

export type TenderStartInput = {
  /** The channel id for the tender created */
  channelId?: InputMaybe<Scalars['Int']['input']>;
  /** Company unique identifier, if not provided the contact original parent company will be set! */
  companyId?: InputMaybe<Scalars['Int']['input']>;
  /** Contact unique identifier */
  contactId?: InputMaybe<Scalars['Int']['input']>;
  /** The currency for the tender created. Defaults to EUR */
  currency?: InputMaybe<Scalars['String']['input']>;
  /** Customer unique identifier */
  customerId?: InputMaybe<Scalars['Int']['input']>;
  /** The id of the user editing the order. Defaults to userId */
  ownerId?: InputMaybe<Scalars['Int']['input']>;
  /** Source of the Tender */
  source?: InputMaybe<Scalars['String']['input']>;
  /** The type for the tender created. Defaults to dropshipment */
  type?: InputMaybe<OrderType>;
  /** The user ID for the tender created */
  userId?: InputMaybe<Scalars['Int']['input']>;
  /** Valid until date (Display Only!) */
  validUntil?: InputMaybe<Scalars['String']['input']>;
};

export type TenderTaxLevel = {
  __typename?: 'TenderTaxLevel';
  discount?: Maybe<Scalars['Float']['output']>;
  percentage: Scalars['Float']['output'];
  tax?: Maybe<Scalars['Float']['output']>;
  total: Scalars['Float']['output'];
};

export type TenderTotal = {
  __typename?: 'TenderTotal';
  /** Discount value that is applied to the tender/order total through one or multiple incentives */
  discount?: Maybe<Scalars['Float']['output']>;
  /** Discount that is applied to the tender/order total through one or multiple incentives. VAT excluding */
  discountGross: Scalars['Float']['output'];
  /** Discount that is applied to the tender/order total through one or multiple incentives. VAT including */
  discountNet: Scalars['Float']['output'];
  /**
   * If false, discount is automatically calculated
   * @deprecated Not used anymore
   */
  discountOverruled?: Maybe<Scalars['Boolean']['output']>;
  /** Discount percentage that is applied to the tender/order total through one or multiple incentives */
  discountPercentage?: Maybe<Scalars['Float']['output']>;
  /** Discount type */
  discountType?: Maybe<OrderDiscountType>;
  /** The total payable amount of all items in this tender/order, excluding postage costs, paymethod costs and discounts. VAT excluding */
  subTotalGross: Scalars['Float']['output'];
  /** The total payable amount of all items in this tender/order, excluding postage costs, paymethod costs and discounts. VAT including */
  subTotalNet: Scalars['Float']['output'];
  /** The total payable amount for this tender/order. VAT excluding */
  totalGross: Scalars['Float']['output'];
  /** The total payable amount for this tender/order. VAT including */
  totalNet: Scalars['Float']['output'];
  /** Total amount of tax that applies to this tender/order */
  totalTax: Scalars['Float']['output'];
};

export type TenderUpdateAddressInput = {
  /** City */
  city: Scalars['String']['input'];
  /** Address code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Address company */
  company?: InputMaybe<Scalars['String']['input']>;
  /** Country code */
  country: Scalars['String']['input'];
  /** Address contact email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Address contact first name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Address contact gender */
  gender?: InputMaybe<Gender>;
  /** ICP is Yes when taxes do not apply for shipping to the given address/ country. Defaults to No */
  icp?: InputMaybe<YesNo>;
  /** Address contact last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Address contact middle name */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Address contact mobile phone number */
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** Notes, remarks, etc. */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Number */
  number?: InputMaybe<Scalars['String']['input']>;
  /** Number extension */
  numberExtension?: InputMaybe<Scalars['String']['input']>;
  /** Address contact phone number */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Postal code */
  postalCode: Scalars['String']['input'];
  /** Region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** Street */
  street: Scalars['String']['input'];
  /** Address type */
  type: TenderAddressType;
};

export type TenderUpdateInput = {
  /** The email address to send the confirmation to */
  email?: InputMaybe<Scalars['String']['input']>;
  /** User's reference for this tender */
  reference?: InputMaybe<Scalars['String']['input']>;
  /** User's remarks for this tender */
  remarks?: InputMaybe<Scalars['String']['input']>;
  /** Tender/order origin */
  source?: InputMaybe<Scalars['String']['input']>;
  /** Status of this tender */
  status?: InputMaybe<Scalars['String']['input']>;
  /** The type for the tender.  One of: [dropshipment, purchase, quotation, stock] */
  type?: InputMaybe<OrderType>;
  /** Valid until date (Display Only!) */
  validUntil?: InputMaybe<Scalars['String']['input']>;
};

export type TenderUpdateItemInput = {
  /** Delivery date - ISO 8601 Date string */
  deliveryDate?: InputMaybe<Scalars['String']['input']>;
  /** Item name. Defaults to product name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Notes, remarks, etc. */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Item price. Defaults to calculated sales price */
  price?: InputMaybe<Scalars['Float']['input']>;
  /** Item quantity */
  quantity?: InputMaybe<Scalars['Int']['input']>;
  /** Item supplier. Defaults to product supplier */
  supplier?: InputMaybe<Scalars['String']['input']>;
  /** If set to false, budget validation will be skipped. Defaults to true */
  validateBudget?: InputMaybe<Scalars['Boolean']['input']>;
  /** If set to false, the items will not be validated against customer order lists. Defaults to true */
  validateOrderList?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TrackAndTrace = {
  __typename?: 'TrackAndTrace';
  /** Carrier unique identifier */
  carrierId: Scalars['Int']['output'];
  /** Track and trace code */
  code: Scalars['String']['output'];
  /** Track and trace unique identifier */
  id: Scalars['Int']['output'];
  /** Order unique identifier */
  orderId: Scalars['Int']['output'];
  /** Shipment unique identifier */
  shipmentId: Scalars['Int']['output'];
};

export type Transaction = {
  __typename?: 'Transaction';
  /** Transaction amount */
  amount: Scalars['Int']['output'];
  /** Transaction currency - ISO 4217 currency code => https://www.xe.com/iso4217.php */
  currency: Scalars['String']['output'];
  /** Description */
  description?: Maybe<Scalars['String']['output']>;
  /** Transaction primary identifier */
  id: Scalars['ID']['output'];
  /** Unique orderId reference for the Payment */
  orderId: Scalars['Int']['output'];
  /** Payment ID - usually provided by the PSP */
  paymentId?: Maybe<Scalars['String']['output']>;
  /** PSP Provider Name */
  provider?: Maybe<Scalars['String']['output']>;
  /** Transaction status */
  status: TransactionStatuses;
  /** Transaction timestamp */
  timestamp?: Maybe<Scalars['DateTime']['output']>;
  /** Transaction ID - usually provided by the PSP [if not available please provide paymentId or other unique identifier] */
  transactionId: Scalars['String']['output'];
  /** Transaction type */
  type: TransactionTypes;
};

/** Available transaction statuses */
export enum TransactionStatuses {
  Failed = 'FAILED',
  Open = 'OPEN',
  Pending = 'PENDING',
  Success = 'SUCCESS',
}

/** Available transaction types */
export enum TransactionTypes {
  Authorization = 'AUTHORIZATION',
  CancelAuthorization = 'CANCEL_AUTHORIZATION',
  Chargeback = 'CHARGEBACK',
  Pay = 'PAY',
  Refund = 'REFUND',
}

export type TransformationInput = {
  /** Enable optimization features automatically. */
  auto?: InputMaybe<Auto>;
  /** Set the background color of an image. */
  bgColor?: InputMaybe<Scalars['String']['input']>;
  /** Set the blurriness of the output image. */
  blur?: InputMaybe<Scalars['Float']['input']>;
  /** Set the brightness of the output image. */
  brightness?: InputMaybe<Scalars['Int']['input']>;
  /** Increase the size of the canvas around an image. */
  canvas?: InputMaybe<CanvasInput>;
  /** Set the contrast of the output image. */
  contrast?: InputMaybe<Scalars['Int']['input']>;
  /** Remove pixels from an image. */
  crop?: InputMaybe<CropInput>;
  /** Disable functionality that is enabled by default. */
  disable?: InputMaybe<FeaturesEnableDisable>;
  /** Serve correctly sized images for devices that expose a device pixel ratio. */
  dpr?: InputMaybe<Scalars['Int']['input']>;
  /** Set how the image will fit within the size bounds provided. */
  fit?: InputMaybe<Fit>;
  /** Specify the output format to convert the image to. */
  format?: InputMaybe<Format>;
  /** Extract the first frame from an animated image sequence. */
  frame?: InputMaybe<Scalars['Int']['input']>;
  /** Resize the height of the image. */
  height?: InputMaybe<Scalars['Float']['input']>;
  /** Specify the level constraints when converting to video. */
  level?: InputMaybe<Level>;
  /** Automatically apply optimal quality compression. */
  optimize?: InputMaybe<Optimize>;
  /** Change the cardinal orientation of the image. */
  orient?: InputMaybe<Orient>;
  /** Add pixels to the edge of an image. */
  pad?: InputMaybe<PadInput>;
  /** Remove pixels from an image before any other transformations occur. */
  precrop?: InputMaybe<CropInput>;
  /** Specify the profile class of application when converting to video. */
  profile?: InputMaybe<Scalars['String']['input']>;
  /**
   * Optimize the image to the given compression level for lossy file formatted images.
   *
   *     NOTE:
   *       1. The quality parameter can be applied to the following output formats: png8, jpg, pjpg, webp, and webply.
   *       2. If no quality parameter is present for jpg, pjpg, or webp, the output image will be returned at the default value set in the Image Optimizer user interface.
   */
  quality?: InputMaybe<Scalars['Float']['input']>;
  /** Specify the resize filter used when resizing images. */
  resizeFilter?: InputMaybe<ResizeFilter>;
  /**
   * Set the saturation of the output image.
   *
   *     The saturation parameter increases or decreases the intensity of the colors in an image.
   *
   *     NOTES:
   *     1. The default value is 0. This leaves the image unchanged.
   *     2. Valid values range from -100 to 100
   *     3. A value of -100 will generate a grayscale image.
   */
  saturation?: InputMaybe<Scalars['Int']['input']>;
  /** Set the sharpness of the output image. */
  sharpen?: InputMaybe<SharpenInput>;
  /** Remove pixels from the edge of an image. */
  trim?: InputMaybe<TrimInput>;
  /**
   * Resize the width of the image.
   *
   *     The width value may be one of:
   *
   *       - Absolute width: An integer between 1 and 8192.
   *       - Relative width: A fraction between 0 and 0.99 (e.g. ,0.5) or a percentage between 0 and 100 followed by the letter p (e.g., 50p). In either case the value indicates the desired width relative to the image's natural width.
   */
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type TransformationsInput = {
  /** Image Transformation input... */
  transformations: Array<ImageTransformationInput>;
};

/**
 * Remove pixels from the edge of an image.
 *
 *   The trim parameter removes pixels from the edge of an image by pixel or percentage value. This can be useful for removing whitespace and borders that appear on a source image.
 *
 *   Notes
 *     1. Values can be specified using CSS style shorthand values. (See CSS Margin or CSS Padding for more examples).
 *     2. CSS shorthand allows for all edges to be specified in one property.
 *     3. Any fractional pixel measurements will be rounded to the nearest whole pixel.
 */
export type TrimInput = {
  /** Bottom - Pixels or Percentage(decimal 0.0 - 1) - Default=0 */
  bottom?: InputMaybe<Scalars['Int']['input']>;
  /** Left - Pixels or Percentage(decimal 0.0 - 1) - Default=0 */
  left?: InputMaybe<Scalars['Int']['input']>;
  /** Right - Pixels or Percentage(decimal 0.0 - 1) - Default=0 */
  right?: InputMaybe<Scalars['Int']['input']>;
  /** Top - Pixels or Percentage(decimal 0.0 - 1) - Default=0 */
  top?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateCategoryInput = {
  /**
   * The long description for this category,
   *     this field can contain html formatting,
   *     this field can be translated is case of multilingual catalog.
   */
  description?: InputMaybe<Array<LocalizedStringInput>>;
  /**
   * Localized category name as it is available in
   *     the supported language defaults to the category's default language.
   */
  name?: InputMaybe<Array<LocalizedStringInput>>;
  /** The category's parent Category when available */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /**
   * The long description for this category,
   *     this field can contain html formatting,
   *     this field can be translated is case of multilingual catalog.
   */
  shortDescription?: InputMaybe<Array<LocalizedStringInput>>;
};

export type UpdateClusterConfigSettingResponse = {
  __typename?: 'UpdateClusterConfigSettingResponse';
  displayType?: Maybe<ClusterConfigSettingDisplayType>;
  id?: Maybe<Scalars['Int']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
};

export type UpdateCompanyInput = {
  cocNumber?: InputMaybe<Scalars['String']['input']>;
  debtorId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  /** The id of the usergroup the company should be created in. */
  parentId?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  taxNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContactInput = {
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  /** The debtor id of the contact. Deprecated in favor of company debtorId */
  debtorId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailingList?: InputMaybe<YesNo>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** The id of the company the contact should be created in. */
  parentId?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  /** The primary language of the contact. */
  primaryLanguage?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCustomerInput = {
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailingList?: InputMaybe<YesNo>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** The id of the usergroup the customer should be created in. */
  parentId?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  /** The primary language of the customer. */
  primaryLanguage?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateInventoryInput = {
  /** Inventory cost price */
  costPrice?: InputMaybe<Scalars['Float']['input']>;
  /** Inventory estimated next delivery date in ISO8601 format */
  nextDeliveryDate?: InputMaybe<Scalars['String']['input']>;
  /** Inventory note */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Inventory quantity */
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateMediaAttachmentInput = {
  /** Media item alt description that briefly explains its contents. */
  alt?: InputMaybe<Array<MediaLocalizedStringInput>>;
  /** Media item short description. */
  description?: InputMaybe<Array<MediaLocalizedStringInput>>;
  /** Media item display priority [Lower value has higher priority] -  default: 1000 */
  priority?: InputMaybe<Scalars['Int']['input']>;
  /** Media item tags. */
  tags?: InputMaybe<Array<MediaLocalizedStringArrayInput>>;
};

export type UpdateMediaDocumentInput = {
  /** Media item alt description that briefly explains its contents. */
  alt?: InputMaybe<Array<MediaLocalizedStringInput>>;
  /** Unique category identifier that the media item relates to */
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  /** Unique cluster identifier that the media item relates to */
  clusterId?: InputMaybe<Scalars['ID']['input']>;
  /** Media item short description. */
  description?: InputMaybe<Array<MediaLocalizedStringInput>>;
  /** MediaDocument unique identified */
  mediaId: Scalars['String']['input'];
  /** Media item display priority [Lower value has higher priority] -  default: 1000 */
  priority?: InputMaybe<Scalars['Int']['input']>;
  /** Unique product identifier that the media item relates to */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** Unique machine identifier that the media item relates to */
  sparePartsMachineId?: InputMaybe<Scalars['ID']['input']>;
  /** Media item tags. */
  tags?: InputMaybe<Array<MediaLocalizedStringArrayInput>>;
  /** Upload File input reference */
  uploadDocument?: InputMaybe<UploadFileInput>;
};

export type UpdateMediaImageInput = {
  /** Media item alt description that briefly explains its contents. */
  alt?: InputMaybe<Array<MediaLocalizedStringInput>>;
  /** Unique category identifier that the media item relates to */
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  /** Unique cluster identifier that the media item relates to */
  clusterId?: InputMaybe<Scalars['ID']['input']>;
  /** Media item short description. */
  description?: InputMaybe<Array<MediaLocalizedStringInput>>;
  /** MediaImage unique identified */
  mediaId: Scalars['String']['input'];
  /** Media item display priority [Lower value has higher priority] -  default: 1000 */
  priority?: InputMaybe<Scalars['Int']['input']>;
  /** Unique product identifier that the media item relates to */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** Unique machine identifier that the media item relates to */
  sparePartsMachineId?: InputMaybe<Scalars['ID']['input']>;
  /** Media item tags. */
  tags?: InputMaybe<Array<MediaLocalizedStringArrayInput>>;
  /** Upload File input reference */
  uploadImage?: InputMaybe<UploadFileInput>;
};

export type UpdateMediaVideoInput = {
  /** Media item alt description that briefly explains its contents. */
  alt?: InputMaybe<Array<MediaLocalizedStringInput>>;
  /** Unique category identifier that the media item relates to */
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  /** Unique cluster identifier that the media item relates to */
  clusterId?: InputMaybe<Scalars['ID']['input']>;
  /** Media item short description. */
  description?: InputMaybe<Array<MediaLocalizedStringInput>>;
  /** MediaVideo unique identified */
  mediaId: Scalars['String']['input'];
  /** Media item display priority [Lower value has higher priority] -  default: 1000 */
  priority?: InputMaybe<Scalars['Int']['input']>;
  /** Unique product identifier that the media item relates to */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** Unique machine identifier that the media item relates to */
  sparePartsMachineId?: InputMaybe<Scalars['ID']['input']>;
  /** Media item tags. */
  tags?: InputMaybe<Array<MediaLocalizedStringArrayInput>>;
  /** Upload Video input reference */
  video?: InputMaybe<UploadVideoInput>;
};

export type UpdateOrderStatusInput = {
  /** The order status set to add this order status to */
  addOrderStatusToSet?: InputMaybe<OrderStatusSetSearchByInput>;
  /** The order status code value */
  code?: InputMaybe<Scalars['String']['input']>;
  /** The order status description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Marks if the order can be archived */
  isArchivable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is confirmable */
  isConfirmable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order status is default */
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is deletable */
  isDeletable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is editable */
  isEditable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is exportable */
  isExportable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks if the order is public */
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  /** The order status name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The IDs of the next possible order statuses */
  nextStatusesIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** The order status for order type. */
  orderType: Scalars['String']['input'];
  /** The order status priority */
  priority?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateOrderStatusSetInput = {
  /** The order status set description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The order status set name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The ids of the order statuses that will be added to the set */
  orderStatusIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UpdatePaymentInput = {
  /** Add a transaction related to the specified payment */
  addTransaction?: InputMaybe<CreateTransactionInput>;
  /** Payment amount [denomination in cents] */
  amount?: InputMaybe<Scalars['Int']['input']>;
  /** Guest User ID */
  anonymousId?: InputMaybe<Scalars['Int']['input']>;
  /** Payment currency - ISO 4217 currency code => https://www.xe.com/iso4217.php */
  currency?: InputMaybe<Scalars['String']['input']>;
  /** Payment method used by the PSP */
  method?: InputMaybe<Scalars['String']['input']>;
  /** Unique paymentId reference for the Payment - provided by the PSP */
  paymentId?: InputMaybe<Scalars['String']['input']>;
  /** Payment status */
  status?: InputMaybe<PaymentStatuses>;
  /** Logged in User ID */
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateProductInput = {
  /** The bar code of this product */
  barCode?: InputMaybe<Scalars['String']['input']>;
  /** The id of the category the product should be created in. */
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  /** Additional keywords of this product per language. Added via the PIM */
  customKeywords?: InputMaybe<Array<LocalizedStringInput>>;
  /** The descriptions of this product per language */
  descriptions?: InputMaybe<Array<LocalizedStringInput>>;
  /** The European Article Number (EAN) code of this product */
  eanCode?: InputMaybe<Scalars['String']['input']>;
  /** The quantity that provides the best value for money for this product */
  economicOrderQuantity?: InputMaybe<Scalars['Int']['input']>;
  /** Additional keywords of this product per language. Added by the supplier */
  keywords?: InputMaybe<Array<LocalizedStringInput>>;
  /** The manufacturer of this product */
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  /** The Original Equipment Manufacturer code (OEM) of this product */
  manufacturerCode?: InputMaybe<Scalars['String']['input']>;
  /** The minimum quantity of products that can be ordered */
  minimumQuantity?: InputMaybe<Scalars['Int']['input']>;
  /** The names of this product per language */
  names?: InputMaybe<Array<LocalizedStringInput>>;
  /** Notes on the product for internal use */
  notes?: InputMaybe<Array<LocalizedStringInput>>;
  /** Is this product orderable */
  orderable?: InputMaybe<YesNo>;
  /** The orderable from date of this product */
  orderableFrom?: InputMaybe<Scalars['String']['input']>;
  /** The orderable to date of this product */
  orderableTo?: InputMaybe<Scalars['String']['input']>;
  /** The package type of this product. i.e.: PIECE, BOX, BLISTER */
  package?: InputMaybe<Scalars['String']['input']>;
  /** The package description of this product per language */
  packageDescriptions?: InputMaybe<Array<LocalizedStringInput>>;
  /** The amount of items per package */
  packageUnit?: InputMaybe<Scalars['Int']['input']>;
  /** The quantifier for the package unit, i.e.: PIECE or BOX */
  packageUnitQuantity?: InputMaybe<Scalars['String']['input']>;
  /** Is it a physical product or a service, download or warranty */
  physical?: InputMaybe<YesNo>;
  /** The price group the product belongs to, this field can be used for price calculations */
  priceGroup?: InputMaybe<Scalars['String']['input']>;
  /** The minimum quantity of products that can be ordered when creating a purchase order */
  purchaseMinimumQuantity?: InputMaybe<Scalars['Int']['input']>;
  /** The unit in which the product can be ordered when creating a purchase order */
  purchaseUnit?: InputMaybe<Scalars['Int']['input']>;
  /** The release date of this product */
  releaseDate?: InputMaybe<Scalars['String']['input']>;
  /** Is this product returnable */
  returnable?: InputMaybe<YesNo>;
  /** The short descriptions of this product per language */
  shortDescriptions?: InputMaybe<Array<LocalizedStringInput>>;
  /** The short name for the product, used for abbreviated orderlines */
  shortName?: InputMaybe<Scalars['String']['input']>;
  /** The SKU (stock keeping unit) of this product */
  sku?: InputMaybe<Scalars['String']['input']>;
  /** The status of this product */
  status?: InputMaybe<ProductStatus>;
  /** The extra status of this product, commonly linked to a custom valueset */
  statusExtra?: InputMaybe<Scalars['String']['input']>;
  /** The supplier of this product */
  supplier?: InputMaybe<Scalars['String']['input']>;
  /** The supplier code of this product */
  supplierCode?: InputMaybe<Scalars['String']['input']>;
  /** The taxonomy the product belongs to */
  taxonomy?: InputMaybe<Scalars['String']['input']>;
  /** The turnover group that classifies the product within the financial structure */
  turnoverGroup?: InputMaybe<Scalars['String']['input']>;
  /** The unit in which the product can be ordered */
  unit?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateSurchargeInput = {
  /** A brief description of the surcharge */
  description?: InputMaybe<Array<LocalizedStringInput>>;
  /** Indicates whether the surcharge is active or not */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** A descriptive name for the surcharge */
  name?: InputMaybe<Array<LocalizedStringInput>>;
  /** Shop identifier for the surcharge to apply to... If not provided the default will be inferred from the channel */
  shopId?: InputMaybe<Scalars['Int']['input']>;
  /** Tax code */
  taxCode?: InputMaybe<TaxCode>;
  /** Tax zone */
  taxZone?: InputMaybe<Scalars['String']['input']>;
  /** Type of the surcharge */
  type?: InputMaybe<SurchargeType>;
  /** The date and time the surcharge is valid from */
  validFrom?: InputMaybe<Scalars['String']['input']>;
  /** The date and time the surcharge is valid to */
  validTo?: InputMaybe<Scalars['String']['input']>;
  /** The value to be applied as surcharge (e.g., percentage or amount) */
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateUserDto = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  job?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUsergroupInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  /** The id of the usergroup the company should be created in. */
  parentId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateWarehouseAddressInput = {
  /** Address city */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Address code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** User company the address applies to */
  company?: InputMaybe<Scalars['String']['input']>;
  /** Address country */
  country?: InputMaybe<Scalars['String']['input']>;
  /** Address email [valid email format required] */
  email?: InputMaybe<Scalars['String']['input']>;
  /** User first name the address applies to */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** User gender the address applies to */
  gender?: InputMaybe<Gender>;
  /** Address primary identifier */
  id: Scalars['Int']['input'];
  /** User last name the address applies to */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** User middle name the address applies to */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Address mobile number [min: 6, max: 30] */
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** Address delivery notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** User street number */
  number?: InputMaybe<Scalars['String']['input']>;
  /** User street number extension */
  numberExtension?: InputMaybe<Scalars['String']['input']>;
  /** Address fixed phone number [min: 6, max: 30] */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Address postal code */
  postalCode?: InputMaybe<Scalars['String']['input']>;
  /** Address region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** User street address */
  street?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWarehouseInput = {
  /**
   * Warehouse business hours per week day
   *
   *     Example:
   *         [{
   *           dayOfWeek: 0
   *           openingTime: "08:00"
   *           closingTime: "20:00"
   *           lunchBeakStartTime: "12:00"
   *           lunchBeakEndTime: "12:30"
   *         },
   *         {
   *           dayOfWeek: 1
   *           openingTime: "08:00"
   *           closingTime: "20:00"
   *           lunchBeakStartTime: "12:00"
   *           lunchBeakEndTime: "12:30"
   *         },
   *         {
   *           dayOfWeek: 2
   *           openingTime: "08:00"
   *           closingTime: "20:00"
   *           lunchBeakStartTime: "12:00"
   *           lunchBeakEndTime: "12:30"
   *         },
   *         {
   *           dayOfWeek: 3
   *           openingTime: "08:00"
   *           closingTime: "20:00"
   *           lunchBeakStartTime: "12:00"
   *           lunchBeakEndTime: "12:30"
   *         },
   *         {
   *           dayOfWeek: 4
   *           openingTime: "08:00"
   *           closingTime: "20:00"
   *           lunchBeakStartTime: "12:00"
   *           lunchBeakEndTime: "12:30"
   *         },
   *         {
   *           dayOfWeek: 5
   *           openingTime: "08:00"
   *           closingTime: "20:00"
   *           lunchBeakStartTime: "12:00"
   *           lunchBeakEndTime: "12:30"
   *         },
   *         {
   *           dayOfWeek: 6
   *           openingTime: "08:00"
   *           closingTime: "20:00"
   *           lunchBeakStartTime: "12:00"
   *           lunchBeakEndTime: "12:30"
   *         }]
   */
  businessHours?: InputMaybe<Array<BusinessHoursInput>>;
  /** Warehouse description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Warehouse isActive status */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Warehouse isPickupLocation status */
  isPickupLocation?: InputMaybe<Scalars['Boolean']['input']>;
  /** Warehouse isStore status */
  isStore?: InputMaybe<Scalars['Boolean']['input']>;
  /** Warehouse name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Warehouse notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Optional warehouse shopId! Default value will be internally inferred from the appropriate channel */
  shopId?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadFileInput = {
  /** Base64 file upload */
  base64File?: InputMaybe<MediaBase64FileInput>;
  /** Cache control options */
  cacheControl?: InputMaybe<CacheControlInput>;
  /** File reference from filesystem [GraphQL Upload form-data specification](https://github.com/jaydenseric/graphql-multipart-request-spec) */
  file?: InputMaybe<StandardFileUploadInput>;
  /** Language specific media item [Default value 'EN'] */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Upload type */
  uploadType: UploadType;
  /** The public url to the file. */
  urlFile?: InputMaybe<UrlFileUploadInput>;
};

/** Upload type */
export enum UploadType {
  /** Upload by providing base64 string representation of a file */
  Base64 = 'BASE64',
  /** Upload by referencing a file */
  File = 'FILE',
  /** Upload by providing public url to the file */
  Url = 'URL',
}

export type UploadVideoInput = {
  /** Language specific video [Default value 'EN'] */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Video URI */
  uri: Scalars['String']['input'];
};

export type UpsertSparePartsMachineInput = {
  /** Machine description */
  description?: InputMaybe<Array<LocalizedStringInput>>;
  externalReference: ExternalReferenceInput;
  /** Level 1 SubMachines input */
  machines?: InputMaybe<Array<CreateSparePartsMachineInput>>;
  /** Spare PartsMachine name */
  name: Array<LocalizedStringInput>;
  /** Spare parts for this machine */
  parts?: InputMaybe<Array<CreateSparePartInput>>;
};

export type UrlFileUploadInput = {
  /** Name to use for storing the file */
  fileName: Scalars['String']['input'];
  /** The public url to the file. */
  url: Scalars['String']['input'];
};

export type User = IBaseUser & {
  __typename?: 'User';
  addresses: Array<Address>;
  /** The Bank Account Number of the user */
  bankAccount?: Maybe<Scalars['String']['output']>;
  /** The Bank Identification Code of the user */
  bic?: Maybe<Scalars['String']['output']>;
  /** The CoC number of the user. */
  cocNumber?: Maybe<Scalars['String']['output']>;
  /** The company of the user. */
  company?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** The date of birth of the contact. */
  dateOfBirth?: Maybe<Scalars['DateTime']['output']>;
  debtorId?: Maybe<Scalars['String']['output']>;
  /** The email of the user. */
  email: Scalars['String']['output'];
  /** The expiration date of the contact. After this date expires contact will be disabled. */
  expires?: Maybe<Scalars['DateTime']['output']>;
  /** External ID */
  externalId?: Maybe<Scalars['String']['output']>;
  /** The first name of the user. */
  firstName: Scalars['String']['output'];
  /** The gender of the user. */
  gender?: Maybe<Gender>;
  /** The International Bank Account Number of the user */
  iban?: Maybe<Scalars['String']['output']>;
  isLoggedIn?: Maybe<Scalars['Boolean']['output']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The last name of the user. */
  lastName: Scalars['String']['output'];
  /** The login name (email) the account is linked to, generally the same as email address. When null a account has not been linked yet. */
  login?: Maybe<Scalars['String']['output']>;
  /** The propeller access root for this user. Users with a login root can log into the backoffice and have a supervisor role on the api's */
  loginRoot?: Maybe<Scalars['Int']['output']>;
  mailingList?: Maybe<YesNo>;
  /** Companies managed by the user. */
  managedCompanies?: Maybe<Array<Company>>;
  /** The middle name of the user. */
  middleName?: Maybe<Scalars['String']['output']>;
  /** The mobile number of the user. */
  mobile?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  orders: OrderResponse;
  /** The usergroup id of parent usergroup. */
  parentUsergroupId: Scalars['Int']['output'];
  /** The phone number of the user. */
  phone?: Maybe<Scalars['String']['output']>;
  /** The primary language of the user */
  primaryLanguage?: Maybe<Scalars['String']['output']>;
  /** The Tax number of the user. */
  taxNumber?: Maybe<Scalars['String']['output']>;
  /** The id of the user. */
  userId: Scalars['Int']['output'];
  usergroup?: Maybe<Usergroup>;
  usergroupPath: Array<Usergroup>;
};

export type UserAddressesArgs = {
  isDefault?: InputMaybe<YesNo>;
  type?: InputMaybe<AddressType>;
};

export type UserAddressCreateInput = {
  /** Address city */
  city: Scalars['String']['input'];
  /** Address code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** User company the address applies to */
  company?: InputMaybe<Scalars['String']['input']>;
  /** Address country */
  country: Scalars['String']['input'];
  /** Address email [valid email format required] */
  email?: InputMaybe<Scalars['String']['input']>;
  /** User first name the address applies to */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** User gender the address applies to */
  gender?: InputMaybe<Gender>;
  /** ICP address field defines whether tax should be applied to an order if the address is selected as a delivery address. Used when shipping b2b order abroad */
  icp?: InputMaybe<YesNo>;
  /** Indicates whether the address is default for its type */
  isDefault?: InputMaybe<YesNo>;
  /** User last name the address applies to */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** User middle name the address applies to */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Address mobile number [min: 6, max: 30] */
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** Address delivery notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** User street number */
  number?: InputMaybe<Scalars['String']['input']>;
  /** User street number extension */
  numberExtension?: InputMaybe<Scalars['String']['input']>;
  /** Address fixed phone number [min: 6, max: 30] */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Address postal code */
  postalCode: Scalars['String']['input'];
  /** Address region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** User street address */
  street: Scalars['String']['input'];
  /** Address type [one of 'home', 'delivery' or 'invoice'] */
  type: AddressType;
  /** Address user primary identifier */
  userId: Scalars['Int']['input'];
};

export type UserAddressDeleteInput = {
  /** Address primary identifier */
  id: Scalars['Int']['input'];
  /** User primary identifier */
  userId: Scalars['Int']['input'];
};

export type UserAddressUpdateInput = {
  /** Address city */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Address code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** User company the address applies to */
  company?: InputMaybe<Scalars['String']['input']>;
  /** Address country */
  country?: InputMaybe<Scalars['String']['input']>;
  /** Address email [valid email format required] */
  email?: InputMaybe<Scalars['String']['input']>;
  /** User first name the address applies to */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** User gender the address applies to */
  gender?: InputMaybe<Gender>;
  /** ICP address field defines whether tax should be applied to an order if the address is selected as a delivery address. Used when shipping b2b order abroad */
  icp?: InputMaybe<YesNo>;
  /** Address primary identifier */
  id: Scalars['Int']['input'];
  /** Indicates whether the address is default for its type */
  isDefault?: InputMaybe<YesNo>;
  /** User last name the address applies to */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** User middle name the address applies to */
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** Address mobile number [min: 6, max: 30] */
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** Address delivery notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** User street number */
  number?: InputMaybe<Scalars['String']['input']>;
  /** User street number extension */
  numberExtension?: InputMaybe<Scalars['String']['input']>;
  /** Address fixed phone number [min: 6, max: 30] */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Address postal code */
  postalCode?: InputMaybe<Scalars['String']['input']>;
  /** Address region */
  region?: InputMaybe<Scalars['String']['input']>;
  /** User street address */
  street?: InputMaybe<Scalars['String']['input']>;
  /** Address user primary identifier */
  userId: Scalars['Int']['input'];
};

export type UserClaims = {
  __typename?: 'UserClaims';
  acllist: Array<Scalars['Int']['output']>;
  adminUserId: Scalars['Int']['output'];
  class: Scalars['String']['output'];
  companyId?: Maybe<Scalars['Int']['output']>;
  companyIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  discounts: Array<Scalars['String']['output']>;
  loginRoot?: Maybe<Scalars['Int']['output']>;
  roles: Array<Scalars['String']['output']>;
  userDirectoryId: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type UserResponse = {
  __typename?: 'UserResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type User */
  items: Array<AdminUser>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type Usergroup = {
  __typename?: 'Usergroup';
  /** Children companies of the usergroup. */
  companies?: Maybe<CompaniesResponse>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customers: Array<Customer>;
  /** Shows whether this usergroup is hidden and should only be used for background operations. */
  hidden?: Maybe<YesNo>;
  /**
   * The unique identifier of the usergroup.
   * @deprecated This property is no longer relevant or necessary, use usergroupId instead.
   */
  id: Scalars['Int']['output'];
  inheritProductList?: Maybe<YesNo>;
  lastModifiedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The name of the usergroup. */
  name: Scalars['String']['output'];
  /** The id of parent usergroup. */
  parentUsergroupId: Scalars['Int']['output'];
  /** The path in id's to the root of the inheritance tree. */
  path?: Maybe<Scalars['String']['output']>;
  /** The slug of the usergroup. */
  slug?: Maybe<Scalars['String']['output']>;
  /** The slug of the usergroup. */
  tag?: Maybe<Scalars['String']['output']>;
  usergroup: Usergroup;
  /** The id of the usergroup. */
  usergroupId: Scalars['Int']['output'];
  usergroups: Array<Usergroup>;
};

export type UsergroupCompaniesArgs = {
  input?: InputMaybe<CompanySearchArguments>;
};

export type UsergroupSearchArguments = {
  /** Usergroup's name */
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  /** List of usergroup sort filters */
  sort?: InputMaybe<Array<UsergroupSortInput>>;
};

export type UsergroupSortInput = {
  /** Available sortable fields */
  field: UsergroupSortableField;
  /** Sort ordering ['ASC' or 'DESC'] */
  order?: SortOrder;
};

/** Usergroup sortable fields */
export enum UsergroupSortableField {
  Name = 'NAME',
}

export type UsergroupsResponse = {
  __typename?: 'UsergroupsResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type Usergroup */
  items: Array<Usergroup>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /**  The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type Valueset = {
  __typename?: 'Valueset';
  /** Valueset creation date */
  createdAt: Scalars['DateTime']['output'];
  /** The userId of the user that created the valueset */
  createdBy?: Maybe<Scalars['Int']['output']>;
  descriptions: Array<LocalizedString>;
  /** Valueset primary identifier */
  id: Scalars['Int']['output'];
  /** Valueset last modified date */
  lastModifiedAt: Scalars['DateTime']['output'];
  /** The userId of the user that changed the valueset */
  lastModifiedBy?: Maybe<Scalars['Int']['output']>;
  /** Valueset name */
  name: Scalars['String']['output'];
  /** Valueset type */
  type: ValuesetType;
  valuesetItems: ValuesetItemResponse;
};

export type ValuesetValuesetItemsArgs = {
  input?: InputMaybe<ValuesetItemSearchInput>;
};

export type ValuesetCreateInput = {
  /** Valueset descriptions per language */
  descriptions?: InputMaybe<Array<LocalizedStringInput>>;
  /** Valueset unique name */
  name: Scalars['String']['input'];
};

export type ValuesetItem = {
  __typename?: 'ValuesetItem';
  descriptions: Array<LocalizedString>;
  /** Valueset item extra value */
  extra?: Maybe<Scalars['String']['output']>;
  /** Valueset item primary identifier */
  id: Scalars['Int']['output'];
  /** Valueset item value */
  value: Scalars['String']['output'];
  /** Valueset foreign identifier */
  valuesetId: Scalars['Int']['output'];
};

export type ValuesetItemCreateInput = {
  /** Valueset items descriptions per language */
  descriptions?: InputMaybe<Array<LocalizedStringInput>>;
  /** Valueset item extra value */
  extra?: InputMaybe<Scalars['String']['input']>;
  /** Valueset item unique value */
  value: Scalars['String']['input'];
  /** Valueset id */
  valuesetId: Scalars['Int']['input'];
};

export type ValuesetItemResponse = {
  __typename?: 'ValuesetItemResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type ValuesetItem */
  items: Array<ValuesetItem>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type ValuesetItemSearchInput = {
  /** Search by extra values */
  extras?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search by valueset item ids */
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Pagination offset number. [Default to `12`] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination page number. [Default to `1`] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Inputs to sort by */
  sortInputs?: InputMaybe<Array<ValuesetItemSortInput>>;
  /** Search by values */
  values?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search by valueset ids */
  valuesetIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum ValuesetItemSortField {
  Extra = 'EXTRA',
  Id = 'ID',
  Value = 'VALUE',
}

export type ValuesetItemSortInput = {
  /** Field to sort by */
  field: ValuesetItemSortField;
  /** Order option to sort by. [Default to `ASC`] */
  order?: InputMaybe<ValuesetSortOrder>;
};

export type ValuesetItemUpdateInput = {
  /** Valueset items descriptions per language */
  descriptions?: InputMaybe<Array<LocalizedStringInput>>;
  /** Valueset item extra value */
  extra?: InputMaybe<Scalars['String']['input']>;
  /** Valueset item unique value */
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ValuesetResponse = {
  __typename?: 'ValuesetResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type Valueset */
  items: Array<Valueset>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type ValuesetSearchInput = {
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** Search by ids */
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Search by names */
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Pagination offset number. [Default to `12`] */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination page number. [Default to `1`] */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Inputs to sort by */
  sortInputs?: InputMaybe<Array<ValuesetSortInput>>;
  /** Valueset type. One of: [SYSTEM, CUSTOM] */
  type?: InputMaybe<ValuesetType>;
};

export enum ValuesetSortField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  LastModifiedAt = 'LAST_MODIFIED_AT',
  Name = 'NAME',
}

export type ValuesetSortInput = {
  /** Field to sort by */
  field: ValuesetSortField;
  /** Order option to sort by. [Default to `ASC`] */
  order?: InputMaybe<ValuesetSortOrder>;
};

export enum ValuesetSortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum ValuesetType {
  Custom = 'CUSTOM',
  System = 'SYSTEM',
}

export type ValuesetUpdateInput = {
  /** Valueset descriptions per language */
  descriptions?: InputMaybe<Array<LocalizedStringInput>>;
  /** Valueset unique name */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type VerifyToken = {
  __typename?: 'VerifyToken';
  aud: Scalars['String']['output'];
  auth_time: Scalars['Int']['output'];
  claims?: Maybe<Scalars['JSONObject']['output']>;
  email: Scalars['String']['output'];
  email_verified: Scalars['Boolean']['output'];
  exp: Scalars['Int']['output'];
  firebase?: Maybe<Firebase>;
  iat: Scalars['Int']['output'];
  iss: Scalars['String']['output'];
  name: Scalars['String']['output'];
  sub: Scalars['String']['output'];
  uid: Scalars['String']['output'];
  user_id: Scalars['String']['output'];
};

export type VerifyTokenInput = {
  token: Scalars['String']['input'];
};

export type Warehouse = {
  __typename?: 'Warehouse';
  /** Warehouse Address */
  address?: Maybe<WarehouseAddress>;
  /** Address identifier associated with the warehouse */
  addressId?: Maybe<Scalars['Int']['output']>;
  /** Warehouse business hours per week day */
  businessHours: Array<BusinessHours>;
  /** Warehouse initial creation timestamp */
  createdAt: Scalars['DateTime']['output'];
  /** Warehouse description */
  description: Scalars['String']['output'];
  /** Warehouse primary identifier */
  id: Scalars['ID']['output'];
  /** Warehouse isActive status */
  isActive: Scalars['Boolean']['output'];
  /** Warehouse isPickupLocation status */
  isPickupLocation: Scalars['Boolean']['output'];
  /** Warehouse isStore status */
  isStore: Scalars['Boolean']['output'];
  /** Warehouse last update timestamp */
  lastModifiedAt: Scalars['DateTime']['output'];
  /** Warehouse name */
  name: Scalars['String']['output'];
  /** Warehouse notes */
  notes: Scalars['String']['output'];
  /** Optional warehouse shopId */
  shopId?: Maybe<Scalars['Int']['output']>;
};

export type WarehouseAddress = {
  __typename?: 'WarehouseAddress';
  /** Address city */
  city: Scalars['String']['output'];
  /** Address code */
  code?: Maybe<Scalars['String']['output']>;
  /** Company the address applies to */
  company?: Maybe<Scalars['String']['output']>;
  /** Address country */
  country: Scalars['String']['output'];
  /** Email that applies to this address [valid email format needed] */
  email?: Maybe<Scalars['String']['output']>;
  /** Fax number that applies to this address [min: 6, max: 30] */
  fax?: Maybe<Scalars['String']['output']>;
  /** First name the address applies to */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Address gender */
  gender?: Maybe<Gender>;
  /** ICP address field defines whether tax should be applied to an order if the address is selected as a delivery address. Used when shipping b2b order abroad */
  icp?: Maybe<YesNo>;
  /** Address primary identifier */
  id: Scalars['Int']['output'];
  /** Last name the address applies to */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Middle name the address applies to */
  middleName?: Maybe<Scalars['String']['output']>;
  /** Mobile phone number that applies to this address [min: 6, max: 30] */
  mobile?: Maybe<Scalars['String']['output']>;
  /** Address friendly descriptive name */
  name?: Maybe<Scalars['String']['output']>;
  /** Address delivery notes */
  notes?: Maybe<Scalars['String']['output']>;
  /** Address street number */
  number?: Maybe<Scalars['String']['output']>;
  /** Address street number extension */
  numberExtension?: Maybe<Scalars['String']['output']>;
  /** Fixed phone number that applies to this address [min: 6, max: 30] */
  phone?: Maybe<Scalars['String']['output']>;
  /** Address postal code */
  postalCode: Scalars['String']['output'];
  /** Address region */
  region?: Maybe<Scalars['String']['output']>;
  /** Address street */
  street: Scalars['String']['output'];
  /** Address company domain url */
  url?: Maybe<Scalars['String']['output']>;
};

export type WarehousesResponse = {
  __typename?: 'WarehousesResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type Warehouse */
  items: Array<Warehouse>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type WarehousesSearchInput = {
  /** Search warehouses by ids */
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Warehouse isActive status */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Warehouse isPickupLocation status */
  isPickupLocation?: InputMaybe<Scalars['Boolean']['input']>;
  /** Warehouse isStore status */
  isStore?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** Boolean in Yes/No format */
export enum YesNo {
  N = 'N',
  Y = 'Y',
}

export type ZoneTaxCode = {
  __typename?: 'ZoneTaxCode';
  /** Creation date */
  createdAt: Scalars['DateTime']['output'];
  /** Primary identifier */
  id: Scalars['String']['output'];
  /** Last modified date */
  lastModifiedAt: Scalars['DateTime']['output'];
  /** Price foreign identifier */
  priceId: Scalars['String']['output'];
  /** Tax code for the specified zone */
  taxCode: Taxcode;
  /** Zone code */
  zone: Scalars['String']['output'];
};

export type ZoneTaxCodeCreateInput = {
  /** Price id */
  priceId: Scalars['String']['input'];
  /** Tax code */
  taxCode: Taxcode;
  /** Zone code */
  zone: Scalars['String']['input'];
};

export type ZoneTaxCodeResponse = {
  __typename?: 'ZoneTaxCodeResponse';
  /** End position of the current page */
  end: Scalars['Int']['output'];
  /** List of items of type ZoneTaxCode */
  items: Array<ZoneTaxCode>;
  /** Total number of items found */
  itemsFound: Scalars['Int']['output'];
  /** The amount of items to show per page */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** Total amount of pages */
  pages: Scalars['Int']['output'];
  /** Start position of the current page */
  start: Scalars['Int']['output'];
};

export type ZoneTaxCodeSearchInput = {
  /** Search by date created */
  createdAt?: InputMaybe<DateSearchInput>;
  /** Search by Zone tax code ids */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Search by date changed */
  lastModifiedAt?: InputMaybe<DateSearchInput>;
  /** Pagination offset number */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** Pagination page number */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Search by price ids */
  priceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Inputs to sort by */
  sortInputs?: InputMaybe<Array<ZoneTaxCodeSortInput>>;
  /** Search by tax code */
  taxCodes?: InputMaybe<Array<Taxcode>>;
  /** Search by zone code */
  zones?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum ZoneTaxCodeSortField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  LastModifiedAt = 'LAST_MODIFIED_AT',
  TaxCode = 'TAX_CODE',
  Zone = 'ZONE',
}

export type ZoneTaxCodeSortInput = {
  /** Field to sort by */
  field: ZoneTaxCodeSortField;
  /** Order option to sort by. [Default to `ASC`] */
  order: SortOrder;
};

export type ZoneTaxCodeUpdateInput = {
  /** Tax code */
  taxCode?: InputMaybe<Taxcode>;
  /** Zone code */
  zone?: InputMaybe<Scalars['String']['input']>;
};
