import { Injectable } from '@angular/core';
import { Actions, createEffect, EffectNotification, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, exhaustMap, filter, map, Observable, of, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs';
import * as CarriersActions from './carriers.actions';
import * as fromApp from '../../../store/app.reducer';
import { CarriersService } from '../carriers.service';
import { QueryRef } from 'apollo-angular';
import { CarrierListResponse } from 'src/app/shared/api/types/types';

@Injectable()
export class CarriersEffects {
  carriersQuery: QueryRef<CarrierListResponse>;
  carriersQuerySub: Observable<Action>;

  constructor(
    private actions: Actions,
    private carriersService: CarriersService,
    private store: Store<fromApp.AppState>,
  ) {}

  public readonly loadCarriers: Observable<Action> = createEffect(() => {
    return this.actions.pipe(
      ofType(CarriersActions.LOAD_CARRIERS),
      withLatestFrom(this.store.select((state) => state.carriers.loaded)),
      filter(([action, loaded]) => !loaded),
      switchMap(([action, loaded]: [CarriersActions.LoadCarriers, boolean]) => {
        this.carriersQuery = this.carriersService.getCarriersList(action.payload);
        this.carriersQuerySub = this.carriersQuery.valueChanges.pipe(
          map((data) => {
            this.store.dispatch(new CarriersActions.GetCarriersCount(data.data.carriers.itemsFound));
            return new CarriersActions.LoadCarriersSuccess(data.data.carriers.items);
          }),
          catchError((error) => of(new CarriersActions.LoadCarriersFailure(error))),
        );
        return this.carriersQuerySub;
      }),
    );
  });

  public readonly refetchBusiinessRules = createEffect(
    () =>
      this.actions.pipe(
        ofType(CarriersActions.REFETCH_CARRIERS),
        tap((action: CarriersActions.RefetchCarriers) => {
          this.carriersQuery.resetLastResults();
          this.carriersQuery.refetch(action.payload);
        }),
      ),
    { dispatch: false },
  );

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>): Observable<EffectNotification> {
    return this.actions.pipe(
      ofType(CarriersActions.CARRIERS_PAGE_INITIALIZED),
      exhaustMap(() =>
        resolvedEffects$.pipe(takeUntil(this.actions.pipe(ofType(CarriersActions.CARRIERS_PAGE_DESTROYED)))),
      ),
    );
  }
}
