import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { filter, Observable, switchMap } from 'rxjs';
import * as fromApp from '../../store/app.reducer';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(
    private store: Store<fromApp.AppState>,
    private router: Router,
    private authService: AuthService,
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return new Observable<boolean | UrlTree>((obs) => {
      this.store
        .select((store) => store.auth.isInitializing)
        .pipe(filter((isInit) => !isInit))
        .subscribe(() => {
          if ((route.data.role && this.authService.checkUserRole(route.data.role)) || !route.data.role) {
            obs.next(true);
          } else {
            obs.next(this.router.createUrlTree(['/404']));
          }
        });
    });
  }
}
