import { Action } from '@ngrx/store';
import { OrderStatusSet, OrderStatusSetSearchByInput } from 'src/app/shared/api/types/GraphQL';

export const GET_CURRENT_PAGE = '[OrderStatusSet] Get Current Page';
export const GET_CURRENT_PAGE_SIZE = '[OrderStatusSet] Get Current Page Size';
export const GET_ORDER_STATUS_SET_COUNT = '[OrderStatusSet] Get OrderStatusSet Count';
export const LOAD_ORDER_STATUS_SET = '[OrderStatusSet] Load OrderStatusSet';
export const REFETCH_ORDER_STATUS_SET = '[OrderStatusSet] Refetch OrderStatusSet';
export const LOAD_ORDER_STATUS_SET_SUCCESS = '[OrderStatusSet] Load OrderStatusSet Success';
export const LOAD_ORDER_STATUS_SET_FAILURE = '[OrderStatusSet] Load OrderStatusSet Failure';

export const ADD_ORDER_STATUS_SET = '[OrderStatusSet] Add OrderStatusSet';
export const CREATE_ORDER_STATUS_SET = '[OrderStatusSet] Create OrderStatusSet';
export const UPDATE_ORDER_STATUS_SET = '[OrderStatusSet] Update OrderStatusSet';
export const DELETE_ORDER_STATUS_SET = '[OrderStatusSet] Delete OrderStatusSet';

export const GET_GENERAL_FILTER = '[OrderStatusSet] Get General Filter';
export const ADD_GENERAL_FILTER = '[OrderStatusSet] Add General Filter';
export const REMOVE_GENERAL_FILTER = '[OrderStatusSet] Remove General Filter';

export const ADD_ORDER_STATUS_SET_TO_LIST = '[OrderStatusSet] Add Selected OrderStatusSet to List';
export const DELETE_ORDER_STATUS_SET_FROM_LIST = '[OrderStatusSet] Delete OrderStatusSet from List';
export const CLEAR_ORDER_STATUS_SET_LIST = '[OrderStatusSet] Clear OrderStatusSet List';

export class GetCurrentPage implements Action {
  readonly type = GET_CURRENT_PAGE;

  constructor(public payload: number) {}
}

export class GetCurrentPageSize implements Action {
  readonly type = GET_CURRENT_PAGE_SIZE;

  constructor(public payload: number) {}
}

export class GetOrderStatusSetCount implements Action {
  readonly type = GET_ORDER_STATUS_SET_COUNT;

  constructor(public payload: number) {}
}

export class LoadOrderStatusSet implements Action {
  readonly type = LOAD_ORDER_STATUS_SET;

  constructor(public payload: {}) {}
}

export class RefetchOrderStatusSet implements Action {
  readonly type = REFETCH_ORDER_STATUS_SET;

  constructor(public payload: {}) {}
}

export class LoadOrderStatusSetSuccess implements Action {
  readonly type = LOAD_ORDER_STATUS_SET_SUCCESS;

  constructor(public payload: OrderStatusSet[]) {}
}

export class LoadOrderStatusSetFailure implements Action {
  readonly type = LOAD_ORDER_STATUS_SET_FAILURE;

  constructor(public payload: Error) {}
}

export class AddOrderStatusSet implements Action {
  readonly type = ADD_ORDER_STATUS_SET;

  constructor(public payload: OrderStatusSet) {}
}
export class CreateOrderStatusSet implements Action {
  readonly type = CREATE_ORDER_STATUS_SET;

  constructor(public payload: OrderStatusSet) {}
}
export class UpdateOrderStatusSet implements Action {
  readonly type = UPDATE_ORDER_STATUS_SET;

  constructor(public payload: { id: number; updatedOrderStatusSet: OrderStatusSet }) {}
}
export class DeleteOrderStatusSet implements Action {
  readonly type = DELETE_ORDER_STATUS_SET;

  constructor(public payload: OrderStatusSet) {}
}

export class AddOrderStatusSetToList implements Action {
  readonly type = ADD_ORDER_STATUS_SET_TO_LIST;

  constructor(public payload: OrderStatusSet) {}
}

export class DeleteOrderStatusSetFromList implements Action {
  readonly type = DELETE_ORDER_STATUS_SET_FROM_LIST;

  constructor(public payload: number) {}
}

export class ClearOrderStatusSetList implements Action {
  readonly type = CLEAR_ORDER_STATUS_SET_LIST;
}

export class GetGeneralFilter implements Action {
  readonly type = GET_GENERAL_FILTER;

  constructor(public payload: string) {}
}
export class AddGeneralFilter implements Action {
  readonly type = ADD_GENERAL_FILTER;

  constructor(public payload: OrderStatusSetSearchByInput | any) {}
}
export class RemoveGeneralFilter implements Action {
  readonly type = REMOVE_GENERAL_FILTER;

  constructor(public payload: { columnName: string; index?: number }) {}
}

export type OrderStatusSetActions =
  | GetGeneralFilter
  | AddGeneralFilter
  | RemoveGeneralFilter
  | GetCurrentPage
  | GetCurrentPageSize
  | GetOrderStatusSetCount
  | LoadOrderStatusSet
  | RefetchOrderStatusSet
  | LoadOrderStatusSetSuccess
  | LoadOrderStatusSetFailure
  | AddOrderStatusSet
  | UpdateOrderStatusSet
  | DeleteOrderStatusSet
  | AddOrderStatusSetToList
  | DeleteOrderStatusSetFromList
  | ClearOrderStatusSetList
  | CreateOrderStatusSet;
