import { MatCheckboxChange } from '@angular/material/checkbox';
import * as FavoritesActions from '../../crm/favorites/store/favorites.actions';
import * as UsersActions from '../../crm/users/store/users/users.actions';
import * as TaxesActions from '../../pricing/taxes/store/taxes.actions';
import * as ValueSetActions from '../../admin/value-set/store/value-set/value-set.actions';
import * as OrderlistsActions from '../../crm/orderlists/store/orderlists.actions';
import * as ValueSetItemActions from '../../admin/value-set/store/value-set-item/value-set-item.actions';
import * as PayMethodsActions from './../../order-management/paymethods/store/paymethods.actions';
import * as SurchargesActions from '../../pricing/surcharges/store/surcharges.actions';
import * as OrderStatusActions from '../../order-management/order-status/store/order-status/order-status.actions';
import * as OrderStatusSetActions from '../../order-management/order-status/store/order-status-set/order-status-set.actions';
import * as AdminUserActions from 'src/app/admin/backoffice-users/store/backoffice-users.actions';
import * as AttributeDescriptionsActions from 'src/app/admin/attributes/store/attributes.actions';
import * as CarriersActions from '../../order-management/carriers/store/carriers.actions';
import * as PriceSheetActions from '../../pricing/pricing/pricesheets/store/price-sheet.actions';

import { Action } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import { ListingTypes } from '../api/types/enums';
import {
  FavoriteList,
  Orderlist,
  Surcharge,
  Tax,
  Valueset,
  ValuesetItem,
  PayMethod,
  Carrier,
  AdminUser,
  AttributeDescription,
  OrderStatus,
  OrderStatusSet,
  Pricesheet,
} from '../api/types/GraphQL';

type ItemType =
  | Tax
  | Surcharge
  | FavoriteList
  | UsersActions.User
  | Valueset
  | ValuesetItem
  | Orderlist
  | PayMethod
  | Carrier
  | AdminUser
  | AttributeDescription
  | OrderStatus
  | OrderStatusSet
  | Pricesheet;

@Injectable({
  providedIn: 'root',
})
export class CheckBoxService {
  presetSelectedList = [];

  constructor(private store: Store<fromApp.AppState>) {}

  private actionsMap: Record<
    string,
    {
      addAction: (item: ItemType, stateEntry?, selectedTab?) => Action;
      deleteAction: (itemId: string | number, stateEntry?, selectedTab?) => Action;
      clearAction: (stateEntry?, selectedTab?) => Action;
    }
  > = {
    [ListingTypes.favorites]: {
      addAction: (item: FavoriteList, stateEntry) =>
        new FavoritesActions.AddFavoriteListToSelectedLists({
          selectedFavoriteListsList: item,
          stateEntry: stateEntry,
        }),
      deleteAction: (itemId: any, stateEntry) =>
        new FavoritesActions.DeleteFavoriteListFromSelectedLists({ id: itemId, stateEntry: stateEntry }),
      clearAction: (stateEntry) => new FavoritesActions.ClearFavoriteListsList({ stateEntry: stateEntry }),
    },
    [ListingTypes.taxes]: {
      addAction: (item: Tax) => new TaxesActions.AddTaxToSelectedList(item),
      deleteAction: (itemId: string) => new TaxesActions.DeleteTaxFromSelectedList(itemId),
      clearAction: () => new TaxesActions.ClearSelectedList(),
    },
    [ListingTypes.users]: {
      addAction: (item: UsersActions.User, stateEntry) =>
        new UsersActions.AddUserToUserList({ selectedUsersList: item, stateEntry: stateEntry }),
      deleteAction: (itemId: number, stateEntry) =>
        new UsersActions.DeleteUserFromUserList({ id: itemId, stateEntry: stateEntry }),
      clearAction: (stateEntry) => new UsersActions.ClearSelectedUsersList({ stateEntry: stateEntry }),
    },
    [ListingTypes.orderlists]: {
      addAction: (item: any) => new OrderlistsActions.AddOrderListToSelectedLists(item),
      deleteAction: (itemId: number) => new OrderlistsActions.DeleteOrderListFromSelectedLists(itemId),
      clearAction: () => new OrderlistsActions.ClearOrderlistsList(),
    },
    [ListingTypes.valueSet]: {
      addAction: (item: Valueset) => new ValueSetActions.AddValueSetToList(item),
      deleteAction: (itemId: number) => new ValueSetActions.DeleteValueSetFromList(itemId),
      clearAction: (stateEntry) => new ValueSetActions.ClearValueSetList({ stateEntry: stateEntry }),
    },
    [ListingTypes.valueSetItem]: {
      addAction: (item: ValuesetItem) => new ValueSetItemActions.AddValueSetItemToList(item),
      deleteAction: (itemId: number) => new ValueSetItemActions.DeleteValueSetItemFromList(itemId),
      clearAction: () => new ValueSetItemActions.ClearValueSetItemList(),
    },
    [ListingTypes.surcharges]: {
      addAction: (item: Surcharge) => new SurchargesActions.AddSurchargeToList(item),
      deleteAction: (itemId: string) => new SurchargesActions.DeleteSurchargeFromList(itemId),
      clearAction: () => new SurchargesActions.ClearSurchargeList(),
    },
    [ListingTypes.paymethods]: {
      addAction: (item: PayMethod) => new PayMethodsActions.AddPayMethodToList(item),
      deleteAction: (itemId: number) => new PayMethodsActions.DeletePayMethodFromList(itemId),
      clearAction: () => new PayMethodsActions.ClearPayMethodList(),
    },
    [ListingTypes.carriers]: {
      addAction: (item: Carrier) => new CarriersActions.AddCarrierToList(item),
      deleteAction: (itemId: number) => new CarriersActions.DeleteCarrierFromList(itemId),
      clearAction: () => new CarriersActions.ClearCarriersList(),
    },
    [ListingTypes.adminUsers]: {
      addAction: (item: AdminUser) => new AdminUserActions.AddBackofficeUserToList(item),
      deleteAction: (itemId: string) => new AdminUserActions.DeleteBackofficeUserFromList(itemId),
      clearAction: () => new AdminUserActions.ClearBackofficeUsersList(),
    },
    [ListingTypes.attributeDescriptions]: {
      addAction: (item: AttributeDescription, stateEntry, selectedTab) =>
        new AttributeDescriptionsActions.AddAttributeDescriptionToList({ item, attrClass: selectedTab }),
      deleteAction: (itemId: string, stateEntry, selectedTab) =>
        new AttributeDescriptionsActions.DeleteAttributeDescriptionFromList({ id: itemId, attrClass: selectedTab }),
      clearAction: (stateEntry, selectedTab) =>
        new AttributeDescriptionsActions.ClearAttributeDescriptionsList(selectedTab),
    },
    [ListingTypes.orderStatus]: {
      addAction: (item: OrderStatus) => new OrderStatusActions.AddOrderStatusToList(item),
      deleteAction: (itemId: number) => new OrderStatusActions.DeleteOrderStatusFromList(itemId),
      clearAction: () => new OrderStatusActions.ClearOrderStatusList(),
    },
    [ListingTypes.orderStatusSet]: {
      addAction: (item: OrderStatusSet) => new OrderStatusSetActions.AddOrderStatusSetToList(item),
      deleteAction: (itemId: number) => new OrderStatusSetActions.DeleteOrderStatusSetFromList(itemId),
      clearAction: () => new OrderStatusSetActions.ClearOrderStatusSetList(),
    },
    [ListingTypes.priceSheet]: {
      addAction: (item: Pricesheet) => new PriceSheetActions.AddPriceSheetToList(item),
      deleteAction: (itemId: number) => new PriceSheetActions.DeletePriceSheetFromList(itemId),
      clearAction: () => new PriceSheetActions.ClearPriceSheetList(),
    },
  };

  checkboxChange(list: any, event: MatCheckboxChange, listType: string, stateEntry?: any, itemClass?: any) {
    const { addAction, deleteAction } = this.actionsMap[listType];
    if (event.checked) {
      this.store.dispatch(addAction(list, stateEntry, itemClass));
    } else {
      this.store.dispatch(deleteAction(this.getItemId(list), stateEntry, itemClass));
    }
  }

  masterCheckboxChange(
    list: ItemType[],
    event: MatCheckboxChange,
    listType: string,
    stateEntry?: any,
    itemClass?: any,
  ) {
    const { addAction, deleteAction } = this.actionsMap[listType];

    if (event) {
      if (event.checked) {
        list
          .filter((item) => !this.isPreset(item) && !this.isValuesetTypeSystem(item))
          .forEach((element: ItemType) => {
            this.store.dispatch(addAction(element, stateEntry, itemClass));
          });
      }
      if (!event.checked) {
        list.forEach((element: ItemType) => {
          this.store.dispatch(deleteAction(this.getItemId(element), stateEntry, itemClass));
        });
      }
    }
  }

  isAllInBasket(data: any, selectedItems: ItemType[]) {
    if (data.length) {
      for (let item of data) {
        if (!this.checkIfSelected(item, selectedItems) || this.isPreset(item)) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  checkIfSelected(item, selectedItems: any[]) {
    return selectedItems.some((e) => this.getItemId(e) === this.getItemId(item)) || this.isPreset(item);
  }

  getItemId(item) {
    if (!item.hasOwnProperty('id')) {
      if ('contactId' in item) return item.contactId;
      else if ('companyId' in item) return item.companyId;
      else if ('customerId' in item) return item.customerId;
    }

    return item.id;
  }

  clearSelectionList(listType: ListingTypes, stateEntry?: any, itemsClass?: any) {
    const { clearAction } = this.actionsMap[listType];
    this.store.dispatch(clearAction(stateEntry, itemsClass));
  }

  shouldDisableCheckbox(row, listType: ListingTypes) {
    switch (listType) {
      case ListingTypes.users:
        return this.isPreset(row);
      case ListingTypes.valueSet:
        return this.isValuesetTypeSystem(row);

      default:
        false;
    }
  }

  isPreset(item): boolean {
    if (this.presetSelectedList) return this.presetSelectedList.some((e) => e.id === item.id);
  }

  isValuesetTypeSystem(item): boolean {
    return item.type === 'SYSTEM';
  }
}
