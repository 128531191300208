import { Component, Input } from '@angular/core';

import { formatDate } from '@angular/common';
import { EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import * as fromApp from 'src/app/store/app.reducer';
import * as UsersActions from '../../../store/users/users.actions';
import { Store } from '@ngrx/store';
import { FormControl, FormGroup } from '@angular/forms';
import { cloneDeep } from '@apollo/client/utilities';
import { ENTER, COMMA, TAB } from '@angular/cdk/keycodes';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { AddressType, CompanySearchInput, CompanyTermFieldName } from 'src/app/shared/api/types/GraphQL';
import { SnackBarService } from 'src/app/shared/components/snack-bar/snack-bar.service';
import { MatOption } from '@angular/material/core';
import { UserStateEntry, UserTypesPlural } from 'src/app/shared/api/types/enums';

type FilterOption = { label: string; value: boolean | string };

@Component({
  selector: 'app-company-filter',
  templateUrl: './company-filter.component.html',
  styleUrls: ['./company-filter.component.scss'],
})
export class CompanyFilterComponent implements OnInit {
  @Output() goOnFirstPage = new EventEmitter<void>();
  @Input() tab: UserTypesPlural;
  @Input() stateEntry: UserStateEntry = UserStateEntry.userListing;

  filtersDrawerOpened: boolean = false;
  isFilterSelectDisabled: boolean = false;
  numOfSelectedFilters: number = 0;
  searchFilterInput$ = new Subject<any>();
  queryVariables;
  page: number;
  itemsPerPage: number;

  companiesFilters: CompanySearchInput | any = {};
  AddressType = AddressType;

  separatorKeysCodes: number[] = [ENTER, COMMA, TAB];

  singularFilters = [
    // Add more filters if needed
  ];
  arrayedFilters = [
    // Add more filters if needed
  ];
  optionFilters: {
    title: string;
    filterKey: string;
    options: FilterOption[];
  }[] = [
    // {
    //   title: 'Address is default',
    //   filterKey: 'addressIsDefault',
    //   options: [
    //     { label: 'Is Default', value: YesNo.Y },
    //     { label: 'Is Not Default', value: YesNo.N },
    //   ],
    // },
    // {
    //   title: 'Address active',
    //   filterKey: 'addressActive',
    //   options: [
    //     { label: 'Is Address active', value: YesNo.Y },
    //     { label: 'Is Not Address active', value: YesNo.N },
    //   ],
    // },
    // Add more filters here...
  ];

  checkBoxFilters = [
    // {
    //   title: 'Address types',
    //   subTitle: 'Address type',
    //   filterKey: 'addressTypes',
    //   options: [
    //     { name: 'Delivery address', value: AddressType.Delivery, isChecked: false },
    //     { name: 'Invoice address', value: AddressType.Home, isChecked: false },
    //     { name: 'Home address', value: AddressType.Invoice, isChecked: false },
    //   ],
    // },
  ];

  dateFilters: {
    title: string;
    filterKey: string;
    control: FormGroup;
  }[] = [
    {
      title: 'Company Created At',
      filterKey: 'companyCreatedAt',
      control: new FormGroup({
        lessThan: new FormControl<Date | null>(null),
        greaterThan: new FormControl<Date | null>(null),
      }),
    },
    {
      title: 'Company Last Modified At',
      filterKey: 'companyLastModifiedAt',
      control: new FormGroup({
        lessThan: new FormControl<Date | null>(null),
        greaterThan: new FormControl<Date | null>(null),
      }),
    },
    {
      title: 'Contact Last Modified At',
      filterKey: 'contactCreatedAt',
      control: new FormGroup({
        lessThan: new FormControl<Date | null>(null),
        greaterThan: new FormControl<Date | null>(null),
      }),
    },
    {
      title: 'Contact Last Modified At',
      filterKey: 'contactLastModifiedAt',
      control: new FormGroup({
        lessThan: new FormControl<Date | null>(null),
        greaterThan: new FormControl<Date | null>(null),
      }),
    },
    {
      title: 'Contact Date Of Birth',
      filterKey: 'contactDateOfBirth',
      control: new FormGroup({
        lessThan: new FormControl<Date | null>(null),
        greaterThan: new FormControl<Date | null>(null),
      }),
    },
  ];

  filtersCtrl: any = {};
  selectedOptions: { [key: string]: any } = {};
  companiesSearchFields = this.createOptions(CompanyTermFieldName);
  selectedFields: any;

  @ViewChild('allSelected') private allSelected: MatOption;

  private destroySubject: Subject<void> = new Subject();

  constructor(
    public snackBarService: SnackBarService,
    private store: Store<fromApp.AppState>,
  ) {
    this.singularFilters.forEach((filter) => {
      this.filtersCtrl[filter.filterKey] = new FormControl();
    });
    this.arrayedFilters.forEach((filter) => {
      this.filtersCtrl[filter.filterKey] = new FormControl();
    });
    this.optionFilters.forEach((filter) => {
      this.selectedOptions[filter.filterKey] = null;
    });

    this.dateFilters.forEach((filter) => {
      this.filtersCtrl[filter.filterKey] = filter.control;
    });
  }

  ngOnInit() {
    this.initializeFilterData();
    this.searchFilterInput$
      .pipe(
        debounceTime(300), // Adjust the debounce time as needed (e.g., 300 milliseconds)
        distinctUntilChanged(), // Only emit if the current value is different from the last
      )
      .subscribe((event) => {
        this.applyGeneralFilter(event.target.value);
      });
  }

  private initializeFilterData(): void {
    this.selectedFields = this.companiesSearchFields.map((field) => field.value);
    this.store
      .select((store) => store.users[this.stateEntry].users.companies)
      .pipe(takeUntil(this.destroySubject))
      .subscribe(({ currentPage, currentPageSize, companiesFilters }) => {
        this.page = currentPage;
        this.itemsPerPage = currentPageSize;
        this.companiesFilters = cloneDeep(companiesFilters);
      });

    this.selectedFields = this.companiesFilters.termFields?.fieldNames
      ? this.companiesFilters.termFields.fieldNames
      : this.companiesSearchFields.map((field) => field.value);

    this.checkBoxFilters.forEach((filter) => {
      const filterData = this.companiesFilters[filter.filterKey];
      if (filterData && Array.isArray(filterData)) {
        filter.options.forEach((option) => {
          if (filterData.includes(option.value)) {
            option.isChecked = true;
          }
        });
      }
    });

    this.numOfSelectedFilters = this.selectedFiltersCount();
  }

  filterOutEmptyValues(object) {
    return Object.fromEntries(
      Object.entries(object).filter(([key, value]) => {
        return this.isNotEmpty(value);
      }),
    );
  }

  toggleFiltersDrawer() {
    if (this.isFilterSelectDisabled) return;
    this.filtersDrawerOpened = !this.filtersDrawerOpened;
  }

  add(event: any, filterValue: any, columnName: string, type?: string): void {
    let value;
    if (typeof filterValue === 'boolean') {
      value = filterValue;
    } else if (columnName === 'termFields') {
      value = filterValue;
    } else if (type === 'datePicker' || type === 'checkbox') {
      value = filterValue;
    } else {
      // If event value exists, trim it and assign it to value
      value = (event.value || '').trim();
    }

    this.handleColumnFilter(columnName, value);

    this.store.dispatch(
      new UsersActions.AddGeneralFilter({ filters: this.companiesFilters, tab: this.tab, stateEntry: this.stateEntry }),
    );

    this.applyColumnFilter();

    if (event?.chipInput?.clear) {
      event.chipInput.clear();
    }
    if (type !== 'datePicker') {
      this.resetFormControls();
    }
  }

  applyColumnFilter() {
    // Create a deep clone of the value set filters
    const filtersData = cloneDeep(this.companiesFilters);
    const filteredFilters = this.filterOutEmptyValues(filtersData);

    // Construct variables for dispatching
    const variablesToDispatch = {
      page: this.page,
      offset: this.itemsPerPage,
      ...this.queryVariables,
      ...filteredFilters,
    };
    const keys = Object.keys(filteredFilters);
    if (keys.length === 0) {
      this.store.dispatch(
        new UsersActions.RefetchUsers({ variables: variablesToDispatch, stateEntry: this.stateEntry }),
      );
    } else {
      this.store.dispatch(new UsersActions.RefetchCompanySearch(variablesToDispatch, this.tab, this.stateEntry));
    }
    this.numOfSelectedFilters = this.selectedFiltersCount();
    this.goOnFirstPage.emit();
  }

  applyGeneralFilter(filterValue: string) {
    if (filterValue !== '' && filterValue.length > 2) {
      this.companiesFilters.term = filterValue;
      this.store.dispatch(
        new UsersActions.AddGeneralFilter({
          filters: this.companiesFilters,
          tab: this.tab,
          stateEntry: this.stateEntry,
        }),
      );
      this.applyColumnFilter();
    } else if (filterValue === '') {
      this.store.dispatch(
        new UsersActions.RemoveGeneralFilter({ columnName: 'term', tab: this.tab, stateEntry: this.stateEntry }),
      );
      this.applyColumnFilter();
    }
  }

  // Helper function to check if a value is not null, undefined, empty string, empty array, or empty object
  isNotEmpty(value) {
    if (value === null || value === undefined) {
      return false;
    }
    if (typeof value === 'string' && value.trim() === '') {
      return false;
    }
    if (Array.isArray(value) && value.length === 0) {
      return false;
    }
    if (typeof value === 'object' && this.isEmpty(value)) {
      return false;
    }
    return true;
  }

  isEmpty(obj) {
    if (obj === null || obj === undefined) {
      return true;
    }
    if (typeof obj === 'string' || Array.isArray(obj)) {
      return obj.length === 0;
    }

    if (typeof obj === 'number') {
      return isNaN(obj);
    }

    if (typeof obj === 'object') {
      return Object.keys(obj).length === 0;
    }

    return false; // Handle other types if needed
  }

  private handleColumnFilter(columnName: string, value: any): void {
    let filterType: 'singular' | 'arrayed' | 'option' | 'checkbox' | 'datepicker' | 'unknown' = 'unknown';
    let type: 'number' | 'date' | 'unknown' = 'unknown';
    // Determine the filter type based on the columnName
    if (this.singularFilters.find((filter) => filter.filterKey === columnName)) {
      filterType = 'singular';
    } else if (this.arrayedFilters.find((filter) => filter.filterKey === columnName)) {
      filterType = 'arrayed';
      const arrayedFilter = this.arrayedFilters.find((filter) => filter.filterKey === columnName);
      if (arrayedFilter && arrayedFilter.type === 'number') {
        type = 'number';
      }
    } else if (this.optionFilters.find((filter) => filter.filterKey === columnName)) {
      filterType = 'option';
    } else if (this.checkBoxFilters.find((filter) => filter.filterKey === columnName)) {
      filterType = 'checkbox';
    } else if (this.dateFilters.find((filter) => filter.filterKey === columnName)) {
      filterType = 'datepicker';
      type = 'date';
    }

    switch (filterType) {
      case 'singular':
      case 'option':
        this.companiesFilters[columnName] = value;
        break;
      case 'arrayed':
      case 'checkbox':
        if (!Array.isArray(this.companiesFilters[columnName])) {
          this.companiesFilters[columnName] = [];
        }
        if (type === 'number') {
          value = this.handleNumericColumnFilter(columnName, value);
        } else {
          this.companiesFilters[columnName].push(value);
        }
        break;
      case 'datepicker':
        this.handleDateColumnFilter(columnName, value);
        break;
      case 'unknown':
        switch (columnName) {
          case 'termFields':
            this.handleSearchFieldsFilter(columnName, value);
            break;
          case 'price':
            this.handlePriceFilter(columnName, value);
            break;
          case 'language':
            this.handleLanguageFilter(columnName, value);
            break;
        }
        break;
      default:
        console.error('Unknown filter type for column:', columnName);
        break;
    }
  }

  private handleNumericColumnFilter(columnName: string, value: string): void {
    let numericValue = parseFloat(value);
    if (!isNaN(numericValue)) {
      this.companiesFilters[columnName].push(numericValue);
    } else {
      this.snackBarService.openSnackBar(`Invalid numeric value: ${value}`, '', 'error');
      console.error('Invalid numeric value:', value);
    }
  }

  private handleDateColumnFilter(
    columnName: string,
    dateRange: { lessThan: Date | null; greaterThan: Date | null },
  ): void {
    let formattedDateRange: { lessThan: string; greaterThan: string } = {
      lessThan: '',
      greaterThan: '',
    };

    try {
      if (dateRange.lessThan) {
        formattedDateRange.lessThan = formatDate(dateRange.lessThan, 'yyyy-MM-ddTHH:mm:ss.SSSZ', 'en-US');
      }
      if (dateRange.greaterThan) {
        formattedDateRange.greaterThan = formatDate(dateRange.greaterThan, 'yyyy-MM-ddTHH:mm:ss.SSSZ', 'en-US');
      }
    } catch (error) {
      console.error('Error formatting date:', error);
      return;
    }
    if (!dateRange.lessThan && !dateRange.greaterThan) return;

    this.companiesFilters[columnName] = formattedDateRange;
  }

  private handleSearchFieldsFilter(columnName: string, searchFields: { fieldNames: CompanyTermFieldName }): void {
    let searchField: { fieldNames: {}; boost: Number } = {
      fieldNames: searchFields,
      boost: 1,
    };

    this.companiesFilters[columnName] = searchField;
  }

  private handlePriceFilter(columnName: string, data): void {
    this.companiesFilters[columnName] = data;
  }

  private handleLanguageFilter(columnName: string, data): void {
    this.companiesFilters[columnName] = data;
  }

  resetFormControls(): void {
    Object.keys(this.filtersCtrl).forEach((key) => {
      this.filtersCtrl[key].reset();
    });
  }

  remove(columnName: string): void {
    this.store.dispatch(
      new UsersActions.RemoveGeneralFilter({ columnName, tab: this.tab, stateEntry: this.stateEntry }),
    );
    this.applyColumnFilter();
  }

  removeArray(index: number, columnName: string): void {
    this.store.dispatch(
      new UsersActions.RemoveGeneralFilter({ columnName, tab: this.tab, index, stateEntry: this.stateEntry }),
    );
    this.applyColumnFilter();
  }

  removeRadioButton(columnName: string): void {
    this.store.dispatch(
      new UsersActions.RemoveGeneralFilter({ columnName, tab: this.tab, stateEntry: this.stateEntry }),
    );
    this.selectedOptions[columnName] = null;

    this.applyColumnFilter();
  }

  removeCheckBox(filterKey: string, option: any) {
    const filter = this.companiesFilters[filterKey];
    if (filter) {
      const indexToRemove = filter.findIndex((opt) => opt === option.value);

      if (indexToRemove !== -1) {
        this.store.dispatch(
          new UsersActions.RemoveGeneralFilter({
            columnName: filterKey,
            index: indexToRemove,
            tab: this.tab,
            stateEntry: this.stateEntry,
          }),
        );
        option.isChecked = false;
        this.applyColumnFilter();
      }
    }
  }
  removeDatePicker(filterKey: string): void {
    this.store.dispatch(
      new UsersActions.RemoveGeneralFilter({ columnName: filterKey, tab: this.tab, stateEntry: this.stateEntry }),
    );
    this.companiesFilters[filterKey] = null;
    this.filtersCtrl[filterKey].reset();
    this.applyColumnFilter();
  }

  toggleCheckbox(filterKey: string, option: any) {
    if (option.isChecked) {
      this.add(null, option.value, filterKey, 'checkbox');
    } else {
      this.removeCheckBox(filterKey, option);
    }
  }

  toggleSelect() {
    if (this.selectedFields.length === this.companiesSearchFields.length) {
      this.selectedFields = [];
      this.store.dispatch(
        new UsersActions.RemoveGeneralFilter({ columnName: 'termFields', tab: this.tab, stateEntry: this.stateEntry }),
      );
      this.applyColumnFilter();
    } else {
      this.selectedFields = this.companiesSearchFields.map((field) => field.value);
      this.add('term', this.selectedFields, 'termFields');
    }
  }

  togglePerOne() {
    if (this.allSelected?.selected) {
      this.allSelected.deselect();
      return false;
    }
    if (this.selectedFields?.length) {
      this.allSelected?.select();
      this.add('term', this.selectedFields, 'termFields');
    }
  }

  private createOptions(enumObject: any) {
    return Object.keys(enumObject).map((key) => ({
      value: enumObject[key],
      label: key,
    }));
  }

  // Function to count selected Filters
  selectedFiltersCount(): number {
    let count = 0;

    // Iterate over the keys of filters
    for (const key in this.companiesFilters) {
      // Check if the value exists and is not empty or undefined
      if (key === 'termFields') {
        continue;
      }
      if (
        this.companiesFilters.hasOwnProperty(key) &&
        this.companiesFilters[key] !== null &&
        this.companiesFilters[key] !== undefined &&
        this.companiesFilters[key] !== 'termFields'
      ) {
        count++;
      }
    }

    return count;
  }

  // Function to get the label based on the filter key and option value
  getChipLabel(filterKey: string, optionValue: any): string {
    const filter = this.optionFilters.find((filter) => filter.filterKey === filterKey);
    if (filter) {
      const option = filter.options.find((option) => option.value === optionValue);
      if (option) {
        return option.label;
      }
    }
    return '';
  }
  ngOnDestroy() {
    this.destroySubject.next();
  }
}
