import { Injectable } from '@angular/core';
import { Actions, createEffect, EffectNotification, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, tap, map, mergeMap, Observable, of, Subscription, takeUntil } from 'rxjs';
import { OrdersService } from '../../orders.service';
import * as OrdersActions from './orders.actions';
import * as fromApp from 'src/app/store/app.reducer';
import { NavigationStart, Router } from '@angular/router';
import { OrderListResponse } from 'src/app/shared/api/types/types';
import { QueryRef } from 'apollo-angular';

@Injectable()
export class OrdersEffects {
  ordersQuery: QueryRef<OrderListResponse>;
  ordersQuerySub: Observable<any>;
  browserRefresh: boolean;
  refreshSub: Subscription;
  queryRefetch: boolean = false;
  location: string;

  constructor(
    private actions: Actions,
    private ordersService: OrdersService,
    private store: Store<fromApp.AppState>,
    private router: Router,
  ) {
    this.refreshSub = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.browserRefresh = !router.navigated;
      }
    });
  }

  public readonly loadOrders: Observable<any> = createEffect(() => {
    return this.actions.pipe(
      ofType(OrdersActions.LOAD_ORDERS),
      mergeMap((action: OrdersActions.LoadOrders) => {
        this.ordersQuery = this.ordersService.getAllQuotes(action.payload.input, action.payload.app);
        this.ordersQuerySub = this.ordersQuery.valueChanges.pipe(
          map((data) => {
            if (!this.queryRefetch) {
              if (action.payload.app === 'sp') {
                this.store.dispatch(new OrdersActions.GetCurrentSpPage(1));
                this.store.dispatch(new OrdersActions.GetCurrentSpPageSize(5));
              } else if (action.payload.app === 'bo') {
                this.store.dispatch(new OrdersActions.GetCurrentBoPage(1));
                this.store.dispatch(new OrdersActions.GetCurrentBoPageSize(5));
              }

              this.store.dispatch(new OrdersActions.ClearAllFilters());
              this.store.dispatch(new OrdersActions.LoadOrdersFailure(undefined));
              this.store.dispatch(new OrdersActions.ClearOrdersList());
            }
            this.queryRefetch = true;
            this.store.dispatch(new OrdersActions.GetOrdersCount(data.data.orders.itemsFound));
            return new OrdersActions.LoadOrdersSuccess(data.data.orders.items);
          }),
          catchError((error) => of(new OrdersActions.LoadOrdersFailure(error))),
        );

        return this.ordersQuerySub;
      }),
    );
  });

  public readonly refetchOrders = createEffect(
    () =>
      this.actions.pipe(
        ofType(OrdersActions.REFETCH_ORDERS),
        tap((action: OrdersActions.RefetchOrders) => {
          this.ordersQuery.resetLastResults();
          this.ordersQuery.refetch(action.payload);
        }),
      ),
    { dispatch: false },
  );

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>): Observable<EffectNotification> {
    return this.actions.pipe(
      ofType(OrdersActions.ORDERS_PAGE_INITIALIZED),
      exhaustMap(() =>
        resolvedEffects$.pipe(takeUntil(this.actions.pipe(ofType(OrdersActions.ORDERS_PAGE_DESTROYED)))),
      ),
    );
  }
}
