import { Action } from '@ngrx/store';
import { Company } from 'src/app/shared/api/types/GraphQL';

export const GET_CURRENT_PAGE = '[My Companies] Get Current Page';
export const GET_CURRENT_PAGE_SIZE = '[My Companies] Get Current Page Size';
export const GET_MY_COMPANIES_COUNT = '[My Companies] Get My Companies Count';
export const LOAD_MY_COMPANIES = '[My Companies] Load My Companies';
export const REFETCH_MY_COMPANIES = '[My Companies] Refetch My Companies';
export const LOAD_MY_COMPANIES_SUCCESS = '[My Companies] Load My Companies Success';
export const LOAD_MY_COMPANIES_FAILURE = '[My Companies] Load My Companies Failure';

export const ADD_COMPANY = '[My Companies] Add Company';
export const UPDATE_COMPANY = '[My Companies] Update Company';
export const DELETE_COMPANY = '[My Companies] Delete Company';

export const GET_GENERAL_FILTER = '[My Companies] Get General Filter';

export const ADD_COMPANY_TO_LIST = '[My Companies] Add Selected Company to List';
export const DELETE_COMPANY_FROM_LIST = '[My Companies] Delete Company from List';
export const CLEAR_MY_COMPANIES_LIST = '[My Companies] Clear My Companies List';

export const MY_COMPANIES_PAGE_INITIALIZED = '[My Companies] Page Initialized';
export const MY_COMPANIES_PAGE_DESTROYED = '[My Companies] Page Destroyed';

export class GetCurrentPage implements Action {
  readonly type = GET_CURRENT_PAGE;

  constructor(public payload: number) {}
}

export class GetCurrentPageSize implements Action {
  readonly type = GET_CURRENT_PAGE_SIZE;

  constructor(public payload: number) {}
}

export class GetMyCompaniesCount implements Action {
  readonly type = GET_MY_COMPANIES_COUNT;

  constructor(public payload: number) {}
}

export class LoadMyCompanies implements Action {
  readonly type = LOAD_MY_COMPANIES;

  constructor(public payload: {}) {}
}

export class RefetchMyCompanies implements Action {
  readonly type = REFETCH_MY_COMPANIES;

  constructor(public payload: {}) {}
}

export class LoadMyCompaniesSuccess implements Action {
  readonly type = LOAD_MY_COMPANIES_SUCCESS;

  constructor(public payload: Company[]) {}
}

export class LoadMyCompaniesFailure implements Action {
  readonly type = LOAD_MY_COMPANIES_FAILURE;

  constructor(public payload: Error) {}
}

export class AddCompany implements Action {
  readonly type = ADD_COMPANY;

  constructor(public payload: Company) {}
}

// export class UpdateCompany implements Action {
//     readonly type = UPDATE_COMPANY;

//     constructor(public payload: {id: number, newCompany: Order}) {}
// }
export class DeleteCompany implements Action {
  readonly type = DELETE_COMPANY;

  constructor(public payload: number) {}
}

export class AddCompanyToList implements Action {
  readonly type = ADD_COMPANY_TO_LIST;

  constructor(public payload: Company) {}
}

export class DeleteCompanyFromList implements Action {
  readonly type = DELETE_COMPANY_FROM_LIST;

  constructor(public payload: number) {}
}

export class ClearMyCompaniesList implements Action {
  readonly type = CLEAR_MY_COMPANIES_LIST;
}

export class GetGeneralFilter implements Action {
  readonly type = GET_GENERAL_FILTER;

  constructor(public payload: string) {}
}

export class MyCompaniesPageInitialized implements Action {
  readonly type = MY_COMPANIES_PAGE_INITIALIZED;
}

export class MyCompaniesPageDestroyed implements Action {
  readonly type = MY_COMPANIES_PAGE_DESTROYED;
}

export type MyCompaniesActions =
  | GetGeneralFilter
  | GetCurrentPage
  | GetCurrentPageSize
  | GetMyCompaniesCount
  | LoadMyCompanies
  | RefetchMyCompanies
  | LoadMyCompaniesSuccess
  | LoadMyCompaniesFailure
  | AddCompany
  //| UpdateCompany
  | DeleteCompany
  | AddCompanyToList
  | DeleteCompanyFromList
  | ClearMyCompaniesList
  | MyCompaniesPageInitialized
  | MyCompaniesPageDestroyed;
