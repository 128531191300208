import { gql } from 'apollo-angular';
import { CategoryFragments } from '../fragments/category-fragments';
import { AttributeFragments } from '../fragments/attribute-fragments';

export const SingleCategory = gql`
  query singleCategory($categoryId: Float, $userId: Int, $appLanguage: String) {
    category(categoryId: $categoryId, userId: $userId) {
      ...category
      categories {
        ...category
        categories {
          categoryId
        }
      }
    }
  }
  ${CategoryFragments.category}
`;

export const OneLevelCategories = gql`
  query firstLevelCategories($filter: CategorySearchInput, $userId: Int, $appLanguage: String) {
    categories(filter: $filter, userId: $userId) {
      page
      itemsFound
      offset
      items {
        ...category
        categories {
          categoryId
        }
      }
    }
  }
  ${CategoryFragments.category}
`;

export const CategoryDetails = gql`
  query categoryDetails($categoryId: Float, $userId: Int, $appLanguage: String) {
    category(categoryId: $categoryId, userId: $userId) {
      ...categoryDetails
    }
  }
  ${CategoryFragments.categoryDetails}
`;

export const CategoryAttributesQuery = gql`
  query categoryAttributes($categoryId: Float!, $input: AttributeResultSearchInput) {
    category(categoryId: $categoryId) {
      attributes(input: $input) {
        items {
          attribute {
            id
            value {
              id
            }
          }
          attributeDescription {
            id
            valuesetId
            name
            descriptions {
              language
              value
            }
            type
            group
            isSearchable
            isPublic
            isSystem
            isHidden
            defaultValue {
              ...attrValue
            }
            createdAt
            lastModifiedAt
          }
          value {
            id
            ... on AttributeColorValue {
              id
              type
              colorValue
            }
            ... on AttributeDateTimeValue {
              id
              type
              dateTimeValue
            }
            ... on AttributeDecimalValue {
              id
              type
              decimalValue
            }
            ... on AttributeEnumValue {
              enumValues
            }
            ... on AttributeIntValue {
              id
              type
              intValue
            }
            ... on AttributeTextValue {
              textValues {
                language
                values
              }
            }
          }
        }
        itemsFound
        page
      }
    }
  }
  ${AttributeFragments.attributeValue}
`;
