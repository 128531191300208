import { AttributeDescriptionClass } from '../../api/types/GraphQL';
import { TableColumns } from '../../api/types/interfaces';
import * as TableColumnsActions from './table-columns.actions';

export interface State {
  paymethodsTableColumns: TableColumns;
  carriersTableColumns: TableColumns;
  surchargesTableColumns: TableColumns;
  taxesTableColumns: TableColumns;
  backofficeUsersTableColumns: TableColumns;
  listing: { valueSetTableColumns: TableColumns };
  picker: { valueSetTableColumns: TableColumns };
  valueSetItemTableColumns: TableColumns;
  orderlistsTableColumns: TableColumns;
  favoriteListing: { favoritesTableColumns: TableColumns };
  favoriteUserListing: { favoritesTableColumns: TableColumns };
  attributeDescriptionsTableColumns: ListClasses;
  orderStatusTableColumns: TableColumns;
  orderStatusSetTableColumns: TableColumns;
  userListing: {
    usersTableColumns: {
      contacts: TableColumns;
      companies: TableColumns;
      customers: TableColumns;
    };
  };
  userPicking: {
    usersTableColumns: {
      contacts: TableColumns;
      companies: TableColumns;
      customers: TableColumns;
    };
  };
  priceSheetTableColumns: TableColumns;
}
export interface ListClasses {
  [key: string]: TableColumns;
}

const initialAttrDescStatePropertiesPerClass: TableColumns = {
  displayedColumns: [
    'select',
    'name',
    'description',
    'type',
    'group',
    'searchable',
    'public',
    'system',
    'hidden',
    'default',
    'createdAt',
    'lastModifiedAt',
  ],
  hiddenColumns: ['select'],
  disabledSuffixLength: 0,
  availableColumns: [],
  disabledColumns: ['select', 'name'],
};

const initialState: State = {
  paymethodsTableColumns: {
    displayedColumns: ['select', 'logo', 'names', 'id', 'createdAt', 'lastModifiedAt', 'externalCode'],
    disabledColumns: ['names'],
    hiddenColumns: ['select'],
    disabledSuffixLength: 0,
    availableColumns: [],
  },
  carriersTableColumns: {
    displayedColumns: [
      'select',
      'id',
      'logo',
      'name',
      'type',
      'trackAndTraceURL',
      'shippingCost',
      'dateCreated',
      'dateUpdated',
    ],
    hiddenColumns: ['select'],
    disabledSuffixLength: 0,
    availableColumns: ['select'],
    disabledColumns: ['name'],
  },
  surchargesTableColumns: {
    displayedColumns: [
      'select',
      'id',
      'name',
      'type',
      'value',
      'enabled',
      'validFrom',
      'validTo',
      'taxCode',
      'taxZone',
      'dateCreated',
      'dateChanged',
    ],
    disabledColumns: ['name'],
    hiddenColumns: ['select'],
    disabledSuffixLength: 1,
    availableColumns: [],
  },
  taxesTableColumns: {
    displayedColumns: [
      'select',
      'id',
      'shopId',
      'code',
      'zone',
      'percentage',
      'exportCode',
      'createdAt',
      'lastModifiedAt',
    ],
    hiddenColumns: ['select'],
    disabledSuffixLength: 0,
    availableColumns: [],
    disabledColumns: ['code'],
  },
  backofficeUsersTableColumns: {
    displayedColumns: ['select', 'id', 'name', 'email', 'createdAt', 'lastModifiedAt'],
    hiddenColumns: ['select'],
    disabledSuffixLength: 0,
    availableColumns: [],
    disabledColumns: ['name'],
  },
  listing: {
    valueSetTableColumns: {
      displayedColumns: [
        'select',
        'id',
        'type',
        'name',
        'descriptions',
        'values',
        'createdAt',
        'lastModifiedAt',
        'actions',
      ],
      hiddenColumns: ['select'],
      disabledSuffixLength: 0,
      availableColumns: ['select'],
      disabledColumns: ['id'],
    },
  },
  picker: {
    valueSetTableColumns: {
      displayedColumns: [
        'select',
        'id',
        'type',
        'name',
        'descriptions',
        'values',
        'createdAt',
        'lastModifiedAt',
        'actions',
      ],
      hiddenColumns: ['select'],
      disabledSuffixLength: 0,
      availableColumns: ['select'],
      disabledColumns: ['id'],
    },
  },
  valueSetItemTableColumns: {
    displayedColumns: ['select', 'id', 'valuesetId', 'descriptions', 'value', 'extra'],
    hiddenColumns: ['select'],
    disabledSuffixLength: 0,
    availableColumns: ['select'],
    disabledColumns: ['id'],
  },
  orderlistsTableColumns: {
    displayedColumns: [
      'select',
      'id',
      'description',
      'type',
      'code',
      'extra',
      'partnerEntity',
      'active',
      'numberOfUsers',
      'numberOfProducts',
      'validFrom',
      'validTo',
      'createdDate',
      'changedDate',
    ],
    hiddenColumns: ['select'],
    disabledSuffixLength: 0,
    availableColumns: [],
    disabledColumns: ['id'],
  },
  favoriteListing: {
    favoritesTableColumns: {
      displayedColumns: [
        'select',
        'id',
        'userId',
        'name',
        'slug',
        'default',
        'numberOfItems',
        'createdDate',
        'changedDate',
      ],
      hiddenColumns: ['select'],
      disabledSuffixLength: 0,
      availableColumns: [],
      disabledColumns: ['name'],
    },
  },
  favoriteUserListing: {
    favoritesTableColumns: {
      displayedColumns: [
        'select',
        'id',
        'userId',
        'name',
        'slug',
        'default',
        'numberOfItems',
        'createdDate',
        'changedDate',
      ],
      hiddenColumns: ['select'],
      disabledSuffixLength: 0,
      availableColumns: [],
      disabledColumns: ['name'],
    },
  },
  attributeDescriptionsTableColumns: {},
  orderStatusTableColumns: {
    displayedColumns: [
      'handle',
      'select',
      'id',
      'code',
      'type',
      'name',
      'description',
      'isDefault',
      'isPublic',
      'isEditable',
      'isExportable',
      'isConfirmable',
      'isArchivable',
      'createdAt',
      'lastModifiedAt',
    ],
    hiddenColumns: ['handle', 'select'],
    disabledSuffixLength: 0,
    availableColumns: ['handle', 'select'],
    disabledColumns: ['id'],
  },
  orderStatusSetTableColumns: {
    displayedColumns: ['select', 'id', 'name', 'description'],
    hiddenColumns: ['select'],
    disabledSuffixLength: 0,
    availableColumns: ['select'],
    disabledColumns: ['id'],
  },
  userListing: {
    usersTableColumns: {
      contacts: {
        displayedColumns: ['select', 'fullName', 'tags', 'email', 'company', 'phone', 'source'],
        hiddenColumns: ['select'],
        disabledSuffixLength: 0,
        availableColumns: [],
        disabledColumns: ['contactId', 'fullName'],
      },
      companies: {
        displayedColumns: ['select', 'name', 'location', 'tags', 'contacts', 'source'],
        hiddenColumns: ['select'],
        disabledSuffixLength: 0,
        availableColumns: [],
        disabledColumns: ['name'],
      },
      customers: {
        displayedColumns: ['select', 'name', 'tags', 'email', 'source', 'customergroup'],
        hiddenColumns: ['select'],
        disabledSuffixLength: 0,
        availableColumns: [],
        disabledColumns: ['name'],
      },
    },
  },
  userPicking: {
    usersTableColumns: {
      contacts: {
        displayedColumns: ['select', 'fullName', 'email', 'company', 'phone', 'source'],
        hiddenColumns: ['select'],
        disabledSuffixLength: 0,
        availableColumns: [],
        disabledColumns: ['contactId', 'fullName'],
      },
      companies: {
        displayedColumns: ['select', 'name', 'contacts', 'source', 'actions'],
        hiddenColumns: ['select'],
        disabledSuffixLength: 0,
        availableColumns: [],
        disabledColumns: ['name'],
      },
      customers: {
        displayedColumns: ['select', 'name', 'email', 'source', 'customergroup'],
        hiddenColumns: ['select'],
        disabledSuffixLength: 0,
        availableColumns: [],
        disabledColumns: ['name'],
      },
    },
  },
  priceSheetTableColumns: {
    displayedColumns: ['handle', 'select', 'code', 'name', 'description', 'readonly', 'createdAt', 'lastModifiedAt'],
    hiddenColumns: ['handle', 'select'],
    disabledSuffixLength: 0,
    availableColumns: ['handle', 'select'],
    disabledColumns: ['code'],
  },
};

Object.values(AttributeDescriptionClass).forEach((key) => {
  initialState.attributeDescriptionsTableColumns[key] = initialAttrDescStatePropertiesPerClass;
});

export function tableColumnsReducer(state: State = initialState, action: TableColumnsActions.TableColumnsActions) {
  switch (action.type) {
    case TableColumnsActions.PAYMETHODS_TABLE_COLUMNS:
      return {
        ...state,
        paymethodsTableColumns: { ...action.payload },
      };

    case TableColumnsActions.CARRIERS_TABLE_COLUMNS:
      return {
        ...state,
        carriersTableColumns: { ...action.payload },
      };

    case TableColumnsActions.SURCHARGES_TABLE_COLUMNS:
      return {
        ...state,
        surchargesTableColumns: { ...action.payload },
      };

    case TableColumnsActions.TAXES_TABLE_COLUMNS:
      return {
        ...state,
        taxesTableColumns: { ...action.payload },
      };

    case TableColumnsActions.BACKOFFICE_USERS_TABLE_COLUMNS:
      return {
        ...state,
        backofficeUsersTableColumns: { ...action.payload },
      };

    case TableColumnsActions.VALUE_SET_TABLE_COLUMNS:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          valueSetTableColumns: { ...action.payload.columnsObj },
        },
      };

    case TableColumnsActions.VALUE_SET_ITEM_TABLE_COLUMNS:
      return {
        ...state,
        valueSetItemTableColumns: { ...action.payload },
      };

    case TableColumnsActions.ORDERLISTS_TABLE_COLUMNS:
      return {
        ...state,
        orderlistsTableColumns: { ...action.payload },
      };

    case TableColumnsActions.FAVORITES_TABLE_COLUMNS:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          favoritesTableColumns: { ...action.payload.columnsObj },
        },
      };
    case TableColumnsActions.ATTRIBUTE_DESCRIPTIONS_TABLE_COLUMNS:
      return {
        ...state,
        attributeDescriptionsTableColumns: {
          ...state.attributeDescriptionsTableColumns,
          [action.payload.attrClass]: { ...action.payload.columnObj },
        },
      };
    case TableColumnsActions.ORDER_STATUS_TABLE_COLUMNS:
      return {
        ...state,
        orderStatusTableColumns: { ...action.payload },
      };
    case TableColumnsActions.ORDER_STATUS_SET_TABLE_COLUMNS:
      return {
        ...state,
        orderStatusSetTableColumns: action.payload,
      };
    case TableColumnsActions.USERS_TABLE_COLUMNS:
      return {
        ...state,
        [action.payload.stateEntry]: {
          ...state[action.payload.stateEntry],
          usersTableColumns: {
            ...state[action.payload.stateEntry].usersTableColumns,
            [action.payload.tab]: action.payload.columnsObj,
          },
        },
      };
    case TableColumnsActions.PRICE_SHEET_TABLE_COLUMNS:
      return {
        ...state,
        priceSheetTableColumns: action.payload,
      };
    default:
      return state;
  }
}
