import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, filter, map, Observable, of, switchMap, tap, withLatestFrom } from 'rxjs';
import * as fromApp from 'src/app/store/app.reducer';
import { QueryRef } from 'apollo-angular';
import { PriceSheetService } from '../price-sheet.service';
import * as priceSheetActions from './price-sheet.actions';
import { priceSheetResponse } from 'src/app/shared/api/types/types';

@Injectable()
export class PriceSheetEffects {
  priceSheetQuery: QueryRef<priceSheetResponse>;
  priceSheetQuerySub: Observable<Action>;

  constructor(
    private actions: Actions,
    private priceSheetService: PriceSheetService,
    private store: Store<fromApp.AppState>,
  ) {}

  public readonly loadOrderStatus: Observable<Action> = createEffect(() => {
    return this.actions.pipe(
      ofType(priceSheetActions.LOAD_PRICE_SHEET),
      withLatestFrom(this.store.select((state) => state.orderStatus.loaded)),
      filter(([action, loaded]) => !loaded),
      switchMap(([action, loaded]: [priceSheetActions.LoadPriceSheet, boolean]) => {
        this.priceSheetQuery = this.priceSheetService.getPriceSheetList(action.payload);
        this.priceSheetQuerySub = this.priceSheetQuery.valueChanges.pipe(
          map((data) => {
            this.store.dispatch(new priceSheetActions.GetPriceSheetCount(data.data.pricesheets.itemsFound));
            return new priceSheetActions.LoadPriceSheetSuccess(data.data.pricesheets.items);
          }),
          catchError((error) => of(new priceSheetActions.LoadPriceSheetFailure(error))),
        );
        return this.priceSheetQuerySub;
      }),
    );
  });

  public readonly refetchOrderStatus = createEffect(
    () =>
      this.actions.pipe(
        ofType(priceSheetActions.REFETCH_PRICE_SHEET),
        tap((action: priceSheetActions.RefetchPriceSheet) => {
          this.priceSheetQuery.resetLastResults();
          this.priceSheetQuery.refetch(action.payload);
        }),
      ),
    { dispatch: false },
  );
}
