import { Injectable } from '@angular/core';
import { Apollo, MutationResult, QueryRef } from 'apollo-angular';
import { Observable } from 'rxjs';
import { PayMethodCreate, PayMethodDelete, PayMethodUpdate } from 'src/app/shared/api/mutations/paymethod-mutations';
import { PayMethodsDropdownListList, PayMethodsList } from 'src/app/shared/api/queries/paymethods-query';
import { PayMethodCreateInput, PayMethodSearchInput, PayMethodUpdateInput } from 'src/app/shared/api/types/GraphQL';
import {
  PayMethodCreateResponse,
  PayMethodListResponse,
  PayMethodUpdateResponse,
} from 'src/app/shared/api/types/types';

@Injectable({
  providedIn: 'root',
})
export class PayMethodsService {
  constructor(private apollo: Apollo) {}

  getPayMethodsList(input: PayMethodSearchInput): QueryRef<PayMethodListResponse> {
    return this.apollo.watchQuery<PayMethodListResponse>({
      query: PayMethodsList,
      variables: { input },
    });
  }

  getPayMethodsDropdownList(input: PayMethodSearchInput): QueryRef<PayMethodListResponse> {
    return this.apollo.watchQuery<PayMethodListResponse>({
      query: PayMethodsDropdownListList,
      variables: { input },
    });
  }
  payMethodCreate(variables: { input: PayMethodCreateInput }): Observable<MutationResult<PayMethodCreateResponse>> {
    return this.apollo.mutate<PayMethodCreateResponse>({
      mutation: PayMethodCreate,
      variables,
    });
  }

  payMethodUpdate(variables: { id: number; input: PayMethodUpdateInput }) {
    return this.apollo.mutate<PayMethodUpdateResponse>({
      mutation: PayMethodUpdate,
      variables,
    });
  }

  payMethodDelete(id: number) {
    return this.apollo.mutate<Boolean>({
      mutation: PayMethodDelete,
      variables: { id },
    });
  }
}
