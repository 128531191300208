import { gql } from 'apollo-angular';
import { AttributeFragments } from '../fragments/attribute-fragments';
import { environment } from 'src/environments/environment';

export const ContactQuery = gql`
  query ContactQuery($id: Int!) {
    contact(id: $id) {
      contactId
      email
      firstName
      middleName
      lastName
      phone
      gender
      company {
        name
      }
    }
  }
`;
export const ContactsDropdownQuery = gql`
  query ContactsDropdownQuery($input: ContactSearchArguments!) {
    contacts(input: $input) {
      page
      itemsFound
      offset
      items {
        firstName
        middleName
        lastName
      }
    }
  }
`;

export const ContactsListQuery = gql`
  query ContactsListQuery($input: ContactSearchArguments!) {
    contacts(input: $input) {
      page
      itemsFound
      offset
      items {
        contactId
        firstName
        middleName
        lastName
        email
        phone
        company {
          name
        }
        attributes(input: { attributeDescription: { names: "${environment.userTagsAttributeName}" } }) {
          items {
            attribute {
             id
            }
            attributeDescription {
              id
            }
            value {
              ... on AttributeEnumValue {
                enumValues
              }
            }
          }
        }
      }
    }
  }
`;

export const ContactDetailsQuery = gql`
  query contact($id: Int!) {
    contact(id: $id) {
      contactId
      firstName
      lastName
      middleName
      phone
      mobile
      email
      gender
      dateOfBirth
      primaryLanguage
      expires
      isLoggedIn
      mailingList
      notes
      createdAt
      lastModifiedAt
      iban
      bankAccount
      bic
      parentCompanyId
      debtorId
      company {
        name
      }
      sources {
        name
        id
      }
      companies {
        items {
          name
        }
      }
    }
  }
`;

export const ContactDetailsAttributesQuery = gql`
  query contactDetailsAttributes($id: Int!, $input: AttributeResultSearchInput) {
    contact(id: $id) {
      contactId
      attributes(input: $input) {
        items {
          attribute {
            id
            value {
              id
            }
          }
          attributeDescription {
            id
            valuesetId
            name
            descriptions {
              language
              value
            }
            type
            group
            isSearchable
            isPublic
            isSystem
            isHidden
            defaultValue {
              ...attrValue
            }
            createdAt
            lastModifiedAt
          }
          value {
            id
            ... on AttributeColorValue {
              id
              type
              colorValue
            }
            ... on AttributeDateTimeValue {
              id
              type
              dateTimeValue
            }
            ... on AttributeDecimalValue {
              id
              type
              decimalValue
            }
            ... on AttributeEnumValue {
              enumValues
            }
            ... on AttributeIntValue {
              id
              type
              intValue
            }
            ... on AttributeTextValue {
              textValues {
                language
                values
              }
            }
          }
        }
        itemsFound
        page
      }
    }
  }
  ${AttributeFragments.attributeValue}
`;

export const CompaniesInContactQuery = gql`
  query contact($id: Int!, $input: ContactCompaniesSearchInput) {
    contact(id: $id) {
      companies(input: $input) {
        items {
          name
          companyId
        }
        itemsFound
        page
        offset
        start
        end
        pages
      }
    }
  }
`;
