import { Orderlist, OrderlistSearchInput } from '../../../shared/api/types/GraphQL';
import { TableColumns } from '../../../shared/api/types/enums';
import * as orderlists from './orderlists.actions';

export interface State {
  currentPage: number;
  currentPageSize: number;
  orderlists: Orderlist[];
  selectedOrderlistsList: Orderlist[];
  loading: boolean;
  orderlistsCount: number;
  productTableColumns: TableColumns;
  orderlistsFilters: OrderlistSearchInput;
}

const initialState: State = {
  currentPage: 1,
  currentPageSize: 5,
  orderlists: [],
  selectedOrderlistsList: [],
  loading: true,
  orderlistsCount: 0,
  productTableColumns: {
    displayedColumns: ['select', 'sku', 'name', 'supplier'],
    hiddenColumns: [],
    disabledSuffixLength: 0,
    availableColumns: [],
    disabledColumns: [],
  },
  orderlistsFilters: {},
};

export function orderlistsReducer(state: State = initialState, action: orderlists.OrderListsActions) {
  switch (action.type) {
    case orderlists.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case orderlists.GET_CURRENT_PAGE_SIZE:
      return {
        ...state,
        currentPageSize: action.payload,
      };

    case orderlists.REFETCH_ORDER_LISTS:
      return {
        ...state,
        loading: true,
      };

    case orderlists.LOAD_ORDER_LISTS: {
      return {
        ...state,
        loading: true,
      };
    }

    case orderlists.LOAD_ORDER_LISTS_SUCCESS: {
      return {
        ...state,
        orderlists: action.payload.items,
        orderlistsCount: action.payload.itemsFound,
        loading: false,
      };
    }

    case orderlists.LOAD_ORDER_LISTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case orderlists.ADD_ORDER_LIST_TO_SELECTED_LISTS:
      if (state.selectedOrderlistsList.map((x) => x.id).indexOf(action.payload.id) === -1) {
        return {
          ...state,
          selectedOrderlistsList: [...state.selectedOrderlistsList, action.payload],
        };
      }
      return state;

    case orderlists.DELETE_ORDER_LIST_FROM_SELECTED_LIST:
      if (state.selectedOrderlistsList.map((x) => x.id).indexOf(action.payload) !== -1) {
        const index = state.selectedOrderlistsList.map((x) => x.id).indexOf(action.payload);
        return {
          ...state,
          selectedOrderlistsList: state.selectedOrderlistsList.filter((list, i) => {
            return i !== index;
          }),
        };
      }
      return state;

    case orderlists.CLEAR_SELECTED_ORDER_LISTS:
      return {
        ...state,
        selectedOrderlistsList: [],
      };

    case orderlists.GET_GENERAL_FILTER:
      return {
        ...state,
        generalFilterValue: action.payload,
      };

    case orderlists.ADD_GENERAL_FILTER:
      return {
        ...state,
        orderlistsFilters: { ...state.orderlistsFilters, ...action.payload },
      };

    case orderlists.REMOVE_GENERAL_FILTER:
      const { columnName, index } = action.payload;

      // Clone the state's orderlistsFilters object to prevent mutation
      const updatedFilters = { ...state.orderlistsFilters };

      // Check if orderlistsFilters[columnName] is an array
      if (Array.isArray(updatedFilters[columnName])) {
        // If it's an array, clone the array, remove the item at the specified index, and update the orderlistsFilters object
        updatedFilters[columnName] = [...updatedFilters[columnName]];
        updatedFilters[columnName].splice(index, 1);
      } else {
        // If it's not an array, simply delete the key corresponding to columnName
        delete updatedFilters[columnName];
      }

      return {
        ...state,
        orderlistsFilters: updatedFilters,
      };

    default:
      return state;
  }
}
