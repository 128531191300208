import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, filter, map, Observable, of, switchMap, tap, withLatestFrom } from 'rxjs';
import * as WarehousesActions from './warehouses.actions';
import * as fromApp from '../../../store/app.reducer';
import { WarehousesService } from '../warehouses.service';
import { QueryRef } from 'apollo-angular';
import { WarehouseListResponse } from 'src/app/shared/api/types/types';

@Injectable()
export class WarehousesEffects {
  warehousesQuery: QueryRef<WarehouseListResponse>;
  warehousesQuerySub: Observable<Action>;

  constructor(
    private actions: Actions,
    private warehousesService: WarehousesService,
    private store: Store<fromApp.AppState>,
  ) {}

  public readonly loadWarehouses: Observable<Action> = createEffect(() => {
    return this.actions.pipe(
      ofType(WarehousesActions.LOAD_WAREHOUSES),
      withLatestFrom(this.store.select((state) => state.warehouses.loaded)),
      filter(([action, loaded]) => !loaded),
      switchMap(([action, loaded]: [WarehousesActions.LoadWarehouses, boolean]) => {
        this.warehousesQuery = this.warehousesService.getWarehousesList(action.payload);
        this.warehousesQuerySub = this.warehousesQuery.valueChanges.pipe(
          map((data) => {
            this.store.dispatch(new WarehousesActions.GetWarehousesCount(data.data.warehouses.itemsFound));
            return new WarehousesActions.LoadWarehousesSuccess(data.data.warehouses.items);
          }),
          catchError((error) => of(new WarehousesActions.LoadWarehousesFailure(error))),
        );
        return this.warehousesQuerySub;
      }),
    );
  });

  public readonly refetchWarehouses = createEffect(
    () =>
      this.actions.pipe(
        ofType(WarehousesActions.REFETCH_WAREHOUSES),
        tap((action: WarehousesActions.RefetchWarehouses) => {
          this.warehousesQuery.resetLastResults();
          this.warehousesQuery.refetch(action.payload);
        }),
      ),
    { dispatch: false },
  );
}
