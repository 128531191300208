import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { QueryRef } from 'apollo-angular';
import { catchError, filter, map, Observable, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { PayMethodListResponse } from 'src/app/shared/api/types/types';
import { PayMethodsService } from '../services/paymethods.service';
import * as fromApp from '../../../store/app.reducer';
import * as PayMethodsActions from './paymethods.actions';

@Injectable()
export class PayMethodsEffects {
  payMethodsQuery: QueryRef<PayMethodListResponse>;
  payMethodsQuerySub: Observable<Action>;

  constructor(
    private actions: Actions,
    private payMethodsService: PayMethodsService,
    private store: Store<fromApp.AppState>,
  ) {}

  public readonly loadPayMethods: Observable<Action> = createEffect(() => {
    return this.actions.pipe(
      ofType(PayMethodsActions.LOAD_PAYMETHODS),
      withLatestFrom(this.store.select((state) => state.paymethods.loaded)),
      filter(([action, loaded]) => !loaded),
      switchMap(([action, loaded]: [PayMethodsActions.LoadPayMethods, boolean]) => {
        this.payMethodsQuery = this.payMethodsService.getPayMethodsList(action.payload);
        this.payMethodsQuerySub = this.payMethodsQuery.valueChanges.pipe(
          map((data) => {
            this.store.dispatch(new PayMethodsActions.GetPayMethodsCount(data.data.payMethods.itemsFound));
            return new PayMethodsActions.LoadPayMethodsSuccess(data.data.payMethods.items);
          }),
          catchError((error) => of(new PayMethodsActions.LoadPayMethodsFailure(error))),
        );
        return this.payMethodsQuerySub;
      }),
    );
  });

  public readonly refetchPayMethods = createEffect(
    () =>
      this.actions.pipe(
        ofType(PayMethodsActions.REFETCH_PAYMETHODS),
        tap((action: PayMethodsActions.RefetchPayMethods) => {
          this.payMethodsQuery.resetLastResults();
          this.payMethodsQuery.refetch(action.payload);
        }),
      ),
    { dispatch: false },
  );
}
