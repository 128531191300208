import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, filter, map, Observable, of, switchMap, tap, withLatestFrom } from 'rxjs';
import * as fromApp from 'src/app/store/app.reducer';
import * as orderStatusSetActions from './order-status-set.actions';
import { OrderStatusService } from '../../order-status.service';
import { QueryRef } from 'apollo-angular';
import { orderStatusSetsResponse } from 'src/app/shared/api/types/types';

@Injectable()
export class OrderStatusSetEffects {
  orderStatusSetQuery: QueryRef<orderStatusSetsResponse>;
  orderStatusSetQuerySub: Observable<Action>;

  constructor(
    private actions: Actions,
    private orderStatusService: OrderStatusService,
    private store: Store<fromApp.AppState>,
  ) {}

  public readonly loadOrderStatusSet: Observable<Action> = createEffect(() => {
    return this.actions.pipe(
      ofType(orderStatusSetActions.LOAD_ORDER_STATUS_SET),
      withLatestFrom(this.store.select((state) => state.orderStatusSet.loaded)),
      filter(([action, loaded]) => !loaded),
      switchMap(([action, loaded]: [orderStatusSetActions.LoadOrderStatusSet, boolean]) => {
        this.orderStatusSetQuery = this.orderStatusService.getOrderStatusSetList(action.payload);
        this.orderStatusSetQuerySub = this.orderStatusSetQuery.valueChanges.pipe(
          map((data) => {
            this.store.dispatch(new orderStatusSetActions.GetOrderStatusSetCount(data.data.orderStatusSets.itemsFound));
            return new orderStatusSetActions.LoadOrderStatusSetSuccess(data.data.orderStatusSets.items);
          }),
          catchError((error) => of(new orderStatusSetActions.LoadOrderStatusSetFailure(error))),
        );
        return this.orderStatusSetQuerySub;
      }),
    );
  });

  public readonly refetchOrderStatusSet = createEffect(
    () =>
      this.actions.pipe(
        ofType(orderStatusSetActions.REFETCH_ORDER_STATUS_SET),
        tap((action: orderStatusSetActions.RefetchOrderStatusSet) => {
          this.orderStatusSetQuery.resetLastResults();
          this.orderStatusSetQuery.refetch(action.payload);
        }),
      ),
    { dispatch: false },
  );
}
