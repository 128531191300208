import { gql } from 'apollo-angular';

export const UpdateOrderlistMutation = gql`
  mutation UpdateOrderlist($id: Int!, $input: OrderlistUpdateInput!) {
    orderlistUpdate(id: $id, input: $input) {
      id
      descriptions {
        value
        language
      }
      extras {
        value
        language
      }
      type
      code
      validFrom
      validTo
    }
  }
`;

export const CreateOrderlistMutation = gql`
  mutation CreateOrderlist($input: OrderlistCreateInput!) {
    orderlistCreate(input: $input) {
      id
    }
  }
`;

export const DeleteOrderlistMutation = gql`
  mutation DeleteOrderlist($id: Int!) {
    orderlistDelete(id: $id)
  }
`;

export const AddProductsToOrderlistMutation = gql`
  mutation AddProductsToOrderList($id: Int!, $input: OrderlistItemsInput!, $productSearchInput: ProductSearchInput!) {
    orderlistAddItems(id: $id, input: $input) {
      id
      products(input: $productSearchInput) {
        items {
          ... on Product {
            id
            language
            class
            hidden
            names {
              value
              language
            }
            class
            productId
            supplier
            manufacturerCode
            sku
            media {
              images(search: { sort: ASC, offset: 1 }) {
                items {
                  imageVariants(
                    input: {
                      transformations: [
                        {
                          name: "xs"
                          transformation: {
                            width: 100
                            height: 100
                            fit: BOUNDS
                            bgColor: "transparent"
                            canvas: { width: 100, height: 100 }
                          }
                        }
                      ]
                    }
                  ) {
                    language
                    name
                    url
                  }
                }
              }
            }
          }
        }
        itemsFound
      }
    }
  }
`;

export const RemoveProductsFromOrderlistMutation = gql`
  mutation RemoveProductsFromOrderlist(
    $id: Int!
    $input: OrderlistItemsInput!
    $productSearchInput: ProductSearchInput!
  ) {
    orderlistRemoveItems(id: $id, input: $input) {
      id
      products(input: $productSearchInput) {
        items {
          ... on Product {
            id
            language
            class
            hidden
            names {
              value
            }
            productId
            class
            supplier
            manufacturerCode
            sku
          }
        }
        itemsFound
      }
    }
  }
`;

export const OrderlistAssignUsersMutation = gql`
  mutation AssignUsersToOrderlist($id: Int!, $input: OrderlistUsersInput!) {
    orderlistAssignUsers(id: $id, input: $input) {
      users {
        ... on Contact {
          contactId
          firstName
          lastName
          email
        }
        ... on Customer {
          customerId
          lastName
          firstName
          email
        }
      }
      companies {
        companyId
        name
      }
    }
  }
`;

export const OrderlistUnassignUsersMutation = gql`
  mutation UnassignUsersFromOrderlist($id: Int!, $input: OrderlistUsersInput!) {
    orderlistUnassignUsers(id: $id, input: $input) {
      users {
        ... on Contact {
          contactId
          firstName
          lastName
          email
        }
        ... on Customer {
          customerId
          lastName
          firstName
          email
        }
      }
      companies {
        companyId
        name
      }
    }
  }
`;

export const OrderlistAssignCompaniesMutation = gql`
  mutation AssignCompaniesToOrderlist($id: Int!, $input: OrderlistCompaniesInput!) {
    orderlistAssignCompanies(id: $id, input: $input) {
      users {
        ... on Contact {
          contactId
          firstName
          lastName
          email
        }
        ... on Customer {
          customerId
          lastName
          firstName
          email
        }
      }
      companies {
        companyId
        name
      }
    }
  }
`;

export const OrderlistUnassignCompaniesMutation = gql`
  mutation UnassignCompaniesFromOrderlist($id: Int!, $input: OrderlistCompaniesInput!) {
    orderlistUnassignCompanies(id: $id, input: $input) {
      users {
        ... on Contact {
          contactId
          firstName
          lastName
          email
        }
        ... on Customer {
          customerId
          lastName
          firstName
          email
        }
      }
      companies {
        companyId
        name
      }
    }
  }
`;
