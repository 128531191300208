import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, filter, map, Observable, of, switchMap, tap, withLatestFrom } from 'rxjs';
import * as fromApp from 'src/app/store/app.reducer';
import * as orderStatusActions from './order-status.actions';
import { OrderStatusService } from '../../order-status.service';
import { QueryRef } from 'apollo-angular';
import { orderStatusesResponse } from 'src/app/shared/api/types/types';

@Injectable()
export class OrderStatusEffects {
  orderStatusQuery: QueryRef<orderStatusesResponse>;
  orderStatusQuerySub: Observable<Action>;

  constructor(
    private actions: Actions,
    private orderStatusService: OrderStatusService,
    private store: Store<fromApp.AppState>,
  ) {}

  public readonly loadOrderStatus: Observable<Action> = createEffect(() => {
    return this.actions.pipe(
      ofType(orderStatusActions.LOAD_ORDER_STATUS),
      withLatestFrom(this.store.select((state) => state.orderStatus.loaded)),
      filter(([action, loaded]) => !loaded),
      switchMap(([action, loaded]: [orderStatusActions.LoadOrderStatus, boolean]) => {
        this.orderStatusQuery = this.orderStatusService.getOrderStatusesList(action.payload);
        this.orderStatusQuerySub = this.orderStatusQuery.valueChanges.pipe(
          map((data) => {
            this.store.dispatch(new orderStatusActions.GetOrderStatusCount(data.data.orderStatuses.itemsFound));
            return new orderStatusActions.LoadOrderStatusSuccess(data.data.orderStatuses.items);
          }),
          catchError((error) => of(new orderStatusActions.LoadOrderStatusFailure(error))),
        );
        return this.orderStatusQuerySub;
      }),
    );
  });

  public readonly refetchOrderStatus = createEffect(
    () =>
      this.actions.pipe(
        ofType(orderStatusActions.REFETCH_ORDER_STATUS),
        tap((action: orderStatusActions.RefetchOrderStatus) => {
          this.orderStatusQuery.resetLastResults();
          this.orderStatusQuery.refetch(action.payload);
        }),
      ),
    { dispatch: false },
  );
}
