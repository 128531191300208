import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { UsersService } from '../users.service';
import { GqlErrorService } from 'src/app/shared/services/gql-error.service';
import { RoleNames } from 'src/app/shared/api/types/enums';
import { RoleAccess } from 'src/app/shared/api/types/GraphQL';
import { Store } from '@ngrx/store';
import * as fromApp from 'src/app/store/app.reducer';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  isViewer: boolean = false;

  constructor(
    private router: Router,
    private usersService: UsersService,
    private errorService: GqlErrorService,
    private store: Store<fromApp.AppState>,
  ) {
    this.store
      .select((state) => state.auth.roles)
      .subscribe((roles) => {
        if (roles.length) {
          const userRole = roles.find((role) => role.name === RoleNames.user);
          if (userRole && userRole.access === RoleAccess.Viewer) this.isViewer = true;
          else if (userRole && userRole.access !== RoleAccess.Viewer) this.isViewer = false;
        }
      });
  }

  openUserDetails(element: any) {
    let userType;
    if (element.__typename.includes('Search')) {
      userType = element.__typename.replace('Search', '').trim().toLowerCase();
    } else {
      userType = element.__typename.toLowerCase();
    }
    const currentUrl = this.router.url;
    const userId = element[`${userType}Id`];
    this.router.navigateByUrl(`${currentUrl}/details/${userType}/${userId}`);
  }

  performUserDeletion(element: any): Observable<any> {
    const userType = element.__typename.toLowerCase();

    const userId =
      'contactId' in element ? element.contactId : 'companyId' in element ? element.companyId : element.customerId;
    return this.usersService.deleteUser(userId, userType).pipe(
      map((data) => data.data),
      catchError((error) => {
        console.error('Deletion error:', error);
        this.errorService.getGqlError(error);
        return of(false);
      }),
    );
  }
}
