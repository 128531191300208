import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { OrderlistSearchInput, OrderlistsResponse } from '../../shared/api/types/GraphQL';
import { OrderlistDetailsQuery, OrderlistsQuery } from '../../shared/api/queries/orderlists-query';
import {
  AddProductsToOrderlistMutation,
  CreateOrderlistMutation,
  DeleteOrderlistMutation,
  RemoveProductsFromOrderlistMutation,
  UpdateOrderlistMutation,
  OrderlistAssignUsersMutation,
  OrderlistAssignCompaniesMutation,
  OrderlistUnassignCompaniesMutation,
  OrderlistUnassignUsersMutation,
} from '../../shared/api/mutations/orderlist-mutations';

@Injectable({
  providedIn: 'root',
})
export class OrderlistsService {
  constructor(private apollo: Apollo) {}

  getOrderLists(variables: OrderlistSearchInput): QueryRef<any> {
    return this.apollo.watchQuery<OrderlistsResponse>({
      query: OrderlistsQuery,
      variables: { input: variables },
      fetchPolicy: 'no-cache',
    });
  }

  getOrderListDetails(id: number, offset: number): QueryRef<any> {
    return this.apollo.watchQuery<any>({
      query: OrderlistDetailsQuery,
      variables: { id, offset },
      fetchPolicy: 'no-cache',
    });
  }

  updateOrderlist(id: number, variables: any) {
    return this.apollo.mutate<any>({
      mutation: UpdateOrderlistMutation,
      variables: { id, input: variables },
    });
  }

  createOrderlist(variables: any) {
    return this.apollo.mutate<any>({
      mutation: CreateOrderlistMutation,
      variables: { input: variables },
    });
  }

  deleteOrderlist(id: number) {
    return this.apollo.mutate<Boolean>({
      mutation: DeleteOrderlistMutation,
      variables: { id },
    });
  }

  addProductsToOrderList(id: number, variables: any, productSearchInput: {} = {}) {
    return this.apollo.mutate<any>({
      mutation: AddProductsToOrderlistMutation,
      variables: { id, input: variables, productSearchInput },
    });
  }

  removeProductsFromOrderlist(id: number, variables: any, productSearchInput: {} = {}) {
    return this.apollo.mutate<any>({
      mutation: RemoveProductsFromOrderlistMutation,
      variables: { id, input: variables, productSearchInput },
    });
  }

  assignUsersToOrderlist(id: number, variables: any) {
    return this.apollo.mutate<any>({
      mutation: OrderlistAssignUsersMutation,
      variables: { id, input: variables },
    });
  }

  unassignUsersFromOrderlist(id, variables: any) {
    return this.apollo.mutate<any>({
      mutation: OrderlistUnassignUsersMutation,
      variables: { id, input: variables },
    });
  }

  assignCompaniesToOrderlist(id: number, variables: any) {
    return this.apollo.mutate<any>({
      mutation: OrderlistAssignCompaniesMutation,
      variables: { id, input: variables },
    });
  }

  unassignCompaniesFromOrderlist(id, variables: any) {
    return this.apollo.mutate<any>({
      mutation: OrderlistUnassignCompaniesMutation,
      variables: { id, input: variables },
    });
  }
}
