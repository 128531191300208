import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import {
  CompaniesDropdownQuery,
  CompanyListQuery,
  CompanyQuery,
  CompaySearchDropdownQuery,
  GetCompaniesSearchQuery,
  SelectedCompanyConatctsQuery,
} from '../shared/api/queries/company-queries';
import { ContactQuery, ContactsDropdownQuery } from '../shared/api/queries/contact-query';
import { CustomersDropdownQuery } from '../shared/api/queries/customer-queries';
import { CompanySearchArguments, CompanySearchInput, CustomerSearchArguments } from '../shared/api/types/GraphQL';
import {
  CompanyListResponse,
  CompanyResponse,
  CompanySearchListResponse,
  ContactListResponse,
  ContactResponse,
  CustomerListResponse,
} from '../shared/api/types/types';
import { DeleteCompanyMutation } from '../shared/api/mutations/company-mutations';

@Injectable({ providedIn: 'root' })
export class CompaniesService {
  constructor(private apollo: Apollo) {}

  getCompanyList(variables: {}): QueryRef<CompanyListResponse> {
    return this.apollo.watchQuery<CompanyListResponse>({
      query: CompanyListQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
  }

  getCompaniesDropdown(variables: CompanySearchArguments): QueryRef<CompanyListResponse> {
    return this.apollo.watchQuery<CompanyListResponse>({
      query: CompaniesDropdownQuery,
      variables: { input: variables },
    });
  }

  getCompanysearchDropdown(variables: CompanySearchInput): QueryRef<CompanySearchListResponse> {
    return this.apollo.watchQuery<CompanySearchListResponse>({
      query: CompaySearchDropdownQuery,
      variables: { input: variables },
    });
  }

  getCompany(variables: { id: number }): QueryRef<CompanyResponse> {
    return this.apollo.watchQuery<CompanyResponse>({
      query: CompanyQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
  }

  getSelectedCompanyContacts(variables: { id: number; page: number }): QueryRef<CompanyResponse> {
    return this.apollo.watchQuery<CompanyResponse>({
      query: SelectedCompanyConatctsQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
  }
  getContact(variables: {}): QueryRef<ContactResponse> {
    return this.apollo.watchQuery<ContactResponse>({
      query: ContactQuery,
      variables,
      nextFetchPolicy: 'no-cache',
    });
  }

  getContactsDropdown(variables: CompanySearchArguments): QueryRef<ContactListResponse> {
    return this.apollo.watchQuery<ContactListResponse>({
      query: ContactsDropdownQuery,
      variables: { input: variables },
    });
  }

  getCustomersDropdown(variables: CustomerSearchArguments): QueryRef<CustomerListResponse> {
    return this.apollo.watchQuery<CustomerListResponse>({
      query: CustomersDropdownQuery,
      variables: { input: variables },
    });
  }

  getCompanySearchList(variables: { input: CompanySearchInput }): QueryRef<CompanySearchListResponse> {
    return this.apollo.watchQuery<CompanySearchListResponse>({
      query: GetCompaniesSearchQuery,
      variables,
      fetchPolicy: 'no-cache',
    });
  }
  deleteCompany(id: number) {
    return this.apollo.mutate<any>({
      mutation: DeleteCompanyMutation,
      variables: { id },
    });
  }
}
