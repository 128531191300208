import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-table-editor-dialog',
  templateUrl: './table-editor-dialog.component.html',
  styleUrls: ['./table-editor-dialog.component.scss'],
})
export class TableEditorDialogComponent implements OnInit {
  columnsInView: any[] = [];
  availableColumns: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      displayedColumns: string[];
      hiddenColumns: string[];
      availableColumns: string[];
      disabledColumns: string[];
      disabledSuffixLength: number;
    },
    private mdDialogRef: MatDialogRef<TableEditorDialogComponent>,
  ) {}

  ngOnInit(): void {
    for (let columnName of this.data.availableColumns) {
      this.availableColumns.push({
        value: columnName,
        hidden: false,
        disabled: false,
      });
    }
    for (let columnName of this.data.displayedColumns) {
      this.columnsInView.push({
        value: columnName,
        hidden: false,
        disabled: false,
      });
    }
    for (let column of this.data.hiddenColumns) {
      for (let columnObj of this.columnsInView) {
        if (column === columnObj.value) {
          columnObj.hidden = true;
        }
      }
      for (let columnObj of this.availableColumns) {
        if (column === columnObj.value) {
          columnObj.hidden = true;
        }
      }
    }
    for (let column of this.data.disabledColumns) {
      for (let columnObj of this.columnsInView) {
        if (column === columnObj.value) {
          columnObj.disabled = true;
        }
      }
    }
  }

  public cancel() {
    this.close(false);
  }
  public close(value) {
    this.mdDialogRef.close(value);
  }
  public confirm() {
    this.data.availableColumns = [];
    this.data.displayedColumns = [];
    for (let item of this.availableColumns) {
      this.data.availableColumns.push(item.value);
    }
    for (let item of this.columnsInView) {
      this.data.displayedColumns.push(item.value);
    }
    this.close(this.data);
  }
  @HostListener('keydown.esc')
  public onEsc() {
    this.close(false);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex - this.data.disabledSuffixLength,
        event.currentIndex - this.data.disabledSuffixLength,
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex - this.data.disabledSuffixLength,
        event.currentIndex - this.data.disabledSuffixLength,
      );
    }
  }

  removeFromDisplayed(previousList, currentList, item) {
    // let assume that the user will move it as last element
    let previousIndex = this.columnsInView.findIndex((object) => {
      return object.value === item.value;
    });
    let currentIndex = this.availableColumns.length - this.data.disabledSuffixLength;
    transferArrayItem(previousList, currentList, previousIndex, currentIndex);
  }
}
