import { gql } from 'apollo-angular';

export const priceSheetListQuery = gql`
  query pricesheets($input: PricesheetSearchInput) {
    pricesheets(input: $input) {
      items {
        id
        code
        descriptions {
          language
          value
        }
        names {
          language
          value
        }
        priority
        readonly
        createdAt
        lastModifiedAt
        companies {
          companyId
          name
          __typename
        }
        customers {
          customerId
          firstName
          lastName
          email
          __typename
        }
        contacts {
          contactId
          firstName
          lastName
          email
          __typename
        }
        usergroups
      }
      itemsFound
      offset
      page
      pages
      start
      end
    }
  }
`;
// type

export const getPriceSheet = gql`
  query pricesheet($id: String!) {
    pricesheet(id: $id) {
      id
      code
      descriptions {
        language
        value
      }
      names {
        language
        value
      }
      priority
      readonly
      createdAt
      lastModifiedAt
      companies {
        companyId
        name
        __typename
      }
      customers {
        customerId
        firstName
        lastName
        email
        __typename
      }
      contacts {
        contactId
        firstName
        lastName
        email
        __typename
      }
      usergroups
    }
  }
`;
// type
