// guard-status.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GuardStatusService {
  private guardActiveSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  setGuardActive(active: boolean) {
    this.guardActiveSubject.next(active);
  }

  getGuardStatus() {
    return this.guardActiveSubject.asObservable();
  }
}
