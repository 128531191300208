import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { QueryRef } from 'apollo-angular';
import { Observable, catchError, filter, map, mergeMap, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { SurchargeResponse } from 'src/app/shared/api/types/types';
import * as fromApp from '../../../../app/store/app.reducer';
import { SurchargesService } from '../services/surcharges.service';
import * as SurchargesActions from './surcharges.actions';

@Injectable()
export class SurchargesEffects {
  query: QueryRef<SurchargeResponse>;
  querySub: Observable<Action>;

  constructor(
    private actions: Actions,
    private surchargesService: SurchargesService,
    private store: Store<fromApp.AppState>,
  ) {}

  public readonly loadSurcharges: Observable<Action> = createEffect(() => {
    return this.actions.pipe(
      ofType(SurchargesActions.LOAD_SURCHARGES),
      withLatestFrom(this.store.select((state) => state.surcharges.loaded)),
      filter(([action, loaded]) => !loaded),
      switchMap(([action, loaded]: [SurchargesActions.LoadSurcharges, boolean]) => {
        this.query = this.surchargesService.getSurchargesList(action.payload);
        this.querySub = this.query.valueChanges.pipe(
          map((data) => {
            this.store.dispatch(new SurchargesActions.GetSurchargesCount(data.data.surcharges.itemsFound));
            return new SurchargesActions.LoadSurchargesSuccess(data.data.surcharges.items);
          }),
          catchError((error) => of(new SurchargesActions.LoadSurchargesFailure(error))),
        );
        return this.querySub;
      }),
    );
  });

  public readonly refetchSurcharges = createEffect(
    () =>
      this.actions.pipe(
        ofType(SurchargesActions.REFETCH_SURCHARGES),
        tap((action: SurchargesActions.RefetchSurcharges) => {
          this.query.resetLastResults();
          this.query.refetch(action.payload);
        }),
      ),
    { dispatch: false },
  );
}
