import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import {
  CompaniesInContactQuery,
  ContactDetailsAttributesQuery,
  ContactDetailsQuery,
} from '../../../shared/api/queries/contact-query';
import {
  CustomerAddressQuery,
  CustomerDetailsAttributesQuery,
  CustomerDetailsQuery,
} from '../../../shared/api/queries/customer-queries';
import {
  CompanyAdressesQuery,
  CompanyDetailsAttributesQuery,
  CompanyDetailsQuery,
  ContactsInCompanyQuery,
} from '../../../shared/api/queries/company-queries';
import {
  Address,
  CompanyAddressCreateInput,
  CompanyAddressDeleteInput,
  CompanyAddressUpdateInput,
  ContactCompaniesSearchInput,
  ContactSearchArguments,
  CustomerAddressCreateInput,
  CustomerAddressUpdateInput,
  MutationCustomerAddressUpdateArgs,
  UpdateCompanyInput,
  UpdateContactInput,
  UpdateCustomerInput,
} from '../../../shared/api/types/GraphQL';
import {
  CreateCustomerAddressMutation,
  UpdateCustomerAddressMutation,
  UpdateCustomerMutation,
} from '../../../shared/api/mutations/customer-mutations';
import {
  CreateCompanyAddressMutation,
  DeleteCompanyAddressMutation,
  UpdateCompanyAddressMutation,
  UpdateCompanyMutation,
} from '../../../shared/api/mutations/company-mutations';
import { CompanyResponse, ContactResponse, CustomerResponse } from '../../../shared/api/types/types';
import { UpdateContactMutation } from '../../../shared/api/mutations/contact-mutations';
import { UserTypes } from '../../../shared/api/types/enums';

@Injectable({ providedIn: 'root' })
export class UserDetailsService {
  constructor(private apollo: Apollo) {}

  private getContactDetails(id: number): QueryRef<ContactResponse> {
    return this.apollo.watchQuery<ContactResponse>({
      query: ContactDetailsQuery,
      variables: { id },
      fetchPolicy: 'no-cache',
    });
  }
  private getCustomerDetails(id: number): QueryRef<CustomerResponse> {
    return this.apollo.watchQuery<CustomerResponse>({
      query: CustomerDetailsQuery,
      variables: { id },
      fetchPolicy: 'no-cache',
    });
  }
  private getCompanyDetails(id: number): QueryRef<CompanyResponse> {
    return this.apollo.watchQuery<CompanyResponse>({
      query: CompanyDetailsQuery,
      variables: { id },
      fetchPolicy: 'no-cache',
    });
  }

  getContactsInCompany(id: number, input?: ContactSearchArguments): QueryRef<CompanyResponse> {
    return this.apollo.watchQuery<CompanyResponse>({
      query: ContactsInCompanyQuery,
      variables: { id, input },
      fetchPolicy: 'no-cache',
    });
  }

  getCompaniesInContact(id: number, input?: ContactCompaniesSearchInput): QueryRef<CompanyResponse> {
    return this.apollo.watchQuery<CompanyResponse>({
      query: CompaniesInContactQuery,
      variables: { id, input },
      fetchPolicy: 'no-cache',
    });
  }

  private getCustomerDetailsAttributes(id: number, variables: {}): QueryRef<CustomerResponse> {
    return this.apollo.watchQuery<CustomerResponse>({
      query: CustomerDetailsAttributesQuery,
      variables: { id: id, input: variables },
      fetchPolicy: 'no-cache',
    });
  }
  private getContactDetailsAttributes(id: number, variables: {}): QueryRef<ContactResponse> {
    return this.apollo.watchQuery<ContactResponse>({
      query: ContactDetailsAttributesQuery,
      variables: { id: id, input: variables },
      fetchPolicy: 'no-cache',
    });
  }

  private getCompanyDetailsAttributes(id: number, variables: {}): QueryRef<ContactResponse> {
    return this.apollo.watchQuery<ContactResponse>({
      query: CompanyDetailsAttributesQuery,
      variables: { id: id, input: variables },
      fetchPolicy: 'no-cache',
    });
  }

  private getCompanyAddress(type: string, companyId: number): QueryRef<Address[]> {
    return this.apollo.watchQuery<Address[]>({
      query: CompanyAdressesQuery,
      variables: { type, companyId },
      fetchPolicy: 'no-cache',
    });
  }

  private getCustomerAddress(type: string, customerId: number): QueryRef<Address[]> {
    return this.apollo.watchQuery<Address[]>({
      query: CustomerAddressQuery,
      variables: { type, customerId },
      fetchPolicy: 'no-cache',
    });
  }

  private updateCustomer(variables: { id: number; input: UpdateCustomerInput }) {
    return this.apollo.mutate<any>({
      mutation: UpdateCustomerMutation,
      variables,
    });
  }

  private updateContact(variables: { id: number; input: UpdateContactInput }) {
    return this.apollo.mutate<any>({
      mutation: UpdateContactMutation,
      variables,
    });
  }

  private updateCompany(variables: { id: number; input: UpdateCompanyInput }) {
    return this.apollo.mutate<any>({
      mutation: UpdateCompanyMutation,
      variables,
    });
  }

  private updateCustomerAddress(variables: CustomerAddressUpdateInput) {
    return this.apollo.mutate<MutationCustomerAddressUpdateArgs>({
      mutation: UpdateCustomerAddressMutation,
      variables: { input: variables },
    });
  }

  private updateCompanyAddress(variables: CompanyAddressUpdateInput) {
    return this.apollo.mutate<any>({
      mutation: UpdateCompanyAddressMutation,
      variables: { input: variables },
    });
  }

  private createCustomerAddress(variables: CustomerAddressCreateInput) {
    return this.apollo.mutate<MutationCustomerAddressUpdateArgs>({
      mutation: CreateCustomerAddressMutation,
      variables: { input: variables },
    });
  }

  deleteCompanyAddress(variables: CompanyAddressDeleteInput) {
    return this.apollo.mutate<any>({
      mutation: DeleteCompanyAddressMutation,
      variables: { input: variables },
    });
  }

  private createCompanyAddress(variables: CompanyAddressCreateInput) {
    return this.apollo.mutate<any>({
      mutation: CreateCompanyAddressMutation,
      variables: { input: variables },
    });
  }

  getAddressQuery(userType: UserTypes, addressType: string, userId: number): QueryRef<Address[]> {
    if (userType === UserTypes.customer) {
      return this.getCustomerAddress(addressType, userId);
    } else {
      return this.getCompanyAddress(addressType, userId);
    }
  }

  getUserDetailsQuery(userType: UserTypes, userId: number): QueryRef<any> {
    if (userType === UserTypes.customer) {
      return this.getCustomerDetails(userId);
    } else if (userType === UserTypes.contact) {
      return this.getContactDetails(userId);
    } else {
      return this.getCompanyDetails(userId);
    }
  }

  getUserAttributesQuery({ userType, userId, variables }): QueryRef<any> {
    switch (userType) {
      case UserTypes.customer:
        return this.getCustomerDetailsAttributes(userId, {
          ...variables,
        });
      case UserTypes.company:
        return this.getCompanyDetailsAttributes(userId, {
          ...variables,
        });
      case UserTypes.contact:
        return this.getContactDetailsAttributes(userId, {
          ...variables,
        });
      default:
        console.error('Invalid attribute type ');
    }
  }

  updateUser(userType: UserTypes, updateObject: any) {
    const userTypeIds = {};
    userTypeIds[userType + 'Id'] = updateObject[userType + 'Id'];
    delete updateObject[userType + 'Id'];

    if (userType == UserTypes.customer) {
      return this.updateCustomer({ id: userTypeIds[userType + 'Id'], input: updateObject });
    } else if (userType == UserTypes.company) {
      return this.updateCompany({ id: userTypeIds[userType + 'Id'], input: updateObject });
    }
    return this.updateContact({ id: userTypeIds[userType + 'Id'], input: updateObject });
  }

  updateAddress(userType: UserTypes, updatedAddress: CustomerAddressUpdateInput | CompanyAddressUpdateInput) {
    if (userType === UserTypes.customer) {
      return this.updateCustomerAddress(updatedAddress as CustomerAddressUpdateInput);
    }

    return this.updateCompanyAddress(updatedAddress as CompanyAddressUpdateInput);
  }

  createAddress(userType: UserTypes, createObject: CustomerAddressCreateInput | CompanyAddressCreateInput) {
    if (userType === UserTypes.customer) {
      return this.createCustomerAddress(createObject as CustomerAddressCreateInput);
    }
    return this.createCompanyAddress(createObject as CompanyAddressCreateInput);
  }
}
