import { Action } from '@ngrx/store';
import { Valueset, ValuesetSearchInput } from 'src/app/shared/api/types/GraphQL';
import { AppComponentLocation, StateEntry } from 'src/app/shared/api/types/enums';

export const RESET_STATE_ENTRY_STATE = '[ValueSet] Reset state entry state';
export const SET_LOCATION = '[ValueSet] Set location';

export const GET_CURRENT_PAGE = '[ValueSet] Get Current Page';
export const GET_CURRENT_PAGE_SIZE = '[ValueSet] Get Current Page Size';
export const GET_VALUE_SET_COUNT = '[ValueSet] Get ValueSet Count';
export const LOAD_VALUE_SET = '[ValueSet] Load ValueSet';
export const REFETCH_VALUE_SET = '[ValueSet] Refetch ValueSet';
export const LOAD_VALUE_SET_SUCCESS = '[ValueSet] Load ValueSet Success';
export const LOAD_VALUE_SET_FAILURE = '[ValueSet] Load ValueSet Failure';

export const ADD_VALUE_SET = '[ValueSet] Add ValueSet';
export const CREATE_VALUE_SET = '[ValueSet] Create ValueSet';
export const UPDATE_VALUE_SET = '[ValueSet] Update ValueSet';
export const DELETE_VALUE_SET = '[ValueSet] Delete ValueSet';

export const GET_GENERAL_FILTER = '[ValueSet] Get General Filter';
export const SET_STATE_ENTRY = '[ValueSet] Set state entry';
export const ADD_GENERAL_FILTER = '[ValueSet] Add General Filter';
export const REMOVE_GENERAL_FILTER = '[ValueSet] Remove General Filter';

export const ADD_VALUE_SET_TO_LIST = '[ValueSet] Add Selected ValueSet to List';
export const DELETE_VALUE_SET_FROM_LIST = '[ValueSet] Delete ValueSet from List';
export const CLEAR_VALUE_SET_LIST = '[ValueSet] Clear ValueSet List';

export class ResetStateEntryState implements Action {
  readonly type = RESET_STATE_ENTRY_STATE;
}

export class SetLocation implements Action {
  readonly type = SET_LOCATION;

  constructor(public payload: AppComponentLocation) {}
}
export class GetCurrentPage implements Action {
  readonly type = GET_CURRENT_PAGE;

  constructor(public payload: number) {}
}

export class GetCurrentPageSize implements Action {
  readonly type = GET_CURRENT_PAGE_SIZE;

  constructor(public payload: number) {}
}

export class GetValueSetCount implements Action {
  readonly type = GET_VALUE_SET_COUNT;

  constructor(public payload: number) {}
}

export class LoadValueSet implements Action {
  readonly type = LOAD_VALUE_SET;

  constructor(public payload: {}) {}
}

export class RefetchValueSet implements Action {
  readonly type = REFETCH_VALUE_SET;

  constructor(public payload: {}) {}
}

export class LoadValueSetSuccess implements Action {
  readonly type = LOAD_VALUE_SET_SUCCESS;

  constructor(public payload: Valueset[]) {}
}

export class LoadValueSetFailure implements Action {
  readonly type = LOAD_VALUE_SET_FAILURE;

  constructor(public payload: Error) {}
}

export class AddValueSet implements Action {
  readonly type = ADD_VALUE_SET;

  constructor(public payload: Valueset) {}
}
export class CreateValueSet implements Action {
  readonly type = CREATE_VALUE_SET;

  constructor(public payload: Valueset) {}
}
export class UpdateValueSet implements Action {
  readonly type = UPDATE_VALUE_SET;

  constructor(public payload: { id: number; updatedValueSet: Valueset }) {}
}
export class DeleteValueSet implements Action {
  readonly type = DELETE_VALUE_SET;

  constructor(public payload: Valueset) {}
}

export class AddValueSetToList implements Action {
  readonly type = ADD_VALUE_SET_TO_LIST;

  constructor(public payload: Valueset) {}
}

export class DeleteValueSetFromList implements Action {
  readonly type = DELETE_VALUE_SET_FROM_LIST;

  constructor(public payload: number) {}
}

export class ClearValueSetList implements Action {
  readonly type = CLEAR_VALUE_SET_LIST;
  constructor(public payload: { stateEntry: StateEntry }) {}
}

export class GetGeneralFilter implements Action {
  readonly type = GET_GENERAL_FILTER;

  constructor(public payload: string) {}
}
export class AddGeneralFilter implements Action {
  readonly type = ADD_GENERAL_FILTER;

  constructor(public payload: ValuesetSearchInput) {}
}
export class RemoveGeneralFilter implements Action {
  readonly type = REMOVE_GENERAL_FILTER;

  constructor(public payload: { columnName: string; index?: number }) {}
}

export class SetStateEntry implements Action {
  readonly type = SET_STATE_ENTRY;

  constructor(public payload: StateEntry) {}
}

export type ValueSetActions =
  | ResetStateEntryState
  | SetLocation
  | GetGeneralFilter
  | GetCurrentPage
  | GetCurrentPageSize
  | GetValueSetCount
  | LoadValueSet
  | RefetchValueSet
  | LoadValueSetSuccess
  | LoadValueSetFailure
  | AddValueSet
  | UpdateValueSet
  | DeleteValueSet
  | AddValueSetToList
  | DeleteValueSetFromList
  | ClearValueSetList
  | CreateValueSet
  | SetStateEntry
  | AddGeneralFilter
  | RemoveGeneralFilter;
