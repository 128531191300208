import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'tinymce';

@Injectable({ providedIn: 'root' })
export class SidenavService {
  selectedTab: string = '';
  emitTabName;
  currentTab;

  constructor() {
    if (localStorage.hasOwnProperty('selectedTab')) {
      this.selectedTab = localStorage.getItem('selectedTab');
    } else {
      localStorage.setItem('selectedTab', 'Home');
      this.selectedTab = 'Home';
    }

    this.emitTabName = new BehaviorSubject(this.selectedTab);
    this.currentTab = this.emitTabName.asObservable();
  }

  changeTab(tabName: string) {
    this.emitTabName.next(tabName);
  }
}
