import { Tax, TaxSearchInput } from '../../../shared/api/types/GraphQL';
import * as taxes from './taxes.actions';

export interface State {
  currentPage: number;
  currentPageSize: number;
  taxes: Tax[];
  selectedTaxesList: Tax[];
  loading: boolean;
  generalFilterValue: string;
  taxesCount: number;
  taxesFilters: TaxSearchInput;
}

const initialState: State = {
  currentPage: 1,
  currentPageSize: 5,
  taxes: [],
  selectedTaxesList: [],
  loading: true,
  generalFilterValue: '',
  taxesCount: 0,
  taxesFilters: {},
};

export function TaxesReducer(state: State = initialState, action: taxes.TaxesActions) {
  switch (action.type) {
    case taxes.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case taxes.GET_CURRENT_PAGE_SIZE:
      return {
        ...state,
        currentPageSize: action.payload,
      };

    case taxes.REFETCH_TAXES:
      return {
        ...state,
        loading: true,
      };

    case taxes.LOAD_TAXES: {
      return {
        ...state,
        loading: true,
      };
    }

    case taxes.LOAD_TAXES_SUCCESS: {
      return {
        ...state,
        taxes: action.payload.items,
        taxesCount: action.payload.itemsFound,
        loading: false,
      };
    }

    case taxes.LOAD_TAXES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case taxes.ADD_TAX_TO_SELECTED_LIST:
      if (state.selectedTaxesList.map((x) => x.id).indexOf(action.payload.id) === -1) {
        return {
          ...state,
          selectedTaxesList: [...state.selectedTaxesList, action.payload],
        };
      }
      return state;

    case taxes.DELETE_TAX_FROM_SELECTED_LIST:
      if (state.selectedTaxesList.map((x) => x.id).indexOf(action.payload) !== -1) {
        const index = state.selectedTaxesList.map((x) => x.id).indexOf(action.payload);
        return {
          ...state,
          selectedTaxesList: state.selectedTaxesList.filter((list, i) => {
            return i !== index;
          }),
        };
      }
      return state;

    case taxes.CLEAR_SELECTED_LIST:
      return {
        ...state,
        selectedTaxesList: [],
      };

    case taxes.GET_GENERAL_FILTER:
      return {
        ...state,
        generalFilterValue: action.payload,
      };
    case taxes.ADD_GENERAL_FILTER:
      return {
        ...state,
        taxesFilters: { ...state.taxesFilters, ...action.payload },
      };
    case taxes.REMOVE_GENERAL_FILTER:
      const { columnName, index } = action.payload;

      // Clone the state's taxesFilters object to prevent mutation
      const updatedFilters = { ...state.taxesFilters };

      // Check if taxesFilters[columnName] is an array
      if (Array.isArray(updatedFilters[columnName])) {
        // If it's an array, clone the array, remove the item at the specified index, and update the taxesFilters object
        updatedFilters[columnName] = [...updatedFilters[columnName]];
        updatedFilters[columnName].splice(index, 1);
      } else {
        // If it's not an array, simply delete the key corresponding to columnName
        delete updatedFilters[columnName];
      }

      return {
        ...state,
        taxesFilters: updatedFilters,
      };
    default:
      return state;
  }
}
