import { gql } from 'apollo-angular';

export const RoleDefinitions = gql`
  query RoleDefinitions($input: RoleDefinitionSearchInput) {
    roleDefinitions(input: $input) {
      page
      itemsFound
      offset
      items {
        id
        name
        descriptions {
          language
          value
        }
        defaultAccess
      }
    }
  }
`;

export const UserRoles = gql`
  query UserRoles($input: RoleSearchInput!) {
    roles(input: $input) {
      page
      itemsFound
      offset
      items {
        id
        roleDefinition {
          id
          name
          descriptions {
            value
            language
          }
        }
        access
      }
    }
  }
`;
