import { ValuesetItem, ValuesetSearchInput } from 'src/app/shared/api/types/GraphQL';
import * as ValueSetItemActions from './value-set-item.actions';
import { globals } from 'src/app/shared/globals';

export interface State {
  loading: boolean;
  loaded: boolean;
  valueSetItem: ValuesetItem[];
  currentPage: number;
  currentPageSize: number;
  valueSetItemCount: number;
  error: Error;
  generalFilterValue: string;
  activeTab: number;
  selectedValueSetItemList: ValuesetItem[];
  valueSetItemFilters: ValuesetSearchInput;
}

const initialState: State = {
  loading: false,
  loaded: false,
  valueSetItem: [],
  currentPage: 1,
  currentPageSize: globals.globalOffset,
  valueSetItemCount: 0,
  error: undefined,
  generalFilterValue: '',
  activeTab: 0,
  selectedValueSetItemList: [],
  valueSetItemFilters: {},
};

export function valueSetItemReducer(state: State = initialState, action: ValueSetItemActions.ValueSetItemActions) {
  switch (action.type) {
    case ValueSetItemActions.GET_GENERAL_FILTER:
      return {
        ...state,
        generalFilterValue: action.payload,
      };
    case ValueSetItemActions.GET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case ValueSetItemActions.GET_CURRENT_PAGE_SIZE:
      return {
        ...state,
        currentPageSize: action.payload,
      };
    case ValueSetItemActions.GET_VALUE_SET_ITEM_COUNT:
      return {
        ...state,
        valueSetItemCount: action.payload,
      };
    case ValueSetItemActions.LOAD_VALUE_SET_ITEM:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case ValueSetItemActions.REFETCH_VALUE_SET_ITEM:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case ValueSetItemActions.LOAD_VALUE_SET_ITEM_SUCCESS:
      return {
        ...state,
        valueSetItem: [...action.payload],
        loading: false,
        loaded: true,
      };
    case ValueSetItemActions.LOAD_VALUE_SET_ITEM_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ValueSetItemActions.ADD_VALUE_SET_ITEM:
      return {
        ...state,
        valueSetItem: [...state.valueSetItem, action.payload],
      };
    case ValueSetItemActions.UPDATE_VALUE_SET_ITEM:
      const updatedValueSetItemIndex = state.valueSetItem.findIndex((valueSet) => valueSet.id === action.payload.id);
      if (updatedValueSetItemIndex > -1) {
        const updatedValueSetItemList = [...state.valueSetItem];
        updatedValueSetItemList[updatedValueSetItemIndex] = action.payload.updatedValueSetItem;
        return {
          ...state,
          valueSetItem: [...updatedValueSetItemList],
        };
      }
      return state; // If the value set to update is not found, return the current state unchanged.
    case ValueSetItemActions.ADD_VALUE_SET_ITEM_TO_LIST:
      if (state.selectedValueSetItemList.map((x) => x.id).indexOf(action.payload.id) === -1) {
        return {
          ...state,
          selectedValueSetItemList: [...state.selectedValueSetItemList, action.payload],
        };
      } else {
        return {
          ...state,
        };
      }
    case ValueSetItemActions.DELETE_VALUE_SET_ITEM_FROM_LIST:
      if (state.selectedValueSetItemList.map((x) => x.id).indexOf(action.payload) !== -1) {
        const index = state.selectedValueSetItemList.map((x) => x.id).indexOf(action.payload);
        return {
          ...state,
          selectedValueSetItemList: state.selectedValueSetItemList.filter((list, i) => {
            return i !== index;
          }),
        };
      }
      return state;
    case ValueSetItemActions.CLEAR_VALUE_SET_ITEM_LIST:
      return {
        ...state,
        selectedValueSetItemList: [],
      };
    case ValueSetItemActions.CREATE_VALUE_SET_ITEM:
      return {
        ...state,
        valueSetItem: [...state.valueSetItem, action.payload],
      };
    case ValueSetItemActions.ADD_GENERAL_FILTER:
      return {
        ...state,
        valueSetItemFilters: { ...state.valueSetItemFilters, ...action.payload },
      };
    case ValueSetItemActions.REMOVE_GENERAL_FILTER:
      const { columnName, index } = action.payload;

      // Clone the state's valueSetItemFilters object to prevent mutation
      const updatedFilters = { ...state.valueSetItemFilters };

      // Check if valueSetItemFilters[columnName] is an array
      if (Array.isArray(updatedFilters[columnName])) {
        // If it's an array, clone the array, remove the item at the specified index, and update the valueSetItemFilters object
        updatedFilters[columnName] = [...updatedFilters[columnName]];
        updatedFilters[columnName].splice(index, 1);
      } else {
        // If it's not an array, simply delete the key corresponding to columnName
        delete updatedFilters[columnName];
      }
      return {
        ...state,
        valueSetItemFilters: updatedFilters,
      };
    default:
      return state;
  }
}
