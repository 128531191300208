import { Injectable } from '@angular/core';
import { SnackBarService } from '../components/snack-bar/snack-bar.service';

@Injectable({ providedIn: 'root' })
export class GqlErrorService {
  error: Error;

  constructor(private snackBarService: SnackBarService) {}

  getGqlError(error) {
    const errorMessage = this.extractErrorMessage(error);
    this.snackBarService.openSnackBar(errorMessage, '', 'error');
  }

  getErrorMessage(error) {
    return this.extractErrorMessage(error);
  }

  private extractErrorMessage(error): string {
    console.log(error);
    if (Array.isArray(error) && error[0].message) {
      return error[0].message;
    }
    if (Array.isArray(error.graphQLErrors) && error.graphQLErrors.length > 0) {
      const firstError = error.graphQLErrors[0];
      const extensions = firstError.extensions;
      if (extensions) {
        if (extensions.type === 'TenderMinimumItemsViolation') {
          return 'You need to add at least one product';
        }

        if (extensions.messages && extensions.messages.length > 0) {
          return extensions.messages[0];
        }

        if (extensions.error && extensions.error.message) {
          return extensions.error.message;
        }

        if (extensions.errors && extensions.errors.length && extensions.errors[0].message) {
          if (extensions.errors[0].message === 'Failed to fetch user data')
            return 'The associated contact no longer exists';
          return extensions.errors[0].message;
        }

        if (extensions.originalError && extensions.originalError.message) {
          return extensions.originalError.message;
        }
      } else if (firstError.message) {
        return firstError.message;
      }
    } else if (error.networkError && error.networkError.result.errors.length) {
      const firstError = error.networkError.result.errors[0];
      return firstError.message;
    }

    return error;
  }
}
