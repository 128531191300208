import { Action } from '@ngrx/store';
import { Surcharge, SurchargeSearchInput } from 'src/app/shared/api/types/GraphQL';

export const GET_CURRENT_PAGE = '[Surcharges] Get Current Page';
export const GET_CURRENT_PAGE_SIZE = '[Surcharges] Get Current Page Size';
export const GET_SURCHARGES_COUNT = '[Surcharges] Get Surcharges Count';
export const LOAD_SURCHARGES = '[Surcharges] Load Surcharges';
export const REFETCH_SURCHARGES = '[Surcharges] Refetch Surcharges';
export const LOAD_SURCHARGES_SUCCESS = '[Surcharges] Load Surcharges Success';
export const LOAD_SURCHARGES_FAILURE = '[Surcharges] Load Surcharges Failure';

export const ADD_SURCHARGE = '[Surcharges] Add Surcharge';
export const UPDATE_SURCHARGE = '[Surcharges] Update Surcharge';
export const DELETE_SURCHARGE = '[Surcharges] Delete Surcharge';

export const GET_GENERAL_FILTER = '[Surcharges] Get General Filter';
export const GET_COLUMN_FILTERS = '[Surcharges] Get Column Filter';
export const GET_MULTISELECT_FILTERS = '{Surcharges] Get Multiselect Filters';

export const ADD_SURCHARGE_TO_LIST = '[Surcharge] Add Selected Surcharge to List';
export const DELETE_SURCHARGE_FROM_LIST = '[Surcharge] Delete Surcharge from List';
export const CLEAR_SURCHARGE_LIST = '[Surcharge] Clear Surcharges List';

export const SURCHARGES_PAGE_INITIALIZED = '[Surcharges] Page Initialized';
export const SURCHARGES_PAGE_DESTROYED = '[Surcharges] Page Destroyed';

export class GetCurrentPage implements Action {
  readonly type = GET_CURRENT_PAGE;

  constructor(public payload: number) {}
}

export class GetCurrentPageSize implements Action {
  readonly type = GET_CURRENT_PAGE_SIZE;

  constructor(public payload: number) {}
}

export class GetSurchargesCount implements Action {
  readonly type = GET_SURCHARGES_COUNT;

  constructor(public payload: number) {}
}

export class LoadSurcharges implements Action {
  readonly type = LOAD_SURCHARGES;

  constructor(public payload: {}) {}
}

export class RefetchSurcharges implements Action {
  readonly type = REFETCH_SURCHARGES;

  constructor(public payload: {}) {}
}

export class LoadSurchargesSuccess implements Action {
  readonly type = LOAD_SURCHARGES_SUCCESS;

  constructor(public payload: Surcharge[]) {}
}

export class LoadSurchargesFailure implements Action {
  readonly type = LOAD_SURCHARGES_FAILURE;

  constructor(public payload: Error) {}
}

export class AddSurcharge implements Action {
  readonly type = ADD_SURCHARGE;

  constructor(public payload: Surcharge) {}
}
export class DeleteSurcharge implements Action {
  readonly type = DELETE_SURCHARGE;

  constructor(public payload: number) {}
}

export class AddSurchargeToList implements Action {
  readonly type = ADD_SURCHARGE_TO_LIST;

  constructor(public payload: Surcharge) {}
}

export class DeleteSurchargeFromList implements Action {
  readonly type = DELETE_SURCHARGE_FROM_LIST;

  constructor(public payload: string) {}
}

export class ClearSurchargeList implements Action {
  readonly type = CLEAR_SURCHARGE_LIST;
}

export class GetGeneralFilter implements Action {
  readonly type = GET_GENERAL_FILTER;

  constructor(public payload: string) {}
}

export class GetColumnFilters implements Action {
  readonly type = GET_COLUMN_FILTERS;

  constructor(public payload: { columnName; filterValue }) {}
}

export class GetMultiselectFilters implements Action {
  readonly type = GET_MULTISELECT_FILTERS;

  constructor(public payload: SurchargeSearchInput) {}
}

export class SurchargesPageInitialized implements Action {
  readonly type = SURCHARGES_PAGE_INITIALIZED;
}

export class SurchargesPageDestroyed implements Action {
  readonly type = SURCHARGES_PAGE_DESTROYED;
}

export type SurchargesActions =
  | GetGeneralFilter
  | GetColumnFilters
  | GetMultiselectFilters
  | GetCurrentPage
  | GetCurrentPageSize
  | GetSurchargesCount
  | LoadSurcharges
  | RefetchSurcharges
  | LoadSurchargesSuccess
  | LoadSurchargesFailure
  | AddSurcharge
  | DeleteSurcharge
  | AddSurchargeToList
  | DeleteSurchargeFromList
  | ClearSurchargeList
  | SurchargesPageInitialized
  | SurchargesPageDestroyed;
