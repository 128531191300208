<div class="description-wrapper">
  <ng-content></ng-content>
  <div class="language-icon" [class.text-area]="isTextArea" (click)="openLanguageSelect(languageSelect)">
    <!-- <mat-icon>language</mat-icon> -->
    <span class="language-text">{{ selectedLanguage }}</span>
    <mat-form-field appearance="outline" class="tags-select">
      <mat-select
        #languageSelect
        [(ngModel)]="selectedLanguage"
        [ngModelOptions]="{ standalone: true }"
        (selectionChange)="onLanguageChange($event)"
        class="custom-select"
      >
        <div class="tags-popup">
          <mat-option *ngFor="let data of customData" [value]="data.value">{{
            data.descriptions | localizedString
          }}</mat-option>
        </div>
      </mat-select>
    </mat-form-field>
    <mat-icon class="expand-icon">keyboard_arrow_down</mat-icon>
  </div>
</div>
