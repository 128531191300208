import { gql } from 'apollo-angular';
import { globals } from '../../globals';
// hidden field
//tenant
// categoryPath

export const discountListQuery = gql`
  query discounts($input: DiscountSearchInput) {
    discounts(input: $input) {
      items {
        id
        discountType
        createdAt
        lastModifiedAt
        value
        quantityFrom
        validFrom
        validTo
        pricesheetId
        productId
        categoryId
        priceGroup
        product {
          names {
            language
            value
          }
            media {
              images(search: { sort: ASC, offset: ${globals.globalOffset} }) {
                itemsFound
                page
                offset
                items {
                  imageVariants(
                    input: {
                      transformations: [{
                        name: "xs"
                        transformation: { width: 100, height: 100, fit: BOUNDS, bgColor: "transparent", canvas: {width: 100, height: 100} }
                      }]
                    }
                  ) {
                    name
                    url
                  }
                }
              }
            }
          }
        category {
          name {
            language
            value
          }
        }
      }
      itemsFound
      offset
      page
      pages
      start
      end
    }
  }
`;
